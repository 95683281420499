import React from "react";
import {css} from "@emotion/react";

interface IProps {
    count: number;
}

export const CountBadge = (props: IProps) => {
    if (props.count === 0) {
        return null;
    }
    return <div css={countBadge}>{props.count}</div>;
};

const countBadge = css`
    color: #23232d;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #ebff00;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    top: 14px;
    left: 25px;

    @media (min-width: 1024px) {
        left: 5px;
    }

    @media (min-width: 1200px) {
        left: 15px;
    }
`;
import React from "react";
import {css} from "@emotion/react";

interface IProps {
    count: number;
}

export const CountBadge = (props: IProps) => {
    if (props.count === 0) {
        return null;
    }
    return <div css={countBadge}>{props.count}</div>;
};

const countBadge = css`
    color: #23232d;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #ebff00;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    top: 14px;
    left: 25px;

    @media (min-width: 1024px) {
        left: 5px;
    }

    @media (min-width: 1200px) {
        left: 15px;
    }
`;
