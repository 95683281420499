import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {Country} from "../../../region/types/Country";
import {offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectToProperSearchSlug =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug?: string; offerListSubType?: OfferListSubType; country?: string}>) => async (dispatch: Dispatch) => {
        const saveSearchResponseSlug = ctx.prevResult.saveSearchResponse?.slug;
        const friendlySlugFromRoute = ctx.match?.params.friendlySlug;
        const countryParam = ctx.match?.params.country;
        const offerListSubType = ctx.match.params.offerListSubType;

        // Redirect for abroad regions without the country in the URL
        if (!countryParam && ctx.prevResult.country && parseInt(ctx.prevResult.country) !== Country.POLAND) {
            const {friendlySlug, countrySlug} = offerUrlBuilder({
                region: ctx.prevResult.region_name,
                type: ctx.prevResult.type,
                country: parseInt(ctx.prevResult.country)
            });
            dispatch(
                enable301ResponseState(
                    rpAppLink.offer.listFriendlyAbroad({
                        friendlySlug: friendlySlug,
                        country: countrySlug,
                        abroadRegionSlug: ctx.prevResult.region_name
                    })
                )
            );
        }
        // redirect only if saveSearchResponseSlug not equal to friendlySlug from current url
        // skip redirect check if it is offer list's subtype
        if ((!countryParam || !offerListSubType) && saveSearchResponseSlug && saveSearchResponseSlug !== friendlySlugFromRoute) {
            dispatch(enable301ResponseState(rpAppLink.offer.listFriendly({friendlySlug: saveSearchResponseSlug})));

            return false;
        }

        return ctx.prevResult;
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {Country} from "../../../region/types/Country";
import {offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectToProperSearchSlug =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug?: string; offerListSubType?: OfferListSubType; country?: string}>) => async (dispatch: Dispatch) => {
        const saveSearchResponseSlug = ctx.prevResult.saveSearchResponse?.slug;
        const friendlySlugFromRoute = ctx.match?.params.friendlySlug;
        const countryParam = ctx.match?.params.country;
        const offerListSubType = ctx.match.params.offerListSubType;

        // Redirect for abroad regions without the country in the URL
        if (!countryParam && ctx.prevResult.country && parseInt(ctx.prevResult.country) !== Country.POLAND) {
            const {friendlySlug, countrySlug} = offerUrlBuilder({
                region: ctx.prevResult.region_name,
                type: ctx.prevResult.type,
                country: parseInt(ctx.prevResult.country)
            });
            dispatch(
                enable301ResponseState(
                    rpAppLink.offer.listFriendlyAbroad({
                        friendlySlug: friendlySlug,
                        country: countrySlug,
                        abroadRegionSlug: ctx.prevResult.region_name
                    })
                )
            );
        }
        // redirect only if saveSearchResponseSlug not equal to friendlySlug from current url
        // skip redirect check if it is offer list's subtype
        if ((!countryParam || !offerListSubType) && saveSearchResponseSlug && saveSearchResponseSlug !== friendlySlugFromRoute) {
            dispatch(enable301ResponseState(rpAppLink.offer.listFriendly({friendlySlug: saveSearchResponseSlug})));

            return false;
        }

        return ctx.prevResult;
    };
