import {houseFilterFriendlyQueryValues} from "../../../constants/house_filter_friendly_query_values";

export const isOfferListSubTypeValidHouseFilter = (offerListSubFilter?: string): boolean => {
    if (!offerListSubFilter) {
        return false;
    }

    return houseFilterFriendlyQueryValues.includes(offerListSubFilter);
};
import {houseFilterFriendlyQueryValues} from "../../../constants/house_filter_friendly_query_values";

export const isOfferListSubTypeValidHouseFilter = (offerListSubFilter?: string): boolean => {
    if (!offerListSubFilter) {
        return false;
    }

    return houseFilterFriendlyQueryValues.includes(offerListSubFilter);
};
