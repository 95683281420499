import {rpAppPath} from "@pg-mono/rp-routes";
import {initUserCom} from "@pg-mono/user-com";

import {userComAppSubdomain, userComB2BAppSubdomain, userComB2BId, userComId} from "../common/app/read_rp_environment_variables";

export const rpUserComInit = () => {
    let userComIdForInit = userComId;
    let userComAppSubdomainForInit = userComAppSubdomain;

    if (window.location.pathname.match(rpAppPath.forDevelopers.base) && userComB2BId && userComB2BAppSubdomain) {
        userComIdForInit = userComB2BId;
        userComAppSubdomainForInit = userComB2BAppSubdomain;
    }

    initUserCom({userComId: userComIdForInit, userComAppSubdomain: userComAppSubdomainForInit});
};
import {rpAppPath} from "@pg-mono/rp-routes";
import {initUserCom} from "@pg-mono/user-com";

import {userComAppSubdomain, userComB2BAppSubdomain, userComB2BId, userComId} from "../common/app/read_rp_environment_variables";

export const rpUserComInit = () => {
    let userComIdForInit = userComId;
    let userComAppSubdomainForInit = userComAppSubdomain;

    if (window.location.pathname.match(rpAppPath.forDevelopers.base) && userComB2BId && userComB2BAppSubdomain) {
        userComIdForInit = userComB2BId;
        userComAppSubdomainForInit = userComB2BAppSubdomain;
    }

    initUserCom({userComId: userComIdForInit, userComAppSubdomain: userComAppSubdomainForInit});
};
