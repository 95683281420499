type Multiplier = 0 | 0.5 | 1 | 2 | 3 | 4;

export const borderRadiusStyle = (borderRadiusValue: Multiplier | {mobile?: Multiplier; desktop?: Multiplier}) => {
    if (typeof borderRadiusValue === "number") {
        return {
            "--border-radius": borderRadiusValue
        };
    }

    return {
        "--border-radius": borderRadiusValue.mobile,
        "--border-radius-md": borderRadiusValue.desktop
    };
};
type Multiplier = 0 | 0.5 | 1 | 2 | 3 | 4;

export const borderRadiusStyle = (borderRadiusValue: Multiplier | {mobile?: Multiplier; desktop?: Multiplier}) => {
    if (typeof borderRadiusValue === "number") {
        return {
            "--border-radius": borderRadiusValue
        };
    }

    return {
        "--border-radius": borderRadiusValue.mobile,
        "--border-radius-md": borderRadiusValue.desktop
    };
};
