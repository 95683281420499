import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {IArticleListArticle} from "../../article/actions/fetch_article_list";
import {fetchArticleListByIdTypes} from "../../article/actions/fetch_article_list_by_ids";

export interface IArticleListStore {
    articles: IArticleListArticle[];
}

export const creditCalculatorReducer = combineReducers({
    articles: reduceResponseField<IArticleListArticle[]>(fetchArticleListByIdTypes, "articles", [])
});
import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {IArticleListArticle} from "../../article/actions/fetch_article_list";
import {fetchArticleListByIdTypes} from "../../article/actions/fetch_article_list_by_ids";

export interface IArticleListStore {
    articles: IArticleListArticle[];
}

export const creditCalculatorReducer = combineReducers({
    articles: reduceResponseField<IArticleListArticle[]>(fetchArticleListByIdTypes, "articles", [])
});
