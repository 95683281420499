import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";

import {IButtonSizeBrandIcon, IButtonSizePrimary} from "../components/Button";
import {ButtonVariant, ButtonVariantUnionType} from "./button_variants";

const SIZE_TO_HEIGHT_DICTIONARY = {
    big: "7.2rem",
    medium: "5.6rem",
    small: "4.8rem",
    "x-small": "4rem"
};

export const getButtonStyle = (variant: ButtonVariantUnionType, size: IButtonSizePrimary | IButtonSizeBrandIcon | undefined) => (theme: Theme) => {
    switch (variant) {
        case ButtonVariant.FILLED_PRIMARY: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.button.primaryLabelColor};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: ${theme.colors.background[100]};
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }

        case ButtonVariant.FILLED_SECONDARY: {
            return css`
                background-color: ${theme.colors.secondary};
                color: white;
                fill: white;
                border-color: ${theme.colors.secondary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: ${theme.colors.gray[700]};
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[300]};
                    fill: ${theme.colors.gray[300]};
                    ${elevation(0)};
                }
            `;
        }

        case ButtonVariant.OUTLINED_SECONDARY: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    background-color: ${theme.colors.primary};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.OUTLINED_SECONDARY_LIGHT: {
            return css`
                background-color: transparent;
                color: white;
                fill: white;
                border-color: white;
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    background-color: ${theme.colors.primary};
                    color: ${theme.colors.secondary};
                    fill: ${theme.colors.secondary};
                    border-color: ${theme.colors.secondary};

                    path {
                        fill: ${theme.colors.secondary};
                    }
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    path {
                        fill: ${theme.colors.gray[700]};
                    }
                }
            `;
        }

        case ButtonVariant.NONE_SECONDARY: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    color: ${theme.colors.highlight};
                    fill: ${theme.colors.highlight};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.BRAND_ICON: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;
                height: ${size === "medium" ? "4.8rem" : "6.4rem"};
            `;
        }

        case ButtonVariant.STRETCHED_PRIMARY: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};
                border-radius: 0;
                height: 6.4rem;
                padding: 2rem;
                width: 100%;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.HIGHLIGHT_PRIMARY: {
            return css`
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;
                background-color: transparent;
                height: 2.4rem;
                padding: 0.4rem;
                min-width: 6rem;

                overflow: hidden;
                background-image: linear-gradient(${theme.button.highlightColor}, ${theme.button.highlightColor} 30%, transparent 30%);
                background-repeat: no-repeat;
                background-position: 0 0.8em;
                position: relative;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};

                    &:before {
                        content: none;
                    }
                }
            `;
        }

        case ButtonVariant.MAP_TOOLTIP: {
            return css`
                color: white;
                fill: white;
                border-color: ${theme.colors.gray[700]};
                background-color: ${theme.colors.gray[700]};
                height: 2.4rem;
                min-width: 6.4rem;
                z-index: 2;
                border-radius: 0.8rem;
                padding: 1.6rem 0.96rem;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};

                    &:before {
                        content: none;
                    }
                }
            `;
        }

        case ButtonVariant.BANNER_PRIMARY: {
            return css`
                background-color: ${theme.colors.highlight};
                color: ${theme.button.primaryLabelColor};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.highlight};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                    color: ${theme.button.primaryLabelColor};
                }

                &:disabled {
                    background-color: ${theme.colors.background[100]};
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }

        default: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }
    }
};
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";

import {IButtonSizeBrandIcon, IButtonSizePrimary} from "../components/Button";
import {ButtonVariant, ButtonVariantUnionType} from "./button_variants";

const SIZE_TO_HEIGHT_DICTIONARY = {
    big: "7.2rem",
    medium: "5.6rem",
    small: "4.8rem",
    "x-small": "4rem"
};

export const getButtonStyle = (variant: ButtonVariantUnionType, size: IButtonSizePrimary | IButtonSizeBrandIcon | undefined) => (theme: Theme) => {
    switch (variant) {
        case ButtonVariant.FILLED_PRIMARY: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.button.primaryLabelColor};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: ${theme.colors.background[100]};
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }

        case ButtonVariant.FILLED_SECONDARY: {
            return css`
                background-color: ${theme.colors.secondary};
                color: white;
                fill: white;
                border-color: ${theme.colors.secondary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: ${theme.colors.gray[700]};
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[300]};
                    fill: ${theme.colors.gray[300]};
                    ${elevation(0)};
                }
            `;
        }

        case ButtonVariant.OUTLINED_SECONDARY: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.secondary};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    background-color: ${theme.colors.primary};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.OUTLINED_SECONDARY_LIGHT: {
            return css`
                background-color: transparent;
                color: white;
                fill: white;
                border-color: white;
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    background-color: ${theme.colors.primary};
                    color: ${theme.colors.secondary};
                    fill: ${theme.colors.secondary};
                    border-color: ${theme.colors.secondary};

                    path {
                        fill: ${theme.colors.secondary};
                    }
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    path {
                        fill: ${theme.colors.gray[700]};
                    }
                }
            `;
        }

        case ButtonVariant.NONE_SECONDARY: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    color: ${theme.colors.highlight};
                    fill: ${theme.colors.highlight};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: ${theme.colors.gray[700]};
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.BRAND_ICON: {
            return css`
                background-color: transparent;
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;
                height: ${size === "medium" ? "4.8rem" : "6.4rem"};
            `;
        }

        case ButtonVariant.STRETCHED_PRIMARY: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};
                border-radius: 0;
                height: 6.4rem;
                padding: 2rem;
                width: 100%;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                }
            `;
        }

        case ButtonVariant.HIGHLIGHT_PRIMARY: {
            return css`
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: transparent;
                background-color: transparent;
                height: 2.4rem;
                padding: 0.4rem;
                min-width: 6rem;

                overflow: hidden;
                background-image: linear-gradient(${theme.button.highlightColor}, ${theme.button.highlightColor} 30%, transparent 30%);
                background-repeat: no-repeat;
                background-position: 0 0.8em;
                position: relative;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};

                    &:before {
                        content: none;
                    }
                }
            `;
        }

        case ButtonVariant.MAP_TOOLTIP: {
            return css`
                color: white;
                fill: white;
                border-color: ${theme.colors.gray[700]};
                background-color: ${theme.colors.gray[700]};
                height: 2.4rem;
                min-width: 6.4rem;
                z-index: 2;
                border-radius: 0.8rem;
                padding: 1.6rem 0.96rem;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};

                    &:before {
                        content: none;
                    }
                }
            `;
        }

        case ButtonVariant.BANNER_PRIMARY: {
            return css`
                background-color: ${theme.colors.highlight};
                color: ${theme.button.primaryLabelColor};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.highlight};
                min-width: 9.6rem;

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                    color: ${theme.button.primaryLabelColor};
                }

                &:disabled {
                    background-color: ${theme.colors.background[100]};
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }

        default: {
            return css`
                background-color: ${theme.colors.primary};
                color: ${theme.colors.secondary};
                fill: ${theme.colors.secondary};
                border-color: ${theme.colors.primary};

                ${size &&
                css`
                    height: ${SIZE_TO_HEIGHT_DICTIONARY[size]};
                `}

                &:hover {
                    ${elevation(1)};
                }

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                    color: ${theme.colors.gray[700]};
                    fill: ${theme.colors.gray[700]};
                    ${elevation(0)};
                }
            `;
        }
    }
};
