import {prefixPath} from "@pg-mono/prefix-path";

export const storeApiPath = prefixPath("/ms/v1/", {
    store: prefixPath("store/", {
        base: "",
        userPreferences: "user-preferences/"
    })
});

export const storeApiLink = {
    store: {
        base: storeApiPath.store.base,
        userPreferences: storeApiPath.store.userPreferences
    }
};

export const userPreferencesRouterApiPath = prefixPath("/user-preferences", {
    base: ""
});

export const userPreferencesRouterApiLink = {
    userPreferences: userPreferencesRouterApiPath.base
};

export const userPreferencesApiLink = {
    userPreferences: {
        base: createLink(storeApiPath.store.userPreferences)
    }
};

function createLink(apiPathElement: string): (microserviceApiUrl: string | null) => string {
    return (microserviceApiUrl: string | null) => {
        // by default store microservice should use relative path with optional absolute path
        return `${microserviceApiUrl ?? ""}${apiPathElement}`;
    };
}
import {prefixPath} from "@pg-mono/prefix-path";

export const storeApiPath = prefixPath("/ms/v1/", {
    store: prefixPath("store/", {
        base: "",
        userPreferences: "user-preferences/"
    })
});

export const storeApiLink = {
    store: {
        base: storeApiPath.store.base,
        userPreferences: storeApiPath.store.userPreferences
    }
};

export const userPreferencesRouterApiPath = prefixPath("/user-preferences", {
    base: ""
});

export const userPreferencesRouterApiLink = {
    userPreferences: userPreferencesRouterApiPath.base
};

export const userPreferencesApiLink = {
    userPreferences: {
        base: createLink(storeApiPath.store.userPreferences)
    }
};

function createLink(apiPathElement: string): (microserviceApiUrl: string | null) => string {
    return (microserviceApiUrl: string | null) => {
        // by default store microservice should use relative path with optional absolute path
        return `${microserviceApiUrl ?? ""}${apiPathElement}`;
    };
}
