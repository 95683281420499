import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IOfferGroupsSimplified, StageStatus} from "../detail/types/IOfferGroups";
import {IOfferDetail} from "../types/IOfferDetail";

export type ICreateOfferLinkOfferParam = Pick<IOfferDetail, "id" | "slug"> & {
    groups?: IOfferGroupsSimplified | null;
    vendor: Pick<IOfferDetail["vendor"], "slug">;
};

export const OFFER_DETAIL_STAGE_QUERY_PARAM = "stage";
export const OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM = "show_sold_stage";

export const getOfferPrimaryStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.primary);
export const getOfferCurrentStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.current);
export const getStageByOfferId = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.offer.id === offer.id);
export const getSoldStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.filter((stage) => stage.status === StageStatus.SOLD);

export const getStageByStageId = (offer: ICreateOfferLinkOfferParam, stageId: number) => offer.groups?.stages?.find((stage) => stage.id === stageId);

export const createOfferLink = (offer: ICreateOfferLinkOfferParam, additionalParams: Record<string, string | number | boolean | undefined> = {}) => {
    const primaryStage = getOfferPrimaryStage(offer);
    const currentStage = getOfferCurrentStage(offer);
    const stageByOfferId = getStageByOfferId(offer);
    const soldStageOffer = getSoldStage(offer);

    const offerLink = rpAppLink.offer.detail.base(
        primaryStage
            ? {vendorSlug: primaryStage.offer.vendor.slug, offerSlug: primaryStage.offer.slug, offerId: primaryStage.offer.id}
            : {vendorSlug: offer.vendor.slug, offerSlug: offer.slug, offerId: offer.id}
    );

    return appendQueryString(offerLink, {
        ...(currentStage?.id !== stageByOfferId?.id ? {[OFFER_DETAIL_STAGE_QUERY_PARAM]: stageByOfferId?.id} : {}),
        ...(soldStageOffer?.some((stage) => stage.offer.id === offer.id) && currentStage?.id !== stageByOfferId?.id
            ? {[OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM]: true}
            : {}),

        ...additionalParams
    });
};
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IOfferGroupsSimplified, StageStatus} from "../detail/types/IOfferGroups";
import {IOfferDetail} from "../types/IOfferDetail";

export type ICreateOfferLinkOfferParam = Pick<IOfferDetail, "id" | "slug"> & {
    groups?: IOfferGroupsSimplified | null;
    vendor: Pick<IOfferDetail["vendor"], "slug">;
};

export const OFFER_DETAIL_STAGE_QUERY_PARAM = "stage";
export const OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM = "show_sold_stage";

export const getOfferPrimaryStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.primary);
export const getOfferCurrentStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.current);
export const getStageByOfferId = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.find((stage) => stage.offer.id === offer.id);
export const getSoldStage = (offer: ICreateOfferLinkOfferParam) => offer.groups?.stages?.filter((stage) => stage.status === StageStatus.SOLD);

export const getStageByStageId = (offer: ICreateOfferLinkOfferParam, stageId: number) => offer.groups?.stages?.find((stage) => stage.id === stageId);

export const createOfferLink = (offer: ICreateOfferLinkOfferParam, additionalParams: Record<string, string | number | boolean | undefined> = {}) => {
    const primaryStage = getOfferPrimaryStage(offer);
    const currentStage = getOfferCurrentStage(offer);
    const stageByOfferId = getStageByOfferId(offer);
    const soldStageOffer = getSoldStage(offer);

    const offerLink = rpAppLink.offer.detail.base(
        primaryStage
            ? {vendorSlug: primaryStage.offer.vendor.slug, offerSlug: primaryStage.offer.slug, offerId: primaryStage.offer.id}
            : {vendorSlug: offer.vendor.slug, offerSlug: offer.slug, offerId: offer.id}
    );

    return appendQueryString(offerLink, {
        ...(currentStage?.id !== stageByOfferId?.id ? {[OFFER_DETAIL_STAGE_QUERY_PARAM]: stageByOfferId?.id} : {}),
        ...(soldStageOffer?.some((stage) => stage.offer.id === offer.id) && currentStage?.id !== stageByOfferId?.id
            ? {[OFFER_DETAIL_ALLOW_SHOW_SOLD_STAGE_PARAM]: true}
            : {}),

        ...additionalParams
    });
};
