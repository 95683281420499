import {prefixPath} from "@pg-mono/prefix-path";

import {BIG_DATA_API_PREFIX} from "./api_prefixes";

export const apiPath = prefixPath(BIG_DATA_API_PREFIX, {
    monthCityStats: prefixPath("month-city-stats/", {
        base: ""
    }),
    monthCityTypeStats: prefixPath("month-city-type-stats/", {
        base: ""
    }),
    monthDistrictStats: prefixPath("month-district-stats/", {
        base: ""
    }),
    monthDistrictTypeStats: prefixPath("month-district-type-stats/", {
        base: ""
    })
});

export const apiBigDataLink = {
    monthCityStats: {
        base: createLinkBd(apiPath.monthCityStats.base)
    },
    monthCityTypeStats: {
        base: createLinkBd(apiPath.monthCityTypeStats.base)
    },
    monthDistrictsStats: {
        base: createLinkBd(apiPath.monthDistrictStats.base)
    },
    monthDistrictTypeStats: {
        base: createLinkBd(apiPath.monthDistrictTypeStats.base)
    }
};

function createLinkBd(apiPathElement: string): (bigDataApiPath: string | null) => string {
    return (bigDataApiPath: string | null) => {
        if (bigDataApiPath) {
            return `${bigDataApiPath}${apiPathElement}`;
        }
        throw new Error("BigData API url not defined");
    };
}
import {prefixPath} from "@pg-mono/prefix-path";

import {BIG_DATA_API_PREFIX} from "./api_prefixes";

export const apiPath = prefixPath(BIG_DATA_API_PREFIX, {
    monthCityStats: prefixPath("month-city-stats/", {
        base: ""
    }),
    monthCityTypeStats: prefixPath("month-city-type-stats/", {
        base: ""
    }),
    monthDistrictStats: prefixPath("month-district-stats/", {
        base: ""
    }),
    monthDistrictTypeStats: prefixPath("month-district-type-stats/", {
        base: ""
    })
});

export const apiBigDataLink = {
    monthCityStats: {
        base: createLinkBd(apiPath.monthCityStats.base)
    },
    monthCityTypeStats: {
        base: createLinkBd(apiPath.monthCityTypeStats.base)
    },
    monthDistrictsStats: {
        base: createLinkBd(apiPath.monthDistrictStats.base)
    },
    monthDistrictTypeStats: {
        base: createLinkBd(apiPath.monthDistrictTypeStats.base)
    }
};

function createLinkBd(apiPathElement: string): (bigDataApiPath: string | null) => string {
    return (bigDataApiPath: string | null) => {
        if (bigDataApiPath) {
            return `${bigDataApiPath}${apiPathElement}`;
        }
        throw new Error("BigData API url not defined");
    };
}
