import React, {Fragment} from "react";

import {Footer} from "../../navigation/components/Footer";
import {Navigation} from "../../navigation/components/Navigation";
import {ErrorView} from "./ErrorView";

export const ErrorViewLayout = () => {
    return (
        <Fragment>
            <Navigation />
            <ErrorView />
            <Footer />
        </Fragment>
    );
};
import React, {Fragment} from "react";

import {Footer} from "../../navigation/components/Footer";
import {Navigation} from "../../navigation/components/Navigation";
import {ErrorView} from "./ErrorView";

export const ErrorViewLayout = () => {
    return (
        <Fragment>
            <Navigation />
            <ErrorView />
            <Footer />
        </Fragment>
    );
};
