import {css} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    htmlFor?: string;
    label: string;
}
export const SearchFilterLabel = (props: IProps) => {
    return (
        <label htmlFor={props.htmlFor}>
            <Text variant="body_copy_1" css={[filterLabelText, mb(2)]}>
                {props.label}
            </Text>
        </label>
    );
};

export const filterLabelText = css`
    padding-right: 1rem;
`;
import {css} from "@emotion/react";

import {mb} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text-module";

interface IProps {
    htmlFor?: string;
    label: string;
}
export const SearchFilterLabel = (props: IProps) => {
    return (
        <label htmlFor={props.htmlFor}>
            <Text variant="body_copy_1" css={[filterLabelText, mb(2)]}>
                {props.label}
            </Text>
        </label>
    );
};

export const filterLabelText = css`
    padding-right: 1rem;
`;
