import React from "react";
import {Props as ReactModalProps} from "react-modal";
import classNames from "classnames";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {modalAlignStyle} from "./styles/modal_align_style";
import {modalValignStyle} from "./styles/modal_valign_style";

import * as styles from "./OverlayElement.module.css";

export const OverlayElement =
    (baseModalProps: IModalProps): ReactModalProps["overlayElement"] =>
    (props, children) => {
        const {align, vAlign, modalTheme, zIndex: zIndexValue} = getPropsWithDefault(baseModalProps);

        const cn = classNames(
            props.className,
            styles.overlayStyle,
            modalTheme === "light" && styles.overlayStyleLight,
            modalAlignStyle(align),
            modalValignStyle(vAlign)
        );

        return (
            <div {...props} className={cn} style={{zIndex: zIndexValue}}>
                {children}
            </div>
        );
    };
import React from "react";
import {Props as ReactModalProps} from "react-modal";
import classNames from "classnames";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {modalAlignStyle} from "./styles/modal_align_style";
import {modalValignStyle} from "./styles/modal_valign_style";

import * as styles from "./OverlayElement.module.css";

export const OverlayElement =
    (baseModalProps: IModalProps): ReactModalProps["overlayElement"] =>
    (props, children) => {
        const {align, vAlign, modalTheme, zIndex: zIndexValue} = getPropsWithDefault(baseModalProps);

        const cn = classNames(
            props.className,
            styles.overlayStyle,
            modalTheme === "light" && styles.overlayStyleLight,
            modalAlignStyle(align),
            modalValignStyle(vAlign)
        );

        return (
            <div {...props} className={cn} style={{zIndex: zIndexValue}}>
                {children}
            </div>
        );
    };
