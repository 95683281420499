import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {INotification, Notification} from "./Notification";

export interface IProps {
    notifications: INotification[];
    removeNotification: (id: string) => void;
    inset?: string;
    zIndex?: number;
}

export const Notifications = (props: IProps) => {
    const {notifications, removeNotification, inset, zIndex} = props;
    const insetCss = inset ? inset : "1.8rem 1.8rem auto auto";
    const zIndexCss = zIndex ? zIndex : 10000;

    return (
        <NotificationsWrap inset={insetCss} zIndex={zIndexCss}>
            {notifications.map((notification) => {
                return (
                    <Notification
                        key={notification.id}
                        notificationData={{...notification}}
                        removeNotificationFromStore={() => {
                            removeNotification(notification.id);
                        }}
                    />
                );
            })}
        </NotificationsWrap>
    );
};

const NotificationsWrap = styled.ul<{inset: string; zIndex: number}>`
    position: fixed;
    list-style-type: none;
    padding: 0;

    ${({inset, zIndex}) => css`
        inset: ${inset};
        z-index: ${zIndex};
    `}
`;
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {INotification, Notification} from "./Notification";

export interface IProps {
    notifications: INotification[];
    removeNotification: (id: string) => void;
    inset?: string;
    zIndex?: number;
}

export const Notifications = (props: IProps) => {
    const {notifications, removeNotification, inset, zIndex} = props;
    const insetCss = inset ? inset : "1.8rem 1.8rem auto auto";
    const zIndexCss = zIndex ? zIndex : 10000;

    return (
        <NotificationsWrap inset={insetCss} zIndex={zIndexCss}>
            {notifications.map((notification) => {
                return (
                    <Notification
                        key={notification.id}
                        notificationData={{...notification}}
                        removeNotificationFromStore={() => {
                            removeNotification(notification.id);
                        }}
                    />
                );
            })}
        </NotificationsWrap>
    );
};

const NotificationsWrap = styled.ul<{inset: string; zIndex: number}>`
    position: fixed;
    list-style-type: none;
    padding: 0;

    ${({inset, zIndex}) => css`
        inset: ${inset};
        z-index: ${zIndex};
    `}
`;
