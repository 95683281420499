import {
    DISABLE_RESPONSE_STATE,
    ENABLE_301_RESPONSE_STATE,
    ENABLE_302_RESPONSE_STATE,
    ENABLE_404_RESPONSE_STATE,
    ENABLE_ERROR_RESPONSE_STATE,
    IActionResponseState
} from "./response_state_actions";
import {ResponseState} from "./ResponseState";

export interface IResponseStateStore {
    state: ResponseState;
    url: string;
    status: number;
}

const initStore: IResponseStateStore = {state: ResponseState.DEFAULT, status: 200, url: ""};

export const responseStateReducer = (state: IResponseStateStore = initStore, action: IActionResponseState): IResponseStateStore => {
    switch (action.type) {
        case ENABLE_301_RESPONSE_STATE:
            return {state: ResponseState.REDIRECT_301, status: 301, url: action.url};
        case ENABLE_302_RESPONSE_STATE:
            return {state: ResponseState.REDIRECT_302, status: 302, url: action.url};
        case ENABLE_404_RESPONSE_STATE:
            return {state: ResponseState.STATE_404, status: 404, url: ""};
        case ENABLE_ERROR_RESPONSE_STATE:
            return {state: ResponseState.STATE_ERROR, status: action.status, url: ""};
        case DISABLE_RESPONSE_STATE:
            return {state: ResponseState.DEFAULT, status: 200, url: ""};
        default:
            return state;
    }
};
import {
    DISABLE_RESPONSE_STATE,
    ENABLE_301_RESPONSE_STATE,
    ENABLE_302_RESPONSE_STATE,
    ENABLE_404_RESPONSE_STATE,
    ENABLE_ERROR_RESPONSE_STATE,
    IActionResponseState
} from "./response_state_actions";
import {ResponseState} from "./ResponseState";

export interface IResponseStateStore {
    state: ResponseState;
    url: string;
    status: number;
}

const initStore: IResponseStateStore = {state: ResponseState.DEFAULT, status: 200, url: ""};

export const responseStateReducer = (state: IResponseStateStore = initStore, action: IActionResponseState): IResponseStateStore => {
    switch (action.type) {
        case ENABLE_301_RESPONSE_STATE:
            return {state: ResponseState.REDIRECT_301, status: 301, url: action.url};
        case ENABLE_302_RESPONSE_STATE:
            return {state: ResponseState.REDIRECT_302, status: 302, url: action.url};
        case ENABLE_404_RESPONSE_STATE:
            return {state: ResponseState.STATE_404, status: 404, url: ""};
        case ENABLE_ERROR_RESPONSE_STATE:
            return {state: ResponseState.STATE_ERROR, status: action.status, url: ""};
        case DISABLE_RESPONSE_STATE:
            return {state: ResponseState.DEFAULT, status: 200, url: ""};
        default:
            return state;
    }
};
