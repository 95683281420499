import {createFavouriteReducer} from "@pg-mono/favourites";

import {favouriteTypes} from "../actions/manage_favourites";

export const favouriteReducer = createFavouriteReducer(favouriteTypes);
import {createFavouriteReducer} from "@pg-mono/favourites";

import {favouriteTypes} from "../actions/manage_favourites";

export const favouriteReducer = createFavouriteReducer(favouriteTypes);
