export enum OfferType {
    FLAT = 1,
    HOUSE = 2,
    COMMERCIAL = 3,
    MODULAR_HOUSE = 4
}
export enum OfferType {
    FLAT = 1,
    HOUSE = 2,
    COMMERCIAL = 3,
    MODULAR_HOUSE = 4
}
