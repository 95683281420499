import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";

import {usePrevious} from "@pg-mono/hooks";

import {dropAllTtlAndRemoveInvalid} from "../state/notifications_slice";

/*
    Hook removes all notifications in case current user location changes.
 */

export const useNotificationsRouteHandler = () => {
    const location = useLocation();
    const prevLocationKey = usePrevious(location.key, null, [location.key]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevLocationKey !== location.key) {
            dispatch(dropAllTtlAndRemoveInvalid());
        }
    }, [location.key]);

    useEffect(() => {
        return () => {
            dispatch(dropAllTtlAndRemoveInvalid());
        };
    }, []);
};
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";

import {usePrevious} from "@pg-mono/hooks";

import {dropAllTtlAndRemoveInvalid} from "../state/notifications_slice";

/*
    Hook removes all notifications in case current user location changes.
 */

export const useNotificationsRouteHandler = () => {
    const location = useLocation();
    const prevLocationKey = usePrevious(location.key, null, [location.key]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (prevLocationKey !== location.key) {
            dispatch(dropAllTtlAndRemoveInvalid());
        }
    }, [location.key]);

    useEffect(() => {
        return () => {
            dispatch(dropAllTtlAndRemoveInvalid());
        };
    }, []);
};
