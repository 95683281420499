import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {catch400, catch404, getRequest, Response400} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

export interface ISitemapArticleCategory {
    id: number;
    name: string;
    slug: string;
}

export interface ISiteMapArticleCategoryResponse extends IPaginatedResponse {
    results: ISitemapArticleCategory[];
}

// Categories ordered by SEO to be excluded.
const excludedCategories = [27, 29, 2, 26];

const FETCH_ARTICLE_CATEGORIES = "SITE_MAP/ARTICLE/CATEGORIES";
export const fetchArticleCategoriesActionTypes = createRequestActionTypes(FETCH_ARTICLE_CATEGORIES);

const articleCategoryApiLink = apiV2ListLink.article.category(Scenario.SITEMAP_ARTICLE_CATEGORIES);

export const fetchSiteMapArticleCategories = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleCategoriesActionTypes.start});

    return getRequest(ctx.meta, articleCategoryApiLink)
        .then((json: ISiteMapArticleCategoryResponse) => {
            const result = json.results.filter((category) => !excludedCategories.includes(category.id));
            dispatch({type: fetchArticleCategoriesActionTypes.success, result});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        )
        .catch(
            catch400(async (err) => {
                await dispatch(handleError400OnSiteMapArticleList(ctx, err));
            })
        );
};

const handleError400OnSiteMapArticleList = (ctx: IFetchContext<IRPRequestMeta>, err: Response400) => async (dispatch: Dispatch) => {
    notifyBugsnag({message: `Error 400 fetching sitemap category`}, err.message);
    consoleError(`Error 400 fetching sitemap category`, "; route: ", ctx.route, "; API error.message: ", err.message);
    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
    return false;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {catch400, catch404, getRequest, Response400} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

export interface ISitemapArticleCategory {
    id: number;
    name: string;
    slug: string;
}

export interface ISiteMapArticleCategoryResponse extends IPaginatedResponse {
    results: ISitemapArticleCategory[];
}

// Categories ordered by SEO to be excluded.
const excludedCategories = [27, 29, 2, 26];

const FETCH_ARTICLE_CATEGORIES = "SITE_MAP/ARTICLE/CATEGORIES";
export const fetchArticleCategoriesActionTypes = createRequestActionTypes(FETCH_ARTICLE_CATEGORIES);

const articleCategoryApiLink = apiV2ListLink.article.category(Scenario.SITEMAP_ARTICLE_CATEGORIES);

export const fetchSiteMapArticleCategories = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleCategoriesActionTypes.start});

    return getRequest(ctx.meta, articleCategoryApiLink)
        .then((json: ISiteMapArticleCategoryResponse) => {
            const result = json.results.filter((category) => !excludedCategories.includes(category.id));
            dispatch({type: fetchArticleCategoriesActionTypes.success, result});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        )
        .catch(
            catch400(async (err) => {
                await dispatch(handleError400OnSiteMapArticleList(ctx, err));
            })
        );
};

const handleError400OnSiteMapArticleList = (ctx: IFetchContext<IRPRequestMeta>, err: Response400) => async (dispatch: Dispatch) => {
    notifyBugsnag({message: `Error 400 fetching sitemap category`}, err.message);
    consoleError(`Error 400 fetching sitemap category`, "; route: ", ctx.route, "; API error.message: ", err.message);
    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
    return false;
};
