import {useGeolocation} from "@pg-mono/geo-utils";

import {CoordinatesSourceType, HitGeolocationEvent} from "../analytics/hit_geolocation_event";
import {useGeolocationStorage} from "./use_geolocation_storage";

export const UseGeolocationWithAlgolytics = () => {
    const {locationAsked, checkGeolocationStorage, saveGeolocationStorage} = useGeolocationStorage();
    const {locateUser} = useGeolocation();

    const hitEventAndSaveLocationAsk = (position?: GeolocationCoordinates) => {
        const canHitEvent = !checkGeolocationStorage();
        if (position && canHitEvent) {
            HitGeolocationEvent({
                latitude: position.latitude,
                longitude: position.longitude,
                accuracy: position.accuracy,
                type: CoordinatesSourceType.LOCATION
            });
        }

        saveGeolocationStorage();
    };

    const onSuccess = (position: GeolocationCoordinates) => {
        hitEventAndSaveLocationAsk(position);
    };

    const onError = () => {
        //TODO add algolytics hit with data based on IP, to do in future after consultation with the analysts
        hitEventAndSaveLocationAsk();
    };

    return {locationAsked, locateUser: () => locateUser(onSuccess, onError)};
};
import {useGeolocation} from "@pg-mono/geo-utils";

import {CoordinatesSourceType, HitGeolocationEvent} from "../analytics/hit_geolocation_event";
import {useGeolocationStorage} from "./use_geolocation_storage";

export const UseGeolocationWithAlgolytics = () => {
    const {locationAsked, checkGeolocationStorage, saveGeolocationStorage} = useGeolocationStorage();
    const {locateUser} = useGeolocation();

    const hitEventAndSaveLocationAsk = (position?: GeolocationCoordinates) => {
        const canHitEvent = !checkGeolocationStorage();
        if (position && canHitEvent) {
            HitGeolocationEvent({
                latitude: position.latitude,
                longitude: position.longitude,
                accuracy: position.accuracy,
                type: CoordinatesSourceType.LOCATION
            });
        }

        saveGeolocationStorage();
    };

    const onSuccess = (position: GeolocationCoordinates) => {
        hitEventAndSaveLocationAsk(position);
    };

    const onError = () => {
        //TODO add algolytics hit with data based on IP, to do in future after consultation with the analysts
        hitEventAndSaveLocationAsk();
    };

    return {locationAsked, locateUser: () => locateUser(onSuccess, onError)};
};
