import {createFormActions, createFormActionTypes} from "@pg-mono/form";

const INVESTMENT_OFFER_FILTER_FORM_ACTION = "investment_offer_filters/FORM";
export const investmentOfferListFilterFormActionTypes = createFormActionTypes(INVESTMENT_OFFER_FILTER_FORM_ACTION);
export const investmentOfferListFilterFormActions = createFormActions(investmentOfferListFilterFormActionTypes);
import {createFormActions, createFormActionTypes} from "@pg-mono/form";

const INVESTMENT_OFFER_FILTER_FORM_ACTION = "investment_offer_filters/FORM";
export const investmentOfferListFilterFormActionTypes = createFormActionTypes(INVESTMENT_OFFER_FILTER_FORM_ACTION);
export const investmentOfferListFilterFormActions = createFormActions(investmentOfferListFilterFormActionTypes);
