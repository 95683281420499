import {reduceReducers, reduceResponse} from "@pg-mono/request-state";

import {fetchUserProfileRequestTypes} from "../../user/actions/fetch_user_profile";
import {patchUserProfileRequestType} from "../../user/actions/patch_user_profile";
import {fetchIsAuthenticatedTypes} from "../actions/fetch_is_authenticated";

export interface IIsAuthenticatedStore {
    isAuthenticated: boolean;
}

export const isAuthenticatedReducer = reduceReducers(
    reduceResponse<boolean>(fetchIsAuthenticatedTypes, false),
    // Other user related actions which may affect the user's auth status
    (state: boolean, action: {type: string}) => {
        switch (action.type) {
            case fetchUserProfileRequestTypes.success:
                return true;
            case fetchUserProfileRequestTypes.error:
            case fetchUserProfileRequestTypes.reset:
            case patchUserProfileRequestType.error:
                return false;
            default:
                return state;
        }
    }
);
import {reduceReducers, reduceResponse} from "@pg-mono/request-state";

import {fetchUserProfileRequestTypes} from "../../user/actions/fetch_user_profile";
import {patchUserProfileRequestType} from "../../user/actions/patch_user_profile";
import {fetchIsAuthenticatedTypes} from "../actions/fetch_is_authenticated";

export interface IIsAuthenticatedStore {
    isAuthenticated: boolean;
}

export const isAuthenticatedReducer = reduceReducers(
    reduceResponse<boolean>(fetchIsAuthenticatedTypes, false),
    // Other user related actions which may affect the user's auth status
    (state: boolean, action: {type: string}) => {
        switch (action.type) {
            case fetchUserProfileRequestTypes.success:
                return true;
            case fetchUserProfileRequestTypes.error:
            case fetchUserProfileRequestTypes.reset:
            case patchUserProfileRequestType.error:
                return false;
            default:
                return state;
        }
    }
);
