import {combineReducers} from "redux";

import {IUnsubscribeUserStore, unsubscribeUserReducer} from "./unsubscribe_user_reducer";

export interface INewsletterStore {
    unsubscribe: IUnsubscribeUserStore;
}

export const newsletterReducer = combineReducers({
    unsubscribe: unsubscribeUserReducer
});
import {combineReducers} from "redux";

import {IUnsubscribeUserStore, unsubscribeUserReducer} from "./unsubscribe_user_reducer";

export interface INewsletterStore {
    unsubscribe: IUnsubscribeUserStore;
}

export const newsletterReducer = combineReducers({
    unsubscribe: unsubscribeUserReducer
});
