export const aggregationToCityMap = new Map([
    [52253, 8647], // Warszawa i okolice, Warszawa
    [52362, 22550], // Bydgoszcz i okolice, Bydgoszcz
    [52261, 31600], // Katowice i okolice, Katowice
    [52258, 11158], // Kraków i okolice, Kraków
    [52259, 16187], // Łódź i okolice, Łódź
    [52366, 13220], // Lublin i okolice, Lublin
    [52364, 26804], // Olsztyn i okolice, Olsztyn
    [52254, 30892], // Poznań i okolice, Poznań
    [52260, 32657], // Szczecin i okolice, Szczecin
    [52256, 52255], // Trójmiasto i okolice, Trójmiasto
    [52257, 26955] // Wrocław i okolice, Wrocław
]);
export const aggregationToCityMap = new Map([
    [52253, 8647], // Warszawa i okolice, Warszawa
    [52362, 22550], // Bydgoszcz i okolice, Bydgoszcz
    [52261, 31600], // Katowice i okolice, Katowice
    [52258, 11158], // Kraków i okolice, Kraków
    [52259, 16187], // Łódź i okolice, Łódź
    [52366, 13220], // Lublin i okolice, Lublin
    [52364, 26804], // Olsztyn i okolice, Olsztyn
    [52254, 30892], // Poznań i okolice, Poznań
    [52260, 32657], // Szczecin i okolice, Szczecin
    [52256, 52255], // Trójmiasto i okolice, Trójmiasto
    [52257, 26955] // Wrocław i okolice, Wrocław
]);
