export const isIOS = (userAgent = ""): boolean | null => {
    if (!userAgent) {
        // UserAgent string not provided - we are not able to determine device type
        return null;
    }

    return /iPad|iPhone|iPod/.test(userAgent);
};
export const isIOS = (userAgent = ""): boolean | null => {
    if (!userAgent) {
        // UserAgent string not provided - we are not able to determine device type
        return null;
    }

    return /iPad|iPhone|iPod/.test(userAgent);
};
