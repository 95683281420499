import {getRegionDetailListBySlug} from "../../region/api/get_region_detail_list_by_slug";
import {IAppDispatch} from "../../store/store";
import {IRankingCity} from "../types/IRankingCity";

export async function getRankingRegionAndValidate(citySlug: string | undefined, cityList: IRankingCity[], dispatch: IAppDispatch) {
    if (!citySlug) {
        return false;
    }

    if (!cityList.some((item) => item.slug === citySlug)) {
        return false;
    }

    const region = await dispatch(getRegionDetailListBySlug.endpoints.getRegionDetailListBySlug.initiate({slug: citySlug})).unwrap();
    if (!region) {
        return false;
    }

    return region.results[0];
}
import {getRegionDetailListBySlug} from "../../region/api/get_region_detail_list_by_slug";
import {IAppDispatch} from "../../store/store";
import {IRankingCity} from "../types/IRankingCity";

export async function getRankingRegionAndValidate(citySlug: string | undefined, cityList: IRankingCity[], dispatch: IAppDispatch) {
    if (!citySlug) {
        return false;
    }

    if (!cityList.some((item) => item.slug === citySlug)) {
        return false;
    }

    const region = await dispatch(getRegionDetailListBySlug.endpoints.getRegionDetailListBySlug.initiate({slug: citySlug})).unwrap();
    if (!region) {
        return false;
    }

    return region.results[0];
}
