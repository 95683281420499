import {css} from "@emotion/react";

export const tippyPopoverStyles = css`
    .tippy-box[data-state="hidden"] {
        opacity: 0;
    }

    .tippy-box[data-state="hidden"][data-placement^="top"] {
        transform: translateY(-10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="bottom"] {
        transform: translateY(10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="left"] {
        transform: translateX(-10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="right"] {
        transform: translateX(10px);
    }

    .tippy-box[data-placement^="top"] > .tippy-arrow {
        bottom: 4px;
    }

    .tippy-box[data-placement^="bottom"] > .tippy-arrow {
        top: -4px;
    }

    .tippy-box[data-placement^="left"] > .tippy-arrow {
        right: 4px;
    }

    .tippy-box[data-placement^="right"] > .tippy-arrow {
        left: -4px;
    }

    .tippy-arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        content: "";
        transform: rotate(45deg);
        background: rgb(67, 68, 77);
    }
`;
import {css} from "@emotion/react";

export const tippyPopoverStyles = css`
    .tippy-box[data-state="hidden"] {
        opacity: 0;
    }

    .tippy-box[data-state="hidden"][data-placement^="top"] {
        transform: translateY(-10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="bottom"] {
        transform: translateY(10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="left"] {
        transform: translateX(-10px);
    }

    .tippy-box[data-state="hidden"][data-placement^="right"] {
        transform: translateX(10px);
    }

    .tippy-box[data-placement^="top"] > .tippy-arrow {
        bottom: 4px;
    }

    .tippy-box[data-placement^="bottom"] > .tippy-arrow {
        top: -4px;
    }

    .tippy-box[data-placement^="left"] > .tippy-arrow {
        right: 4px;
    }

    .tippy-box[data-placement^="right"] > .tippy-arrow {
        left: -4px;
    }

    .tippy-arrow::before {
        position: absolute;
        width: 8px;
        height: 8px;
        content: "";
        transform: rotate(45deg);
        background: rgb(67, 68, 77);
    }
`;
