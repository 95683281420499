import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {RegionType} from "../../../region/types/RegionType";
import {IVoivodeshipRegion} from "./fetch_offer_list_recommended_regions";

const FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_NEARBY_REGIONS";
export const fetchOfferListNearbyRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX);

interface IOfferListNearbyRegionsResponse {
    results: IOfferListNearbyRegion[];
}

export interface IOfferListNearbyRegion {
    name: string;
    slug: string;
    id: number;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
    type: RegionType;
}

export const FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES = "offerlist/FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES";
export const fetchOfferListVoivodeshipTopCitiesTypes = createRequestActionTypes(FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES);

const fetchOfferListVoivodeshipTopCities =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return;
        }
        const offerType = getState().offerList.list.latestQuery.type;
        const query = {offer_type: offerType, regionId: region[0]};

        if (isEqual(query, getState().offerList.voivodeshipTopCitiesLatestQuery)) {
            return getState().offerList.voivodeshipTopCities;
        }

        dispatch({type: actionType.start, latestQuery: query});

        const url = appendQueryString(apiV2Link.region.voivodeship({regionId: region[0]}), query);
        return getRequest(ctx.meta, url)
            .then((res: {results: IVoivodeshipRegion[]}) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch((error) => {
                dispatch({type: actionType.error, error});
                return [];
            });
    };

const fetchOfferListNearbyRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return false;
        }

        const query = {children_regions: region[0]};
        if (isEqual(query, getState().offerList.nearbyRegionsLatestQuery)) {
            return getState().offerList.nearbyRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
        const url = appendQueryString(nearbyRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListNearbyRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListNearbyRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const selectedRegions = getState().offerList.selectedRegions;
    if (selectedRegions.length > 0) {
        const regionType = selectedRegions[0].type;
        if (regionType === RegionType.VOIVODESHIP) {
            return await dispatch(fetchOfferListVoivodeshipTopCities(fetchOfferListVoivodeshipTopCitiesTypes)(ctx));
        }
    }

    return await dispatch(fetchOfferListNearbyRegions(fetchOfferListNearbyRegionsTypes)(ctx));
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {RegionType} from "../../../region/types/RegionType";
import {IVoivodeshipRegion} from "./fetch_offer_list_recommended_regions";

const FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_NEARBY_REGIONS";
export const fetchOfferListNearbyRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_NEARBY_REGIONS_PREFIX);

interface IOfferListNearbyRegionsResponse {
    results: IOfferListNearbyRegion[];
}

export interface IOfferListNearbyRegion {
    name: string;
    slug: string;
    id: number;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
    type: RegionType;
}

export const FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES = "offerlist/FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES";
export const fetchOfferListVoivodeshipTopCitiesTypes = createRequestActionTypes(FETCH_OFFER_LIST_VOIVODESHIP_TOP_CITIES);

const fetchOfferListVoivodeshipTopCities =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return;
        }
        const offerType = getState().offerList.list.latestQuery.type;
        const query = {offer_type: offerType, regionId: region[0]};

        if (isEqual(query, getState().offerList.voivodeshipTopCitiesLatestQuery)) {
            return getState().offerList.voivodeshipTopCities;
        }

        dispatch({type: actionType.start, latestQuery: query});

        const url = appendQueryString(apiV2Link.region.voivodeship({regionId: region[0]}), query);
        return getRequest(ctx.meta, url)
            .then((res: {results: IVoivodeshipRegion[]}) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch((error) => {
                dispatch({type: actionType.error, error});
                return [];
            });
    };

const fetchOfferListNearbyRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        if (!region) {
            return false;
        }

        const query = {children_regions: region[0]};
        if (isEqual(query, getState().offerList.nearbyRegionsLatestQuery)) {
            return getState().offerList.nearbyRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const nearbyRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
        const url = appendQueryString(nearbyRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListNearbyRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListNearbyRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const selectedRegions = getState().offerList.selectedRegions;
    if (selectedRegions.length > 0) {
        const regionType = selectedRegions[0].type;
        if (regionType === RegionType.VOIVODESHIP) {
            return await dispatch(fetchOfferListVoivodeshipTopCities(fetchOfferListVoivodeshipTopCitiesTypes)(ctx));
        }
    }

    return await dispatch(fetchOfferListNearbyRegions(fetchOfferListNearbyRegionsTypes)(ctx));
};
