import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchArticleDetailById} from "../../article/api/fetch_article_detail_by_id";

export const redirectArticlePermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const articleId = parseInt(ctx.match.params.articleId as string, 10);

    if (isFinite(articleId)) {
        const article = await fetchArticleDetailById(ctx.meta, articleId);

        if (article) {
            const redirectUrl = appendQueryString(rpAppLink.article.detail({articleId: article.id, articleSlug: article.slug}), ctx.route.query);

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchArticleDetailById} from "../../article/api/fetch_article_detail_by_id";

export const redirectArticlePermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const articleId = parseInt(ctx.match.params.articleId as string, 10);

    if (isFinite(articleId)) {
        const article = await fetchArticleDetailById(ctx.meta, articleId);

        if (article) {
            const redirectUrl = appendQueryString(rpAppLink.article.detail({articleId: article.id, articleSlug: article.slug}), ctx.route.query);

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
