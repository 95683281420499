import {css, SerializedStyles} from "@emotion/react";

const MOBILE_BREAKPOINT = "1024px";

// onMobile is a helper using @media min-width 1024px breakpoint
// it accepts css helpers as rest params i.e: onDesktop(p(1), ml(1))
export const onDesktop = (...helpers: SerializedStyles[]) => css`
    @media (min-width: ${MOBILE_BREAKPOINT}) {
        ${helpers}
    }
`;
import {css, SerializedStyles} from "@emotion/react";

const MOBILE_BREAKPOINT = "1024px";

// onMobile is a helper using @media min-width 1024px breakpoint
// it accepts css helpers as rest params i.e: onDesktop(p(1), ml(1))
export const onDesktop = (...helpers: SerializedStyles[]) => css`
    @media (min-width: ${MOBILE_BREAKPOINT}) {
        ${helpers}
    }
`;
