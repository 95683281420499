import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchVendorListRegion} from "./fetch_vendor_list_region";

export const redirectToFriendlyVendorUrl = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const regionId = parseInt(ctx.route.query.region as string, 10);
    if (Number.isFinite(regionId)) {
        const region = await dispatch(fetchVendorListRegion(ctx, regionId));
        if (region) {
            const restQueryValues = omit(ctx.route.query, ["region"]) as Record<string, string>;
            const url = appendQueryString(rpAppLink.vendor.listFriendly({friendlySlug: region.slug}), restQueryValues);
            dispatch(enable301ResponseState(url));
            return false;
        }
    }
    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchVendorListRegion} from "./fetch_vendor_list_region";

export const redirectToFriendlyVendorUrl = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const regionId = parseInt(ctx.route.query.region as string, 10);
    if (Number.isFinite(regionId)) {
        const region = await dispatch(fetchVendorListRegion(ctx, regionId));
        if (region) {
            const restQueryValues = omit(ctx.route.query, ["region"]) as Record<string, string>;
            const url = appendQueryString(rpAppLink.vendor.listFriendly({friendlySlug: region.slug}), restQueryValues);
            dispatch(enable301ResponseState(url));
            return false;
        }
    }
    return true;
};
