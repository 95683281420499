const devices = [/iPhone|iPad|iPod/i, /Opera Mini/i, /Android/i];

export const isMobile = (userAgent = ""): boolean | null => {
    if (!userAgent) {
        // UserAgent string not provided - we are not able to determine device type
        return null;
    }

    return devices.some((device) => Boolean(userAgent.match(device)));
};
const devices = [/iPhone|iPad|iPod/i, /Opera Mini/i, /Android/i];

export const isMobile = (userAgent = ""): boolean | null => {
    if (!userAgent) {
        // UserAgent string not provided - we are not able to determine device type
        return null;
    }

    return devices.some((device) => Boolean(userAgent.match(device)));
};
