export enum SiteSlug {
    RP = "rynekpierwotny",
    GH = "gethome",
    KM = "konto-mieszkaniowe"
}
export enum SiteSlug {
    RP = "rynekpierwotny",
    GH = "gethome",
    KM = "konto-mieszkaniowe"
}
