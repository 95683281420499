import {FloorChoice} from "../types/FloorChoice";

export function getFloorChoiceText(floorChoice: FloorChoice) {
    switch (floorChoice) {
        case FloorChoice.GROUND:
            return "na parterze";
        case FloorChoice.GROUND_WITH_GARDEN:
            return "na parterze z ogródkiem";
        case FloorChoice.LOW_LEVELS:
            return "na niskich piętrach";
        case FloorChoice.HIGH_LEVELS:
            return "na wysokich piętrach";
        case FloorChoice.LAST_LEVEL:
            return "na ostatnim piętrze";
        case FloorChoice.LAST_LEVEL_WITH_TERRACE:
            return "na ostatnie piętrze z tarasem";
        default:
            throw new Error("Invalid floor choice in getFloorChoiceText");
    }
}
import {FloorChoice} from "../types/FloorChoice";

export function getFloorChoiceText(floorChoice: FloorChoice) {
    switch (floorChoice) {
        case FloorChoice.GROUND:
            return "na parterze";
        case FloorChoice.GROUND_WITH_GARDEN:
            return "na parterze z ogródkiem";
        case FloorChoice.LOW_LEVELS:
            return "na niskich piętrach";
        case FloorChoice.HIGH_LEVELS:
            return "na wysokich piętrach";
        case FloorChoice.LAST_LEVEL:
            return "na ostatnim piętrze";
        case FloorChoice.LAST_LEVEL_WITH_TERRACE:
            return "na ostatnie piętrze z tarasem";
        default:
            throw new Error("Invalid floor choice in getFloorChoiceText");
    }
}
