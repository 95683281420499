import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {addRegionSlugToQuery} from "../../helpers/friendly_offer_list/add_region_slug_to_query";
import {divideQueryIntoFriendlyAndRest, offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {fetchSelectedRegionById} from "./offer_list_selected_data_actions";

export const redirectToFriendlyUrl = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    let query = ctx.route.query;
    // update region-slug if necessary
    const regionId = parseInt(query.region as string, 10);
    if (Number.isFinite(regionId)) {
        const region = await dispatch(fetchSelectedRegionById(ctx.meta, regionId));
        if (region) {
            // update query's `region` field (if needed)
            query = addRegionSlugToQuery(query, region);
        }
    }
    const [friendlyQueryValues, restQueryValues] = divideQueryIntoFriendlyAndRest(query);
    const friendlySlug = offerUrlBuilder(friendlyQueryValues).friendlySlug;
    const path = rpAppLink.offer.listFriendly({friendlySlug});
    // const path = redirectToMap ? appLink.offer.mapFriendly({friendlySlug}) : appLink.offer.listFriendly({friendlySlug});
    dispatch(enable301ResponseState(appendQueryString(path, restQueryValues)));
    return false; // stop fetching
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {addRegionSlugToQuery} from "../../helpers/friendly_offer_list/add_region_slug_to_query";
import {divideQueryIntoFriendlyAndRest, offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {fetchSelectedRegionById} from "./offer_list_selected_data_actions";

export const redirectToFriendlyUrl = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    let query = ctx.route.query;
    // update region-slug if necessary
    const regionId = parseInt(query.region as string, 10);
    if (Number.isFinite(regionId)) {
        const region = await dispatch(fetchSelectedRegionById(ctx.meta, regionId));
        if (region) {
            // update query's `region` field (if needed)
            query = addRegionSlugToQuery(query, region);
        }
    }
    const [friendlyQueryValues, restQueryValues] = divideQueryIntoFriendlyAndRest(query);
    const friendlySlug = offerUrlBuilder(friendlyQueryValues).friendlySlug;
    const path = rpAppLink.offer.listFriendly({friendlySlug});
    // const path = redirectToMap ? appLink.offer.mapFriendly({friendlySlug}) : appLink.offer.listFriendly({friendlySlug});
    dispatch(enable301ResponseState(appendQueryString(path, restQueryValues)));
    return false; // stop fetching
};
