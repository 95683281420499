import type {CSSProperties, MouseEventHandler, PropsWithChildren} from "react";
import {forwardRef} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {IFieldState} from "../../types";
import {getFieldStateColor} from "../../utils/get_field_state_color";

import {
    borderlessStyle,
    detailStyle,
    inputWrapperBase,
    inputWrapperDisabled,
    inputWrapperPadding,
    inputWrapStyle,
    leftElementStyle,
    rightElementStyle
} from "./InputWrapper.module.css";

export type IInputWrapperProps = PropsWithChildren<{
    fieldState?: IFieldState;
    detail?: string;
    leftElement?: JSX.Element | string;
    rightElement?: JSX.Element | string;
    elementCustomStyle?: CSSProperties;
    elementCustomClassName?: string;
    className?: string;
    noPadding?: boolean; // TODO: temp fix, consider defining padding in inner components like in an actual input field or whatever is using the wrapper
    inputWrapClassName?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    isBorderless?: boolean;
}>;

/**
 * This component uses no internal padding, it should be defined by the child component, not by wrapper. It was a design mistake in original component
 * I also removed some props, they were basically CSS properties
 */
export const InputWrapper = forwardRef<HTMLDivElement, IInputWrapperProps>((props: IInputWrapperProps, forwardedRef) => {
    const {
        children,
        fieldState,
        detail,
        leftElement,
        rightElement,
        elementCustomClassName,
        elementCustomStyle,
        className,
        noPadding,
        onClick,
        inputWrapClassName,
        isBorderless
    } = props;

    const fieldStateColor = getFieldStateColor(fieldState);

    const wrapperVariables = fieldStateColor ? ({"--wrapper-field-state-color": `var(${fieldStateColor})`} as CSSProperties) : {};

    const wrapperBaseCN = classNames(
        className,
        inputWrapperBase,
        !noPadding && inputWrapperPadding,
        fieldState === "disabled" && inputWrapperDisabled,
        isBorderless && borderlessStyle
    );
    const leftElementCN = classNames(elementCustomClassName, leftElementStyle);
    const rightElementCN = classNames(elementCustomClassName, rightElementStyle);
    const inputWrapCN = classNames(inputWrapClassName, inputWrapStyle);

    return (
        <div style={wrapperVariables} className={wrapperBaseCN} ref={forwardedRef} onClick={onClick}>
            {leftElement && (
                <div className={leftElementCN} style={elementCustomStyle}>
                    {leftElement}
                </div>
            )}
            <div className={inputWrapCN}>
                {children}
                {detail && (
                    <span className={detailStyle}>
                        <Text as="span" variant="info_txt_3">
                            {detail}
                        </Text>
                    </span>
                )}
            </div>
            {rightElement && (
                <div className={rightElementCN} style={elementCustomStyle}>
                    {rightElement}
                </div>
            )}
        </div>
    );
});
import type {CSSProperties, MouseEventHandler, PropsWithChildren} from "react";
import {forwardRef} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {IFieldState} from "../../types";
import {getFieldStateColor} from "../../utils/get_field_state_color";

import {
    borderlessStyle,
    detailStyle,
    inputWrapperBase,
    inputWrapperDisabled,
    inputWrapperPadding,
    inputWrapStyle,
    leftElementStyle,
    rightElementStyle
} from "./InputWrapper.module.css";

export type IInputWrapperProps = PropsWithChildren<{
    fieldState?: IFieldState;
    detail?: string;
    leftElement?: JSX.Element | string;
    rightElement?: JSX.Element | string;
    elementCustomStyle?: CSSProperties;
    elementCustomClassName?: string;
    className?: string;
    noPadding?: boolean; // TODO: temp fix, consider defining padding in inner components like in an actual input field or whatever is using the wrapper
    inputWrapClassName?: string;
    onClick?: MouseEventHandler<HTMLDivElement>;
    isBorderless?: boolean;
}>;

/**
 * This component uses no internal padding, it should be defined by the child component, not by wrapper. It was a design mistake in original component
 * I also removed some props, they were basically CSS properties
 */
export const InputWrapper = forwardRef<HTMLDivElement, IInputWrapperProps>((props: IInputWrapperProps, forwardedRef) => {
    const {
        children,
        fieldState,
        detail,
        leftElement,
        rightElement,
        elementCustomClassName,
        elementCustomStyle,
        className,
        noPadding,
        onClick,
        inputWrapClassName,
        isBorderless
    } = props;

    const fieldStateColor = getFieldStateColor(fieldState);

    const wrapperVariables = fieldStateColor ? ({"--wrapper-field-state-color": `var(${fieldStateColor})`} as CSSProperties) : {};

    const wrapperBaseCN = classNames(
        className,
        inputWrapperBase,
        !noPadding && inputWrapperPadding,
        fieldState === "disabled" && inputWrapperDisabled,
        isBorderless && borderlessStyle
    );
    const leftElementCN = classNames(elementCustomClassName, leftElementStyle);
    const rightElementCN = classNames(elementCustomClassName, rightElementStyle);
    const inputWrapCN = classNames(inputWrapClassName, inputWrapStyle);

    return (
        <div style={wrapperVariables} className={wrapperBaseCN} ref={forwardedRef} onClick={onClick}>
            {leftElement && (
                <div className={leftElementCN} style={elementCustomStyle}>
                    {leftElement}
                </div>
            )}
            <div className={inputWrapCN}>
                {children}
                {detail && (
                    <span className={detailStyle}>
                        <Text as="span" variant="info_txt_3">
                            {detail}
                        </Text>
                    </span>
                )}
            </div>
            {rightElement && (
                <div className={rightElementCN} style={elementCustomStyle}>
                    {rightElement}
                </div>
            )}
        </div>
    );
});
