// Floor choices are used as values in forms, but also as query values for pages and api
export enum FloorChoice {
    GROUND = 1,
    GROUND_WITH_GARDEN = 2,
    LOW_LEVELS = 3,
    HIGH_LEVELS = 4,
    LAST_LEVEL = 5,
    LAST_LEVEL_WITH_TERRACE = 6
}
// Floor choices are used as values in forms, but also as query values for pages and api
export enum FloorChoice {
    GROUND = 1,
    GROUND_WITH_GARDEN = 2,
    LOW_LEVELS = 3,
    HIGH_LEVELS = 4,
    LAST_LEVEL = 5,
    LAST_LEVEL_WITH_TERRACE = 6
}
