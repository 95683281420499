import {themeGH, themeKM, themePA, themeRP} from "@pg-design/themes";

import {cssBreakpointFactory, cssVariableFactory, transformThemeToCssVariables} from "./token_factories";

export const getThemeBreakpoint = cssBreakpointFactory(themeRP.breakpoints);
export const getThemeVariable = cssVariableFactory<typeof themeRP>();
export const themeVariableListRp = transformThemeToCssVariables(themeRP);
export const themeVariableListGh = transformThemeToCssVariables(themeGH);
export const themeVariableListKm = transformThemeToCssVariables(themeKM);
export const themeVariableListPa = transformThemeToCssVariables(themePA);
import {themeGH, themeKM, themePA, themeRP} from "@pg-design/themes";

import {cssBreakpointFactory, cssVariableFactory, transformThemeToCssVariables} from "./token_factories";

export const getThemeBreakpoint = cssBreakpointFactory(themeRP.breakpoints);
export const getThemeVariable = cssVariableFactory<typeof themeRP>();
export const themeVariableListRp = transformThemeToCssVariables(themeRP);
export const themeVariableListGh = transformThemeToCssVariables(themeGH);
export const themeVariableListKm = transformThemeToCssVariables(themeKM);
export const themeVariableListPa = transformThemeToCssVariables(themePA);
