import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateContractorMeta = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName(`Oferta dla Architekta -`);
    const description = "Załóż profil biura architektonicznego! Docieraj do nowych klientów dzięki skutecznej promocji na portalu rynekpierwotny.pl!";

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateContractorMeta = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName(`Oferta dla Architekta -`);
    const description = "Załóż profil biura architektonicznego! Docieraj do nowych klientów dzięki skutecznej promocji na portalu rynekpierwotny.pl!";

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};
