import * as lscache from "lscache";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {ISentLeadStoredValues} from "../types/ISentLeadStoredValues";

export function setSentLeadFormValuesInStorage(formValues: Partial<ISentLeadStoredValues>) {
    const currentFormValues = getSentLeadFormValuesFromStorage();

    if (currentFormValues) {
        lscache.set(APPLICATION_LOCAL_STORAGE_KEY, {
            ...currentFormValues,
            ...formValues
        });
    } else {
        lscache.set(APPLICATION_LOCAL_STORAGE_KEY, formValues);
    }

    return getSentLeadFormValuesFromStorage() as ISentLeadStoredValues;
}

export function getSentLeadFormValuesFromStorage(): ISentLeadStoredValues | null {
    const formValues = lscache.get(APPLICATION_LOCAL_STORAGE_KEY);

    if (formValues) {
        const sentLeadFormValues: ISentLeadStoredValues = {
            email: getStringValue(formValues.email),
            name: getStringValue(formValues.name),
            phone: getStringValue(formValues.phone),
            text: getStringValue(formValues.text),
            financing_services: getBooleanValue(formValues.financing_services),
            new_property_notification_consent: getBooleanValue(formValues.new_property_notification_consent),
            investment_services: getBooleanValue(formValues.investment_services),
            rooms: getArrayValue(formValues.rooms)
        };

        return sentLeadFormValues;
    }

    return null;
}

function getStringValue(value: string | null | undefined) {
    if (value) {
        return value;
    }

    return "";
}

function getBooleanValue(value: boolean | null | undefined) {
    if (value === true || value === false) {
        return value;
    }

    return false;
}

function getArrayValue(value: object | undefined | null) {
    if (value && Array.isArray(value)) {
        return value;
    }

    return [];
}
import * as lscache from "lscache";

import {APPLICATION_LOCAL_STORAGE_KEY} from "../../app/constants/storage_keys";
import {ISentLeadStoredValues} from "../types/ISentLeadStoredValues";

export function setSentLeadFormValuesInStorage(formValues: Partial<ISentLeadStoredValues>) {
    const currentFormValues = getSentLeadFormValuesFromStorage();

    if (currentFormValues) {
        lscache.set(APPLICATION_LOCAL_STORAGE_KEY, {
            ...currentFormValues,
            ...formValues
        });
    } else {
        lscache.set(APPLICATION_LOCAL_STORAGE_KEY, formValues);
    }

    return getSentLeadFormValuesFromStorage() as ISentLeadStoredValues;
}

export function getSentLeadFormValuesFromStorage(): ISentLeadStoredValues | null {
    const formValues = lscache.get(APPLICATION_LOCAL_STORAGE_KEY);

    if (formValues) {
        const sentLeadFormValues: ISentLeadStoredValues = {
            email: getStringValue(formValues.email),
            name: getStringValue(formValues.name),
            phone: getStringValue(formValues.phone),
            text: getStringValue(formValues.text),
            financing_services: getBooleanValue(formValues.financing_services),
            new_property_notification_consent: getBooleanValue(formValues.new_property_notification_consent),
            investment_services: getBooleanValue(formValues.investment_services),
            rooms: getArrayValue(formValues.rooms)
        };

        return sentLeadFormValues;
    }

    return null;
}

function getStringValue(value: string | null | undefined) {
    if (value) {
        return value;
    }

    return "";
}

function getBooleanValue(value: boolean | null | undefined) {
    if (value === true || value === false) {
        return value;
    }

    return false;
}

function getArrayValue(value: object | undefined | null) {
    if (value && Array.isArray(value)) {
        return value;
    }

    return [];
}
