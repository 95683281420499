import {css} from "@emotion/react";

export const pointer = css`
    cursor: pointer;
`;
import {css} from "@emotion/react";

export const pointer = css`
    cursor: pointer;
`;
