import {GLUE_API_PREFIX, MP_API_PREFIX, V1_API_PREFIX, V2_API_PREFIX} from "@pg-mono/rp-api-routes";

import {rpServerGlueApiUrl, rpServerMpApiUrl, rpServerV1ApiUrl, rpServerV2ApiUrl} from "../read_rp_environment_variables";

export const serverApiUrls = {
    [GLUE_API_PREFIX]: rpServerGlueApiUrl,
    [V1_API_PREFIX]: rpServerV1ApiUrl,
    [V2_API_PREFIX]: rpServerV2ApiUrl,
    [MP_API_PREFIX]: rpServerMpApiUrl
};
import {GLUE_API_PREFIX, MP_API_PREFIX, V1_API_PREFIX, V2_API_PREFIX} from "@pg-mono/rp-api-routes";

import {rpServerGlueApiUrl, rpServerMpApiUrl, rpServerV1ApiUrl, rpServerV2ApiUrl} from "../read_rp_environment_variables";

export const serverApiUrls = {
    [GLUE_API_PREFIX]: rpServerGlueApiUrl,
    [V1_API_PREFIX]: rpServerV1ApiUrl,
    [V2_API_PREFIX]: rpServerV2ApiUrl,
    [MP_API_PREFIX]: rpServerMpApiUrl
};
