import React from "react";
import {Route, Switch} from "react-router";

import {IVisualRoute} from "./types/IVisualRoute";

export const Routes = (props: {routes: IVisualRoute[]}) => {
    return (
        <Switch>
            {props.routes.map((route, idx) => (
                <Route key={idx.toString()} path={route.path} exact={route.exact !== false} strict component={route.component} />
            ))}
        </Switch>
    );
};
import React from "react";
import {Route, Switch} from "react-router";

import {IVisualRoute} from "./types/IVisualRoute";

export const Routes = (props: {routes: IVisualRoute[]}) => {
    return (
        <Switch>
            {props.routes.map((route, idx) => (
                <Route key={idx.toString()} path={route.path} exact={route.exact !== false} strict component={route.component} />
            ))}
        </Switch>
    );
};
