import {match} from "react-router";
import {Request, Response} from "express";
import {Dispatch} from "redux";

import {matchFetchEntry} from "./match_fetch_entry";

// main building block of data-fetcher's path-to-fetch map
export interface IFetchEntry<TMeta, TStore> {
    path: string | string[]; // paths to match
    exact?: boolean; // paths matching option, default is true
    fetch: IFetchAction<TMeta, TStore>; // fetch action
    routes?: IFetchEntry<TMeta, TStore>[]; // children routes to look for further matches
    exclude?: string | string[];
}
// every fetch action called by data-fetcher
export type IFetchAction<TMeta, TStore> = (ctx: IFetchContext<TMeta>) => (dispatch: Dispatch, getState: () => TStore) => Promise<unknown>;
// context passed to every fetch action called by data-fetcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IFetchContext<TMeta, TParams extends {[K in keyof TParams]?: string} = any, TPrevResult = any> {
    route: IFetchContextRoute; // current route, universal for server and browser
    prevRoute: IFetchContextRoute | null; // previous route, passed by browser data-fetcher
    match: match<TParams>; // react-route matchPath's result
    // matchedEntry: IFetchEntry<TMeta>;       // [unnecessary for now] - fetch-entry matched by react-route, we call it's `fetch` action
    prevResult: TPrevResult; // result from previous action or `true` (for the first action)
    meta: TMeta; // additional request data, universal for server and browser
    req?: Partial<Request>; // server only
    res?: Response; // server only
}
// route description, universal for server and browser
export interface IFetchContextRoute {
    pathname: string; // server request `path` or browser location `pathname`
    query: Record<string, string | string[]>; // server request `query` or browser location `search` parsed to object
    url: string; // server request `originalUrl` or browser location `pathname` + `search` + `hash`
    hash?: string; // browser location `hash`
}
// prepare functional (server, browser) data-fetcher based on fetch-entries provided by app
export const createDataFetcher =
    <TMeta, TStore>(fetchEntries: IFetchEntry<TMeta, TStore>[]) =>
    (route: IFetchContextRoute, prevRoute: IFetchContextRoute | null, meta: TMeta, req?: Request, res?: Response) =>
    async (dispatch: Dispatch): Promise<void> => {
        const matchedEntries = matchFetchEntry<TMeta, TStore>(fetchEntries, route.pathname);
        let idx = 0;
        let prevResult: unknown = true; // true allows to start action-chain
        while (!!prevResult && idx < matchedEntries.length) {
            const matchedEntry = matchedEntries[idx++];
            const ctx: IFetchContext<TMeta> = {route, prevRoute, match: matchedEntry.match, prevResult, meta, req, res};
            prevResult = await dispatch(matchedEntry.entry.fetch(ctx)); // each action has to be dispatched
        }
    };
import {match} from "react-router";
import {Request, Response} from "express";
import {Dispatch} from "redux";

import {matchFetchEntry} from "./match_fetch_entry";

// main building block of data-fetcher's path-to-fetch map
export interface IFetchEntry<TMeta, TStore> {
    path: string | string[]; // paths to match
    exact?: boolean; // paths matching option, default is true
    fetch: IFetchAction<TMeta, TStore>; // fetch action
    routes?: IFetchEntry<TMeta, TStore>[]; // children routes to look for further matches
    exclude?: string | string[];
}
// every fetch action called by data-fetcher
export type IFetchAction<TMeta, TStore> = (ctx: IFetchContext<TMeta>) => (dispatch: Dispatch, getState: () => TStore) => Promise<unknown>;
// context passed to every fetch action called by data-fetcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IFetchContext<TMeta, TParams extends {[K in keyof TParams]?: string} = any, TPrevResult = any> {
    route: IFetchContextRoute; // current route, universal for server and browser
    prevRoute: IFetchContextRoute | null; // previous route, passed by browser data-fetcher
    match: match<TParams>; // react-route matchPath's result
    // matchedEntry: IFetchEntry<TMeta>;       // [unnecessary for now] - fetch-entry matched by react-route, we call it's `fetch` action
    prevResult: TPrevResult; // result from previous action or `true` (for the first action)
    meta: TMeta; // additional request data, universal for server and browser
    req?: Partial<Request>; // server only
    res?: Response; // server only
}
// route description, universal for server and browser
export interface IFetchContextRoute {
    pathname: string; // server request `path` or browser location `pathname`
    query: Record<string, string | string[]>; // server request `query` or browser location `search` parsed to object
    url: string; // server request `originalUrl` or browser location `pathname` + `search` + `hash`
    hash?: string; // browser location `hash`
}
// prepare functional (server, browser) data-fetcher based on fetch-entries provided by app
export const createDataFetcher =
    <TMeta, TStore>(fetchEntries: IFetchEntry<TMeta, TStore>[]) =>
    (route: IFetchContextRoute, prevRoute: IFetchContextRoute | null, meta: TMeta, req?: Request, res?: Response) =>
    async (dispatch: Dispatch): Promise<void> => {
        const matchedEntries = matchFetchEntry<TMeta, TStore>(fetchEntries, route.pathname);
        let idx = 0;
        let prevResult: unknown = true; // true allows to start action-chain
        while (!!prevResult && idx < matchedEntries.length) {
            const matchedEntry = matchedEntries[idx++];
            const ctx: IFetchContext<TMeta> = {route, prevRoute, match: matchedEntry.match, prevResult, meta, req, res};
            prevResult = await dispatch(matchedEntry.entry.fetch(ctx)); // each action has to be dispatched
        }
    };
