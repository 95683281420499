import {IModalProps} from "../../../types/IModalProps";

import * as styles from "./modal_align_style.module.css";

export const modalAlignStyle = (align: IModalProps["align"]) => align && styles[align];
import {IModalProps} from "../../../types/IModalProps";

import * as styles from "./modal_align_style.module.css";

export const modalAlignStyle = (align: IModalProps["align"]) => align && styles[align];
