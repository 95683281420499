import {FormFieldType} from "@pg-mono/form";

export const searchFormFields = {
    search: FormFieldType.Input,
    type: FormFieldType.SearchOfferType,
    rooms: FormFieldType.SelectRangeNumber,
    price: FormFieldType.InputRange,
    area: FormFieldType.InputRange,
    floor_choices: FormFieldType.Radio,
    construction_end_date: FormFieldType.MultiCheckbox,
    distance: FormFieldType.Input,
    house_type: FormFieldType.Radio,
    house_additional_areas: FormFieldType.MultiCheckbox,
    house_storeys: FormFieldType.Radio,
    instalment: FormFieldType.Input
};
import {FormFieldType} from "@pg-mono/form";

export const searchFormFields = {
    search: FormFieldType.Input,
    type: FormFieldType.SearchOfferType,
    rooms: FormFieldType.SelectRangeNumber,
    price: FormFieldType.InputRange,
    area: FormFieldType.InputRange,
    floor_choices: FormFieldType.Radio,
    construction_end_date: FormFieldType.MultiCheckbox,
    distance: FormFieldType.Input,
    house_type: FormFieldType.Radio,
    house_additional_areas: FormFieldType.MultiCheckbox,
    house_storeys: FormFieldType.Radio,
    instalment: FormFieldType.Input
};
