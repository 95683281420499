import {matchPath} from "react-router";
import * as pathToRegexp from "path-to-regexp";

import {prefixPath} from "@pg-mono/prefix-path";

import {SiteSlug} from "./types/site_slug";

export const kmAppPath = prefixPath("/", {
    base: "",
    logout: "logout/",
    site: prefixPath(":site([\\w-]+)/", {
        base: "",
        landingPage: prefixPath("", {
            createAccount: "nowe-konto/"
        }),
        marketData: prefixPath("dane-rynkowe/", {
            base: ""
        }),
        myAds: prefixPath("moje-ogloszenia/", {
            base: ""
        }),
        offers: prefixPath("oferty/", {
            base: "",
            recommended: "rekomendowane/",
            favourites: "ulubione/",
            applied: "moje-zapytania/",
            dismissed: "odrzucone/"
        }),
        passwordChange: prefixPath("przypomnij-haslo/", {
            base: "",
            link: ":token([\\w-]+)/"
        }),
        preferences: prefixPath("preferencje/", {
            base: ""
        }),
        remindPassword: prefixPath("przypomnij-haslo/", {
            base: "",
            link: ":token([\\w-]+)/"
        }),
        unsubscribe: prefixPath("unsubscribe/", {
            cpl: "cpl/:uuid/",
            mailing: "mailing/:emailHash/:verificationHash/"
        }),
        user: prefixPath("konto/", {
            base: "",
            edit: "edycja/",
            passwordChange: "zmien-haslo/",
            subscription: "subskrypcje/",
            test: "test/"
        })
    })
});

export const kmAppLink = {
    logout: noParamsLink(kmAppPath.logout), // logout is handled on backend, routed with ingress
    site: {
        base: noParamsLink(kmAppPath.site.base),
        baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.base),
        marketData: parseParams<{site: SiteSlug}>(kmAppPath.site.marketData.base),
        myAds: parseParams<{site: SiteSlug}>(kmAppPath.site.myAds.base),
        offers: {
            base: noParamsLink(kmAppPath.site.offers.base),
            baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.base),
            recommendedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.recommended),
            favouritesWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.favourites),
            appliedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.applied),
            dismissedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.dismissed)
        },
        passwordChange: {
            base: noParamsLink(kmAppPath.site.passwordChange.base),
            link: parseParams<{site: SiteSlug; token: string}>(kmAppPath.site.passwordChange.link)
        },
        preferences: parseParams<{site: SiteSlug}>(kmAppPath.site.preferences.base),
        remindPassword: {
            base: noParamsLink(kmAppPath.site.passwordChange.base),
            link: parseParams<{site: SiteSlug; token: string}>(kmAppPath.site.remindPassword.link)
        },
        user: {
            base: noParamsLink(kmAppPath.site.user.edit),
            baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.edit),
            edit: noParamsLink(kmAppPath.site.user.edit),
            passwordChange: noParamsLink(kmAppPath.site.user.passwordChange),
            passwordChangeWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.passwordChange),
            subscription: noParamsLink(kmAppPath.site.user.subscription),
            subscriptionWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.subscription)
        }
    }
};

function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

function noParamsLink(path: string) {
    const compiler = pathToRegexp.compile(path);
    return () => {
        const matchedSite = matchPath<{site: SiteSlug}>(window.location.pathname, {path: kmAppPath.site.base})?.params.site;
        return compiler({site: matchedSite});
    };
}
import {matchPath} from "react-router";
import * as pathToRegexp from "path-to-regexp";

import {prefixPath} from "@pg-mono/prefix-path";

import {SiteSlug} from "./types/site_slug";

export const kmAppPath = prefixPath("/", {
    base: "",
    logout: "logout/",
    site: prefixPath(":site([\\w-]+)/", {
        base: "",
        landingPage: prefixPath("", {
            createAccount: "nowe-konto/"
        }),
        marketData: prefixPath("dane-rynkowe/", {
            base: ""
        }),
        myAds: prefixPath("moje-ogloszenia/", {
            base: ""
        }),
        offers: prefixPath("oferty/", {
            base: "",
            recommended: "rekomendowane/",
            favourites: "ulubione/",
            applied: "moje-zapytania/",
            dismissed: "odrzucone/"
        }),
        passwordChange: prefixPath("przypomnij-haslo/", {
            base: "",
            link: ":token([\\w-]+)/"
        }),
        preferences: prefixPath("preferencje/", {
            base: ""
        }),
        remindPassword: prefixPath("przypomnij-haslo/", {
            base: "",
            link: ":token([\\w-]+)/"
        }),
        unsubscribe: prefixPath("unsubscribe/", {
            cpl: "cpl/:uuid/",
            mailing: "mailing/:emailHash/:verificationHash/"
        }),
        user: prefixPath("konto/", {
            base: "",
            edit: "edycja/",
            passwordChange: "zmien-haslo/",
            subscription: "subskrypcje/",
            test: "test/"
        })
    })
});

export const kmAppLink = {
    logout: noParamsLink(kmAppPath.logout), // logout is handled on backend, routed with ingress
    site: {
        base: noParamsLink(kmAppPath.site.base),
        baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.base),
        marketData: parseParams<{site: SiteSlug}>(kmAppPath.site.marketData.base),
        myAds: parseParams<{site: SiteSlug}>(kmAppPath.site.myAds.base),
        offers: {
            base: noParamsLink(kmAppPath.site.offers.base),
            baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.base),
            recommendedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.recommended),
            favouritesWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.favourites),
            appliedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.applied),
            dismissedWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.offers.dismissed)
        },
        passwordChange: {
            base: noParamsLink(kmAppPath.site.passwordChange.base),
            link: parseParams<{site: SiteSlug; token: string}>(kmAppPath.site.passwordChange.link)
        },
        preferences: parseParams<{site: SiteSlug}>(kmAppPath.site.preferences.base),
        remindPassword: {
            base: noParamsLink(kmAppPath.site.passwordChange.base),
            link: parseParams<{site: SiteSlug; token: string}>(kmAppPath.site.remindPassword.link)
        },
        user: {
            base: noParamsLink(kmAppPath.site.user.edit),
            baseWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.edit),
            edit: noParamsLink(kmAppPath.site.user.edit),
            passwordChange: noParamsLink(kmAppPath.site.user.passwordChange),
            passwordChangeWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.passwordChange),
            subscription: noParamsLink(kmAppPath.site.user.subscription),
            subscriptionWithParams: parseParams<{site: SiteSlug}>(kmAppPath.site.user.subscription)
        }
    }
};

function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

function noParamsLink(path: string) {
    const compiler = pathToRegexp.compile(path);
    return () => {
        const matchedSite = matchPath<{site: SiteSlug}>(window.location.pathname, {path: kmAppPath.site.base})?.params.site;
        return compiler({site: matchedSite});
    };
}
