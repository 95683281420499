import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";
import {IOfferFilters, IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {
    doesExistAnyFilterBesidesAllowed,
    doesFilterContainsNonListedValues,
    shouldHaveActiveFilters,
    shouldHaveActiveSomeFilters
} from "./offer_listing_canonical_and_robots_helpers";

const doesRoomsFiltersExitWithRange = (filters: IOfferFilters) => {
    const rooms = [Number(filters.rooms_0), Number(filters.rooms_1)];

    return rooms.every(Number.isFinite) && rooms[1] > rooms[0];
};

const haveRoomsFiltersDifferentValues = (filters: IOfferFilters) => filters.rooms_0 !== filters.rooms_1;

export const createCanonicalAndRobotsCommonRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => doesRoomsFiltersExitWithRange(params.filters),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createCanonicalAndRobotsFlatRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        const isFlatOfferType = params.offerType === OfferType.FLAT;
        const hasActiveRoomsFilters = shouldHaveActiveSomeFilters(params.filters, ["rooms_0", "rooms_1"]);
        const hasAnyFiltersActiveBesidesRoomsFilters = doesExistAnyFilterBesidesAllowed(params.filters, ["rooms_0", "rooms_1"]);

        // 5 rooms or more: rooms_0 = 5, rooms_1 = undefined
        // 4 rooms: rooms_0 = 4, rooms_1 = 4
        // etc
        // 1 room: rooms_0 = undefined, rooms_1 = 1
        const hasRoomsFiltersNotAllowedValues =
            doesFilterContainsNonListedValues(params.filters.rooms_0, ["5"]) &&
            doesFilterContainsNonListedValues(params.filters.rooms_1, ["1"]) &&
            haveRoomsFiltersDifferentValues(params.filters);

        return isFlatOfferType && hasActiveRoomsFilters && (hasAnyFiltersActiveBesidesRoomsFilters || hasRoomsFiltersNotAllowedValues);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createCanonicalAndRobotsHouseRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        const isHouseOfferType = params.offerType === OfferType.HOUSE;
        const hasActiveRoomsFilters = shouldHaveActiveSomeFilters(params.filters, ["rooms_0", "rooms_1"]);
        const hasAnyFiltersActiveBesidesRoomsFilters = doesExistAnyFilterBesidesAllowed(params.filters, ["rooms_0", "rooms_1"]);

        // 5 rooms or more: rooms_0 = 5, rooms_1 = undefined
        // 4 rooms: rooms_0 = 4, rooms_1 = 4
        // etc
        // 1 room: rooms_0 = undefined, rooms_1 = 1
        const hasRoomsFiltersNotAllowedValues =
            doesFilterContainsNonListedValues(params.filters.rooms_0, ["3", "4", "5"]) ||
            (shouldHaveActiveFilters(params.filters, ["rooms_1"]) && doesFilterContainsNonListedValues(params.filters.rooms_1, ["3", "4", "5"])) ||
            (doesFilterContainsNonListedValues(params.filters.rooms_0, ["5"]) && haveRoomsFiltersDifferentValues(params.filters));

        return isHouseOfferType && hasActiveRoomsFilters && (hasAnyFiltersActiveBesidesRoomsFilters || hasRoomsFiltersNotAllowedValues);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";
import {IOfferFilters, IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {
    doesExistAnyFilterBesidesAllowed,
    doesFilterContainsNonListedValues,
    shouldHaveActiveFilters,
    shouldHaveActiveSomeFilters
} from "./offer_listing_canonical_and_robots_helpers";

const doesRoomsFiltersExitWithRange = (filters: IOfferFilters) => {
    const rooms = [Number(filters.rooms_0), Number(filters.rooms_1)];

    return rooms.every(Number.isFinite) && rooms[1] > rooms[0];
};

const haveRoomsFiltersDifferentValues = (filters: IOfferFilters) => filters.rooms_0 !== filters.rooms_1;

export const createCanonicalAndRobotsCommonRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => doesRoomsFiltersExitWithRange(params.filters),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createCanonicalAndRobotsFlatRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        const isFlatOfferType = params.offerType === OfferType.FLAT;
        const hasActiveRoomsFilters = shouldHaveActiveSomeFilters(params.filters, ["rooms_0", "rooms_1"]);
        const hasAnyFiltersActiveBesidesRoomsFilters = doesExistAnyFilterBesidesAllowed(params.filters, ["rooms_0", "rooms_1"]);

        // 5 rooms or more: rooms_0 = 5, rooms_1 = undefined
        // 4 rooms: rooms_0 = 4, rooms_1 = 4
        // etc
        // 1 room: rooms_0 = undefined, rooms_1 = 1
        const hasRoomsFiltersNotAllowedValues =
            doesFilterContainsNonListedValues(params.filters.rooms_0, ["5"]) &&
            doesFilterContainsNonListedValues(params.filters.rooms_1, ["1"]) &&
            haveRoomsFiltersDifferentValues(params.filters);

        return isFlatOfferType && hasActiveRoomsFilters && (hasAnyFiltersActiveBesidesRoomsFilters || hasRoomsFiltersNotAllowedValues);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createCanonicalAndRobotsHouseRoomsFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => {
        const isHouseOfferType = params.offerType === OfferType.HOUSE;
        const hasActiveRoomsFilters = shouldHaveActiveSomeFilters(params.filters, ["rooms_0", "rooms_1"]);
        const hasAnyFiltersActiveBesidesRoomsFilters = doesExistAnyFilterBesidesAllowed(params.filters, ["rooms_0", "rooms_1"]);

        // 5 rooms or more: rooms_0 = 5, rooms_1 = undefined
        // 4 rooms: rooms_0 = 4, rooms_1 = 4
        // etc
        // 1 room: rooms_0 = undefined, rooms_1 = 1
        const hasRoomsFiltersNotAllowedValues =
            doesFilterContainsNonListedValues(params.filters.rooms_0, ["3", "4", "5"]) ||
            (shouldHaveActiveFilters(params.filters, ["rooms_1"]) && doesFilterContainsNonListedValues(params.filters.rooms_1, ["3", "4", "5"])) ||
            (doesFilterContainsNonListedValues(params.filters.rooms_0, ["5"]) && haveRoomsFiltersDifferentValues(params.filters));

        return isHouseOfferType && hasActiveRoomsFilters && (hasAnyFiltersActiveBesidesRoomsFilters || hasRoomsFiltersNotAllowedValues);
    },
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});
