import {houseFiltersToFriendQuery} from "../../constants/house_filter";

export const houseFilterFriendlyQueryValues: string[] = [
    ...Object.values(houseFiltersToFriendQuery.house_type),
    ...Object.values(houseFiltersToFriendQuery.house_storeys),
    ...Object.values(houseFiltersToFriendQuery.house_additional_areas)
];
import {houseFiltersToFriendQuery} from "../../constants/house_filter";

export const houseFilterFriendlyQueryValues: string[] = [
    ...Object.values(houseFiltersToFriendQuery.house_type),
    ...Object.values(houseFiltersToFriendQuery.house_storeys),
    ...Object.values(houseFiltersToFriendQuery.house_additional_areas)
];
