import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getReelsList} from "../api/get_reels_list";

export const getReelListAction = (_ctx: IFetchContext<IRPRequestMeta, {}>) => async (dispatch: IAppDispatch, getState: () => IRPStore) => {
    const regionIds = getState().offerList.selectedRegions.map((region) => region.id);
    const reelList = await dispatch(getReelsList.endpoints.getReelsList.initiate({regionIds}));

    if ((reelList.isError && isRtkqError(reelList.error) && reelList.error.status === 404) || !reelList.data) {
        return true;
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getReelsList} from "../api/get_reels_list";

export const getReelListAction = (_ctx: IFetchContext<IRPRequestMeta, {}>) => async (dispatch: IAppDispatch, getState: () => IRPStore) => {
    const regionIds = getState().offerList.selectedRegions.map((region) => region.id);
    const reelList = await dispatch(getReelsList.endpoints.getReelsList.initiate({regionIds}));

    if ((reelList.isError && isRtkqError(reelList.error) && reelList.error.status === 404) || !reelList.data) {
        return true;
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
