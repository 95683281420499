import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updatePropertyConfiguratorMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const hasParams = !!Object.keys(ctx.route.query).length;
    const metaData = updateMetaData({
        title: `${concatWithBrandName("Konfigurator mieszkania -")}`,
        description: `Skonfiguruj swoje mieszkanie z ${BRAND_NAME} - określ powierzchnię, wybierz liczbę pokoi, wskaż dodatkowe pomieszczenia i przeglądaj dostępne oferty!`,
        pathname: ctx.route.pathname,
        ...(hasParams && {robots: "noindex, follow"})
    });
    dispatch(metaData);
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updatePropertyConfiguratorMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const hasParams = !!Object.keys(ctx.route.query).length;
    const metaData = updateMetaData({
        title: `${concatWithBrandName("Konfigurator mieszkania -")}`,
        description: `Skonfiguruj swoje mieszkanie z ${BRAND_NAME} - określ powierzchnię, wybierz liczbę pokoi, wskaż dodatkowe pomieszczenia i przeglądaj dostępne oferty!`,
        pathname: ctx.route.pathname,
        ...(hasParams && {robots: "noindex, follow"})
    });
    dispatch(metaData);
    return true;
};
