import {appendQueryString} from "@pg-mono/request";

export function createNoParamLinkWithScenario<TScenario>(apiPathElement: string) {
    return (scenario: TScenario): string => appendQueryString(apiPathElement, {s: scenario as unknown as string});
}
import {appendQueryString} from "@pg-mono/request";

export function createNoParamLinkWithScenario<TScenario>(apiPathElement: string) {
    return (scenario: TScenario): string => appendQueryString(apiPathElement, {s: scenario as unknown as string});
}
