import {SET_VIEW_TYPE} from "../constants/set_view_type";
import {IViewTypeMetaData} from "../view_type_actions";
import {ViewType} from "../ViewType";

export function setViewTypeAction(viewType: ViewType, data: Partial<IViewTypeMetaData> = {}) {
    return {type: SET_VIEW_TYPE, current: viewType, data};
}
import {SET_VIEW_TYPE} from "../constants/set_view_type";
import {IViewTypeMetaData} from "../view_type_actions";
import {ViewType} from "../ViewType";

export function setViewTypeAction(viewType: ViewType, data: Partial<IViewTypeMetaData> = {}) {
    return {type: SET_VIEW_TYPE, current: viewType, data};
}
