import React from "react";
import {Props as ReactModalProps} from "react-modal";
import classNames from "classnames";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {borderRadiusStyle} from "./styles/border_radius_style";
import {fullScreenStyle} from "./styles/full_screen_style";

import * as style from "./ContentElement.module.css";

export const ContentElement =
    (baseModalProps: IModalProps): ReactModalProps["contentElement"] =>
    (props, children) => {
        const {fullScreen, borderRadius: borderRadiusValue} = getPropsWithDefault(baseModalProps);

        const cn = classNames(props.className, style.contentElement, fullScreen && fullScreenStyle(fullScreen), fullScreen !== true && style.borderRadius);

        return (
            <div {...props} style={borderRadiusValue ? borderRadiusStyle(borderRadiusValue) : undefined} className={cn}>
                {children}
            </div>
        );
    };
import React from "react";
import {Props as ReactModalProps} from "react-modal";
import classNames from "classnames";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {borderRadiusStyle} from "./styles/border_radius_style";
import {fullScreenStyle} from "./styles/full_screen_style";

import * as style from "./ContentElement.module.css";

export const ContentElement =
    (baseModalProps: IModalProps): ReactModalProps["contentElement"] =>
    (props, children) => {
        const {fullScreen, borderRadius: borderRadiusValue} = getPropsWithDefault(baseModalProps);

        const cn = classNames(props.className, style.contentElement, fullScreen && fullScreenStyle(fullScreen), fullScreen !== true && style.borderRadius);

        return (
            <div {...props} style={borderRadiusValue ? borderRadiusStyle(borderRadiusValue) : undefined} className={cn}>
                {children}
            </div>
        );
    };
