export const offerUrlConstants = {
    sort: {
        // WARNING: there is a difference between null and undefined. Undefined values will cause offerUrlParser to classify slug as incorrect
        nowe: null,
        tanie: null,
        gotowe: null
    } as Record<string, number | null>,
    types: {
        "mieszkania-i-domy": null,
        mieszkania: 1,
        domy: 2,
        "lokale-uzytkowe": 3
    } as Record<string, number | null>,

    rooms: {
        jednopokojowe: 1,
        dwupokojowe: 2,
        trzypokojowe: 3,
        czteropokojowe: 4,
        pieciopokojowe: 5,
        "pieciopokojowe-i-wiecej": 5
    } as Record<string, number>
};
export const offerUrlConstants = {
    sort: {
        // WARNING: there is a difference between null and undefined. Undefined values will cause offerUrlParser to classify slug as incorrect
        nowe: null,
        tanie: null,
        gotowe: null
    } as Record<string, number | null>,
    types: {
        "mieszkania-i-domy": null,
        mieszkania: 1,
        domy: 2,
        "lokale-uzytkowe": 3
    } as Record<string, number | null>,

    rooms: {
        jednopokojowe: 1,
        dwupokojowe: 2,
        trzypokojowe: 3,
        czteropokojowe: 4,
        pieciopokojowe: 5,
        "pieciopokojowe-i-wiecej": 5
    } as Record<string, number>
};
