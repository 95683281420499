export const SearchInfoWindowCitiesList = [
    {label: "Warszawa", id: 8647, region: "warszawa", regionFull: "mazowieckie, Warszawa", offerCount: 12999, vendorCount: 159},
    {label: "Wrocław", id: 26955, region: "wroclaw", regionFull: "dolnośląskie, Wrocław", offerCount: 6893, vendorCount: 69},
    {label: "Kraków", id: 11158, region: "krakow", regionFull: "małopolskie, Kraków", offerCount: 5736, vendorCount: 101},
    {label: "Poznań", id: 30892, region: "poznan", regionFull: "wielkopolskie, Poznań", offerCount: 5004, vendorCount: 53},
    {label: "Łódź", id: 16187, region: "lodz", regionFull: "łódzkie, Łódź", offerCount: 3437, vendorCount: 65}
];

export const SearchInfoWindowCitiesListSpain = [
    {label: "Malaga", id: 54370, region: "malaga", regionFull: "Malaga", offerCount: 718, vendorCount: 4},
    {label: "Alicante", id: 54342, region: "alicante", regionFull: "Alicante", offerCount: 573, vendorCount: 3},
    {label: "Torrevieja", id: 54351, region: "torrevieja", regionFull: "Alicante, Torrevieja", offerCount: 177, vendorCount: 2},
    {label: "Murcja", id: 54437, region: "murcja", regionFull: "Murcja", offerCount: 100, vendorCount: 2},
    {label: "Marbella", id: 54371, region: "marbella", regionFull: "Malaga, Marbella", offerCount: 30, vendorCount: 3}
];
export const SearchInfoWindowCitiesList = [
    {label: "Warszawa", id: 8647, region: "warszawa", regionFull: "mazowieckie, Warszawa", offerCount: 12999, vendorCount: 159},
    {label: "Wrocław", id: 26955, region: "wroclaw", regionFull: "dolnośląskie, Wrocław", offerCount: 6893, vendorCount: 69},
    {label: "Kraków", id: 11158, region: "krakow", regionFull: "małopolskie, Kraków", offerCount: 5736, vendorCount: 101},
    {label: "Poznań", id: 30892, region: "poznan", regionFull: "wielkopolskie, Poznań", offerCount: 5004, vendorCount: 53},
    {label: "Łódź", id: 16187, region: "lodz", regionFull: "łódzkie, Łódź", offerCount: 3437, vendorCount: 65}
];

export const SearchInfoWindowCitiesListSpain = [
    {label: "Malaga", id: 54370, region: "malaga", regionFull: "Malaga", offerCount: 718, vendorCount: 4},
    {label: "Alicante", id: 54342, region: "alicante", regionFull: "Alicante", offerCount: 573, vendorCount: 3},
    {label: "Torrevieja", id: 54351, region: "torrevieja", regionFull: "Alicante, Torrevieja", offerCount: 177, vendorCount: 2},
    {label: "Murcja", id: 54437, region: "murcja", regionFull: "Murcja", offerCount: 100, vendorCount: 2},
    {label: "Marbella", id: 54371, region: "marbella", regionFull: "Malaga, Marbella", offerCount: 30, vendorCount: 3}
];
