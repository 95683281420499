import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {fetchPartnersListTypes, IPartner} from "../actions/fetch_partners_list";

export interface IPartnersListStore {
    list: IPartner[];
}

export const partnersListReducer = combineReducers({
    list: reduceResponse<IPartner[]>(fetchPartnersListTypes, [])
});
import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {fetchPartnersListTypes, IPartner} from "../actions/fetch_partners_list";

export interface IPartnersListStore {
    list: IPartner[];
}

export const partnersListReducer = combineReducers({
    list: reduceResponse<IPartner[]>(fetchPartnersListTypes, [])
});
