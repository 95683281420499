import React, {CSSProperties} from "react";
import {useContext} from "react";
import classNames from "classnames";

import {CloseIcon} from "@pg-design/icons-module";

import {ModalContext} from "../ModalContext";

import * as styles from "./ModalCloseButton.module.css";

interface IModalCloseButtonProps {
    placement?: "left" | "right";
    className?: string;
    fillColor?: string;
    wrapperColor?: string;
    size?: string;
    style?: CSSProperties;
}

export const ModalCloseButton = (props: IModalCloseButtonProps) => {
    const {onModalClose} = useContext(ModalContext);

    const size = props.size ?? "2.4";
    const fillColor = props.fillColor ?? "var(--colors.secondary)";
    const backgroundColorValue = props.wrapperColor ?? "transparent";

    const cn = classNames(props.className, styles.base, props.placement && styles[props.placement]);

    return (
        <div role="button" onClick={onModalClose} className={cn} style={{...props.style, backgroundColor: backgroundColorValue}}>
            <CloseIcon size={size} wrapperSize={size} wrapperColor={props.wrapperColor} fill={fillColor} />
        </div>
    );
};
import React, {CSSProperties} from "react";
import {useContext} from "react";
import classNames from "classnames";

import {CloseIcon} from "@pg-design/icons-module";

import {ModalContext} from "../ModalContext";

import * as styles from "./ModalCloseButton.module.css";

interface IModalCloseButtonProps {
    placement?: "left" | "right";
    className?: string;
    fillColor?: string;
    wrapperColor?: string;
    size?: string;
    style?: CSSProperties;
}

export const ModalCloseButton = (props: IModalCloseButtonProps) => {
    const {onModalClose} = useContext(ModalContext);

    const size = props.size ?? "2.4";
    const fillColor = props.fillColor ?? "var(--colors.secondary)";
    const backgroundColorValue = props.wrapperColor ?? "transparent";

    const cn = classNames(props.className, styles.base, props.placement && styles[props.placement]);

    return (
        <div role="button" onClick={onModalClose} className={cn} style={{...props.style, backgroundColor: backgroundColorValue}}>
            <CloseIcon size={size} wrapperSize={size} wrapperColor={props.wrapperColor} fill={fillColor} />
        </div>
    );
};
