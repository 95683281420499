import {compile} from "path-to-regexp";

import {isEmpty} from "@pg-mono/nodash";

import {promotionListRegex} from "./promotion_url_parser";
import {PromotionType} from "./PromotionType";

export interface IFriendlyPromotionQuery {
    type?: PromotionType;
    region?: string;
}

export const getPromotionSlugByType = (type?: PromotionType): string => {
    switch (type) {
        case PromotionType.OFFER:
            return "oferty-promocyjne";
        case PromotionType.OPENDAYS:
            return "dni-otwarte";
        case PromotionType.DISCOUNT:
            return "przeceny";
        case PromotionType.LASTMINUTE:
            return "last-minute";
        case PromotionType.PRESALE:
            return "przedsprzedaz";
        case PromotionType.EXHIBITION:
            return "targi-mieszkaniowe";
        default:
            return "";
    }
};

// Promotion search friendly slug builder
export const buildFriendlyPromotionSlug = (data: Partial<IFriendlyPromotionQuery>): string => {
    const compiler = compile(promotionListRegex);
    const {region, type} = data;
    const values = {
        region: region || "",
        beforeTypeDash: "-",
        beforeRegionDash: !isEmpty(region) && "-",
        type: getPromotionSlugByType(type)
    };

    let builtUrl = compiler(values);

    // We remove "-" if it is the first or the last character of the string
    if (builtUrl.startsWith("-")) {
        builtUrl = builtUrl.substr(1);
    }

    if (builtUrl.endsWith("-")) {
        builtUrl = builtUrl.substr(0, builtUrl.length - 1);
    }

    return builtUrl;
};
import {compile} from "path-to-regexp";

import {isEmpty} from "@pg-mono/nodash";

import {promotionListRegex} from "./promotion_url_parser";
import {PromotionType} from "./PromotionType";

export interface IFriendlyPromotionQuery {
    type?: PromotionType;
    region?: string;
}

export const getPromotionSlugByType = (type?: PromotionType): string => {
    switch (type) {
        case PromotionType.OFFER:
            return "oferty-promocyjne";
        case PromotionType.OPENDAYS:
            return "dni-otwarte";
        case PromotionType.DISCOUNT:
            return "przeceny";
        case PromotionType.LASTMINUTE:
            return "last-minute";
        case PromotionType.PRESALE:
            return "przedsprzedaz";
        case PromotionType.EXHIBITION:
            return "targi-mieszkaniowe";
        default:
            return "";
    }
};

// Promotion search friendly slug builder
export const buildFriendlyPromotionSlug = (data: Partial<IFriendlyPromotionQuery>): string => {
    const compiler = compile(promotionListRegex);
    const {region, type} = data;
    const values = {
        region: region || "",
        beforeTypeDash: "-",
        beforeRegionDash: !isEmpty(region) && "-",
        type: getPromotionSlugByType(type)
    };

    let builtUrl = compiler(values);

    // We remove "-" if it is the first or the last character of the string
    if (builtUrl.startsWith("-")) {
        builtUrl = builtUrl.substr(1);
    }

    if (builtUrl.endsWith("-")) {
        builtUrl = builtUrl.substr(0, builtUrl.length - 1);
    }

    return builtUrl;
};
