import React from "react";
import classNames from "classnames";

import {Text, TextVariantUnionType} from "@pg-design/text-module";

import {linkStyle, underlineStyle} from "./Link.module.css";

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    className?: string;
    children: React.ReactNode;
    href?: string;
    underline?: boolean;
    size?: ILinkSize;
    color?: string;
}

type ILinkSize = "x-small" | "small" | "big" | "inherit";

const getTextVariant: (size?: ILinkSize) => TextVariantUnionType = (size) => {
    switch (size) {
        case "x-small":
            return "info_txt_2";

        case "small":
            return "info_txt_1";

        case "big":
            return "body_copy_1";
    }

    //  Default, mid
    return "body_copy_2";
};

export const Link = (props: IProps) => {
    const {children, size, color, underline, className, ...restProps} = props;

    const colorStyle = {color: color || "var(--colors-secondary)"};
    const classname = underline ? classNames(linkStyle, colorStyle, underlineStyle, className) : classNames(linkStyle, colorStyle, className);

    return (
        <a {...restProps} className={classname}>
            {size === "inherit" ? (
                children
            ) : (
                <Text variant={getTextVariant(size)} as="span">
                    {children}
                </Text>
            )}
        </a>
    );
};
import React from "react";
import classNames from "classnames";

import {Text, TextVariantUnionType} from "@pg-design/text-module";

import {linkStyle, underlineStyle} from "./Link.module.css";

interface IProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    className?: string;
    children: React.ReactNode;
    href?: string;
    underline?: boolean;
    size?: ILinkSize;
    color?: string;
}

type ILinkSize = "x-small" | "small" | "big" | "inherit";

const getTextVariant: (size?: ILinkSize) => TextVariantUnionType = (size) => {
    switch (size) {
        case "x-small":
            return "info_txt_2";

        case "small":
            return "info_txt_1";

        case "big":
            return "body_copy_1";
    }

    //  Default, mid
    return "body_copy_2";
};

export const Link = (props: IProps) => {
    const {children, size, color, underline, className, ...restProps} = props;

    const colorStyle = {color: color || "var(--colors-secondary)"};
    const classname = underline ? classNames(linkStyle, colorStyle, underlineStyle, className) : classNames(linkStyle, colorStyle, className);

    return (
        <a {...restProps} className={classname}>
            {size === "inherit" ? (
                children
            ) : (
                <Text variant={getTextVariant(size)} as="span">
                    {children}
                </Text>
            )}
        </a>
    );
};
