import {Dispatch} from "redux";

import {appendQueryString, catch400, catchStalled, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {createDebouncedAction} from "../../store/utils/create_debounced_action";

const FETCH_REGION_LIST = "offer/FETCH_REGION_LIST";
export const fetchRegionListTypes = createRequestActionTypes(FETCH_REGION_LIST);

export interface IRegionListRegion {
    full_name: string;
    full_name_reverted: string;
    id: number;
    is_leaf_node: boolean;
    name: string;
    name_declension_what: string;
    name_declension_where: string;
    parent: number;
    short_name: string;
    slug: string;
    stats: {
        promotions_count_total: number;
        properties_all_count_for_sale: number;
        vendors_count_for_sale: number;
        region_type_voivodeship: {
            name: string;
        };
        region_type_city?: {
            name: string;
        };
        region_type_district?: {
            name: string;
        };
    };
    tree: {
        id: number;
        left: number;
        right: number;
    };
    type: RegionType;
    area: {
        center: {
            lat: number;
            lon: number;
        };
        radius: number;
    };
    country?: Country | null;
}

export interface IRegionListResponse {
    count: number;
    page_size: number;
    results: IRegionListRegion[];
}

const regionListApiLink = apiV2ListLink.region.list(Scenario.SEARCH_REGION_DETAIL);

const fetchRegionListSimple =
    (fullName: string, query: Record<string, string> = {}) =>
    (dispatch: Dispatch) => {
        dispatch({type: fetchRegionListTypes.start});

        const url = appendQueryString(regionListApiLink, {
            full_name: fullName,
            page_size: 20,
            has_properties_for_sale: "True",
            // Poland should be a default region, but we want to search also for abroad regions (e.g. Spain)
            // country param should be passed with query then
            country: Country.POLAND,
            ...query
        });
        return getRequest({}, url)
            .then((response: IRegionListResponse) => {
                dispatch({type: fetchRegionListTypes.success, result: response.results});
                return response; // important for batched fetch
            })
            .catch(
                catch400((err) => {
                    dispatch({type: fetchRegionListTypes.error, err});
                })
            )
            .catch(catchStalled((err) => console.warn("Response stalled. Error: ", err)));
    };

export const {action: fetchRegionList, clear: stopFetchRegionList} = createDebouncedAction(fetchRegionListSimple, 500);
export const resetRegionList = () => ({type: fetchRegionListTypes.reset});

/**
 * Single region fetch
 */
// we keep this action alongside fetching for search because it should fetch similar objects (interfaces)

export const fetchSingleRegionSimple = async (id: number): Promise<IRegionListRegion> => {
    const regionDetailApiLink = apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId: id});
    return getRequest({}, regionDetailApiLink).then((res: IRegionListRegion) => {
        return res;
    });
};
import {Dispatch} from "redux";

import {appendQueryString, catch400, catchStalled, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {createDebouncedAction} from "../../store/utils/create_debounced_action";

const FETCH_REGION_LIST = "offer/FETCH_REGION_LIST";
export const fetchRegionListTypes = createRequestActionTypes(FETCH_REGION_LIST);

export interface IRegionListRegion {
    full_name: string;
    full_name_reverted: string;
    id: number;
    is_leaf_node: boolean;
    name: string;
    name_declension_what: string;
    name_declension_where: string;
    parent: number;
    short_name: string;
    slug: string;
    stats: {
        promotions_count_total: number;
        properties_all_count_for_sale: number;
        vendors_count_for_sale: number;
        region_type_voivodeship: {
            name: string;
        };
        region_type_city?: {
            name: string;
        };
        region_type_district?: {
            name: string;
        };
    };
    tree: {
        id: number;
        left: number;
        right: number;
    };
    type: RegionType;
    area: {
        center: {
            lat: number;
            lon: number;
        };
        radius: number;
    };
    country?: Country | null;
}

export interface IRegionListResponse {
    count: number;
    page_size: number;
    results: IRegionListRegion[];
}

const regionListApiLink = apiV2ListLink.region.list(Scenario.SEARCH_REGION_DETAIL);

const fetchRegionListSimple =
    (fullName: string, query: Record<string, string> = {}) =>
    (dispatch: Dispatch) => {
        dispatch({type: fetchRegionListTypes.start});

        const url = appendQueryString(regionListApiLink, {
            full_name: fullName,
            page_size: 20,
            has_properties_for_sale: "True",
            // Poland should be a default region, but we want to search also for abroad regions (e.g. Spain)
            // country param should be passed with query then
            country: Country.POLAND,
            ...query
        });
        return getRequest({}, url)
            .then((response: IRegionListResponse) => {
                dispatch({type: fetchRegionListTypes.success, result: response.results});
                return response; // important for batched fetch
            })
            .catch(
                catch400((err) => {
                    dispatch({type: fetchRegionListTypes.error, err});
                })
            )
            .catch(catchStalled((err) => console.warn("Response stalled. Error: ", err)));
    };

export const {action: fetchRegionList, clear: stopFetchRegionList} = createDebouncedAction(fetchRegionListSimple, 500);
export const resetRegionList = () => ({type: fetchRegionListTypes.reset});

/**
 * Single region fetch
 */
// we keep this action alongside fetching for search because it should fetch similar objects (interfaces)

export const fetchSingleRegionSimple = async (id: number): Promise<IRegionListRegion> => {
    const regionDetailApiLink = apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId: id});
    return getRequest({}, regionDetailApiLink).then((res: IRegionListRegion) => {
        return res;
    });
};
