import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IRangeQuery} from "../../../request/IRangeQuery";

const FETCH_OFFER_DETAIL_PROMOTIONS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_PROMOTIONS";
export const fetchOfferDetailPromotionsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_PROMOTIONS_PREFIX);

export interface IOfferDetailPromotion {
    description: string | null;
    id: number;
    name: string;
    offer: number;
    promotion_date_range: IRangeQuery<string | null> | null;
    type: number;
}

interface IOfferDetailPromotionsResponse {
    results: {
        promotions: IOfferDetailPromotion[];
    };
}

export const fetchOfferDetailPromotionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {offer} = getState().offerDetail;
    dispatch({type: fetchOfferDetailPromotionsTypes.start});
    const url = appendQueryString(apiV2ListLink.promotion.list(Scenario.PROMOTION_LIST), {
        offer: offer?.id
    });
    return getRequest(ctx.meta, url).then((res: IOfferDetailPromotionsResponse) => {
        dispatch({type: fetchOfferDetailPromotionsTypes.success, result: res.results});
        return res;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IRangeQuery} from "../../../request/IRangeQuery";

const FETCH_OFFER_DETAIL_PROMOTIONS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_PROMOTIONS";
export const fetchOfferDetailPromotionsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_PROMOTIONS_PREFIX);

export interface IOfferDetailPromotion {
    description: string | null;
    id: number;
    name: string;
    offer: number;
    promotion_date_range: IRangeQuery<string | null> | null;
    type: number;
}

interface IOfferDetailPromotionsResponse {
    results: {
        promotions: IOfferDetailPromotion[];
    };
}

export const fetchOfferDetailPromotionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {offer} = getState().offerDetail;
    dispatch({type: fetchOfferDetailPromotionsTypes.start});
    const url = appendQueryString(apiV2ListLink.promotion.list(Scenario.PROMOTION_LIST), {
        offer: offer?.id
    });
    return getRequest(ctx.meta, url).then((res: IOfferDetailPromotionsResponse) => {
        dispatch({type: fetchOfferDetailPromotionsTypes.success, result: res.results});
        return res;
    });
};
