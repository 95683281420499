import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchMapSelectedOfferTypes} from "../actions/fetch_map_offer_detail";
import {IMapOfferSidebar} from "../components/MapOfferSidebar";

export interface IMapSelectedOfferStore {
    data: IMapOfferSidebar;
    requestState: RequestState;
}

export const mapSelectedOfferReducer = combineReducers({
    data: reduceResponse<IMapOfferSidebar>(fetchMapSelectedOfferTypes),
    requestState: reduceRequestState(fetchMapSelectedOfferTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchMapSelectedOfferTypes} from "../actions/fetch_map_offer_detail";
import {IMapOfferSidebar} from "../components/MapOfferSidebar";

export interface IMapSelectedOfferStore {
    data: IMapOfferSidebar;
    requestState: RequestState;
}

export const mapSelectedOfferReducer = combineReducers({
    data: reduceResponse<IMapOfferSidebar>(fetchMapSelectedOfferTypes),
    requestState: reduceRequestState(fetchMapSelectedOfferTypes)
});
