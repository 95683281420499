import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef, useState} from "react";
import classNames from "classnames";

import {Button} from "@pg-design/button-module";
import {ChevronDownIcon} from "@pg-design/icons-module";

import {expandableCollapsedDefaultHeight, expandableDefaultAnimationTime} from "../constants/expandable_defaults";

import {
    buttonIconCollapsedStyle,
    buttonIconExpandedStyle,
    buttonIconStyle,
    buttonStyle,
    contentWrapperStyle,
    shadowStyle,
    wrapperStyle
} from "./Expandable.module.css";

interface IProps extends PropsWithChildren {
    isAlwaysExpanded?: boolean;
    collapsedHeight?: number;
    isExpandedByDefault?: boolean;
    customCollapseButton?: (props: {isExpanded: boolean; toggleCollapse: () => void}) => ReactNode;
    customShadowStyle?: CSSProperties;
}

const buttonHeight = 25;

export const Expandable = (props: IProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const collapsedHeight = props.collapsedHeight ?? expandableCollapsedDefaultHeight;

    const [states, setStates] = useState(() => ({
        isExpanded: !!props.isExpandedByDefault,
        height: collapsedHeight
    }));

    const getContentHeight = () => {
        return (contentRef.current?.scrollHeight || 0) + buttonHeight;
    };

    useEffect(() => {
        if (states.isExpanded) {
            // observe content height change
            const observer = new ResizeObserver(() => {
                setStates((prev) => {
                    return {
                        ...prev,
                        height: getContentHeight()
                    };
                });
            });

            observer.observe(contentRef.current as Element);

            return () => {
                observer.disconnect();
            };
        }
    }, [states.isExpanded]);

    const toggleCollapse = () => {
        setStates((prev) => {
            return {
                ...prev,
                height: prev.isExpanded ? collapsedHeight : getContentHeight(),
                isExpanded: !prev.isExpanded
            };
        });

        setTimeout(() => {
            setStates((prev) => {
                return {
                    ...prev,
                    height: prev.isExpanded ? getContentHeight() : collapsedHeight
                };
            });
        }, expandableDefaultAnimationTime);
    };

    const defaultAnimationTime: CSSProperties = {"--expandable-default-animation-time": `${expandableDefaultAnimationTime}ms`};
    const contentWrapperDynamicStyle: CSSProperties = props.isAlwaysExpanded
        ? defaultAnimationTime
        : {height: `${states.height}px`, "--expandable-default-animation-time": `${expandableDefaultAnimationTime}ms`};

    const buttonDynamicStyle: CSSProperties = states.isExpanded ? {transform: "none"} : {};
    const iconCN = classNames(buttonIconStyle, states.isExpanded ? buttonIconExpandedStyle : buttonIconCollapsedStyle);

    return (
        <div className={wrapperStyle}>
            <div className={contentWrapperStyle} style={contentWrapperDynamicStyle}>
                <div ref={contentRef}>{props.children}</div>
                <div
                    className={!props.isAlwaysExpanded ? shadowStyle : undefined}
                    style={!props.isAlwaysExpanded ? {...props.customShadowStyle, height: `${states.isExpanded ? 0 : "60%"}`} : props.customShadowStyle}
                />
            </div>

            {props.isAlwaysExpanded ? null : props.customCollapseButton ? (
                props.customCollapseButton({isExpanded: states.isExpanded, toggleCollapse})
            ) : (
                <Button size="small" variant="filled_primary" type="button" className={buttonStyle} style={buttonDynamicStyle} onClick={toggleCollapse}>
                    <ChevronDownIcon size="2.4" className={iconCN} style={defaultAnimationTime} />
                    {states.isExpanded ? "Zwiń" : "Rozwiń"}
                </Button>
            )}
        </div>
    );
};
import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useRef, useState} from "react";
import classNames from "classnames";

import {Button} from "@pg-design/button-module";
import {ChevronDownIcon} from "@pg-design/icons-module";

import {expandableCollapsedDefaultHeight, expandableDefaultAnimationTime} from "../constants/expandable_defaults";

import {
    buttonIconCollapsedStyle,
    buttonIconExpandedStyle,
    buttonIconStyle,
    buttonStyle,
    contentWrapperStyle,
    shadowStyle,
    wrapperStyle
} from "./Expandable.module.css";

interface IProps extends PropsWithChildren {
    isAlwaysExpanded?: boolean;
    collapsedHeight?: number;
    isExpandedByDefault?: boolean;
    customCollapseButton?: (props: {isExpanded: boolean; toggleCollapse: () => void}) => ReactNode;
    customShadowStyle?: CSSProperties;
}

const buttonHeight = 25;

export const Expandable = (props: IProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const collapsedHeight = props.collapsedHeight ?? expandableCollapsedDefaultHeight;

    const [states, setStates] = useState(() => ({
        isExpanded: !!props.isExpandedByDefault,
        height: collapsedHeight
    }));

    const getContentHeight = () => {
        return (contentRef.current?.scrollHeight || 0) + buttonHeight;
    };

    useEffect(() => {
        if (states.isExpanded) {
            // observe content height change
            const observer = new ResizeObserver(() => {
                setStates((prev) => {
                    return {
                        ...prev,
                        height: getContentHeight()
                    };
                });
            });

            observer.observe(contentRef.current as Element);

            return () => {
                observer.disconnect();
            };
        }
    }, [states.isExpanded]);

    const toggleCollapse = () => {
        setStates((prev) => {
            return {
                ...prev,
                height: prev.isExpanded ? collapsedHeight : getContentHeight(),
                isExpanded: !prev.isExpanded
            };
        });

        setTimeout(() => {
            setStates((prev) => {
                return {
                    ...prev,
                    height: prev.isExpanded ? getContentHeight() : collapsedHeight
                };
            });
        }, expandableDefaultAnimationTime);
    };

    const defaultAnimationTime: CSSProperties = {"--expandable-default-animation-time": `${expandableDefaultAnimationTime}ms`};
    const contentWrapperDynamicStyle: CSSProperties = props.isAlwaysExpanded
        ? defaultAnimationTime
        : {height: `${states.height}px`, "--expandable-default-animation-time": `${expandableDefaultAnimationTime}ms`};

    const buttonDynamicStyle: CSSProperties = states.isExpanded ? {transform: "none"} : {};
    const iconCN = classNames(buttonIconStyle, states.isExpanded ? buttonIconExpandedStyle : buttonIconCollapsedStyle);

    return (
        <div className={wrapperStyle}>
            <div className={contentWrapperStyle} style={contentWrapperDynamicStyle}>
                <div ref={contentRef}>{props.children}</div>
                <div
                    className={!props.isAlwaysExpanded ? shadowStyle : undefined}
                    style={!props.isAlwaysExpanded ? {...props.customShadowStyle, height: `${states.isExpanded ? 0 : "60%"}`} : props.customShadowStyle}
                />
            </div>

            {props.isAlwaysExpanded ? null : props.customCollapseButton ? (
                props.customCollapseButton({isExpanded: states.isExpanded, toggleCollapse})
            ) : (
                <Button size="small" variant="filled_primary" type="button" className={buttonStyle} style={buttonDynamicStyle} onClick={toggleCollapse}>
                    <ChevronDownIcon size="2.4" className={iconCN} style={defaultAnimationTime} />
                    {states.isExpanded ? "Zwiń" : "Rozwiń"}
                </Button>
            )}
        </div>
    );
};
