import React from "react";
import {hydrateRoot} from "react-dom/client";

import {IVisualRoute} from "../../common/app/types/IVisualRoute";
import {RpStore} from "../../common/store/store";
import {RpClientApp} from "../RpClientApp";

export const hydrateApp = async (providerStore: RpStore, container: Element, routes: IVisualRoute[]) => {
    const root = hydrateRoot(container, <RpClientApp initialStore={providerStore} routes={routes} />);

    //  Webpack HMR, only in dev mode
    if (module.hot) {
        module.hot.accept("../RpClientApp", () => {
            const RefreshedApp = require("../RpClientApp").RpClientApp;
            root.render(<RefreshedApp initialStore={providerStore} routes={routes} />);
        });
    }
};
import React from "react";
import {hydrateRoot} from "react-dom/client";

import {IVisualRoute} from "../../common/app/types/IVisualRoute";
import {RpStore} from "../../common/store/store";
import {RpClientApp} from "../RpClientApp";

export const hydrateApp = async (providerStore: RpStore, container: Element, routes: IVisualRoute[]) => {
    const root = hydrateRoot(container, <RpClientApp initialStore={providerStore} routes={routes} />);

    //  Webpack HMR, only in dev mode
    if (module.hot) {
        module.hot.accept("../RpClientApp", () => {
            const RefreshedApp = require("../RpClientApp").RpClientApp;
            root.render(<RefreshedApp initialStore={providerStore} routes={routes} />);
        });
    }
};
