import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {getCountryId} from "../helpers/sub_type/get_fetch_offer_list_query";

const FETCH_OFFER_LIST_RECOMMENDED_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_RECOMMENDED_REGIONS";
export const fetchOfferListRecommendedRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_RECOMMENDED_REGIONS_PREFIX);

export interface IVoivodeshipRegion {
    country: number;
    full_name: string;
    full_name_reverted: string;
    id: number;
    name: string;
    name_declension_about: string;
    name_declension_what: string;
    name_declension_where: string;
    name_declension_whom: string;
    parent: number;
    short_name: string;
    short_name_reverted: string;
    slug: string;
    type: number;
    properties_count: number;
}

export interface IRecommendedRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface IOfferListRecommendedRegionsResponse {
    results: IRecommendedRegion[];
}

const fetchOfferListRecommendedRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        const country = ctx.prevResult.country;

        // do not fetch data on "nowe mieszkania i domy" listing
        if ((!region && !country) || (ctx.match.params.friendlySlug === "nowe-mieszkania-i-domy" && !region)) {
            return false;
        }

        // we are using nearby_regions as query param to avoid getting associate regions if we'll use recommended_regions
        const query = {
            country: country ?? Country.POLAND,
            ...(getCountryId(country) === Country.SPAIN && {type: RegionType.VOIVODESHIP}),
            ...(region && {nearby_regions: region[0]})
        };
        if (isEqual(query, getState().offerList.recommendedRegionsLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.recommendedRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const recommendedRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

        const url = appendQueryString(recommendedRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListRecommendedRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListRecommendedRegions(fetchOfferListRecommendedRegionsTypes)(ctx);
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {getCountryId} from "../helpers/sub_type/get_fetch_offer_list_query";

const FETCH_OFFER_LIST_RECOMMENDED_REGIONS_PREFIX = "offer_list/FETCH_OFFER_LIST_RECOMMENDED_REGIONS";
export const fetchOfferListRecommendedRegionsTypes = createRequestActionTypes(FETCH_OFFER_LIST_RECOMMENDED_REGIONS_PREFIX);

export interface IVoivodeshipRegion {
    country: number;
    full_name: string;
    full_name_reverted: string;
    id: number;
    name: string;
    name_declension_about: string;
    name_declension_what: string;
    name_declension_where: string;
    name_declension_whom: string;
    parent: number;
    short_name: string;
    short_name_reverted: string;
    slug: string;
    type: number;
    properties_count: number;
}

export interface IRecommendedRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface IOfferListRecommendedRegionsResponse {
    results: IRecommendedRegion[];
}

const fetchOfferListRecommendedRegions =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const region = ctx.prevResult.region;
        const country = ctx.prevResult.country;

        // do not fetch data on "nowe mieszkania i domy" listing
        if ((!region && !country) || (ctx.match.params.friendlySlug === "nowe-mieszkania-i-domy" && !region)) {
            return false;
        }

        // we are using nearby_regions as query param to avoid getting associate regions if we'll use recommended_regions
        const query = {
            country: country ?? Country.POLAND,
            ...(getCountryId(country) === Country.SPAIN && {type: RegionType.VOIVODESHIP}),
            ...(region && {nearby_regions: region[0]})
        };
        if (isEqual(query, getState().offerList.recommendedRegionsLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.recommendedRegions;
        }

        dispatch({type: actionType.start, latestQuery: query});
        const recommendedRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

        const url = appendQueryString(recommendedRegionsListApiLink, query);
        return getRequest(ctx.meta, url)
            .then((res: IOfferListRecommendedRegionsResponse) => {
                dispatch({type: actionType.success, result: res.results});
                return res.results;
            })
            .catch(
                catch400((error) => {
                    dispatch({type: actionType.error, error: error.appError});
                })
            );
    };

export const fetchOfferListRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListRecommendedRegions(fetchOfferListRecommendedRegionsTypes)(ctx);
};
