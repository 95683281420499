import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, getRequest, IQueryValue, Response400} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

const ARTICLES_WRITTEN_BY_AUTHOR_PREFIX = "articles_written_by_author/fetch";
export const fetchArticlesWrittenByAuthorTypes = createRequestActionTypes(ARTICLES_WRITTEN_BY_AUTHOR_PREFIX);
export const ARTICLE_LIST_PAGE_SIZE = 15;
import {IArticleListArticle} from "./fetch_article_list";

interface IArticleListResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}

const articleListApiLink = apiV2ListLink.article.list;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchArticlesWrittenByAuthorAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const page = parseInt(ctx.route.query.page as string, 10) || 1;
    const offset = ARTICLE_LIST_PAGE_SIZE * (page - 1);
    const sort = ctx.route.query.sort === "rating" ? "rating" : "all";

    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        limit: ARTICLE_LIST_PAGE_SIZE,
        author: ctx.match.params.authorId,
        sort,
        offset
    };

    dispatch({
        type: fetchArticlesWrittenByAuthorTypes.start,
        latestQuery: query as Record<string, string[]>
    });

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            // when we send LIMIT and OFFSET - we do not send page, therefore cannot get 404 response on Empty list. This is a workaround
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles,
                page: page,
                count: json.count,
                page_size: json.page_size || ARTICLE_LIST_PAGE_SIZE
            };
            dispatch({type: fetchArticlesWrittenByAuthorTypes.success, result});
            return json;
        })
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnAuthorDetailArticles(ctx, err));
            })
        );
};

const handleError400OnAuthorDetailArticles = (ctx: IFetchContext<IRPRequestMeta>, err: Response400) => async (dispatch: Dispatch) => {
    // bad request - can occur when someone removes article category, but we are still fetching that ID from api
    notifyBugsnag({message: `Error 400 fetching articles for author id: ${ctx.match.params.authorId}`}, err.message);
    consoleError(`Error 400 fetching articles for author id: ${ctx.match.params.authorId}`, "; route: ", ctx.route, "; API error.message: ", err.message);
    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
    return false;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, getRequest, IQueryValue, Response400} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

const ARTICLES_WRITTEN_BY_AUTHOR_PREFIX = "articles_written_by_author/fetch";
export const fetchArticlesWrittenByAuthorTypes = createRequestActionTypes(ARTICLES_WRITTEN_BY_AUTHOR_PREFIX);
export const ARTICLE_LIST_PAGE_SIZE = 15;
import {IArticleListArticle} from "./fetch_article_list";

interface IArticleListResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}

const articleListApiLink = apiV2ListLink.article.list;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchArticlesWrittenByAuthorAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const page = parseInt(ctx.route.query.page as string, 10) || 1;
    const offset = ARTICLE_LIST_PAGE_SIZE * (page - 1);
    const sort = ctx.route.query.sort === "rating" ? "rating" : "all";

    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        limit: ARTICLE_LIST_PAGE_SIZE,
        author: ctx.match.params.authorId,
        sort,
        offset
    };

    dispatch({
        type: fetchArticlesWrittenByAuthorTypes.start,
        latestQuery: query as Record<string, string[]>
    });

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            // when we send LIMIT and OFFSET - we do not send page, therefore cannot get 404 response on Empty list. This is a workaround
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles,
                page: page,
                count: json.count,
                page_size: json.page_size || ARTICLE_LIST_PAGE_SIZE
            };
            dispatch({type: fetchArticlesWrittenByAuthorTypes.success, result});
            return json;
        })
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnAuthorDetailArticles(ctx, err));
            })
        );
};

const handleError400OnAuthorDetailArticles = (ctx: IFetchContext<IRPRequestMeta>, err: Response400) => async (dispatch: Dispatch) => {
    // bad request - can occur when someone removes article category, but we are still fetching that ID from api
    notifyBugsnag({message: `Error 400 fetching articles for author id: ${ctx.match.params.authorId}`}, err.message);
    consoleError(`Error 400 fetching articles for author id: ${ctx.match.params.authorId}`, "; route: ", ctx.route, "; API error.message: ", err.message);
    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
    return false;
};
