/* eslint-disable @typescript-eslint/no-explicit-any */

import {FormFieldType} from "@pg-mono/form";

import {getPhoneDataFromString} from "../utils/get_phone_data_from_string";
import {strEmpty} from "../utils/str_empty";

export const fromJsonValues = (formFields: Record<string, FormFieldType>, jsonValues: Record<string, any>) => {
    return Object.entries(formFields).reduce((acc, [field, fieldType]) => {
        switch (fieldType) {
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
                return {...acc, [field]: jsonValues[field] ?? undefined};
            case FormFieldType.Input:
            case FormFieldType.Radio:
            case FormFieldType.Select:
            case FormFieldType.Textarea:
                return {...acc, [field]: jsonValues[field] ?? ""};
            case FormFieldType.SelectRange:
            case FormFieldType.InputRange:
            case FormFieldType.SelectRangeNumber:
                return {
                    ...acc,
                    [field]: {
                        lower: jsonValues[field]?.lower ?? "",
                        upper: jsonValues[field]?.upper ?? ""
                    }
                };
            case FormFieldType.SearchOfferType:
                if (jsonValues[field] == null) {
                    return {...acc, [field]: []};
                }
                const arrayValues = Array.isArray(jsonValues[field]) ? jsonValues[field] : [jsonValues[field]];
                return {...acc, [field]: arrayValues};
            case FormFieldType.PhoneField:
                return {
                    ...acc,
                    [field]: getPhoneDataFromString(jsonValues[field])
                };
            case FormFieldType.Rooms:
                return {...acc, [field]: strEmpty(jsonValues[field], [])};
            default:
                throw new Error(`fromJsonValues: not implemented for ${field}, formFieldType is ${fieldType}`);
        }
    }, {});
};
/* eslint-disable @typescript-eslint/no-explicit-any */

import {FormFieldType} from "@pg-mono/form";

import {getPhoneDataFromString} from "../utils/get_phone_data_from_string";
import {strEmpty} from "../utils/str_empty";

export const fromJsonValues = (formFields: Record<string, FormFieldType>, jsonValues: Record<string, any>) => {
    return Object.entries(formFields).reduce((acc, [field, fieldType]) => {
        switch (fieldType) {
            case FormFieldType.Checkbox:
            case FormFieldType.MultiCheckbox:
                return {...acc, [field]: jsonValues[field] ?? undefined};
            case FormFieldType.Input:
            case FormFieldType.Radio:
            case FormFieldType.Select:
            case FormFieldType.Textarea:
                return {...acc, [field]: jsonValues[field] ?? ""};
            case FormFieldType.SelectRange:
            case FormFieldType.InputRange:
            case FormFieldType.SelectRangeNumber:
                return {
                    ...acc,
                    [field]: {
                        lower: jsonValues[field]?.lower ?? "",
                        upper: jsonValues[field]?.upper ?? ""
                    }
                };
            case FormFieldType.SearchOfferType:
                if (jsonValues[field] == null) {
                    return {...acc, [field]: []};
                }
                const arrayValues = Array.isArray(jsonValues[field]) ? jsonValues[field] : [jsonValues[field]];
                return {...acc, [field]: arrayValues};
            case FormFieldType.PhoneField:
                return {
                    ...acc,
                    [field]: getPhoneDataFromString(jsonValues[field])
                };
            case FormFieldType.Rooms:
                return {...acc, [field]: strEmpty(jsonValues[field], [])};
            default:
                throw new Error(`fromJsonValues: not implemented for ${field}, formFieldType is ${fieldType}`);
        }
    }, {});
};
