import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS";
export const fetchOfferDetailRecommendedRegionsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS_PREFIX);

const FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS";
export const fetchPropertyDetailRecommendedRegionsTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS_PREFIX);

interface IOfferDetailRecommendedRegionsResponse {
    results: {
        regions: IRecommendedRegion[];
    };
}

export interface IRecommendedRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
    } | null;
}

const recommendedRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
const fetchOfferRecommendedRegions =
    (actionType: RequestActionTypes) => (meta: IRPRequestMeta, query: Record<string, number | string>) => (dispatch: Dispatch) => {
        dispatch({type: actionType.start});

        const url = appendQueryString(recommendedRegionsListApiLink, query);
        return getRequest(meta, url).then((res: IOfferDetailRecommendedRegionsResponse) => {
            dispatch({type: actionType.success, result: res.results});
            return res;
        });
    };
// we are using nearby_regions as query param to avoid getting associate regions if we'll use recommended_regions
export const fetchOfferDetailRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferRecommendedRegions(fetchOfferDetailRecommendedRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });

export const fetchPropertyDetailRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferRecommendedRegions(fetchPropertyDetailRecommendedRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS";
export const fetchOfferDetailRecommendedRegionsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_RECOMMENDED_REGIONS_PREFIX);

const FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS";
export const fetchPropertyDetailRecommendedRegionsTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_RECOMMENDED_REGIONS_PREFIX);

interface IOfferDetailRecommendedRegionsResponse {
    results: {
        regions: IRecommendedRegion[];
    };
}

export interface IRecommendedRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
    } | null;
}

const recommendedRegionsListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);
const fetchOfferRecommendedRegions =
    (actionType: RequestActionTypes) => (meta: IRPRequestMeta, query: Record<string, number | string>) => (dispatch: Dispatch) => {
        dispatch({type: actionType.start});

        const url = appendQueryString(recommendedRegionsListApiLink, query);
        return getRequest(meta, url).then((res: IOfferDetailRecommendedRegionsResponse) => {
            dispatch({type: actionType.success, result: res.results});
            return res;
        });
    };
// we are using nearby_regions as query param to avoid getting associate regions if we'll use recommended_regions
export const fetchOfferDetailRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferRecommendedRegions(fetchOfferDetailRecommendedRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });

export const fetchPropertyDetailRecommendedRegionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    fetchOfferRecommendedRegions(fetchPropertyDetailRecommendedRegionsTypes)(ctx.meta, {
        nearby_regions: ctx.prevResult.offer.region.id
    });
