import {Country} from "../../region/types/Country";
import {SearchTab} from "../actions/fetch_search_all_action";
import {IUpdateCurrentTabAction, UPDATE_CURRENT_TAB} from "../actions/update_current_tab";

const initialState: {currentTab: SearchTab; country?: Country} = {currentTab: SearchTab.Regions, country: Country.POLAND};
export const currentTabReducer = (state = initialState, action: IUpdateCurrentTabAction): {currentTab: SearchTab; country?: Country} => {
    switch (action.type) {
        case UPDATE_CURRENT_TAB:
            return action.payload;
        default:
            return state;
    }
};
import {Country} from "../../region/types/Country";
import {SearchTab} from "../actions/fetch_search_all_action";
import {IUpdateCurrentTabAction, UPDATE_CURRENT_TAB} from "../actions/update_current_tab";

const initialState: {currentTab: SearchTab; country?: Country} = {currentTab: SearchTab.Regions, country: Country.POLAND};
export const currentTabReducer = (state = initialState, action: IUpdateCurrentTabAction): {currentTab: SearchTab; country?: Country} => {
    switch (action.type) {
        case UPDATE_CURRENT_TAB:
            return action.payload;
        default:
            return state;
    }
};
