import {OFFER_LIST_PAGE_SIZE} from "../list/constants/offer_list";
import {IOfferModalState} from "../types/OfferModalState";

export enum PropertyListType {
    TABLE = "table",
    TILES = "tiles"
}

export const offerModalInitialState: IOfferModalState = {
    isOpen: false,
    offerId: 0,
    position: 1,
    listingPage: 1,
    listingPageSize: OFFER_LIST_PAGE_SIZE,
    query: null,
    sort: null,
    page: 1,
    pageSize: 6,
    filters: {
        rooms: {
            lower: "",
            upper: ""
        },
        area: {
            lower: "",
            upper: ""
        },
        floor_choices: []
    },
    sourceSection: null,
    type: "tiles"
};
import {OFFER_LIST_PAGE_SIZE} from "../list/constants/offer_list";
import {IOfferModalState} from "../types/OfferModalState";

export enum PropertyListType {
    TABLE = "table",
    TILES = "tiles"
}

export const offerModalInitialState: IOfferModalState = {
    isOpen: false,
    offerId: 0,
    position: 1,
    listingPage: 1,
    listingPageSize: OFFER_LIST_PAGE_SIZE,
    query: null,
    sort: null,
    page: 1,
    pageSize: 6,
    filters: {
        rooms: {
            lower: "",
            upper: ""
        },
        area: {
            lower: "",
            upper: ""
        },
        floor_choices: []
    },
    sourceSection: null,
    type: "tiles"
};
