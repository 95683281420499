import {useAppSelector} from "../../store/hooks";
import {useNotificationsRouteHandler} from "../utils/use_notifications_route_handler";
import {NotificationsReduxContainer} from "./NotificationsRedux";

export const Notifications = () => {
    const notifications = useAppSelector((store) => store.notifications.list);
    useNotificationsRouteHandler();

    return <NotificationsReduxContainer notifications={notifications} />;
};
import {useAppSelector} from "../../store/hooks";
import {useNotificationsRouteHandler} from "../utils/use_notifications_route_handler";
import {NotificationsReduxContainer} from "./NotificationsRedux";

export const Notifications = () => {
    const notifications = useAppSelector((store) => store.notifications.list);
    useNotificationsRouteHandler();

    return <NotificationsReduxContainer notifications={notifications} />;
};
