import {CtxType} from "../init_ab_testing_instance";
import {IABTestConfiguration} from "../interfaces/IABTestConfiguration";
import {specifyABTestVariant} from "../utils/ab_testing_ssr";

export const getABTestDataAction = (abTest: IABTestConfiguration, ctx: CtxType, domain: string): string => {
    const variant = specifyABTestVariant(abTest, domain, ctx.req, ctx.meta);

    return `${abTest.id}.${variant}`;
};
import {CtxType} from "../init_ab_testing_instance";
import {IABTestConfiguration} from "../interfaces/IABTestConfiguration";
import {specifyABTestVariant} from "../utils/ab_testing_ssr";

export const getABTestDataAction = (abTest: IABTestConfiguration, ctx: CtxType, domain: string): string => {
    const variant = specifyABTestVariant(abTest, domain, ctx.req, ctx.meta);

    return `${abTest.id}.${variant}`;
};
