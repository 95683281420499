export enum Attribute {
    USER_ID = "user_id",
    EMAIL = "email",
    NAME = "name",
    PHONE_NUMBER = "phone_number"
}
export enum Attribute {
    USER_ID = "user_id",
    EMAIL = "email",
    NAME = "name",
    PHONE_NUMBER = "phone_number"
}
