import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOnList404} from "../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {IPaginatedResponse} from "../../request/IResponse";
import {IVendorBox} from "../components/VendorBox";

const VENDOR_LIST_PREFIX = "vendor_list/fetch";
export const fetchVendorListTypes = createRequestActionTypes(VENDOR_LIST_PREFIX);

export const VENDOR_LIST_PAGE_SIZE = 24;
const THRESHOLD_MAX_STANDARD_VENDORS_PAGE = 2; // we show archived vendors only for short listings (standard-offers)
const MAX_SOLD_VENDORS_PAGE = 2; // we show only limited number of pages of archived vendors
const maxPageToShowSoldVendors = THRESHOLD_MAX_STANDARD_VENDORS_PAGE + MAX_SOLD_VENDORS_PAGE - 1;

interface IVendorListResponse extends IPaginatedResponse {
    results: IVendorBox[];
    all_offers_count: number;
}

const vendorListApiLink = apiV2ListLink.vendor.list(Scenario.VENDOR_LIST);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchVendorListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const parsedPage = safelyParsePage(ctx.route.query.page);
    const query = {
        ...ctx.route.query,
        page: parsedPage,
        page_size: VENDOR_LIST_PAGE_SIZE,
        ...(ctx.match.params.friendlySlug ? {region_slug: ctx.match.params.friendlySlug} : {})
    };

    dispatch({type: fetchVendorListTypes.start, latestQuery: query});
    const url = appendQueryString(vendorListApiLink, query);
    return getRequest(ctx.meta, url)
        .then((json: IVendorListResponse) => {
            const result = {
                vendors: json.results,
                allOffersCount: json.all_offers_count,
                count: json.count,
                pageSize: json.page_size || VENDOR_LIST_PAGE_SIZE,
                page: parsedPage
            };
            dispatch({type: fetchVendorListTypes.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchVendorListTypes.error, error: error.appError});
            })
        )
        .catch(
            catch404(async () => {
                // prevResult from region fetch
                if (
                    parsedPage > maxPageToShowSoldVendors ||
                    typeof ctx.prevResult.stats?.vendors_count_sold !== "number" ||
                    ctx.prevResult.stats?.vendors_count_sold < 1
                ) {
                    // we don't have any archived vendors, or we are on a 'page too far' to show standard or sold vendors
                    return dispatch(redirectOnList404(ctx.route, parsedPage, ctx.meta));
                } else {
                    // fetch shortest possible vndor list to get its count
                    const modifiedQuery = {...query, page: 1, page_size: 1};
                    const modifiedUrl = appendQueryString(vendorListApiLink, modifiedQuery);

                    return getRequest(ctx.meta, modifiedUrl).then(async (json: IVendorListResponse) => {
                        const result: IVendorListResponse = {
                            //  mock valid vendor list response, we do not need this list, only the count
                            results: [],
                            all_offers_count: json.all_offers_count,
                            count: json.count,
                            page_size: VENDOR_LIST_PAGE_SIZE,
                            page: parsedPage,
                            next: null,
                            previous: null
                        };
                        dispatch({type: fetchVendorListTypes.success, result});
                        return result;
                    });
                }
            })
        )
        .then((json: IVendorListResponse | void) => {
            if (json) {
                const maxStandardPage = json.page > 0 ? Math.ceil(json.count / VENDOR_LIST_PAGE_SIZE) : 1;
                // skip fetching sold vendors if we have at least 3 pages
                if (maxStandardPage > 2) {
                    return json;
                }
                return dispatch(fetchSoldVendorList(ctx, json.count, isEmpty(json.results)));
            }
        });
};

const SOLD_VENDOR_LIST_PREFIX = "vendor_list/sold_vendors_fetch";
export const fetchSoldVendorListTypes = createRequestActionTypes(SOLD_VENDOR_LIST_PREFIX);

const fetchSoldVendorList =
    ({match, route, meta}: IFetchContext<IRPRequestMeta>, standardVendorsCount: number, isStandardVendorListEmpty: boolean) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchSoldVendorListTypes.start});
        const parsedPage = safelyParsePage(route.query.page);
        const maxStandardPage = standardVendorsCount > 0 ? Math.ceil(standardVendorsCount / VENDOR_LIST_PAGE_SIZE) : 1;

        const query = {
            ...route.query,
            page: parsedPage - maxStandardPage + 1 || 1,
            page_size: VENDOR_LIST_PAGE_SIZE,
            ...(match.params.friendlySlug ? {sold_in_region_slug: match.params.friendlySlug} : {})
        };

        const url = appendQueryString(vendorListApiLink, query);
        return getRequest(meta, url)
            .then(async (json: IVendorListResponse) => {
                const result = {
                    vendors: json.results,
                    count: json.count,
                    allOffersCount: json.all_offers_count,
                    pageSize: json.page_size || VENDOR_LIST_PAGE_SIZE,
                    page: parsedPage
                };

                await dispatch({type: fetchSoldVendorListTypes.success, result});
                return json;
            })
            .catch(
                catch404(() => {
                    if (isStandardVendorListEmpty) {
                        return dispatch(redirectOnList404(route, parsedPage, meta));
                    }
                })
            );
    };

export const resetVendorList = (): IAction => ({type: fetchVendorListTypes.reset});

export const resetSoldVendorsAtRoute = () => async (dispatch: Dispatch) => {
    dispatch({type: fetchSoldVendorListTypes.reset});
    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {safelyParsePage} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOnList404} from "../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {IPaginatedResponse} from "../../request/IResponse";
import {IVendorBox} from "../components/VendorBox";

const VENDOR_LIST_PREFIX = "vendor_list/fetch";
export const fetchVendorListTypes = createRequestActionTypes(VENDOR_LIST_PREFIX);

export const VENDOR_LIST_PAGE_SIZE = 24;
const THRESHOLD_MAX_STANDARD_VENDORS_PAGE = 2; // we show archived vendors only for short listings (standard-offers)
const MAX_SOLD_VENDORS_PAGE = 2; // we show only limited number of pages of archived vendors
const maxPageToShowSoldVendors = THRESHOLD_MAX_STANDARD_VENDORS_PAGE + MAX_SOLD_VENDORS_PAGE - 1;

interface IVendorListResponse extends IPaginatedResponse {
    results: IVendorBox[];
    all_offers_count: number;
}

const vendorListApiLink = apiV2ListLink.vendor.list(Scenario.VENDOR_LIST);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchVendorListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const parsedPage = safelyParsePage(ctx.route.query.page);
    const query = {
        ...ctx.route.query,
        page: parsedPage,
        page_size: VENDOR_LIST_PAGE_SIZE,
        ...(ctx.match.params.friendlySlug ? {region_slug: ctx.match.params.friendlySlug} : {})
    };

    dispatch({type: fetchVendorListTypes.start, latestQuery: query});
    const url = appendQueryString(vendorListApiLink, query);
    return getRequest(ctx.meta, url)
        .then((json: IVendorListResponse) => {
            const result = {
                vendors: json.results,
                allOffersCount: json.all_offers_count,
                count: json.count,
                pageSize: json.page_size || VENDOR_LIST_PAGE_SIZE,
                page: parsedPage
            };
            dispatch({type: fetchVendorListTypes.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchVendorListTypes.error, error: error.appError});
            })
        )
        .catch(
            catch404(async () => {
                // prevResult from region fetch
                if (
                    parsedPage > maxPageToShowSoldVendors ||
                    typeof ctx.prevResult.stats?.vendors_count_sold !== "number" ||
                    ctx.prevResult.stats?.vendors_count_sold < 1
                ) {
                    // we don't have any archived vendors, or we are on a 'page too far' to show standard or sold vendors
                    return dispatch(redirectOnList404(ctx.route, parsedPage, ctx.meta));
                } else {
                    // fetch shortest possible vndor list to get its count
                    const modifiedQuery = {...query, page: 1, page_size: 1};
                    const modifiedUrl = appendQueryString(vendorListApiLink, modifiedQuery);

                    return getRequest(ctx.meta, modifiedUrl).then(async (json: IVendorListResponse) => {
                        const result: IVendorListResponse = {
                            //  mock valid vendor list response, we do not need this list, only the count
                            results: [],
                            all_offers_count: json.all_offers_count,
                            count: json.count,
                            page_size: VENDOR_LIST_PAGE_SIZE,
                            page: parsedPage,
                            next: null,
                            previous: null
                        };
                        dispatch({type: fetchVendorListTypes.success, result});
                        return result;
                    });
                }
            })
        )
        .then((json: IVendorListResponse | void) => {
            if (json) {
                const maxStandardPage = json.page > 0 ? Math.ceil(json.count / VENDOR_LIST_PAGE_SIZE) : 1;
                // skip fetching sold vendors if we have at least 3 pages
                if (maxStandardPage > 2) {
                    return json;
                }
                return dispatch(fetchSoldVendorList(ctx, json.count, isEmpty(json.results)));
            }
        });
};

const SOLD_VENDOR_LIST_PREFIX = "vendor_list/sold_vendors_fetch";
export const fetchSoldVendorListTypes = createRequestActionTypes(SOLD_VENDOR_LIST_PREFIX);

const fetchSoldVendorList =
    ({match, route, meta}: IFetchContext<IRPRequestMeta>, standardVendorsCount: number, isStandardVendorListEmpty: boolean) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchSoldVendorListTypes.start});
        const parsedPage = safelyParsePage(route.query.page);
        const maxStandardPage = standardVendorsCount > 0 ? Math.ceil(standardVendorsCount / VENDOR_LIST_PAGE_SIZE) : 1;

        const query = {
            ...route.query,
            page: parsedPage - maxStandardPage + 1 || 1,
            page_size: VENDOR_LIST_PAGE_SIZE,
            ...(match.params.friendlySlug ? {sold_in_region_slug: match.params.friendlySlug} : {})
        };

        const url = appendQueryString(vendorListApiLink, query);
        return getRequest(meta, url)
            .then(async (json: IVendorListResponse) => {
                const result = {
                    vendors: json.results,
                    count: json.count,
                    allOffersCount: json.all_offers_count,
                    pageSize: json.page_size || VENDOR_LIST_PAGE_SIZE,
                    page: parsedPage
                };

                await dispatch({type: fetchSoldVendorListTypes.success, result});
                return json;
            })
            .catch(
                catch404(() => {
                    if (isStandardVendorListEmpty) {
                        return dispatch(redirectOnList404(route, parsedPage, meta));
                    }
                })
            );
    };

export const resetVendorList = (): IAction => ({type: fetchVendorListTypes.reset});

export const resetSoldVendorsAtRoute = () => async (dispatch: Dispatch) => {
    dispatch({type: fetchSoldVendorListTypes.reset});
    return true;
};
