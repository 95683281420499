import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {VendorType} from "../../vendor/helpers/VendorType";

export const descriptionVendorDetail = (vendorType: VendorType, vendorName: string) => {
    return `Sprawdź ofertę🔎 ${vendorType === VendorType.AGENCY ? "agencji nieruchomości" : "dewelopera"} ${vendorName} ${concatWithBrandName(
        "na"
    )}. Znajdź wymarzone mieszkanie i skontaktuj się z doradcą!✅`;
};
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {VendorType} from "../../vendor/helpers/VendorType";

export const descriptionVendorDetail = (vendorType: VendorType, vendorName: string) => {
    return `Sprawdź ofertę🔎 ${vendorType === VendorType.AGENCY ? "agencji nieruchomości" : "dewelopera"} ${vendorName} ${concatWithBrandName(
        "na"
    )}. Znajdź wymarzone mieszkanie i skontaktuj się z doradcą!✅`;
};
