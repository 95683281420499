import {css} from "@emotion/react";

import {IBrandIcon, IButtonSizeBrandIcon, IButtonSizePrimary, IIcon} from "../components/Button";
import {ButtonVariant, ButtonVariantUnionType} from "../utils/button_variants";

const getIconMargin = (orientation: "left" | "right", marginValue: string, hasText: boolean) =>
    hasText &&
    css`
        ${orientation === "left" ? "margin-right" : "margin-left"}: ${marginValue};
    `;
export const withIconVariantStyles =
    (Icon: IIcon | IBrandIcon) =>
    (variant: ButtonVariantUnionType, orientation: "left" | "right", hasText: boolean, size: IButtonSizePrimary | IButtonSizeBrandIcon | undefined) => {
        switch (variant) {
            case ButtonVariant.STRETCHED_PRIMARY: {
                const iconStyle = css`
                    position: absolute;
                    ${orientation === "left" ? "left" : "right"}: 2.2rem;
                `;
                return <Icon size="2.4" css={iconStyle} fill="inherit" />;
            }
            case ButtonVariant.MAP_TOOLTIP: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "1.06rem", hasText)}
                `;
                return <Icon size="1.2" css={iconStyle} fill="inherit" />;
            }
            case ButtonVariant.BRAND_ICON: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "0.8rem", hasText)}
                `;

                return size === "big" ? (
                    <Icon size="6" wrapperColor="#ebff00" wrapperSize="6.4" wrapperType="circle" css={iconStyle} fill="inherit" />
                ) : (
                    <Icon size="4" wrapperColor="#ebff00" wrapperSize="4.8" wrapperType="circle" css={iconStyle} fill="inherit" />
                );
            }
            default: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "0.8rem", hasText)}
                `;
                return <Icon size="2.4" wrapperSize="2.4" wrapperColor="tranpsarent" css={iconStyle} fill="inherit" />;
            }
        }
    };
import {css} from "@emotion/react";

import {IBrandIcon, IButtonSizeBrandIcon, IButtonSizePrimary, IIcon} from "../components/Button";
import {ButtonVariant, ButtonVariantUnionType} from "../utils/button_variants";

const getIconMargin = (orientation: "left" | "right", marginValue: string, hasText: boolean) =>
    hasText &&
    css`
        ${orientation === "left" ? "margin-right" : "margin-left"}: ${marginValue};
    `;
export const withIconVariantStyles =
    (Icon: IIcon | IBrandIcon) =>
    (variant: ButtonVariantUnionType, orientation: "left" | "right", hasText: boolean, size: IButtonSizePrimary | IButtonSizeBrandIcon | undefined) => {
        switch (variant) {
            case ButtonVariant.STRETCHED_PRIMARY: {
                const iconStyle = css`
                    position: absolute;
                    ${orientation === "left" ? "left" : "right"}: 2.2rem;
                `;
                return <Icon size="2.4" css={iconStyle} fill="inherit" />;
            }
            case ButtonVariant.MAP_TOOLTIP: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "1.06rem", hasText)}
                `;
                return <Icon size="1.2" css={iconStyle} fill="inherit" />;
            }
            case ButtonVariant.BRAND_ICON: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "0.8rem", hasText)}
                `;

                return size === "big" ? (
                    <Icon size="6" wrapperColor="#ebff00" wrapperSize="6.4" wrapperType="circle" css={iconStyle} fill="inherit" />
                ) : (
                    <Icon size="4" wrapperColor="#ebff00" wrapperSize="4.8" wrapperType="circle" css={iconStyle} fill="inherit" />
                );
            }
            default: {
                const iconStyle = css`
                    ${getIconMargin(orientation, "0.8rem", hasText)}
                `;
                return <Icon size="2.4" wrapperSize="2.4" wrapperColor="tranpsarent" css={iconStyle} fill="inherit" />;
            }
        }
    };
