// if null, all cities will be enabled for regions selection on map
export const ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP: number[] | null = [
    8647, // Warszawa
    11158, // Kraków
    26955, // Wrocław
    30892, // Poznań
    32657, // Szczecin
    46083, // Gdańsk
    6196, // Gdynia
    31600, // Katowice
    16187 // Łódź
];
// if null, all cities will be enabled for regions selection on map
export const ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP: number[] | null = [
    8647, // Warszawa
    11158, // Kraków
    26955, // Wrocław
    30892, // Poznań
    32657, // Szczecin
    46083, // Gdańsk
    6196, // Gdynia
    31600, // Katowice
    16187 // Łódź
];
