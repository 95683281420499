import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {ISlugParsed} from "../friendly_offer_list/offer_url_parser";
import {offerSearchPhraseBuilder} from "./search_phrase";

export interface IFilterOptions {
    is_luxury: boolean | undefined;
    price_lower_than_average: boolean | undefined;
    construction_end_date: string | undefined;
}

export const getSearchFilterPrefix = (options: IFilterOptions) => {
    // TODO Check if we need luxury and holiday therefore whole `IFilterOptions`
    if (options.is_luxury) {
        return "Luksusowe";
    }

    if (options.price_lower_than_average) {
        return "Tanie";
    }

    if (options.construction_end_date === "0") {
        return "Gotowe";
    }

    return "Nowe";
};

export interface IParsedQuery extends Partial<ISlugParsed> {}

export const getGeneratedName = (parsedQuery: IParsedQuery, regionName?: string) => (ctx: IFetchContext<IRPRequestMeta>) => {
    const searchPhraseQuery = createSearchPhraseQuery(parsedQuery);
    return (
        createGetSearchFilterPrefix(parsedQuery) +
        " " +
        offerSearchPhraseBuilder(
            regionName
                ? {
                      ...searchPhraseQuery,
                      regionName
                  }
                : {
                      ...searchPhraseQuery,
                      offerName: ctx.route.query.near_by_offer && ctx.prevResult.offer ? ctx.prevResult.offer.name : undefined,
                      vendorName: ctx.route.query.vendor && ctx.prevResult.vendor ? ctx.prevResult.vendor.name : undefined,
                      placeName: ctx.route.query.lat && ctx.route.query.lng && ctx.route.query.s ? (ctx.route.query.s as string) : undefined
                  }
        )
    );
};

export const createSearchPhraseQuery = (parsedQuery: IParsedQuery) => ({
    type: parsedQuery.type,
    area_0: parsedQuery.area_0 ? parsedQuery.area_0.toString() : undefined,
    area_1: parsedQuery.area_1 ? parsedQuery.area_1.toString() : undefined,
    price_0: parsedQuery.price_0 ? parsedQuery.price_0.toString() : undefined,
    price_1: parsedQuery.price_1 ? parsedQuery.price_1.toString() : undefined,
    rooms_0: parsedQuery.rooms_0 ? parsedQuery.rooms_0.toString() : undefined,
    rooms_1: parsedQuery.rooms_1 ? parsedQuery.rooms_1.toString() : undefined
});

export const createGetSearchFilterPrefix = (parsedQuery: IParsedQuery) =>
    getSearchFilterPrefix({
        is_luxury: parsedQuery.is_luxury,
        price_lower_than_average: parsedQuery.price_lower_than_average,
        construction_end_date: parsedQuery.construction_end_date
    });
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {ISlugParsed} from "../friendly_offer_list/offer_url_parser";
import {offerSearchPhraseBuilder} from "./search_phrase";

export interface IFilterOptions {
    is_luxury: boolean | undefined;
    price_lower_than_average: boolean | undefined;
    construction_end_date: string | undefined;
}

export const getSearchFilterPrefix = (options: IFilterOptions) => {
    // TODO Check if we need luxury and holiday therefore whole `IFilterOptions`
    if (options.is_luxury) {
        return "Luksusowe";
    }

    if (options.price_lower_than_average) {
        return "Tanie";
    }

    if (options.construction_end_date === "0") {
        return "Gotowe";
    }

    return "Nowe";
};

export interface IParsedQuery extends Partial<ISlugParsed> {}

export const getGeneratedName = (parsedQuery: IParsedQuery, regionName?: string) => (ctx: IFetchContext<IRPRequestMeta>) => {
    const searchPhraseQuery = createSearchPhraseQuery(parsedQuery);
    return (
        createGetSearchFilterPrefix(parsedQuery) +
        " " +
        offerSearchPhraseBuilder(
            regionName
                ? {
                      ...searchPhraseQuery,
                      regionName
                  }
                : {
                      ...searchPhraseQuery,
                      offerName: ctx.route.query.near_by_offer && ctx.prevResult.offer ? ctx.prevResult.offer.name : undefined,
                      vendorName: ctx.route.query.vendor && ctx.prevResult.vendor ? ctx.prevResult.vendor.name : undefined,
                      placeName: ctx.route.query.lat && ctx.route.query.lng && ctx.route.query.s ? (ctx.route.query.s as string) : undefined
                  }
        )
    );
};

export const createSearchPhraseQuery = (parsedQuery: IParsedQuery) => ({
    type: parsedQuery.type,
    area_0: parsedQuery.area_0 ? parsedQuery.area_0.toString() : undefined,
    area_1: parsedQuery.area_1 ? parsedQuery.area_1.toString() : undefined,
    price_0: parsedQuery.price_0 ? parsedQuery.price_0.toString() : undefined,
    price_1: parsedQuery.price_1 ? parsedQuery.price_1.toString() : undefined,
    rooms_0: parsedQuery.rooms_0 ? parsedQuery.rooms_0.toString() : undefined,
    rooms_1: parsedQuery.rooms_1 ? parsedQuery.rooms_1.toString() : undefined
});

export const createGetSearchFilterPrefix = (parsedQuery: IParsedQuery) =>
    getSearchFilterPrefix({
        is_luxury: parsedQuery.is_luxury,
        price_lower_than_average: parsedQuery.price_lower_than_average,
        construction_end_date: parsedQuery.construction_end_date
    });
