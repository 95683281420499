import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IPropertyDetail} from "../../../property/types/IPropertyDetail";
import {fetchMapPropertyDetailsTypes} from "../actions/fetch_map_property_details";

export interface IMapSelectedPropertyStore {
    data: IPropertyDetail;
    requestState: RequestState;
}

export const mapSelectedPropertyReducer = combineReducers({
    data: reduceResponse<IPropertyDetail>(fetchMapPropertyDetailsTypes),
    requestState: reduceRequestState(fetchMapPropertyDetailsTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IPropertyDetail} from "../../../property/types/IPropertyDetail";
import {fetchMapPropertyDetailsTypes} from "../actions/fetch_map_property_details";

export interface IMapSelectedPropertyStore {
    data: IPropertyDetail;
    requestState: RequestState;
}

export const mapSelectedPropertyReducer = combineReducers({
    data: reduceResponse<IPropertyDetail>(fetchMapPropertyDetailsTypes),
    requestState: reduceRequestState(fetchMapPropertyDetailsTypes)
});
