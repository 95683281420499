import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_PROMOTION_REGION_STATISTIC = "promotion_statistic/FETCH_REGION";
export const fetchPromotionRegionStatisticTypes = createRequestActionTypes(FETCH_PROMOTION_REGION_STATISTIC);

export interface IPromotionRegionStatistic {
    type: number;
    count: number;
}

interface IPromotionRegionStatisticResponse {
    results: IPromotionRegionStatistic[];
}

export const fetchPromotionRegionStatisticById =
    (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        {meta, route}: IFetchContext<IRPRequestMeta>,
        regionId: number
    ) =>
    async (dispatch: Dispatch): Promise<IPromotionRegionStatistic[] | void> => {
        const url = appendQueryString(apiV2Link.promotion.statistic(null), {region: regionId});

        dispatch({type: fetchPromotionRegionStatisticTypes.start});
        return getRequest(meta, url)
            .then((response: IPromotionRegionStatisticResponse) => {
                dispatch({type: fetchPromotionRegionStatisticTypes.success, result: response.results || null});
                return response.results;
            })
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchPromotionRegionStatisticTypes.error, error});
                })
            );
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_PROMOTION_REGION_STATISTIC = "promotion_statistic/FETCH_REGION";
export const fetchPromotionRegionStatisticTypes = createRequestActionTypes(FETCH_PROMOTION_REGION_STATISTIC);

export interface IPromotionRegionStatistic {
    type: number;
    count: number;
}

interface IPromotionRegionStatisticResponse {
    results: IPromotionRegionStatistic[];
}

export const fetchPromotionRegionStatisticById =
    (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        {meta, route}: IFetchContext<IRPRequestMeta>,
        regionId: number
    ) =>
    async (dispatch: Dispatch): Promise<IPromotionRegionStatistic[] | void> => {
        const url = appendQueryString(apiV2Link.promotion.statistic(null), {region: regionId});

        dispatch({type: fetchPromotionRegionStatisticTypes.start});
        return getRequest(meta, url)
            .then((response: IPromotionRegionStatisticResponse) => {
                dispatch({type: fetchPromotionRegionStatisticTypes.success, result: response.results || null});
                return response.results;
            })
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchPromotionRegionStatisticTypes.error, error});
                })
            );
    };
