import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchTopArticlesTypes, ITopArticleLink} from "../actions/fetch_top_articles";

export interface IFooterStore {
    articles: {
        data: ITopArticleLink[] | null;
        requestState: RequestState;
    };
}

export const footerReducer = combineReducers({
    articles: combineReducers({
        data: reduceResponse(fetchTopArticlesTypes),
        requestState: reduceRequestState(fetchTopArticlesTypes)
    })
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchTopArticlesTypes, ITopArticleLink} from "../actions/fetch_top_articles";

export interface IFooterStore {
    articles: {
        data: ITopArticleLink[] | null;
        requestState: RequestState;
    };
}

export const footerReducer = combineReducers({
    articles: combineReducers({
        data: reduceResponse(fetchTopArticlesTypes),
        requestState: reduceRequestState(fetchTopArticlesTypes)
    })
});
