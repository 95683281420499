export function getParsedString(value: unknown, defaultValue?: string) {
    if (value && typeof value === "string") {
        return value as string;
    }

    if (defaultValue) {
        return defaultValue;
    }

    return "";
}
export function getParsedString(value: unknown, defaultValue?: string) {
    if (value && typeof value === "string") {
        return value as string;
    }

    if (defaultValue) {
        return defaultValue;
    }

    return "";
}
