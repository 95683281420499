import React, {type CSSProperties, forwardRef} from "react";
import classNames from "classnames";

import {IIcon} from "../interfaces/IIcon";
import {SvgIconWrapper} from "./SvgIconWrapper";

import {svgIconBase} from "./SvgIcon.module.css";

export const SvgIcon = forwardRef<HTMLDivElement, IIcon>((props, ref) => {
    const hasWrapper = props.wrapperSize && props.wrapperColor;
    const iconStyle = {
        "--iconFill": props.fill || "var(--colors-secondary)",
        "--iconSize": props.size || "1"
    } as CSSProperties;
    const svgIconCN = classNames(svgIconBase, props.className);

    const icon = (
        <svg onClick={!hasWrapper ? props.onClick : undefined} className={!hasWrapper ? svgIconCN : svgIconBase} style={iconStyle} viewBox="0 0 10 10">
            {props.children}
        </svg>
    );
    return props.wrapperSize && props.wrapperColor ? (
        <SvgIconWrapper
            onClick={props.onClick}
            wrapperColor={props.wrapperColor}
            wrapperSize={props.wrapperSize}
            wrapperType={props.wrapperType}
            className={props.className}
            ref={ref}
        >
            {icon}
        </SvgIconWrapper>
    ) : (
        icon
    );
});
import React, {type CSSProperties, forwardRef} from "react";
import classNames from "classnames";

import {IIcon} from "../interfaces/IIcon";
import {SvgIconWrapper} from "./SvgIconWrapper";

import {svgIconBase} from "./SvgIcon.module.css";

export const SvgIcon = forwardRef<HTMLDivElement, IIcon>((props, ref) => {
    const hasWrapper = props.wrapperSize && props.wrapperColor;
    const iconStyle = {
        "--iconFill": props.fill || "var(--colors-secondary)",
        "--iconSize": props.size || "1"
    } as CSSProperties;
    const svgIconCN = classNames(svgIconBase, props.className);

    const icon = (
        <svg onClick={!hasWrapper ? props.onClick : undefined} className={!hasWrapper ? svgIconCN : svgIconBase} style={iconStyle} viewBox="0 0 10 10">
            {props.children}
        </svg>
    );
    return props.wrapperSize && props.wrapperColor ? (
        <SvgIconWrapper
            onClick={props.onClick}
            wrapperColor={props.wrapperColor}
            wrapperSize={props.wrapperSize}
            wrapperType={props.wrapperType}
            className={props.className}
            ref={ref}
        >
            {icon}
        </SvgIconWrapper>
    ) : (
        icon
    );
});
