import {upperFirst} from "@pg-mono/nodash";
import {areaFormat, floorFormat, roomsFormat} from "@pg-mono/string-utils";

import {PropertyFlatType} from "../../offer/detail/constants/PropertyFlatType";
import {OfferType} from "../../offer/types/OfferType";
import {getPageTitle} from "../utils/get_page_title";
import {getPropertyFlatMetaType} from "../utils/get_property_flat_meta_type";

export interface ITitlePropertyDetail {
    offerName: string;
    regionName: string;
    offerType: OfferType;
    flatType: PropertyFlatType;
    rooms: number;
    area: number;
    floor: number | null;
}

export const titlePropertyDetail = (props: ITitlePropertyDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = getPropertyFlatMetaType(props.flatType);
            break;
        case OfferType.HOUSE:
            type = "dom";
            break;
        case OfferType.COMMERCIAL:
            type = "lokal użytkowy";
            break;
        default:
            break;
    }

    const rooms = roomsFormat(props.rooms);
    const area = areaFormat(props.area);
    const floor = typeof props.floor === "number" ? floorFormat(props.floor) : "";

    return getPageTitle(`${upperFirst(type)} ${props.regionName}, ${props.offerName} - ${rooms}, ${area}${floor && `, ${floor}`}`);
};
import {upperFirst} from "@pg-mono/nodash";
import {areaFormat, floorFormat, roomsFormat} from "@pg-mono/string-utils";

import {PropertyFlatType} from "../../offer/detail/constants/PropertyFlatType";
import {OfferType} from "../../offer/types/OfferType";
import {getPageTitle} from "../utils/get_page_title";
import {getPropertyFlatMetaType} from "../utils/get_property_flat_meta_type";

export interface ITitlePropertyDetail {
    offerName: string;
    regionName: string;
    offerType: OfferType;
    flatType: PropertyFlatType;
    rooms: number;
    area: number;
    floor: number | null;
}

export const titlePropertyDetail = (props: ITitlePropertyDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = getPropertyFlatMetaType(props.flatType);
            break;
        case OfferType.HOUSE:
            type = "dom";
            break;
        case OfferType.COMMERCIAL:
            type = "lokal użytkowy";
            break;
        default:
            break;
    }

    const rooms = roomsFormat(props.rooms);
    const area = areaFormat(props.area);
    const floor = typeof props.floor === "number" ? floorFormat(props.floor) : "";

    return getPageTitle(`${upperFirst(type)} ${props.regionName}, ${props.offerName} - ${rooms}, ${area}${floor && `, ${floor}`}`);
};
