import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IPaginatedResponse} from "../../request/IResponse";

// Categories that used to be but are no more... SEO asked to 301 them to /
const danglingURLs = ["kawiarnia-prasowa", "my-w-mediach", "z-zycia-portalu", "biuro-prasowe", "audycje-i-wideo"];

export const SITEMAP_ARTICLE_LIST_PAGE_SIZE = 99;
const Fetch_site_map_article_list = "SITE_MAP/ARTICLE/LIST";
export const fetchArticleListActionTypes = createRequestActionTypes(Fetch_site_map_article_list);

interface ISitemapArticleListResponse extends IPaginatedResponse {
    list: ISitemapArticle[];
}

export interface ISitemapArticle {
    id: number;
    slug: string;
    title: string;
    create_date: string;
    is_indexing_allowed: boolean;
    author: {
        id: number;
        name: string;
    };
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.SITEMAP_ARTICLE_LIST);

export const fetchSiteMapArticleList =
    (ctx: IFetchContext<IRPRequestMeta, {categorySlug: string}, {results: {id: number; slug: string}[]}>) => async (dispatch: Dispatch) => {
        if (!ctx.prevResult.results.length) {
            return Promise.resolve(false);
        }
        const categoryObj = ctx.prevResult.results.find((article_category) => article_category.slug === ctx.match.params.categorySlug);
        if (!categoryObj) {
            return dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        }

        if (danglingURLs.find((url) => url === ctx.route.query.categorySlug)) {
            return dispatch(enable301ResponseState(rpAppPath.siteMap.article.base));
        }

        const page = ctx.route.query.page || 1;

        dispatch({type: fetchArticleListActionTypes.start});
        const fullQuery = {
            category: categoryObj.id,
            page_size: SITEMAP_ARTICLE_LIST_PAGE_SIZE,
            page,
            is_indexing_allowed: "True"
        };

        const url = appendQueryString(articleListApiLink, {...fullQuery, page});
        return getRequest(ctx.meta, url)
            .then((result: ISitemapArticleListResponse) => {
                dispatch({type: fetchArticleListActionTypes.success, result});
                return result;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IPaginatedResponse} from "../../request/IResponse";

// Categories that used to be but are no more... SEO asked to 301 them to /
const danglingURLs = ["kawiarnia-prasowa", "my-w-mediach", "z-zycia-portalu", "biuro-prasowe", "audycje-i-wideo"];

export const SITEMAP_ARTICLE_LIST_PAGE_SIZE = 99;
const Fetch_site_map_article_list = "SITE_MAP/ARTICLE/LIST";
export const fetchArticleListActionTypes = createRequestActionTypes(Fetch_site_map_article_list);

interface ISitemapArticleListResponse extends IPaginatedResponse {
    list: ISitemapArticle[];
}

export interface ISitemapArticle {
    id: number;
    slug: string;
    title: string;
    create_date: string;
    is_indexing_allowed: boolean;
    author: {
        id: number;
        name: string;
    };
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.SITEMAP_ARTICLE_LIST);

export const fetchSiteMapArticleList =
    (ctx: IFetchContext<IRPRequestMeta, {categorySlug: string}, {results: {id: number; slug: string}[]}>) => async (dispatch: Dispatch) => {
        if (!ctx.prevResult.results.length) {
            return Promise.resolve(false);
        }
        const categoryObj = ctx.prevResult.results.find((article_category) => article_category.slug === ctx.match.params.categorySlug);
        if (!categoryObj) {
            return dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        }

        if (danglingURLs.find((url) => url === ctx.route.query.categorySlug)) {
            return dispatch(enable301ResponseState(rpAppPath.siteMap.article.base));
        }

        const page = ctx.route.query.page || 1;

        dispatch({type: fetchArticleListActionTypes.start});
        const fullQuery = {
            category: categoryObj.id,
            page_size: SITEMAP_ARTICLE_LIST_PAGE_SIZE,
            page,
            is_indexing_allowed: "True"
        };

        const url = appendQueryString(articleListApiLink, {...fullQuery, page});
        return getRequest(ctx.meta, url)
            .then((result: ISitemapArticleListResponse) => {
                dispatch({type: fetchArticleListActionTypes.success, result});
                return result;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };
