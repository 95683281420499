import {combineReducers} from "redux";

import {reduceReducers, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchUserProfileRequestTypes} from "../actions/fetch_user_profile";
import {patchUserProfileRequestType} from "../actions/patch_user_profile";
import {IUserProfile} from "../ts/interfaces/IUserProfile";

export interface IUserProfileStore {
    data: IUserProfile | null;
    requestState: RequestState;
}

export const userProfileReducer = combineReducers({
    data: reduceReducers(reduceResponse<IUserProfile>(fetchUserProfileRequestTypes), reduceResponse<IUserProfile>(patchUserProfileRequestType)),
    requestState: reduceRequestState(fetchUserProfileRequestTypes)
});
import {combineReducers} from "redux";

import {reduceReducers, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchUserProfileRequestTypes} from "../actions/fetch_user_profile";
import {patchUserProfileRequestType} from "../actions/patch_user_profile";
import {IUserProfile} from "../ts/interfaces/IUserProfile";

export interface IUserProfileStore {
    data: IUserProfile | null;
    requestState: RequestState;
}

export const userProfileReducer = combineReducers({
    data: reduceReducers(reduceResponse<IUserProfile>(fetchUserProfileRequestTypes), reduceResponse<IUserProfile>(patchUserProfileRequestType)),
    requestState: reduceRequestState(fetchUserProfileRequestTypes)
});
