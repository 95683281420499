import {IAction, IAppError, RequestState} from "@pg-mono/request-state";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {applicationCreateTypes} from "../actions/application_form_actions";
import {fetchApplicationOfferDetailTypes} from "../actions/fetch_application_offer_detail";
import {fetchApplicationPropertyDetailTypes} from "../actions/fetch_application_property_detail";
import {fetchApplicationRelatedDataTypes} from "../actions/fetch_application_related_data";
import {fetchApplicationVendorDetailTypes} from "../actions/fetch_application_vendor_detail";
import {
    IMultiLeadApplication,
    IMultiLeadApplicationsActions,
    IMultiLeadApplicationsAppendAction,
    multiLeadApplicationsActionsTypes
} from "../actions/multi_lead_applications_actions";
import {IApplication} from "../types/IApplication";

export interface IApplicationStore {
    [storeHash: string]: IApplicationData | undefined;
}

export interface IApplicationData {
    request: RequestState;
    application: IApplication | null;
    error: IAppError | null;
    vendor: IAdditionalData<IVendorDetailApplication> | null;
    offer: IAdditionalData<IOfferDetailApplication> | null;
    property: IAdditionalData<IPropertyDetailApplication> | null;
    leadDataRequest: RequestState;
    multiLeadApplications: IMultiLeadApplication[] | null;
}

export interface IAdditionalData<T> {
    request: RequestState;
    detail?: T;
    error?: IAppError;
}

interface IApplicationActionStart extends IAction {
    id: string;
}

interface IApplicationActionSuccess extends IAction {
    id: string;
    result: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface IApplicationActionError extends IAction {
    id: string;
    error: IAppError;
}

interface IApplicationActionReset extends IAction {
    id: string;
}

export type IApplicationAction =
    | IApplicationActionStart
    | IApplicationActionSuccess
    | IApplicationActionError
    | IApplicationActionReset
    | IMultiLeadApplicationsActions;

const getInitialApplicationData = (): IApplicationData => ({
    request: RequestState.None,
    application: null,
    error: null,
    vendor: null,
    offer: null,
    property: null,
    leadDataRequest: RequestState.None,
    multiLeadApplications: null
});

export function applicationReducer(state: IApplicationStore = {}, action: IApplicationAction): IApplicationStore {
    const actionSuccess = action as IApplicationActionSuccess;
    const actionError = action as IApplicationActionError;
    const currentData: IApplicationData = state[action.id] == null ? getInitialApplicationData() : (state[action.id] as IApplicationData);

    switch (action.type) {
        /**
         *  CREATE
         */

        case applicationCreateTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Waiting
                }
            };
        case applicationCreateTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Success,
                    application: actionSuccess.result
                }
            };
        case applicationCreateTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Error,
                    error: actionError.error
                }
            };
        case applicationCreateTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.None,
                    error: null
                }
            };

        /**
         * Default state
         */
        case fetchApplicationRelatedDataTypes.initialize:
            return {
                ...state,
                [action.id]: getInitialApplicationData()
            };

        /**
         * Lead data
         */
        case fetchApplicationRelatedDataTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Waiting
                }
            };
        case fetchApplicationRelatedDataTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Success
                }
            };
        case fetchApplicationRelatedDataTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Error
                }
            };

        /**
         *  VENDOR
         */

        case fetchApplicationVendorDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: null
                }
            };

        /**
         *  OFFER
         */

        case fetchApplicationOfferDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: null
                }
            };

        /**
         *  PROPERTY
         */

        case fetchApplicationPropertyDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: null
                }
            };

        /**
         * multi lead applications
         */
        case multiLeadApplicationsActionsTypes.append:
            const multiLeadAction = action as IMultiLeadApplicationsAppendAction;

            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    multiLeadApplications: [...(currentData.multiLeadApplications || []), ...multiLeadAction.applications]
                }
            };
        case multiLeadApplicationsActionsTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    multiLeadApplications: []
                }
            };

        default:
            return state;
    }
}
import {IAction, IAppError, RequestState} from "@pg-mono/request-state";

import {IOfferDetailApplication} from "../../offer/types/IOfferDetailApplication";
import {IPropertyDetailApplication} from "../../property/types/IPropertyDetailApplication";
import {IVendorDetailApplication} from "../../vendor/types/IVendorDetailApplication";
import {applicationCreateTypes} from "../actions/application_form_actions";
import {fetchApplicationOfferDetailTypes} from "../actions/fetch_application_offer_detail";
import {fetchApplicationPropertyDetailTypes} from "../actions/fetch_application_property_detail";
import {fetchApplicationRelatedDataTypes} from "../actions/fetch_application_related_data";
import {fetchApplicationVendorDetailTypes} from "../actions/fetch_application_vendor_detail";
import {
    IMultiLeadApplication,
    IMultiLeadApplicationsActions,
    IMultiLeadApplicationsAppendAction,
    multiLeadApplicationsActionsTypes
} from "../actions/multi_lead_applications_actions";
import {IApplication} from "../types/IApplication";

export interface IApplicationStore {
    [storeHash: string]: IApplicationData | undefined;
}

export interface IApplicationData {
    request: RequestState;
    application: IApplication | null;
    error: IAppError | null;
    vendor: IAdditionalData<IVendorDetailApplication> | null;
    offer: IAdditionalData<IOfferDetailApplication> | null;
    property: IAdditionalData<IPropertyDetailApplication> | null;
    leadDataRequest: RequestState;
    multiLeadApplications: IMultiLeadApplication[] | null;
}

export interface IAdditionalData<T> {
    request: RequestState;
    detail?: T;
    error?: IAppError;
}

interface IApplicationActionStart extends IAction {
    id: string;
}

interface IApplicationActionSuccess extends IAction {
    id: string;
    result: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface IApplicationActionError extends IAction {
    id: string;
    error: IAppError;
}

interface IApplicationActionReset extends IAction {
    id: string;
}

export type IApplicationAction =
    | IApplicationActionStart
    | IApplicationActionSuccess
    | IApplicationActionError
    | IApplicationActionReset
    | IMultiLeadApplicationsActions;

const getInitialApplicationData = (): IApplicationData => ({
    request: RequestState.None,
    application: null,
    error: null,
    vendor: null,
    offer: null,
    property: null,
    leadDataRequest: RequestState.None,
    multiLeadApplications: null
});

export function applicationReducer(state: IApplicationStore = {}, action: IApplicationAction): IApplicationStore {
    const actionSuccess = action as IApplicationActionSuccess;
    const actionError = action as IApplicationActionError;
    const currentData: IApplicationData = state[action.id] == null ? getInitialApplicationData() : (state[action.id] as IApplicationData);

    switch (action.type) {
        /**
         *  CREATE
         */

        case applicationCreateTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Waiting
                }
            };
        case applicationCreateTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Success,
                    application: actionSuccess.result
                }
            };
        case applicationCreateTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.Error,
                    error: actionError.error
                }
            };
        case applicationCreateTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    request: RequestState.None,
                    error: null
                }
            };

        /**
         * Default state
         */
        case fetchApplicationRelatedDataTypes.initialize:
            return {
                ...state,
                [action.id]: getInitialApplicationData()
            };

        /**
         * Lead data
         */
        case fetchApplicationRelatedDataTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Waiting
                }
            };
        case fetchApplicationRelatedDataTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Success
                }
            };
        case fetchApplicationRelatedDataTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    leadDataRequest: RequestState.Error
                }
            };

        /**
         *  VENDOR
         */

        case fetchApplicationVendorDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationVendorDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    vendor: null
                }
            };

        /**
         *  OFFER
         */

        case fetchApplicationOfferDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationOfferDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    offer: null
                }
            };

        /**
         *  PROPERTY
         */

        case fetchApplicationPropertyDetailTypes.start:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Waiting
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.success:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Success,
                        detail: actionSuccess.result
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.error:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: {
                        request: RequestState.Error,
                        error: actionError.error
                    }
                }
            };
        case fetchApplicationPropertyDetailTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    property: null
                }
            };

        /**
         * multi lead applications
         */
        case multiLeadApplicationsActionsTypes.append:
            const multiLeadAction = action as IMultiLeadApplicationsAppendAction;

            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    multiLeadApplications: [...(currentData.multiLeadApplications || []), ...multiLeadAction.applications]
                }
            };
        case multiLeadApplicationsActionsTypes.reset:
            return {
                ...state,
                [action.id]: {
                    ...currentData,
                    multiLeadApplications: []
                }
            };

        default:
            return state;
    }
}
