import {ArticleCategory, getNameByArticleCategory} from "../../article/actions/ArticleCategory";
import {getPageTitle} from "../utils/get_page_title";

const titleArticleList = "Wiadomości i porady";

export const getTitleArticleList = (page: number) => {
    return getPageTitle(titleArticleList, page);
};
export const titleArticleDetail = (title: string) => {
    return getPageTitle(`${title}`);
};

export const titleArticleCategory = (category: ArticleCategory | null, page: number) => {
    if (category) {
        return getPageTitle(`${getNameByArticleCategory(category)} - Wiadomości`, page);
    }
    return getPageTitle(titleArticleList, page);
};
import {ArticleCategory, getNameByArticleCategory} from "../../article/actions/ArticleCategory";
import {getPageTitle} from "../utils/get_page_title";

const titleArticleList = "Wiadomości i porady";

export const getTitleArticleList = (page: number) => {
    return getPageTitle(titleArticleList, page);
};
export const titleArticleDetail = (title: string) => {
    return getPageTitle(`${title}`);
};

export const titleArticleCategory = (category: ArticleCategory | null, page: number) => {
    if (category) {
        return getPageTitle(`${getNameByArticleCategory(category)} - Wiadomości`, page);
    }
    return getPageTitle(titleArticleList, page);
};
