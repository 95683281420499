import React, {forwardRef} from "react";
import ReactSelect, {GroupBase, Props as IReactSelectProps, SelectInstance} from "react-select";
import {css, useTheme} from "@emotion/react";

import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useSelectTheme} from "../hooks/use_select_theme";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ISelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> extends IReactSelectProps {
    isBorderless?: boolean;
    labelContent?: string;
    dataTestId?: string;
}

const Wrapper = (props: {children: (React.ReactNode | null)[]; hasLabel?: boolean; className?: string}) => {
    if (props.hasLabel) {
        return <div className={props.className}>{props.children}</div>;
    }
    return <>{props.children}</>;
};

function SelectBase<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ISelectProps<Option, IsMulti, Group>,
    ref: React.LegacyRef<SelectInstance<unknown, boolean, GroupBase<unknown>>>
) {
    const theme = useTheme();

    const {getTheme, styles} = useSelectTheme();
    const {labelContent, isBorderless, className, placeholder, dataTestId, ...restProps} = props;

    return (
        <Wrapper hasLabel={!!labelContent} className={className} data-testid={dataTestId}>
            {labelContent ? (
                <Text mb="0.4rem" variant="body_copy_2" as="div">
                    {labelContent}
                </Text>
            ) : null}
            <ReactSelect
                components={{
                    DropdownIndicator: (state) => {
                        return <ChevronDownIcon css={icon} size="2" fill={theme.colors.gray[state.isDisabled ? "300" : "700"]} />;
                    }
                }}
                isSearchable={false}
                styles={{
                    ...styles,
                    ...(isBorderless && {
                        control: (provided) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            backgroundColor: "transparent"
                        })
                    })
                }}
                theme={getTheme}
                {...restProps}
                className={!labelContent ? className : undefined}
                placeholder={placeholder || "Wybierz"}
                ref={ref}
            />
        </Wrapper>
    );
}

export const Select = forwardRef(SelectBase);

/*
    Styles
 */
const icon = css`
    margin-right: 2rem;
`;
import React, {forwardRef} from "react";
import ReactSelect, {GroupBase, Props as IReactSelectProps, SelectInstance} from "react-select";
import {css, useTheme} from "@emotion/react";

import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useSelectTheme} from "../hooks/use_select_theme";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ISelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> extends IReactSelectProps {
    isBorderless?: boolean;
    labelContent?: string;
    dataTestId?: string;
}

const Wrapper = (props: {children: (React.ReactNode | null)[]; hasLabel?: boolean; className?: string}) => {
    if (props.hasLabel) {
        return <div className={props.className}>{props.children}</div>;
    }
    return <>{props.children}</>;
};

function SelectBase<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ISelectProps<Option, IsMulti, Group>,
    ref: React.LegacyRef<SelectInstance<unknown, boolean, GroupBase<unknown>>>
) {
    const theme = useTheme();

    const {getTheme, styles} = useSelectTheme();
    const {labelContent, isBorderless, className, placeholder, dataTestId, ...restProps} = props;

    return (
        <Wrapper hasLabel={!!labelContent} className={className} data-testid={dataTestId}>
            {labelContent ? (
                <Text mb="0.4rem" variant="body_copy_2" as="div">
                    {labelContent}
                </Text>
            ) : null}
            <ReactSelect
                components={{
                    DropdownIndicator: (state) => {
                        return <ChevronDownIcon css={icon} size="2" fill={theme.colors.gray[state.isDisabled ? "300" : "700"]} />;
                    }
                }}
                isSearchable={false}
                styles={{
                    ...styles,
                    ...(isBorderless && {
                        control: (provided) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            backgroundColor: "transparent"
                        })
                    })
                }}
                theme={getTheme}
                {...restProps}
                className={!labelContent ? className : undefined}
                placeholder={placeholder || "Wybierz"}
                ref={ref}
            />
        </Wrapper>
    );
}

export const Select = forwardRef(SelectBase);

/*
    Styles
 */
const icon = css`
    margin-right: 2rem;
`;
