// Replacement for https://lodash.com/docs/4.17.15#compact

type Falsey = null | undefined | false | "" | 0 | 0n;

export const compact = <T>(array: (T | Falsey)[]): T[] => {
    return array.filter((value) => !!value) as T[];
};
// Replacement for https://lodash.com/docs/4.17.15#compact

type Falsey = null | undefined | false | "" | 0 | 0n;

export const compact = <T>(array: (T | Falsey)[]): T[] => {
    return array.filter((value) => !!value) as T[];
};
