const GOOGLE_API = "https://www.googleapis.com/auth/";

export const GOOGLE_OAUTH_SCOPE = `${GOOGLE_API}userinfo.profile ${GOOGLE_API}user.emails.read`;

// GOOGLE ADMIN PERMISSION - Paweł Chojnowski
export const GOOGLE_OAUTH_CLIENT_ID = "1064501703591-k38ml97gpdpuahd910mho26mo6i0j14g.apps.googleusercontent.com";
const GOOGLE_API = "https://www.googleapis.com/auth/";

export const GOOGLE_OAUTH_SCOPE = `${GOOGLE_API}userinfo.profile ${GOOGLE_API}user.emails.read`;

// GOOGLE ADMIN PERMISSION - Paweł Chojnowski
export const GOOGLE_OAUTH_CLIENT_ID = "1064501703591-k38ml97gpdpuahd910mho26mo6i0j14g.apps.googleusercontent.com";
