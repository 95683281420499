import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";
import {isEmpty, isNumber} from "@pg-mono/nodash";
import {catch400, catch404, getRequest, postRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink, apiPath} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {Country} from "../../../region/types/Country";
import {IOfferMetaRobotsIds} from "../../constants/offer_meta_robots";
import {fetchSelectedRegionById} from "../../list/actions/offer_list_selected_data_actions";
import {SearchSort} from "../../list/constants/SearchSort";
import {OfferListSubType} from "../../list/types/OfferListSubType";
import {fromParsedObjectToQuery, ISlugParsed, ISlugQuery} from "../friendly_offer_list/offer_url_parser";
import {getGeneratedName} from "./generate_name";
import {prepareSaveSearchValues} from "./prepare_save_search_values";

export interface ISearch {
    id: number;
    create_date: string;
    update_date: string;
    slug: string;
    name: string;
    price: {
        lower: number | null;
        upper: number | null;
    } | null;
    rooms: {
        lower: number | null;
        upper: number | null;
    } | null;
    area: {
        lower: number | null;
        upper: number | null;
    } | null;
    is_luxury: boolean;
    family_type: number | null;
    holiday_location: boolean;
    sort: SearchSort | null;
    calls_count: number;
    seo_description: string | null;
    seo_meta_keywords?: string[];
    region: number | null;
    country: number | null;
    type: number | null;
    meta_description: string | null;
    meta_title: string | null;
    show_meta_fields: boolean;
    canonical_url: string | null;
    meta_robots: IOfferMetaRobotsIds | null;
    region_name?: string | null;
}

const FETCH_OFFER_LIST_SEO_CONTENT = "offer_list/fetch_search";
export const fetchOfferListSearchActionTypes = createRequestActionTypes(FETCH_OFFER_LIST_SEO_CONTENT);
const isBrowser = process.env.EXEC_ENV === "browser";

export const fetchSearchParsedOnError =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    (dispatch: Dispatch): Promise<Partial<ISlugQuery> | void> => {
        dispatch({type: fetchOfferListSearchActionTypes.start});

        const url = apiLink.search.search({})({friendlySlug: ctx.match.params.friendlySlug});
        return getRequest(ctx.meta, url)
            .then(async (search: ISearch) => {
                dispatch({type: fetchOfferListSearchActionTypes.success, result: search});

                if (isNumber(search.region)) {
                    dispatch(fixSearchRegion(search, ctx.meta));
                }

                const searchParsed = getParsedSearch(search, ctx.prevResult.price_lower_than_average);

                return fromParsedObjectToQuery(searchParsed);
            })
            .catch(
                catch404(async (error: unknown) => {
                    dispatch({type: fetchOfferListSearchActionTypes.error, error});
                    // when we cannot parse slug and there is no search we need to trigger 404
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };

export const fetchSearchAtRoute =
    (
        ctx: IFetchContext<
            IRPRequestMeta,
            {friendlySlug: string; offerListSubType?: OfferListSubType; offerListSubFilter?: string; country?: string},
            ISlugParsed
        >
    ) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        dispatch({type: fetchOfferListSearchActionTypes.start});

        // prevent search call on Abroad without region
        if (ctx.prevResult.country != Country.POLAND && !ctx.prevResult.region_name) {
            dispatch({type: fetchOfferListSearchActionTypes.success, result: null});
        }

        async function onSearchSuccess(search: ISearch): Promise<Partial<ISlugQuery>> {
            if (isNumber(search.region)) {
                dispatch(fixSearchRegion(search, ctx.meta));
            }

            const searchParsed: Partial<ISlugParsed> = getParsedSearch(search, ctx.prevResult.price_lower_than_average);
            const searchQuery: Partial<ISlugQuery> = fromParsedObjectToQuery(searchParsed);
            return {
                // we need to add house filters & floor choices manually because API doesn't handle it properly
                ...pick(ctx.prevResult, ["house_additional_areas", "house_type", "house_storeys"]),
                ...(pick(ctx.prevResult, ["floor_choices"]) as unknown as {floor_choices: string[] | string | undefined}),
                ...pick(ctx.prevResult, ["country", "region"]),
                ...pick(ctx.prevResult, ["region_name", "region_name"]),

                ...ctx.route.query,
                ...searchQuery
            };
        }
        const search: ISearch | null = getState().offerList.search;

        if (!isBrowser && search) {
            return onSearchSuccess(search);
        }

        const friendlySlug = `${ctx.match.params.friendlySlug}${ctx.prevResult.country != Country.POLAND ? `-${ctx.prevResult.region_name}` : ""}`;
        const url = apiLink.search.search({})({
            friendlySlug
        });

        return getRequest(ctx.meta, url)
            .then(async (search: ISearch) => {
                dispatch({type: fetchOfferListSearchActionTypes.success, result: search});
                return onSearchSuccess(search);
            })
            .catch(
                catch404(async (error: unknown) => {
                    dispatch({type: fetchOfferListSearchActionTypes.error, error});
                    // when we have parsed slug successfully and there is no search
                    if (isBrowser) {
                        // in browser we add search entry
                        const {selectedRegions} = getState().offerList;

                        const saveSearchResponse = await dispatch(
                            saveSearch(
                                {
                                    slug: ctx.match.params.friendlySlug,
                                    type: ctx.prevResult.type || null,
                                    region: !isEmpty(ctx.prevResult.region) ? parseInt(ctx.prevResult.region[0], 10) : null,
                                    ...prepareSaveSearchValues(ctx.prevResult),
                                    name: getGeneratedName(ctx.prevResult, selectedRegions.length ? selectedRegions[0].short_name : undefined)(ctx)
                                },
                                ctx.meta
                            )
                        );

                        return {
                            ...ctx.prevResult,
                            saveSearchResponse
                        };
                    } else {
                        return ctx.prevResult;
                    }
                })
            );
    };

export const saveSearch = (searchData: Partial<ISearch>, meta: IRPRequestMeta) => (): Promise<ISearch | void> => {
    return postRequest(meta, apiPath.search.search.base, searchData)
        .then((search: ISearch) => {
            return search;
        })
        .catch(
            catch400(() => {
                // search does not have to successfully save the slug and related query params
            })
        );
};

//  Utils
function getParsedSearch(search: ISearch, priceLowerThanAverage?: boolean) {
    const result: Partial<ISlugParsed> = {};
    // type & region
    if (isNumber(search.type)) {
        result.type = search.type;
    }
    if (isNumber(search.region)) {
        result.region = [search.region.toString()];
    }
    if (search.country) {
        result.country = search.country;
    }
    if (search.region_name) {
        result.region_name = search.region_name;
    }

    // price
    if (search.price && search.price.lower) {
        result.price_0 = search.price.lower;
    }
    if (search.price && search.price.upper) {
        result.price_1 = search.price.upper;
    }

    // rooms
    if (search.rooms && search.rooms.lower) {
        result.rooms_0 = search.rooms.lower;
    }
    if (search.rooms && search.rooms.upper) {
        if (search.rooms.lower === 5) {
            result.rooms_1 = undefined;
        } else {
            result.rooms_1 = search.rooms.upper;
        }
    }

    // area
    if (search.area && search.area.lower) {
        result.area_0 = search.area.lower;
    }
    if (search.area && search.area.upper) {
        result.area_1 = search.area.upper;
    }
    if (search.sort) {
        result.sort = search.sort;
    }

    // additional
    if (search.is_luxury) {
        result.is_luxury = search.is_luxury;
    }

    //price_lower_than_average does not appear in search and is used in SEO context for "tanie..." listings
    if (priceLowerThanAverage) {
        result.price_lower_than_average = priceLowerThanAverage;
    }

    return result;
}

function fixSearchRegion(search: ISearch, meta: IRPRequestMeta) {
    return async (dispatch: Dispatch) => {
        if (isNumber(search.region)) {
            // we should update selected data because search may give us different region
            await dispatch(fetchSelectedRegionById(meta, search.region));
        }
    };
}
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";
import {isEmpty, isNumber} from "@pg-mono/nodash";
import {catch400, catch404, getRequest, postRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink, apiPath} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {Country} from "../../../region/types/Country";
import {IOfferMetaRobotsIds} from "../../constants/offer_meta_robots";
import {fetchSelectedRegionById} from "../../list/actions/offer_list_selected_data_actions";
import {SearchSort} from "../../list/constants/SearchSort";
import {OfferListSubType} from "../../list/types/OfferListSubType";
import {fromParsedObjectToQuery, ISlugParsed, ISlugQuery} from "../friendly_offer_list/offer_url_parser";
import {getGeneratedName} from "./generate_name";
import {prepareSaveSearchValues} from "./prepare_save_search_values";

export interface ISearch {
    id: number;
    create_date: string;
    update_date: string;
    slug: string;
    name: string;
    price: {
        lower: number | null;
        upper: number | null;
    } | null;
    rooms: {
        lower: number | null;
        upper: number | null;
    } | null;
    area: {
        lower: number | null;
        upper: number | null;
    } | null;
    is_luxury: boolean;
    family_type: number | null;
    holiday_location: boolean;
    sort: SearchSort | null;
    calls_count: number;
    seo_description: string | null;
    seo_meta_keywords?: string[];
    region: number | null;
    country: number | null;
    type: number | null;
    meta_description: string | null;
    meta_title: string | null;
    show_meta_fields: boolean;
    canonical_url: string | null;
    meta_robots: IOfferMetaRobotsIds | null;
    region_name?: string | null;
}

const FETCH_OFFER_LIST_SEO_CONTENT = "offer_list/fetch_search";
export const fetchOfferListSearchActionTypes = createRequestActionTypes(FETCH_OFFER_LIST_SEO_CONTENT);
const isBrowser = process.env.EXEC_ENV === "browser";

export const fetchSearchParsedOnError =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    (dispatch: Dispatch): Promise<Partial<ISlugQuery> | void> => {
        dispatch({type: fetchOfferListSearchActionTypes.start});

        const url = apiLink.search.search({})({friendlySlug: ctx.match.params.friendlySlug});
        return getRequest(ctx.meta, url)
            .then(async (search: ISearch) => {
                dispatch({type: fetchOfferListSearchActionTypes.success, result: search});

                if (isNumber(search.region)) {
                    dispatch(fixSearchRegion(search, ctx.meta));
                }

                const searchParsed = getParsedSearch(search, ctx.prevResult.price_lower_than_average);

                return fromParsedObjectToQuery(searchParsed);
            })
            .catch(
                catch404(async (error: unknown) => {
                    dispatch({type: fetchOfferListSearchActionTypes.error, error});
                    // when we cannot parse slug and there is no search we need to trigger 404
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };

export const fetchSearchAtRoute =
    (
        ctx: IFetchContext<
            IRPRequestMeta,
            {friendlySlug: string; offerListSubType?: OfferListSubType; offerListSubFilter?: string; country?: string},
            ISlugParsed
        >
    ) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        dispatch({type: fetchOfferListSearchActionTypes.start});

        // prevent search call on Abroad without region
        if (ctx.prevResult.country != Country.POLAND && !ctx.prevResult.region_name) {
            dispatch({type: fetchOfferListSearchActionTypes.success, result: null});
        }

        async function onSearchSuccess(search: ISearch): Promise<Partial<ISlugQuery>> {
            if (isNumber(search.region)) {
                dispatch(fixSearchRegion(search, ctx.meta));
            }

            const searchParsed: Partial<ISlugParsed> = getParsedSearch(search, ctx.prevResult.price_lower_than_average);
            const searchQuery: Partial<ISlugQuery> = fromParsedObjectToQuery(searchParsed);
            return {
                // we need to add house filters & floor choices manually because API doesn't handle it properly
                ...pick(ctx.prevResult, ["house_additional_areas", "house_type", "house_storeys"]),
                ...(pick(ctx.prevResult, ["floor_choices"]) as unknown as {floor_choices: string[] | string | undefined}),
                ...pick(ctx.prevResult, ["country", "region"]),
                ...pick(ctx.prevResult, ["region_name", "region_name"]),

                ...ctx.route.query,
                ...searchQuery
            };
        }
        const search: ISearch | null = getState().offerList.search;

        if (!isBrowser && search) {
            return onSearchSuccess(search);
        }

        const friendlySlug = `${ctx.match.params.friendlySlug}${ctx.prevResult.country != Country.POLAND ? `-${ctx.prevResult.region_name}` : ""}`;
        const url = apiLink.search.search({})({
            friendlySlug
        });

        return getRequest(ctx.meta, url)
            .then(async (search: ISearch) => {
                dispatch({type: fetchOfferListSearchActionTypes.success, result: search});
                return onSearchSuccess(search);
            })
            .catch(
                catch404(async (error: unknown) => {
                    dispatch({type: fetchOfferListSearchActionTypes.error, error});
                    // when we have parsed slug successfully and there is no search
                    if (isBrowser) {
                        // in browser we add search entry
                        const {selectedRegions} = getState().offerList;

                        const saveSearchResponse = await dispatch(
                            saveSearch(
                                {
                                    slug: ctx.match.params.friendlySlug,
                                    type: ctx.prevResult.type || null,
                                    region: !isEmpty(ctx.prevResult.region) ? parseInt(ctx.prevResult.region[0], 10) : null,
                                    ...prepareSaveSearchValues(ctx.prevResult),
                                    name: getGeneratedName(ctx.prevResult, selectedRegions.length ? selectedRegions[0].short_name : undefined)(ctx)
                                },
                                ctx.meta
                            )
                        );

                        return {
                            ...ctx.prevResult,
                            saveSearchResponse
                        };
                    } else {
                        return ctx.prevResult;
                    }
                })
            );
    };

export const saveSearch = (searchData: Partial<ISearch>, meta: IRPRequestMeta) => (): Promise<ISearch | void> => {
    return postRequest(meta, apiPath.search.search.base, searchData)
        .then((search: ISearch) => {
            return search;
        })
        .catch(
            catch400(() => {
                // search does not have to successfully save the slug and related query params
            })
        );
};

//  Utils
function getParsedSearch(search: ISearch, priceLowerThanAverage?: boolean) {
    const result: Partial<ISlugParsed> = {};
    // type & region
    if (isNumber(search.type)) {
        result.type = search.type;
    }
    if (isNumber(search.region)) {
        result.region = [search.region.toString()];
    }
    if (search.country) {
        result.country = search.country;
    }
    if (search.region_name) {
        result.region_name = search.region_name;
    }

    // price
    if (search.price && search.price.lower) {
        result.price_0 = search.price.lower;
    }
    if (search.price && search.price.upper) {
        result.price_1 = search.price.upper;
    }

    // rooms
    if (search.rooms && search.rooms.lower) {
        result.rooms_0 = search.rooms.lower;
    }
    if (search.rooms && search.rooms.upper) {
        if (search.rooms.lower === 5) {
            result.rooms_1 = undefined;
        } else {
            result.rooms_1 = search.rooms.upper;
        }
    }

    // area
    if (search.area && search.area.lower) {
        result.area_0 = search.area.lower;
    }
    if (search.area && search.area.upper) {
        result.area_1 = search.area.upper;
    }
    if (search.sort) {
        result.sort = search.sort;
    }

    // additional
    if (search.is_luxury) {
        result.is_luxury = search.is_luxury;
    }

    //price_lower_than_average does not appear in search and is used in SEO context for "tanie..." listings
    if (priceLowerThanAverage) {
        result.price_lower_than_average = priceLowerThanAverage;
    }

    return result;
}

function fixSearchRegion(search: ISearch, meta: IRPRequestMeta) {
    return async (dispatch: Dispatch) => {
        if (isNumber(search.region)) {
            // we should update selected data because search may give us different region
            await dispatch(fetchSelectedRegionById(meta, search.region));
        }
    };
}
