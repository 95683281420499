import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, catch404, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {ArticleCategory} from "../../../article/actions/ArticleCategory";
import {IArticleListResponse} from "../../../article/actions/fetch_article_list";
import {notifyBugsnag} from "../../../errors/bugsnag/notify_bugsnag";

const PRICES_ARTICLE_LIST_PREFIX = "reports/FETCH_PRICES_ARTICLE_LIST";
export const fetchPricesArticleListTypes = createRequestActionTypes(PRICES_ARTICLE_LIST_PREFIX);

const INVESTMENTS_ARTICLE_PAGE_SIZE = 3;

export const fetchPricesArticleList = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    const query: Record<string, IQueryValue> = {
        category: ArticleCategory.Reports,
        page: 1,
        page_size: INVESTMENTS_ARTICLE_PAGE_SIZE
    };

    const url = appendQueryString(apiV2ListLink.article.list(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then((json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });

            dispatch({type: fetchPricesArticleListTypes.success, result: articles});
            return json;
        })
        .catch(
            catch404(async () => {
                // we should not really expect a 404 here
                dispatch({type: fetchPricesArticleListTypes.success, result: []});
                return [];
            })
        )
        .catch(
            catch400(async (err) => {
                // in case someone removes `Investments` category
                const errorMsg = `Report prices general LP, article category is missing: 'raporty', id: ${ArticleCategory.Reports}`;
                notifyBugsnag({message: errorMsg}, err.message);
                consoleError(errorMsg, "; route: ", ctx.route, "; API error.message: ", err.message);

                dispatch({type: fetchPricesArticleListTypes.success, result: []});
                return [];
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, catch404, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {ArticleCategory} from "../../../article/actions/ArticleCategory";
import {IArticleListResponse} from "../../../article/actions/fetch_article_list";
import {notifyBugsnag} from "../../../errors/bugsnag/notify_bugsnag";

const PRICES_ARTICLE_LIST_PREFIX = "reports/FETCH_PRICES_ARTICLE_LIST";
export const fetchPricesArticleListTypes = createRequestActionTypes(PRICES_ARTICLE_LIST_PREFIX);

const INVESTMENTS_ARTICLE_PAGE_SIZE = 3;

export const fetchPricesArticleList = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    const query: Record<string, IQueryValue> = {
        category: ArticleCategory.Reports,
        page: 1,
        page_size: INVESTMENTS_ARTICLE_PAGE_SIZE
    };

    const url = appendQueryString(apiV2ListLink.article.list(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then((json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });

            dispatch({type: fetchPricesArticleListTypes.success, result: articles});
            return json;
        })
        .catch(
            catch404(async () => {
                // we should not really expect a 404 here
                dispatch({type: fetchPricesArticleListTypes.success, result: []});
                return [];
            })
        )
        .catch(
            catch400(async (err) => {
                // in case someone removes `Investments` category
                const errorMsg = `Report prices general LP, article category is missing: 'raporty', id: ${ArticleCategory.Reports}`;
                notifyBugsnag({message: errorMsg}, err.message);
                consoleError(errorMsg, "; route: ", ctx.route, "; API error.message: ", err.message);

                dispatch({type: fetchPricesArticleListTypes.success, result: []});
                return [];
            })
        );
};
