import {appendQueryString} from "@pg-mono/request";

export const generatePrevNextMetaLinks = (parsedPage: number, maxPage: number, fullUrl: string, restQuery: Record<string, string | string[]>) => {
    // getPrevPage has this strange type syntax on purpose (didnt work otherwise)
    const getPrevPage: (page: number) => {} | {page: number} = (p: number) => (p <= 2 ? {} : {page: p - 1}); // do not add `page` param to first page link
    const prev = parsedPage === 1 ? undefined : appendQueryString(appendQueryString(fullUrl, restQuery), getPrevPage(parsedPage));
    const next = parsedPage < maxPage ? appendQueryString(appendQueryString(fullUrl, restQuery), {page: parsedPage + 1}) : undefined;

    return {
        prev,
        next
    };
};
import {appendQueryString} from "@pg-mono/request";

export const generatePrevNextMetaLinks = (parsedPage: number, maxPage: number, fullUrl: string, restQuery: Record<string, string | string[]>) => {
    // getPrevPage has this strange type syntax on purpose (didnt work otherwise)
    const getPrevPage: (page: number) => {} | {page: number} = (p: number) => (p <= 2 ? {} : {page: p - 1}); // do not add `page` param to first page link
    const prev = parsedPage === 1 ? undefined : appendQueryString(appendQueryString(fullUrl, restQuery), getPrevPage(parsedPage));
    const next = parsedPage < maxPage ? appendQueryString(appendQueryString(fullUrl, restQuery), {page: parsedPage + 1}) : undefined;

    return {
        prev,
        next
    };
};
