import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateBuylandoMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName("Działki na sprzedaż - Sprzedaj Swój Grunt -");
    const description =
        "Chcesz sprzedać grunt inwestycyjny z przeznaczeniem pod zabudowę jednorodzinną lub wielorodzinną? Posiadasz działkę o dużym potencjalne? Dobrze trafiłeś. Sprawdź.";

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));

    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateBuylandoMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName("Działki na sprzedaż - Sprzedaj Swój Grunt -");
    const description =
        "Chcesz sprzedać grunt inwestycyjny z przeznaczeniem pod zabudowę jednorodzinną lub wielorodzinną? Posiadasz działkę o dużym potencjalne? Dobrze trafiłeś. Sprawdź.";

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));

    return true;
};
