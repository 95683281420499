import React, {PropsWithChildren, ReactNode} from "react";
import classNames from "classnames";

import {Button} from "@pg-design/button-module";
import {Text} from "@pg-design/text-module";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

import * as styles from "./ModalLayoutPrompt.module.css";

interface IProps extends PropsWithChildren {
    header?: ReactNode;
    onOkClick: () => void;
    onCancelClick: () => void;
    okButtonText?: string | false;
    cancelButtonText?: string | false;
    className?: string;
}

export const ModalLayoutPrompt = (props: IProps) => {
    return (
        <div className={classNames(props.className, styles.wrapperStyle)}>
            <Text variant="headline_3">{props.header}</Text>
            <ModalCloseButton className={styles.modalCloseButtonStyle} />

            {props.children ? <div>{props.children}</div> : null}

            <div className={styles.buttonsWrapperStyle}>
                {props.okButtonText !== false && (
                    <Button className={styles.buttonStyle} variant="filled_primary" onClick={props.onOkClick}>
                        {props.okButtonText ?? "OK"}
                    </Button>
                )}
                {props.cancelButtonText !== false && (
                    <Button className={styles.buttonStyle} variant="outlined_secondary" onClick={props.onCancelClick}>
                        {props.cancelButtonText ?? "Anuluj"}
                    </Button>
                )}
            </div>
        </div>
    );
};
import React, {PropsWithChildren, ReactNode} from "react";
import classNames from "classnames";

import {Button} from "@pg-design/button-module";
import {Text} from "@pg-design/text-module";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

import * as styles from "./ModalLayoutPrompt.module.css";

interface IProps extends PropsWithChildren {
    header?: ReactNode;
    onOkClick: () => void;
    onCancelClick: () => void;
    okButtonText?: string | false;
    cancelButtonText?: string | false;
    className?: string;
}

export const ModalLayoutPrompt = (props: IProps) => {
    return (
        <div className={classNames(props.className, styles.wrapperStyle)}>
            <Text variant="headline_3">{props.header}</Text>
            <ModalCloseButton className={styles.modalCloseButtonStyle} />

            {props.children ? <div>{props.children}</div> : null}

            <div className={styles.buttonsWrapperStyle}>
                {props.okButtonText !== false && (
                    <Button className={styles.buttonStyle} variant="filled_primary" onClick={props.onOkClick}>
                        {props.okButtonText ?? "OK"}
                    </Button>
                )}
                {props.cancelButtonText !== false && (
                    <Button className={styles.buttonStyle} variant="outlined_secondary" onClick={props.onCancelClick}>
                        {props.cancelButtonText ?? "Anuluj"}
                    </Button>
                )}
            </div>
        </div>
    );
};
