import {compile} from "path-to-regexp";

import {isEmpty, omit, pick} from "@pg-mono/nodash";

import {Country} from "../../../region/types/Country";
import {SearchSort} from "../../list/constants/SearchSort";
import {getCountryName} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {IFriendlySubQuery} from "../../list/types/friendly_sub_query";
import {OfferType} from "../../types/OfferType";
import {getOfferListSubFilterValue} from "./sub_filter/get_offer_list_sub_filter_value";
import {friendlyUrlSubQueryKeys, getSubFilter} from "./sub_filter/get_sub_filter";
import {offerListRegex} from "./offer_url_parser";

export const friendlyUrlQueryKeys: (keyof IFriendlyQuery)[] = [
    "type",
    "region",
    "region_type",
    "price_0",
    "price_1",
    "area_0",
    "area_1",
    "rooms_0",
    "rooms_1",
    "sort",
    "is_luxury",
    "price_lower_than_average"
];

type IParsedFriendlyQuery = Record<keyof IFriendlyQuery, string>;
type IInputQuery = Record<string, string | number | string[] | number[] | undefined | null>;

export const divideQueryIntoFriendlyAndRest = (query: IInputQuery): [Partial<IFriendlyQuery>, IInputQuery] => {
    const friendlyQuery = pick(query, friendlyUrlQueryKeys) as IParsedFriendlyQuery;
    const friendlySubQuery = pick(query, friendlyUrlSubQueryKeys) as Record<keyof IFriendlySubQuery, string>;

    const offerListingType = query.type && typeof query.type === "string" ? parseInt(query.type.toString(), 10) : null;
    const subFilter = getSubFilter(friendlyQuery, friendlySubQuery, offerListingType as OfferType);

    const restQuery = omit(query, [...friendlyUrlQueryKeys, ...friendlyUrlSubQueryKeys]) as Record<string, string | string[]>;

    /**
     * TODO Why do we convert values to string so we can parse them back?
     *  The pick() is not doing conversion, we're responsible for this in other places, so the type we think we receive,
     *  IInputQuery/IFriendlyQuery/IParsedFriendlyQuery, is actually a wrong type, it's already converted.
     *  If we'd actually receive the type as it's implemented here, we wouldn't receive Record<keyof IFriendlyQuery, string> by using pick() or omit().
     *  We should change this approach.
     *  If there's separate place that needs strings, totally fine, but it should not share logic with this functionality.
     */
    // transform `friendlyQuery` into `friendlyValues`
    const friendlyValues: Partial<IFriendlyQuery> = {
        ...(friendlyQuery.type ? {type: parseInt(friendlyQuery.type, 10)} : {}),
        region: friendlyQuery.region,
        ...(friendlyQuery.price_0 ? {price_0: parseInt(friendlyQuery.price_0, 10)} : {}),
        ...(friendlyQuery.price_1 ? {price_1: parseInt(friendlyQuery.price_1, 10)} : {}),
        ...(friendlyQuery.area_0 ? {area_0: parseInt(friendlyQuery.area_0, 10)} : {}),
        ...(friendlyQuery.area_1 ? {area_1: parseInt(friendlyQuery.area_1, 10)} : {}),
        ...(friendlyQuery.rooms_0 ? {rooms_0: parseInt(friendlyQuery.rooms_0, 10)} : {}),
        ...(friendlyQuery.rooms_1 ? {rooms_1: parseInt(friendlyQuery.rooms_1, 10)} : {}),
        ...(friendlyQuery.sort ? {sort: parseInt(friendlyQuery.sort, 10)} : {}),
        ...(friendlyQuery.is_luxury ? {is_luxury: friendlyQuery.is_luxury === "true"} : {}),
        ...(friendlyQuery.price_lower_than_average ? {price_lower_than_average: friendlyQuery.price_lower_than_average === "true"} : {}),
        ...subFilter.friendly
    };

    const restValues = {
        ...restQuery,
        ...subFilter.params,
        // respond to `getOfferSortParam` logic
        ...(friendlyValues.sort !== SearchSort.PRICE && friendlyValues.sort !== SearchSort.CONSTRUCTION_DATE ? {sort: friendlyQuery.sort} : {})
    };
    //convert restQuery.construction_end_date to friendlyValues fot listings "gotowe"
    //modifying restQuery for listings "gotowe", make construction_end_date query disappear from link
    if (
        restQuery.construction_end_date &&
        restQuery.construction_end_date[0] === "0" &&
        !friendlyValues.price_lower_than_average &&
        !friendlyValues.is_luxury
    ) {
        friendlyValues.construction_end_date = restQuery.construction_end_date[0].toString();
    }
    // return combined result
    return [friendlyValues, restValues];
};

// Offer search friendly URL builder
type IUrlBuilderData = Partial<IFriendlyQuery> & Partial<IFriendlySubQuery>;
export const offerUrlBuilder = (data: IUrlBuilderData) => {
    const offerListSubFilter = getOfferListSubFilterValue(data);
    const {country} = data;
    const region = country && country !== Country.POLAND ? "" : data.region || "";

    const basicValue = {
        region: region,
        beforeTypeDash: "-",
        beforeRegionDash: !isEmpty(region) && "-",
        sort: getOfferSortParam(data),
        type: getOfferType(data)
    };

    const fullValues = {
        ...basicValue,
        ...getPriceValue(data),
        ...getAreaValue(data),
        ...getRoomValues(data),
        ...(offerListSubFilter ? {offerListSubFilter} : {})
    };

    const finalValues = country && country !== Country.POLAND ? basicValue : fullValues;

    const compiler = compile(offerListRegex);

    let builtUrl = compiler(finalValues);

    // We remove "-" if it is the first or the last character of the string
    if (builtUrl.startsWith("-")) {
        builtUrl = builtUrl.substr(1);
    }

    if (builtUrl.endsWith("-")) {
        builtUrl = builtUrl.substr(0, builtUrl.length - 1);
    }

    const [friendlySlug, offerListSubFilterSlug] = builtUrl.split("/");
    const countrySlug = getCountryName(country);

    return {
        friendlySlug,
        offerListSubFilterSlug,
        countrySlug
    };
};

const getOfferSortParam = (data: {sort?: SearchSort; is_luxury?: boolean; price_lower_than_average?: boolean; construction_end_date?: string}) => {
    const {is_luxury, price_lower_than_average, construction_end_date} = data;
    if (is_luxury) {
        return "luksusowe";
    } else if (price_lower_than_average) {
        return "tanie";
    } else if (construction_end_date === "0") {
        return "gotowe";
    } else {
        return "nowe";
    }
};

const getOfferType = (data: {type?: OfferType | null}) => {
    const offerType = parseInt((data.type || "").toString(), 10); // We need an int for url building.

    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domy";
        case OfferType.COMMERCIAL:
            return "lokale-uzytkowe";
        default:
            return "mieszkania-i-domy";
    }
};

const getPriceValue = ({price_0, price_1}: {price_0?: number; price_1?: number}) => {
    if (price_0 || price_1) {
        return {
            price: "cena",
            price_0: price_0 || null,
            price_1: price_1 || null,
            price_0_prefix: price_0 ? "-od-" : null,
            price_1_prefix: price_1 ? "-do-" : null,
            beforePriceDash: "-",
            zl: "-zl"
        };
    }
};

const getAreaValue = ({area_0, area_1}: {area_0?: number; area_1?: number}) => {
    if (area_0 || area_1) {
        return {
            area: "powierzchnia",
            area_0: area_0 || null,
            area_1: area_1 || null,
            area_0_prefix: area_0 ? "-od-" : null,
            area_1_prefix: area_1 ? "-do-" : null,
            m2: "-m2",
            beforeAreaDash: "-"
        };
    }
};

const getRoomValues = ({rooms_0, rooms_1}: {rooms_0?: number; rooms_1?: number}) => {
    const roomsVerbose = {
        1: "jednopokojowe",
        2: "dwupokojowe",
        3: "trzypokojowe",
        4: "czteropokojowe",
        5: "pieciopokojowe-i-wiecej"
    } as Record<number, string>;

    if (rooms_0 || rooms_1) {
        if (rooms_1 && rooms_0 === rooms_1) {
            const val = inRange(rooms_1, 1, 5);
            return {
                roomsEqual: roomsVerbose[val],
                beforeEqualRoomsDash: "-"
            };
        }
        if ((rooms_0 === 1 && rooms_1 === 5) || (!rooms_0 && rooms_1 && rooms_1 > 5)) {
            return "";
        }
        if (rooms_0 === 5 && !rooms_1) {
            return {
                roomsEqual: roomsVerbose[rooms_0],
                beforeEqualRoomsDash: "-"
            };
        }
        if (rooms_1 === 1 && !rooms_0) {
            return {
                roomsEqual: roomsVerbose[rooms_1],
                beforeEqualRoomsDash: "-"
            };
        }
        if ((rooms_0 && rooms_0 > 5) || (rooms_1 && rooms_1 > 5)) {
            return {
                roomsEqual: roomsVerbose[5],
                beforeEqualRoomsDash: "-"
            };
        }

        const val0 = rooms_0 && rooms_0 > 1;
        const val1 = rooms_1 && rooms_1 < 5;
        return {
            rooms: (val0 || val1) && "liczba-pokoi",
            rooms_0: val0 ? rooms_0 : null,
            rooms_1: val1 ? rooms_1 : null,
            rooms_0_prefix: val0 ? "-od-" : null,
            rooms_1_prefix: val1 ? "-do-" : null,
            beforeRoomsDash: (val0 || val1) && "-"
        };
    }
};

const inRange = (val: number, a: number, b: number) => {
    let res = val;
    res = Math.max(a, res);
    res = Math.min(b, res);
    return res;
};
import {compile} from "path-to-regexp";

import {isEmpty, omit, pick} from "@pg-mono/nodash";

import {Country} from "../../../region/types/Country";
import {SearchSort} from "../../list/constants/SearchSort";
import {getCountryName} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {IFriendlySubQuery} from "../../list/types/friendly_sub_query";
import {OfferType} from "../../types/OfferType";
import {getOfferListSubFilterValue} from "./sub_filter/get_offer_list_sub_filter_value";
import {friendlyUrlSubQueryKeys, getSubFilter} from "./sub_filter/get_sub_filter";
import {offerListRegex} from "./offer_url_parser";

export const friendlyUrlQueryKeys: (keyof IFriendlyQuery)[] = [
    "type",
    "region",
    "region_type",
    "price_0",
    "price_1",
    "area_0",
    "area_1",
    "rooms_0",
    "rooms_1",
    "sort",
    "is_luxury",
    "price_lower_than_average"
];

type IParsedFriendlyQuery = Record<keyof IFriendlyQuery, string>;
type IInputQuery = Record<string, string | number | string[] | number[] | undefined | null>;

export const divideQueryIntoFriendlyAndRest = (query: IInputQuery): [Partial<IFriendlyQuery>, IInputQuery] => {
    const friendlyQuery = pick(query, friendlyUrlQueryKeys) as IParsedFriendlyQuery;
    const friendlySubQuery = pick(query, friendlyUrlSubQueryKeys) as Record<keyof IFriendlySubQuery, string>;

    const offerListingType = query.type && typeof query.type === "string" ? parseInt(query.type.toString(), 10) : null;
    const subFilter = getSubFilter(friendlyQuery, friendlySubQuery, offerListingType as OfferType);

    const restQuery = omit(query, [...friendlyUrlQueryKeys, ...friendlyUrlSubQueryKeys]) as Record<string, string | string[]>;

    /**
     * TODO Why do we convert values to string so we can parse them back?
     *  The pick() is not doing conversion, we're responsible for this in other places, so the type we think we receive,
     *  IInputQuery/IFriendlyQuery/IParsedFriendlyQuery, is actually a wrong type, it's already converted.
     *  If we'd actually receive the type as it's implemented here, we wouldn't receive Record<keyof IFriendlyQuery, string> by using pick() or omit().
     *  We should change this approach.
     *  If there's separate place that needs strings, totally fine, but it should not share logic with this functionality.
     */
    // transform `friendlyQuery` into `friendlyValues`
    const friendlyValues: Partial<IFriendlyQuery> = {
        ...(friendlyQuery.type ? {type: parseInt(friendlyQuery.type, 10)} : {}),
        region: friendlyQuery.region,
        ...(friendlyQuery.price_0 ? {price_0: parseInt(friendlyQuery.price_0, 10)} : {}),
        ...(friendlyQuery.price_1 ? {price_1: parseInt(friendlyQuery.price_1, 10)} : {}),
        ...(friendlyQuery.area_0 ? {area_0: parseInt(friendlyQuery.area_0, 10)} : {}),
        ...(friendlyQuery.area_1 ? {area_1: parseInt(friendlyQuery.area_1, 10)} : {}),
        ...(friendlyQuery.rooms_0 ? {rooms_0: parseInt(friendlyQuery.rooms_0, 10)} : {}),
        ...(friendlyQuery.rooms_1 ? {rooms_1: parseInt(friendlyQuery.rooms_1, 10)} : {}),
        ...(friendlyQuery.sort ? {sort: parseInt(friendlyQuery.sort, 10)} : {}),
        ...(friendlyQuery.is_luxury ? {is_luxury: friendlyQuery.is_luxury === "true"} : {}),
        ...(friendlyQuery.price_lower_than_average ? {price_lower_than_average: friendlyQuery.price_lower_than_average === "true"} : {}),
        ...subFilter.friendly
    };

    const restValues = {
        ...restQuery,
        ...subFilter.params,
        // respond to `getOfferSortParam` logic
        ...(friendlyValues.sort !== SearchSort.PRICE && friendlyValues.sort !== SearchSort.CONSTRUCTION_DATE ? {sort: friendlyQuery.sort} : {})
    };
    //convert restQuery.construction_end_date to friendlyValues fot listings "gotowe"
    //modifying restQuery for listings "gotowe", make construction_end_date query disappear from link
    if (
        restQuery.construction_end_date &&
        restQuery.construction_end_date[0] === "0" &&
        !friendlyValues.price_lower_than_average &&
        !friendlyValues.is_luxury
    ) {
        friendlyValues.construction_end_date = restQuery.construction_end_date[0].toString();
    }
    // return combined result
    return [friendlyValues, restValues];
};

// Offer search friendly URL builder
type IUrlBuilderData = Partial<IFriendlyQuery> & Partial<IFriendlySubQuery>;
export const offerUrlBuilder = (data: IUrlBuilderData) => {
    const offerListSubFilter = getOfferListSubFilterValue(data);
    const {country} = data;
    const region = country && country !== Country.POLAND ? "" : data.region || "";

    const basicValue = {
        region: region,
        beforeTypeDash: "-",
        beforeRegionDash: !isEmpty(region) && "-",
        sort: getOfferSortParam(data),
        type: getOfferType(data)
    };

    const fullValues = {
        ...basicValue,
        ...getPriceValue(data),
        ...getAreaValue(data),
        ...getRoomValues(data),
        ...(offerListSubFilter ? {offerListSubFilter} : {})
    };

    const finalValues = country && country !== Country.POLAND ? basicValue : fullValues;

    const compiler = compile(offerListRegex);

    let builtUrl = compiler(finalValues);

    // We remove "-" if it is the first or the last character of the string
    if (builtUrl.startsWith("-")) {
        builtUrl = builtUrl.substr(1);
    }

    if (builtUrl.endsWith("-")) {
        builtUrl = builtUrl.substr(0, builtUrl.length - 1);
    }

    const [friendlySlug, offerListSubFilterSlug] = builtUrl.split("/");
    const countrySlug = getCountryName(country);

    return {
        friendlySlug,
        offerListSubFilterSlug,
        countrySlug
    };
};

const getOfferSortParam = (data: {sort?: SearchSort; is_luxury?: boolean; price_lower_than_average?: boolean; construction_end_date?: string}) => {
    const {is_luxury, price_lower_than_average, construction_end_date} = data;
    if (is_luxury) {
        return "luksusowe";
    } else if (price_lower_than_average) {
        return "tanie";
    } else if (construction_end_date === "0") {
        return "gotowe";
    } else {
        return "nowe";
    }
};

const getOfferType = (data: {type?: OfferType | null}) => {
    const offerType = parseInt((data.type || "").toString(), 10); // We need an int for url building.

    switch (offerType) {
        case OfferType.FLAT:
            return "mieszkania";
        case OfferType.HOUSE:
            return "domy";
        case OfferType.COMMERCIAL:
            return "lokale-uzytkowe";
        default:
            return "mieszkania-i-domy";
    }
};

const getPriceValue = ({price_0, price_1}: {price_0?: number; price_1?: number}) => {
    if (price_0 || price_1) {
        return {
            price: "cena",
            price_0: price_0 || null,
            price_1: price_1 || null,
            price_0_prefix: price_0 ? "-od-" : null,
            price_1_prefix: price_1 ? "-do-" : null,
            beforePriceDash: "-",
            zl: "-zl"
        };
    }
};

const getAreaValue = ({area_0, area_1}: {area_0?: number; area_1?: number}) => {
    if (area_0 || area_1) {
        return {
            area: "powierzchnia",
            area_0: area_0 || null,
            area_1: area_1 || null,
            area_0_prefix: area_0 ? "-od-" : null,
            area_1_prefix: area_1 ? "-do-" : null,
            m2: "-m2",
            beforeAreaDash: "-"
        };
    }
};

const getRoomValues = ({rooms_0, rooms_1}: {rooms_0?: number; rooms_1?: number}) => {
    const roomsVerbose = {
        1: "jednopokojowe",
        2: "dwupokojowe",
        3: "trzypokojowe",
        4: "czteropokojowe",
        5: "pieciopokojowe-i-wiecej"
    } as Record<number, string>;

    if (rooms_0 || rooms_1) {
        if (rooms_1 && rooms_0 === rooms_1) {
            const val = inRange(rooms_1, 1, 5);
            return {
                roomsEqual: roomsVerbose[val],
                beforeEqualRoomsDash: "-"
            };
        }
        if ((rooms_0 === 1 && rooms_1 === 5) || (!rooms_0 && rooms_1 && rooms_1 > 5)) {
            return "";
        }
        if (rooms_0 === 5 && !rooms_1) {
            return {
                roomsEqual: roomsVerbose[rooms_0],
                beforeEqualRoomsDash: "-"
            };
        }
        if (rooms_1 === 1 && !rooms_0) {
            return {
                roomsEqual: roomsVerbose[rooms_1],
                beforeEqualRoomsDash: "-"
            };
        }
        if ((rooms_0 && rooms_0 > 5) || (rooms_1 && rooms_1 > 5)) {
            return {
                roomsEqual: roomsVerbose[5],
                beforeEqualRoomsDash: "-"
            };
        }

        const val0 = rooms_0 && rooms_0 > 1;
        const val1 = rooms_1 && rooms_1 < 5;
        return {
            rooms: (val0 || val1) && "liczba-pokoi",
            rooms_0: val0 ? rooms_0 : null,
            rooms_1: val1 ? rooms_1 : null,
            rooms_0_prefix: val0 ? "-od-" : null,
            rooms_1_prefix: val1 ? "-do-" : null,
            beforeRoomsDash: (val0 || val1) && "-"
        };
    }
};

const inRange = (val: number, a: number, b: number) => {
    let res = val;
    res = Math.max(a, res);
    res = Math.min(b, res);
    return res;
};
