import {initABTestingInstance} from "@pg-mono/ab-testing";

import {rpInternalApiCookieDomain, validateAbTestDomain} from "../app/read_rp_environment_variables";
import {ongoingABTests} from "./ongoing_ab_tests";

export const abTestingInstance = initABTestingInstance(ongoingABTests, {domain: rpInternalApiCookieDomain, validateDomain: validateAbTestDomain});
import {initABTestingInstance} from "@pg-mono/ab-testing";

import {rpInternalApiCookieDomain, validateAbTestDomain} from "../app/read_rp_environment_variables";
import {ongoingABTests} from "./ongoing_ab_tests";

export const abTestingInstance = initABTestingInstance(ongoingABTests, {domain: rpInternalApiCookieDomain, validateDomain: validateAbTestDomain});
