export enum OfferListSubType {
    // special listings
    CREDIT_TWO_PERCENT = "kredyt-2-procent",
    // floor filters
    FLOOR_GROUND = "parter",
    FLOOR_GROUND_WITH_GARDEN = "parter-z-ogrodem",
    LAST_FLOOR = "ostatnie-pietro",
    // house filters
    HOUSE_TYPE_SERIAL = "szeregowe",
    HOUSE_TYPE_DETACHED = "wolnostojace",
    HOUSE_TYPE_SEMI_DETACHED = "blizniaki",
    HOUSE_TYPE_WOODEN = "z-bali",
    HOUSE_STOREYS_MULTI_STOREY = "pietrowe",
    HOUSE_STOREYS_SINGLE_STOREY = "parterowe",
    HOUSE_ADDITIONAL_AREAS_ATTIC = "z-poddaszem",
    HOUSE_ADDITIONAL_AREAS_GARAGE = "z-garazem",
    HOUSE_ADDITIONAL_AREAS_GARDEN = "z-ogrodem",
    HOUSE_ADDITIONAL_AREAS_TERRACE = "z-tarasem",
    HOUSE_ADDITIONAL_AREAS_BALCONY = "z-balkonem"
}
export enum OfferListSubType {
    // special listings
    CREDIT_TWO_PERCENT = "kredyt-2-procent",
    // floor filters
    FLOOR_GROUND = "parter",
    FLOOR_GROUND_WITH_GARDEN = "parter-z-ogrodem",
    LAST_FLOOR = "ostatnie-pietro",
    // house filters
    HOUSE_TYPE_SERIAL = "szeregowe",
    HOUSE_TYPE_DETACHED = "wolnostojace",
    HOUSE_TYPE_SEMI_DETACHED = "blizniaki",
    HOUSE_TYPE_WOODEN = "z-bali",
    HOUSE_STOREYS_MULTI_STOREY = "pietrowe",
    HOUSE_STOREYS_SINGLE_STOREY = "parterowe",
    HOUSE_ADDITIONAL_AREAS_ATTIC = "z-poddaszem",
    HOUSE_ADDITIONAL_AREAS_GARAGE = "z-garazem",
    HOUSE_ADDITIONAL_AREAS_GARDEN = "z-ogrodem",
    HOUSE_ADDITIONAL_AREAS_TERRACE = "z-tarasem",
    HOUSE_ADDITIONAL_AREAS_BALCONY = "z-balkonem"
}
