import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updatePartnersListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = `Partnerzy ${concatWithBrandName("portalu")}`;
    const description = `Nasz portal współpracuje z wieloma partnerami 🤝 będącymi wiodącymi firmami w swojej specjalizacji - sprawdź!`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updatePartnersListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = `Partnerzy ${concatWithBrandName("portalu")}`;
    const description = `Nasz portal współpracuje z wieloma partnerami 🤝 będącymi wiodącymi firmami w swojej specjalizacji - sprawdź!`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return true;
};
