import {getCookie} from "@pg-mono/request";

import {IABTestingOptions} from "../types/IABTestingOptions";

export const buildABTestCookie = (abTestId: string, abTestVariant: number, expires?: Date, domain?: string): string => {
    return `${abTestId}=${abTestVariant}; Path=/; Domain=${domain}; Expires=${expires ? new Date(expires).toUTCString() : null};`;
};

export const getABTestVariantFromDocumentCookies = (abTestId: string, options: IABTestingOptions): number | null => {
    const variantFromDocumentCookie = getABTestCookie(abTestId, options);
    const parsedVariant = parseInt(variantFromDocumentCookie as string);

    return Number.isFinite(parsedVariant) ? parsedVariant : null;
};

function getABTestCookie(abTestId: string, options: IABTestingOptions) {
    if (options.domain && options.validateDomain) {
        return getCookieForCurrentDomain(abTestId, options.domain);
    }

    return getCookie(abTestId);
}

function getCookieForCurrentDomain(cookieName: string, domain: string): string | null {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
        const [name, value] = cookie.split("=").map((c) => c.trim());

        if (name === cookieName && document.location.hostname.includes(domain)) {
            return value;
        }
    }

    return null;
}
import {getCookie} from "@pg-mono/request";

import {IABTestingOptions} from "../types/IABTestingOptions";

export const buildABTestCookie = (abTestId: string, abTestVariant: number, expires?: Date, domain?: string): string => {
    return `${abTestId}=${abTestVariant}; Path=/; Domain=${domain}; Expires=${expires ? new Date(expires).toUTCString() : null};`;
};

export const getABTestVariantFromDocumentCookies = (abTestId: string, options: IABTestingOptions): number | null => {
    const variantFromDocumentCookie = getABTestCookie(abTestId, options);
    const parsedVariant = parseInt(variantFromDocumentCookie as string);

    return Number.isFinite(parsedVariant) ? parsedVariant : null;
};

function getABTestCookie(abTestId: string, options: IABTestingOptions) {
    if (options.domain && options.validateDomain) {
        return getCookieForCurrentDomain(abTestId, options.domain);
    }

    return getCookie(abTestId);
}

function getCookieForCurrentDomain(cookieName: string, domain: string): string | null {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
        const [name, value] = cookie.split("=").map((c) => c.trim());

        if (name === cookieName && document.location.hostname.includes(domain)) {
            return value;
        }
    }

    return null;
}
