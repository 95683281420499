import React, {useContext} from "react";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";

import {ModalContext} from "../ModalContext";

import * as style from "./ModalContent.module.css";

interface IModalContentProps {
    maxWidth?: string;
    maxWidthDesktop?: string;
    children?: React.ReactNode;
    className?: string;
}

export const ModalContent = (props: IModalContentProps) => {
    const {modalTheme} = useContext(ModalContext);

    const textColorValue = modalTheme === "light" ? getThemeVariable("colors.secondary") : "#fff";
    const backgroundColorValue = modalTheme === "light" ? "#fff" : getThemeVariable("colors.secondary");

    const cn = classNames(props.className, style.wrapper);
    return (
        <div
            style={{
                color: textColorValue,
                backgroundColor: backgroundColorValue
            }}
            className={cn}
        >
            {props.children}
        </div>
    );
};
import React, {useContext} from "react";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";

import {ModalContext} from "../ModalContext";

import * as style from "./ModalContent.module.css";

interface IModalContentProps {
    maxWidth?: string;
    maxWidthDesktop?: string;
    children?: React.ReactNode;
    className?: string;
}

export const ModalContent = (props: IModalContentProps) => {
    const {modalTheme} = useContext(ModalContext);

    const textColorValue = modalTheme === "light" ? getThemeVariable("colors.secondary") : "#fff";
    const backgroundColorValue = modalTheme === "light" ? "#fff" : getThemeVariable("colors.secondary");

    const cn = classNames(props.className, style.wrapper);
    return (
        <div
            style={{
                color: textColorValue,
                backgroundColor: backgroundColorValue
            }}
            className={cn}
        >
            {props.children}
        </div>
    );
};
