import {IFriendlyQuery} from "../../list/types/friendly_query";

export function isFriendlyQueryWithFilters(friendlyQuery: Partial<Record<keyof IFriendlyQuery, string>>) {
    const {price_0, price_1, area_0, area_1, rooms_0, rooms_1, is_luxury} = friendlyQuery;

    return Boolean(price_0 || price_1 || area_0 || area_1 || rooms_0 || rooms_1 || is_luxury);
}
import {IFriendlyQuery} from "../../list/types/friendly_query";

export function isFriendlyQueryWithFilters(friendlyQuery: Partial<Record<keyof IFriendlyQuery, string>>) {
    const {price_0, price_1, area_0, area_1, rooms_0, rooms_1, is_luxury} = friendlyQuery;

    return Boolean(price_0 || price_1 || area_0 || area_1 || rooms_0 || rooms_1 || is_luxury);
}
