import {match, matchPath} from "react-router";

import {IFetchEntry} from "./create_data_fetcher";

// result of `matchFetchEntry` block
interface IMatchedEntry<TMeta, TStore> {
    match: match<object>; // result of `matchPath` logic
    entry: IFetchEntry<TMeta, TStore>; // data-fetcher entry matched by `matchPath`
}
const isExcluded = (pathname: string, exclude: string | string[] | undefined): boolean => {
    if (!exclude) {
        return false;
    }
    const excludePaths = Array.isArray(exclude) ? exclude : [exclude];
    return excludePaths.some((path) => matchPath(pathname, {path, exact: true, strict: true}));
};

// helper that operates on data-fetcher path-to-fetch map and matches entries to given `pathname`
// returns array of matched entries
export const matchFetchEntry = <TMeta, TStore>(fetchEntries: IFetchEntry<TMeta, TStore>[], pathname: string): IMatchedEntry<TMeta, TStore>[] => {
    let matchedEntry: IFetchEntry<TMeta, TStore> | null = null;
    let match: match<object> | null = null;
    for (const entry of fetchEntries) {
        const paths = Array.isArray(entry.path) ? entry.path : [entry.path];
        for (const path of paths) {
            match = matchPath(pathname, {path, exact: entry.exact !== false, sensitive: true, strict: true}); // default for `exact` is `true`
            if (match && !isExcluded(pathname, entry.exclude)) {
                // path does match
                matchedEntry = entry;

                break; // we look for first path-match
            }
        }
        if (matchedEntry) {
            break; // we look for first entry-match only
        }
    }
    // prepare matches
    let result: IMatchedEntry<TMeta, TStore>[] = [];
    if (match && matchedEntry) {
        // we matched the path, so we travel deeper into routes
        const thisMatch = {match, entry: matchedEntry};
        const deepMatches = matchedEntry.routes ? matchFetchEntry(matchedEntry.routes, pathname) : [];
        result = [thisMatch, ...deepMatches];
    }
    return result;
};
import {match, matchPath} from "react-router";

import {IFetchEntry} from "./create_data_fetcher";

// result of `matchFetchEntry` block
interface IMatchedEntry<TMeta, TStore> {
    match: match<object>; // result of `matchPath` logic
    entry: IFetchEntry<TMeta, TStore>; // data-fetcher entry matched by `matchPath`
}
const isExcluded = (pathname: string, exclude: string | string[] | undefined): boolean => {
    if (!exclude) {
        return false;
    }
    const excludePaths = Array.isArray(exclude) ? exclude : [exclude];
    return excludePaths.some((path) => matchPath(pathname, {path, exact: true, strict: true}));
};

// helper that operates on data-fetcher path-to-fetch map and matches entries to given `pathname`
// returns array of matched entries
export const matchFetchEntry = <TMeta, TStore>(fetchEntries: IFetchEntry<TMeta, TStore>[], pathname: string): IMatchedEntry<TMeta, TStore>[] => {
    let matchedEntry: IFetchEntry<TMeta, TStore> | null = null;
    let match: match<object> | null = null;
    for (const entry of fetchEntries) {
        const paths = Array.isArray(entry.path) ? entry.path : [entry.path];
        for (const path of paths) {
            match = matchPath(pathname, {path, exact: entry.exact !== false, sensitive: true, strict: true}); // default for `exact` is `true`
            if (match && !isExcluded(pathname, entry.exclude)) {
                // path does match
                matchedEntry = entry;

                break; // we look for first path-match
            }
        }
        if (matchedEntry) {
            break; // we look for first entry-match only
        }
    }
    // prepare matches
    let result: IMatchedEntry<TMeta, TStore>[] = [];
    if (match && matchedEntry) {
        // we matched the path, so we travel deeper into routes
        const thisMatch = {match, entry: matchedEntry};
        const deepMatches = matchedEntry.routes ? matchFetchEntry(matchedEntry.routes, pathname) : [];
        result = [thisMatch, ...deepMatches];
    }
    return result;
};
