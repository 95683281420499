import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual, isPlainObject} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOnList404} from "../../helpers/friendly_offer_list/redirect_on_list_404";
import {handleFetchOfferListGTMSearchEvents, handleFetchOfferListGTMSortEvents} from "../helpers/handle_fetch_offer_list_gtm_events";
import {getFetchOfferListQuery} from "../helpers/sub_type/get_fetch_offer_list_query";
import {validateFetchOfferListSearchQuery} from "../helpers/validate_fetch_offer_list_query";
import {OfferListSubType} from "../types/OfferListSubType";
import {fetchOfferList} from "./fetch_offer_list";

export const fetchOfferListAtRoute =
    (
        ctx: IFetchContext<
            IRPRequestMeta,
            {friendlySlug?: string; offerListSubType?: OfferListSubType; offerListSubFilter?: OfferListSubType; country?: string},
            Record<string, string | string[] | null>
        >
    ) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const {offerListSubType, offerListSubFilter, friendlySlug} = ctx.match.params;

        const query = getFetchOfferListQuery(
            isPlainObject(ctx.prevResult) && ctx.prevResult !== null ? ctx.prevResult : ctx.route.query,
            offerListSubType,
            offerListSubFilter
        ) as Record<string, string | string[]>;
        const {latestQuery, requestState} = getState().offerList.list;
        const currentRegionCountry = getState().offerList.selectedRegions[0]?.country;
        if (requestState !== RequestState.None && isEqual(latestQuery, query)) {
            return false; // prevent fetching
        }

        // Prevent fetch when filters are not valid and redirect user
        if (!validateFetchOfferListSearchQuery(query)) {
            return dispatch(redirectOnList404(ctx.route, 1, ctx.meta));
        }

        if (isBrowser) {
            handleFetchOfferListGTMSearchEvents(latestQuery, query);
            handleFetchOfferListGTMSortEvents(latestQuery, query);
        }

        const finalQuery = {
            ...query,
            ...(friendlySlug?.includes("gotowe") ? {construction_end_date: "0"} : {}),
            ...(currentRegionCountry ? {country: currentRegionCountry.toString()} : {})
        };

        return dispatch(fetchOfferList(ctx, finalQuery, offerListSubType));
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual, isPlainObject} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOnList404} from "../../helpers/friendly_offer_list/redirect_on_list_404";
import {handleFetchOfferListGTMSearchEvents, handleFetchOfferListGTMSortEvents} from "../helpers/handle_fetch_offer_list_gtm_events";
import {getFetchOfferListQuery} from "../helpers/sub_type/get_fetch_offer_list_query";
import {validateFetchOfferListSearchQuery} from "../helpers/validate_fetch_offer_list_query";
import {OfferListSubType} from "../types/OfferListSubType";
import {fetchOfferList} from "./fetch_offer_list";

export const fetchOfferListAtRoute =
    (
        ctx: IFetchContext<
            IRPRequestMeta,
            {friendlySlug?: string; offerListSubType?: OfferListSubType; offerListSubFilter?: OfferListSubType; country?: string},
            Record<string, string | string[] | null>
        >
    ) =>
    async (dispatch: Dispatch, getState: () => IRPStore) => {
        const {offerListSubType, offerListSubFilter, friendlySlug} = ctx.match.params;

        const query = getFetchOfferListQuery(
            isPlainObject(ctx.prevResult) && ctx.prevResult !== null ? ctx.prevResult : ctx.route.query,
            offerListSubType,
            offerListSubFilter
        ) as Record<string, string | string[]>;
        const {latestQuery, requestState} = getState().offerList.list;
        const currentRegionCountry = getState().offerList.selectedRegions[0]?.country;
        if (requestState !== RequestState.None && isEqual(latestQuery, query)) {
            return false; // prevent fetching
        }

        // Prevent fetch when filters are not valid and redirect user
        if (!validateFetchOfferListSearchQuery(query)) {
            return dispatch(redirectOnList404(ctx.route, 1, ctx.meta));
        }

        if (isBrowser) {
            handleFetchOfferListGTMSearchEvents(latestQuery, query);
            handleFetchOfferListGTMSortEvents(latestQuery, query);
        }

        const finalQuery = {
            ...query,
            ...(friendlySlug?.includes("gotowe") ? {construction_end_date: "0"} : {}),
            ...(currentRegionCountry ? {country: currentRegionCountry.toString()} : {})
        };

        return dispatch(fetchOfferList(ctx, finalQuery, offerListSubType));
    };
