import * as pathToRegexp from "path-to-regexp";

import {IModularHouseOfferParams} from "./types/IModularHouseOfferParams";
import {IRankingAnnualParams} from "./types/IRankingAnnualParams";
import {IRankingParams} from "./types/IRankingParams";
import {rpAppPath} from "./rp_app_path";

export interface IInvestmentOfferCityParams {
    category: string;
    subcategory: InvestmentOfferSubTypeSlug | string;
}

export interface IArticleCategoryParams {
    categorySlug: string;
}

export enum InvestmentOfferSubTypeSlug {
    LAKE = "nad-jeziorem",
    SEA = "nad-morzem",
    MOUNTAIN = "w-gorach"
}

export const rpAppLink = {
    base: () => rpAppPath.base,
    offer: {
        // list
        list: () => rpAppPath.offer.list,
        map: () => rpAppPath.offer.map,
        listFriendly: parseParams<IOfferFriendlyUrlParams>(rpAppPath.friendlyOfferList),
        listFriendlySubType: parseParams<IOfferFriendlySubTypeUrlParams>(rpAppPath.friendlyOfferListSubType),
        listFriendlySubFilterGeneral: parseParams<IOfferFriendlySubFilterGeneralUrlParams>(rpAppPath.friendlyOfferListSubFilterGeneral),
        mapAbroadCountry: parseParams<IOfferFriendlyAbroadCountryParams>(rpAppPath.friendlyOfferListAbroadCountryMap),
        mapAbroadRegion: parseParams<IOfferFriendlyAbroadRegionUrlParams>(rpAppPath.friendlyOfferListAbroadRegionMap),
        mapFriendly: parseParams<IOfferFriendlyUrlParams>(rpAppPath.friendlyOfferListMap.base),
        mapFriendlySubType: parseParams<IOfferFriendlySubTypeUrlParams>(rpAppPath.friendlyOfferListMap.subType),
        multiList: () => rpAppPath.multiOfferList.base,
        multiListMap: () => rpAppPath.multiOfferList.map,
        listFriendlyAbroad: (params: IOfferFriendlyAbroadRegionUrlParams) => {
            return params.abroadRegionSlug
                ? parseParams<IOfferFriendlyAbroadRegionUrlParams>(rpAppPath.friendlyOfferListAbroadRegion)(params)
                : parseParams<IOfferFriendlyAbroadCountryParams>(rpAppPath.friendlyOfferListAbroadCountry)(params);
        },

        // detail
        detail: {
            base: parseParams<IOfferParams>(rpAppPath.offer.detail.base)
        }
    },
    modularHouseOffer: {
        detail: {
            base: parseParams<IModularHouseOfferParams>(rpAppPath.modularHouseOffer.detail.base)
        }
    },
    property: {
        detail: {
            base: parseParams<IPropertyParams>(rpAppPath.property.detail.base)
        }
    },
    article: {
        list: () => rpAppPath.article.list,
        category: parseParams<IArticleCategoryParams>(rpAppPath.article.category),
        detail: parseParams<IArticleParams>(rpAppPath.article.detail),
        author: {
            list: () => rpAppPath.article.author.list,
            detail: parseParams<IAuthorParams>(rpAppPath.article.author.detail)
        }
    },
    investmentOffer: {
        landingPage: () => rpAppPath.investmentOffer.base,
        investmentCategory: {
            base: parseParams<Pick<IInvestmentOfferCityParams, "category">>(rpAppPath.investmentOffer.investmentCategory.base),
            map: parseParams<Pick<IInvestmentOfferCityParams, "category">>(rpAppPath.investmentOffer.investmentCategory.map),
            subcategory: {
                base: parseParams<IInvestmentOfferCityParams>(rpAppPath.investmentOffer.investmentCategory.subcategory.base),
                map: parseParams<IInvestmentOfferCityParams>(rpAppPath.investmentOffer.investmentCategory.subcategory.map)
            }
        },
        map: () => null
    },
    partners: {
        list: () => rpAppPath.partners.list
    },
    promotions: {
        list: () => rpAppPath.promotions.list,
        listFriendly: parseParams<IPromotionFriendlyUrlParams>(rpAppPath.promotions.friendlyPromotionList)
    },
    ranking: {
        base: () => rpAppPath.ranking.base,
        lastYearList: parseParams<IRankingParams>(rpAppPath.ranking.city.base),
        annualList: parseParams<IRankingAnnualParams>(rpAppPath.ranking.city.annual)
    },
    user: {
        base: () => rpAppPath.user.base,
        edit: () => rpAppPath.user.edit,
        favourite: () => rpAppPath.user.favourite,
        notifications: () => rpAppPath.user.notifications,
        knowledge: () => rpAppPath.user.knowledge,
        changePassword: () => rpAppPath.user.changePassword,
        poi: () => rpAppPath.user.poi,
        subscriptions: () => rpAppPath.user.subscriptions
    },
    vendor: {
        list: () => rpAppPath.vendor.list,
        listFriendly: parseParams<IVendorFriendlyUrlParams>(rpAppPath.vendor.friendlyVendorList),
        detail: {
            base: parseParams<IVendorParams>(rpAppPath.vendor.detail.base)
        }
    },
    forDevelopers: {
        base: () => rpAppPath.forDevelopers.base
    },
    architect: {
        list: () => rpAppPath.architect.list,
        city: parseParams<IArchitectUrlParams>(rpAppPath.architect.city)
    },
    specialist: {
        detail: parseParams<ISpecialistUrlParams>(rpAppPath.specialist.detail)
    },
    contractor: {
        base: () => rpAppPath.contractor.base
    },
    location: {
        detail: parseParams<{regionSlug: string}>(rpAppPath.location.detail)
    },
    recommendations: {
        base: () => rpAppPath.recommendations.base
    },
    termsOfService: {
        base: () => rpAppPath.termsOfService.base
    },
    siteMap: {
        base: () => rpAppPath.siteMap.base,
        article: {
            base: () => rpAppPath.siteMap.article.base,
            category: parseParams<ISiteMapArticleParams>(rpAppPath.siteMap.article.category)
        },
        offer: {
            base: () => rpAppPath.siteMap.offer.base,
            voivodeship: parseParams<ISiteMapOfferVoivodeshipParams>(rpAppPath.siteMap.offer.voivodeship)
        },
        vendor: () => rpAppPath.siteMap.vendor,
        house: () => rpAppPath.siteMap.house,
        flat: () => rpAppPath.siteMap.flat,
        commercial: () => rpAppPath.siteMap.commercial
    },
    rodoTerms: {
        base: () => rpAppPath.rodoTerms.base
    },
    partnersTerms: {
        base: () => rpAppPath.partnersTerms.base
    },
    myOffer: () => rpAppPath.myOffer.base,
    notusTos: () => rpAppPath.notusTos,
    ourOffer: () => rpAppPath.ourOffer,
    myList: () => rpAppPath.favouriteList,
    comparisonTool: () => rpAppPath.comparisonTool,
    buylando: () => rpAppPath.buylando,
    prices: {
        base: () => rpAppPath.prices.base,
        flats: {
            base: () => rpAppPath.prices.flats.base,
            city: parseParams<IPriceParams>(rpAppPath.prices.flats.city)
        }
    },
    credits: {
        base: () => rpAppPath.credits.base,
        offers: () => rpAppPath.credits.offers,
        rating: () => rpAppPath.credits.rating
    },
    external: {
        getHome: "https://gethome.pl/",
        crediPassTOS: "https://credipass.pl/polityka-prywatnosci/",
        rentersPrivacyPolicy: "https://renters.pl/polityka-prywatnosci/",
        competition: "https://konkurs.rynekpierwotny.pl/"
    },
    propertyConfigurator: () => rpAppPath.propertyConfigurator,
    newsletter: {
        sms: {
            unsubscribe: parseParams<ISmsUnsubscribeParams>(rpAppPath.newsletter.sms.unsubscribe)
        }
    },
    openDay: {
        base: parseParams<Pick<ISmsCampaignParams, "campaignUuid">>(rpAppPath.openDays.base),
        withSubscriberId: parseParams<ISmsCampaignParams>(rpAppPath.openDays.withSubscriberId)
    }
};

/**
 * Helper
 */
function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

/**
 * interfaces
 */
export interface IOfferParams {
    vendorSlug: string;
    offerSlug: string;
    offerId: number;
}

export interface IVendorParams {
    vendorSlug: string;
    vendorId: number;
}

interface IVendorFriendlyUrlParams {
    friendlySlug: string;
}

export interface IOfferFriendlyUrlParams {
    friendlySlug: string;
}

export interface IOfferFriendlyAbroadCountryParams {
    friendlySlug: string;
    country: string;
}

export interface IOfferFriendlyAbroadRegionUrlParams extends IOfferFriendlyAbroadCountryParams {
    abroadRegionSlug?: string;
}

export interface IOfferFriendlySubTypeUrlParams {
    friendlySlug: string;
    offerListSubType: string;
}

export interface IOfferFriendlySubFilterGeneralUrlParams {
    friendlySlug: string;
    offerListSubFilter: string;
}

export interface IArticleParams {
    articleSlug: string;
    articleId: number;
}

export interface IAuthorParams {
    authorSlug: string;
    authorId: number;
}

interface IPromotionFriendlyUrlParams {
    friendlySlug: string;
}

export interface IPropertyParams {
    vendorSlug: string;
    offerSlug: string;
    offerId: number;
    propertyId: number;
}

export interface ISiteMapArticleParams {
    categorySlug: string;
}

export interface ISiteMapOfferVoivodeshipParams {
    voivodeshipSlug: string;
}

export interface IPasswordChangeParams {
    uid: string;
    token: string;
}

interface IArchitectUrlParams {
    regionSlug: string;
}

interface ISpecialistUrlParams {
    specialistId: number;
    specialistSlug: string;
}

interface IPriceParams {
    regionSlug: string;
}

export interface ISmsCampaignParams {
    campaignUuid: string;
    subscriberUuid?: string;
}

export interface ISmsUnsubscribeParams {
    subscriberUuid: string;
}
import * as pathToRegexp from "path-to-regexp";

import {IModularHouseOfferParams} from "./types/IModularHouseOfferParams";
import {IRankingAnnualParams} from "./types/IRankingAnnualParams";
import {IRankingParams} from "./types/IRankingParams";
import {rpAppPath} from "./rp_app_path";

export interface IInvestmentOfferCityParams {
    category: string;
    subcategory: InvestmentOfferSubTypeSlug | string;
}

export interface IArticleCategoryParams {
    categorySlug: string;
}

export enum InvestmentOfferSubTypeSlug {
    LAKE = "nad-jeziorem",
    SEA = "nad-morzem",
    MOUNTAIN = "w-gorach"
}

export const rpAppLink = {
    base: () => rpAppPath.base,
    offer: {
        // list
        list: () => rpAppPath.offer.list,
        map: () => rpAppPath.offer.map,
        listFriendly: parseParams<IOfferFriendlyUrlParams>(rpAppPath.friendlyOfferList),
        listFriendlySubType: parseParams<IOfferFriendlySubTypeUrlParams>(rpAppPath.friendlyOfferListSubType),
        listFriendlySubFilterGeneral: parseParams<IOfferFriendlySubFilterGeneralUrlParams>(rpAppPath.friendlyOfferListSubFilterGeneral),
        mapAbroadCountry: parseParams<IOfferFriendlyAbroadCountryParams>(rpAppPath.friendlyOfferListAbroadCountryMap),
        mapAbroadRegion: parseParams<IOfferFriendlyAbroadRegionUrlParams>(rpAppPath.friendlyOfferListAbroadRegionMap),
        mapFriendly: parseParams<IOfferFriendlyUrlParams>(rpAppPath.friendlyOfferListMap.base),
        mapFriendlySubType: parseParams<IOfferFriendlySubTypeUrlParams>(rpAppPath.friendlyOfferListMap.subType),
        multiList: () => rpAppPath.multiOfferList.base,
        multiListMap: () => rpAppPath.multiOfferList.map,
        listFriendlyAbroad: (params: IOfferFriendlyAbroadRegionUrlParams) => {
            return params.abroadRegionSlug
                ? parseParams<IOfferFriendlyAbroadRegionUrlParams>(rpAppPath.friendlyOfferListAbroadRegion)(params)
                : parseParams<IOfferFriendlyAbroadCountryParams>(rpAppPath.friendlyOfferListAbroadCountry)(params);
        },

        // detail
        detail: {
            base: parseParams<IOfferParams>(rpAppPath.offer.detail.base)
        }
    },
    modularHouseOffer: {
        detail: {
            base: parseParams<IModularHouseOfferParams>(rpAppPath.modularHouseOffer.detail.base)
        }
    },
    property: {
        detail: {
            base: parseParams<IPropertyParams>(rpAppPath.property.detail.base)
        }
    },
    article: {
        list: () => rpAppPath.article.list,
        category: parseParams<IArticleCategoryParams>(rpAppPath.article.category),
        detail: parseParams<IArticleParams>(rpAppPath.article.detail),
        author: {
            list: () => rpAppPath.article.author.list,
            detail: parseParams<IAuthorParams>(rpAppPath.article.author.detail)
        }
    },
    investmentOffer: {
        landingPage: () => rpAppPath.investmentOffer.base,
        investmentCategory: {
            base: parseParams<Pick<IInvestmentOfferCityParams, "category">>(rpAppPath.investmentOffer.investmentCategory.base),
            map: parseParams<Pick<IInvestmentOfferCityParams, "category">>(rpAppPath.investmentOffer.investmentCategory.map),
            subcategory: {
                base: parseParams<IInvestmentOfferCityParams>(rpAppPath.investmentOffer.investmentCategory.subcategory.base),
                map: parseParams<IInvestmentOfferCityParams>(rpAppPath.investmentOffer.investmentCategory.subcategory.map)
            }
        },
        map: () => null
    },
    partners: {
        list: () => rpAppPath.partners.list
    },
    promotions: {
        list: () => rpAppPath.promotions.list,
        listFriendly: parseParams<IPromotionFriendlyUrlParams>(rpAppPath.promotions.friendlyPromotionList)
    },
    ranking: {
        base: () => rpAppPath.ranking.base,
        lastYearList: parseParams<IRankingParams>(rpAppPath.ranking.city.base),
        annualList: parseParams<IRankingAnnualParams>(rpAppPath.ranking.city.annual)
    },
    user: {
        base: () => rpAppPath.user.base,
        edit: () => rpAppPath.user.edit,
        favourite: () => rpAppPath.user.favourite,
        notifications: () => rpAppPath.user.notifications,
        knowledge: () => rpAppPath.user.knowledge,
        changePassword: () => rpAppPath.user.changePassword,
        poi: () => rpAppPath.user.poi,
        subscriptions: () => rpAppPath.user.subscriptions
    },
    vendor: {
        list: () => rpAppPath.vendor.list,
        listFriendly: parseParams<IVendorFriendlyUrlParams>(rpAppPath.vendor.friendlyVendorList),
        detail: {
            base: parseParams<IVendorParams>(rpAppPath.vendor.detail.base)
        }
    },
    forDevelopers: {
        base: () => rpAppPath.forDevelopers.base
    },
    architect: {
        list: () => rpAppPath.architect.list,
        city: parseParams<IArchitectUrlParams>(rpAppPath.architect.city)
    },
    specialist: {
        detail: parseParams<ISpecialistUrlParams>(rpAppPath.specialist.detail)
    },
    contractor: {
        base: () => rpAppPath.contractor.base
    },
    location: {
        detail: parseParams<{regionSlug: string}>(rpAppPath.location.detail)
    },
    recommendations: {
        base: () => rpAppPath.recommendations.base
    },
    termsOfService: {
        base: () => rpAppPath.termsOfService.base
    },
    siteMap: {
        base: () => rpAppPath.siteMap.base,
        article: {
            base: () => rpAppPath.siteMap.article.base,
            category: parseParams<ISiteMapArticleParams>(rpAppPath.siteMap.article.category)
        },
        offer: {
            base: () => rpAppPath.siteMap.offer.base,
            voivodeship: parseParams<ISiteMapOfferVoivodeshipParams>(rpAppPath.siteMap.offer.voivodeship)
        },
        vendor: () => rpAppPath.siteMap.vendor,
        house: () => rpAppPath.siteMap.house,
        flat: () => rpAppPath.siteMap.flat,
        commercial: () => rpAppPath.siteMap.commercial
    },
    rodoTerms: {
        base: () => rpAppPath.rodoTerms.base
    },
    partnersTerms: {
        base: () => rpAppPath.partnersTerms.base
    },
    myOffer: () => rpAppPath.myOffer.base,
    notusTos: () => rpAppPath.notusTos,
    ourOffer: () => rpAppPath.ourOffer,
    myList: () => rpAppPath.favouriteList,
    comparisonTool: () => rpAppPath.comparisonTool,
    buylando: () => rpAppPath.buylando,
    prices: {
        base: () => rpAppPath.prices.base,
        flats: {
            base: () => rpAppPath.prices.flats.base,
            city: parseParams<IPriceParams>(rpAppPath.prices.flats.city)
        }
    },
    credits: {
        base: () => rpAppPath.credits.base,
        offers: () => rpAppPath.credits.offers,
        rating: () => rpAppPath.credits.rating
    },
    external: {
        getHome: "https://gethome.pl/",
        crediPassTOS: "https://credipass.pl/polityka-prywatnosci/",
        rentersPrivacyPolicy: "https://renters.pl/polityka-prywatnosci/",
        competition: "https://konkurs.rynekpierwotny.pl/"
    },
    propertyConfigurator: () => rpAppPath.propertyConfigurator,
    newsletter: {
        sms: {
            unsubscribe: parseParams<ISmsUnsubscribeParams>(rpAppPath.newsletter.sms.unsubscribe)
        }
    },
    openDay: {
        base: parseParams<Pick<ISmsCampaignParams, "campaignUuid">>(rpAppPath.openDays.base),
        withSubscriberId: parseParams<ISmsCampaignParams>(rpAppPath.openDays.withSubscriberId)
    }
};

/**
 * Helper
 */
function parseParams<T extends {}>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

/**
 * interfaces
 */
export interface IOfferParams {
    vendorSlug: string;
    offerSlug: string;
    offerId: number;
}

export interface IVendorParams {
    vendorSlug: string;
    vendorId: number;
}

interface IVendorFriendlyUrlParams {
    friendlySlug: string;
}

export interface IOfferFriendlyUrlParams {
    friendlySlug: string;
}

export interface IOfferFriendlyAbroadCountryParams {
    friendlySlug: string;
    country: string;
}

export interface IOfferFriendlyAbroadRegionUrlParams extends IOfferFriendlyAbroadCountryParams {
    abroadRegionSlug?: string;
}

export interface IOfferFriendlySubTypeUrlParams {
    friendlySlug: string;
    offerListSubType: string;
}

export interface IOfferFriendlySubFilterGeneralUrlParams {
    friendlySlug: string;
    offerListSubFilter: string;
}

export interface IArticleParams {
    articleSlug: string;
    articleId: number;
}

export interface IAuthorParams {
    authorSlug: string;
    authorId: number;
}

interface IPromotionFriendlyUrlParams {
    friendlySlug: string;
}

export interface IPropertyParams {
    vendorSlug: string;
    offerSlug: string;
    offerId: number;
    propertyId: number;
}

export interface ISiteMapArticleParams {
    categorySlug: string;
}

export interface ISiteMapOfferVoivodeshipParams {
    voivodeshipSlug: string;
}

export interface IPasswordChangeParams {
    uid: string;
    token: string;
}

interface IArchitectUrlParams {
    regionSlug: string;
}

interface ISpecialistUrlParams {
    specialistId: number;
    specialistSlug: string;
}

interface IPriceParams {
    regionSlug: string;
}

export interface ISmsCampaignParams {
    campaignUuid: string;
    subscriberUuid?: string;
}

export interface ISmsUnsubscribeParams {
    subscriberUuid: string;
}
