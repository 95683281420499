import {friendlyFloorChoices} from "../../../constants/friendly_floor_choices";
import {IFriendlyQuery} from "../../../list/types/friendly_query";
import {IFriendlySubQuery} from "../../../list/types/friendly_sub_query";
import {OfferType} from "../../../types/OfferType";
import {isFriendlyQueryWithFilters} from "../is_friendly_query_with_filters";

export type IParsedFriendlySubQuery = Record<keyof IFriendlySubQuery, string | string[]>;
export type ISubQueryValues = {params: Partial<IFriendlySubQuery>; friendly: Partial<IFriendlySubQuery>};

interface ISubFilterValues {
    params: Partial<IFriendlySubQuery>;
    canBeUsedAsFriendlyQuery?: boolean;
}

export const friendlyUrlSubQueryKeys: (keyof IFriendlySubQuery)[] = ["floor_choices", "house_type", "house_additional_areas", "house_storeys"];

export function getSubFilter(
    friendlyQuery: Partial<Record<keyof IFriendlyQuery, string>>,
    friendlySubQuery: Partial<Record<keyof IFriendlySubQuery, string | string[]>>,
    offerListingType: OfferType
): ISubQueryValues {
    const {floor_choices, house_type, house_additional_areas, house_storeys} = friendlySubQuery;

    const subFilters = [
        getFloorChoicesSubFilter(offerListingType, floor_choices),
        getHouseSubFilter({offerListingType, house_type, house_storeys, house_additional_areas})
    ].filter((subFilter) => Object.entries(subFilter.params).length > 0);

    /**
     * Based on info in https://app.clickup.com/t/33njt3t, we assume, that allowed friendly values,
     * are when there's only one filter in both, friendlyQuery and friendlySubQuery
     */
    const numberOfValidSubFilters = subFilters.filter((subFilter) => Object.entries(subFilter.params).length > 0).length;
    const isFriendlySubQueryAllowed = !isFriendlyQueryWithFilters(friendlyQuery) && numberOfValidSubFilters === 1;

    const allFriendlyFromSubFilters = subFilters.filter((subFilter) => subFilter.canBeUsedAsFriendlyQuery);
    const friendly = isFriendlySubQueryAllowed && allFriendlyFromSubFilters.length === 1 ? allFriendlyFromSubFilters[0].params : {};

    return {
        friendly,
        params: subFilters
            .filter((subFilter) => subFilter.params !== friendly)
            .reduce(
                (acc, subFilter) => ({
                    ...acc,
                    ...subFilter.params
                }),
                {} as Partial<IFriendlySubQuery>
            )
    };
}

function getFloorChoicesSubFilter(offerListingType: OfferType, floorChoices?: IParsedFriendlySubQuery["floor_choices"]): ISubFilterValues {
    if (!floorChoices || !isFloorChoicesValid(floorChoices)) {
        return {
            params: {}
        };
    }

    /**
     * Based on info in https://app.clickup.com/t/33njt3t, we assume, that allowed friendly values, are single values
     * For example, floor_choices=1 instead of floor_choices=1&floor_choices=2
     */
    return {
        params: {
            floor_choices: Array.isArray(floorChoices) ? floorChoices.map((floorChoice) => parseInt(floorChoice[0], 10)) : [parseInt(floorChoices, 10)]
        },
        canBeUsedAsFriendlyQuery: offerListingType === OfferType.FLAT && floorChoices.length === 1 && isFloorChoiceValid(floorChoices[0])
    };
}

function isFloorChoicesValid(floorChoices?: IParsedFriendlySubQuery["floor_choices"]): floorChoices is string[] {
    return Array.isArray(floorChoices) && floorChoices.length >= 1;
}

function isFloorChoiceValid(floorChoice: string) {
    return friendlyFloorChoices.includes(parseInt(floorChoice, 10));
}

function castStringsToNumbers(stringsOrString: string | string[]) {
    return Array.isArray(stringsOrString) ? stringsOrString.map((str) => parseInt(str, 10)) : [parseInt(stringsOrString, 10)];
}

function getHouseSubFilter(props: {
    offerListingType: OfferType;
    house_type?: IParsedFriendlySubQuery["house_type"];
    house_additional_areas?: IParsedFriendlySubQuery["house_additional_areas"];
    house_storeys?: IParsedFriendlySubQuery["house_storeys"];
}): ISubFilterValues {
    const isHouseTypeValid = (houseType?: IParsedFriendlySubQuery["house_type"]): houseType is string | string[] =>
        (Array.isArray(houseType) && houseType.length > 0) || !!houseType;
    const areHouseAdditionalAreasValid = (houseAdditionalAreas?: IParsedFriendlySubQuery["house_additional_areas"]): houseAdditionalAreas is string[] =>
        Array.isArray(houseAdditionalAreas) && houseAdditionalAreas.length > 0;
    const areHouseFloorOptionsValid = (houseFloorOptions?: IParsedFriendlySubQuery["house_storeys"]): houseFloorOptions is string | string[] =>
        (Array.isArray(houseFloorOptions) && houseFloorOptions.length > 0) || !!houseFloorOptions;

    const houseSubFilterParams = {
        ...(isHouseTypeValid(props.house_type) ? {house_type: castStringsToNumbers(props.house_type)} : {}),
        ...(areHouseAdditionalAreasValid(props.house_additional_areas)
            ? {
                  house_additional_areas: castStringsToNumbers(props.house_additional_areas)
              }
            : {}),
        ...(areHouseFloorOptionsValid(props.house_storeys) ? {house_storeys: castStringsToNumbers(props.house_storeys)} : {})
    };

    const totalHouseSubFilterParamsFlatten = Object.values(houseSubFilterParams).flat(1).length;

    return {
        params: props.offerListingType === OfferType.HOUSE ? houseSubFilterParams : {},
        canBeUsedAsFriendlyQuery: props.offerListingType === OfferType.HOUSE && totalHouseSubFilterParamsFlatten === 1
    };
}
import {friendlyFloorChoices} from "../../../constants/friendly_floor_choices";
import {IFriendlyQuery} from "../../../list/types/friendly_query";
import {IFriendlySubQuery} from "../../../list/types/friendly_sub_query";
import {OfferType} from "../../../types/OfferType";
import {isFriendlyQueryWithFilters} from "../is_friendly_query_with_filters";

export type IParsedFriendlySubQuery = Record<keyof IFriendlySubQuery, string | string[]>;
export type ISubQueryValues = {params: Partial<IFriendlySubQuery>; friendly: Partial<IFriendlySubQuery>};

interface ISubFilterValues {
    params: Partial<IFriendlySubQuery>;
    canBeUsedAsFriendlyQuery?: boolean;
}

export const friendlyUrlSubQueryKeys: (keyof IFriendlySubQuery)[] = ["floor_choices", "house_type", "house_additional_areas", "house_storeys"];

export function getSubFilter(
    friendlyQuery: Partial<Record<keyof IFriendlyQuery, string>>,
    friendlySubQuery: Partial<Record<keyof IFriendlySubQuery, string | string[]>>,
    offerListingType: OfferType
): ISubQueryValues {
    const {floor_choices, house_type, house_additional_areas, house_storeys} = friendlySubQuery;

    const subFilters = [
        getFloorChoicesSubFilter(offerListingType, floor_choices),
        getHouseSubFilter({offerListingType, house_type, house_storeys, house_additional_areas})
    ].filter((subFilter) => Object.entries(subFilter.params).length > 0);

    /**
     * Based on info in https://app.clickup.com/t/33njt3t, we assume, that allowed friendly values,
     * are when there's only one filter in both, friendlyQuery and friendlySubQuery
     */
    const numberOfValidSubFilters = subFilters.filter((subFilter) => Object.entries(subFilter.params).length > 0).length;
    const isFriendlySubQueryAllowed = !isFriendlyQueryWithFilters(friendlyQuery) && numberOfValidSubFilters === 1;

    const allFriendlyFromSubFilters = subFilters.filter((subFilter) => subFilter.canBeUsedAsFriendlyQuery);
    const friendly = isFriendlySubQueryAllowed && allFriendlyFromSubFilters.length === 1 ? allFriendlyFromSubFilters[0].params : {};

    return {
        friendly,
        params: subFilters
            .filter((subFilter) => subFilter.params !== friendly)
            .reduce(
                (acc, subFilter) => ({
                    ...acc,
                    ...subFilter.params
                }),
                {} as Partial<IFriendlySubQuery>
            )
    };
}

function getFloorChoicesSubFilter(offerListingType: OfferType, floorChoices?: IParsedFriendlySubQuery["floor_choices"]): ISubFilterValues {
    if (!floorChoices || !isFloorChoicesValid(floorChoices)) {
        return {
            params: {}
        };
    }

    /**
     * Based on info in https://app.clickup.com/t/33njt3t, we assume, that allowed friendly values, are single values
     * For example, floor_choices=1 instead of floor_choices=1&floor_choices=2
     */
    return {
        params: {
            floor_choices: Array.isArray(floorChoices) ? floorChoices.map((floorChoice) => parseInt(floorChoice[0], 10)) : [parseInt(floorChoices, 10)]
        },
        canBeUsedAsFriendlyQuery: offerListingType === OfferType.FLAT && floorChoices.length === 1 && isFloorChoiceValid(floorChoices[0])
    };
}

function isFloorChoicesValid(floorChoices?: IParsedFriendlySubQuery["floor_choices"]): floorChoices is string[] {
    return Array.isArray(floorChoices) && floorChoices.length >= 1;
}

function isFloorChoiceValid(floorChoice: string) {
    return friendlyFloorChoices.includes(parseInt(floorChoice, 10));
}

function castStringsToNumbers(stringsOrString: string | string[]) {
    return Array.isArray(stringsOrString) ? stringsOrString.map((str) => parseInt(str, 10)) : [parseInt(stringsOrString, 10)];
}

function getHouseSubFilter(props: {
    offerListingType: OfferType;
    house_type?: IParsedFriendlySubQuery["house_type"];
    house_additional_areas?: IParsedFriendlySubQuery["house_additional_areas"];
    house_storeys?: IParsedFriendlySubQuery["house_storeys"];
}): ISubFilterValues {
    const isHouseTypeValid = (houseType?: IParsedFriendlySubQuery["house_type"]): houseType is string | string[] =>
        (Array.isArray(houseType) && houseType.length > 0) || !!houseType;
    const areHouseAdditionalAreasValid = (houseAdditionalAreas?: IParsedFriendlySubQuery["house_additional_areas"]): houseAdditionalAreas is string[] =>
        Array.isArray(houseAdditionalAreas) && houseAdditionalAreas.length > 0;
    const areHouseFloorOptionsValid = (houseFloorOptions?: IParsedFriendlySubQuery["house_storeys"]): houseFloorOptions is string | string[] =>
        (Array.isArray(houseFloorOptions) && houseFloorOptions.length > 0) || !!houseFloorOptions;

    const houseSubFilterParams = {
        ...(isHouseTypeValid(props.house_type) ? {house_type: castStringsToNumbers(props.house_type)} : {}),
        ...(areHouseAdditionalAreasValid(props.house_additional_areas)
            ? {
                  house_additional_areas: castStringsToNumbers(props.house_additional_areas)
              }
            : {}),
        ...(areHouseFloorOptionsValid(props.house_storeys) ? {house_storeys: castStringsToNumbers(props.house_storeys)} : {})
    };

    const totalHouseSubFilterParamsFlatten = Object.values(houseSubFilterParams).flat(1).length;

    return {
        params: props.offerListingType === OfferType.HOUSE ? houseSubFilterParams : {},
        canBeUsedAsFriendlyQuery: props.offerListingType === OfferType.HOUSE && totalHouseSubFilterParamsFlatten === 1
    };
}
