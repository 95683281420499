import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, catch404, getRequest, IQueryValue, Response400} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {redirectOnList404} from "../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {IPaginatedResponse} from "../../request/IResponse";
import {ArticleCategory, getArticleCategoryBySlug} from "./ArticleCategory";

const ARTICLE_LIST_PREFIX = "article_list/fetch";
export const fetchArticleListTypes = createRequestActionTypes(ARTICLE_LIST_PREFIX);
export const ARTICLE_LIST_PAGE_SIZE = 15;

const ARTICLE_LIST_FEATURED_PREFIX = "article_list/fetch_featured";
export const fetchArticleListFeaturedTypes = createRequestActionTypes(ARTICLE_LIST_FEATURED_PREFIX);
const ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER = 4;
const ARTICLE_LIST_FEATURE_ARTICLES_NUMBER = 0;

export interface IArticleListGallery {
    image: {
        g_img_65x65: string;
        g_img_206x206: string;
        g_img_280x280: string;
        g_img_349x239: string;
        g_img_440x292: string;
        g_img_440x440: string;
        g_img_572x315: string;
        g_img_572x572: string;
        g_img_728x392: string;
    };
}

export interface IArticleListArticle {
    author: IArticleListAuthor;
    date_published: string | null;
    excerpt: string;
    gallery: (IArticleListGallery | undefined)[];
    id: number;
    lead: string | null;
    slug: string;
    title: string;
    featured_categories: ArticleCategory[];
    categories: number[];
}

export interface IArticleListAuthor {
    id: number;
    name: string;
}

export interface IArticleListResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}

const articleListApiLink = apiV2ListLink.article.list;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchArticleListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const page = parseInt(ctx.route.query.page as string, 10) || 1;
    const articleCategory = getArticleCategoryBySlug(ctx.match.params.categorySlug);

    if (ctx.match.params.categorySlug && articleCategory === null) {
        // category slug is passed in url, but we don't recognize it
        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        return false;
    }

    const excludedArticlesNumber = !ctx.route.query.phrase && articleCategory ? ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER : ARTICLE_LIST_FEATURE_ARTICLES_NUMBER;
    const offset = excludedArticlesNumber + ARTICLE_LIST_PAGE_SIZE * (page - 1);
    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        offset,
        limit: ARTICLE_LIST_PAGE_SIZE
    };

    if (articleCategory) {
        query.category = articleCategory;
        if (articleCategory !== ArticleCategory.Press) {
            query.exclude_category = ArticleCategory.Press;
        }
    } else {
        // for latest articles list there is no category-slug
        query.exclude_category = ArticleCategory.Press;
    }

    dispatch({type: fetchArticleListTypes.start, latestQuery: query as Record<string, string[]>});

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            // when we send LIMIT and OFFSET - we do not send page, therefore cannot get 404 response on Empty list. This is a workaround
            if (json.count > excludedArticlesNumber && json.count <= offset) {
                await dispatch(redirectOnList404(ctx.route, page, ctx.meta));
                return false;
            }
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles,
                page: page,
                count: json.count - excludedArticlesNumber,
                page_size: json.page_size || ARTICLE_LIST_PAGE_SIZE
            };
            dispatch({type: fetchArticleListTypes.success, result});
            return json;
        })
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnArticleList(ctx, err, articleCategory));
            })
        );
};

export const resetArticleList = (): IAction => ({type: fetchArticleListTypes.reset});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchFeaturedArticleListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const articleCategory = getArticleCategoryBySlug(ctx.match.params.categorySlug);
    const pageSize = articleCategory ? ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER : ARTICLE_LIST_FEATURE_ARTICLES_NUMBER;
    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        page: 1,
        page_size: pageSize
    };

    if (articleCategory) {
        query.category = articleCategory;
        if (articleCategory !== ArticleCategory.Press) {
            query.exclude_category = ArticleCategory.Press;
        }
    } else {
        // for latest articles list there is no category-slug
        query.exclude_category = ArticleCategory.Press;
    }

    dispatch({
        type: fetchArticleListFeaturedTypes.start,
        latestQuery: query as Record<string, string[]>
    });

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);
    return getRequest(ctx.meta, url)
        .then((json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });

            dispatch({type: fetchArticleListFeaturedTypes.success, result: articles});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        )
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnArticleList(ctx, err, articleCategory));
            })
        );
};

const handleError400OnArticleList =
    (ctx: IFetchContext<IRPRequestMeta>, err: Response400, articleCategory: ArticleCategory | null) => async (dispatch: Dispatch) => {
        // bad request - can occur when someone removes article category, but we are still fetching that ID from api
        notifyBugsnag({message: `Error 400 fetching category id: ${articleCategory}`}, err.message);
        consoleError(`Error 400 fetching category id: ${articleCategory}`, "; route: ", ctx.route, "; API error.message: ", err.message);
        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        return false;
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, catch400, catch404, getRequest, IQueryValue, Response400} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {redirectOnList404} from "../../offer/helpers/friendly_offer_list/redirect_on_list_404";
import {IPaginatedResponse} from "../../request/IResponse";
import {ArticleCategory, getArticleCategoryBySlug} from "./ArticleCategory";

const ARTICLE_LIST_PREFIX = "article_list/fetch";
export const fetchArticleListTypes = createRequestActionTypes(ARTICLE_LIST_PREFIX);
export const ARTICLE_LIST_PAGE_SIZE = 15;

const ARTICLE_LIST_FEATURED_PREFIX = "article_list/fetch_featured";
export const fetchArticleListFeaturedTypes = createRequestActionTypes(ARTICLE_LIST_FEATURED_PREFIX);
const ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER = 4;
const ARTICLE_LIST_FEATURE_ARTICLES_NUMBER = 0;

export interface IArticleListGallery {
    image: {
        g_img_65x65: string;
        g_img_206x206: string;
        g_img_280x280: string;
        g_img_349x239: string;
        g_img_440x292: string;
        g_img_440x440: string;
        g_img_572x315: string;
        g_img_572x572: string;
        g_img_728x392: string;
    };
}

export interface IArticleListArticle {
    author: IArticleListAuthor;
    date_published: string | null;
    excerpt: string;
    gallery: (IArticleListGallery | undefined)[];
    id: number;
    lead: string | null;
    slug: string;
    title: string;
    featured_categories: ArticleCategory[];
    categories: number[];
}

export interface IArticleListAuthor {
    id: number;
    name: string;
}

export interface IArticleListResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}

const articleListApiLink = apiV2ListLink.article.list;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchArticleListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const page = parseInt(ctx.route.query.page as string, 10) || 1;
    const articleCategory = getArticleCategoryBySlug(ctx.match.params.categorySlug);

    if (ctx.match.params.categorySlug && articleCategory === null) {
        // category slug is passed in url, but we don't recognize it
        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        return false;
    }

    const excludedArticlesNumber = !ctx.route.query.phrase && articleCategory ? ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER : ARTICLE_LIST_FEATURE_ARTICLES_NUMBER;
    const offset = excludedArticlesNumber + ARTICLE_LIST_PAGE_SIZE * (page - 1);
    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        offset,
        limit: ARTICLE_LIST_PAGE_SIZE
    };

    if (articleCategory) {
        query.category = articleCategory;
        if (articleCategory !== ArticleCategory.Press) {
            query.exclude_category = ArticleCategory.Press;
        }
    } else {
        // for latest articles list there is no category-slug
        query.exclude_category = ArticleCategory.Press;
    }

    dispatch({type: fetchArticleListTypes.start, latestQuery: query as Record<string, string[]>});

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);

    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            // when we send LIMIT and OFFSET - we do not send page, therefore cannot get 404 response on Empty list. This is a workaround
            if (json.count > excludedArticlesNumber && json.count <= offset) {
                await dispatch(redirectOnList404(ctx.route, page, ctx.meta));
                return false;
            }
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles,
                page: page,
                count: json.count - excludedArticlesNumber,
                page_size: json.page_size || ARTICLE_LIST_PAGE_SIZE
            };
            dispatch({type: fetchArticleListTypes.success, result});
            return json;
        })
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnArticleList(ctx, err, articleCategory));
            })
        );
};

export const resetArticleList = (): IAction => ({type: fetchArticleListTypes.reset});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchFeaturedArticleListAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch, getState: () => IRPStore) => {
    const articleCategory = getArticleCategoryBySlug(ctx.match.params.categorySlug);
    const pageSize = articleCategory ? ARTICLE_CATEGORY_FEATURE_ARTICLES_NUMBER : ARTICLE_LIST_FEATURE_ARTICLES_NUMBER;
    const query: Record<string, IQueryValue> = {
        ...ctx.route.query,
        page: 1,
        page_size: pageSize
    };

    if (articleCategory) {
        query.category = articleCategory;
        if (articleCategory !== ArticleCategory.Press) {
            query.exclude_category = ArticleCategory.Press;
        }
    } else {
        // for latest articles list there is no category-slug
        query.exclude_category = ArticleCategory.Press;
    }

    dispatch({
        type: fetchArticleListFeaturedTypes.start,
        latestQuery: query as Record<string, string[]>
    });

    const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);
    return getRequest(ctx.meta, url)
        .then((json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });

            dispatch({type: fetchArticleListFeaturedTypes.success, result: articles});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        )
        .catch(
            catch400(async (err) => {
                return dispatch(handleError400OnArticleList(ctx, err, articleCategory));
            })
        );
};

const handleError400OnArticleList =
    (ctx: IFetchContext<IRPRequestMeta>, err: Response400, articleCategory: ArticleCategory | null) => async (dispatch: Dispatch) => {
        // bad request - can occur when someone removes article category, but we are still fetching that ID from api
        notifyBugsnag({message: `Error 400 fetching category id: ${articleCategory}`}, err.message);
        consoleError(`Error 400 fetching category id: ${articleCategory}`, "; route: ", ctx.route, "; API error.message: ", err.message);
        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
        return false;
    };
