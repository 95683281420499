import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagRuIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#clip0_12221_2235)">
                <g clipPath="url(#clip1_12221_2235)">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                    <path d="M1.00005 5.71436H9.00005V7.71436H1L1.00005 5.71436Z" fill="#DE1119" />
                    <path d="M1 3.71436H9V5.71436H1V3.71436Z" fill="#1158A8" />
                    <rect x="1" y="2" width="8" height="1.91429" fill="white" />
                </g>
            </g>
            <rect x="1.14286" y="2.14286" width="7.71429" height="5.42857" rx="0.428571" stroke="#F5F5F5" strokeWidth="0.285714" />
            <defs>
                <clipPath id="clip0_12221_2235">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
                <clipPath id="clip1_12221_2235">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
import React from "react";

import {FlagIcon} from "../../components/FlagIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FlagRuIcon = (props: IIcon) => {
    return (
        <FlagIcon {...props}>
            <g clipPath="url(#clip0_12221_2235)">
                <g clipPath="url(#clip1_12221_2235)">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                    <path d="M1.00005 5.71436H9.00005V7.71436H1L1.00005 5.71436Z" fill="#DE1119" />
                    <path d="M1 3.71436H9V5.71436H1V3.71436Z" fill="#1158A8" />
                    <rect x="1" y="2" width="8" height="1.91429" fill="white" />
                </g>
            </g>
            <rect x="1.14286" y="2.14286" width="7.71429" height="5.42857" rx="0.428571" stroke="#F5F5F5" strokeWidth="0.285714" />
            <defs>
                <clipPath id="clip0_12221_2235">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
                <clipPath id="clip1_12221_2235">
                    <rect x="1" y="2" width="8" height="5.71429" rx="0.571429" fill="white" />
                </clipPath>
            </defs>
        </FlagIcon>
    );
};
