import {css} from "@emotion/react";

export const listUnStyled = css`
    padding-left: 0;
    list-style: none;
    //  TODO: Shouldn't we remove also the margin?
`;
import {css} from "@emotion/react";

export const listUnStyled = css`
    padding-left: 0;
    list-style: none;
    //  TODO: Shouldn't we remove also the margin?
`;
