import {createDataFetcher, IFetchEntry, mapActions, reduceActions, strictMapActions} from "@pg-mono/data-fetcher";
import {rpAppLink, rpAppPath} from "@pg-mono/rp-routes";

import {generateApplicationAtRoute} from "../application/actions/generate_application";
import {fetchArticleCommentListAtRoute} from "../article/actions/fetch_article_comment_list";
import {fetchArticleDetail} from "../article/actions/fetch_article_detail";
import {fetchArticleListAtRoute, fetchFeaturedArticleListAtRoute} from "../article/actions/fetch_article_list";
import {fetchArticleListByIdsAtRoute} from "../article/actions/fetch_article_list_by_ids";
import {fetchArticlesWrittenByAuthorAtRoute} from "../article/actions/fetch_articles_written_by_author";
import {fetchAuthorDetailAtRoute} from "../article/actions/fetch_author_detail";
import {fetchAuthorsListAtRoute} from "../article/actions/fetch_authors_list";
import {fetchCategoryDescriptionAtRoute} from "../article/actions/fetch_category_description";
import {fetchRelatedArticlesAtRoute} from "../article/actions/fetch_related_articles";
import {updateArticleCategoryMetaData, updateArticleDetailMetaData, updateArticleListMetaData} from "../article/actions/update_article_meta_data";
import {updateAuthorDetailMetaData} from "../article/actions/update_author_detail_metadata";
import {updateAuthorsListMetaData} from "../article/actions/update_authors_list_meta_data";
import {redirectAfterOauthLoginAtRoute} from "../auth/actions/redirect_after_oauth_login";
import {updateBuylandoMetaData} from "../buylando/actions/update_buylando_meta_data";
import {updateContractorMeta} from "../contractor/actions/update_contractor_meta_data";
import {updateCreditLandingMeta, updateCreditOffersMeta, updateCreditRatingOffersMeta} from "../credit_calculator/actions/update_credits_meta";
import {fetchFaqListAtRoute} from "../faq/actions/fetch_faq";
import {fetchInvestmentOfferLPArticleList} from "../investment_offer/actions/fetch_investment_offer_article_list";
import {fetchInvestmentOfferStatsAtRoute} from "../investment_offer/actions/fetch_investment_offer_stats";
import {fetchInvestmentOfferListAbroadVoivodeshipAtRoute} from "../investment_offer/actions/fetch_investment_offer_voivodeship_regions";
import {updateInvestmentOfferMetaData} from "../investment_offer/actions/update_investment_offer_meta_data";
import {fetchInvestmentOfferListAtRoute, fetchInvestmentOfferListMapAtRoute} from "../investment_offer/list/actions/fetch_investment_offer_list";
import {parseInvestmentOfferUrl} from "../investment_offer/list/actions/parse_investment_offer_url";
import {updateInvestmentOfferListMetaDataAtRoute} from "../investment_offer/list/actions/update_investment_offer_list_meta_data";
import {updateInvestmentOfferListMapMetaDataAtRoute} from "../investment_offer/map/actions/update_investment_offer_list_map_meta_data";
import {fetchCustomMetaData} from "../meta_data/actions/fetch_custom_meta_data";
import {modularHousesOfferDetailViewAction} from "../modular_houses/actions/modular_houses_offer_detail_view_action";
import {fetchTopArticles} from "../navigation/actions/fetch_top_articles";
import {fetchUserUnsubscriptionDataAtRoute} from "../newsletter/actions/fetch_user_unsubscription_data";
import {unsubscribeUserFromSmsAtRoute} from "../newsletter/actions/unsubscribe_user_from_sms";
import {updateNewsletterUnsubscribeMetaData} from "../newsletter/actions/update_newsletter_unsubscribe_meta_data";
import {offerListingAction} from "../offer/actions/offer_listing_action";
import {fetchOfferDetailAtRoute} from "../offer/detail/actions/fetch_offer_detail";
import {fetchOfferDetailArticlesAtRoute, fetchPropertyDetailArticlesAtRoute} from "../offer/detail/actions/fetch_offer_detail_articles";
import {fetchOfferDetailRelatedOffersListAtRoute} from "../offer/detail/actions/fetch_offer_detail_offers_list";
import {fetchOfferDetailPoiStatsAtRoute} from "../offer/detail/actions/fetch_offer_detail_poi";
import {fetchOfferDetailPromotionsAtRoute} from "../offer/detail/actions/fetch_offer_detail_promotions";
import {fetchOfferDetailPropertiesAtRoute} from "../offer/detail/actions/fetch_offer_detail_properties";
import {
    fetchOfferDetailRecommendedRegionsAtRoute,
    fetchPropertyDetailRecommendedRegionsAtRoute
} from "../offer/detail/actions/fetch_offer_detail_recommended_regions";
import {fetchOfferTransportPoiAtRoute} from "../offer/detail/actions/fetch_offer_transport_poi_at_route";
import {redirectToOfferDetail} from "../offer/detail/actions/redirect_to_offer_detail";
import {updateOfferDetailMetaData} from "../offer/detail/actions/update_offer_detail_meta_data";
import {parseFriendlyUrl} from "../offer/helpers/friendly_offer_list/parse_friendly_url";
import {redirectToFriendlyUrlForNoMultiRegion} from "../offer/helpers/friendly_offer_list/redirect_to_friendly_url_for_no_multi_region";
import {redirectWithEmptyParams} from "../offer/helpers/friendly_offer_list/redirect_witch_empty_params";
import {redirectWithoutDistanceIfNotAllowed} from "../offer/helpers/friendly_offer_list/redirect_without_distance_if_not_allowed";
import {redirectWithoutFirstPage} from "../offer/helpers/friendly_offer_list/redirect_without_first_page";
import {redirectWithoutGivenParams} from "../offer/helpers/friendly_offer_list/redirect_without_given_params";
import {fetchSearchAtRoute} from "../offer/helpers/searches/fetch_search";
import {fetchOfferListArticlesAtRoute} from "../offer/list/actions/fetch_offer_list_articles";
import {fetchOfferListAtRoute} from "../offer/list/actions/fetch_offer_list_at_route";
import {fetchOfferListNearbyRegionsAtRoute} from "../offer/list/actions/fetch_offer_list_nearby_regions";
import {fetchOfferListRegionPathAtRoute} from "../offer/list/actions/fetch_offer_list_parent_regions";
import {fetchOfferListRecommendedRegionsAtRoute} from "../offer/list/actions/fetch_offer_list_recommended_regions";
import {fetchSelectedRegionsAtRoute} from "../offer/list/actions/offer_list_selected_data_actions";
import {redirectFromSubFilterToOfferList} from "../offer/list/actions/redirect_from_subfilter_to_offer_list";
import {redirectFromSubtypeToOfferList} from "../offer/list/actions/redirect_from_subtype_to_offer_list";
import {redirectToFriendlyUrl} from "../offer/list/actions/redirect_to_friendly_url";
import {redirectToProperSearchSlug} from "../offer/list/actions/redirect_to_proper_search_slug";
import {setOfferListMetaDataDefaults} from "../offer/list/actions/set_offer_list_meta_data_defaults";
import {updateOfferListMetaData} from "../offer/list/actions/update_offer_list_meta_data";
import {updateOfferMultiListMetaData} from "../offer/list/actions/update_offer_multi_list_meta_data";
import {fetchAppliedOffersAtRoute} from "../offer/map/actions/fetch_applied_offers";
import {fetchMapOfferListAtRoute} from "../offer/map/actions/fetch_map_offer_list";
import {fetchMapRegionAtRoute} from "../offer/map/actions/fetch_map_region";
import {fetchMapRegionDistrictsAndSurroundings} from "../offer/map/actions/fetch_map_region_districts_and_surroundings";
import {updateOfferListMapMetaData} from "../offer/map/actions/update_offer_list_map_meta_data";
import {updateOfferMultiListMapMetaData} from "../offer/map/actions/update_offer_multi_list_map_meta_data";
import {fetchTestimonials} from "../our_offer/actions/fetch_testimonials";
import {updateOurOfferMetaData} from "../our_offer/actions/update_our_offer_meta_data";
import {fetchPartnersList} from "../partners/actions/fetch_partners_list";
import {updatePartnersListMetaData} from "../partners/actions/update_partners_list_meta_data";
import {redirectArticlePermalink} from "../permalinks/actions/redirect_article_permalink";
import {redirectOfferPermalink} from "../permalinks/actions/redirect_offer_permalink";
import {redirectOpendayPermalink} from "../permalinks/actions/redirect_openday_permalink";
import {redirectPropertyPermalink} from "../permalinks/actions/redirect_property_permalink";
import {redirectVendorPermalink} from "../permalinks/actions/redirect_vendor_permalink";
import {conditionallyRedirectToFriendlyPromotionUrlAtRoute} from "../promotion/actions/conditionally_redirect_to_friendly_promotion_url";
import {fetchPromotionListAtRoute} from "../promotion/actions/fetch_promotion_list";
import {resetClientOnlyPromotionSearchFormAtRoute} from "../promotion/actions/fetch_promotion_search_lists";
import {parseFriendlyPromotionUrlAtRoute} from "../promotion/actions/parse_promotion_url";
import {updateFriendlyPromotionListMetaDataAtRoute, updatePromotionListMetaDataAtRoute} from "../promotion/actions/update_promotion_meta_data";
import {validateFriendlyPromotionUrlAtRoute} from "../promotion/actions/validate_friendly_promotion_url";
import {fetchRegionStatsAtRoute, fetchRegionStatsOnPropertyDetailAtRoute} from "../property/actions/fetch_properties_stats";
import {fetchPropertyDetailAtRoute} from "../property/actions/fetch_property_detail";
import {fetchPropertyDetailRelatedOffersListAtRoute} from "../property/actions/fetch_property_detail_offers_list";
import {fetchDesktopPropertyDetailPromotionsAtRoute} from "../property/actions/fetch_property_detail_promotions";
import {updatePropertyDetailMetaData} from "../property/actions/update_property_detail_mata_data";
import {updatePropertyConfiguratorMetaData} from "../property_configurator/actions/update_property_configurator_meta_data";
import {rankingArchivedYearViewAction} from "../ranking/actions/ranking_archived_year_view_action";
import {rankingLastYearViewAction} from "../ranking/actions/ranking_last_year_view_action";
import {rankingMainViewAction} from "../ranking/actions/ranking_main_view_action";
import {getReelListAction} from "../reels/actions/get_reel_list_action";
import {getReelListForOfferAction} from "../reels/actions/get_reel_list_for_offer_action";
import {fetchPriceReportRegion} from "../report/price/actions/fetch_price_report_region";
import {fetchPricesArticleList} from "../report/price/actions/fetch_prices_article_list";
import {fetchReportPriceAveragePrices, fetchReportPriceYearAgoAveragePrices} from "../report/price/actions/fetch_report_price_average_prices";
import {updatePricesFlatMetaData} from "../report/price/actions/update_prices_meta_data";
import {validatePriceReportRegion} from "../report/price/actions/validate_price_report_region";
import {fetchAllCitiesPrices} from "../report/price/api/fetch_all_cities_prices";
import {fetchAllCitiesPricesOld} from "../report/price/api/fetch_all_cities_prices_old";
import {resetClientOnlyMultiRegionSearchFormAtRoute, resetClientOnlySearchFormAtRoute} from "../search/actions/search_form_actions";
import {fetchSiteMapArticleCategories} from "../site_map/actions/fetch_site_map_article_category";
import {fetchSiteMapArticleList} from "../site_map/actions/fetch_site_map_article_list";
import {fetchSiteMapOfferList} from "../site_map/actions/fetch_site_map_offer";
import {
    fetchSiteMapRegionAggregationList,
    fetchSiteMapRegionCityList,
    fetchSiteMapRegionCountyList,
    fetchSiteMapRegionVoivodeshipList
} from "../site_map/actions/fetch_site_map_region";
import {fetchSiteMapVendorList} from "../site_map/actions/fetch_site_map_vendor_list";
import {updateSiteMapArticleListMetaData} from "../site_map/actions/meta-data/update_site_map_article_list_meta_data";
import {
    updateSiteMapArticleCategoriesMetaData,
    updateSiteMapMetaData,
    updateSiteMapOfferVoivodeshipListMetaData,
    updateSiteMapVendorListMetaData
} from "../site_map/actions/meta-data/update_site_map_metadata";
import {updateSiteMapOfferTypeMetaData} from "../site_map/actions/meta-data/update_site_map_offer_type_metadata";
import {updateSiteMapOfferVoivodeshipMetaData} from "../site_map/actions/meta-data/update_site_map_offer_voivodeship_metadata";
import {
    redirectToSiteMap,
    redirectToSiteMapCommercials,
    redirectToSiteMapFlats,
    redirectToSiteMapHouses,
    redirectToSiteMapVendors
} from "../site_map/actions/redirect_obsolete_paths";
import {smsCampaignAction} from "../sms_campaign/actions/sms_campaign_action";
import {fetchVariablesAtRoute} from "../variables/actions/fetch_variables";
import {fetchRecommendedRegionsVendorsAtRoute} from "../vendor/actions/fetch_recommended_regions_vendors";
import {fetchVendorDetailAtRoute} from "../vendor/actions/fetch_vendor_detail";
import {fetchVendorDetailCitiesAtRoute} from "../vendor/actions/fetch_vendor_detail_cities";
import {fetchVendorDetailOffersAtRoute} from "../vendor/actions/fetch_vendor_detail_offers";
import {fetchVendorDetailRankingsAtRoute} from "../vendor/actions/fetch_vendor_detail_rankings";
import {fetchVendorDetailArticlesAtRoute} from "../vendor/actions/fetch_vendor_detail_related_articles";
import {fetchVendorListAtRoute, resetSoldVendorsAtRoute} from "../vendor/actions/fetch_vendor_list";
import {fetchVendorRegionAtRoute, resetVendorRegionList} from "../vendor/actions/fetch_vendor_list_region";
import {fetchVendorNearbyRegionVendorsAtRoute} from "../vendor/actions/fetch_vendor_nearby_regions_vendors";
import {fetchVendorRegionSEOMetadataAtRoute, resetVendorRegionSEOMetadataAtRoute} from "../vendor/actions/fetch_vendor_region_seo_metadata";
import {resetClientOnlyVendorSearchFormAtRoute} from "../vendor/actions/fetch_vendor_search_lists";
import {redirectToFriendlyVendorUrl} from "../vendor/actions/redirect_to_friendly_vendor_url";
import {redirectToSEOFriendlyListing} from "../vendor/actions/redirect_to_seo_friendly_listing";
import {redirectToVendorDetail} from "../vendor/actions/redirect_to_vendor_detail";
import {updateVendorDetailMetaData, updateVendorListMetaData} from "../vendor/actions/update_vendor_meta_data";
import {
    setArticleDetailViewTypeAtRoute,
    setArticleListViewTypeAtRoute,
    setBuylandoLandingViewTypeAtRoute,
    setComparisonToolViewTypeAtRoute,
    setCreditsLandingViewTypeAtRoute,
    setCreditsOfferLandingViewTypeAtRoute,
    setCreditsOfferRatingViewTypeAtRoute,
    setForInvestmentsListViewTypeAtRoute,
    setForInvestmentsMapViewTypeAtRoute,
    setForInvestmentsViewTypeAtRoute,
    setHomepageViewTypeAtRoute,
    setMyListViewTypeAtRoute,
    setOfferDetailViewTypeAtRoute,
    setOfferListMapViewTypeAtRoute,
    setOfferListViewTypeAtRoute,
    setPromotionViewTypeAtRoute,
    setPropertyDetailViewTypeAtRoute,
    setVendorDetailViewTypeAtRoute,
    setVendorListViewTypeAtRoute
} from "../view_type/view_type_actions";
import {rpFeatureFlagNewUserFlow} from "./read_rp_environment_variables";
import {IRPStore} from "./rp_reducer";
import {IRPRequestMeta} from "./rp_request_meta";

const RP_FETCH_ROUTES: IFetchEntry<IRPRequestMeta, IRPStore>[] = [
    {
        path: rpAppPath.base,
        exact: false,
        fetch: strictMapActions(fetchVariablesAtRoute, fetchTopArticles, fetchCustomMetaData),
        routes: [
            {
                path: rpAppPath.base,
                fetch: setHomepageViewTypeAtRoute
            },

            // offer list
            {
                path: rpAppPath.offer.list,
                fetch: redirectToFriendlyUrl
            },
            {
                path: rpAppPath.multiOfferList.base,
                fetch: reduceActions(
                    redirectToFriendlyUrlForNoMultiRegion,
                    redirectWithoutFirstPage,
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerList.selectedRegions),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerList.selectedRegions) : () => async () => true,
                        fetchOfferListAtRoute,
                        fetchFaqListAtRoute,
                        resetClientOnlyMultiRegionSearchFormAtRoute,
                        getReelListAction
                    ),
                    updateOfferMultiListMetaData,
                    setOfferListViewTypeAtRoute
                )
            },
            {
                exclude: [rpAppPath.friendlyOfferListAbroadCountryMap],
                path: [
                    rpAppPath.friendlyOfferListAbroadCountry,
                    rpAppPath.friendlyOfferListAbroadRegion,
                    rpAppPath.friendlyOfferList,
                    rpAppPath.friendlyOfferListSubType,
                    rpAppPath.friendlyOfferListSubFilterGeneral
                ],

                fetch: reduceActions(
                    redirectWithoutFirstPage,
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerList.selectedRegions),
                    parseFriendlyUrl,
                    redirectFromSubtypeToOfferList,
                    redirectFromSubFilterToOfferList,
                    setOfferListMetaDataDefaults,
                    fetchSearchAtRoute,
                    redirectToProperSearchSlug,
                    mapActions(
                        rpFeatureFlagNewUserFlow ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerList.selectedRegions) : () => async () => true,
                        fetchOfferListAtRoute,
                        offerListingAction,
                        fetchFaqListAtRoute,
                        fetchOfferListArticlesAtRoute,
                        fetchOfferListRecommendedRegionsAtRoute,
                        fetchOfferListNearbyRegionsAtRoute,
                        fetchOfferListRegionPathAtRoute,
                        resetClientOnlySearchFormAtRoute,
                        getReelListAction
                    ),
                    updateOfferListMetaData,
                    setOfferListViewTypeAtRoute
                )
            },
            // offer detail
            {
                path: rpAppPath.offer.detail.base,
                fetch: reduceActions(
                    fetchOfferDetailAtRoute,
                    mapActions(
                        updateOfferDetailMetaData,
                        fetchOfferDetailArticlesAtRoute,
                        fetchOfferDetailPropertiesAtRoute,
                        fetchOfferTransportPoiAtRoute,
                        fetchOfferDetailPoiStatsAtRoute,
                        fetchOfferDetailPromotionsAtRoute,
                        fetchOfferDetailRelatedOffersListAtRoute,
                        fetchOfferDetailRecommendedRegionsAtRoute,
                        getReelListForOfferAction
                    ),
                    setOfferDetailViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.offer.detail.redirects.askAboutOffer,
                    rpAppPath.offer.detail.redirects.externalSite,
                    rpAppPath.offer.detail.redirects.propertyList,
                    rpAppPath.offer.detail.redirects.virtualTour,
                    rpAppPath.offer.detail.redirects.map
                ],
                fetch: redirectToOfferDetail
            },
            // partners
            {
                path: rpAppPath.partners.list,
                fetch: reduceActions(fetchPartnersList, updatePartnersListMetaData)
            },
            // property detail
            {
                path: rpAppPath.property.detail.base,
                fetch: reduceActions(
                    fetchPropertyDetailAtRoute,
                    mapActions(
                        updatePropertyDetailMetaData,
                        fetchPropertyDetailArticlesAtRoute,
                        fetchOfferDetailPoiStatsAtRoute,
                        fetchPropertyDetailRelatedOffersListAtRoute,
                        fetchPropertyDetailRecommendedRegionsAtRoute,
                        fetchDesktopPropertyDetailPromotionsAtRoute,
                        fetchRegionStatsOnPropertyDetailAtRoute
                    ),
                    setPropertyDetailViewTypeAtRoute
                )
            },
            // property detail
            {
                path: rpAppPath.modularHouseOffer.detail.base,
                fetch: reduceActions(modularHousesOfferDetailViewAction)
            },
            // offer list map
            {
                path: rpAppPath.multiOfferList.map,
                fetch: reduceActions(
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchRegionStatsAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        resetClientOnlyMultiRegionSearchFormAtRoute
                    ),
                    updateOfferMultiListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.friendlyOfferListAbroadCountryMap,
                    rpAppPath.friendlyOfferListAbroadRegionMap,
                    rpAppPath.friendlyOfferListMap.base,
                    rpAppPath.friendlyOfferListMap.subType
                ],
                fetch: reduceActions(
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    parseFriendlyUrl,
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        fetchRegionStatsAtRoute,
                        resetClientOnlySearchFormAtRoute
                    ),
                    updateOfferListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.offer.map,
                fetch: reduceActions(
                    redirectWithEmptyParams(["region"]),
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        fetchRegionStatsAtRoute,
                        resetClientOnlySearchFormAtRoute
                    ),
                    updateOfferListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },

            {
                path: rpAppPath.article.detail,
                fetch: reduceActions(
                    fetchArticleDetail,
                    mapActions(updateArticleDetailMetaData, fetchRelatedArticlesAtRoute, fetchFaqListAtRoute),
                    fetchArticleCommentListAtRoute,
                    setArticleDetailViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.article.list,
                fetch: reduceActions(
                    fetchSiteMapArticleCategories,
                    redirectWithoutFirstPage,
                    mapActions(fetchArticleListAtRoute, fetchFeaturedArticleListAtRoute, setArticleListViewTypeAtRoute),
                    updateArticleListMetaData
                )
            },
            {
                path: rpAppPath.article.category,
                fetch: reduceActions(
                    fetchSiteMapArticleCategories,
                    redirectWithoutFirstPage,
                    fetchArticleListAtRoute,
                    fetchFeaturedArticleListAtRoute,
                    fetchCategoryDescriptionAtRoute,
                    updateArticleCategoryMetaData,
                    setArticleListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.article.author.list,
                fetch: mapActions(fetchAuthorsListAtRoute, updateAuthorsListMetaData)
            },
            // our-offer
            {
                path: rpAppPath.ourOffer,
                fetch: reduceActions(fetchTestimonials, updateOurOfferMetaData)
            },
            // property detail
            {
                path: rpAppPath.property.detail.base,
                fetch: reduceActions(fetchPropertyDetailAtRoute)
            },
            {
                path: rpAppPath.article.author.detail,
                fetch: reduceActions(fetchAuthorDetailAtRoute, fetchArticlesWrittenByAuthorAtRoute, updateAuthorDetailMetaData)
            },
            // sms campaign
            {
                path: [rpAppPath.openDays.base, rpAppPath.openDays.withSubscriberId],
                fetch: reduceActions(smsCampaignAction)
            },
            // vendor
            {
                path: rpAppPath.vendor.list,
                fetch: reduceActions(
                    redirectToFriendlyVendorUrl,
                    redirectWithoutFirstPage,
                    resetSoldVendorsAtRoute,
                    fetchFaqListAtRoute,
                    mapActions(
                        fetchVendorListAtRoute,
                        fetchRecommendedRegionsVendorsAtRoute,
                        fetchVendorNearbyRegionVendorsAtRoute,
                        resetVendorRegionSEOMetadataAtRoute,
                        resetVendorRegionList
                    ),
                    resetClientOnlyVendorSearchFormAtRoute,
                    updateVendorListMetaData,
                    setVendorListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.vendor.friendlyVendorList,
                fetch: reduceActions(
                    redirectWithoutFirstPage,
                    resetSoldVendorsAtRoute,
                    fetchFaqListAtRoute,
                    reduceActions(
                        fetchVendorRegionAtRoute,
                        fetchVendorListAtRoute,
                        fetchRecommendedRegionsVendorsAtRoute,
                        fetchVendorNearbyRegionVendorsAtRoute,
                        fetchVendorRegionSEOMetadataAtRoute,
                        redirectToSEOFriendlyListing
                    ),
                    resetClientOnlyVendorSearchFormAtRoute,
                    updateVendorListMetaData,
                    setVendorListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.vendor.detail.base,
                fetch: reduceActions(
                    mapActions(
                        fetchVendorDetailAtRoute,
                        mapActions(
                            fetchVendorDetailOffersAtRoute,
                            fetchVendorDetailArticlesAtRoute,
                            fetchVendorDetailRankingsAtRoute,
                            fetchVendorDetailCitiesAtRoute,
                            fetchAppliedOffersAtRoute
                        )
                    ),
                    updateVendorDetailMetaData,
                    setVendorDetailViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.vendor.detail.redirects.customRodoTerms,
                    rpAppPath.vendor.detail.redirects.externalSite,
                    rpAppPath.vendor.detail.redirects.askAboutOffer
                ],
                fetch: redirectToVendorDetail
            },
            // ranking
            {
                path: rpAppPath.ranking.base,
                fetch: rankingMainViewAction
            },
            {
                path: rpAppPath.ranking.city.base,
                fetch: rankingLastYearViewAction
            },
            {
                path: rpAppPath.ranking.city.annual,
                fetch: rankingArchivedYearViewAction
            },
            // investment offer
            {
                path: rpAppPath.investmentOffer.base,
                fetch: reduceActions(
                    mapActions(fetchInvestmentOfferLPArticleList, fetchInvestmentOfferStatsAtRoute, updateInvestmentOfferMetaData),
                    setForInvestmentsViewTypeAtRoute
                )
            },
            {
                path: [rpAppPath.investmentOffer.investmentCategory.map, rpAppPath.investmentOffer.investmentCategory.subcategory.map],
                fetch: reduceActions(
                    setForInvestmentsMapViewTypeAtRoute,
                    redirectWithoutFirstPage,
                    parseInvestmentOfferUrl(rpAppLink.investmentOffer.investmentCategory.map),
                    fetchInvestmentOfferListMapAtRoute,
                    fetchInvestmentOfferStatsAtRoute,
                    fetchAppliedOffersAtRoute,
                    updateInvestmentOfferListMapMetaDataAtRoute
                )
            },
            {
                path: [rpAppPath.investmentOffer.investmentCategory.base, rpAppPath.investmentOffer.investmentCategory.subcategory.base],
                fetch: reduceActions(
                    setForInvestmentsListViewTypeAtRoute,
                    redirectWithoutFirstPage,
                    fetchFaqListAtRoute,
                    parseInvestmentOfferUrl(rpAppLink.investmentOffer.investmentCategory.base),
                    fetchInvestmentOfferListAtRoute,
                    fetchInvestmentOfferStatsAtRoute,
                    updateInvestmentOfferListMetaDataAtRoute,
                    resetClientOnlySearchFormAtRoute,
                    fetchInvestmentOfferListAbroadVoivodeshipAtRoute
                ),
                exact: false
            },
            {
                path: rpAppPath.oauth.redirect,
                fetch: redirectAfterOauthLoginAtRoute
            },
            {
                path: rpAppPath.favouriteList,
                fetch: reduceActions(setMyListViewTypeAtRoute)
            },
            {
                path: rpAppPath.comparisonTool,
                fetch: reduceActions(setComparisonToolViewTypeAtRoute)
            },
            {path: rpAppPath.permalinks.article, fetch: redirectArticlePermalink},
            {path: rpAppPath.permalinks.offer, fetch: redirectOfferPermalink},
            {path: rpAppPath.permalinks.openDay, fetch: redirectOpendayPermalink},
            {path: rpAppPath.permalinks.property, fetch: redirectPropertyPermalink},
            {path: rpAppPath.permalinks.vendor, fetch: redirectVendorPermalink},
            {path: rpAppPath.buylando, fetch: reduceActions(setBuylandoLandingViewTypeAtRoute, updateBuylandoMetaData)},
            // promotion list
            {
                path: rpAppPath.promotions.list,
                fetch: reduceActions(
                    setPromotionViewTypeAtRoute,
                    conditionallyRedirectToFriendlyPromotionUrlAtRoute,
                    fetchPromotionListAtRoute,
                    updatePromotionListMetaDataAtRoute,
                    fetchFaqListAtRoute
                )
            },
            {
                path: rpAppPath.promotions.friendlyPromotionList,
                fetch: reduceActions(
                    setPromotionViewTypeAtRoute,
                    parseFriendlyPromotionUrlAtRoute,
                    validateFriendlyPromotionUrlAtRoute,
                    mapActions(fetchPromotionListAtRoute, resetClientOnlyPromotionSearchFormAtRoute),
                    updateFriendlyPromotionListMetaDataAtRoute,
                    fetchFaqListAtRoute
                )
            },
            // siteMap
            {
                path: rpAppPath.siteMap.base,
                fetch: updateSiteMapMetaData
            },
            {
                path: rpAppPath.siteMap.article.base,
                fetch: reduceActions(fetchSiteMapArticleCategories, updateSiteMapArticleCategoriesMetaData)
            },
            {
                path: rpAppPath.siteMap.article.category,
                fetch: reduceActions(fetchSiteMapArticleCategories, fetchSiteMapArticleList, updateSiteMapArticleListMetaData)
            },
            {
                path: rpAppPath.siteMap.vendor,
                fetch: reduceActions(fetchSiteMapVendorList, updateSiteMapVendorListMetaData)
            },
            {
                path: rpAppPath.siteMap.vendor,
                exact: false,
                fetch: redirectToSiteMapVendors
            },
            {
                path: rpAppPath.siteMap.offer.base,
                fetch: reduceActions(fetchSiteMapRegionVoivodeshipList, updateSiteMapOfferVoivodeshipListMetaData)
            },
            {
                path: rpAppPath.siteMap.offer.voivodeship,
                fetch: reduceActions(fetchSiteMapRegionVoivodeshipList, fetchSiteMapOfferList, updateSiteMapOfferVoivodeshipMetaData)
            },
            {
                path: [rpAppPath.siteMap.flat, rpAppPath.siteMap.house, rpAppPath.siteMap.commercial],
                fetch: reduceActions(
                    mapActions(fetchSiteMapRegionVoivodeshipList, fetchSiteMapRegionAggregationList, fetchSiteMapRegionCountyList, fetchSiteMapRegionCityList),
                    updateSiteMapOfferTypeMetaData
                )
            },
            {
                path: rpAppPath.siteMap.flat,
                exact: false,
                fetch: redirectToSiteMapFlats
            },
            {
                path: rpAppPath.siteMap.house,
                exact: false,
                fetch: redirectToSiteMapHouses
            },
            {
                path: rpAppPath.siteMap.commercial,
                exact: false,
                fetch: redirectToSiteMapCommercials
            },
            {
                path: rpAppPath.siteMap.base,
                exact: false,
                fetch: redirectToSiteMap
            },
            // newsletter
            {
                path: rpAppPath.newsletter.mailing.unsubscribe,
                fetch: reduceActions(fetchUserUnsubscriptionDataAtRoute, updateNewsletterUnsubscribeMetaData)
            },
            {
                path: rpAppPath.newsletter.sms.unsubscribe,
                fetch: unsubscribeUserFromSmsAtRoute
            },
            // price report
            {
                path: rpAppPath.prices.flats.base,
                fetch: reduceActions(fetchFaqListAtRoute, updatePricesFlatMetaData, fetchAllCitiesPrices, fetchAllCitiesPricesOld, fetchPricesArticleList)
            },
            {
                path: rpAppPath.prices.flats.city,
                fetch: reduceActions(
                    validatePriceReportRegion,
                    fetchPriceReportRegion,
                    fetchFaqListAtRoute,
                    fetchReportPriceAveragePrices,
                    fetchReportPriceYearAgoAveragePrices,
                    updatePricesFlatMetaData
                )
            },
            // property configurator
            {
                path: rpAppPath.propertyConfigurator,
                fetch: reduceActions(updatePropertyConfiguratorMetaData)
            },
            // credits
            {
                path: rpAppPath.credits.base,
                fetch: reduceActions(mapActions(fetchArticleListByIdsAtRoute, fetchFaqListAtRoute), updateCreditLandingMeta, setCreditsLandingViewTypeAtRoute)
            },
            {
                path: rpAppPath.credits.offers,
                fetch: reduceActions(updateCreditOffersMeta, setCreditsOfferLandingViewTypeAtRoute, fetchFaqListAtRoute)
            },
            {
                path: rpAppPath.credits.rating,
                fetch: reduceActions(
                    mapActions(fetchArticleListByIdsAtRoute, fetchFaqListAtRoute),
                    updateCreditRatingOffersMeta,
                    setCreditsOfferRatingViewTypeAtRoute
                )
            },
            // application generator
            {
                path: rpAppPath.redirect.applicationLeadGenerator,
                fetch: generateApplicationAtRoute
            },
            // LP for contractors
            {
                path: rpAppPath.contractor.base,
                fetch: updateContractorMeta
            }
        ]
    }
];
export const rpDataFetcher = createDataFetcher<IRPRequestMeta, IRPStore>(RP_FETCH_ROUTES);
import {createDataFetcher, IFetchEntry, mapActions, reduceActions, strictMapActions} from "@pg-mono/data-fetcher";
import {rpAppLink, rpAppPath} from "@pg-mono/rp-routes";

import {generateApplicationAtRoute} from "../application/actions/generate_application";
import {fetchArticleCommentListAtRoute} from "../article/actions/fetch_article_comment_list";
import {fetchArticleDetail} from "../article/actions/fetch_article_detail";
import {fetchArticleListAtRoute, fetchFeaturedArticleListAtRoute} from "../article/actions/fetch_article_list";
import {fetchArticleListByIdsAtRoute} from "../article/actions/fetch_article_list_by_ids";
import {fetchArticlesWrittenByAuthorAtRoute} from "../article/actions/fetch_articles_written_by_author";
import {fetchAuthorDetailAtRoute} from "../article/actions/fetch_author_detail";
import {fetchAuthorsListAtRoute} from "../article/actions/fetch_authors_list";
import {fetchCategoryDescriptionAtRoute} from "../article/actions/fetch_category_description";
import {fetchRelatedArticlesAtRoute} from "../article/actions/fetch_related_articles";
import {updateArticleCategoryMetaData, updateArticleDetailMetaData, updateArticleListMetaData} from "../article/actions/update_article_meta_data";
import {updateAuthorDetailMetaData} from "../article/actions/update_author_detail_metadata";
import {updateAuthorsListMetaData} from "../article/actions/update_authors_list_meta_data";
import {redirectAfterOauthLoginAtRoute} from "../auth/actions/redirect_after_oauth_login";
import {updateBuylandoMetaData} from "../buylando/actions/update_buylando_meta_data";
import {updateContractorMeta} from "../contractor/actions/update_contractor_meta_data";
import {updateCreditLandingMeta, updateCreditOffersMeta, updateCreditRatingOffersMeta} from "../credit_calculator/actions/update_credits_meta";
import {fetchFaqListAtRoute} from "../faq/actions/fetch_faq";
import {fetchInvestmentOfferLPArticleList} from "../investment_offer/actions/fetch_investment_offer_article_list";
import {fetchInvestmentOfferStatsAtRoute} from "../investment_offer/actions/fetch_investment_offer_stats";
import {fetchInvestmentOfferListAbroadVoivodeshipAtRoute} from "../investment_offer/actions/fetch_investment_offer_voivodeship_regions";
import {updateInvestmentOfferMetaData} from "../investment_offer/actions/update_investment_offer_meta_data";
import {fetchInvestmentOfferListAtRoute, fetchInvestmentOfferListMapAtRoute} from "../investment_offer/list/actions/fetch_investment_offer_list";
import {parseInvestmentOfferUrl} from "../investment_offer/list/actions/parse_investment_offer_url";
import {updateInvestmentOfferListMetaDataAtRoute} from "../investment_offer/list/actions/update_investment_offer_list_meta_data";
import {updateInvestmentOfferListMapMetaDataAtRoute} from "../investment_offer/map/actions/update_investment_offer_list_map_meta_data";
import {fetchCustomMetaData} from "../meta_data/actions/fetch_custom_meta_data";
import {modularHousesOfferDetailViewAction} from "../modular_houses/actions/modular_houses_offer_detail_view_action";
import {fetchTopArticles} from "../navigation/actions/fetch_top_articles";
import {fetchUserUnsubscriptionDataAtRoute} from "../newsletter/actions/fetch_user_unsubscription_data";
import {unsubscribeUserFromSmsAtRoute} from "../newsletter/actions/unsubscribe_user_from_sms";
import {updateNewsletterUnsubscribeMetaData} from "../newsletter/actions/update_newsletter_unsubscribe_meta_data";
import {offerListingAction} from "../offer/actions/offer_listing_action";
import {fetchOfferDetailAtRoute} from "../offer/detail/actions/fetch_offer_detail";
import {fetchOfferDetailArticlesAtRoute, fetchPropertyDetailArticlesAtRoute} from "../offer/detail/actions/fetch_offer_detail_articles";
import {fetchOfferDetailRelatedOffersListAtRoute} from "../offer/detail/actions/fetch_offer_detail_offers_list";
import {fetchOfferDetailPoiStatsAtRoute} from "../offer/detail/actions/fetch_offer_detail_poi";
import {fetchOfferDetailPromotionsAtRoute} from "../offer/detail/actions/fetch_offer_detail_promotions";
import {fetchOfferDetailPropertiesAtRoute} from "../offer/detail/actions/fetch_offer_detail_properties";
import {
    fetchOfferDetailRecommendedRegionsAtRoute,
    fetchPropertyDetailRecommendedRegionsAtRoute
} from "../offer/detail/actions/fetch_offer_detail_recommended_regions";
import {fetchOfferTransportPoiAtRoute} from "../offer/detail/actions/fetch_offer_transport_poi_at_route";
import {redirectToOfferDetail} from "../offer/detail/actions/redirect_to_offer_detail";
import {updateOfferDetailMetaData} from "../offer/detail/actions/update_offer_detail_meta_data";
import {parseFriendlyUrl} from "../offer/helpers/friendly_offer_list/parse_friendly_url";
import {redirectToFriendlyUrlForNoMultiRegion} from "../offer/helpers/friendly_offer_list/redirect_to_friendly_url_for_no_multi_region";
import {redirectWithEmptyParams} from "../offer/helpers/friendly_offer_list/redirect_witch_empty_params";
import {redirectWithoutDistanceIfNotAllowed} from "../offer/helpers/friendly_offer_list/redirect_without_distance_if_not_allowed";
import {redirectWithoutFirstPage} from "../offer/helpers/friendly_offer_list/redirect_without_first_page";
import {redirectWithoutGivenParams} from "../offer/helpers/friendly_offer_list/redirect_without_given_params";
import {fetchSearchAtRoute} from "../offer/helpers/searches/fetch_search";
import {fetchOfferListArticlesAtRoute} from "../offer/list/actions/fetch_offer_list_articles";
import {fetchOfferListAtRoute} from "../offer/list/actions/fetch_offer_list_at_route";
import {fetchOfferListNearbyRegionsAtRoute} from "../offer/list/actions/fetch_offer_list_nearby_regions";
import {fetchOfferListRegionPathAtRoute} from "../offer/list/actions/fetch_offer_list_parent_regions";
import {fetchOfferListRecommendedRegionsAtRoute} from "../offer/list/actions/fetch_offer_list_recommended_regions";
import {fetchSelectedRegionsAtRoute} from "../offer/list/actions/offer_list_selected_data_actions";
import {redirectFromSubFilterToOfferList} from "../offer/list/actions/redirect_from_subfilter_to_offer_list";
import {redirectFromSubtypeToOfferList} from "../offer/list/actions/redirect_from_subtype_to_offer_list";
import {redirectToFriendlyUrl} from "../offer/list/actions/redirect_to_friendly_url";
import {redirectToProperSearchSlug} from "../offer/list/actions/redirect_to_proper_search_slug";
import {setOfferListMetaDataDefaults} from "../offer/list/actions/set_offer_list_meta_data_defaults";
import {updateOfferListMetaData} from "../offer/list/actions/update_offer_list_meta_data";
import {updateOfferMultiListMetaData} from "../offer/list/actions/update_offer_multi_list_meta_data";
import {fetchAppliedOffersAtRoute} from "../offer/map/actions/fetch_applied_offers";
import {fetchMapOfferListAtRoute} from "../offer/map/actions/fetch_map_offer_list";
import {fetchMapRegionAtRoute} from "../offer/map/actions/fetch_map_region";
import {fetchMapRegionDistrictsAndSurroundings} from "../offer/map/actions/fetch_map_region_districts_and_surroundings";
import {updateOfferListMapMetaData} from "../offer/map/actions/update_offer_list_map_meta_data";
import {updateOfferMultiListMapMetaData} from "../offer/map/actions/update_offer_multi_list_map_meta_data";
import {fetchTestimonials} from "../our_offer/actions/fetch_testimonials";
import {updateOurOfferMetaData} from "../our_offer/actions/update_our_offer_meta_data";
import {fetchPartnersList} from "../partners/actions/fetch_partners_list";
import {updatePartnersListMetaData} from "../partners/actions/update_partners_list_meta_data";
import {redirectArticlePermalink} from "../permalinks/actions/redirect_article_permalink";
import {redirectOfferPermalink} from "../permalinks/actions/redirect_offer_permalink";
import {redirectOpendayPermalink} from "../permalinks/actions/redirect_openday_permalink";
import {redirectPropertyPermalink} from "../permalinks/actions/redirect_property_permalink";
import {redirectVendorPermalink} from "../permalinks/actions/redirect_vendor_permalink";
import {conditionallyRedirectToFriendlyPromotionUrlAtRoute} from "../promotion/actions/conditionally_redirect_to_friendly_promotion_url";
import {fetchPromotionListAtRoute} from "../promotion/actions/fetch_promotion_list";
import {resetClientOnlyPromotionSearchFormAtRoute} from "../promotion/actions/fetch_promotion_search_lists";
import {parseFriendlyPromotionUrlAtRoute} from "../promotion/actions/parse_promotion_url";
import {updateFriendlyPromotionListMetaDataAtRoute, updatePromotionListMetaDataAtRoute} from "../promotion/actions/update_promotion_meta_data";
import {validateFriendlyPromotionUrlAtRoute} from "../promotion/actions/validate_friendly_promotion_url";
import {fetchRegionStatsAtRoute, fetchRegionStatsOnPropertyDetailAtRoute} from "../property/actions/fetch_properties_stats";
import {fetchPropertyDetailAtRoute} from "../property/actions/fetch_property_detail";
import {fetchPropertyDetailRelatedOffersListAtRoute} from "../property/actions/fetch_property_detail_offers_list";
import {fetchDesktopPropertyDetailPromotionsAtRoute} from "../property/actions/fetch_property_detail_promotions";
import {updatePropertyDetailMetaData} from "../property/actions/update_property_detail_mata_data";
import {updatePropertyConfiguratorMetaData} from "../property_configurator/actions/update_property_configurator_meta_data";
import {rankingArchivedYearViewAction} from "../ranking/actions/ranking_archived_year_view_action";
import {rankingLastYearViewAction} from "../ranking/actions/ranking_last_year_view_action";
import {rankingMainViewAction} from "../ranking/actions/ranking_main_view_action";
import {getReelListAction} from "../reels/actions/get_reel_list_action";
import {getReelListForOfferAction} from "../reels/actions/get_reel_list_for_offer_action";
import {fetchPriceReportRegion} from "../report/price/actions/fetch_price_report_region";
import {fetchPricesArticleList} from "../report/price/actions/fetch_prices_article_list";
import {fetchReportPriceAveragePrices, fetchReportPriceYearAgoAveragePrices} from "../report/price/actions/fetch_report_price_average_prices";
import {updatePricesFlatMetaData} from "../report/price/actions/update_prices_meta_data";
import {validatePriceReportRegion} from "../report/price/actions/validate_price_report_region";
import {fetchAllCitiesPrices} from "../report/price/api/fetch_all_cities_prices";
import {fetchAllCitiesPricesOld} from "../report/price/api/fetch_all_cities_prices_old";
import {resetClientOnlyMultiRegionSearchFormAtRoute, resetClientOnlySearchFormAtRoute} from "../search/actions/search_form_actions";
import {fetchSiteMapArticleCategories} from "../site_map/actions/fetch_site_map_article_category";
import {fetchSiteMapArticleList} from "../site_map/actions/fetch_site_map_article_list";
import {fetchSiteMapOfferList} from "../site_map/actions/fetch_site_map_offer";
import {
    fetchSiteMapRegionAggregationList,
    fetchSiteMapRegionCityList,
    fetchSiteMapRegionCountyList,
    fetchSiteMapRegionVoivodeshipList
} from "../site_map/actions/fetch_site_map_region";
import {fetchSiteMapVendorList} from "../site_map/actions/fetch_site_map_vendor_list";
import {updateSiteMapArticleListMetaData} from "../site_map/actions/meta-data/update_site_map_article_list_meta_data";
import {
    updateSiteMapArticleCategoriesMetaData,
    updateSiteMapMetaData,
    updateSiteMapOfferVoivodeshipListMetaData,
    updateSiteMapVendorListMetaData
} from "../site_map/actions/meta-data/update_site_map_metadata";
import {updateSiteMapOfferTypeMetaData} from "../site_map/actions/meta-data/update_site_map_offer_type_metadata";
import {updateSiteMapOfferVoivodeshipMetaData} from "../site_map/actions/meta-data/update_site_map_offer_voivodeship_metadata";
import {
    redirectToSiteMap,
    redirectToSiteMapCommercials,
    redirectToSiteMapFlats,
    redirectToSiteMapHouses,
    redirectToSiteMapVendors
} from "../site_map/actions/redirect_obsolete_paths";
import {smsCampaignAction} from "../sms_campaign/actions/sms_campaign_action";
import {fetchVariablesAtRoute} from "../variables/actions/fetch_variables";
import {fetchRecommendedRegionsVendorsAtRoute} from "../vendor/actions/fetch_recommended_regions_vendors";
import {fetchVendorDetailAtRoute} from "../vendor/actions/fetch_vendor_detail";
import {fetchVendorDetailCitiesAtRoute} from "../vendor/actions/fetch_vendor_detail_cities";
import {fetchVendorDetailOffersAtRoute} from "../vendor/actions/fetch_vendor_detail_offers";
import {fetchVendorDetailRankingsAtRoute} from "../vendor/actions/fetch_vendor_detail_rankings";
import {fetchVendorDetailArticlesAtRoute} from "../vendor/actions/fetch_vendor_detail_related_articles";
import {fetchVendorListAtRoute, resetSoldVendorsAtRoute} from "../vendor/actions/fetch_vendor_list";
import {fetchVendorRegionAtRoute, resetVendorRegionList} from "../vendor/actions/fetch_vendor_list_region";
import {fetchVendorNearbyRegionVendorsAtRoute} from "../vendor/actions/fetch_vendor_nearby_regions_vendors";
import {fetchVendorRegionSEOMetadataAtRoute, resetVendorRegionSEOMetadataAtRoute} from "../vendor/actions/fetch_vendor_region_seo_metadata";
import {resetClientOnlyVendorSearchFormAtRoute} from "../vendor/actions/fetch_vendor_search_lists";
import {redirectToFriendlyVendorUrl} from "../vendor/actions/redirect_to_friendly_vendor_url";
import {redirectToSEOFriendlyListing} from "../vendor/actions/redirect_to_seo_friendly_listing";
import {redirectToVendorDetail} from "../vendor/actions/redirect_to_vendor_detail";
import {updateVendorDetailMetaData, updateVendorListMetaData} from "../vendor/actions/update_vendor_meta_data";
import {
    setArticleDetailViewTypeAtRoute,
    setArticleListViewTypeAtRoute,
    setBuylandoLandingViewTypeAtRoute,
    setComparisonToolViewTypeAtRoute,
    setCreditsLandingViewTypeAtRoute,
    setCreditsOfferLandingViewTypeAtRoute,
    setCreditsOfferRatingViewTypeAtRoute,
    setForInvestmentsListViewTypeAtRoute,
    setForInvestmentsMapViewTypeAtRoute,
    setForInvestmentsViewTypeAtRoute,
    setHomepageViewTypeAtRoute,
    setMyListViewTypeAtRoute,
    setOfferDetailViewTypeAtRoute,
    setOfferListMapViewTypeAtRoute,
    setOfferListViewTypeAtRoute,
    setPromotionViewTypeAtRoute,
    setPropertyDetailViewTypeAtRoute,
    setVendorDetailViewTypeAtRoute,
    setVendorListViewTypeAtRoute
} from "../view_type/view_type_actions";
import {rpFeatureFlagNewUserFlow} from "./read_rp_environment_variables";
import {IRPStore} from "./rp_reducer";
import {IRPRequestMeta} from "./rp_request_meta";

const RP_FETCH_ROUTES: IFetchEntry<IRPRequestMeta, IRPStore>[] = [
    {
        path: rpAppPath.base,
        exact: false,
        fetch: strictMapActions(fetchVariablesAtRoute, fetchTopArticles, fetchCustomMetaData),
        routes: [
            {
                path: rpAppPath.base,
                fetch: setHomepageViewTypeAtRoute
            },

            // offer list
            {
                path: rpAppPath.offer.list,
                fetch: redirectToFriendlyUrl
            },
            {
                path: rpAppPath.multiOfferList.base,
                fetch: reduceActions(
                    redirectToFriendlyUrlForNoMultiRegion,
                    redirectWithoutFirstPage,
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerList.selectedRegions),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerList.selectedRegions) : () => async () => true,
                        fetchOfferListAtRoute,
                        fetchFaqListAtRoute,
                        resetClientOnlyMultiRegionSearchFormAtRoute,
                        getReelListAction
                    ),
                    updateOfferMultiListMetaData,
                    setOfferListViewTypeAtRoute
                )
            },
            {
                exclude: [rpAppPath.friendlyOfferListAbroadCountryMap],
                path: [
                    rpAppPath.friendlyOfferListAbroadCountry,
                    rpAppPath.friendlyOfferListAbroadRegion,
                    rpAppPath.friendlyOfferList,
                    rpAppPath.friendlyOfferListSubType,
                    rpAppPath.friendlyOfferListSubFilterGeneral
                ],

                fetch: reduceActions(
                    redirectWithoutFirstPage,
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerList.selectedRegions),
                    parseFriendlyUrl,
                    redirectFromSubtypeToOfferList,
                    redirectFromSubFilterToOfferList,
                    setOfferListMetaDataDefaults,
                    fetchSearchAtRoute,
                    redirectToProperSearchSlug,
                    mapActions(
                        rpFeatureFlagNewUserFlow ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerList.selectedRegions) : () => async () => true,
                        fetchOfferListAtRoute,
                        offerListingAction,
                        fetchFaqListAtRoute,
                        fetchOfferListArticlesAtRoute,
                        fetchOfferListRecommendedRegionsAtRoute,
                        fetchOfferListNearbyRegionsAtRoute,
                        fetchOfferListRegionPathAtRoute,
                        resetClientOnlySearchFormAtRoute,
                        getReelListAction
                    ),
                    updateOfferListMetaData,
                    setOfferListViewTypeAtRoute
                )
            },
            // offer detail
            {
                path: rpAppPath.offer.detail.base,
                fetch: reduceActions(
                    fetchOfferDetailAtRoute,
                    mapActions(
                        updateOfferDetailMetaData,
                        fetchOfferDetailArticlesAtRoute,
                        fetchOfferDetailPropertiesAtRoute,
                        fetchOfferTransportPoiAtRoute,
                        fetchOfferDetailPoiStatsAtRoute,
                        fetchOfferDetailPromotionsAtRoute,
                        fetchOfferDetailRelatedOffersListAtRoute,
                        fetchOfferDetailRecommendedRegionsAtRoute,
                        getReelListForOfferAction
                    ),
                    setOfferDetailViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.offer.detail.redirects.askAboutOffer,
                    rpAppPath.offer.detail.redirects.externalSite,
                    rpAppPath.offer.detail.redirects.propertyList,
                    rpAppPath.offer.detail.redirects.virtualTour,
                    rpAppPath.offer.detail.redirects.map
                ],
                fetch: redirectToOfferDetail
            },
            // partners
            {
                path: rpAppPath.partners.list,
                fetch: reduceActions(fetchPartnersList, updatePartnersListMetaData)
            },
            // property detail
            {
                path: rpAppPath.property.detail.base,
                fetch: reduceActions(
                    fetchPropertyDetailAtRoute,
                    mapActions(
                        updatePropertyDetailMetaData,
                        fetchPropertyDetailArticlesAtRoute,
                        fetchOfferDetailPoiStatsAtRoute,
                        fetchPropertyDetailRelatedOffersListAtRoute,
                        fetchPropertyDetailRecommendedRegionsAtRoute,
                        fetchDesktopPropertyDetailPromotionsAtRoute,
                        fetchRegionStatsOnPropertyDetailAtRoute
                    ),
                    setPropertyDetailViewTypeAtRoute
                )
            },
            // property detail
            {
                path: rpAppPath.modularHouseOffer.detail.base,
                fetch: reduceActions(modularHousesOfferDetailViewAction)
            },
            // offer list map
            {
                path: rpAppPath.multiOfferList.map,
                fetch: reduceActions(
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchRegionStatsAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        resetClientOnlyMultiRegionSearchFormAtRoute
                    ),
                    updateOfferMultiListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.friendlyOfferListAbroadCountryMap,
                    rpAppPath.friendlyOfferListAbroadRegionMap,
                    rpAppPath.friendlyOfferListMap.base,
                    rpAppPath.friendlyOfferListMap.subType
                ],
                fetch: reduceActions(
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    parseFriendlyUrl,
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        fetchRegionStatsAtRoute,
                        resetClientOnlySearchFormAtRoute
                    ),
                    updateOfferListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.offer.map,
                fetch: reduceActions(
                    redirectWithEmptyParams(["region"]),
                    redirectWithoutGivenParams(["page", "sort"]),
                    redirectWithoutDistanceIfNotAllowed,
                    fetchSelectedRegionsAtRoute((state) => state.offerListMap.selectedRegion),
                    () => async () => true, // to disable `prevActionResult` query for following actions
                    mapActions(
                        rpFeatureFlagNewUserFlow
                            ? fetchMapRegionDistrictsAndSurroundings((state) => state.offerListMap.selectedRegion)
                            : () => async () => true,
                        fetchMapOfferListAtRoute,
                        fetchMapRegionAtRoute,
                        fetchAppliedOffersAtRoute,
                        fetchRegionStatsAtRoute,
                        resetClientOnlySearchFormAtRoute
                    ),
                    updateOfferListMapMetaData,
                    setOfferListMapViewTypeAtRoute
                )
            },

            {
                path: rpAppPath.article.detail,
                fetch: reduceActions(
                    fetchArticleDetail,
                    mapActions(updateArticleDetailMetaData, fetchRelatedArticlesAtRoute, fetchFaqListAtRoute),
                    fetchArticleCommentListAtRoute,
                    setArticleDetailViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.article.list,
                fetch: reduceActions(
                    fetchSiteMapArticleCategories,
                    redirectWithoutFirstPage,
                    mapActions(fetchArticleListAtRoute, fetchFeaturedArticleListAtRoute, setArticleListViewTypeAtRoute),
                    updateArticleListMetaData
                )
            },
            {
                path: rpAppPath.article.category,
                fetch: reduceActions(
                    fetchSiteMapArticleCategories,
                    redirectWithoutFirstPage,
                    fetchArticleListAtRoute,
                    fetchFeaturedArticleListAtRoute,
                    fetchCategoryDescriptionAtRoute,
                    updateArticleCategoryMetaData,
                    setArticleListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.article.author.list,
                fetch: mapActions(fetchAuthorsListAtRoute, updateAuthorsListMetaData)
            },
            // our-offer
            {
                path: rpAppPath.ourOffer,
                fetch: reduceActions(fetchTestimonials, updateOurOfferMetaData)
            },
            // property detail
            {
                path: rpAppPath.property.detail.base,
                fetch: reduceActions(fetchPropertyDetailAtRoute)
            },
            {
                path: rpAppPath.article.author.detail,
                fetch: reduceActions(fetchAuthorDetailAtRoute, fetchArticlesWrittenByAuthorAtRoute, updateAuthorDetailMetaData)
            },
            // sms campaign
            {
                path: [rpAppPath.openDays.base, rpAppPath.openDays.withSubscriberId],
                fetch: reduceActions(smsCampaignAction)
            },
            // vendor
            {
                path: rpAppPath.vendor.list,
                fetch: reduceActions(
                    redirectToFriendlyVendorUrl,
                    redirectWithoutFirstPage,
                    resetSoldVendorsAtRoute,
                    fetchFaqListAtRoute,
                    mapActions(
                        fetchVendorListAtRoute,
                        fetchRecommendedRegionsVendorsAtRoute,
                        fetchVendorNearbyRegionVendorsAtRoute,
                        resetVendorRegionSEOMetadataAtRoute,
                        resetVendorRegionList
                    ),
                    resetClientOnlyVendorSearchFormAtRoute,
                    updateVendorListMetaData,
                    setVendorListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.vendor.friendlyVendorList,
                fetch: reduceActions(
                    redirectWithoutFirstPage,
                    resetSoldVendorsAtRoute,
                    fetchFaqListAtRoute,
                    reduceActions(
                        fetchVendorRegionAtRoute,
                        fetchVendorListAtRoute,
                        fetchRecommendedRegionsVendorsAtRoute,
                        fetchVendorNearbyRegionVendorsAtRoute,
                        fetchVendorRegionSEOMetadataAtRoute,
                        redirectToSEOFriendlyListing
                    ),
                    resetClientOnlyVendorSearchFormAtRoute,
                    updateVendorListMetaData,
                    setVendorListViewTypeAtRoute
                )
            },
            {
                path: rpAppPath.vendor.detail.base,
                fetch: reduceActions(
                    mapActions(
                        fetchVendorDetailAtRoute,
                        mapActions(
                            fetchVendorDetailOffersAtRoute,
                            fetchVendorDetailArticlesAtRoute,
                            fetchVendorDetailRankingsAtRoute,
                            fetchVendorDetailCitiesAtRoute,
                            fetchAppliedOffersAtRoute
                        )
                    ),
                    updateVendorDetailMetaData,
                    setVendorDetailViewTypeAtRoute
                )
            },
            {
                path: [
                    rpAppPath.vendor.detail.redirects.customRodoTerms,
                    rpAppPath.vendor.detail.redirects.externalSite,
                    rpAppPath.vendor.detail.redirects.askAboutOffer
                ],
                fetch: redirectToVendorDetail
            },
            // ranking
            {
                path: rpAppPath.ranking.base,
                fetch: rankingMainViewAction
            },
            {
                path: rpAppPath.ranking.city.base,
                fetch: rankingLastYearViewAction
            },
            {
                path: rpAppPath.ranking.city.annual,
                fetch: rankingArchivedYearViewAction
            },
            // investment offer
            {
                path: rpAppPath.investmentOffer.base,
                fetch: reduceActions(
                    mapActions(fetchInvestmentOfferLPArticleList, fetchInvestmentOfferStatsAtRoute, updateInvestmentOfferMetaData),
                    setForInvestmentsViewTypeAtRoute
                )
            },
            {
                path: [rpAppPath.investmentOffer.investmentCategory.map, rpAppPath.investmentOffer.investmentCategory.subcategory.map],
                fetch: reduceActions(
                    setForInvestmentsMapViewTypeAtRoute,
                    redirectWithoutFirstPage,
                    parseInvestmentOfferUrl(rpAppLink.investmentOffer.investmentCategory.map),
                    fetchInvestmentOfferListMapAtRoute,
                    fetchInvestmentOfferStatsAtRoute,
                    fetchAppliedOffersAtRoute,
                    updateInvestmentOfferListMapMetaDataAtRoute
                )
            },
            {
                path: [rpAppPath.investmentOffer.investmentCategory.base, rpAppPath.investmentOffer.investmentCategory.subcategory.base],
                fetch: reduceActions(
                    setForInvestmentsListViewTypeAtRoute,
                    redirectWithoutFirstPage,
                    fetchFaqListAtRoute,
                    parseInvestmentOfferUrl(rpAppLink.investmentOffer.investmentCategory.base),
                    fetchInvestmentOfferListAtRoute,
                    fetchInvestmentOfferStatsAtRoute,
                    updateInvestmentOfferListMetaDataAtRoute,
                    resetClientOnlySearchFormAtRoute,
                    fetchInvestmentOfferListAbroadVoivodeshipAtRoute
                ),
                exact: false
            },
            {
                path: rpAppPath.oauth.redirect,
                fetch: redirectAfterOauthLoginAtRoute
            },
            {
                path: rpAppPath.favouriteList,
                fetch: reduceActions(setMyListViewTypeAtRoute)
            },
            {
                path: rpAppPath.comparisonTool,
                fetch: reduceActions(setComparisonToolViewTypeAtRoute)
            },
            {path: rpAppPath.permalinks.article, fetch: redirectArticlePermalink},
            {path: rpAppPath.permalinks.offer, fetch: redirectOfferPermalink},
            {path: rpAppPath.permalinks.openDay, fetch: redirectOpendayPermalink},
            {path: rpAppPath.permalinks.property, fetch: redirectPropertyPermalink},
            {path: rpAppPath.permalinks.vendor, fetch: redirectVendorPermalink},
            {path: rpAppPath.buylando, fetch: reduceActions(setBuylandoLandingViewTypeAtRoute, updateBuylandoMetaData)},
            // promotion list
            {
                path: rpAppPath.promotions.list,
                fetch: reduceActions(
                    setPromotionViewTypeAtRoute,
                    conditionallyRedirectToFriendlyPromotionUrlAtRoute,
                    fetchPromotionListAtRoute,
                    updatePromotionListMetaDataAtRoute,
                    fetchFaqListAtRoute
                )
            },
            {
                path: rpAppPath.promotions.friendlyPromotionList,
                fetch: reduceActions(
                    setPromotionViewTypeAtRoute,
                    parseFriendlyPromotionUrlAtRoute,
                    validateFriendlyPromotionUrlAtRoute,
                    mapActions(fetchPromotionListAtRoute, resetClientOnlyPromotionSearchFormAtRoute),
                    updateFriendlyPromotionListMetaDataAtRoute,
                    fetchFaqListAtRoute
                )
            },
            // siteMap
            {
                path: rpAppPath.siteMap.base,
                fetch: updateSiteMapMetaData
            },
            {
                path: rpAppPath.siteMap.article.base,
                fetch: reduceActions(fetchSiteMapArticleCategories, updateSiteMapArticleCategoriesMetaData)
            },
            {
                path: rpAppPath.siteMap.article.category,
                fetch: reduceActions(fetchSiteMapArticleCategories, fetchSiteMapArticleList, updateSiteMapArticleListMetaData)
            },
            {
                path: rpAppPath.siteMap.vendor,
                fetch: reduceActions(fetchSiteMapVendorList, updateSiteMapVendorListMetaData)
            },
            {
                path: rpAppPath.siteMap.vendor,
                exact: false,
                fetch: redirectToSiteMapVendors
            },
            {
                path: rpAppPath.siteMap.offer.base,
                fetch: reduceActions(fetchSiteMapRegionVoivodeshipList, updateSiteMapOfferVoivodeshipListMetaData)
            },
            {
                path: rpAppPath.siteMap.offer.voivodeship,
                fetch: reduceActions(fetchSiteMapRegionVoivodeshipList, fetchSiteMapOfferList, updateSiteMapOfferVoivodeshipMetaData)
            },
            {
                path: [rpAppPath.siteMap.flat, rpAppPath.siteMap.house, rpAppPath.siteMap.commercial],
                fetch: reduceActions(
                    mapActions(fetchSiteMapRegionVoivodeshipList, fetchSiteMapRegionAggregationList, fetchSiteMapRegionCountyList, fetchSiteMapRegionCityList),
                    updateSiteMapOfferTypeMetaData
                )
            },
            {
                path: rpAppPath.siteMap.flat,
                exact: false,
                fetch: redirectToSiteMapFlats
            },
            {
                path: rpAppPath.siteMap.house,
                exact: false,
                fetch: redirectToSiteMapHouses
            },
            {
                path: rpAppPath.siteMap.commercial,
                exact: false,
                fetch: redirectToSiteMapCommercials
            },
            {
                path: rpAppPath.siteMap.base,
                exact: false,
                fetch: redirectToSiteMap
            },
            // newsletter
            {
                path: rpAppPath.newsletter.mailing.unsubscribe,
                fetch: reduceActions(fetchUserUnsubscriptionDataAtRoute, updateNewsletterUnsubscribeMetaData)
            },
            {
                path: rpAppPath.newsletter.sms.unsubscribe,
                fetch: unsubscribeUserFromSmsAtRoute
            },
            // price report
            {
                path: rpAppPath.prices.flats.base,
                fetch: reduceActions(fetchFaqListAtRoute, updatePricesFlatMetaData, fetchAllCitiesPrices, fetchAllCitiesPricesOld, fetchPricesArticleList)
            },
            {
                path: rpAppPath.prices.flats.city,
                fetch: reduceActions(
                    validatePriceReportRegion,
                    fetchPriceReportRegion,
                    fetchFaqListAtRoute,
                    fetchReportPriceAveragePrices,
                    fetchReportPriceYearAgoAveragePrices,
                    updatePricesFlatMetaData
                )
            },
            // property configurator
            {
                path: rpAppPath.propertyConfigurator,
                fetch: reduceActions(updatePropertyConfiguratorMetaData)
            },
            // credits
            {
                path: rpAppPath.credits.base,
                fetch: reduceActions(mapActions(fetchArticleListByIdsAtRoute, fetchFaqListAtRoute), updateCreditLandingMeta, setCreditsLandingViewTypeAtRoute)
            },
            {
                path: rpAppPath.credits.offers,
                fetch: reduceActions(updateCreditOffersMeta, setCreditsOfferLandingViewTypeAtRoute, fetchFaqListAtRoute)
            },
            {
                path: rpAppPath.credits.rating,
                fetch: reduceActions(
                    mapActions(fetchArticleListByIdsAtRoute, fetchFaqListAtRoute),
                    updateCreditRatingOffersMeta,
                    setCreditsOfferRatingViewTypeAtRoute
                )
            },
            // application generator
            {
                path: rpAppPath.redirect.applicationLeadGenerator,
                fetch: generateApplicationAtRoute
            },
            // LP for contractors
            {
                path: rpAppPath.contractor.base,
                fetch: updateContractorMeta
            }
        ]
    }
];
export const rpDataFetcher = createDataFetcher<IRPRequestMeta, IRPStore>(RP_FETCH_ROUTES);
