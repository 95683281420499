import {upperFirst} from "@pg-mono/nodash";
import {areaFormat, floorFormat, priceFormat, roomsFormat} from "@pg-mono/string-utils";

import {PropertyFlatType} from "../../offer/detail/constants/PropertyFlatType";
import {OfferType} from "../../offer/types/OfferType";
import {getPropertyFlatMetaType} from "../utils/get_property_flat_meta_type";

export interface IDescriptionPropertyDetail {
    offerType: OfferType;
    flatType: PropertyFlatType | null;
    rooms: number;
    area: number;
    price?: number | null;
    regionName: string;
    streetName: string;
    offerName: string;
    floor: number | null;
}

export const descriptionPropertyDetail = (props: IDescriptionPropertyDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = props.flatType ? getPropertyFlatMetaType(props.flatType) : "mieszkanie";
            break;
        case OfferType.HOUSE:
            type = "dom";
            break;
        case OfferType.COMMERCIAL:
            type = "lokal użytkowy";
            break;
        default:
            break;
    }

    const rooms = getRoomsName(props.offerType, props.flatType, props.rooms);
    const area = areaFormat(props.area);
    const price = props.price ? `w cenie ${priceFormat(props.price)}` : "";
    const floor = typeof props.floor === "number" ? floorFormat(props.floor) : "";

    return `${upperFirst(type)} ${rooms} na sprzedaż ${props.regionName}, ${props.streetName} -${floor && ` ${floor},`} ${area}${
        price && `, ${price}`
    }. Sprawdź!👍`;
};

const getRoomsName = (offerType: OfferType, flatType: PropertyFlatType | null, rooms: number) => {
    if (rooms > 4) {
        return roomsFormat(rooms); // 5 pokoi...
    }

    const roomsE = {
        1: "jednopokojowe",
        2: "dwupokojowe",
        3: "trzypokojowe",
        4: "czteropokojowe"
    } as Record<number, string>;

    const roomsY = {
        1: "jednopokojowy",
        2: "dwupokojowy",
        3: "trzypokojowy",
        4: "czteropokojowy"
    } as Record<number, string>;

    // only 'mieszkanie jednopokojowe', rest is '... jednopokojowy'
    return offerType === OfferType.FLAT && flatType == PropertyFlatType.STANDARD ? roomsE[rooms] : roomsY[rooms];
};
import {upperFirst} from "@pg-mono/nodash";
import {areaFormat, floorFormat, priceFormat, roomsFormat} from "@pg-mono/string-utils";

import {PropertyFlatType} from "../../offer/detail/constants/PropertyFlatType";
import {OfferType} from "../../offer/types/OfferType";
import {getPropertyFlatMetaType} from "../utils/get_property_flat_meta_type";

export interface IDescriptionPropertyDetail {
    offerType: OfferType;
    flatType: PropertyFlatType | null;
    rooms: number;
    area: number;
    price?: number | null;
    regionName: string;
    streetName: string;
    offerName: string;
    floor: number | null;
}

export const descriptionPropertyDetail = (props: IDescriptionPropertyDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = props.flatType ? getPropertyFlatMetaType(props.flatType) : "mieszkanie";
            break;
        case OfferType.HOUSE:
            type = "dom";
            break;
        case OfferType.COMMERCIAL:
            type = "lokal użytkowy";
            break;
        default:
            break;
    }

    const rooms = getRoomsName(props.offerType, props.flatType, props.rooms);
    const area = areaFormat(props.area);
    const price = props.price ? `w cenie ${priceFormat(props.price)}` : "";
    const floor = typeof props.floor === "number" ? floorFormat(props.floor) : "";

    return `${upperFirst(type)} ${rooms} na sprzedaż ${props.regionName}, ${props.streetName} -${floor && ` ${floor},`} ${area}${
        price && `, ${price}`
    }. Sprawdź!👍`;
};

const getRoomsName = (offerType: OfferType, flatType: PropertyFlatType | null, rooms: number) => {
    if (rooms > 4) {
        return roomsFormat(rooms); // 5 pokoi...
    }

    const roomsE = {
        1: "jednopokojowe",
        2: "dwupokojowe",
        3: "trzypokojowe",
        4: "czteropokojowe"
    } as Record<number, string>;

    const roomsY = {
        1: "jednopokojowy",
        2: "dwupokojowy",
        3: "trzypokojowy",
        4: "czteropokojowy"
    } as Record<number, string>;

    // only 'mieszkanie jednopokojowe', rest is '... jednopokojowy'
    return offerType === OfferType.FLAT && flatType == PropertyFlatType.STANDARD ? roomsE[rooms] : roomsY[rooms];
};
