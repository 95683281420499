import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAuthorsListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = `Dziennikarze wiadomości mieszkaniowych - ${BRAND_NAME}`;
    const description = `Autorzy artykułów ✍ na Wiadomościach Mieszkaniowych ${concatWithBrandName(
        "portalu"
    )} to dziennikarze, pasjonaci i eksperci. Zapoznaj się z ich profilami i artykułami.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return Promise.resolve();
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateAuthorsListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = `Dziennikarze wiadomości mieszkaniowych - ${BRAND_NAME}`;
    const description = `Autorzy artykułów ✍ na Wiadomościach Mieszkaniowych ${concatWithBrandName(
        "portalu"
    )} to dziennikarze, pasjonaci i eksperci. Zapoznaj się z ich profilami i artykułami.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return Promise.resolve();
};
