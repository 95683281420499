import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {FacebookIcon, InstagramIcon, LinkedinIcon, XIcon, YouTubeIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {FOOTER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {FooterContactBox} from "./FooterContactBox";
import {FooterMenuElement, IFooterMenuElement} from "./FooterMenuElement";
import {FooterPanelExpandable} from "./FooterPanelExpandable";
import {IFooterTheme} from "./IFooterTheme";

const logoRp = require("./Logo-DefaultTextLite.svg");

export interface IProps {
    footerMenuData: IFooterMenuData;
    panelArrowDown: JSX.Element;
    hide?: boolean;
    publisherInfo?: string;
    contactInfoLines?: string[];
    className?: string;
    logoSrc?: string;
}

export interface IFooterMenuData {
    various: IFooterMenuElement[];
    cityOffers: IFooterMenuElement[];
    promotions: IFooterMenuElement[];
    info: IFooterMenuElement[];
    cooperation: IFooterMenuElement[];
    topArticles?: IFooterMenuElement[];
}

const panelTitles = {
    offers: "Oferty",
    promotions: "Promocje",
    info: "Informacje",
    cooperation: "Współpraca",
    topArticles: "Top artykuły"
};

const publisherInfo = "Wydawcą portalu rynekpierwotny.pl jest PropertyGroup Sp. z o.o.";

const contactInfoLines = ["Tel.: +48 22 825 60 71", "Kontakt w dni robocze: 9:00 – 17:00", "E-mail: biuro@rynekpierwotny.pl"];

export const Footer: React.FC<IProps> = (props) => {
    if (props.hide) {
        return null;
    }

    const socialIcons = [
        {
            icon: <FacebookIcon fill="#fff" size="3" />,
            url: "https://www.facebook.com/rynekpierwotnypl/"
        },
        {
            icon: <InstagramIcon fill="#fff" size="3" />,
            url: "https://www.instagram.com/rynekpierwotny/"
        },
        {
            icon: <LinkedinIcon fill="#fff" size="3" />,
            url: "https://www.linkedin.com/company/rynekpierwotny-com/"
        },
        {
            icon: <YouTubeIcon fill="#fff" size="3" />,
            url: "https://www.youtube.com/channel/UCjJn84caCWMP5h-EE5BBv8w/"
        },
        {
            icon: <XIcon fill="#23232D" wrapperColor="#fff" wrapperSize="2.6" size="2.6" />,
            url: "https://x.com/rynekpierwotny"
        }
    ];

    return (
        <FooterStyled className={props.className}>
            <FooterPanelsWrap>
                <FooterColumn>
                    <img css={logoWrapper} height="60px" width="196px" src={props.logoSrc || logoRp} alt="logo" />
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.offers}>
                        <FooterPanelList>
                            {props.footerMenuData.various.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                            {props.footerMenuData.cityOffers.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.promotions}>
                        <FooterPanelList wide>
                            {props.footerMenuData.promotions.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>

                    <FooterPanelExpandable css={panelMarginMid} title={panelTitles.info}>
                        <FooterPanelList wide>
                            {props.footerMenuData.info.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.cooperation}>
                        <FooterPanelList wide>
                            {props.footerMenuData.cooperation.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                    {props.footerMenuData.topArticles && props.footerMenuData.topArticles?.length > 0 && (
                        <FooterPanelExpandable css={panelMarginMid} title={panelTitles.topArticles}>
                            <FooterPanelList wide>
                                {props.footerMenuData.topArticles.map((element) => (
                                    <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                                ))}
                            </FooterPanelList>
                        </FooterPanelExpandable>
                    )}

                    <FooterContactBox lines={props.contactInfoLines || contactInfoLines} css={contactBoxStyle} />

                    <SocialIconsHolder>
                        {socialIcons.map((socialIcon) => (
                            <SocialIcon
                                key={socialIcon.url}
                                href={socialIcon.url}
                                target="_blank"
                                rel="nofollow"
                                data-testid={FOOTER_TEST_IDS.FOOTER_SOCIAL_ICON}
                            >
                                {socialIcon.icon}
                            </SocialIcon>
                        ))}
                    </SocialIconsHolder>
                </FooterColumn>
            </FooterPanelsWrap>

            <div css={footerCopyrightHolder}>
                <FooterCopyright variant="info_txt_1">{props.publisherInfo ? props.publisherInfo : publisherInfo}</FooterCopyright>
            </div>
        </FooterStyled>
    );
};

interface IThemeProps {
    theme?: Theme & IFooterTheme;
    wide?: boolean;
}

const FooterStyled = styled.footer<IThemeProps>`
    width: 100%;
    background: ${(props) => props.theme?.colors?.secondary ?? "#23232d"};
    padding: 3.2rem 0;
    
    @media print {
        display: none; !important;
    }
`;

const logoWrapper = (theme: Theme & IFooterTheme) => css`
    height: 40px;
    width: 130px;

    margin-bottom: 4rem;

    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        height: 60px;
        width: 196px;
    }
`;

const FooterPanelsWrap = styled.div<IThemeProps>`
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const panelMarginMid = (theme: Theme) => css`
    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        margin-top: 5.4rem;
`;

const FooterColumn = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
    }
`;

const FooterPanelList = styled.ul<IThemeProps>`
    margin: 0;
    padding: 0;
`;

const footerCopyrightHolder = (theme: Theme & IFooterTheme) => css`
    display: flex;
    justify-content: left;

    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        padding: 1.2rem;
        justify-content: center;
    }
`;

const FooterCopyright = styled(Text)<IThemeProps>`
    margin: 0 auto;
    flex-grow: 1;
    padding: 2rem 0;
    color: ${(props) => props.theme?.colors?.gray?.["700"] ?? "#ccc"};

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        flex-grow: 0;
        justify-content: flex-end;

        color: ${(props) => props.theme?.colors?.gray?.["700"] ?? "#7b7b81"};
    }
`;

const SocialIconsHolder = styled.div<IThemeProps>`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    padding: 1.6rem 0 0 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        flex-grow: 0;
        padding: 1.6rem 0 0 0;
        margin-left: -0.4rem;
    }
`;

const contactBoxStyle = css`
    margin-top: 3.2rem;
`;

const SocialIcon = styled.a<IThemeProps>`
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.6rem 0 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        margin-bottom: 0;
    }
`;
import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {FacebookIcon, InstagramIcon, LinkedinIcon, XIcon, YouTubeIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";
import {FOOTER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {FooterContactBox} from "./FooterContactBox";
import {FooterMenuElement, IFooterMenuElement} from "./FooterMenuElement";
import {FooterPanelExpandable} from "./FooterPanelExpandable";
import {IFooterTheme} from "./IFooterTheme";

const logoRp = require("./Logo-DefaultTextLite.svg");

export interface IProps {
    footerMenuData: IFooterMenuData;
    panelArrowDown: JSX.Element;
    hide?: boolean;
    publisherInfo?: string;
    contactInfoLines?: string[];
    className?: string;
    logoSrc?: string;
}

export interface IFooterMenuData {
    various: IFooterMenuElement[];
    cityOffers: IFooterMenuElement[];
    promotions: IFooterMenuElement[];
    info: IFooterMenuElement[];
    cooperation: IFooterMenuElement[];
    topArticles?: IFooterMenuElement[];
}

const panelTitles = {
    offers: "Oferty",
    promotions: "Promocje",
    info: "Informacje",
    cooperation: "Współpraca",
    topArticles: "Top artykuły"
};

const publisherInfo = "Wydawcą portalu rynekpierwotny.pl jest PropertyGroup Sp. z o.o.";

const contactInfoLines = ["Tel.: +48 22 825 60 71", "Kontakt w dni robocze: 9:00 – 17:00", "E-mail: biuro@rynekpierwotny.pl"];

export const Footer: React.FC<IProps> = (props) => {
    if (props.hide) {
        return null;
    }

    const socialIcons = [
        {
            icon: <FacebookIcon fill="#fff" size="3" />,
            url: "https://www.facebook.com/rynekpierwotnypl/"
        },
        {
            icon: <InstagramIcon fill="#fff" size="3" />,
            url: "https://www.instagram.com/rynekpierwotny/"
        },
        {
            icon: <LinkedinIcon fill="#fff" size="3" />,
            url: "https://www.linkedin.com/company/rynekpierwotny-com/"
        },
        {
            icon: <YouTubeIcon fill="#fff" size="3" />,
            url: "https://www.youtube.com/channel/UCjJn84caCWMP5h-EE5BBv8w/"
        },
        {
            icon: <XIcon fill="#23232D" wrapperColor="#fff" wrapperSize="2.6" size="2.6" />,
            url: "https://x.com/rynekpierwotny"
        }
    ];

    return (
        <FooterStyled className={props.className}>
            <FooterPanelsWrap>
                <FooterColumn>
                    <img css={logoWrapper} height="60px" width="196px" src={props.logoSrc || logoRp} alt="logo" />
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.offers}>
                        <FooterPanelList>
                            {props.footerMenuData.various.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                            {props.footerMenuData.cityOffers.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.promotions}>
                        <FooterPanelList wide>
                            {props.footerMenuData.promotions.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>

                    <FooterPanelExpandable css={panelMarginMid} title={panelTitles.info}>
                        <FooterPanelList wide>
                            {props.footerMenuData.info.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                </FooterColumn>

                <FooterColumn>
                    <FooterPanelExpandable title={panelTitles.cooperation}>
                        <FooterPanelList wide>
                            {props.footerMenuData.cooperation.map((element) => (
                                <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                            ))}
                        </FooterPanelList>
                    </FooterPanelExpandable>
                    {props.footerMenuData.topArticles && props.footerMenuData.topArticles?.length > 0 && (
                        <FooterPanelExpandable css={panelMarginMid} title={panelTitles.topArticles}>
                            <FooterPanelList wide>
                                {props.footerMenuData.topArticles.map((element) => (
                                    <FooterMenuElement key={element.title.toLowerCase()} {...element} />
                                ))}
                            </FooterPanelList>
                        </FooterPanelExpandable>
                    )}

                    <FooterContactBox lines={props.contactInfoLines || contactInfoLines} css={contactBoxStyle} />

                    <SocialIconsHolder>
                        {socialIcons.map((socialIcon) => (
                            <SocialIcon
                                key={socialIcon.url}
                                href={socialIcon.url}
                                target="_blank"
                                rel="nofollow"
                                data-testid={FOOTER_TEST_IDS.FOOTER_SOCIAL_ICON}
                            >
                                {socialIcon.icon}
                            </SocialIcon>
                        ))}
                    </SocialIconsHolder>
                </FooterColumn>
            </FooterPanelsWrap>

            <div css={footerCopyrightHolder}>
                <FooterCopyright variant="info_txt_1">{props.publisherInfo ? props.publisherInfo : publisherInfo}</FooterCopyright>
            </div>
        </FooterStyled>
    );
};

interface IThemeProps {
    theme?: Theme & IFooterTheme;
    wide?: boolean;
}

const FooterStyled = styled.footer<IThemeProps>`
    width: 100%;
    background: ${(props) => props.theme?.colors?.secondary ?? "#23232d"};
    padding: 3.2rem 0;
    
    @media print {
        display: none; !important;
    }
`;

const logoWrapper = (theme: Theme & IFooterTheme) => css`
    height: 40px;
    width: 130px;

    margin-bottom: 4rem;

    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        height: 60px;
        width: 196px;
    }
`;

const FooterPanelsWrap = styled.div<IThemeProps>`
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const panelMarginMid = (theme: Theme) => css`
    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        margin-top: 5.4rem;
`;

const FooterColumn = styled.div<IThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
    }
`;

const FooterPanelList = styled.ul<IThemeProps>`
    margin: 0;
    padding: 0;
`;

const footerCopyrightHolder = (theme: Theme & IFooterTheme) => css`
    display: flex;
    justify-content: left;

    @media (min-width: ${theme?.breakpoints?.md ?? "1024px"}) {
        padding: 1.2rem;
        justify-content: center;
    }
`;

const FooterCopyright = styled(Text)<IThemeProps>`
    margin: 0 auto;
    flex-grow: 1;
    padding: 2rem 0;
    color: ${(props) => props.theme?.colors?.gray?.["700"] ?? "#ccc"};

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        flex-grow: 0;
        justify-content: flex-end;

        color: ${(props) => props.theme?.colors?.gray?.["700"] ?? "#7b7b81"};
    }
`;

const SocialIconsHolder = styled.div<IThemeProps>`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    padding: 1.6rem 0 0 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        flex-grow: 0;
        padding: 1.6rem 0 0 0;
        margin-left: -0.4rem;
    }
`;

const contactBoxStyle = css`
    margin-top: 3.2rem;
`;

const SocialIcon = styled.a<IThemeProps>`
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.6rem 0 0;

    @media (min-width: ${(props) => props.theme?.breakpoints?.md ?? "1024px"}) {
        margin-bottom: 0;
    }
`;
