import {ITrackedSiteData} from "../../types/ITrackedSiteData";

export const getTrackedSiteData = (): ITrackedSiteData => {
    const isMobile = window.innerWidth < 1024;
    return {site: isMobile ? "Rynek Pierwotny Mobile" : "Rynek Pierwotny", site_id: isMobile ? 33 : 1};
};
import {ITrackedSiteData} from "../../types/ITrackedSiteData";

export const getTrackedSiteData = (): ITrackedSiteData => {
    const isMobile = window.innerWidth < 1024;
    return {site: isMobile ? "Rynek Pierwotny Mobile" : "Rynek Pierwotny", site_id: isMobile ? 33 : 1};
};
