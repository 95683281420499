import {useEffect, useState} from "react";

import {HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED} from "../../app/constants/storage_keys";
import {sessionCache} from "../../utils/session_cache";
import {useUserRecommendationState} from "./use_user_recommendation_state";

const POPOVER_ACTIVATION_DELAY = 5000;

export const useUserRecommendationPopover = () => {
    const [hasPopoverBeenClosed, setHasPopoverBeenClosed] = useState<boolean>(sessionCache.get<boolean>(HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED) || false);
    const [isPopoverActive, setIsPopoverActive] = useState(false);
    const {recommendedCount} = useUserRecommendationState();

    useEffect(() => {
        if (recommendedCount > 0 && !hasPopoverBeenClosed) {
            const timeoutId = setTimeout(() => {
                setIsPopoverActive(true);
            }, POPOVER_ACTIVATION_DELAY);

            return () => clearTimeout(timeoutId);
        }
    }, [recommendedCount]);

    const onPopoverClose = () => {
        setIsPopoverActive(false);
        setHasPopoverBeenClosed(true);
        sessionCache.set(HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED, true);
    };

    return {
        recommendedCount,
        isPopoverActive,
        onPopoverClose
    };
};
import {useEffect, useState} from "react";

import {HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED} from "../../app/constants/storage_keys";
import {sessionCache} from "../../utils/session_cache";
import {useUserRecommendationState} from "./use_user_recommendation_state";

const POPOVER_ACTIVATION_DELAY = 5000;

export const useUserRecommendationPopover = () => {
    const [hasPopoverBeenClosed, setHasPopoverBeenClosed] = useState<boolean>(sessionCache.get<boolean>(HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED) || false);
    const [isPopoverActive, setIsPopoverActive] = useState(false);
    const {recommendedCount} = useUserRecommendationState();

    useEffect(() => {
        if (recommendedCount > 0 && !hasPopoverBeenClosed) {
            const timeoutId = setTimeout(() => {
                setIsPopoverActive(true);
            }, POPOVER_ACTIVATION_DELAY);

            return () => clearTimeout(timeoutId);
        }
    }, [recommendedCount]);

    const onPopoverClose = () => {
        setIsPopoverActive(false);
        setHasPopoverBeenClosed(true);
        sessionCache.set(HAS_RECOMMENDATION_POPOVER_BEEN_CLOSED, true);
    };

    return {
        recommendedCount,
        isPopoverActive,
        onPopoverClose
    };
};
