import {PromotionType} from "../../promotion/helpers/PromotionType";
import {Country} from "../../region/types/Country";
import {ApplicationSource} from "./ApplicationSource";

interface IProps {
    source: ApplicationSource;
    offerName?: string | null;
    propertyNumber?: string | null;
    promotionType?: PromotionType;
    customText?: string | null | void;
    propertyPrice?: number | null;
    country?: Country;
}

export const generateApplicationText = (props: IProps) => {
    let text = "";

    // For now, for abroad offers return simple text without additional logic.
    if (props.country !== Country.POLAND) {
        if (!props.offerName) {
            return `Dzień dobry,\nZainteresowała mnie Państwa oferta nieruchomości. Proszę o kontakt.`;
        }

        if (props.propertyNumber) {
            return `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o kontakt.`;
        }

        return `Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji ${props.offerName}. Proszę o kontakt.`;
    }

    if (props.source === ApplicationSource.Vendor) {
        text = `Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji. Proszę o kontakt.`; // 1
    } else if (props.propertyNumber) {
        switch (props.source) {
            case ApplicationSource.Property: // 3
            case ApplicationSource.QuickViewListingRecommendation: // 37
            case ApplicationSource.ChatBot: // 40
            case ApplicationSource.RecommendedProperty: // 33
                text = `Proszę o pilny kontakt i ewentualne zaproponowanie terminu spotkania. Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. `;
                break;
            case ApplicationSource.NegotiatePrice: // 4
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ` + `Na jaki rabat mogę liczyć?`;
                break;
            case ApplicationSource.PriceInquiry: // 5
            case ApplicationSource.OfferRevealPrice: // 19
            case ApplicationSource.PropertyRevealPrice: // 20
            case ApplicationSource.PropertyAutoresponderRevealedPrices: // 32
            case ApplicationSource.QuickViewPropertyModal:
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ${
                    props.propertyPrice ? "Proszę o kontakt i przesłanie pełnej oferty." : "Proszę o podanie ceny."
                }`;
                break;
            case ApplicationSource.Reservation: // 13
                text =
                    `Zainteresowała mnie zarezerwowana nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ` +
                    `Proszę o niezobowiązujący kontakt w przypadku anulowania rezerwacji na tę ofertę.`;
                break;
            case ApplicationSource.RevealedPrices: // 24
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o podanie ceny.`;
                break;
            default:
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o podanie ceny.`;
                break;
        }
    } else if (props.offerName) {
        switch (props.source) {
            case ApplicationSource.Offer:
            case ApplicationSource.LandingPage:
            case ApplicationSource.OfferPriceInquiry:
            case ApplicationSource.OfferChatBot:
            case ApplicationSource.RecommendedOffer:
                text =
                    `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. ` +
                    `Proszę o niezobowiązujący kontakt w sprawie szczegółów oferty.`;
                break;
            case ApplicationSource.OfferAutoresponderRevealedPrices:
                text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. ` + `Proszę o podanie informacji o cenach.`;
                break;
            default:
                break;
        }
    }

    if (props.source === ApplicationSource.InvestmentInquiry) {
        // 26 - Renters
        text = `Jestem zainteresowany inwestowaniem w nieruchomości. Proszę o kontakt.`;
    }

    if (props.offerName && props.source === ApplicationSource.ApplicationOfferScheduleMeeting) {
        //17
        text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. Proszę o zaproponowanie najbliższego terminu spotkania w biurze sprzedaży.`;
    }

    if (props.offerName && [ApplicationSource.Promotion, ApplicationSource.PromotionProperty].includes(props.source)) {
        switch (props.promotionType) {
            case PromotionType.OFFER: // 1
                text = `Jestem zainteresowany promocją w ${props.offerName}. Proszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.OPENDAYS: // 2
                text = `Jestem zainteresowany Dniami Otwartymi w ${props.offerName}. Poproszę o rezerwację miejsca w czasie tego wydarzenia.`;
                break;
            case PromotionType.DISCOUNT: // 3
                text = `Jestem zainteresowany przecenionymi nieruchomościami w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.LASTMINUTE: // 4
                text = `Jestem zainteresowany ofertami last minute w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.PRESALE: // 5
                text = `Jestem zainteresowany ofertą przedsprzedaży w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            default:
                text = `Proszę o więcej informacji na temat aktualnej promocji dla inwestycji ${props.offerName}.`;
        }
    }

    return text + ` ${props.customText ? props.customText : ""}`;
};
import {PromotionType} from "../../promotion/helpers/PromotionType";
import {Country} from "../../region/types/Country";
import {ApplicationSource} from "./ApplicationSource";

interface IProps {
    source: ApplicationSource;
    offerName?: string | null;
    propertyNumber?: string | null;
    promotionType?: PromotionType;
    customText?: string | null | void;
    propertyPrice?: number | null;
    country?: Country;
}

export const generateApplicationText = (props: IProps) => {
    let text = "";

    // For now, for abroad offers return simple text without additional logic.
    if (props.country !== Country.POLAND) {
        if (!props.offerName) {
            return `Dzień dobry,\nZainteresowała mnie Państwa oferta nieruchomości. Proszę o kontakt.`;
        }

        if (props.propertyNumber) {
            return `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o kontakt.`;
        }

        return `Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji ${props.offerName}. Proszę o kontakt.`;
    }

    if (props.source === ApplicationSource.Vendor) {
        text = `Jestem zainteresowany/a zakupem nieruchomości w Państwa inwestycji. Proszę o kontakt.`; // 1
    } else if (props.propertyNumber) {
        switch (props.source) {
            case ApplicationSource.Property: // 3
            case ApplicationSource.QuickViewListingRecommendation: // 37
            case ApplicationSource.ChatBot: // 40
            case ApplicationSource.RecommendedProperty: // 33
                text = `Proszę o pilny kontakt i ewentualne zaproponowanie terminu spotkania. Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. `;
                break;
            case ApplicationSource.NegotiatePrice: // 4
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ` + `Na jaki rabat mogę liczyć?`;
                break;
            case ApplicationSource.PriceInquiry: // 5
            case ApplicationSource.OfferRevealPrice: // 19
            case ApplicationSource.PropertyRevealPrice: // 20
            case ApplicationSource.PropertyAutoresponderRevealedPrices: // 32
            case ApplicationSource.QuickViewPropertyModal:
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ${
                    props.propertyPrice ? "Proszę o kontakt i przesłanie pełnej oferty." : "Proszę o podanie ceny."
                }`;
                break;
            case ApplicationSource.Reservation: // 13
                text =
                    `Zainteresowała mnie zarezerwowana nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. ` +
                    `Proszę o niezobowiązujący kontakt w przypadku anulowania rezerwacji na tę ofertę.`;
                break;
            case ApplicationSource.RevealedPrices: // 24
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o podanie ceny.`;
                break;
            default:
                text = `Zainteresowała mnie nieruchomość ${props.propertyNumber} w Państwa inwestycji: ${props.offerName}. Proszę o podanie ceny.`;
                break;
        }
    } else if (props.offerName) {
        switch (props.source) {
            case ApplicationSource.Offer:
            case ApplicationSource.LandingPage:
            case ApplicationSource.OfferPriceInquiry:
            case ApplicationSource.OfferChatBot:
            case ApplicationSource.RecommendedOffer:
                text =
                    `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. ` +
                    `Proszę o niezobowiązujący kontakt w sprawie szczegółów oferty.`;
                break;
            case ApplicationSource.OfferAutoresponderRevealedPrices:
                text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. ` + `Proszę o podanie informacji o cenach.`;
                break;
            default:
                break;
        }
    }

    if (props.source === ApplicationSource.InvestmentInquiry) {
        // 26 - Renters
        text = `Jestem zainteresowany inwestowaniem w nieruchomości. Proszę o kontakt.`;
    }

    if (props.offerName && props.source === ApplicationSource.ApplicationOfferScheduleMeeting) {
        //17
        text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offerName}. Proszę o zaproponowanie najbliższego terminu spotkania w biurze sprzedaży.`;
    }

    if (props.offerName && [ApplicationSource.Promotion, ApplicationSource.PromotionProperty].includes(props.source)) {
        switch (props.promotionType) {
            case PromotionType.OFFER: // 1
                text = `Jestem zainteresowany promocją w ${props.offerName}. Proszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.OPENDAYS: // 2
                text = `Jestem zainteresowany Dniami Otwartymi w ${props.offerName}. Poproszę o rezerwację miejsca w czasie tego wydarzenia.`;
                break;
            case PromotionType.DISCOUNT: // 3
                text = `Jestem zainteresowany przecenionymi nieruchomościami w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.LASTMINUTE: // 4
                text = `Jestem zainteresowany ofertami last minute w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            case PromotionType.PRESALE: // 5
                text = `Jestem zainteresowany ofertą przedsprzedaży w ${props.offerName}. Poproszę o kontakt w tej sprawie.`;
                break;
            default:
                text = `Proszę o więcej informacji na temat aktualnej promocji dla inwestycji ${props.offerName}.`;
        }
    }

    return text + ` ${props.customText ? props.customText : ""}`;
};
