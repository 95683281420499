import {catch404, getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {ISelectedRegion} from "../../offer/list/actions/offer_list_selected_data_actions";

export const fetchSearchRegionById = (regionId: string): Promise<ISelectedRegion> => {
    const regionApiLink = apiV2Link.region.detail(Scenario.SEARCH_REGION_DETAIL, {regionId: parseInt(regionId, 10)});

    return getRequest({}, regionApiLink).catch(catch404(() => null));
};
import {catch404, getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {ISelectedRegion} from "../../offer/list/actions/offer_list_selected_data_actions";

export const fetchSearchRegionById = (regionId: string): Promise<ISelectedRegion> => {
    const regionApiLink = apiV2Link.region.detail(Scenario.SEARCH_REGION_DETAIL, {regionId: parseInt(regionId, 10)});

    return getRequest({}, regionApiLink).catch(catch404(() => null));
};
