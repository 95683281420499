import {useDispatch} from "react-redux";

import {INotification, Notifications} from "@pg-design/notifications";

import {removeNotification} from "../state/notifications_slice";

interface IProps {
    notifications: INotification[];
}

export const NotificationsReduxContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const removeNotificationCallback = (id: string) => dispatch(removeNotification(id));

    return <Notifications notifications={props.notifications} removeNotification={removeNotificationCallback} />;
};
import {useDispatch} from "react-redux";

import {INotification, Notifications} from "@pg-design/notifications";

import {removeNotification} from "../state/notifications_slice";

interface IProps {
    notifications: INotification[];
}

export const NotificationsReduxContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const removeNotificationCallback = (id: string) => dispatch(removeNotification(id));

    return <Notifications notifications={props.notifications} removeNotification={removeNotificationCallback} />;
};
