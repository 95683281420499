import {OfferDisplayType} from "../helpers/OfferDisplayType";

export const offerDetailPropertiesListConstraints = {
    limited_presentation: false,
    for_sale: true,
    display_type: OfferDisplayType.FOR_SALE
};
import {OfferDisplayType} from "../helpers/OfferDisplayType";

export const offerDetailPropertiesListConstraints = {
    limited_presentation: false,
    for_sale: true,
    display_type: OfferDisplayType.FOR_SALE
};
