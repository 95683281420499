import {consoleError} from "@pg-mono/logger";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

const cookiesApiLink = apiLink.cookies.base({});

export const fetchCookiesOnServer = (meta: IRPRequestMeta) => {
    return getRequest(meta, cookiesApiLink(null)).catch((error) => {
        const message = "Error in fetchCookiesOnServer";

        consoleError(message, error.message);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

// We can pass UTM params as an `additionalQuery` - it will be used by BE for analytics purposes
export const fetchCookies = (additionalQuery: Record<string, string> = {}) => {
    const url = appendQueryString(cookiesApiLink(null), additionalQuery);

    return getRequest({}, url).catch((error) => {
        const message = "Error in fetchCookies";

        consoleError(message, error);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

export const fetchMpCookiesOnServer = (meta: IRPRequestMeta) => {
    return getRequest(meta, apiLink.marketplace.cookies.base()).catch((error) => {
        const message = "Error in fetchMpCookiesOnServer";

        consoleError(message, error.message);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

export const fetchMpCookies = (additionalQuery: Record<string, string> = {}) => {
    const url = appendQueryString(apiLink.marketplace.cookies.base(), additionalQuery);

    return getRequest({}, url).catch((error) => {
        const message = "Error in fetchMpCookies";

        consoleError(message, error);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};
import {consoleError} from "@pg-mono/logger";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

const cookiesApiLink = apiLink.cookies.base({});

export const fetchCookiesOnServer = (meta: IRPRequestMeta) => {
    return getRequest(meta, cookiesApiLink(null)).catch((error) => {
        const message = "Error in fetchCookiesOnServer";

        consoleError(message, error.message);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

// We can pass UTM params as an `additionalQuery` - it will be used by BE for analytics purposes
export const fetchCookies = (additionalQuery: Record<string, string> = {}) => {
    const url = appendQueryString(cookiesApiLink(null), additionalQuery);

    return getRequest({}, url).catch((error) => {
        const message = "Error in fetchCookies";

        consoleError(message, error);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

export const fetchMpCookiesOnServer = (meta: IRPRequestMeta) => {
    return getRequest(meta, apiLink.marketplace.cookies.base()).catch((error) => {
        const message = "Error in fetchMpCookiesOnServer";

        consoleError(message, error.message);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};

export const fetchMpCookies = (additionalQuery: Record<string, string> = {}) => {
    const url = appendQueryString(apiLink.marketplace.cookies.base(), additionalQuery);

    return getRequest({}, url).catch((error) => {
        const message = "Error in fetchMpCookies";

        consoleError(message, error);
        notifyBugsnag(error, message, {originalError: JSON.stringify(error)});
    });
};
