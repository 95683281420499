import React from "react";

import {IUserDeviceContext} from "../interfaces/IUserDeviceContext";

export const UserDeviceContext = React.createContext<IUserDeviceContext>({isMobile: null, isIOS: null});
import React from "react";

import {IUserDeviceContext} from "../interfaces/IUserDeviceContext";

export const UserDeviceContext = React.createContext<IUserDeviceContext>({isMobile: null, isIOS: null});
