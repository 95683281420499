import {getParsedString} from "./get_parsed_string";

export function getPhoneDataFromString(phone: string) {
    const arr = getParsedString(phone).split(" ");
    const code = parseInt(arr[0], 10);
    const num = arr[1];

    return {
        code: isFinite(code) ? code : 48,
        number: !!num ? num : ""
    };
}
import {getParsedString} from "./get_parsed_string";

export function getPhoneDataFromString(phone: string) {
    const arr = getParsedString(phone).split(" ");
    const code = parseInt(arr[0], 10);
    const num = arr[1];

    return {
        code: isFinite(code) ? code : 48,
        number: !!num ? num : ""
    };
}
