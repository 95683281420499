import type {Dispatch} from "redux";

import {escape} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {OfferMetaRobots} from "../../offer/constants/offer_meta_robots";
import {IStandardMetaData, rpCanonicalUrl, UPDATE_META_DATA, UPDATE_META_TITLE_DESCRIPTION} from "../reducers/meta_data_reducer";
import {setClientMetaData} from "../utils/set_client_meta_data";

export interface IUpdateMetaDataAction extends IStandardMetaData {
    type: typeof UPDATE_META_DATA;
}

export interface IUpdateMetaTitleDescriptionAction extends Pick<IStandardMetaData, "title" | "description"> {
    type: typeof UPDATE_META_TITLE_DESCRIPTION;
}

export type MetaDataDispatch = Dispatch<IUpdateMetaDataAction | IUpdateMetaTitleDescriptionAction>;

export interface IMetaDataOptions {
    title: string;
    description: string;
    pathname: string;
    prev?: string;
    next?: string;
    robots?: string;
    dimensionRegion?: string;
    gtmPage?: string;
    ogImage?: string;
    ogTitle?: string;
    ogType?: string;
    ogUrl?: string;
    ogPublishDate?: string;
    customCanonicalPath?: string;
    pageNumber?: number;
    hreflang?: {language_code: string; slug: string}[];
    lang?: "uk" | "pl";
}

export const updateMetaData = (metaData: IMetaDataOptions) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const customMetaData = getState().metaData.customMetaData;
    const {
        title,
        description,
        pathname,
        prev,
        next,
        pageNumber,
        dimensionRegion,
        robots,
        gtmPage,
        ogImage,
        ogTitle,
        ogType,
        ogPublishDate,
        customCanonicalPath,
        hreflang,
        lang = "pl"
    } = metaData;

    // check for stages in offer detail view, stages that are uncanonical (robots = noindex, flow) should have default title and desc
    const isStagedOfferUncanonical = robots === OfferMetaRobots.NOINDEX_FOLLOW && pathname.includes("oferty");
    const prepareCustomTitle =
        !isStagedOfferUncanonical && customMetaData?.title && `${customMetaData.title}${pageNumber && pageNumber > 1 ? ` - Strona ${pageNumber}` : ""}`;
    const prepareCustomDesc =
        !isStagedOfferUncanonical &&
        customMetaData?.description &&
        `${customMetaData.description}${pageNumber && pageNumber > 1 ? ` - Strona ${pageNumber}` : ""}`;
    const escapeTitle = escape(prepareCustomTitle || title);
    const escapeDescription = escape(prepareCustomDesc || description);
    let escapeCanonical = escape(`${rpCanonicalUrl}${pathname}`);
    if (customCanonicalPath) {
        // priority custom canonical path
        escapeCanonical = escape(`${customCanonicalPath.includes(rpCanonicalUrl) ? "" : rpCanonicalUrl}${customCanonicalPath}`);
    } else if (pageNumber && pageNumber > 1) {
        escapeCanonical += escape(`?page=${pageNumber}`);
    }
    const escapePrev = prev && escape(prev);
    const escapeNext = next && escape(next);
    const dimensionRegions = escape(dimensionRegion);
    // advised practice to set ogType "article" only to blog type content views and set "website" to the rest of pages
    const ogTypeData = ogType || "website";

    const newMetaData: IStandardMetaData = {
        title: escapeTitle,
        description: escapeDescription,
        canonical: escapeCanonical,
        prev: escapePrev,
        next: escapeNext,
        robots,
        dimensionRegion: dimensionRegions,
        gtmPage,
        ogDescription: escapeDescription,
        ogTitle: ogTitle || escapeTitle,
        ogImage,
        ogType: ogTypeData,
        ogUrl: escapeCanonical,
        ogPublishDate,
        lang,
        ...(hreflang && {hreflang})
    };

    // update DOM
    setClientMetaData(newMetaData);

    return dispatch({type: UPDATE_META_DATA, ...newMetaData});
};

export const updateMetaTitleDescription = (title: string, description: string) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const escapeTitle = escape(title);
    const escapeDescription = escape(description);
    const metaDataState = getState().metaData.standardMetaData;

    const metaData: IStandardMetaData = {
        ...metaDataState,
        title: escapeTitle,
        description: escapeDescription
    };

    setClientMetaData(metaData);

    return dispatch({type: UPDATE_META_TITLE_DESCRIPTION, ...metaData});
};
import type {Dispatch} from "redux";

import {escape} from "@pg-mono/nodash";

import {IRPStore} from "../../app/rp_reducer";
import {OfferMetaRobots} from "../../offer/constants/offer_meta_robots";
import {IStandardMetaData, rpCanonicalUrl, UPDATE_META_DATA, UPDATE_META_TITLE_DESCRIPTION} from "../reducers/meta_data_reducer";
import {setClientMetaData} from "../utils/set_client_meta_data";

export interface IUpdateMetaDataAction extends IStandardMetaData {
    type: typeof UPDATE_META_DATA;
}

export interface IUpdateMetaTitleDescriptionAction extends Pick<IStandardMetaData, "title" | "description"> {
    type: typeof UPDATE_META_TITLE_DESCRIPTION;
}

export type MetaDataDispatch = Dispatch<IUpdateMetaDataAction | IUpdateMetaTitleDescriptionAction>;

export interface IMetaDataOptions {
    title: string;
    description: string;
    pathname: string;
    prev?: string;
    next?: string;
    robots?: string;
    dimensionRegion?: string;
    gtmPage?: string;
    ogImage?: string;
    ogTitle?: string;
    ogType?: string;
    ogUrl?: string;
    ogPublishDate?: string;
    customCanonicalPath?: string;
    pageNumber?: number;
    hreflang?: {language_code: string; slug: string}[];
    lang?: "uk" | "pl";
}

export const updateMetaData = (metaData: IMetaDataOptions) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const customMetaData = getState().metaData.customMetaData;
    const {
        title,
        description,
        pathname,
        prev,
        next,
        pageNumber,
        dimensionRegion,
        robots,
        gtmPage,
        ogImage,
        ogTitle,
        ogType,
        ogPublishDate,
        customCanonicalPath,
        hreflang,
        lang = "pl"
    } = metaData;

    // check for stages in offer detail view, stages that are uncanonical (robots = noindex, flow) should have default title and desc
    const isStagedOfferUncanonical = robots === OfferMetaRobots.NOINDEX_FOLLOW && pathname.includes("oferty");
    const prepareCustomTitle =
        !isStagedOfferUncanonical && customMetaData?.title && `${customMetaData.title}${pageNumber && pageNumber > 1 ? ` - Strona ${pageNumber}` : ""}`;
    const prepareCustomDesc =
        !isStagedOfferUncanonical &&
        customMetaData?.description &&
        `${customMetaData.description}${pageNumber && pageNumber > 1 ? ` - Strona ${pageNumber}` : ""}`;
    const escapeTitle = escape(prepareCustomTitle || title);
    const escapeDescription = escape(prepareCustomDesc || description);
    let escapeCanonical = escape(`${rpCanonicalUrl}${pathname}`);
    if (customCanonicalPath) {
        // priority custom canonical path
        escapeCanonical = escape(`${customCanonicalPath.includes(rpCanonicalUrl) ? "" : rpCanonicalUrl}${customCanonicalPath}`);
    } else if (pageNumber && pageNumber > 1) {
        escapeCanonical += escape(`?page=${pageNumber}`);
    }
    const escapePrev = prev && escape(prev);
    const escapeNext = next && escape(next);
    const dimensionRegions = escape(dimensionRegion);
    // advised practice to set ogType "article" only to blog type content views and set "website" to the rest of pages
    const ogTypeData = ogType || "website";

    const newMetaData: IStandardMetaData = {
        title: escapeTitle,
        description: escapeDescription,
        canonical: escapeCanonical,
        prev: escapePrev,
        next: escapeNext,
        robots,
        dimensionRegion: dimensionRegions,
        gtmPage,
        ogDescription: escapeDescription,
        ogTitle: ogTitle || escapeTitle,
        ogImage,
        ogType: ogTypeData,
        ogUrl: escapeCanonical,
        ogPublishDate,
        lang,
        ...(hreflang && {hreflang})
    };

    // update DOM
    setClientMetaData(newMetaData);

    return dispatch({type: UPDATE_META_DATA, ...newMetaData});
};

export const updateMetaTitleDescription = (title: string, description: string) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const escapeTitle = escape(title);
    const escapeDescription = escape(description);
    const metaDataState = getState().metaData.standardMetaData;

    const metaData: IStandardMetaData = {
        ...metaDataState,
        title: escapeTitle,
        description: escapeDescription
    };

    setClientMetaData(metaData);

    return dispatch({type: UPDATE_META_TITLE_DESCRIPTION, ...metaData});
};
