export enum HolidayLocationType {
    SEA = 1,
    MOUNTAINS = 2,
    LAKE = 3
}
export enum HolidayLocationType {
    SEA = 1,
    MOUNTAINS = 2,
    LAKE = 3
}
