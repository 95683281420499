import {appendQueryString} from "@pg-mono/request";

import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {SPECIAL_LISTING} from "../../list/constants/offer_list";
import {SpecialListingType} from "../../list/types/SpecialListingType";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {doesExistAnyFilterBesidesAllowed} from "./offer_listing_canonical_and_robots_helpers";

interface ICreateGeneralSeoRobotsRuleParams {
    page?: number;
    sortParamExists: boolean;
    seoDescriptionExists: boolean;
    offersCount: number;
    soldOffersCount: number;
    soonFinishedOffersCount: number;
    routeQuery: {
        [key: string]: string | string[];
    };
}

export const createGeneralSeoRobotsRule = ({
    page,
    sortParamExists,
    seoDescriptionExists,
    offersCount,
    soldOffersCount,
    soonFinishedOffersCount,
    routeQuery
}: ICreateGeneralSeoRobotsRuleParams) => ({
    condition: () =>
        Boolean(
            (page && page > 1) ||
                sortParamExists ||
                (offersCount + soldOffersCount + soonFinishedOffersCount === 0 && !seoDescriptionExists) ||
                (offersCount + soldOffersCount + soonFinishedOffersCount === 0 && routeQuery?.s)
        ),
    robots: OfferMetaRobots.NOINDEX_FOLLOW
});

interface ICreateSpecialListingsRuleParams extends IOfferListingSeoRuleParams {
    routePathname: string;
}

export const createCanonicalPageRule = ({page, ...params}: IOfferListingSeoRuleParams) => ({
    condition: () => Boolean(page && page > 1),
    canonical: () => appendQueryString(getCustomOfferCanonical(params), {page})
});

export const createSpecialListingCanonicalRule = ({routePathname, ...canonicalParams}: ICreateSpecialListingsRuleParams) => ({
    condition: () => SPECIAL_LISTING.some((el) => routePathname.includes(el)),
    canonical: () => getCustomOfferCanonical(canonicalParams)
});

export const createCanonicalAndRobotsCommercialAndMixedTypeFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => (params.offerType === OfferType.COMMERCIAL || !params.offerType) && doesExistAnyFilterBesidesAllowed(params.filters, []),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createRobotsMixedTypeForCustomFilterRule = (
    params: Pick<IOfferListingSeoRuleParams, "offerType"> & {query: {[key: string]: string | string[]}}
) => ({
    condition: () => !params.offerType && !!params.query.floor_choices,
    robots: OfferMetaRobots.NOINDEX
});

export const createSpecialAbroadListingsRobotsRule = (params: ICreateSpecialListingsRuleParams) => ({
    condition: () => {
        return !!(params.country && params.country !== Country.POLAND && !params.regions && !params.offerType);
    },
    robots: OfferMetaRobots.NOINDEX_FOLLOW
});

export const createSpecialListingsRobotsRule = (params: ICreateSpecialListingsRuleParams) => ({
    condition: () => {
        const regionType = params.regions && params.regions.length === 1 && params.regions[0].type;
        const isCity = regionType === RegionType.CITY;
        const isAggregation = regionType === RegionType.AGGREGATION;
        const isVoivodeshipOrCounty = [RegionType.VOIVODESHIP, RegionType.COUNTY].includes(regionType as number);
        const isDistrict = regionType === RegionType.DISTRICT;
        const isNeighborhood = regionType === RegionType.NEIGHBORHOOD;
        return (
            (params.routePathname.includes(SpecialListingType.LUXURY) && params.offerType && ![OfferType.HOUSE, OfferType.FLAT].includes(params.offerType)) ||
            ((params.routePathname.includes(SpecialListingType.LUXURY) || params.routePathname.includes(SpecialListingType.READY)) &&
                (isVoivodeshipOrCounty || isDistrict || isNeighborhood)) ||
            (params.routePathname.includes(SpecialListingType.CHEAP) &&
                ((params.offerType === OfferType.FLAT && !isCity && !isAggregation && !isDistrict) ||
                    (params.offerType === OfferType.HOUSE && !isVoivodeshipOrCounty && !isCity)))
        );
    },
    robots: OfferMetaRobots.NOINDEX
});
import {appendQueryString} from "@pg-mono/request";

import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {SPECIAL_LISTING} from "../../list/constants/offer_list";
import {SpecialListingType} from "../../list/types/SpecialListingType";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {doesExistAnyFilterBesidesAllowed} from "./offer_listing_canonical_and_robots_helpers";

interface ICreateGeneralSeoRobotsRuleParams {
    page?: number;
    sortParamExists: boolean;
    seoDescriptionExists: boolean;
    offersCount: number;
    soldOffersCount: number;
    soonFinishedOffersCount: number;
    routeQuery: {
        [key: string]: string | string[];
    };
}

export const createGeneralSeoRobotsRule = ({
    page,
    sortParamExists,
    seoDescriptionExists,
    offersCount,
    soldOffersCount,
    soonFinishedOffersCount,
    routeQuery
}: ICreateGeneralSeoRobotsRuleParams) => ({
    condition: () =>
        Boolean(
            (page && page > 1) ||
                sortParamExists ||
                (offersCount + soldOffersCount + soonFinishedOffersCount === 0 && !seoDescriptionExists) ||
                (offersCount + soldOffersCount + soonFinishedOffersCount === 0 && routeQuery?.s)
        ),
    robots: OfferMetaRobots.NOINDEX_FOLLOW
});

interface ICreateSpecialListingsRuleParams extends IOfferListingSeoRuleParams {
    routePathname: string;
}

export const createCanonicalPageRule = ({page, ...params}: IOfferListingSeoRuleParams) => ({
    condition: () => Boolean(page && page > 1),
    canonical: () => appendQueryString(getCustomOfferCanonical(params), {page})
});

export const createSpecialListingCanonicalRule = ({routePathname, ...canonicalParams}: ICreateSpecialListingsRuleParams) => ({
    condition: () => SPECIAL_LISTING.some((el) => routePathname.includes(el)),
    canonical: () => getCustomOfferCanonical(canonicalParams)
});

export const createCanonicalAndRobotsCommercialAndMixedTypeFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => (params.offerType === OfferType.COMMERCIAL || !params.offerType) && doesExistAnyFilterBesidesAllowed(params.filters, []),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

export const createRobotsMixedTypeForCustomFilterRule = (
    params: Pick<IOfferListingSeoRuleParams, "offerType"> & {query: {[key: string]: string | string[]}}
) => ({
    condition: () => !params.offerType && !!params.query.floor_choices,
    robots: OfferMetaRobots.NOINDEX
});

export const createSpecialAbroadListingsRobotsRule = (params: ICreateSpecialListingsRuleParams) => ({
    condition: () => {
        return !!(params.country && params.country !== Country.POLAND && !params.regions && !params.offerType);
    },
    robots: OfferMetaRobots.NOINDEX_FOLLOW
});

export const createSpecialListingsRobotsRule = (params: ICreateSpecialListingsRuleParams) => ({
    condition: () => {
        const regionType = params.regions && params.regions.length === 1 && params.regions[0].type;
        const isCity = regionType === RegionType.CITY;
        const isAggregation = regionType === RegionType.AGGREGATION;
        const isVoivodeshipOrCounty = [RegionType.VOIVODESHIP, RegionType.COUNTY].includes(regionType as number);
        const isDistrict = regionType === RegionType.DISTRICT;
        const isNeighborhood = regionType === RegionType.NEIGHBORHOOD;
        return (
            (params.routePathname.includes(SpecialListingType.LUXURY) && params.offerType && ![OfferType.HOUSE, OfferType.FLAT].includes(params.offerType)) ||
            ((params.routePathname.includes(SpecialListingType.LUXURY) || params.routePathname.includes(SpecialListingType.READY)) &&
                (isVoivodeshipOrCounty || isDistrict || isNeighborhood)) ||
            (params.routePathname.includes(SpecialListingType.CHEAP) &&
                ((params.offerType === OfferType.FLAT && !isCity && !isAggregation && !isDistrict) ||
                    (params.offerType === OfferType.HOUSE && !isVoivodeshipOrCounty && !isCity)))
        );
    },
    robots: OfferMetaRobots.NOINDEX
});
