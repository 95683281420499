import {Libraries, Library, Loader} from "@googlemaps/js-api-loader";

import {GoogleMapsApiConfig} from "./google_maps_configuration";

/**
 * GoogleLoader is a helper type that describes part of the google.maps object that is created in window after api initialization.
 * `importLibrary` method is untyped, but available in window, it is also mentioned in the documentation as a way to load additional
 * libraries on the go, ie. here https://developers.google.com/maps/documentation/javascript/libraries?hl=pl
 *
 * if that fails, we can always store reference to the Loader class instead of google.maps object
 */
type GoogleLoader = {importLibrary: (lib: Library) => Promise<void>};

const loadedLibraries: Libraries = [];

export const loadGoogleMapsApi = async (libraries: Libraries) => {
    let mapLoader: Loader | GoogleLoader | null = null;
    if (!window.google?.maps) {
        // if google apis are not loaded, create a new loader
        const apiKey = GoogleMapsApiConfig.getApiKey();

        if (!apiKey) {
            throw new Error("Google API key is not set. Please set it using `GoogleMapsApiConfig.setApiKey`.");
        }

        mapLoader = new Loader({
            apiKey: apiKey,
            region: "PL",
            language: "pl"
        });
    }

    if (window.google?.maps && !mapLoader) {
        // if google apis are already loaded, we can use them directly
        mapLoader = google.maps as unknown as GoogleLoader;
    }

    for (const lib of libraries) {
        if (!loadedLibraries.includes(lib)) {
            await mapLoader?.importLibrary(lib);
            loadedLibraries.push(lib);
        }
    }
};
import {Libraries, Library, Loader} from "@googlemaps/js-api-loader";

import {GoogleMapsApiConfig} from "./google_maps_configuration";

/**
 * GoogleLoader is a helper type that describes part of the google.maps object that is created in window after api initialization.
 * `importLibrary` method is untyped, but available in window, it is also mentioned in the documentation as a way to load additional
 * libraries on the go, ie. here https://developers.google.com/maps/documentation/javascript/libraries?hl=pl
 *
 * if that fails, we can always store reference to the Loader class instead of google.maps object
 */
type GoogleLoader = {importLibrary: (lib: Library) => Promise<void>};

const loadedLibraries: Libraries = [];

export const loadGoogleMapsApi = async (libraries: Libraries) => {
    let mapLoader: Loader | GoogleLoader | null = null;
    if (!window.google?.maps) {
        // if google apis are not loaded, create a new loader
        const apiKey = GoogleMapsApiConfig.getApiKey();

        if (!apiKey) {
            throw new Error("Google API key is not set. Please set it using `GoogleMapsApiConfig.setApiKey`.");
        }

        mapLoader = new Loader({
            apiKey: apiKey,
            region: "PL",
            language: "pl"
        });
    }

    if (window.google?.maps && !mapLoader) {
        // if google apis are already loaded, we can use them directly
        mapLoader = google.maps as unknown as GoogleLoader;
    }

    for (const lib of libraries) {
        if (!loadedLibraries.includes(lib)) {
            await mapLoader?.importLibrary(lib);
            loadedLibraries.push(lib);
        }
    }
};
