type Primitive = string | number;

type ThemeType = {[key: string | number | symbol]: ThemeType | Primitive};

export type PropertyStringPath<T, Prefix = ""> = {
    [K in keyof T]: T[K] extends Primitive | unknown[]
        ? `${Primitive & Prefix}${Primitive & K}`
        : `${Primitive & Prefix}${Primitive & K}` | PropertyStringPath<T[K], `${Primitive & Prefix}${Primitive & K}.`>;
}[keyof T];

/*
 * function returns a string containing css variables and values, ie:
 * ```
 * --colors-brand_success: #a55
 * --colors-gray_brighter: #b0b
 * ...etc.
 * ```
 */
export const transformThemeToCssVariables = (theme: ThemeType, base = "--"): string =>
    Object.entries(theme).reduce((css, [key, value]) => {
        const newBase = base + `${key}`;
        if (typeof value !== "object") {
            return css + newBase + `: ${value};\n`;
        }
        return css + transformThemeToCssVariables(value, newBase + "-");
    }, ``);

/**
 // TODO: do we need this with IDE autocomplete working correctly these days?
 * factory creates a convenient method to use theme variables inside css and styled components
 */
export const cssVariableFactory = <ThemeType>() => {
    return (path: PropertyStringPath<ThemeType>) => {
        // suppressing errors, because TS cries that this type is recursive (because it is)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (path.match("breakpoint")) {
            throw new Error("cssVariableFactory: css variables cannot be used in media queries");
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        const newPath = path.replace(/\./g, "-");
        return `var(--${newPath})`;
    };
};

/**
 * factory to create a getter for theme breakpoints
 * @param breakpoints
 */
export const cssBreakpointFactory =
    <BreakpointsType extends Record<string, string | Record<string, unknown>>>(breakpoints: BreakpointsType) =>
    (): BreakpointsType => {
        return breakpoints;
    };
type Primitive = string | number;

type ThemeType = {[key: string | number | symbol]: ThemeType | Primitive};

export type PropertyStringPath<T, Prefix = ""> = {
    [K in keyof T]: T[K] extends Primitive | unknown[]
        ? `${Primitive & Prefix}${Primitive & K}`
        : `${Primitive & Prefix}${Primitive & K}` | PropertyStringPath<T[K], `${Primitive & Prefix}${Primitive & K}.`>;
}[keyof T];

/*
 * function returns a string containing css variables and values, ie:
 * ```
 * --colors-brand_success: #a55
 * --colors-gray_brighter: #b0b
 * ...etc.
 * ```
 */
export const transformThemeToCssVariables = (theme: ThemeType, base = "--"): string =>
    Object.entries(theme).reduce((css, [key, value]) => {
        const newBase = base + `${key}`;
        if (typeof value !== "object") {
            return css + newBase + `: ${value};\n`;
        }
        return css + transformThemeToCssVariables(value, newBase + "-");
    }, ``);

/**
 // TODO: do we need this with IDE autocomplete working correctly these days?
 * factory creates a convenient method to use theme variables inside css and styled components
 */
export const cssVariableFactory = <ThemeType>() => {
    return (path: PropertyStringPath<ThemeType>) => {
        // suppressing errors, because TS cries that this type is recursive (because it is)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (path.match("breakpoint")) {
            throw new Error("cssVariableFactory: css variables cannot be used in media queries");
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        const newPath = path.replace(/\./g, "-");
        return `var(--${newPath})`;
    };
};

/**
 * factory to create a getter for theme breakpoints
 * @param breakpoints
 */
export const cssBreakpointFactory =
    <BreakpointsType extends Record<string, string | Record<string, unknown>>>(breakpoints: BreakpointsType) =>
    (): BreakpointsType => {
        return breakpoints;
    };
