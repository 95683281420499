import {ElementType, forwardRef, PropsWithChildren} from "react";
import React from "react";
import cn from "classnames";

import * as styles from "./Container.module.css";

interface IProps extends React.HTMLAttributes<HTMLElement> {
    as?: ElementType;
    fluid?: boolean;
}

export const Container = forwardRef((props: PropsWithChildren<IProps>, ref) => {
    const {as, className, fluid, ...restProps} = props;

    const Element = as || "div";

    return (
        <Element className={cn(fluid ? styles.containerFluid : styles.container, className)} ref={ref} {...restProps}>
            {props.children}
        </Element>
    );
});
import {ElementType, forwardRef, PropsWithChildren} from "react";
import React from "react";
import cn from "classnames";

import * as styles from "./Container.module.css";

interface IProps extends React.HTMLAttributes<HTMLElement> {
    as?: ElementType;
    fluid?: boolean;
}

export const Container = forwardRef((props: PropsWithChildren<IProps>, ref) => {
    const {as, className, fluid, ...restProps} = props;

    const Element = as || "div";

    return (
        <Element className={cn(fluid ? styles.containerFluid : styles.container, className)} ref={ref} {...restProps}>
            {props.children}
        </Element>
    );
});
