import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getCountryId} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {RegionType} from "../../region/types/RegionType";

const FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP_PREFIX = "investment_offer/FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP";
export const fetchInvestmentOfferListAbroadVoivodeshipTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP_PREFIX);

export interface IRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface IOfferListAbroadVoivodeshipResponse {
    results: IRegion[];
}

export const fetchInvestmentOfferListAbroadVoivodeship = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const countryName = getState().investmentOffer.categories.subcategory;
    const countryId = countryName ? getCountryId(countryName) : undefined;

    if (!countryId) {
        return;
    }

    const query = {country: countryId, type: RegionType.VOIVODESHIP};
    dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.start, latestQuery: query});
    const voivodeshipListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

    const url = appendQueryString(voivodeshipListApiLink, query);
    return getRequest(ctx.meta, url)
        .then((res: IOfferListAbroadVoivodeshipResponse) => {
            dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.success, result: res.results});
            return res.results;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.error, error: error.appError});
            })
        );
};

export const fetchInvestmentOfferListAbroadVoivodeshipAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchInvestmentOfferListAbroadVoivodeship(ctx);
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getCountryId} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {RegionType} from "../../region/types/RegionType";

const FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP_PREFIX = "investment_offer/FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP";
export const fetchInvestmentOfferListAbroadVoivodeshipTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_LIST_ABROAD_VOIVODESHIP_PREFIX);

export interface IRegion {
    id: number;
    name: string;
    slug: string;
    stats: {
        properties_all_count_for_sale: number;
        properties_flats_count_for_sale: number;
        properties_houses_count_for_sale: number;
        properties_commercials_count_for_sale: number;
        vendors_count_for_sale: number;
    };
}

interface IOfferListAbroadVoivodeshipResponse {
    results: IRegion[];
}

export const fetchInvestmentOfferListAbroadVoivodeship = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const countryName = getState().investmentOffer.categories.subcategory;
    const countryId = countryName ? getCountryId(countryName) : undefined;

    if (!countryId) {
        return;
    }

    const query = {country: countryId, type: RegionType.VOIVODESHIP};
    dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.start, latestQuery: query});
    const voivodeshipListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

    const url = appendQueryString(voivodeshipListApiLink, query);
    return getRequest(ctx.meta, url)
        .then((res: IOfferListAbroadVoivodeshipResponse) => {
            dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.success, result: res.results});
            return res.results;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchInvestmentOfferListAbroadVoivodeshipTypes.error, error: error.appError});
            })
        );
};

export const fetchInvestmentOfferListAbroadVoivodeshipAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchInvestmentOfferListAbroadVoivodeship(ctx);
};
