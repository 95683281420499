import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateInvestmentOfferMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    dispatch(
        updateMetaData({
            title: concatWithBrandName("Oferty nieruchomości idealnych pod inwestycje -"),
            description:
                "Chcesz zainwestować swoje pieniądze? &#9989; Szukasz mieszkań, które idealnie nadają się pod inwestycje? &#9989; Sprawdź ile możesz zarobić na nieruchomości inwestycyjnej!",
            pathname: ctx.route.pathname
        })
    );

    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateInvestmentOfferMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    dispatch(
        updateMetaData({
            title: concatWithBrandName("Oferty nieruchomości idealnych pod inwestycje -"),
            description:
                "Chcesz zainwestować swoje pieniądze? &#9989; Szukasz mieszkań, które idealnie nadają się pod inwestycje? &#9989; Sprawdź ile możesz zarobić na nieruchomości inwestycyjnej!",
            pathname: ctx.route.pathname
        })
    );

    return true;
};
