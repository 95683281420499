import {Dispatch, UnknownAction} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {pipe} from "@pg-mono/nodash";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {addImageLazyLoad} from "../utils/add_image_lazy_load";
import {extractHeadlines} from "../utils/extract_headlines";
import {insertSearchIntoArticle} from "../utils/insert_search_into_articles";
import {IArticleDetailArticle} from "./IArticleDetail";

export interface ITableOfContentsElement {
    text: string | null;
    id: string | null;
    elements: ITableOfContentsElement[];
}

const ARTICLE_DETAIL_PREFIX = "article_detail/fetch";
export const fetchArticleDetailTypes = createRequestActionTypes(ARTICLE_DETAIL_PREFIX);

export const fetchArticleDetail = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleDetailTypes.start});
    const articleDetailApiLink = apiV2Link.article.detail(Scenario.ARTICLE_DETAIL, {articleId: ctx.match.params.articleId});

    return getRequest(ctx.meta, articleDetailApiLink)
        .then((article: IArticleDetailArticle) => {
            // validate slug
            if (ctx.match.params.articleSlug !== article.slug) {
                const link = rpAppLink.article.detail({articleId: article.id, articleSlug: article.slug});
                dispatch(enable301ResponseState(link));
                return false;
            }
            const {text: originalText} = article;

            const APMTtansaction = ctx.meta.apm?.transaction;
            const apmSpan = APMTtansaction?.startSpan("article", "parse", {childOf: APMTtansaction?.traceparent});

            const {articleText, tableOfContents, articleHeaderIds} = extractHeadlines(originalText, "article");

            const modifiedText = pipe(insertSearchIntoArticle, addImageLazyLoad)(articleText);

            apmSpan?.end();
            apmSpan?.setOutcome("success");

            dispatch({
                type: fetchArticleDetailTypes.success,
                result: {
                    ...article,
                    lead: article.lead?.replace(/[\n\r]/g, "\n"),
                    text: modifiedText
                        ?.replace(/&oacute;/g, "ó")
                        .replace(/&Oacute;/g, "Ó")
                        .replace(/[\n\r]/g, "\n"),
                    table_of_contents: tableOfContents,
                    article_header_ids: articleHeaderIds
                }
            });

            return article;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};

export const resetArticleDetail = (): UnknownAction => ({type: fetchArticleDetailTypes.reset});
import {Dispatch, UnknownAction} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {pipe} from "@pg-mono/nodash";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {addImageLazyLoad} from "../utils/add_image_lazy_load";
import {extractHeadlines} from "../utils/extract_headlines";
import {insertSearchIntoArticle} from "../utils/insert_search_into_articles";
import {IArticleDetailArticle} from "./IArticleDetail";

export interface ITableOfContentsElement {
    text: string | null;
    id: string | null;
    elements: ITableOfContentsElement[];
}

const ARTICLE_DETAIL_PREFIX = "article_detail/fetch";
export const fetchArticleDetailTypes = createRequestActionTypes(ARTICLE_DETAIL_PREFIX);

export const fetchArticleDetail = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchArticleDetailTypes.start});
    const articleDetailApiLink = apiV2Link.article.detail(Scenario.ARTICLE_DETAIL, {articleId: ctx.match.params.articleId});

    return getRequest(ctx.meta, articleDetailApiLink)
        .then((article: IArticleDetailArticle) => {
            // validate slug
            if (ctx.match.params.articleSlug !== article.slug) {
                const link = rpAppLink.article.detail({articleId: article.id, articleSlug: article.slug});
                dispatch(enable301ResponseState(link));
                return false;
            }
            const {text: originalText} = article;

            const APMTtansaction = ctx.meta.apm?.transaction;
            const apmSpan = APMTtansaction?.startSpan("article", "parse", {childOf: APMTtansaction?.traceparent});

            const {articleText, tableOfContents, articleHeaderIds} = extractHeadlines(originalText, "article");

            const modifiedText = pipe(insertSearchIntoArticle, addImageLazyLoad)(articleText);

            apmSpan?.end();
            apmSpan?.setOutcome("success");

            dispatch({
                type: fetchArticleDetailTypes.success,
                result: {
                    ...article,
                    lead: article.lead?.replace(/[\n\r]/g, "\n"),
                    text: modifiedText
                        ?.replace(/&oacute;/g, "ó")
                        .replace(/&Oacute;/g, "Ó")
                        .replace(/[\n\r]/g, "\n"),
                    table_of_contents: tableOfContents,
                    article_header_ids: articleHeaderIds
                }
            });

            return article;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};

export const resetArticleDetail = (): UnknownAction => ({type: fetchArticleDetailTypes.reset});
