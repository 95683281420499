import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState, enable302ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {fetchVendorDetailById} from "../api/fetch_vendor_detail_by_id";

const DESKTOP_VENDOR_DETAIL_PREFIX = "desktop_vendor_detail/fetch";
export const fetchVendorDetailTypes = createRequestActionTypes(DESKTOP_VENDOR_DETAIL_PREFIX);

export const fetchVendorDetailAtRoute =
    ({route, meta, match}: IFetchContext<IRPRequestMeta>) =>
    (dispatch: Dispatch) => {
        dispatch({type: fetchVendorDetailTypes.start});
        return fetchVendorDetailById(meta, match.params.vendorId)
            .then((json) => {
                // validate is_active
                if (json.configuration.is_active === false) {
                    // TODO: Discuss this with business
                    // const headquarter = json.offices.find((o) => o.type === 1);

                    if ((json.headquarters && json.headquarters.type !== 1) || !json.headquarters) {
                        dispatch(enable302ResponseState(rpAppLink.vendor.list()));
                        return false;
                    }
                    const regionFriendlySlug = offerUrlBuilder({region: json.headquarters.region.slug}).friendlySlug;
                    dispatch(enable302ResponseState(rpAppLink.offer.listFriendly({friendlySlug: regionFriendlySlug})));
                    return false;
                }
                // validate slug
                if (match.params.vendorSlug !== json.slug) {
                    const link = rpAppLink.vendor.detail.base({vendorSlug: json.slug, vendorId: json.id});
                    dispatch(enable301ResponseState(link));
                    return false;
                }
                // success
                dispatch({type: fetchVendorDetailTypes.success, result: json});
                return json;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(route.pathname, meta));
                })
            );
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState, enable302ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {fetchVendorDetailById} from "../api/fetch_vendor_detail_by_id";

const DESKTOP_VENDOR_DETAIL_PREFIX = "desktop_vendor_detail/fetch";
export const fetchVendorDetailTypes = createRequestActionTypes(DESKTOP_VENDOR_DETAIL_PREFIX);

export const fetchVendorDetailAtRoute =
    ({route, meta, match}: IFetchContext<IRPRequestMeta>) =>
    (dispatch: Dispatch) => {
        dispatch({type: fetchVendorDetailTypes.start});
        return fetchVendorDetailById(meta, match.params.vendorId)
            .then((json) => {
                // validate is_active
                if (json.configuration.is_active === false) {
                    // TODO: Discuss this with business
                    // const headquarter = json.offices.find((o) => o.type === 1);

                    if ((json.headquarters && json.headquarters.type !== 1) || !json.headquarters) {
                        dispatch(enable302ResponseState(rpAppLink.vendor.list()));
                        return false;
                    }
                    const regionFriendlySlug = offerUrlBuilder({region: json.headquarters.region.slug}).friendlySlug;
                    dispatch(enable302ResponseState(rpAppLink.offer.listFriendly({friendlySlug: regionFriendlySlug})));
                    return false;
                }
                // validate slug
                if (match.params.vendorSlug !== json.slug) {
                    const link = rpAppLink.vendor.detail.base({vendorSlug: json.slug, vendorId: json.id});
                    dispatch(enable301ResponseState(link));
                    return false;
                }
                // success
                dispatch({type: fetchVendorDetailTypes.success, result: json});
                return json;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(route.pathname, meta));
                })
            );
    };
