import React, {forwardRef, ReactNode, useRef} from "react";

import {RadioCheckedIcon, RadioIcon} from "@pg-design/icons-module";

import {IRadioOnChange} from "../types/IRadioOnChange";
import {IRadioValue} from "../types/IRadioValue";

import {
    componentContainerNotReadOnlyStyle,
    componentContainerReadOnlyStyle,
    hiddenInputStyle,
    labelContainerStyle,
    radioActiveMarkStyle,
    radioActiveMarkSvgStyle,
    radioContainerStyle,
    radioFrameStyle,
    radioFrameSvgStyle,
    radioWrapperStyle,
    radioWrapStyle
} from "./Radio.module.css";

//  Types
export type IRadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "checked" | "aria-labelledby"> & {
    name: string;
    value: IRadioValue;
    id: string;
    isActive?: boolean;
} & ILabelScenarios &
    IReadWriteScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: ReactNode | string;
    "aria-labelledby"?: never;
};

// RW scenarios - provide onChange when you don't provide readOnly
type IReadWriteScenarios = IEditableScenario | IReadOnlyScenario;

type IEditableScenario = {
    readOnly?: never;
    onChange: IRadioOnChange;
};

type IReadOnlyScenario = {
    onChange?: never;
    readOnly: true;
};

// Component
export const Radio = forwardRef<HTMLInputElement, IRadioProps>((props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isActive, onChange, autoFocus, readOnly, labelContent, id, ...inputProps} = props;
    const radioRef = forwardedRef ? useRef<HTMLInputElement | null>(null) : (forwardedRef as unknown as React.MutableRefObject<HTMLInputElement>);

    const componentContainerStyle = readOnly ? componentContainerReadOnlyStyle : componentContainerNotReadOnlyStyle;
    const radioFrameFill = readOnly ? "var(--colors-gray-300)" : "var(--colors-gray-700)";
    const radioActiveMarkSvgFill = readOnly ? "var(--colors-gray-300)" : "#02D054";
    const radioActiveMarkOpacity = {opacity: isActive ? 100 : 0};

    return (
        <div className={componentContainerStyle}>
            <div className={radioWrapperStyle}>
                <input
                    {...inputProps}
                    type="radio"
                    checked={isActive}
                    onChange={({target: {checked, value, name}}) => {
                        if (!readOnly && onChange) {
                            (onChange as IRadioOnChange)(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={radioRef}
                    className={hiddenInputStyle}
                />
                <div className={radioWrapStyle}>
                    <div className={radioContainerStyle}>
                        {isActive ? (
                            <div className={radioActiveMarkStyle} style={radioActiveMarkOpacity}>
                                <RadioCheckedIcon className={radioActiveMarkSvgStyle} fill={radioActiveMarkSvgFill} />
                            </div>
                        ) : (
                            <div className={radioFrameStyle}>
                                <RadioIcon className={radioFrameSvgStyle} fill={radioFrameFill} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {labelContent && (
                <label
                    className={labelContainerStyle}
                    onClick={() => {
                        if (!readOnly && onChange && radioRef?.current) {
                            const {name, value, checked} = radioRef.current;
                            (onChange as IRadioOnChange)(name, !checked, value);
                        }
                    }}
                    htmlFor={id}
                >
                    {labelContent}
                </label>
            )}
        </div>
    );
});
import React, {forwardRef, ReactNode, useRef} from "react";

import {RadioCheckedIcon, RadioIcon} from "@pg-design/icons-module";

import {IRadioOnChange} from "../types/IRadioOnChange";
import {IRadioValue} from "../types/IRadioValue";

import {
    componentContainerNotReadOnlyStyle,
    componentContainerReadOnlyStyle,
    hiddenInputStyle,
    labelContainerStyle,
    radioActiveMarkStyle,
    radioActiveMarkSvgStyle,
    radioContainerStyle,
    radioFrameStyle,
    radioFrameSvgStyle,
    radioWrapperStyle,
    radioWrapStyle
} from "./Radio.module.css";

//  Types
export type IRadioProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "checked" | "aria-labelledby"> & {
    name: string;
    value: IRadioValue;
    id: string;
    isActive?: boolean;
} & ILabelScenarios &
    IReadWriteScenarios;

//  Label scenarios - provide aria-labelledby if you're not providing a labelContent
type ILabelScenarios = INoLabelScenario | ILabelProvidedScenario;

type INoLabelScenario = {
    labelContent?: never;
    "aria-labelledby": string;
};

type ILabelProvidedScenario = {
    labelContent: ReactNode | string;
    "aria-labelledby"?: never;
};

// RW scenarios - provide onChange when you don't provide readOnly
type IReadWriteScenarios = IEditableScenario | IReadOnlyScenario;

type IEditableScenario = {
    readOnly?: never;
    onChange: IRadioOnChange;
};

type IReadOnlyScenario = {
    onChange?: never;
    readOnly: true;
};

// Component
export const Radio = forwardRef<HTMLInputElement, IRadioProps>((props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isActive, onChange, autoFocus, readOnly, labelContent, id, ...inputProps} = props;
    const radioRef = forwardedRef ? useRef<HTMLInputElement | null>(null) : (forwardedRef as unknown as React.MutableRefObject<HTMLInputElement>);

    const componentContainerStyle = readOnly ? componentContainerReadOnlyStyle : componentContainerNotReadOnlyStyle;
    const radioFrameFill = readOnly ? "var(--colors-gray-300)" : "var(--colors-gray-700)";
    const radioActiveMarkSvgFill = readOnly ? "var(--colors-gray-300)" : "#02D054";
    const radioActiveMarkOpacity = {opacity: isActive ? 100 : 0};

    return (
        <div className={componentContainerStyle}>
            <div className={radioWrapperStyle}>
                <input
                    {...inputProps}
                    type="radio"
                    checked={isActive}
                    onChange={({target: {checked, value, name}}) => {
                        if (!readOnly && onChange) {
                            (onChange as IRadioOnChange)(name, checked, value);
                        }
                    }}
                    id={id}
                    ref={radioRef}
                    className={hiddenInputStyle}
                />
                <div className={radioWrapStyle}>
                    <div className={radioContainerStyle}>
                        {isActive ? (
                            <div className={radioActiveMarkStyle} style={radioActiveMarkOpacity}>
                                <RadioCheckedIcon className={radioActiveMarkSvgStyle} fill={radioActiveMarkSvgFill} />
                            </div>
                        ) : (
                            <div className={radioFrameStyle}>
                                <RadioIcon className={radioFrameSvgStyle} fill={radioFrameFill} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {labelContent && (
                <label
                    className={labelContainerStyle}
                    onClick={() => {
                        if (!readOnly && onChange && radioRef?.current) {
                            const {name, value, checked} = radioRef.current;
                            (onChange as IRadioOnChange)(name, !checked, value);
                        }
                    }}
                    htmlFor={id}
                >
                    {labelContent}
                </label>
            )}
        </div>
    );
});
