export enum TravelTimeValues {
    TEN = 10,
    TWENTY = 20,
    THIRTY = 30,
    FORTY_FIVE = 45,
    SIXTY = 60
}
export enum TravelTimeValues {
    TEN = 10,
    TWENTY = 20,
    THIRTY = 30,
    FORTY_FIVE = 45,
    SIXTY = 60
}
