import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, postRequest} from "@pg-mono/request";
import {enable302ResponseState} from "@pg-mono/response-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

export const redirectAfterOauthLoginAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {code, next} = ctx.route.query;
    const userApiMeta = getUserApiRequestMeta(ctx.meta);

    if (code) {
        await postRequest(userApiMeta, apiUserLink.oauth.token, {code}).catch((error) => {
            notifyBugsnag(error, "code validation error in redirectAfterOauthLoginAtRoute", error);
        });
    }

    /*
     * Redirect to place where user clicked `login` button (`next` query param) or to main page
     * Add `oauth_login` to query in order to make subsequent actions like sync user with user.com data.
     */
    dispatch(enable302ResponseState(appendQueryString((next as string) || rpAppPath.base, {oauth_login: true})));

    return false;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, postRequest} from "@pg-mono/request";
import {enable302ResponseState} from "@pg-mono/response-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

export const redirectAfterOauthLoginAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const {code, next} = ctx.route.query;
    const userApiMeta = getUserApiRequestMeta(ctx.meta);

    if (code) {
        await postRequest(userApiMeta, apiUserLink.oauth.token, {code}).catch((error) => {
            notifyBugsnag(error, "code validation error in redirectAfterOauthLoginAtRoute", error);
        });
    }

    /*
     * Redirect to place where user clicked `login` button (`next` query param) or to main page
     * Add `oauth_login` to query in order to make subsequent actions like sync user with user.com data.
     */
    dispatch(enable302ResponseState(appendQueryString((next as string) || rpAppPath.base, {oauth_login: true})));

    return false;
};
