import {IFetchContext} from "@pg-mono/data-fetcher";
import {upperFirst} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize, safelyParsePage} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {generateMetaPageNumber} from "../../../meta_data/utils/generate_meta_page_number";
import {IInvestmentOfferStats} from "../../actions/fetch_investment_offer_stats";
import {
    InvestmentOfferCitySlug,
    InvestmentOfferSubTypeSlug,
    InvestmentOfferTypeSlug,
    SpainInvestmentOfferSlug
} from "../../constants/investment_offer_constants";
import {getCountryDeclensionWhere} from "../../utils/get_country_declension_where";
import {INVESTMENT_OFFER_LIST_PAGE_SIZE} from "./fetch_investment_offer_list";

const prepareCategoryViewMetadata = (
    categories: {category: string | null},
    stats: IInvestmentOfferStats
): {title: string; description: string; pathname?: string; robots?: string} => {
    switch (categories.category) {
        case InvestmentOfferTypeSlug.CONDOHOTEL:
            return {
                title: concatWithBrandName("Condohotele - zysk 5-8% w skali roku. Zainwestuj już dziś! -"),
                description:
                    "Oferty condohoteli w które możesz zainwestować już dziś. Gwarancja zwrotu bez konieczności zarządzania inwestycja. Sprawdź ile możesz zyskać 💰"
            };
        case InvestmentOfferTypeSlug.HOLIDAY:
            return {
                title: concatWithBrandName("Apartamenty wakacyjne - zarabiaj na nieruchomościach w kurortach -"),
                description: `${stats.totals.is_holiday_location} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_holiday_location
                )} apartamentów wakacyjnych. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.INVESTMENT:
            return {
                title: concatWithBrandName("Apartamenty inwestycyjne - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_investment_apartment} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_investment_apartment
                )} apartamentów inwestycyjnych. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.FOR_RENT:
            return {
                title: concatWithBrandName("Oferty pod wynajem - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_dedicated_for_rent} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_dedicated_for_rent
                )} pod wynajem. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`,
                pathname: rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.INVESTMENT})
            };
        case InvestmentOfferTypeSlug.ABROAD:
            return {
                title: concatWithBrandName("Nieruchomości Hiszpania - apartamenty wakacyjne na sprzedaż -"),
                description: `${concatWithBrandName(
                    "Nieruchomości Hiszpania - inwestuj w mieszkania za granicą. Kup apartament wakacyjny w zagranicznym kurorcie na"
                )}. Zarabiaj i wypoczywaj!`
            };
        default:
            return {
                title: concatWithBrandName("Oferty nieruchomości idealnych pod inwestycje -"),
                description:
                    "Chcesz zainwestować swoje pieniądze? &amp;#9989; Szukasz mieszkań, które idealnie nadają się pod inwestycje? &amp;#9989; Sprawdź ile możesz zarobić na nieruchomości inwestycyjnej!"
            };
    }
};

export const prepareSubCategoryViewMetaData = (categories: {category: string | null; subcategory: string | null}, offerCount: number, mapView?: boolean) => {
    const parsedCategory = (() => {
        switch (categories.category) {
            case InvestmentOfferTypeSlug.CONDOHOTEL:
                return {
                    one: "condohotel",
                    two: "condohotele",
                    five: "condohoteli"
                };
            case InvestmentOfferTypeSlug.INVESTMENT:
                return {
                    one: "apartament inwestycyjny",
                    two: "apartamenty inwestycyjne",
                    five: "apartamentów inwestycyjnych"
                };
            case InvestmentOfferTypeSlug.HOLIDAY:
                return {
                    one: "apartament wakacyjny",
                    two: "apartamenty wakacyjne",
                    five: "apartamentów wakacyjnych"
                };
            case InvestmentOfferTypeSlug.FOR_RENT:
                return {
                    one: "oferta pod wynajem",
                    two: "oferty pod wynajem",
                    five: "ofert pod wynajem"
                };
            default:
                return {
                    one: "",
                    two: "",
                    five: ""
                };
        }
    })();

    const parsedSubCategory = (() => {
        switch (categories.subcategory) {
            case InvestmentOfferSubTypeSlug.SEA:
                return concatWithBrandName("nad morzem -");
            case InvestmentOfferSubTypeSlug.LAKE:
                return concatWithBrandName("nad jeziorem -");
            case InvestmentOfferSubTypeSlug.MOUNTAIN:
                return concatWithBrandName("w górach -");
            case InvestmentOfferCitySlug.WARSZAWA:
                return concatWithBrandName("w Warszawie -");
            case InvestmentOfferCitySlug.GDANSK:
                return concatWithBrandName("w Gdańsku -");
            case InvestmentOfferCitySlug.KRAKOW:
                return concatWithBrandName("w Krakowie -");
            case InvestmentOfferCitySlug.LODZ:
                return concatWithBrandName("w Łodzi -");
            case InvestmentOfferCitySlug.POZNAN:
                return concatWithBrandName("w Poznaniu -");
            case InvestmentOfferCitySlug.WROCLAW:
                return concatWithBrandName("we Wrocławiu -");
            default:
                return "";
        }
    })();

    const robots = (() => {
        if ((categories.category === InvestmentOfferTypeSlug.FOR_RENT && offerCount) || !offerCount) {
            return "noindex, follow";
        }

        return undefined;
    })();

    if (categories.category === InvestmentOfferTypeSlug.ABROAD) {
        let country,
            countryWhere = "";

        if (categories.subcategory) {
            country = upperFirst(categories.subcategory);
            countryWhere = getCountryDeclensionWhere(categories.subcategory as SpainInvestmentOfferSlug);
        }

        return {
            title: concatWithBrandName(`Nieruchomości ${country} - apartamenty i domy -`),
            description: `${concatWithBrandName(
                `Nieruchomości ${country} - inwestuj w mieszkania ${countryWhere}. Domy, mieszkania, apartamenty ${country} na`
            )}. Zarabiaj i wypoczywaj!`,
            robots
        };
    }

    return {
        title: `${upperFirst(parsedCategory.two)} ${parsedSubCategory}`,
        description: `Szukasz opłacalnej inwestycji? Sprawdź oferty${mapView ? " na mapie" : ""} ${
            parsedCategory.five
        } ${parsedSubCategory} - zainwestuj i ciesz się zyskiem 💰`,
        robots
    };
};

export const updateInvestmentOfferListMetaDataAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {offers} = getState().investmentOffer.list;
        if (!offers) {
            return false;
        }
        const {count: offerCount} = offers;
        const {categories, stats} = getState().investmentOffer;

        const parsedPage = safelyParsePage(ctx.route.query.page as string);
        const query = ctx.route.query;

        const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
        // TODO: change next prev calculation
        // const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);

        const prev = (() => {
            if (parsedPage === 1) {
                return undefined;
            }
            if (parsedPage === 2) {
                return hostAndPathname;
            }
            return appendQueryString(hostAndPathname, {...ctx.route.query, page: parsedPage - 1});
        })();
        const next =
            parsedPage * INVESTMENT_OFFER_LIST_PAGE_SIZE < offerCount
                ? appendQueryString(hostAndPathname, {...ctx.route.query, page: parsedPage + 1})
                : undefined;

        if (categories && categories.category && !categories.subcategory) {
            const categoryViewMetaData = prepareCategoryViewMetadata(categories, stats);
            const robots = parsedPage > 1 || (query && query.sort) ? "noindex, follow" : categoryViewMetaData.robots;
            dispatch(
                updateMetaData({
                    title: categoryViewMetaData.title + generateMetaPageNumber(parsedPage),
                    description: categoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: categoryViewMetaData.pathname ? categoryViewMetaData.pathname : ctx.route.pathname,
                    prev,
                    next,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        if (categories && categories.category && categories.subcategory) {
            const subcategoryViewMetaData = prepareSubCategoryViewMetaData(categories, offerCount);
            const robots = parsedPage > 1 || (query && query.sort) ? "noindex, follow" : subcategoryViewMetaData.robots;
            dispatch(
                updateMetaData({
                    title: subcategoryViewMetaData.title + generateMetaPageNumber(parsedPage),
                    description: subcategoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: ctx.route.pathname,
                    prev,
                    next,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        return Promise.resolve(true);
    };
import {IFetchContext} from "@pg-mono/data-fetcher";
import {upperFirst} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize, safelyParsePage} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {generateMetaPageNumber} from "../../../meta_data/utils/generate_meta_page_number";
import {IInvestmentOfferStats} from "../../actions/fetch_investment_offer_stats";
import {
    InvestmentOfferCitySlug,
    InvestmentOfferSubTypeSlug,
    InvestmentOfferTypeSlug,
    SpainInvestmentOfferSlug
} from "../../constants/investment_offer_constants";
import {getCountryDeclensionWhere} from "../../utils/get_country_declension_where";
import {INVESTMENT_OFFER_LIST_PAGE_SIZE} from "./fetch_investment_offer_list";

const prepareCategoryViewMetadata = (
    categories: {category: string | null},
    stats: IInvestmentOfferStats
): {title: string; description: string; pathname?: string; robots?: string} => {
    switch (categories.category) {
        case InvestmentOfferTypeSlug.CONDOHOTEL:
            return {
                title: concatWithBrandName("Condohotele - zysk 5-8% w skali roku. Zainwestuj już dziś! -"),
                description:
                    "Oferty condohoteli w które możesz zainwestować już dziś. Gwarancja zwrotu bez konieczności zarządzania inwestycja. Sprawdź ile możesz zyskać 💰"
            };
        case InvestmentOfferTypeSlug.HOLIDAY:
            return {
                title: concatWithBrandName("Apartamenty wakacyjne - zarabiaj na nieruchomościach w kurortach -"),
                description: `${stats.totals.is_holiday_location} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_holiday_location
                )} apartamentów wakacyjnych. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.INVESTMENT:
            return {
                title: concatWithBrandName("Apartamenty inwestycyjne - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_investment_apartment} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_investment_apartment
                )} apartamentów inwestycyjnych. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`
            };
        case InvestmentOfferTypeSlug.FOR_RENT:
            return {
                title: concatWithBrandName("Oferty pod wynajem - zarabiaj na nieruchomościach w całej Polsce -"),
                description: `${stats.totals.is_dedicated_for_rent} ${pluralize(["oferta", "oferty", "ofert"])(
                    stats.totals.is_dedicated_for_rent
                )} pod wynajem. Porównaj oferty i wybierz nieruchomość w którą warto zainwestować. Zarabiaj nawet 8% 💰`,
                pathname: rpAppLink.investmentOffer.investmentCategory.base({category: InvestmentOfferTypeSlug.INVESTMENT})
            };
        case InvestmentOfferTypeSlug.ABROAD:
            return {
                title: concatWithBrandName("Nieruchomości Hiszpania - apartamenty wakacyjne na sprzedaż -"),
                description: `${concatWithBrandName(
                    "Nieruchomości Hiszpania - inwestuj w mieszkania za granicą. Kup apartament wakacyjny w zagranicznym kurorcie na"
                )}. Zarabiaj i wypoczywaj!`
            };
        default:
            return {
                title: concatWithBrandName("Oferty nieruchomości idealnych pod inwestycje -"),
                description:
                    "Chcesz zainwestować swoje pieniądze? &amp;#9989; Szukasz mieszkań, które idealnie nadają się pod inwestycje? &amp;#9989; Sprawdź ile możesz zarobić na nieruchomości inwestycyjnej!"
            };
    }
};

export const prepareSubCategoryViewMetaData = (categories: {category: string | null; subcategory: string | null}, offerCount: number, mapView?: boolean) => {
    const parsedCategory = (() => {
        switch (categories.category) {
            case InvestmentOfferTypeSlug.CONDOHOTEL:
                return {
                    one: "condohotel",
                    two: "condohotele",
                    five: "condohoteli"
                };
            case InvestmentOfferTypeSlug.INVESTMENT:
                return {
                    one: "apartament inwestycyjny",
                    two: "apartamenty inwestycyjne",
                    five: "apartamentów inwestycyjnych"
                };
            case InvestmentOfferTypeSlug.HOLIDAY:
                return {
                    one: "apartament wakacyjny",
                    two: "apartamenty wakacyjne",
                    five: "apartamentów wakacyjnych"
                };
            case InvestmentOfferTypeSlug.FOR_RENT:
                return {
                    one: "oferta pod wynajem",
                    two: "oferty pod wynajem",
                    five: "ofert pod wynajem"
                };
            default:
                return {
                    one: "",
                    two: "",
                    five: ""
                };
        }
    })();

    const parsedSubCategory = (() => {
        switch (categories.subcategory) {
            case InvestmentOfferSubTypeSlug.SEA:
                return concatWithBrandName("nad morzem -");
            case InvestmentOfferSubTypeSlug.LAKE:
                return concatWithBrandName("nad jeziorem -");
            case InvestmentOfferSubTypeSlug.MOUNTAIN:
                return concatWithBrandName("w górach -");
            case InvestmentOfferCitySlug.WARSZAWA:
                return concatWithBrandName("w Warszawie -");
            case InvestmentOfferCitySlug.GDANSK:
                return concatWithBrandName("w Gdańsku -");
            case InvestmentOfferCitySlug.KRAKOW:
                return concatWithBrandName("w Krakowie -");
            case InvestmentOfferCitySlug.LODZ:
                return concatWithBrandName("w Łodzi -");
            case InvestmentOfferCitySlug.POZNAN:
                return concatWithBrandName("w Poznaniu -");
            case InvestmentOfferCitySlug.WROCLAW:
                return concatWithBrandName("we Wrocławiu -");
            default:
                return "";
        }
    })();

    const robots = (() => {
        if ((categories.category === InvestmentOfferTypeSlug.FOR_RENT && offerCount) || !offerCount) {
            return "noindex, follow";
        }

        return undefined;
    })();

    if (categories.category === InvestmentOfferTypeSlug.ABROAD) {
        let country,
            countryWhere = "";

        if (categories.subcategory) {
            country = upperFirst(categories.subcategory);
            countryWhere = getCountryDeclensionWhere(categories.subcategory as SpainInvestmentOfferSlug);
        }

        return {
            title: concatWithBrandName(`Nieruchomości ${country} - apartamenty i domy -`),
            description: `${concatWithBrandName(
                `Nieruchomości ${country} - inwestuj w mieszkania ${countryWhere}. Domy, mieszkania, apartamenty ${country} na`
            )}. Zarabiaj i wypoczywaj!`,
            robots
        };
    }

    return {
        title: `${upperFirst(parsedCategory.two)} ${parsedSubCategory}`,
        description: `Szukasz opłacalnej inwestycji? Sprawdź oferty${mapView ? " na mapie" : ""} ${
            parsedCategory.five
        } ${parsedSubCategory} - zainwestuj i ciesz się zyskiem 💰`,
        robots
    };
};

export const updateInvestmentOfferListMetaDataAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {offers} = getState().investmentOffer.list;
        if (!offers) {
            return false;
        }
        const {count: offerCount} = offers;
        const {categories, stats} = getState().investmentOffer;

        const parsedPage = safelyParsePage(ctx.route.query.page as string);
        const query = ctx.route.query;

        const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
        // TODO: change next prev calculation
        // const {prev, next} = generatePrevNextMetaLinks(parsedPage, maxPage, hostAndPathname, restQuery);

        const prev = (() => {
            if (parsedPage === 1) {
                return undefined;
            }
            if (parsedPage === 2) {
                return hostAndPathname;
            }
            return appendQueryString(hostAndPathname, {...ctx.route.query, page: parsedPage - 1});
        })();
        const next =
            parsedPage * INVESTMENT_OFFER_LIST_PAGE_SIZE < offerCount
                ? appendQueryString(hostAndPathname, {...ctx.route.query, page: parsedPage + 1})
                : undefined;

        if (categories && categories.category && !categories.subcategory) {
            const categoryViewMetaData = prepareCategoryViewMetadata(categories, stats);
            const robots = parsedPage > 1 || (query && query.sort) ? "noindex, follow" : categoryViewMetaData.robots;
            dispatch(
                updateMetaData({
                    title: categoryViewMetaData.title + generateMetaPageNumber(parsedPage),
                    description: categoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: categoryViewMetaData.pathname ? categoryViewMetaData.pathname : ctx.route.pathname,
                    prev,
                    next,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        if (categories && categories.category && categories.subcategory) {
            const subcategoryViewMetaData = prepareSubCategoryViewMetaData(categories, offerCount);
            const robots = parsedPage > 1 || (query && query.sort) ? "noindex, follow" : subcategoryViewMetaData.robots;
            dispatch(
                updateMetaData({
                    title: subcategoryViewMetaData.title + generateMetaPageNumber(parsedPage),
                    description: subcategoryViewMetaData.description + generateMetaPageNumber(parsedPage),
                    pathname: ctx.route.pathname,
                    prev,
                    next,
                    pageNumber: parsedPage,
                    robots
                })
            );
        }

        return Promise.resolve(true);
    };
