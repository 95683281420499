import {IFavourite} from "../interfaces/IFavourite";

const sortByDate = (a: string, b: string) => {
    return new Date(a).getTime() - new Date(b).getTime();
};

export const findLatestUpdatedFavouriteDate = (favourites: IFavourite[]): string | null => {
    if (!favourites.length) {
        return null;
    }

    const favourite = favourites.sort((a, b) => sortByDate(a.update_date as string, b.update_date as string)).reverse()?.[0];

    return favourite ? (favourite.update_date as string) : null;
};
import {IFavourite} from "../interfaces/IFavourite";

const sortByDate = (a: string, b: string) => {
    return new Date(a).getTime() - new Date(b).getTime();
};

export const findLatestUpdatedFavouriteDate = (favourites: IFavourite[]): string | null => {
    if (!favourites.length) {
        return null;
    }

    const favourite = favourites.sort((a, b) => sortByDate(a.update_date as string, b.update_date as string)).reverse()?.[0];

    return favourite ? (favourite.update_date as string) : null;
};
