import {formatISO, lastDayOfMonth, setDate, sub} from "date-fns";
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {getTypeForFetch, PricesViewType} from "../helpers/prices_view_type";
import {IAveragePricesQuery} from "../types/IAveragePricesQuery";
import {fetchAveragePricesBySlug} from "./fetch_average_prices";

const FETCH_REPORT_PRICE_ALL_CITIES_PRICES = "reports/FETCH_REPORT_PRICE_ALL_CITIES_PRICES";
export const fetchReportPriceAllCitiesPrices = createRequestActionTypes(FETCH_REPORT_PRICE_ALL_CITIES_PRICES);

export const fetchAllCitiesPrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceAllCitiesPrices.start});

    const date = new Date();
    const query: IAveragePricesQuery = {
        //first day of month, 6 months ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 1}), {representation: "date"}),
        //last day of month before date, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1})), {representation: "date"}),
        scenario: "rp-avg",
        offer_type: getTypeForFetch(PricesViewType.FLATS),
        slug_city: "all"
    };

    const prices = await fetchAveragePricesBySlug(query);
    if (prices) {
        dispatch({type: fetchReportPriceAllCitiesPrices.success, result: prices});
        return prices;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
import {formatISO, lastDayOfMonth, setDate, sub} from "date-fns";
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {getTypeForFetch, PricesViewType} from "../helpers/prices_view_type";
import {IAveragePricesQuery} from "../types/IAveragePricesQuery";
import {fetchAveragePricesBySlug} from "./fetch_average_prices";

const FETCH_REPORT_PRICE_ALL_CITIES_PRICES = "reports/FETCH_REPORT_PRICE_ALL_CITIES_PRICES";
export const fetchReportPriceAllCitiesPrices = createRequestActionTypes(FETCH_REPORT_PRICE_ALL_CITIES_PRICES);

export const fetchAllCitiesPrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceAllCitiesPrices.start});

    const date = new Date();
    const query: IAveragePricesQuery = {
        //first day of month, 6 months ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 1}), {representation: "date"}),
        //last day of month before date, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1})), {representation: "date"}),
        scenario: "rp-avg",
        offer_type: getTypeForFetch(PricesViewType.FLATS),
        slug_city: "all"
    };

    const prices = await fetchAveragePricesBySlug(query);
    if (prices) {
        dispatch({type: fetchReportPriceAllCitiesPrices.success, result: prices});
        return prices;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
