import {ISentLeadStoredValues} from "../types/ISentLeadStoredValues";

export const emptyLeadStorageData: ISentLeadStoredValues = {
    email: "",
    financing_services: false,
    name: "",
    new_property_notification_consent: false,
    phone: "",
    rooms: [],
    text: "",
    investment_services: false
};
import {ISentLeadStoredValues} from "../types/ISentLeadStoredValues";

export const emptyLeadStorageData: ISentLeadStoredValues = {
    email: "",
    financing_services: false,
    name: "",
    new_property_notification_consent: false,
    phone: "",
    rooms: [],
    text: "",
    investment_services: false
};
