// This file is autogenerated

export const validOfferApiParams = [
    "page",
    "page_size",
    "name",
    "type",
    "country",
    "construction_end_date",
    "offer_size",
    "facilities",
    "holiday_location",
    "is_holiday",
    "is_condohotel",
    "is_dedicated_for_rent",
    "has_investment",
    "has_investment_apartments",
    "vendor",
    "region",
    "price_lower_than_average",
    "distance",
    "distance_fluid",
    "force_distance",
    "lat",
    "lng",
    "near_by_offer",
    "exclude_offer",
    "display_type",
    "limited_presentation",
    "show_on_main_page",
    "show_on_listing",
    "with_promotions",
    "is_favourite",
    "is_hidden_favourite",
    "is_hidden",
    "is_application_sent",
    "is_application_hidden",
    "is_hidden_recommendation",
    "is_construction_ended",
    "homepage",
    "include_offer",
    "building",
    "building_uuid",
    "price_0",
    "price_1",
    "area_0",
    "area_1",
    "rooms_0",
    "rooms_1",
    "rooms_choice",
    "over_limit",
    "price_m2_0",
    "price_m2_1",
    "floor_0",
    "floor_1",
    "floor_choices",
    "additional_areas",
    "is_luxury",
    "offer",
    "for_sale",
    "include_pre_sale",
    "include_null_prices",
    "is_investment_apartment",
    "region_ancestor",
    "skip_recommendations",
    "for_recommendations",
    "exclude_property",
    "include_property",
    "offer_with_properties_roi",
    "exclude_compass_offers",
    "house_type",
    "house_additional_areas",
    "house_storeys",
    "pre_sale",
    "s",
    "sort"
];
export type ValidOfferApiParams =
    | "page"
    | "page_size"
    | "name"
    | "type"
    | "country"
    | "construction_end_date"
    | "offer_size"
    | "facilities"
    | "holiday_location"
    | "is_holiday"
    | "is_condohotel"
    | "is_dedicated_for_rent"
    | "has_investment"
    | "has_investment_apartments"
    | "vendor"
    | "region"
    | "price_lower_than_average"
    | "distance"
    | "distance_fluid"
    | "force_distance"
    | "lat"
    | "lng"
    | "near_by_offer"
    | "exclude_offer"
    | "display_type"
    | "limited_presentation"
    | "show_on_main_page"
    | "show_on_listing"
    | "with_promotions"
    | "is_favourite"
    | "is_hidden_favourite"
    | "is_hidden"
    | "is_application_sent"
    | "is_application_hidden"
    | "is_hidden_recommendation"
    | "is_construction_ended"
    | "homepage"
    | "include_offer"
    | "building"
    | "building_uuid"
    | "price_0"
    | "price_1"
    | "area_0"
    | "area_1"
    | "rooms_0"
    | "rooms_1"
    | "rooms_choice"
    | "over_limit"
    | "price_m2_0"
    | "price_m2_1"
    | "floor_0"
    | "floor_1"
    | "floor_choices"
    | "additional_areas"
    | "is_luxury"
    | "offer"
    | "for_sale"
    | "include_pre_sale"
    | "include_null_prices"
    | "is_investment_apartment"
    | "region_ancestor"
    | "skip_recommendations"
    | "for_recommendations"
    | "exclude_property"
    | "include_property"
    | "offer_with_properties_roi"
    | "exclude_compass_offers"
    | "house_type"
    | "house_additional_areas"
    | "house_storeys"
    | "pre_sale"
    | "s"
    | "sort";
// This file is autogenerated

export const validOfferApiParams = [
    "page",
    "page_size",
    "name",
    "type",
    "country",
    "construction_end_date",
    "offer_size",
    "facilities",
    "holiday_location",
    "is_holiday",
    "is_condohotel",
    "is_dedicated_for_rent",
    "has_investment",
    "has_investment_apartments",
    "vendor",
    "region",
    "price_lower_than_average",
    "distance",
    "distance_fluid",
    "force_distance",
    "lat",
    "lng",
    "near_by_offer",
    "exclude_offer",
    "display_type",
    "limited_presentation",
    "show_on_main_page",
    "show_on_listing",
    "with_promotions",
    "is_favourite",
    "is_hidden_favourite",
    "is_hidden",
    "is_application_sent",
    "is_application_hidden",
    "is_hidden_recommendation",
    "is_construction_ended",
    "homepage",
    "include_offer",
    "building",
    "building_uuid",
    "price_0",
    "price_1",
    "area_0",
    "area_1",
    "rooms_0",
    "rooms_1",
    "rooms_choice",
    "over_limit",
    "price_m2_0",
    "price_m2_1",
    "floor_0",
    "floor_1",
    "floor_choices",
    "additional_areas",
    "is_luxury",
    "offer",
    "for_sale",
    "include_pre_sale",
    "include_null_prices",
    "is_investment_apartment",
    "region_ancestor",
    "skip_recommendations",
    "for_recommendations",
    "exclude_property",
    "include_property",
    "offer_with_properties_roi",
    "exclude_compass_offers",
    "house_type",
    "house_additional_areas",
    "house_storeys",
    "pre_sale",
    "s",
    "sort"
];
export type ValidOfferApiParams =
    | "page"
    | "page_size"
    | "name"
    | "type"
    | "country"
    | "construction_end_date"
    | "offer_size"
    | "facilities"
    | "holiday_location"
    | "is_holiday"
    | "is_condohotel"
    | "is_dedicated_for_rent"
    | "has_investment"
    | "has_investment_apartments"
    | "vendor"
    | "region"
    | "price_lower_than_average"
    | "distance"
    | "distance_fluid"
    | "force_distance"
    | "lat"
    | "lng"
    | "near_by_offer"
    | "exclude_offer"
    | "display_type"
    | "limited_presentation"
    | "show_on_main_page"
    | "show_on_listing"
    | "with_promotions"
    | "is_favourite"
    | "is_hidden_favourite"
    | "is_hidden"
    | "is_application_sent"
    | "is_application_hidden"
    | "is_hidden_recommendation"
    | "is_construction_ended"
    | "homepage"
    | "include_offer"
    | "building"
    | "building_uuid"
    | "price_0"
    | "price_1"
    | "area_0"
    | "area_1"
    | "rooms_0"
    | "rooms_1"
    | "rooms_choice"
    | "over_limit"
    | "price_m2_0"
    | "price_m2_1"
    | "floor_0"
    | "floor_1"
    | "floor_choices"
    | "additional_areas"
    | "is_luxury"
    | "offer"
    | "for_sale"
    | "include_pre_sale"
    | "include_null_prices"
    | "is_investment_apartment"
    | "region_ancestor"
    | "skip_recommendations"
    | "for_recommendations"
    | "exclude_property"
    | "include_property"
    | "offer_with_properties_roi"
    | "exclude_compass_offers"
    | "house_type"
    | "house_additional_areas"
    | "house_storeys"
    | "pre_sale"
    | "s"
    | "sort";
