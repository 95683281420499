import {RequestActionTypes} from "./create_request_action_types";
import {IAction} from "./reduce_response";
import {RequestState} from "./request_state";

export const reduceRequestState =
    (requestTypes: RequestActionTypes) =>
    (state: RequestState = RequestState.None, action: IAction): RequestState => {
        switch (action.type) {
            case requestTypes.start:
                return RequestState.Waiting;
            case requestTypes.success:
                return RequestState.Success;
            case requestTypes.error:
                return RequestState.Error;
            case requestTypes.reset:
                return RequestState.None;
            default:
                return state;
        }
    };
import {RequestActionTypes} from "./create_request_action_types";
import {IAction} from "./reduce_response";
import {RequestState} from "./request_state";

export const reduceRequestState =
    (requestTypes: RequestActionTypes) =>
    (state: RequestState = RequestState.None, action: IAction): RequestState => {
        switch (action.type) {
            case requestTypes.start:
                return RequestState.Waiting;
            case requestTypes.success:
                return RequestState.Success;
            case requestTypes.error:
                return RequestState.Error;
            case requestTypes.reset:
                return RequestState.None;
            default:
                return state;
        }
    };
