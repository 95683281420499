import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IMapOfferSidebar} from "../../../offer/map/components/MapOfferSidebar";
import {fetchInvestmentOfferMapSelectedOfferTypes} from "../actions/fetch_investment_offer_map_offer_detail";

export interface IInvestmentOfferMapSelectedOfferStore {
    data: IMapOfferSidebar;
    requestState: RequestState;
}

export const investmentOfferMapSelectedOfferReducer = combineReducers({
    data: reduceResponse<IMapOfferSidebar>(fetchInvestmentOfferMapSelectedOfferTypes),
    requestState: reduceRequestState(fetchInvestmentOfferMapSelectedOfferTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IMapOfferSidebar} from "../../../offer/map/components/MapOfferSidebar";
import {fetchInvestmentOfferMapSelectedOfferTypes} from "../actions/fetch_investment_offer_map_offer_detail";

export interface IInvestmentOfferMapSelectedOfferStore {
    data: IMapOfferSidebar;
    requestState: RequestState;
}

export const investmentOfferMapSelectedOfferReducer = combineReducers({
    data: reduceResponse<IMapOfferSidebar>(fetchInvestmentOfferMapSelectedOfferTypes),
    requestState: reduceRequestState(fetchInvestmentOfferMapSelectedOfferTypes)
});
