import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchVendorDetailById} from "../../vendor/api/fetch_vendor_detail_by_id";

export const redirectVendorPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const vendorId = parseInt(ctx.match.params.vendorId as string, 10);

    if (isFinite(vendorId)) {
        const vendor = await fetchVendorDetailById(ctx.meta, vendorId);

        if (vendor) {
            const redirectUrl = appendQueryString(rpAppLink.vendor.detail.base({vendorSlug: vendor.slug, vendorId: vendor.id}), ctx.route.query);

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState, enable404ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchVendorDetailById} from "../../vendor/api/fetch_vendor_detail_by_id";

export const redirectVendorPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const vendorId = parseInt(ctx.match.params.vendorId as string, 10);

    if (isFinite(vendorId)) {
        const vendor = await fetchVendorDetailById(ctx.meta, vendorId);

        if (vendor) {
            const redirectUrl = appendQueryString(rpAppLink.vendor.detail.base({vendorSlug: vendor.slug, vendorId: vendor.id}), ctx.route.query);

            dispatch(enable301ResponseState(redirectUrl));

            return true;
        }
    }

    dispatch(enable404ResponseState());

    return true;
};
