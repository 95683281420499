export const colors = {
    primary: "#ebff00",
    secondary: "#23232d",
    background: {
        100: "#fafaf5",
        200: "#e1d7cd"
    },
    success: "#b9e19b",
    info: "#9bd7ff",
    highlight: "#d7afe1",
    warning: "#ffcda5",
    danger: "#ff385c",
    gray: {
        100: "#f9f9f9",
        200: "#ededee",
        300: "#e5e5e6",
        400: "#dcdcdd",
        500: "#d3d3d5",
        600: "#a7a7ab",
        700: "#7b7b81",
        800: "#4f4f57"
    }
};
export const colors = {
    primary: "#ebff00",
    secondary: "#23232d",
    background: {
        100: "#fafaf5",
        200: "#e1d7cd"
    },
    success: "#b9e19b",
    info: "#9bd7ff",
    highlight: "#d7afe1",
    warning: "#ffcda5",
    danger: "#ff385c",
    gray: {
        100: "#f9f9f9",
        200: "#ededee",
        300: "#e5e5e6",
        400: "#dcdcdd",
        500: "#d3d3d5",
        600: "#a7a7ab",
        700: "#7b7b81",
        800: "#4f4f57"
    }
};
