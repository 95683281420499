import {constructionEndDateOptions} from "../../../search/components/ConstructionEndDateSwitch";
import {floorOptions} from "../../../search/components/FloorChoicesSwitch";
import {OfferType} from "../../types/OfferType";

export const validateFetchOfferListSearchQuery = (query: Record<string, string | string[]>) => {
    let isQueryValid = true;

    const floorChoicesRange = floorOptions.map(({value}) => value.toString());
    const constructionEndDateRange = constructionEndDateOptions.map(({value}) => value.toString());

    // Validate construction end date
    if (query.construction_end_date && !constructionEndDateRange?.includes(query.construction_end_date as string)) {
        isQueryValid = false;
    }

    // Validate floor range
    if (query.floor_choices) {
        // User selected only one floor
        if (typeof query.floor_choices === "string") {
            isQueryValid = floorChoicesRange?.includes(query.floor_choices);
        } else {
            // User selected range of floors
            query.floor_choices.forEach((floor) => {
                if (!floorChoicesRange?.includes(floor)) {
                    isQueryValid = false;
                    return;
                }
            });
        }
    }

    if (query.house_type || query.house_storeys || query.house_additional_areas) {
        if (query.type?.toString() !== OfferType.HOUSE.toString()) {
            isQueryValid = false;
        }
    }

    return isQueryValid;
};
import {constructionEndDateOptions} from "../../../search/components/ConstructionEndDateSwitch";
import {floorOptions} from "../../../search/components/FloorChoicesSwitch";
import {OfferType} from "../../types/OfferType";

export const validateFetchOfferListSearchQuery = (query: Record<string, string | string[]>) => {
    let isQueryValid = true;

    const floorChoicesRange = floorOptions.map(({value}) => value.toString());
    const constructionEndDateRange = constructionEndDateOptions.map(({value}) => value.toString());

    // Validate construction end date
    if (query.construction_end_date && !constructionEndDateRange?.includes(query.construction_end_date as string)) {
        isQueryValid = false;
    }

    // Validate floor range
    if (query.floor_choices) {
        // User selected only one floor
        if (typeof query.floor_choices === "string") {
            isQueryValid = floorChoicesRange?.includes(query.floor_choices);
        } else {
            // User selected range of floors
            query.floor_choices.forEach((floor) => {
                if (!floorChoicesRange?.includes(floor)) {
                    isQueryValid = false;
                    return;
                }
            });
        }
    }

    if (query.house_type || query.house_storeys || query.house_additional_areas) {
        if (query.type?.toString() !== OfferType.HOUSE.toString()) {
            isQueryValid = false;
        }
    }

    return isQueryValid;
};
