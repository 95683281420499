import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchArticleCommentListTypes, IArticleComment} from "../actions/fetch_article_comment_list";

export interface IArticleCommentStore {
    list: IArticleComment[];
    listRequest: RequestState;
    requestState: RequestState;
}

export const articleCommentReducer = combineReducers({
    list: reduceResponse(fetchArticleCommentListTypes, []),
    listRequest: reduceRequestState(fetchArticleCommentListTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchArticleCommentListTypes, IArticleComment} from "../actions/fetch_article_comment_list";

export interface IArticleCommentStore {
    list: IArticleComment[];
    listRequest: RequestState;
    requestState: RequestState;
}

export const articleCommentReducer = combineReducers({
    list: reduceResponse(fetchArticleCommentListTypes, []),
    listRequest: reduceRequestState(fetchArticleCommentListTypes)
});
