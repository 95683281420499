import {IAction} from "@pg-mono/request-state";

import {manageUserPoiActionTypes} from "../actions/manage_user_poi";
import {IUserPoi} from "../types/IUserPoi";

export type UserPoiStore = IUserPoi[];

export interface IUserPoiAction extends IAction {
    payload: {
        id: number;
        poi: IUserPoi;
        pois: IUserPoi[];
    };
}

export const userPoiReducer = (state: UserPoiStore = [], action: IUserPoiAction) => {
    switch (action.type) {
        case manageUserPoiActionTypes.addUserPoi: {
            return state.concat(action.payload.poi);
        }
        case manageUserPoiActionTypes.editUserPoi: {
            return state.map((userPoi) => {
                if (userPoi.id === action.payload.id) {
                    return action.payload.poi;
                }
                return userPoi;
            });
        }
        case manageUserPoiActionTypes.removeUserPoi: {
            return state.filter(({id}) => id !== action.payload.id);
        }
        case manageUserPoiActionTypes.restoreUserPoi: {
            return action.payload.pois;
        }
        default:
            return state;
    }
};
import {IAction} from "@pg-mono/request-state";

import {manageUserPoiActionTypes} from "../actions/manage_user_poi";
import {IUserPoi} from "../types/IUserPoi";

export type UserPoiStore = IUserPoi[];

export interface IUserPoiAction extends IAction {
    payload: {
        id: number;
        poi: IUserPoi;
        pois: IUserPoi[];
    };
}

export const userPoiReducer = (state: UserPoiStore = [], action: IUserPoiAction) => {
    switch (action.type) {
        case manageUserPoiActionTypes.addUserPoi: {
            return state.concat(action.payload.poi);
        }
        case manageUserPoiActionTypes.editUserPoi: {
            return state.map((userPoi) => {
                if (userPoi.id === action.payload.id) {
                    return action.payload.poi;
                }
                return userPoi;
            });
        }
        case manageUserPoiActionTypes.removeUserPoi: {
            return state.filter(({id}) => id !== action.payload.id);
        }
        case manageUserPoiActionTypes.restoreUserPoi: {
            return action.payload.pois;
        }
        default:
            return state;
    }
};
