import {ISelectedRegion} from "../../../list/actions/offer_list_selected_data_actions";

export type ISelectedRegionParam = Pick<ISelectedRegion, "id" | "type" | "country"> & {
    parent?: null | number | string | {id?: number | string};
};

export const getRegionParentId = (region: ISelectedRegionParam) => {
    if (typeof region.parent === "object" && region.parent?.id) {
        return region.parent.id;
    }

    return region.parent;
};
import {ISelectedRegion} from "../../../list/actions/offer_list_selected_data_actions";

export type ISelectedRegionParam = Pick<ISelectedRegion, "id" | "type" | "country"> & {
    parent?: null | number | string | {id?: number | string};
};

export const getRegionParentId = (region: ISelectedRegionParam) => {
    if (typeof region.parent === "object" && region.parent?.id) {
        return region.parent.id;
    }

    return region.parent;
};
