import {isEqual, omit} from "@pg-mono/nodash";

import {IFetchContextRoute} from "./create_data_fetcher";

/**
 * Class used to control and block some unnecessary fetches. We can whitelist some URLSearchParam names that will be ignored
 * when determining if fetcher should be triggered or not.
 *
 * Should be used client side only.
 *
 * Example of fetch that could be ignored: user changes history state by opening a routed modal.
 */
export class RouteChangeValidator {
    /**
     * stores params to be ignored by fetcher
     * @private
     */
    private static _ignoredClientSideParams: string[] = [];

    /**
     * returns boolean if fetch should be called
     */
    public static validateChange = (route: IFetchContextRoute, prevRoute: IFetchContextRoute) => {
        if (!prevRoute) {
            // always fetch on server
            return true;
        }

        // validate path change
        const path = route.pathname;
        const prevPath = prevRoute.pathname;
        const isSamePath = path === prevPath;

        // validate query change
        const validPrevQuery = prevRoute?.query && omit(prevRoute?.query, this._ignoredClientSideParams);
        const validCurrentQuery = route.query && omit(route.query, this._ignoredClientSideParams);
        const isSameQuery = isEqual(validPrevQuery, validCurrentQuery);

        // allow fetching when parameters or path changes
        return !(isSameQuery && isSamePath);
    };

    /**
     * function registers param to be ignored by fetcher
     */
    public static registerIgnoredParam = (param: string) => {
        const hasParam = this._ignoredClientSideParams.includes(param);
        if (!hasParam) {
            this._ignoredClientSideParams.push(param);
        }
    };
}
import {isEqual, omit} from "@pg-mono/nodash";

import {IFetchContextRoute} from "./create_data_fetcher";

/**
 * Class used to control and block some unnecessary fetches. We can whitelist some URLSearchParam names that will be ignored
 * when determining if fetcher should be triggered or not.
 *
 * Should be used client side only.
 *
 * Example of fetch that could be ignored: user changes history state by opening a routed modal.
 */
export class RouteChangeValidator {
    /**
     * stores params to be ignored by fetcher
     * @private
     */
    private static _ignoredClientSideParams: string[] = [];

    /**
     * returns boolean if fetch should be called
     */
    public static validateChange = (route: IFetchContextRoute, prevRoute: IFetchContextRoute) => {
        if (!prevRoute) {
            // always fetch on server
            return true;
        }

        // validate path change
        const path = route.pathname;
        const prevPath = prevRoute.pathname;
        const isSamePath = path === prevPath;

        // validate query change
        const validPrevQuery = prevRoute?.query && omit(prevRoute?.query, this._ignoredClientSideParams);
        const validCurrentQuery = route.query && omit(route.query, this._ignoredClientSideParams);
        const isSameQuery = isEqual(validPrevQuery, validCurrentQuery);

        // allow fetching when parameters or path changes
        return !(isSameQuery && isSamePath);
    };

    /**
     * function registers param to be ignored by fetcher
     */
    public static registerIgnoredParam = (param: string) => {
        const hasParam = this._ignoredClientSideParams.includes(param);
        if (!hasParam) {
            this._ignoredClientSideParams.push(param);
        }
    };
}
