import {breakpoints, grid, IGridTheme} from "@pg-design/grid";
import {ITextTheme} from "@pg-design/text";

import {ICoreThemeDS} from "../interfaces/ICoreThemeDS";
import {colors} from "./colors";

export interface IThemeKM extends ICoreThemeDS, IGridTheme, ITextTheme {}

export const themeKM = {
    breakpoints,
    colors,
    globals: {
        body: {
            background: "#fff"
        },
        link: {
            color: colors.secondary,
            hover: {
                color: colors.highlight,
                decoration: "none"
            }
        },
        text: {
            color: colors.secondary,
            family: "Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif",
            lineHeight: "2rem",
            root: "10px",
            size: {
                desktop: "1.4rem",
                mobile: "1.2rem"
            },
            weight: 400
        },
        checkbox: {
            checkedColor: "#02d054"
        },
        base: 0.8,
        baseUnit: "0.8rem"
    },
    grid,
    transition: {
        timingFunction: "cubic-bezier(1, 0, 0, 1)",
        duration: "0.4s"
    },
    shadow: {
        0: "0 0 0 rgba(174, 177, 180, 0)",
        1: "0 4px 10px rgba(174, 177, 180, 0.3)",
        2: "0 8px 20px rgba(174, 177, 180, 0.25)",
        3: "0 16px 40px rgba(174, 177, 180, 0.2)",
        4: "0 16px 60px rgba(174, 177, 180, 0.6)",
        deep: " 0 4px 4px rgba(0, 0, 0, 0.25)"
    },
    text: {
        family: {
            primary: "'Poppins', sans-serif",
            secondary: "'Playfair_Display', serif"
        }
    },
    button: {
        primaryLabelColor: colors.secondary,
        highlightColor: colors.primary
    }
} satisfies IThemeKM;
import {breakpoints, grid, IGridTheme} from "@pg-design/grid";
import {ITextTheme} from "@pg-design/text";

import {ICoreThemeDS} from "../interfaces/ICoreThemeDS";
import {colors} from "./colors";

export interface IThemeKM extends ICoreThemeDS, IGridTheme, ITextTheme {}

export const themeKM = {
    breakpoints,
    colors,
    globals: {
        body: {
            background: "#fff"
        },
        link: {
            color: colors.secondary,
            hover: {
                color: colors.highlight,
                decoration: "none"
            }
        },
        text: {
            color: colors.secondary,
            family: "Poppins, 'Helvetica Neue', Helvetica, Arial, sans-serif",
            lineHeight: "2rem",
            root: "10px",
            size: {
                desktop: "1.4rem",
                mobile: "1.2rem"
            },
            weight: 400
        },
        checkbox: {
            checkedColor: "#02d054"
        },
        base: 0.8,
        baseUnit: "0.8rem"
    },
    grid,
    transition: {
        timingFunction: "cubic-bezier(1, 0, 0, 1)",
        duration: "0.4s"
    },
    shadow: {
        0: "0 0 0 rgba(174, 177, 180, 0)",
        1: "0 4px 10px rgba(174, 177, 180, 0.3)",
        2: "0 8px 20px rgba(174, 177, 180, 0.25)",
        3: "0 16px 40px rgba(174, 177, 180, 0.2)",
        4: "0 16px 60px rgba(174, 177, 180, 0.6)",
        deep: " 0 4px 4px rgba(0, 0, 0, 0.25)"
    },
    text: {
        family: {
            primary: "'Poppins', sans-serif",
            secondary: "'Playfair_Display', serif"
        }
    },
    button: {
        primaryLabelColor: colors.secondary,
        highlightColor: colors.primary
    }
} satisfies IThemeKM;
