import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const MapMarkerOutlineIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.977 1A2.976 2.976 0 0 0 2.69 5.882L4.641 8.54l.336.46 2.234-3.05A2.976 2.976 0 0 0 4.977 1Zm0 7.08L4.032 6.8 3.11 5.539l-.035-.046a2.438 2.438 0 1 1 3.805 0l-.008-.005L5.909 6.8l-.932 1.28Z" />
            <path d="M5.044 4.87a.976.976 0 1 0 0-1.952.976.976 0 0 0 0 1.952Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const MapMarkerOutlineIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.977 1A2.976 2.976 0 0 0 2.69 5.882L4.641 8.54l.336.46 2.234-3.05A2.976 2.976 0 0 0 4.977 1Zm0 7.08L4.032 6.8 3.11 5.539l-.035-.046a2.438 2.438 0 1 1 3.805 0l-.008-.005L5.909 6.8l-.932 1.28Z" />
            <path d="M5.044 4.87a.976.976 0 1 0 0-1.952.976.976 0 0 0 0 1.952Z" />
        </SvgIcon>
    );
};
