import React, {ReactNode} from "react";
import classNames from "classnames";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

import * as styles from "./ModalLayoutEmptyResponsive.module.css";

interface IProps {
    breakpoint?: string;
    children?: ReactNode;
    className?: string;
}

export const ModalLayoutEmptyResponsive = (props: IProps) => {
    return (
        <div className={classNames(props.className, styles.wrapper)}>
            <ModalCloseButton className={styles.modalCloseButtonStyle} />
            {props.children}
        </div>
    );
};
import React, {ReactNode} from "react";
import classNames from "classnames";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

import * as styles from "./ModalLayoutEmptyResponsive.module.css";

interface IProps {
    breakpoint?: string;
    children?: ReactNode;
    className?: string;
}

export const ModalLayoutEmptyResponsive = (props: IProps) => {
    return (
        <div className={classNames(props.className, styles.wrapper)}>
            <ModalCloseButton className={styles.modalCloseButtonStyle} />
            {props.children}
        </div>
    );
};
