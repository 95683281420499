import {PromotionType} from "./PromotionType";

export const promotionUrlConstants = {
    types: {
        "oferty-promocyjne": PromotionType.OFFER,
        "dni-otwarte": PromotionType.OPENDAYS,
        przeceny: PromotionType.DISCOUNT,
        "last-minute": PromotionType.LASTMINUTE,
        przedsprzedaz: PromotionType.PRESALE,
        "targi-mieszkaniowe": PromotionType.EXHIBITION
    } as Record<string, PromotionType>
};

export const knownPromotionQueryTypes = ["type", "region"];
import {PromotionType} from "./PromotionType";

export const promotionUrlConstants = {
    types: {
        "oferty-promocyjne": PromotionType.OFFER,
        "dni-otwarte": PromotionType.OPENDAYS,
        przeceny: PromotionType.DISCOUNT,
        "last-minute": PromotionType.LASTMINUTE,
        przedsprzedaz: PromotionType.PRESALE,
        "targi-mieszkaniowe": PromotionType.EXHIBITION
    } as Record<string, PromotionType>
};

export const knownPromotionQueryTypes = ["type", "region"];
