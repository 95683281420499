import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {fetchHomepageOfferListTypes} from "../actions/fetch_homepage_offer_list";

export interface IHomepageStore {
    count: number;
    recommendedOfferList: IOfferListOffer[];
    requestState: RequestState;
}

export const homepageReducer = combineReducers({
    recommendedOfferList: reduceResponseField<IOfferListOffer[]>(fetchHomepageOfferListTypes, "offerList", []),
    count: reduceResponseField<number>(fetchHomepageOfferListTypes, "count", null),
    requestState: reduceRequestState(fetchHomepageOfferListTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IOfferListOffer} from "../../offer/types/IOfferListOffer";
import {fetchHomepageOfferListTypes} from "../actions/fetch_homepage_offer_list";

export interface IHomepageStore {
    count: number;
    recommendedOfferList: IOfferListOffer[];
    requestState: RequestState;
}

export const homepageReducer = combineReducers({
    recommendedOfferList: reduceResponseField<IOfferListOffer[]>(fetchHomepageOfferListTypes, "offerList", []),
    count: reduceResponseField<number>(fetchHomepageOfferListTypes, "count", null),
    requestState: reduceRequestState(fetchHomepageOfferListTypes)
});
