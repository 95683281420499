import {useContext} from "react";

import {WeglotContext} from "../context/WeglotContextProvider";

export function useWeglotContext() {
    const weglotContextValue = useContext(WeglotContext);

    if (weglotContextValue === null) {
        throw new Error("WeglotContextProvider is required");
    }

    return weglotContextValue;
}
import {useContext} from "react";

import {WeglotContext} from "../context/WeglotContextProvider";

export function useWeglotContext() {
    const weglotContextValue = useContext(WeglotContext);

    if (weglotContextValue === null) {
        throw new Error("WeglotContextProvider is required");
    }

    return weglotContextValue;
}
