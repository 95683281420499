import {createFormActions, createFormActionTypes} from "@pg-mono/form";

const PROMOTION_LIST_FILTER_FORM_ACTION = "promotion_list_filters/FORM";
export const promotionListFilterFormActionTypes = createFormActionTypes(PROMOTION_LIST_FILTER_FORM_ACTION);
export const promotionListFilterFormActions = createFormActions(promotionListFilterFormActionTypes);
import {createFormActions, createFormActionTypes} from "@pg-mono/form";

const PROMOTION_LIST_FILTER_FORM_ACTION = "promotion_list_filters/FORM";
export const promotionListFilterFormActionTypes = createFormActionTypes(PROMOTION_LIST_FILTER_FORM_ACTION);
export const promotionListFilterFormActions = createFormActions(promotionListFilterFormActionTypes);
