import {FormActionTypes, IFormValuesAction} from "./create_form_action_types";

interface IAction {
    type: string;
}

export interface FormActions<T> {
    clear: () => IAction; // clears entire form
    update: (formValues: Partial<T>) => IFormValuesAction<T>; // updates only given fields
    replace: (formValues: T) => IFormValuesAction<T>; // replaces entire form with given data
}

export const createFormActions = <T>(formTypes: FormActionTypes): FormActions<T> => ({
    clear: (): IAction => ({type: formTypes.clear}),
    update: (formValues: Partial<T>): IFormValuesAction<T> => ({type: formTypes.update, formValues}),
    replace: (formValues: T): IFormValuesAction<T> => ({type: formTypes.replace, formValues})
});
import {FormActionTypes, IFormValuesAction} from "./create_form_action_types";

interface IAction {
    type: string;
}

export interface FormActions<T> {
    clear: () => IAction; // clears entire form
    update: (formValues: Partial<T>) => IFormValuesAction<T>; // updates only given fields
    replace: (formValues: T) => IFormValuesAction<T>; // replaces entire form with given data
}

export const createFormActions = <T>(formTypes: FormActionTypes): FormActions<T> => ({
    clear: (): IAction => ({type: formTypes.clear}),
    update: (formValues: Partial<T>): IFormValuesAction<T> => ({type: formTypes.update, formValues}),
    replace: (formValues: T): IFormValuesAction<T> => ({type: formTypes.replace, formValues})
});
