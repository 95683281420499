import {ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {FlagGbIcon, FlagPlIcon, FlagRuIcon, FlagUaIcon, IIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useWeglotContext} from "../hooks/use_weglot_context";
import {RwdSelect} from "./RwdSelect";

interface ISetWidthBreakpoints {
    setWidthBreakpoints?: {min?: string; max?: string};
}

interface IProps extends ISetWidthBreakpoints {
    isMobile: boolean;
    onLangChange?: (langCode: string) => void;
}

export function WeglotLangSwitcher(props: IProps) {
    const {isMobile, onLangChange, setWidthBreakpoints} = props;
    const {weglot} = useWeglotContext();
    const theme = useTheme();

    const widthBreakpoint = {min: setWidthBreakpoints?.min || theme.breakpoints.md, max: setWidthBreakpoints?.max || theme.breakpoints.lg};
    const langOptionsRaw: {value: string; flag: React.FC<IIcon>; text: string}[] = [
        {value: "pl", flag: FlagPlIcon, text: "Polski"},
        {value: "en", flag: FlagGbIcon, text: "English"},
        {value: "uk", flag: FlagUaIcon, text: "Yкраїнська"},
        {value: "ru", flag: FlagRuIcon, text: "Русский"}
    ];

    const langOptions: {value: string; label: ReactNode}[] = langOptionsRaw.map((langOption) => {
        const FlagIcon = langOption.flag;

        return {
            value: langOption.value,
            label: (
                <div css={langRow}>
                    <FlagIcon size="2.8" wrapperSize="2.8" wrapperColor="transparent" />
                    <Text variant="body_copy_1">{langOption.text}</Text>
                </div>
            )
        };
    });

    if (!weglot) {
        return null;
    }

    return (
        <LangSwitcher setWidthBreakpoints={{...widthBreakpoint}} className="lang-switcher-wrap">
            <RwdSelect
                isMobile={isMobile}
                initialValue={weglot.getCurrentLang()}
                instanceId="weglot-lang-switcher"
                menuPlacement="auto"
                menuPosition="fixed"
                radioName="lang"
                options={langOptions}
                onChange={(newValue) => {
                    const newLangCode = newValue.value;

                    weglot.switchTo(newLangCode);

                    if (onLangChange) {
                        onLangChange(newLangCode);
                    }
                }}
                styles={{
                    container: (styles) => ({
                        ...styles,
                        minWidth: "auto !important"
                    }),
                    menu: (styles) => ({
                        ...styles,
                        width: "max-content",
                        minWidth: "100%"
                    }),
                    indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none"
                    })
                }}
                css={langSelect}
            />
        </LangSwitcher>
    );
}

const langRow = css`
    display: flex;
    column-gap: ${crs(1)};
    align-items: center;
`;

const langSelect = css`
    min-width: 210px;
`;

const LangSwitcher = styled.div<ISetWidthBreakpoints>`
    min-width: 80px;

    @media (min-width: ${(props) => props.setWidthBreakpoints?.min && props.setWidthBreakpoints.min}) and (max-width: ${(props) =>
            props.setWidthBreakpoints?.max && props.setWidthBreakpoints.max}) {
        width: 80px;
    }
`;
import {ReactNode} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs} from "@pg-design/helpers-css";
import {FlagGbIcon, FlagPlIcon, FlagRuIcon, FlagUaIcon, IIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {useWeglotContext} from "../hooks/use_weglot_context";
import {RwdSelect} from "./RwdSelect";

interface ISetWidthBreakpoints {
    setWidthBreakpoints?: {min?: string; max?: string};
}

interface IProps extends ISetWidthBreakpoints {
    isMobile: boolean;
    onLangChange?: (langCode: string) => void;
}

export function WeglotLangSwitcher(props: IProps) {
    const {isMobile, onLangChange, setWidthBreakpoints} = props;
    const {weglot} = useWeglotContext();
    const theme = useTheme();

    const widthBreakpoint = {min: setWidthBreakpoints?.min || theme.breakpoints.md, max: setWidthBreakpoints?.max || theme.breakpoints.lg};
    const langOptionsRaw: {value: string; flag: React.FC<IIcon>; text: string}[] = [
        {value: "pl", flag: FlagPlIcon, text: "Polski"},
        {value: "en", flag: FlagGbIcon, text: "English"},
        {value: "uk", flag: FlagUaIcon, text: "Yкраїнська"},
        {value: "ru", flag: FlagRuIcon, text: "Русский"}
    ];

    const langOptions: {value: string; label: ReactNode}[] = langOptionsRaw.map((langOption) => {
        const FlagIcon = langOption.flag;

        return {
            value: langOption.value,
            label: (
                <div css={langRow}>
                    <FlagIcon size="2.8" wrapperSize="2.8" wrapperColor="transparent" />
                    <Text variant="body_copy_1">{langOption.text}</Text>
                </div>
            )
        };
    });

    if (!weglot) {
        return null;
    }

    return (
        <LangSwitcher setWidthBreakpoints={{...widthBreakpoint}} className="lang-switcher-wrap">
            <RwdSelect
                isMobile={isMobile}
                initialValue={weglot.getCurrentLang()}
                instanceId="weglot-lang-switcher"
                menuPlacement="auto"
                menuPosition="fixed"
                radioName="lang"
                options={langOptions}
                onChange={(newValue) => {
                    const newLangCode = newValue.value;

                    weglot.switchTo(newLangCode);

                    if (onLangChange) {
                        onLangChange(newLangCode);
                    }
                }}
                styles={{
                    container: (styles) => ({
                        ...styles,
                        minWidth: "auto !important"
                    }),
                    menu: (styles) => ({
                        ...styles,
                        width: "max-content",
                        minWidth: "100%"
                    }),
                    indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none"
                    })
                }}
                css={langSelect}
            />
        </LangSwitcher>
    );
}

const langRow = css`
    display: flex;
    column-gap: ${crs(1)};
    align-items: center;
`;

const langSelect = css`
    min-width: 210px;
`;

const LangSwitcher = styled.div<ISetWidthBreakpoints>`
    min-width: 80px;

    @media (min-width: ${(props) => props.setWidthBreakpoints?.min && props.setWidthBreakpoints.min}) and (max-width: ${(props) =>
            props.setWidthBreakpoints?.max && props.setWidthBreakpoints.max}) {
        width: 80px;
    }
`;
