import {combineReducers} from "redux";

import {IUserPreferencesStore, userPreferencesReducer} from "./user_preferences_reducer";
import {IUserProfileStore, userProfileReducer} from "./user_profile_reducer";
import {IUserRecommendationsStateStore, userRecommendationsStateReducer} from "./user_recommendation_state_reducer";

export interface IUserStore {
    preferences?: IUserPreferencesStore;
    profile: IUserProfileStore;
    recommendationsState: IUserRecommendationsStateStore;
}

export const userReducer = combineReducers({
    preferences: userPreferencesReducer,
    profile: userProfileReducer,
    recommendationsState: userRecommendationsStateReducer
});
import {combineReducers} from "redux";

import {IUserPreferencesStore, userPreferencesReducer} from "./user_preferences_reducer";
import {IUserProfileStore, userProfileReducer} from "./user_profile_reducer";
import {IUserRecommendationsStateStore, userRecommendationsStateReducer} from "./user_recommendation_state_reducer";

export interface IUserStore {
    preferences?: IUserPreferencesStore;
    profile: IUserProfileStore;
    recommendationsState: IUserRecommendationsStateStore;
}

export const userReducer = combineReducers({
    preferences: userPreferencesReducer,
    profile: userProfileReducer,
    recommendationsState: userRecommendationsStateReducer
});
