import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectToFriendlyUrl} from "../../list/actions/redirect_to_friendly_url";

export const redirectToFriendlyUrlForNoMultiRegion = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (!ctx.route.query.region || !Array.isArray(ctx.route.query.region)) {
        return redirectToFriendlyUrl(ctx)(dispatch);
    }
    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectToFriendlyUrl} from "../../list/actions/redirect_to_friendly_url";

export const redirectToFriendlyUrlForNoMultiRegion = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (!ctx.route.query.region || !Array.isArray(ctx.route.query.region)) {
        return redirectToFriendlyUrl(ctx)(dispatch);
    }
    return true;
};
