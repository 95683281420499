import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchMyApplicationsList} from "../../../my_offers/api/fetch_my_applications_list";

const fetchAppliedOffers = "map/FETCH_APPLIED_OFFERS";
export const fetchAppliedOffersTypes = createRequestActionTypes(fetchAppliedOffers);

export const fetchAppliedOffersAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchAppliedOffersTypes.start});

    const isAuthenticated = getState().isAuthenticated;

    if (!isAuthenticated) {
        return true; // Do not fetch data for not auth users, but proceed with rest of the fetchers
    }

    return fetchMyApplicationsList(ctx.meta)
        .then((response) => {
            return (response.results || []).reduce<number[]>((acc, application) => {
                if (application.offer && !acc.includes(application.offer)) {
                    acc.push(application.offer);
                }

                return acc;
            }, []);
        })
        .then((offers) => {
            dispatch({type: fetchAppliedOffersTypes.success, result: offers});

            return true;
        });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchMyApplicationsList} from "../../../my_offers/api/fetch_my_applications_list";

const fetchAppliedOffers = "map/FETCH_APPLIED_OFFERS";
export const fetchAppliedOffersTypes = createRequestActionTypes(fetchAppliedOffers);

export const fetchAppliedOffersAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    dispatch({type: fetchAppliedOffersTypes.start});

    const isAuthenticated = getState().isAuthenticated;

    if (!isAuthenticated) {
        return true; // Do not fetch data for not auth users, but proceed with rest of the fetchers
    }

    return fetchMyApplicationsList(ctx.meta)
        .then((response) => {
            return (response.results || []).reduce<number[]>((acc, application) => {
                if (application.offer && !acc.includes(application.offer)) {
                    acc.push(application.offer);
                }

                return acc;
            }, []);
        })
        .then((offers) => {
            dispatch({type: fetchAppliedOffersTypes.success, result: offers});

            return true;
        });
};
