import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {noop} from "@pg-mono/nodash";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export interface IInvestmentOfferSeoContent {
    holiday_location: number | null;
    region: number | null;
    seo_description: string;
    type: number;
}

/*
 * TODO: Next API version will be flat without `for_investor_search` field - all fields from `for_investor_search` will be
 *  available on higher level. For now we need to handle both versions.
 */
interface IInvestmentOfferSeoContentResponse extends IInvestmentOfferSeoContent {
    for_investor_search: IInvestmentOfferSeoContent;
}

const FETCH_INVESTMENT_OFFER_LIST_SEO_CONTENT = "investment_offer_list/SEO_CONTENT";
export const fetchInvestmentOfferSeoContentActionTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_LIST_SEO_CONTENT);

const investmentOfferSeoContentApiLink = apiLink.search.for_investor({});

export const fetchInvestmentOfferSeoContent =
    (query: Record<string, string | number | undefined | string[] | boolean>, ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
        dispatch({type: fetchInvestmentOfferSeoContentActionTypes.start});

        const fullQuery = {
            ...buildSeoContentQuery(query)
        };

        const url = appendQueryString(investmentOfferSeoContentApiLink(null), fullQuery);
        return getRequest(ctx.meta, url)
            .then(async (json: IInvestmentOfferSeoContentResponse) => {
                dispatch({type: fetchInvestmentOfferSeoContentActionTypes.success, result: json.for_investor_search || json});
                return json;
            })
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchInvestmentOfferSeoContentActionTypes.error, error});
                })
            );
    };

const buildSeoContentQuery = (query: Record<string, string | number | undefined | string[] | boolean>) => ({
    ...(query.region && {region: query.region}),
    ...(query.holiday_location && {holiday_location: query.holiday_location}),
    ...(typeof query.is_holiday !== "undefined" && !query.is_dedicated_for_rent && !query.is_investment_apartment && !query.is_condohotel && {type: 1}),
    ...(query.is_dedicated_for_rent && {type: 2}),
    ...(query.is_condohotel && {type: 3}),
    /*
     * If `query.country` is an array - then we need to fetch only SEO content for category.
     * Array for `query.country` means that we are on general abroad offers listing - without selected country.
     */
    ...(query.country && {type: 4, country: Array.isArray(query.country) ? noop() : query.country})
});
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {noop} from "@pg-mono/nodash";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export interface IInvestmentOfferSeoContent {
    holiday_location: number | null;
    region: number | null;
    seo_description: string;
    type: number;
}

/*
 * TODO: Next API version will be flat without `for_investor_search` field - all fields from `for_investor_search` will be
 *  available on higher level. For now we need to handle both versions.
 */
interface IInvestmentOfferSeoContentResponse extends IInvestmentOfferSeoContent {
    for_investor_search: IInvestmentOfferSeoContent;
}

const FETCH_INVESTMENT_OFFER_LIST_SEO_CONTENT = "investment_offer_list/SEO_CONTENT";
export const fetchInvestmentOfferSeoContentActionTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_LIST_SEO_CONTENT);

const investmentOfferSeoContentApiLink = apiLink.search.for_investor({});

export const fetchInvestmentOfferSeoContent =
    (query: Record<string, string | number | undefined | string[] | boolean>, ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
        dispatch({type: fetchInvestmentOfferSeoContentActionTypes.start});

        const fullQuery = {
            ...buildSeoContentQuery(query)
        };

        const url = appendQueryString(investmentOfferSeoContentApiLink(null), fullQuery);
        return getRequest(ctx.meta, url)
            .then(async (json: IInvestmentOfferSeoContentResponse) => {
                dispatch({type: fetchInvestmentOfferSeoContentActionTypes.success, result: json.for_investor_search || json});
                return json;
            })
            .catch(
                catch404(async (error) => {
                    dispatch({type: fetchInvestmentOfferSeoContentActionTypes.error, error});
                })
            );
    };

const buildSeoContentQuery = (query: Record<string, string | number | undefined | string[] | boolean>) => ({
    ...(query.region && {region: query.region}),
    ...(query.holiday_location && {holiday_location: query.holiday_location}),
    ...(typeof query.is_holiday !== "undefined" && !query.is_dedicated_for_rent && !query.is_investment_apartment && !query.is_condohotel && {type: 1}),
    ...(query.is_dedicated_for_rent && {type: 2}),
    ...(query.is_condohotel && {type: 3}),
    /*
     * If `query.country` is an array - then we need to fetch only SEO content for category.
     * Array for `query.country` means that we are on general abroad offers listing - without selected country.
     */
    ...(query.country && {type: 4, country: Array.isArray(query.country) ? noop() : query.country})
});
