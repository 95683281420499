import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_VENDOR_DETAIL_CITIES_PREFIX = "desktop_vendor_detail/FETCH_VENDOR_DETAIL_CITIES_PREFIX";
export const fetchVendorDetailCitiesTypes = createRequestActionTypes(FETCH_VENDOR_DETAIL_CITIES_PREFIX);

export interface IVendorDetailCity {
    // TODO: update interface and add new scenario
    id: number;
    name: string;
}

interface IVendorDetailCityResponse {
    results: IVendorDetailCity[];
}

const citiesListApiLink = apiV2ListLink.region.list(Scenario.VENDOR_DETAIL_CITIES_LIST);

export const fetchVendorDetailCitiesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorDetailCitiesTypes.start});

    const url = appendQueryString(citiesListApiLink, {
        active_offers: true,
        offer_vendor: ctx.match.params.vendorId,
        with_ancestors: 2,
        ancestor_type: 4,
        offer_types: [1, 2]
    });

    return getRequest(ctx.meta, url).then((res: IVendorDetailCityResponse) => {
        dispatch({type: fetchVendorDetailCitiesTypes.success, result: res.results});
        return res;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_VENDOR_DETAIL_CITIES_PREFIX = "desktop_vendor_detail/FETCH_VENDOR_DETAIL_CITIES_PREFIX";
export const fetchVendorDetailCitiesTypes = createRequestActionTypes(FETCH_VENDOR_DETAIL_CITIES_PREFIX);

export interface IVendorDetailCity {
    // TODO: update interface and add new scenario
    id: number;
    name: string;
}

interface IVendorDetailCityResponse {
    results: IVendorDetailCity[];
}

const citiesListApiLink = apiV2ListLink.region.list(Scenario.VENDOR_DETAIL_CITIES_LIST);

export const fetchVendorDetailCitiesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchVendorDetailCitiesTypes.start});

    const url = appendQueryString(citiesListApiLink, {
        active_offers: true,
        offer_vendor: ctx.match.params.vendorId,
        with_ancestors: 2,
        ancestor_type: 4,
        offer_types: [1, 2]
    });

    return getRequest(ctx.meta, url).then((res: IVendorDetailCityResponse) => {
        dispatch({type: fetchVendorDetailCitiesTypes.success, result: res.results});
        return res;
    });
};
