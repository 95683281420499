import {Country} from "../../region/types/Country";
import {SearchTab} from "./fetch_search_all_action";

export interface IUpdateCurrentTabAction {
    type: string;
    payload: {
        currentTab: SearchTab;
        country?: Country;
    };
}

export const UPDATE_CURRENT_TAB = "search_engine/UPDATE_CURRENT_TAB";
export const updateCurrentTab = (currentTab: SearchTab, country?: Country) => ({type: UPDATE_CURRENT_TAB, payload: {currentTab, country}});
import {Country} from "../../region/types/Country";
import {SearchTab} from "./fetch_search_all_action";

export interface IUpdateCurrentTabAction {
    type: string;
    payload: {
        currentTab: SearchTab;
        country?: Country;
    };
}

export const UPDATE_CURRENT_TAB = "search_engine/UPDATE_CURRENT_TAB";
export const updateCurrentTab = (currentTab: SearchTab, country?: Country) => ({type: UPDATE_CURRENT_TAB, payload: {currentTab, country}});
