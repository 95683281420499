export enum RegionType {
    VOIVODESHIP = 1,
    AGGREGATION = 2,
    COUNTY = 3,
    CITY = 4,
    TOWN = 5,
    DISTRICT = 6,
    NEIGHBORHOOD = 7,
    SURROUNDINGS = 8
}
export enum RegionType {
    VOIVODESHIP = 1,
    AGGREGATION = 2,
    COUNTY = 3,
    CITY = 4,
    TOWN = 5,
    DISTRICT = 6,
    NEIGHBORHOOD = 7,
    SURROUNDINGS = 8
}
