import {css} from "@emotion/react";

import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

export const mb = (size: Size = DEFAULT_SIZE) => css`
    margin-bottom: ${calculateRemSize(size)};
`;

export const mt = (size: Size = DEFAULT_SIZE) => css`
    margin-top: ${calculateRemSize(size)};
`;

export const ml = (size: Size = DEFAULT_SIZE) => css`
    margin-left: ${calculateRemSize(size)};
`;

// size is multiplied by base size which is 0.8rem
export const mr = (size: Size = DEFAULT_SIZE) => css`
    margin-right: ${calculateRemSize(size)};
`;

// size is multiplied by base size which is 0.8rem
export const mv = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return css`
        margin-bottom: ${value};
        margin-top: ${value};
    `;
};

// size is multiplied by base size which is 0.8rem
export const mh = (size: Size | "auto" = DEFAULT_SIZE) => {
    const value = size === "auto" ? "auto" : calculateRemSize(size);

    return css`
        margin-left: ${value};
        margin-right: ${value};
    `;
};

// each size is multiplied by base size which is 0.8rem
export const m = (...sizeArgs: [] | [Size | "auto"] | [Size, Size | "auto"] | [Size, Size | "auto", Size] | [Size, Size | "auto", Size, Size | "auto"]) => {
    if (sizeArgs.length > 0) {
        return css`
            margin: ${sizeArgs.map((size) => (size === "auto" ? "auto" : calculateRemSize(size))).join(" ")};
        `;
    }

    return css`
        margin: ${sizeArgs[0] === "auto" ? "auto" : calculateRemSize(sizeArgs[0])};
    `;
};
import {css} from "@emotion/react";

import {calculateRemSize, DEFAULT_SIZE, Size} from "./utils/rem";

export const mb = (size: Size = DEFAULT_SIZE) => css`
    margin-bottom: ${calculateRemSize(size)};
`;

export const mt = (size: Size = DEFAULT_SIZE) => css`
    margin-top: ${calculateRemSize(size)};
`;

export const ml = (size: Size = DEFAULT_SIZE) => css`
    margin-left: ${calculateRemSize(size)};
`;

// size is multiplied by base size which is 0.8rem
export const mr = (size: Size = DEFAULT_SIZE) => css`
    margin-right: ${calculateRemSize(size)};
`;

// size is multiplied by base size which is 0.8rem
export const mv = (size: Size = DEFAULT_SIZE) => {
    const value = calculateRemSize(size);

    return css`
        margin-bottom: ${value};
        margin-top: ${value};
    `;
};

// size is multiplied by base size which is 0.8rem
export const mh = (size: Size | "auto" = DEFAULT_SIZE) => {
    const value = size === "auto" ? "auto" : calculateRemSize(size);

    return css`
        margin-left: ${value};
        margin-right: ${value};
    `;
};

// each size is multiplied by base size which is 0.8rem
export const m = (...sizeArgs: [] | [Size | "auto"] | [Size, Size | "auto"] | [Size, Size | "auto", Size] | [Size, Size | "auto", Size, Size | "auto"]) => {
    if (sizeArgs.length > 0) {
        return css`
            margin: ${sizeArgs.map((size) => (size === "auto" ? "auto" : calculateRemSize(size))).join(" ")};
        `;
    }

    return css`
        margin: ${sizeArgs[0] === "auto" ? "auto" : calculateRemSize(sizeArgs[0])};
    `;
};
