import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CloseIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 2H7.512l-2.51 2.516L2.484 2 1 2.002l3.255 3.255-3.251 3.25h1.484l2.508-2.501 2.506 2.502H8.99L5.745 5.265 9 2Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CloseIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 2H7.512l-2.51 2.516L2.484 2 1 2.002l3.255 3.255-3.251 3.25h1.484l2.508-2.501 2.506 2.502H8.99L5.745 5.265 9 2Z" />
        </SvgIcon>
    );
};
