import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {DEFAULT_DISTANCE} from "../../../offer/list/constants/offer_list";
import {IPaginatedResponse} from "../../../request/IResponse";

const INVESTMENT_OFFER_LIST_PROPERTIES_COUNT_PREFIX = "investment_offer_list/property_list/PROPERTIES_COUNT";
export const fetchInvestmentOffersPropertiesCountTypes = createRequestActionTypes(INVESTMENT_OFFER_LIST_PROPERTIES_COUNT_PREFIX);

const propertyListApiLink = apiV2Link.property.list(Scenario.PROPERTY_LIST);

export const fetchInvestmentOffersPropertiesCount =
    (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, string | string[] | number | number[] | boolean | undefined>) =>
    async (dispatch: Dispatch): Promise<number | null> => {
        dispatch({type: fetchInvestmentOffersPropertiesCountTypes.start});

        // get rid of unnecessary query params
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {page, ...validQuery} = query;
        const fullQuery = {
            distance: DEFAULT_DISTANCE,
            ...validQuery,
            page_size: 0 // this speeds up backend response to get only count
        };

        // save latest query and
        const url = appendQueryString(propertyListApiLink, fullQuery);
        // services connected with favourites, endpoint might return different values
        return getRequest(ctx.meta, url)
            .then((json: IPaginatedResponse) => {
                dispatch({type: fetchInvestmentOffersPropertiesCountTypes.success, result: json.count});
                return json.count;
            })
            .catch(
                catch400(() => {
                    dispatch({type: fetchInvestmentOffersPropertiesCountTypes.error});
                    return null;
                })
            );
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {DEFAULT_DISTANCE} from "../../../offer/list/constants/offer_list";
import {IPaginatedResponse} from "../../../request/IResponse";

const INVESTMENT_OFFER_LIST_PROPERTIES_COUNT_PREFIX = "investment_offer_list/property_list/PROPERTIES_COUNT";
export const fetchInvestmentOffersPropertiesCountTypes = createRequestActionTypes(INVESTMENT_OFFER_LIST_PROPERTIES_COUNT_PREFIX);

const propertyListApiLink = apiV2Link.property.list(Scenario.PROPERTY_LIST);

export const fetchInvestmentOffersPropertiesCount =
    (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, string | string[] | number | number[] | boolean | undefined>) =>
    async (dispatch: Dispatch): Promise<number | null> => {
        dispatch({type: fetchInvestmentOffersPropertiesCountTypes.start});

        // get rid of unnecessary query params
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {page, ...validQuery} = query;
        const fullQuery = {
            distance: DEFAULT_DISTANCE,
            ...validQuery,
            page_size: 0 // this speeds up backend response to get only count
        };

        // save latest query and
        const url = appendQueryString(propertyListApiLink, fullQuery);
        // services connected with favourites, endpoint might return different values
        return getRequest(ctx.meta, url)
            .then((json: IPaginatedResponse) => {
                dispatch({type: fetchInvestmentOffersPropertiesCountTypes.success, result: json.count});
                return json.count;
            })
            .catch(
                catch400(() => {
                    dispatch({type: fetchInvestmentOffersPropertiesCountTypes.error});
                    return null;
                })
            );
    };
