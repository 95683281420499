import {combineReducers} from "redux";

import {reduceReducers, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {ADD_APPLIED_OFFERS_TYPE} from "../actions/add_applied_offers";
import {fetchAppliedOffersTypes} from "../actions/fetch_applied_offers";

export interface IAppliedOffersStore {
    data: number[];
    requestState: RequestState;
}

const extendedAppliedOffersReducer = (state: number[] = [], action: {type: string; payload: number[]}) => {
    switch (action.type) {
        case ADD_APPLIED_OFFERS_TYPE:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

// TODO: This reducer should be a part of the my_offers_reducer - it is not a map thing, we need this data at least in two places.
export const appliedOffersReducer = combineReducers({
    data: reduceReducers(reduceResponse<number[]>(fetchAppliedOffersTypes, []), extendedAppliedOffersReducer),
    requestState: reduceRequestState(fetchAppliedOffersTypes)
});
import {combineReducers} from "redux";

import {reduceReducers, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {ADD_APPLIED_OFFERS_TYPE} from "../actions/add_applied_offers";
import {fetchAppliedOffersTypes} from "../actions/fetch_applied_offers";

export interface IAppliedOffersStore {
    data: number[];
    requestState: RequestState;
}

const extendedAppliedOffersReducer = (state: number[] = [], action: {type: string; payload: number[]}) => {
    switch (action.type) {
        case ADD_APPLIED_OFFERS_TYPE:
            return [...state, ...action.payload];
        default:
            return state;
    }
};

// TODO: This reducer should be a part of the my_offers_reducer - it is not a map thing, we need this data at least in two places.
export const appliedOffersReducer = combineReducers({
    data: reduceReducers(reduceResponse<number[]>(fetchAppliedOffersTypes, []), extendedAppliedOffersReducer),
    requestState: reduceRequestState(fetchAppliedOffersTypes)
});
