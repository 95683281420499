import {Country} from "../../../../region/types/Country";
import {RegionType} from "../../../../region/types/RegionType";
import {aggregationToCityMap} from "../../constants/aggregation_to_city_map";
import {getRegionParentId, ISelectedRegionParam} from "./get_region_parent_id";

export const getMainCityBySelectedRegions = (selectedRegion: ISelectedRegionParam[]) => {
    if (selectedRegion.length === 0) {
        return null;
    }

    if (selectedRegion.some(({country}) => country && country !== Country.POLAND)) {
        // disable foreign regions
        return null;
    }

    // only one region is selected and it is a city
    if (selectedRegion.length === 1 && selectedRegion[0].type === RegionType.CITY) {
        return selectedRegion[0].id;
    }

    // only one region is selected and can be mapped to city by aggregation map
    if (selectedRegion.length === 1 && aggregationToCityMap.has(selectedRegion[0].id)) {
        return aggregationToCityMap.get(selectedRegion[0].id) as number;
    }

    // all regions are districts or surrounding from the same city
    if (
        selectedRegion.every(
            (region) =>
                (region.type === RegionType.DISTRICT || region.type === RegionType.SURROUNDINGS) &&
                getRegionParentId(region) === getRegionParentId(selectedRegion[0])
        )
    ) {
        const parentRegionId = getRegionParentId(selectedRegion[0]);

        if (parentRegionId) {
            return getRegionParentId(selectedRegion[0]) as number;
        }
    }

    // only one city has been selected with surroundings
    const mainCity = selectedRegion.find((region) => region.type === RegionType.CITY);
    if (mainCity && selectedRegion.every((region) => getRegionParentId(region) === mainCity.id || region.id === mainCity.id)) {
        return mainCity.id;
    }

    return null;
};
import {Country} from "../../../../region/types/Country";
import {RegionType} from "../../../../region/types/RegionType";
import {aggregationToCityMap} from "../../constants/aggregation_to_city_map";
import {getRegionParentId, ISelectedRegionParam} from "./get_region_parent_id";

export const getMainCityBySelectedRegions = (selectedRegion: ISelectedRegionParam[]) => {
    if (selectedRegion.length === 0) {
        return null;
    }

    if (selectedRegion.some(({country}) => country && country !== Country.POLAND)) {
        // disable foreign regions
        return null;
    }

    // only one region is selected and it is a city
    if (selectedRegion.length === 1 && selectedRegion[0].type === RegionType.CITY) {
        return selectedRegion[0].id;
    }

    // only one region is selected and can be mapped to city by aggregation map
    if (selectedRegion.length === 1 && aggregationToCityMap.has(selectedRegion[0].id)) {
        return aggregationToCityMap.get(selectedRegion[0].id) as number;
    }

    // all regions are districts or surrounding from the same city
    if (
        selectedRegion.every(
            (region) =>
                (region.type === RegionType.DISTRICT || region.type === RegionType.SURROUNDINGS) &&
                getRegionParentId(region) === getRegionParentId(selectedRegion[0])
        )
    ) {
        const parentRegionId = getRegionParentId(selectedRegion[0]);

        if (parentRegionId) {
            return getRegionParentId(selectedRegion[0]) as number;
        }
    }

    // only one city has been selected with surroundings
    const mainCity = selectedRegion.find((region) => region.type === RegionType.CITY);
    if (mainCity && selectedRegion.every((region) => getRegionParentId(region) === mainCity.id || region.id === mainCity.id)) {
        return mainCity.id;
    }

    return null;
};
