import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {objectsDiff} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export const redirectWithEmptyParams = (paramsToCheck: string[]) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const newQuery = Object.keys(ctx.route.query).reduce((acc, key) => {
        const param = ctx.route.query[key];
        if (paramsToCheck.includes(key) && (param === "" || param === "undefined" || param === "null")) {
            return acc;
        }

        return {...acc, [key]: param};
    }, {});

    if (objectsDiff(newQuery, ctx.route.query)) {
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, newQuery)));
        return false;
    }

    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {objectsDiff} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export const redirectWithEmptyParams = (paramsToCheck: string[]) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const newQuery = Object.keys(ctx.route.query).reduce((acc, key) => {
        const param = ctx.route.query[key];
        if (paramsToCheck.includes(key) && (param === "" || param === "undefined" || param === "null")) {
            return acc;
        }

        return {...acc, [key]: param};
    }, {});

    if (objectsDiff(newQuery, ctx.route.query)) {
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, newQuery)));
        return false;
    }

    return true;
};
