import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IRangeQuery} from "../../request/IRangeQuery";

const FETCH_DESKTOP_PROPERTY_DETAIL_PROMOTIONS_PREFIX = "desktop_property_detail/FETCH_PROPERTY_DETAIL_PROMOTIONS";
export const fetchDesktopPropertyDetailPromotionsTypes = createRequestActionTypes(FETCH_DESKTOP_PROPERTY_DETAIL_PROMOTIONS_PREFIX);

export interface IPropertyDetailPromotion {
    description: string | null;
    id: number;
    name: string;
    offer: number;
    promotion_date_range: IRangeQuery<string | null> | null;
    type: number;
}

interface IPropertyDetailPromotionsResponse {
    results: {
        promotions: IPropertyDetailPromotion[];
    };
}
export const fetchDesktopPropertyDetailPromotionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchDesktopPropertyDetailPromotionsTypes.start});
    const url = appendQueryString(apiV2ListLink.promotion.list(Scenario.PROMOTION_LIST), {
        offer: ctx.prevResult.offer.id
    });
    return getRequest(ctx.meta, url).then((res: IPropertyDetailPromotionsResponse) => {
        dispatch({type: fetchDesktopPropertyDetailPromotionsTypes.success, result: res.results});
        return res;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IRangeQuery} from "../../request/IRangeQuery";

const FETCH_DESKTOP_PROPERTY_DETAIL_PROMOTIONS_PREFIX = "desktop_property_detail/FETCH_PROPERTY_DETAIL_PROMOTIONS";
export const fetchDesktopPropertyDetailPromotionsTypes = createRequestActionTypes(FETCH_DESKTOP_PROPERTY_DETAIL_PROMOTIONS_PREFIX);

export interface IPropertyDetailPromotion {
    description: string | null;
    id: number;
    name: string;
    offer: number;
    promotion_date_range: IRangeQuery<string | null> | null;
    type: number;
}

interface IPropertyDetailPromotionsResponse {
    results: {
        promotions: IPropertyDetailPromotion[];
    };
}
export const fetchDesktopPropertyDetailPromotionsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchDesktopPropertyDetailPromotionsTypes.start});
    const url = appendQueryString(apiV2ListLink.promotion.list(Scenario.PROMOTION_LIST), {
        offer: ctx.prevResult.offer.id
    });
    return getRequest(ctx.meta, url).then((res: IPropertyDetailPromotionsResponse) => {
        dispatch({type: fetchDesktopPropertyDetailPromotionsTypes.success, result: res.results});
        return res;
    });
};
