import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, isPlainObject} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOnList404} from "../../helpers/friendly_offer_list/redirect_on_list_404";
import {OfferType} from "../../types/OfferType";
import {MAX_SOLD_OFFERS_PAGE, OFFER_LIST_CONSTRAINTS, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {getFetchOfferListQueryData} from "../helpers/get_fetch_offer_list_query_data";
import {IOfferListResponse} from "../types/IOfferListResponse";
import {OfferListSubType} from "../types/OfferListSubType";
import {fetchPrivilegedOfferList} from "./fetch_privileged_offer_list";
import {fetchPropertiesCount} from "./fetch_properties_count";
import {fetchSoldOfferList, resetSoldOfferList} from "./fetch_sold_offer_list";
import {fetchSoonFinishedOfferList, resetSoonFinishedOfferList} from "./fetch_soon_finished_offer_list";

const THRESHOLD_MAX_STANDARD_OFFERS_PAGE = 2; // we show sold offers only for short listings (standard-offers)

const OFFER_LIST = "offer_list/fetch";
export const fetchOfferListTypes = createRequestActionTypes(OFFER_LIST);

const offerListLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

// TODO: consider creating one interface for offer list query instead of using `Record` containing different types. ILatestQuery may be a good starting point
export const fetchOfferList =
    (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, string | string[] | null>, offerListSubType?: OfferListSubType) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchOfferListTypes.start, latestQuery: query});

        const {fullQuery, paramPage, safeQuery} = getFetchOfferListQueryData(query, offerListSubType, true);

        // Define url and start fetch
        const url = appendQueryString(offerListLink, fullQuery);

        // Fetch properties and offers asynchronously
        const [, offerListResponse] = await Promise.all([
            dispatch(fetchPropertiesCount(ctx, {...safeQuery, ...OFFER_LIST_CONSTRAINTS})), // asynchronously fetch properties count
            getRequest(ctx.meta, url) // asynchronously fetch offers list
                .then(async (response: IOfferListResponse) => {
                    const result = {
                        offers: response.results,
                        count: response.count,
                        page_size: response.page_size,
                        page: fullQuery.page
                    };
                    // if (process.env.EXEC_ENV === "browser") {
                    //     const region = isArray(query.region) && query.region.length === 1 ? query.region[0] : undefined;
                    //     configureDesktopSmartAdAtOfferList(region, true); // refresh ads
                    // }
                    dispatch({type: fetchOfferListTypes.success, result});
                    return response;
                })
                .catch(
                    catch400((error) => {
                        dispatch({type: fetchOfferListTypes.error, error: error.appError});
                    })
                )
                .catch(
                    catch404(() => {
                        // Special case 404: fetch `count`
                        const modifiedQuery = {...fullQuery, page: 1, page_size: 1};
                        const modifiedUrl = appendQueryString(offerListLink, modifiedQuery);
                        // We expect this request to be safe in terms of 400 or 404 response
                        return getRequest(ctx.meta, modifiedUrl).then(async (response: IOfferListResponse) => {
                            const maxPage = response.count > 0 ? Math.ceil(response.count / OFFER_LIST_PAGE_SIZE) : 1;
                            if (maxPage > THRESHOLD_MAX_STANDARD_OFFERS_PAGE) {
                                // Standard offer list is too long
                                return dispatch(redirectOnList404(ctx.route, paramPage, ctx.meta));
                            }
                            const soldOfferListPage = paramPage - maxPage + 1;
                            if (soldOfferListPage > MAX_SOLD_OFFERS_PAGE) {
                                // We limit sold offers pages
                                return dispatch(redirectOnList404(ctx.route, paramPage, ctx.meta));
                            }
                            const result = {
                                // Mock proper response, we do not need this list, only the count
                                offers: [],
                                vendors: [],
                                regions: [],
                                count: response.count,
                                page_size: OFFER_LIST_PAGE_SIZE,
                                page: maxPage,
                                propertiesCount: null
                            };
                            dispatch({type: fetchOfferListTypes.success, result});
                            return response;
                        });
                    })
                )
                .then(async (prevResponse: IOfferListResponse | boolean | void) => {
                    // Fetch offers that soon will be finished. For the cases where we searched for finished offers but had not found any.
                    if (isPlainObject(prevResponse)) {
                        const wereOffersFilteredByFinishedParam = query.construction_end_date === "0";
                        const wereThereNoOffersToShow = prevResponse && (prevResponse as IOfferListResponse).results.length === 0;

                        if (wereOffersFilteredByFinishedParam && wereThereNoOffersToShow) {
                            return dispatch(
                                fetchSoonFinishedOfferList(ctx, paramPage, {
                                    ...query,
                                    ...(safeQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {})
                                })
                            );
                        }
                    }

                    dispatch(resetSoonFinishedOfferList());
                    return prevResponse;
                })
                .then(async (prevResponse: IOfferListResponse | boolean | void) => {
                    // Continue with sold fetch
                    if (prevResponse && isPlainObject(prevResponse)) {
                        const response = prevResponse as IOfferListResponse;

                        if (response.results) {
                            const maxPage = response.count > 0 ? Math.ceil(response.count / OFFER_LIST_PAGE_SIZE) : 1;
                            if (maxPage <= paramPage && maxPage <= THRESHOLD_MAX_STANDARD_OFFERS_PAGE) {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const {region, type, distance, sort, page, ...restQuery} = query;
                                if (sort == null && isEmpty(restQuery) && region && (!Array.isArray(region) || region.length === 1)) {
                                    const archivedOffersQuery = {
                                        region,
                                        type,
                                        distance,
                                        page: paramPage - maxPage + 1
                                    };
                                    return dispatch(fetchSoldOfferList(ctx, paramPage, archivedOffersQuery, response.count));
                                }
                            }
                        }
                    }
                    dispatch(resetSoldOfferList());
                    return prevResponse;
                }),
            dispatch(fetchPrivilegedOfferList(ctx))
        ]);

        return offerListResponse;
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, isPlainObject} from "@pg-mono/nodash";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOnList404} from "../../helpers/friendly_offer_list/redirect_on_list_404";
import {OfferType} from "../../types/OfferType";
import {MAX_SOLD_OFFERS_PAGE, OFFER_LIST_CONSTRAINTS, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {getFetchOfferListQueryData} from "../helpers/get_fetch_offer_list_query_data";
import {IOfferListResponse} from "../types/IOfferListResponse";
import {OfferListSubType} from "../types/OfferListSubType";
import {fetchPrivilegedOfferList} from "./fetch_privileged_offer_list";
import {fetchPropertiesCount} from "./fetch_properties_count";
import {fetchSoldOfferList, resetSoldOfferList} from "./fetch_sold_offer_list";
import {fetchSoonFinishedOfferList, resetSoonFinishedOfferList} from "./fetch_soon_finished_offer_list";

const THRESHOLD_MAX_STANDARD_OFFERS_PAGE = 2; // we show sold offers only for short listings (standard-offers)

const OFFER_LIST = "offer_list/fetch";
export const fetchOfferListTypes = createRequestActionTypes(OFFER_LIST);

const offerListLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

// TODO: consider creating one interface for offer list query instead of using `Record` containing different types. ILatestQuery may be a good starting point
export const fetchOfferList =
    (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, string | string[] | null>, offerListSubType?: OfferListSubType) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchOfferListTypes.start, latestQuery: query});

        const {fullQuery, paramPage, safeQuery} = getFetchOfferListQueryData(query, offerListSubType, true);

        // Define url and start fetch
        const url = appendQueryString(offerListLink, fullQuery);

        // Fetch properties and offers asynchronously
        const [, offerListResponse] = await Promise.all([
            dispatch(fetchPropertiesCount(ctx, {...safeQuery, ...OFFER_LIST_CONSTRAINTS})), // asynchronously fetch properties count
            getRequest(ctx.meta, url) // asynchronously fetch offers list
                .then(async (response: IOfferListResponse) => {
                    const result = {
                        offers: response.results,
                        count: response.count,
                        page_size: response.page_size,
                        page: fullQuery.page
                    };
                    // if (process.env.EXEC_ENV === "browser") {
                    //     const region = isArray(query.region) && query.region.length === 1 ? query.region[0] : undefined;
                    //     configureDesktopSmartAdAtOfferList(region, true); // refresh ads
                    // }
                    dispatch({type: fetchOfferListTypes.success, result});
                    return response;
                })
                .catch(
                    catch400((error) => {
                        dispatch({type: fetchOfferListTypes.error, error: error.appError});
                    })
                )
                .catch(
                    catch404(() => {
                        // Special case 404: fetch `count`
                        const modifiedQuery = {...fullQuery, page: 1, page_size: 1};
                        const modifiedUrl = appendQueryString(offerListLink, modifiedQuery);
                        // We expect this request to be safe in terms of 400 or 404 response
                        return getRequest(ctx.meta, modifiedUrl).then(async (response: IOfferListResponse) => {
                            const maxPage = response.count > 0 ? Math.ceil(response.count / OFFER_LIST_PAGE_SIZE) : 1;
                            if (maxPage > THRESHOLD_MAX_STANDARD_OFFERS_PAGE) {
                                // Standard offer list is too long
                                return dispatch(redirectOnList404(ctx.route, paramPage, ctx.meta));
                            }
                            const soldOfferListPage = paramPage - maxPage + 1;
                            if (soldOfferListPage > MAX_SOLD_OFFERS_PAGE) {
                                // We limit sold offers pages
                                return dispatch(redirectOnList404(ctx.route, paramPage, ctx.meta));
                            }
                            const result = {
                                // Mock proper response, we do not need this list, only the count
                                offers: [],
                                vendors: [],
                                regions: [],
                                count: response.count,
                                page_size: OFFER_LIST_PAGE_SIZE,
                                page: maxPage,
                                propertiesCount: null
                            };
                            dispatch({type: fetchOfferListTypes.success, result});
                            return response;
                        });
                    })
                )
                .then(async (prevResponse: IOfferListResponse | boolean | void) => {
                    // Fetch offers that soon will be finished. For the cases where we searched for finished offers but had not found any.
                    if (isPlainObject(prevResponse)) {
                        const wereOffersFilteredByFinishedParam = query.construction_end_date === "0";
                        const wereThereNoOffersToShow = prevResponse && (prevResponse as IOfferListResponse).results.length === 0;

                        if (wereOffersFilteredByFinishedParam && wereThereNoOffersToShow) {
                            return dispatch(
                                fetchSoonFinishedOfferList(ctx, paramPage, {
                                    ...query,
                                    ...(safeQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {})
                                })
                            );
                        }
                    }

                    dispatch(resetSoonFinishedOfferList());
                    return prevResponse;
                })
                .then(async (prevResponse: IOfferListResponse | boolean | void) => {
                    // Continue with sold fetch
                    if (prevResponse && isPlainObject(prevResponse)) {
                        const response = prevResponse as IOfferListResponse;

                        if (response.results) {
                            const maxPage = response.count > 0 ? Math.ceil(response.count / OFFER_LIST_PAGE_SIZE) : 1;
                            if (maxPage <= paramPage && maxPage <= THRESHOLD_MAX_STANDARD_OFFERS_PAGE) {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const {region, type, distance, sort, page, ...restQuery} = query;
                                if (sort == null && isEmpty(restQuery) && region && (!Array.isArray(region) || region.length === 1)) {
                                    const archivedOffersQuery = {
                                        region,
                                        type,
                                        distance,
                                        page: paramPage - maxPage + 1
                                    };
                                    return dispatch(fetchSoldOfferList(ctx, paramPage, archivedOffersQuery, response.count));
                                }
                            }
                        }
                    }
                    dispatch(resetSoldOfferList());
                    return prevResponse;
                }),
            dispatch(fetchPrivilegedOfferList(ctx))
        ]);

        return offerListResponse;
    };
