export enum SpecialListingType {
    CHEAP = "tanie",
    LUXURY = "luksusowe",
    READY = "gotowe"
}
export enum SpecialListingType {
    CHEAP = "tanie",
    LUXURY = "luksusowe",
    READY = "gotowe"
}
