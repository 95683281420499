// Replacement for https://lodash.com/docs/4.17.15#isEmpty
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => {
    if (typeof value === "number") {
        return true;
    }
    return [Object, Array].includes((value || {}).constructor) && !Object.entries(value || {}).length;
};
// Replacement for https://lodash.com/docs/4.17.15#isEmpty
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmpty = (value: any) => {
    if (typeof value === "number") {
        return true;
    }
    return [Object, Array].includes((value || {}).constructor) && !Object.entries(value || {}).length;
};
