import type {Dispatch, UnknownAction} from "redux";

import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {IActiveItemAction} from "../reducers/active_dropdown_item_reducer";
import {SearchInfoWindowCitiesList} from "../utils/search_info_window_cities_list";
import {SearchTab} from "./fetch_search_all_action";
import {IOfferQueryOffers} from "./fetch_search_offers_action";
import {AutocompletePrediction} from "./fetch_search_places_action";
import {IRegionListRegion} from "./fetch_search_regions_action";
import {IVendorListVendor} from "./fetch_search_vendors_action";

type IActiveItem = {label: string; id: number};

const createRegionActiveItem = (regions: IRegionListRegion[], index: number): IActiveItem => {
    const region = regions[index];
    return {
        label: region.full_name,
        id: index
    };
};

const createOfferActiveItem = (offers: IOfferQueryOffers[], index: number): IActiveItem => {
    const offer = offers[index];
    return {
        label: offer.name,
        id: index
    };
};

const createPlaceActiveItem = (places: AutocompletePrediction[], index: number): IActiveItem => {
    const place = places[index];
    return {
        label: place.description,
        id: index
    };
};

const createVendorActiveItem = (vendors: IVendorListVendor[], index: number): IActiveItem => {
    const vendor = vendors[index];
    return {
        label: vendor.name,
        id: index
    };
};

const createSetActiveItemAction = (item: IActiveItem): IActiveItemAction => ({type: SET_ACTIVE_DROPDOWN_ITEM, item});

const getPreviousId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return length - 1;
    }
    if (0 < index && index < length) {
        return index - 1;
    }
    return null;
};

const getNextId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return 0;
    }
    if (index < length - 1) {
        return index + 1;
    }
    return null;
};

export const SET_ACTIVE_DROPDOWN_ITEM = "search_engine/SET_ACTIVE_DROPDOWN_ITEM";
export const RESET_ACTIVE_DROPDOWN_ITEM = "search_engine/RESET_ACTIVE_DROPDOWN_ITEM";

export const setPreviousDropdownItem = () => (dispatch: Dispatch<IActiveItemAction | UnknownAction>, getState: () => IRPStore) => {
    const {search} = getState();

    const {
        formValues,
        currentTabData,
        activeDropdownItem,
        offers,
        regions,
        places,
        vendors,
        fetchOffersRequest,
        fetchRegionsRequest,
        fetchPlacesRequest,
        fetchVendorsRequest
    } = search;

    const inputValue = formValues["search"].label;
    if (isEmpty(inputValue)) {
        const previousId = getPreviousId(SearchInfoWindowCitiesList.length, activeDropdownItem.id);
        if (previousId !== null) {
            const currentCity = SearchInfoWindowCitiesList[previousId];
            return dispatch(createSetActiveItemAction({label: currentCity.label, id: previousId}));
        }
        return dispatch(resetActiveDropdownItem());
    }

    if (currentTabData.currentTab === SearchTab.Offers) {
        if (fetchOffersRequest === RequestState.Success) {
            const previousId = getPreviousId(offers.length, activeDropdownItem.id);
            if (previousId !== null) {
                const offer = createOfferActiveItem(offers, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(offer));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Regions) {
        if (fetchRegionsRequest === RequestState.Success) {
            const previousId = getPreviousId(regions.length, activeDropdownItem.id);
            if (previousId !== null) {
                const region = createRegionActiveItem(regions, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(region));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            const previousId = getPreviousId(places.length, activeDropdownItem.id);
            if (previousId !== null) {
                const place = createPlaceActiveItem(places, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(place));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Vendors) {
        if (fetchVendorsRequest === RequestState.Success) {
            const previousId = getPreviousId(vendors.length, activeDropdownItem.id);
            if (previousId !== null) {
                const vendor = createVendorActiveItem(vendors, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(vendor));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
};

export const setNextDropdownItem = () => (dispatch: Dispatch<IActiveItemAction | UnknownAction>, getState: () => IRPStore) => {
    const {search} = getState();

    const {
        formValues,
        currentTabData,
        activeDropdownItem,
        offers,
        regions,
        places,
        vendors,
        fetchOffersRequest,
        fetchRegionsRequest,
        fetchPlacesRequest,
        fetchVendorsRequest
    } = search;

    const inputValue = formValues["search"].label;

    if (isEmpty(inputValue)) {
        const nextId = getNextId(SearchInfoWindowCitiesList.length, activeDropdownItem.id);
        if (nextId !== null) {
            const currentCity = SearchInfoWindowCitiesList[nextId];
            return dispatch(createSetActiveItemAction({label: currentCity.label, id: nextId}));
        }
        return dispatch(resetActiveDropdownItem());
    }

    if (currentTabData.currentTab === SearchTab.Offers) {
        if (fetchOffersRequest === RequestState.Success) {
            const nextId = getNextId(offers.length, activeDropdownItem.id);
            if (nextId !== null) {
                const offer = createOfferActiveItem(offers, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(offer));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Regions) {
        if (fetchRegionsRequest === RequestState.Success) {
            const nextId = getNextId(regions.length, activeDropdownItem.id);
            if (nextId !== null) {
                const region = createRegionActiveItem(regions, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(region));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            const nextId = getNextId(places.length, activeDropdownItem.id);
            if (nextId !== null) {
                const place = createPlaceActiveItem(places, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(place));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Vendors) {
        if (fetchVendorsRequest === RequestState.Success) {
            const nextId = getNextId(vendors.length, activeDropdownItem.id);
            if (nextId !== null) {
                const vendor = createVendorActiveItem(vendors, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(vendor));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
};

export const resetActiveDropdownItem = () => ({type: RESET_ACTIVE_DROPDOWN_ITEM});

export const CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM = "search_engine/CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM";
export const clearLabelOfActiveValue = () => ({type: CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM});

const scrollToTabItem = (index: number) => {
    const tabItem = document.getElementById("scrollableItem-" + index);

    tabItem && tabItem.scrollIntoView(false);
};
import type {Dispatch, UnknownAction} from "redux";

import {isEmpty} from "@pg-mono/nodash";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../app/rp_reducer";
import {IActiveItemAction} from "../reducers/active_dropdown_item_reducer";
import {SearchInfoWindowCitiesList} from "../utils/search_info_window_cities_list";
import {SearchTab} from "./fetch_search_all_action";
import {IOfferQueryOffers} from "./fetch_search_offers_action";
import {AutocompletePrediction} from "./fetch_search_places_action";
import {IRegionListRegion} from "./fetch_search_regions_action";
import {IVendorListVendor} from "./fetch_search_vendors_action";

type IActiveItem = {label: string; id: number};

const createRegionActiveItem = (regions: IRegionListRegion[], index: number): IActiveItem => {
    const region = regions[index];
    return {
        label: region.full_name,
        id: index
    };
};

const createOfferActiveItem = (offers: IOfferQueryOffers[], index: number): IActiveItem => {
    const offer = offers[index];
    return {
        label: offer.name,
        id: index
    };
};

const createPlaceActiveItem = (places: AutocompletePrediction[], index: number): IActiveItem => {
    const place = places[index];
    return {
        label: place.description,
        id: index
    };
};

const createVendorActiveItem = (vendors: IVendorListVendor[], index: number): IActiveItem => {
    const vendor = vendors[index];
    return {
        label: vendor.name,
        id: index
    };
};

const createSetActiveItemAction = (item: IActiveItem): IActiveItemAction => ({type: SET_ACTIVE_DROPDOWN_ITEM, item});

const getPreviousId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return length - 1;
    }
    if (0 < index && index < length) {
        return index - 1;
    }
    return null;
};

const getNextId = (length: number, index: number | null): number | null => {
    if (index == null) {
        return 0;
    }
    if (index < length - 1) {
        return index + 1;
    }
    return null;
};

export const SET_ACTIVE_DROPDOWN_ITEM = "search_engine/SET_ACTIVE_DROPDOWN_ITEM";
export const RESET_ACTIVE_DROPDOWN_ITEM = "search_engine/RESET_ACTIVE_DROPDOWN_ITEM";

export const setPreviousDropdownItem = () => (dispatch: Dispatch<IActiveItemAction | UnknownAction>, getState: () => IRPStore) => {
    const {search} = getState();

    const {
        formValues,
        currentTabData,
        activeDropdownItem,
        offers,
        regions,
        places,
        vendors,
        fetchOffersRequest,
        fetchRegionsRequest,
        fetchPlacesRequest,
        fetchVendorsRequest
    } = search;

    const inputValue = formValues["search"].label;
    if (isEmpty(inputValue)) {
        const previousId = getPreviousId(SearchInfoWindowCitiesList.length, activeDropdownItem.id);
        if (previousId !== null) {
            const currentCity = SearchInfoWindowCitiesList[previousId];
            return dispatch(createSetActiveItemAction({label: currentCity.label, id: previousId}));
        }
        return dispatch(resetActiveDropdownItem());
    }

    if (currentTabData.currentTab === SearchTab.Offers) {
        if (fetchOffersRequest === RequestState.Success) {
            const previousId = getPreviousId(offers.length, activeDropdownItem.id);
            if (previousId !== null) {
                const offer = createOfferActiveItem(offers, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(offer));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Regions) {
        if (fetchRegionsRequest === RequestState.Success) {
            const previousId = getPreviousId(regions.length, activeDropdownItem.id);
            if (previousId !== null) {
                const region = createRegionActiveItem(regions, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(region));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            const previousId = getPreviousId(places.length, activeDropdownItem.id);
            if (previousId !== null) {
                const place = createPlaceActiveItem(places, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(place));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Vendors) {
        if (fetchVendorsRequest === RequestState.Success) {
            const previousId = getPreviousId(vendors.length, activeDropdownItem.id);
            if (previousId !== null) {
                const vendor = createVendorActiveItem(vendors, previousId);
                scrollToTabItem(previousId);
                return dispatch(createSetActiveItemAction(vendor));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
};

export const setNextDropdownItem = () => (dispatch: Dispatch<IActiveItemAction | UnknownAction>, getState: () => IRPStore) => {
    const {search} = getState();

    const {
        formValues,
        currentTabData,
        activeDropdownItem,
        offers,
        regions,
        places,
        vendors,
        fetchOffersRequest,
        fetchRegionsRequest,
        fetchPlacesRequest,
        fetchVendorsRequest
    } = search;

    const inputValue = formValues["search"].label;

    if (isEmpty(inputValue)) {
        const nextId = getNextId(SearchInfoWindowCitiesList.length, activeDropdownItem.id);
        if (nextId !== null) {
            const currentCity = SearchInfoWindowCitiesList[nextId];
            return dispatch(createSetActiveItemAction({label: currentCity.label, id: nextId}));
        }
        return dispatch(resetActiveDropdownItem());
    }

    if (currentTabData.currentTab === SearchTab.Offers) {
        if (fetchOffersRequest === RequestState.Success) {
            const nextId = getNextId(offers.length, activeDropdownItem.id);
            if (nextId !== null) {
                const offer = createOfferActiveItem(offers, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(offer));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Regions) {
        if (fetchRegionsRequest === RequestState.Success) {
            const nextId = getNextId(regions.length, activeDropdownItem.id);
            if (nextId !== null) {
                const region = createRegionActiveItem(regions, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(region));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Places) {
        if (fetchPlacesRequest === RequestState.Success) {
            const nextId = getNextId(places.length, activeDropdownItem.id);
            if (nextId !== null) {
                const place = createPlaceActiveItem(places, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(place));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
    if (currentTabData.currentTab === SearchTab.Vendors) {
        if (fetchVendorsRequest === RequestState.Success) {
            const nextId = getNextId(vendors.length, activeDropdownItem.id);
            if (nextId !== null) {
                const vendor = createVendorActiveItem(vendors, nextId);
                scrollToTabItem(nextId);
                return dispatch(createSetActiveItemAction(vendor));
            }
            return dispatch(resetActiveDropdownItem());
        }
        return;
    }
};

export const resetActiveDropdownItem = () => ({type: RESET_ACTIVE_DROPDOWN_ITEM});

export const CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM = "search_engine/CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM";
export const clearLabelOfActiveValue = () => ({type: CLEAR_LABEL_OF_ACTIVE_DROPDOWN_ITEM});

const scrollToTabItem = (index: number) => {
    const tabItem = document.getElementById("scrollableItem-" + index);

    tabItem && tabItem.scrollIntoView(false);
};
