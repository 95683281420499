import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const InstagramIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.956 6.067a.776.776 0 1 0 0-1.552.776.776 0 0 0 0 1.552Z" />
            <path d="M5.926 3.386h-1.94c-.283 0-.53.088-.688.247-.159.159-.247.406-.247.688v1.94c0 .282.088.53.264.705a.994.994 0 0 0 .688.247h1.923c.282 0 .529-.088.687-.247.177-.158.265-.405.265-.687v-1.94a.994.994 0 0 0-.247-.688c-.176-.177-.406-.265-.705-.265Zm-.97 3.104a1.2 1.2 0 0 1 0-2.398c.652 0 1.216.529 1.216 1.199s-.546 1.2-1.216 1.2Zm1.252-2.169a.279.279 0 0 1-.282-.282c0-.159.123-.282.282-.282.158 0 .282.123.282.282a.279.279 0 0 1-.282.282Z" />
            <path d="M4.991.917A4.336 4.336 0 0 0 .652 5.256a4.336 4.336 0 0 0 4.339 4.338A4.336 4.336 0 0 0 9.33 5.256C9.347 2.857 7.39.917 4.99.917Zm2.31 5.362c0 .405-.14.758-.388 1.005-.247.247-.6.37-.987.37H4.003c-.388 0-.74-.123-.987-.37-.265-.247-.388-.6-.388-1.005v-1.94c0-.812.546-1.376 1.375-1.376h1.94c.406 0 .741.14.988.388.247.247.37.582.37.988v1.94Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const InstagramIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.956 6.067a.776.776 0 1 0 0-1.552.776.776 0 0 0 0 1.552Z" />
            <path d="M5.926 3.386h-1.94c-.283 0-.53.088-.688.247-.159.159-.247.406-.247.688v1.94c0 .282.088.53.264.705a.994.994 0 0 0 .688.247h1.923c.282 0 .529-.088.687-.247.177-.158.265-.405.265-.687v-1.94a.994.994 0 0 0-.247-.688c-.176-.177-.406-.265-.705-.265Zm-.97 3.104a1.2 1.2 0 0 1 0-2.398c.652 0 1.216.529 1.216 1.199s-.546 1.2-1.216 1.2Zm1.252-2.169a.279.279 0 0 1-.282-.282c0-.159.123-.282.282-.282.158 0 .282.123.282.282a.279.279 0 0 1-.282.282Z" />
            <path d="M4.991.917A4.336 4.336 0 0 0 .652 5.256a4.336 4.336 0 0 0 4.339 4.338A4.336 4.336 0 0 0 9.33 5.256C9.347 2.857 7.39.917 4.99.917Zm2.31 5.362c0 .405-.14.758-.388 1.005-.247.247-.6.37-.987.37H4.003c-.388 0-.74-.123-.987-.37-.265-.247-.388-.6-.388-1.005v-1.94c0-.812.546-1.376 1.375-1.376h1.94c.406 0 .741.14.988.388.247.247.37.582.37.988v1.94Z" />
        </SvgIcon>
    );
};
