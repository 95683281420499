import {useLocation} from "react-router";

import {IFetchContextRoute} from "@pg-mono/data-fetcher";
import {parseSearch} from "@pg-mono/request";

export const getRouteFromLocation = (location: ReturnType<typeof useLocation>): IFetchContextRoute => {
    return {
        pathname: location.pathname,
        query: parseSearch(location.search),
        url: location.pathname + location.search + location.hash,
        hash: location.hash
    };
};
import {useLocation} from "react-router";

import {IFetchContextRoute} from "@pg-mono/data-fetcher";
import {parseSearch} from "@pg-mono/request";

export const getRouteFromLocation = (location: ReturnType<typeof useLocation>): IFetchContextRoute => {
    return {
        pathname: location.pathname,
        query: parseSearch(location.search),
        url: location.pathname + location.search + location.hash,
        hash: location.hash
    };
};
