import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectFromSubtypeToOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug: string; offerListSubType?: OfferListSubType}>) => async (dispatch: Dispatch) => {
        const {offerListSubType, friendlySlug} = ctx.match.params;

        // We want offer list subtype `CREDIT_TWO_PERCENT` to be redirected to main listing
        if (offerListSubType === OfferListSubType.CREDIT_TWO_PERCENT) {
            dispatch(enable301ResponseState(rpAppLink.offer.listFriendly({friendlySlug})));

            return false;
        }

        return ctx.prevResult;
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {OfferListSubType} from "../types/OfferListSubType";

export const redirectFromSubtypeToOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {friendlySlug: string; offerListSubType?: OfferListSubType}>) => async (dispatch: Dispatch) => {
        const {offerListSubType, friendlySlug} = ctx.match.params;

        // We want offer list subtype `CREDIT_TWO_PERCENT` to be redirected to main listing
        if (offerListSubType === OfferListSubType.CREDIT_TWO_PERCENT) {
            dispatch(enable301ResponseState(rpAppLink.offer.listFriendly({friendlySlug})));

            return false;
        }

        return ctx.prevResult;
    };
