import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const TESTIMONIALS_PREFIX = "testimonials/fetch";

export const fetchTestimonialsTypes = createRequestActionTypes(TESTIMONIALS_PREFIX);

export interface ITestimonial {
    id: number;
    image: {
        r_img_200x115: string;
    } | null;
    name: string;
    text: string;
}

export const fetchTestimonials = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchTestimonialsTypes.start});

    const testimonialsListApiLink = apiV2Link.recommendations.list(Scenario.REFERENCE_LIST);
    const url = appendQueryString(testimonialsListApiLink, {
        page_size: 1000,
        page: 1
    });

    return getRequest(ctx.meta, url).then((json: {results: ITestimonial[]}) => {
        dispatch({type: fetchTestimonialsTypes.success, result: json});
        return json;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const TESTIMONIALS_PREFIX = "testimonials/fetch";

export const fetchTestimonialsTypes = createRequestActionTypes(TESTIMONIALS_PREFIX);

export interface ITestimonial {
    id: number;
    image: {
        r_img_200x115: string;
    } | null;
    name: string;
    text: string;
}

export const fetchTestimonials = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchTestimonialsTypes.start});

    const testimonialsListApiLink = apiV2Link.recommendations.list(Scenario.REFERENCE_LIST);
    const url = appendQueryString(testimonialsListApiLink, {
        page_size: 1000,
        page: 1
    });

    return getRequest(ctx.meta, url).then((json: {results: ITestimonial[]}) => {
        dispatch({type: fetchTestimonialsTypes.success, result: json});
        return json;
    });
};
