/*
Describes full application routing of RP.
 */

import {prefixPath} from "@pg-mono/prefix-path";

export const rpAppPath = prefixPath("/", {
    base: "",
    friendlyBase: "s/",
    multiOfferList: prefixPath("ws/", {
        base: "",
        map: "mapa/"
    }),
    passwordChange: prefixPath("przypomnij-haslo/", {
        base: "",
        link: ":uid([\\w-]+)/:token([\\w-]+)/"
    }),
    offer: prefixPath("oferty/", {
        list: "",
        map: "mapa/",
        detail: prefixPath(":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/", {
            base: "",
            // redirects to offer detail
            redirects: {
                virtualTour: "wirtualny-spacer/",
                askAboutOffer: "zapytaj-o-oferte/",
                propertyList: "lista-ofert/",
                externalSite: "strona-internetowa/",
                map: "mapa/"
            }
        })
    }),
    modularHouseOffer: prefixPath("domy-modulowe/", {
        detail: prefixPath(":contractorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/", {
            base: ""
        })
    }),

    friendlyOfferList: "s/:friendlySlug([\\w-,.%]+)/",
    friendlyOfferListMap: prefixPath("s/:friendlySlug([\\w-,.%]+)/mapa/", {
        base: "",
        subType: ":offerListSubType(kredyt-2-procent)/"
    }),
    friendlyOfferListAbroadCountryMap: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/mapa/",
    friendlyOfferListAbroadRegionMap: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/:abroadRegionSlug([\\w-]+)/mapa/",
    friendlyOfferListAbroadRegion: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/:abroadRegionSlug([\\w-]+)/",
    friendlyOfferListAbroadCountry: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/",
    friendlyOfferListSubType: "s/:friendlySlug([\\w-,.%]+)/:offerListSubType(kredyt-2-procent)/",
    friendlyOfferListSubFilterGeneral:
        "s/:friendlySlug([\\w-,.%]+)/:offerListSubFilter(parter|parter-z-ogrodem|ostatnie-pietro|szeregowe|wolnostojace|blizniaki|z-bali|pietrowe|parterowe|z-poddaszem|z-garazem|z-ogrodem|z-tarasem|z-balkonem)/",
    investmentOffer: prefixPath("pod-inwestycje/", {
        base: "",
        investmentCategory: prefixPath(":category([\\w-]+)/", {
            base: "",
            map: "mapa/",
            subcategory: prefixPath(":subcategory(w-gorach|nad-morzem|nad-jeziorem|hiszpania|gdansk|krakow|lodz|poznan|warszawa|wroclaw)/", {
                base: "",
                map: "mapa/"
            })
        })
    }),
    newsletter: prefixPath("newsletter/", {
        mailing: prefixPath("mailing/", {
            unsubscribe: "wypisz/:emailHash/:verificationHash/"
        }),
        sms: prefixPath("sms/", {
            unsubscribe: "wypisz/:subscriberUuid([\\w-]+)/"
        })
    }),
    property: prefixPath("oferty/", {
        detailOld: ":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)/:propertyId(\\d+)/", // old address without offerId, we redirect to new address
        detail: prefixPath(":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/:propertyId(\\d+)/", {
            base: "",
            map: "mapa/",
            // redirects to property detail
            redirects: {
                map: "mapa/",
                virtualTour: "wirtualny-spacer/",
                askAboutProperty: "zapytaj-o-nieruchomosc/",
                askAboutPrice: "zapytaj-o-cene/",
                negotiatePrice: "negocjuj-cene/"
            }
        })
    }),
    article: prefixPath("wiadomosci-mieszkaniowe/", {
        list: "",
        category: "kategorie/:categorySlug([\\w-]+)/",
        detail: ":articleSlug([\\w-]+)/:articleId(\\d+)/",
        author: prefixPath("autorzy/", {
            list: "",
            detail: ":authorSlug([\\w-]+)-:authorId(\\d+)/"
        }),
        redirects: {
            categories: "kategorie/",
            search: "szukaj/"
        }
    }),
    partners: prefixPath("partnerzy/", {
        list: ""
    }),
    promotions: prefixPath("promocje/", {
        list: "",
        friendlyPromotionList: ":friendlySlug([\\w-]+)/"
    }),
    vendor: prefixPath("deweloperzy/", {
        list: "",
        friendlyVendorList: "s/:friendlySlug([\\w-]+)/",

        detail: prefixPath(":vendorSlug([\\w-]+)-:vendorId(\\d+)/", {
            base: "",
            redirects: {
                askAboutOffer: "zapytaj-o-oferte/",
                customRodoTerms: "regulamin-rodo/",
                externalSite: "strona-internetowa/"
            }
        })
    }),
    weFindForYou: prefixPath("znajdziemy-dla-ciebie/", {
        base: ""
    }),
    forDevelopers: prefixPath("dla-dewelopera/", {
        base: ""
    }),
    architect: prefixPath("architekt/", {
        list: "",
        city: ":regionSlug([\\w-]+)/"
    }),
    specialist: prefixPath("specjalisci/", {
        detail: ":specialistSlug([\\w-]+)-:specialistId(\\d+)/"
    }),
    contractor: prefixPath("dla-architekta/", {
        base: ""
    }),
    landPurchase: prefixPath("zakup-gruntow/", {
        base: ""
    }),
    location: prefixPath("tu-mieszkam/", {
        base: "",
        detail: ":regionSlug([\\w-]+)/"
    }),
    recommendations: prefixPath("rekomendacje/", {
        base: ""
    }),
    termsOfService: prefixPath("regulamin/", {
        base: ""
    }),
    rodoTerms: prefixPath("regulamin-rodo/", {
        base: ""
    }),
    partnersTerms: prefixPath("regulamin-dla-partnerow/", {
        base: ""
    }),
    ranking: prefixPath("ranking-inwestycji/", {
        base: "",
        city: prefixPath(":citySlug([\\w-]+)/", {
            base: "",
            annual: ":year(\\d+)/"
        })
    }),
    siteMap: prefixPath("mapa-strony/", {
        base: "",
        article: prefixPath("artykuly/", {
            base: "",
            category: ":categorySlug([\\w-]+)/"
        }),
        vendor: "deweloperzy/",
        house: "domy/",
        flat: "mieszkania/",
        commercial: "lokale-uzytkowe/",
        offer: prefixPath("inwestycje/", {
            base: "",
            voivodeship: ":voivodeshipSlug([\\w-]+)/"
        })
    }),
    user: prefixPath("konto/", {
        base: "",
        edit: "edycja/",
        favourite: "ulubione/",
        notifications: "powiadomienia/",
        knowledge: "wiedza/",
        changePassword: "zmien-haslo/",
        poi: "moje-miejsca/",
        subscriptions: "subskrypcje/"
    }),
    offerMdmList: "s/mieszkania-z-doplata-mdm/",
    myOffer: prefixPath("moje-oferty/", {
        base: "",
        map: "mapa/"
    }),
    notusTos: "regulamin-notus/",
    redirectToHomepage: {
        eu_3_3_2: "dzialanie-3-3-2/",
        eu_6_1: "dzialanie-6-1/",
        reference_list: "rekomendacje/",
        testPWA: "test-pwa/"
    },
    redirects: {
        financing_services: "kredyty/",
        old_mobile_offer_list_map: "s/mapa/"
    },
    ourOffer: "dla-dewelopera/",
    comparisonTool: "porownywarka/",
    favouriteList: "moja-lista/",
    permalinks: prefixPath("permalinks/", {
        article: "articles/article/:articleId([\\w-]+)/",
        offer: "offers/offer/:offerId([\\w-]+)/",
        openDay: "opendays/campaign/:campaignUuid([\\w-]+)/",
        property: "properties/property/:propertyId([\\w-]+)/",
        vendor: "vendors/vendor/:vendorId([\\w-]+)/"
    }),
    oauth: prefixPath("oauth/", {
        redirect: "redirect/"
    }),
    buylando: "sprzedaj-grunt/",
    propertyConfigurator: "konfigurator-mieszkania/",
    prices: prefixPath("ceny/", {
        base: "",
        flats: prefixPath("mieszkania/", {
            base: "",
            city: ":regionSlug([\\w-]+)/"
        })
    }),
    credits: prefixPath("kredyty/", {
        base: "",
        offers: "kalkulator-kredytu-hipotecznego/",
        rating: "kalkulator-zdolnosci-kredytowej/"
    }),
    openDays: prefixPath("dni-otwarte/", {
        base: ":campaignUuid([\\w-]+)/",
        withSubscriberId: ":campaignUuid([\\w-]+)/:subscriberUuid([\\w-]+)/"
    }),
    redirect: prefixPath("redirect/", {
        applicationLeadGenerator: "application-lead-generator/:applicationUuid([\\w-]+)/:offerId(\\d+)/"
    })
});
/*
Describes full application routing of RP.
 */

import {prefixPath} from "@pg-mono/prefix-path";

export const rpAppPath = prefixPath("/", {
    base: "",
    friendlyBase: "s/",
    multiOfferList: prefixPath("ws/", {
        base: "",
        map: "mapa/"
    }),
    passwordChange: prefixPath("przypomnij-haslo/", {
        base: "",
        link: ":uid([\\w-]+)/:token([\\w-]+)/"
    }),
    offer: prefixPath("oferty/", {
        list: "",
        map: "mapa/",
        detail: prefixPath(":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/", {
            base: "",
            // redirects to offer detail
            redirects: {
                virtualTour: "wirtualny-spacer/",
                askAboutOffer: "zapytaj-o-oferte/",
                propertyList: "lista-ofert/",
                externalSite: "strona-internetowa/",
                map: "mapa/"
            }
        })
    }),
    modularHouseOffer: prefixPath("domy-modulowe/", {
        detail: prefixPath(":contractorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/", {
            base: ""
        })
    }),

    friendlyOfferList: "s/:friendlySlug([\\w-,.%]+)/",
    friendlyOfferListMap: prefixPath("s/:friendlySlug([\\w-,.%]+)/mapa/", {
        base: "",
        subType: ":offerListSubType(kredyt-2-procent)/"
    }),
    friendlyOfferListAbroadCountryMap: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/mapa/",
    friendlyOfferListAbroadRegionMap: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/:abroadRegionSlug([\\w-]+)/mapa/",
    friendlyOfferListAbroadRegion: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/:abroadRegionSlug([\\w-]+)/",
    friendlyOfferListAbroadCountry: "s/:friendlySlug([\\w-,.%]+)-:country(hiszpania)/",
    friendlyOfferListSubType: "s/:friendlySlug([\\w-,.%]+)/:offerListSubType(kredyt-2-procent)/",
    friendlyOfferListSubFilterGeneral:
        "s/:friendlySlug([\\w-,.%]+)/:offerListSubFilter(parter|parter-z-ogrodem|ostatnie-pietro|szeregowe|wolnostojace|blizniaki|z-bali|pietrowe|parterowe|z-poddaszem|z-garazem|z-ogrodem|z-tarasem|z-balkonem)/",
    investmentOffer: prefixPath("pod-inwestycje/", {
        base: "",
        investmentCategory: prefixPath(":category([\\w-]+)/", {
            base: "",
            map: "mapa/",
            subcategory: prefixPath(":subcategory(w-gorach|nad-morzem|nad-jeziorem|hiszpania|gdansk|krakow|lodz|poznan|warszawa|wroclaw)/", {
                base: "",
                map: "mapa/"
            })
        })
    }),
    newsletter: prefixPath("newsletter/", {
        mailing: prefixPath("mailing/", {
            unsubscribe: "wypisz/:emailHash/:verificationHash/"
        }),
        sms: prefixPath("sms/", {
            unsubscribe: "wypisz/:subscriberUuid([\\w-]+)/"
        })
    }),
    property: prefixPath("oferty/", {
        detailOld: ":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)/:propertyId(\\d+)/", // old address without offerId, we redirect to new address
        detail: prefixPath(":vendorSlug([\\w-]+)/:offerSlug([\\w-]+)-:offerId(\\d+)/:propertyId(\\d+)/", {
            base: "",
            map: "mapa/",
            // redirects to property detail
            redirects: {
                map: "mapa/",
                virtualTour: "wirtualny-spacer/",
                askAboutProperty: "zapytaj-o-nieruchomosc/",
                askAboutPrice: "zapytaj-o-cene/",
                negotiatePrice: "negocjuj-cene/"
            }
        })
    }),
    article: prefixPath("wiadomosci-mieszkaniowe/", {
        list: "",
        category: "kategorie/:categorySlug([\\w-]+)/",
        detail: ":articleSlug([\\w-]+)/:articleId(\\d+)/",
        author: prefixPath("autorzy/", {
            list: "",
            detail: ":authorSlug([\\w-]+)-:authorId(\\d+)/"
        }),
        redirects: {
            categories: "kategorie/",
            search: "szukaj/"
        }
    }),
    partners: prefixPath("partnerzy/", {
        list: ""
    }),
    promotions: prefixPath("promocje/", {
        list: "",
        friendlyPromotionList: ":friendlySlug([\\w-]+)/"
    }),
    vendor: prefixPath("deweloperzy/", {
        list: "",
        friendlyVendorList: "s/:friendlySlug([\\w-]+)/",

        detail: prefixPath(":vendorSlug([\\w-]+)-:vendorId(\\d+)/", {
            base: "",
            redirects: {
                askAboutOffer: "zapytaj-o-oferte/",
                customRodoTerms: "regulamin-rodo/",
                externalSite: "strona-internetowa/"
            }
        })
    }),
    weFindForYou: prefixPath("znajdziemy-dla-ciebie/", {
        base: ""
    }),
    forDevelopers: prefixPath("dla-dewelopera/", {
        base: ""
    }),
    architect: prefixPath("architekt/", {
        list: "",
        city: ":regionSlug([\\w-]+)/"
    }),
    specialist: prefixPath("specjalisci/", {
        detail: ":specialistSlug([\\w-]+)-:specialistId(\\d+)/"
    }),
    contractor: prefixPath("dla-architekta/", {
        base: ""
    }),
    landPurchase: prefixPath("zakup-gruntow/", {
        base: ""
    }),
    location: prefixPath("tu-mieszkam/", {
        base: "",
        detail: ":regionSlug([\\w-]+)/"
    }),
    recommendations: prefixPath("rekomendacje/", {
        base: ""
    }),
    termsOfService: prefixPath("regulamin/", {
        base: ""
    }),
    rodoTerms: prefixPath("regulamin-rodo/", {
        base: ""
    }),
    partnersTerms: prefixPath("regulamin-dla-partnerow/", {
        base: ""
    }),
    ranking: prefixPath("ranking-inwestycji/", {
        base: "",
        city: prefixPath(":citySlug([\\w-]+)/", {
            base: "",
            annual: ":year(\\d+)/"
        })
    }),
    siteMap: prefixPath("mapa-strony/", {
        base: "",
        article: prefixPath("artykuly/", {
            base: "",
            category: ":categorySlug([\\w-]+)/"
        }),
        vendor: "deweloperzy/",
        house: "domy/",
        flat: "mieszkania/",
        commercial: "lokale-uzytkowe/",
        offer: prefixPath("inwestycje/", {
            base: "",
            voivodeship: ":voivodeshipSlug([\\w-]+)/"
        })
    }),
    user: prefixPath("konto/", {
        base: "",
        edit: "edycja/",
        favourite: "ulubione/",
        notifications: "powiadomienia/",
        knowledge: "wiedza/",
        changePassword: "zmien-haslo/",
        poi: "moje-miejsca/",
        subscriptions: "subskrypcje/"
    }),
    offerMdmList: "s/mieszkania-z-doplata-mdm/",
    myOffer: prefixPath("moje-oferty/", {
        base: "",
        map: "mapa/"
    }),
    notusTos: "regulamin-notus/",
    redirectToHomepage: {
        eu_3_3_2: "dzialanie-3-3-2/",
        eu_6_1: "dzialanie-6-1/",
        reference_list: "rekomendacje/",
        testPWA: "test-pwa/"
    },
    redirects: {
        financing_services: "kredyty/",
        old_mobile_offer_list_map: "s/mapa/"
    },
    ourOffer: "dla-dewelopera/",
    comparisonTool: "porownywarka/",
    favouriteList: "moja-lista/",
    permalinks: prefixPath("permalinks/", {
        article: "articles/article/:articleId([\\w-]+)/",
        offer: "offers/offer/:offerId([\\w-]+)/",
        openDay: "opendays/campaign/:campaignUuid([\\w-]+)/",
        property: "properties/property/:propertyId([\\w-]+)/",
        vendor: "vendors/vendor/:vendorId([\\w-]+)/"
    }),
    oauth: prefixPath("oauth/", {
        redirect: "redirect/"
    }),
    buylando: "sprzedaj-grunt/",
    propertyConfigurator: "konfigurator-mieszkania/",
    prices: prefixPath("ceny/", {
        base: "",
        flats: prefixPath("mieszkania/", {
            base: "",
            city: ":regionSlug([\\w-]+)/"
        })
    }),
    credits: prefixPath("kredyty/", {
        base: "",
        offers: "kalkulator-kredytu-hipotecznego/",
        rating: "kalkulator-zdolnosci-kredytowej/"
    }),
    openDays: prefixPath("dni-otwarte/", {
        base: ":campaignUuid([\\w-]+)/",
        withSubscriberId: ":campaignUuid([\\w-]+)/:subscriberUuid([\\w-]+)/"
    }),
    redirect: prefixPath("redirect/", {
        applicationLeadGenerator: "application-lead-generator/:applicationUuid([\\w-]+)/:offerId(\\d+)/"
    })
});
