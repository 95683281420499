import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export const redirectWithoutGivenParams = (paramsToOmit: string[]) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (paramsToOmit.some((param) => !!ctx.route.query[param])) {
        const omitQueries = omit(ctx.route.query, paramsToOmit);
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, omitQueries)));
        return false;
    }
    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {omit} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

export const redirectWithoutGivenParams = (paramsToOmit: string[]) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (paramsToOmit.some((param) => !!ctx.route.query[param])) {
        const omitQueries = omit(ctx.route.query, paramsToOmit);
        dispatch(enable301ResponseState(appendQueryString(ctx.route.pathname, omitQueries)));
        return false;
    }
    return true;
};
