import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const RadioIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 .833A4.168 4.168 0 0 0 .833 5C.833 7.3 2.7 9.167 5 9.167S9.166 7.3 9.166 5 7.3.833 5 .833Zm0 7.5a3.332 3.332 0 1 1 0-6.666 3.332 3.332 0 1 1 0 6.667Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const RadioIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 .833A4.168 4.168 0 0 0 .833 5C.833 7.3 2.7 9.167 5 9.167S9.166 7.3 9.166 5 7.3.833 5 .833Zm0 7.5a3.332 3.332 0 1 1 0-6.666 3.332 3.332 0 1 1 0 6.667Z" />
        </SvgIcon>
    );
};
