import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

const FETCH_IS_AUTHENTICATED_PREFIX = "auth/FETCH_IS_AUTHENTICATED";
export const fetchIsAuthenticatedTypes = createRequestActionTypes(FETCH_IS_AUTHENTICATED_PREFIX);

export const fetchIsAuthenticated =
    (meta: IRPRequestMeta) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        const userApiMeta = getUserApiRequestMeta(meta);

        dispatch({type: fetchIsAuthenticatedTypes.start});

        return getRequest(userApiMeta, apiUserLink.users.info).then((res: {is_authenticated: boolean}) => {
            const isAuthenticated = (res && res.is_authenticated) || false;

            dispatch({type: fetchIsAuthenticatedTypes.success, result: isAuthenticated});

            return isAuthenticated;
        });
    };
import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

const FETCH_IS_AUTHENTICATED_PREFIX = "auth/FETCH_IS_AUTHENTICATED";
export const fetchIsAuthenticatedTypes = createRequestActionTypes(FETCH_IS_AUTHENTICATED_PREFIX);

export const fetchIsAuthenticated =
    (meta: IRPRequestMeta) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        const userApiMeta = getUserApiRequestMeta(meta);

        dispatch({type: fetchIsAuthenticatedTypes.start});

        return getRequest(userApiMeta, apiUserLink.users.info).then((res: {is_authenticated: boolean}) => {
            const isAuthenticated = (res && res.is_authenticated) || false;

            dispatch({type: fetchIsAuthenticatedTypes.success, result: isAuthenticated});

            return isAuthenticated;
        });
    };
