export class GoogleMapsApiConfig {
    static apiKey: string;

    public static setApiKey(apiKey: string) {
        this.apiKey = apiKey;
    }

    public static getApiKey() {
        return this.apiKey;
    }
}
export class GoogleMapsApiConfig {
    static apiKey: string;

    public static setApiKey(apiKey: string) {
        this.apiKey = apiKey;
    }

    public static getApiKey() {
        return this.apiKey;
    }
}
