import {OfferDisplayType} from "../../offer/helpers/OfferDisplayType";

export const investmentOfferListConstrains = {
    limited_presentation: false,
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: true,
    show_on_listing: true
};
import {OfferDisplayType} from "../../offer/helpers/OfferDisplayType";

export const investmentOfferListConstrains = {
    limited_presentation: false,
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: true,
    show_on_listing: true
};
