import {friendlyQueryToHouseFilters} from "../../constants/house_filter";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {doesExistAnyFilterBesidesAllowed} from "./offer_listing_canonical_and_robots_helpers";

export const createCanonicalAndRobotsHouseFilterOnlyRule = (params: IOfferListingSeoRuleParams, offerCount: number, offerListSubFilter?: string) => ({
    condition: () => {
        return !!(
            params.offerType === OfferType.HOUSE &&
            offerListSubFilter &&
            friendlyQueryToHouseFilters[offerListSubFilter] &&
            offerCount > 0 &&
            (!doesExistAnyFilterBesidesAllowed(params.filters, ["house_type"]) ||
                !doesExistAnyFilterBesidesAllowed(params.filters, ["house_storeys"]) ||
                !doesExistAnyFilterBesidesAllowed(params.filters, ["house_additional_areas"]))
        );
    },
    robots: OfferMetaRobots.INDEX_FOLLOW,
    canonical: () => getCustomOfferCanonical(params, offerListSubFilter)
});

export const createCanonicalAndRobotsHouseFilterWithOtherFiltersRule = (
    params: IOfferListingSeoRuleParams,
    offerCount: number,
    offerListSubFilter?: string
) => ({
    condition: () => {
        const isSubFilterHouseFilter = offerListSubFilter && friendlyQueryToHouseFilters[offerListSubFilter];
        const hasQueryContainHouseFilters = params.filters.house_type || params.filters.house_additional_areas || params.filters.house_storeys;

        return !!(params.offerType === OfferType.HOUSE && (isSubFilterHouseFilter || hasQueryContainHouseFilters));
    },
    robots: offerCount > 0 && Number(params.page || 1) <= 1 ? OfferMetaRobots.NOINDEX_FOLLOW : OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params, offerListSubFilter)
});
import {friendlyQueryToHouseFilters} from "../../constants/house_filter";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {OfferType} from "../../types/OfferType";
import {IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";
import {doesExistAnyFilterBesidesAllowed} from "./offer_listing_canonical_and_robots_helpers";

export const createCanonicalAndRobotsHouseFilterOnlyRule = (params: IOfferListingSeoRuleParams, offerCount: number, offerListSubFilter?: string) => ({
    condition: () => {
        return !!(
            params.offerType === OfferType.HOUSE &&
            offerListSubFilter &&
            friendlyQueryToHouseFilters[offerListSubFilter] &&
            offerCount > 0 &&
            (!doesExistAnyFilterBesidesAllowed(params.filters, ["house_type"]) ||
                !doesExistAnyFilterBesidesAllowed(params.filters, ["house_storeys"]) ||
                !doesExistAnyFilterBesidesAllowed(params.filters, ["house_additional_areas"]))
        );
    },
    robots: OfferMetaRobots.INDEX_FOLLOW,
    canonical: () => getCustomOfferCanonical(params, offerListSubFilter)
});

export const createCanonicalAndRobotsHouseFilterWithOtherFiltersRule = (
    params: IOfferListingSeoRuleParams,
    offerCount: number,
    offerListSubFilter?: string
) => ({
    condition: () => {
        const isSubFilterHouseFilter = offerListSubFilter && friendlyQueryToHouseFilters[offerListSubFilter];
        const hasQueryContainHouseFilters = params.filters.house_type || params.filters.house_additional_areas || params.filters.house_storeys;

        return !!(params.offerType === OfferType.HOUSE && (isSubFilterHouseFilter || hasQueryContainHouseFilters));
    },
    robots: offerCount > 0 && Number(params.page || 1) <= 1 ? OfferMetaRobots.NOINDEX_FOLLOW : OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params, offerListSubFilter)
});
