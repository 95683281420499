/**
 * While clearing our own cache, we append a cache miss header to api requests.
 * @param headers
 * @param api - Redux toolkit api
 */
import {IRootState} from "../../store/store";

export const appendCacheMissHeader = (headers: Headers, api: {getState: () => unknown}) => {
    /**
     * RTK/Redux team claims that using app state inside actions is considered an antipattern because this makes these functions "unpure". They type api.getState as () => "unknown"
     * We want to modify api request headers, and not modify api response, casting seems innocent enough.
     */
    const state = api.getState() as IRootState;

    /**
     * When frontend receives a request with 'x-cache-force-miss'='1' header,
     * we also pass it to backend, so it can miss its own cache.
     * This way user/pre-cache crawler generates fresh data and cache.
     */
    const shouldMissCache = !!state?.headers.requestHeaders["x-cache-force-miss"] || process.env.API_CACHE_FORCE_MISS === "1";

    if (shouldMissCache) {
        headers.set("x-cache-force-miss", "1");
    }
};
/**
 * While clearing our own cache, we append a cache miss header to api requests.
 * @param headers
 * @param api - Redux toolkit api
 */
import {IRootState} from "../../store/store";

export const appendCacheMissHeader = (headers: Headers, api: {getState: () => unknown}) => {
    /**
     * RTK/Redux team claims that using app state inside actions is considered an antipattern because this makes these functions "unpure". They type api.getState as () => "unknown"
     * We want to modify api request headers, and not modify api response, casting seems innocent enough.
     */
    const state = api.getState() as IRootState;

    /**
     * When frontend receives a request with 'x-cache-force-miss'='1' header,
     * we also pass it to backend, so it can miss its own cache.
     * This way user/pre-cache crawler generates fresh data and cache.
     */
    const shouldMissCache = !!state?.headers.requestHeaders["x-cache-force-miss"] || process.env.API_CACHE_FORCE_MISS === "1";

    if (shouldMissCache) {
        headers.set("x-cache-force-miss", "1");
    }
};
