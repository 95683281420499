import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";

export const getPropsWithDefault = (baseModalProps: IModalProps) => ({
    ...defaultModalParams,
    ...baseModalProps
});
import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";

export const getPropsWithDefault = (baseModalProps: IModalProps) => ({
    ...defaultModalParams,
    ...baseModalProps
});
