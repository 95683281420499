import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../../app/api/rp_api";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {notifyBugsnag} from "../../../errors/bugsnag/notify_bugsnag";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {IAppDispatch} from "../../../store/store";
import {isRtkqError} from "../../../store/utils/is_rtkq_error";
import {ISelectedRegion} from "../../list/actions/offer_list_selected_data_actions";
import {getMapRegionDistrictsAndSurroundings} from "../api/get_map_region_districts_and_surroundings";
import {getMainCityBySelectedRegions} from "../utils/districts_and_surroundings/get_main_city_by_selected_regions";

export const fetchMapRegionDistrictsAndSurroundings =
    (selectedDataSelector: (state: IRPStore) => ISelectedRegion[]) =>
    (_ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: IAppDispatch, getState: () => IRPStore) => {
        const selectedRegion = selectedDataSelector(getState());
        let results: IRegionListMap[] = [];

        if (selectedRegion.length >= 1) {
            try {
                const parentId = getMainCityBySelectedRegions(selectedRegion);

                if (parentId) {
                    const response = await dispatch(
                        getMapRegionDistrictsAndSurroundings.endpoints.getMapRegionDistrictsAndSurroundings.initiate({parent: parentId})
                    ).unwrap();

                    results = response.results;
                }
            } catch (error) {
                if (isRtkqError(error) && error.status === 500) {
                    notifyBugsnag(error, "Map region districts and surroundings fetching failed");
                    return [];
                }
            }
        }

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
        return results;
    };
import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../../app/api/rp_api";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {notifyBugsnag} from "../../../errors/bugsnag/notify_bugsnag";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {IAppDispatch} from "../../../store/store";
import {isRtkqError} from "../../../store/utils/is_rtkq_error";
import {ISelectedRegion} from "../../list/actions/offer_list_selected_data_actions";
import {getMapRegionDistrictsAndSurroundings} from "../api/get_map_region_districts_and_surroundings";
import {getMainCityBySelectedRegions} from "../utils/districts_and_surroundings/get_main_city_by_selected_regions";

export const fetchMapRegionDistrictsAndSurroundings =
    (selectedDataSelector: (state: IRPStore) => ISelectedRegion[]) =>
    (_ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: IAppDispatch, getState: () => IRPStore) => {
        const selectedRegion = selectedDataSelector(getState());
        let results: IRegionListMap[] = [];

        if (selectedRegion.length >= 1) {
            try {
                const parentId = getMainCityBySelectedRegions(selectedRegion);

                if (parentId) {
                    const response = await dispatch(
                        getMapRegionDistrictsAndSurroundings.endpoints.getMapRegionDistrictsAndSurroundings.initiate({parent: parentId})
                    ).unwrap();

                    results = response.results;
                }
            } catch (error) {
                if (isRtkqError(error) && error.status === 500) {
                    notifyBugsnag(error, "Map region districts and surroundings fetching failed");
                    return [];
                }
            }
        }

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
        return results;
    };
