import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";

import {INotification} from "@pg-design/notifications";

export interface INotificationsState {
    list: INotification[];
}

const initialState: INotificationsState = {
    list: []
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification: (
            state,
            action: PayloadAction<{
                notification: Omit<INotification, "id" | "timeout">;
                timeout?: INotification["timeout"];
            }>
        ) => {
            const notifications = state.list;
            const timeout = action.payload.timeout;
            const newNotification = {
                ...action.payload.notification,
                id: uuid(),
                timeout: timeout ? timeout : 2000
            };
            const isDuplicate = notifications.some((notification) => notification.content === newNotification.content);

            state.list = isDuplicate ? state.list : [...state.list, newNotification];
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            const notificationId = action.payload;
            state.list = state.list.filter((notification) => notification.id !== notificationId);
        },
        dropAllTtlAndRemoveInvalid: (state) => {
            state.list = state.list.reduce<INotification[]>((acc, notification) => {
                if (notification.timeout === -1) {
                    // infinite timeout
                    return [...acc, notification];
                }
                // drop timeout by 1 when is high enough
                if (notification.timeout > 1) {
                    return [...acc, {...notification, timeout: notification.timeout - 1}];
                }
                // filter out alert whose timeout is 1 or less
                return acc;
            }, []);
        }
    }
});
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuid} from "uuid";

import {INotification} from "@pg-design/notifications";

export interface INotificationsState {
    list: INotification[];
}

const initialState: INotificationsState = {
    list: []
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        addNotification: (
            state,
            action: PayloadAction<{
                notification: Omit<INotification, "id" | "timeout">;
                timeout?: INotification["timeout"];
            }>
        ) => {
            const notifications = state.list;
            const timeout = action.payload.timeout;
            const newNotification = {
                ...action.payload.notification,
                id: uuid(),
                timeout: timeout ? timeout : 2000
            };
            const isDuplicate = notifications.some((notification) => notification.content === newNotification.content);

            state.list = isDuplicate ? state.list : [...state.list, newNotification];
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            const notificationId = action.payload;
            state.list = state.list.filter((notification) => notification.id !== notificationId);
        },
        dropAllTtlAndRemoveInvalid: (state) => {
            state.list = state.list.reduce<INotification[]>((acc, notification) => {
                if (notification.timeout === -1) {
                    // infinite timeout
                    return [...acc, notification];
                }
                // drop timeout by 1 when is high enough
                if (notification.timeout > 1) {
                    return [...acc, {...notification, timeout: notification.timeout - 1}];
                }
                // filter out alert whose timeout is 1 or less
                return acc;
            }, []);
        }
    }
});
