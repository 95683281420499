import type {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {getInternalRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IVariables} from "../reducers/variables_reducer";

const VARIABLES_PREFIX = "variables/fetch";
export const fetchVariablesTypes = createRequestActionTypes(VARIABLES_PREFIX);

type IVariablesResponse = Partial<IVariables>[];

const variablesApiLink = apiLink.variables({});

// TODO: rewrite usage of variables to use redux-toolkit (common/app/api/get_variables.ts) and replace this in data fetcher
export const fetchVariablesAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => {variables: {data: IVariables | null; requestState: RequestState}}) => {
        const {data, requestState} = getState().variables;

        if (requestState === RequestState.Success) {
            return data;
        }

        dispatch({type: fetchVariablesTypes.start});

        return getInternalRequest(ctx.meta, variablesApiLink(null)).then((variablesResponse: IVariablesResponse) => {
            const variables = variablesResponse.reduce((acc, variableObject) => ({...acc, ...variableObject}), {});

            dispatch({type: fetchVariablesTypes.success, result: variables});

            return variables;
        });
    };
import type {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {getInternalRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestState} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IVariables} from "../reducers/variables_reducer";

const VARIABLES_PREFIX = "variables/fetch";
export const fetchVariablesTypes = createRequestActionTypes(VARIABLES_PREFIX);

type IVariablesResponse = Partial<IVariables>[];

const variablesApiLink = apiLink.variables({});

// TODO: rewrite usage of variables to use redux-toolkit (common/app/api/get_variables.ts) and replace this in data fetcher
export const fetchVariablesAtRoute =
    (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => {variables: {data: IVariables | null; requestState: RequestState}}) => {
        const {data, requestState} = getState().variables;

        if (requestState === RequestState.Success) {
            return data;
        }

        dispatch({type: fetchVariablesTypes.start});

        return getInternalRequest(ctx.meta, variablesApiLink(null)).then((variablesResponse: IVariablesResponse) => {
            const variables = variablesResponse.reduce((acc, variableObject) => ({...acc, ...variableObject}), {});

            dispatch({type: fetchVariablesTypes.success, result: variables});

            return variables;
        });
    };
