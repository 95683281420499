import React from "react";
import classNames from "classnames";

import {disabled, inputStyle} from "./InputCore.module.css";

export type IInputCoreProps = React.InputHTMLAttributes<HTMLInputElement> & {
    testid?: string;
    className?: string;
    inputtextalign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
};

export const InputCore = React.forwardRef<HTMLInputElement, IInputCoreProps>((props, forwardedInputRef) => {
    const cn = classNames(inputStyle, props.disabled && disabled, props.className);
    const {testid, ...restProps} = props;
    return (
        <input
            {...restProps}
            style={{textAlign: props.inputtextalign}}
            type={props.type}
            className={cn}
            disabled={props.disabled}
            ref={forwardedInputRef}
            data-testid={testid}
        />
    );
});
import React from "react";
import classNames from "classnames";

import {disabled, inputStyle} from "./InputCore.module.css";

export type IInputCoreProps = React.InputHTMLAttributes<HTMLInputElement> & {
    testid?: string;
    className?: string;
    inputtextalign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
};

export const InputCore = React.forwardRef<HTMLInputElement, IInputCoreProps>((props, forwardedInputRef) => {
    const cn = classNames(inputStyle, props.disabled && disabled, props.className);
    const {testid, ...restProps} = props;
    return (
        <input
            {...restProps}
            style={{textAlign: props.inputtextalign}}
            type={props.type}
            className={cn}
            disabled={props.disabled}
            ref={forwardedInputRef}
            data-testid={testid}
        />
    );
});
