import {IFavourite} from "@pg-mono/favourites";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

export const fetchFavourites = (query: Record<string, string | boolean> = {}): Promise<IFavourite[]> => {
    const userApiMeta = getUserApiRequestMeta({});

    return getRequest(userApiMeta, appendQueryString(apiUserLink.favorites.favorite, query))
        .then(({results}: {results: IFavourite[]}) => results)
        .catch(() => []);
};
import {IFavourite} from "@pg-mono/favourites";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

export const fetchFavourites = (query: Record<string, string | boolean> = {}): Promise<IFavourite[]> => {
    const userApiMeta = getUserApiRequestMeta({});

    return getRequest(userApiMeta, appendQueryString(apiUserLink.favorites.favorite, query))
        .then(({results}: {results: IFavourite[]}) => results)
        .catch(() => []);
};
