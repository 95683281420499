import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {isServer} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

interface ISyncUserSessionResponse {
    redirect_required: boolean;
    redirect_url: string;
}
export const syncSessionMarkerCookieName = "rp-sync";

export const syncUserSession = async (meta: IRPRequestMeta, next: string): Promise<string | null> => {
    const userApiMeta = isServer ? getUserApiRequestMeta(meta) : {};

    return getRequest(userApiMeta, appendQueryString(apiUserLink.session.sync, {next})).then((response: ISyncUserSessionResponse) => {
        return response.redirect_required ? response.redirect_url : null;
    });
};
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {isServer} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getUserApiRequestMeta} from "../utils/get_user_api_request_meta";

interface ISyncUserSessionResponse {
    redirect_required: boolean;
    redirect_url: string;
}
export const syncSessionMarkerCookieName = "rp-sync";

export const syncUserSession = async (meta: IRPRequestMeta, next: string): Promise<string | null> => {
    const userApiMeta = isServer ? getUserApiRequestMeta(meta) : {};

    return getRequest(userApiMeta, appendQueryString(apiUserLink.session.sync, {next})).then((response: ISyncUserSessionResponse) => {
        return response.redirect_required ? response.redirect_url : null;
    });
};
