import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPropertyDetail} from "../types/IPropertyDetail";

export const fetchPropertyDetailById = (meta: IRPRequestMeta, propertyId: number): Promise<IPropertyDetail> => {
    const requestUrl = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});

    return getRequest(meta, requestUrl);
};
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPropertyDetail} from "../types/IPropertyDetail";

export const fetchPropertyDetailById = (meta: IRPRequestMeta, propertyId: number): Promise<IPropertyDetail> => {
    const requestUrl = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});

    return getRequest(meta, requestUrl);
};
