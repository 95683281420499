import {calculatePriceFromInstalment} from "../../credit_calculator/utils/calculate_price_from_instalment";

export const transformOfferFetchQuery = (query: Record<string, string | string[] | null>) => {
    if (query.instalment && typeof query.instalment === "string") {
        query.price_1 = calculatePriceFromInstalment(parseInt(query.instalment));
        delete query.instalment;
    }

    return query;
};
import {calculatePriceFromInstalment} from "../../credit_calculator/utils/calculate_price_from_instalment";

export const transformOfferFetchQuery = (query: Record<string, string | string[] | null>) => {
    if (query.instalment && typeof query.instalment === "string") {
        query.price_1 = calculatePriceFromInstalment(parseInt(query.instalment));
        delete query.instalment;
    }

    return query;
};
