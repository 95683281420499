import {IGlobalsActions, ISetGlobals, SET_GLOBALS} from "../actions/globals";

export type IGlobalsStore = ISetGlobals["value"];

export const globalsReducer = (state: IGlobalsStore = {}, action: IGlobalsActions) => {
    switch (action.type) {
        case SET_GLOBALS: {
            return {
                ...state,
                ...action.value
            };
        }
        default:
            return state;
    }
};
import {IGlobalsActions, ISetGlobals, SET_GLOBALS} from "../actions/globals";

export type IGlobalsStore = ISetGlobals["value"];

export const globalsReducer = (state: IGlobalsStore = {}, action: IGlobalsActions) => {
    switch (action.type) {
        case SET_GLOBALS: {
            return {
                ...state,
                ...action.value
            };
        }
        default:
            return state;
    }
};
