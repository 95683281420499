import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {defaultMetaData} from "../../meta_data/reducers/meta_data_reducer";

export const updateNewsletterUnsubscribeMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName("Wypisanie z newslettera -");
    const robots = "noindex, follow";
    const description = defaultMetaData.description;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname,
        robots
    });
    dispatch(metaData);
    return Promise.resolve(true);
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {defaultMetaData} from "../../meta_data/reducers/meta_data_reducer";

export const updateNewsletterUnsubscribeMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = concatWithBrandName("Wypisanie z newslettera -");
    const robots = "noindex, follow";
    const description = defaultMetaData.description;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname,
        robots
    });
    dispatch(metaData);
    return Promise.resolve(true);
};
