import {DomUtils, parseDocument} from "htmlparser2";

export const insertSearchIntoArticle = (text: string): string => {
    const pTagsCounter = text.match(/<p\b[^>]*>/gi)?.length ?? 0;
    const insertPoints = pTagsCounter > 30 ? [Math.floor(pTagsCounter * 0.25), Math.floor(pTagsCounter * 0.75)] : [Math.floor(pTagsCounter * 0.49)];

    const parsedArticleTextDOM = parseDocument(text, {decodeEntities: true});

    let index = 0;
    DomUtils.findAll((childNode: {tagName: string; attribs: {class: string; data?: string}}) => {
        if (childNode.tagName === "p") {
            if (insertPoints.includes(index)) {
                const newElement = parseDocument('<div class="rp-article-app" data-apptype="searchBar"></div>').childNodes[0];

                const parent = DomUtils.getParent(childNode);
                if (parent) {
                    const childIndex = parent.children.indexOf(childNode);
                    if (childIndex !== -1) {
                        parent.children.splice(childIndex + 1, 0, newElement);
                    }
                }
            }
            index++;
        }
    }, parsedArticleTextDOM.childNodes);

    return DomUtils.getOuterHTML(parsedArticleTextDOM);
};
import {DomUtils, parseDocument} from "htmlparser2";

export const insertSearchIntoArticle = (text: string): string => {
    const pTagsCounter = text.match(/<p\b[^>]*>/gi)?.length ?? 0;
    const insertPoints = pTagsCounter > 30 ? [Math.floor(pTagsCounter * 0.25), Math.floor(pTagsCounter * 0.75)] : [Math.floor(pTagsCounter * 0.49)];

    const parsedArticleTextDOM = parseDocument(text, {decodeEntities: true});

    let index = 0;
    DomUtils.findAll((childNode: {tagName: string; attribs: {class: string; data?: string}}) => {
        if (childNode.tagName === "p") {
            if (insertPoints.includes(index)) {
                const newElement = parseDocument('<div class="rp-article-app" data-apptype="searchBar"></div>').childNodes[0];

                const parent = DomUtils.getParent(childNode);
                if (parent) {
                    const childIndex = parent.children.indexOf(childNode);
                    if (childIndex !== -1) {
                        parent.children.splice(childIndex + 1, 0, newElement);
                    }
                }
            }
            index++;
        }
    }, parsedArticleTextDOM.childNodes);

    return DomUtils.getOuterHTML(parsedArticleTextDOM);
};
