import {combineReducers} from "redux";

import {reduceFormValues} from "@pg-mono/form";
import {IAppError, reduceRequestErrors, reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fromJsonValues} from "../../form/translate/from_json_values";
import {fetchOtherPropertiesInOfferTypes} from "../actions/fetch_other_properties_in_offer";
import {otherPropertiesFilterFormTypes} from "../actions/update_other_properties_filter_form";
import {IRoomFilterFormValues, otherPropertiesFilterFormFields} from "../components/PropertyDetailOtherPropertiesFilters";
import {IPropertyListProperty} from "../types/PropertyListProperty";
import {excludeCurrentRoomFilterOption} from "./exclude_current_filter_reducer";

export interface IOtherPropertiesInOfferStore {
    list: IPropertyListProperty[];
    count: number;
    formValues: IRoomFilterFormValues;
    request: RequestState;
    errors: IAppError | null;
    excludeCurrentRoomFilterOption: boolean;
}

export const otherPropertiesInOfferStore = combineReducers({
    list: reduceResponseField<IPropertyListProperty[]>(fetchOtherPropertiesInOfferTypes, "results", []),
    count: reduceResponseField<number>(fetchOtherPropertiesInOfferTypes, "count", 0),
    formValues: reduceFormValues(otherPropertiesFilterFormTypes, fromJsonValues(otherPropertiesFilterFormFields, {})),
    request: reduceRequestState(fetchOtherPropertiesInOfferTypes),
    errors: reduceRequestErrors(fetchOtherPropertiesInOfferTypes),
    excludeCurrentRoomFilterOption: excludeCurrentRoomFilterOption
});
import {combineReducers} from "redux";

import {reduceFormValues} from "@pg-mono/form";
import {IAppError, reduceRequestErrors, reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fromJsonValues} from "../../form/translate/from_json_values";
import {fetchOtherPropertiesInOfferTypes} from "../actions/fetch_other_properties_in_offer";
import {otherPropertiesFilterFormTypes} from "../actions/update_other_properties_filter_form";
import {IRoomFilterFormValues, otherPropertiesFilterFormFields} from "../components/PropertyDetailOtherPropertiesFilters";
import {IPropertyListProperty} from "../types/PropertyListProperty";
import {excludeCurrentRoomFilterOption} from "./exclude_current_filter_reducer";

export interface IOtherPropertiesInOfferStore {
    list: IPropertyListProperty[];
    count: number;
    formValues: IRoomFilterFormValues;
    request: RequestState;
    errors: IAppError | null;
    excludeCurrentRoomFilterOption: boolean;
}

export const otherPropertiesInOfferStore = combineReducers({
    list: reduceResponseField<IPropertyListProperty[]>(fetchOtherPropertiesInOfferTypes, "results", []),
    count: reduceResponseField<number>(fetchOtherPropertiesInOfferTypes, "count", 0),
    formValues: reduceFormValues(otherPropertiesFilterFormTypes, fromJsonValues(otherPropertiesFilterFormFields, {})),
    request: reduceRequestState(fetchOtherPropertiesInOfferTypes),
    errors: reduceRequestErrors(fetchOtherPropertiesInOfferTypes),
    excludeCurrentRoomFilterOption: excludeCurrentRoomFilterOption
});
