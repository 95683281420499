import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getMarketplaceRegionListApi} from "../../region/api/get_marketplace_region_list";
import {IAppDispatch} from "../../store/store";

/*
 * TODO: There seems to be a consensus, that we want to move to "one action per view" approach.
 *  So please add new "offer list" actions to this file and migrate the logic from the old ones.
 */

export const offerListingAction = (ctx: IFetchContext<IRPRequestMeta, {region?: string}>) => async (dispatch: IAppDispatch) => {
    /**
     * TODO: Currently, we "parse" url in a separate action, and pass parsed data as "prevResult" in ctx.
     *   This should be rewritten to a more explicite approach, we can define what "filters" we expect in the url.
     *   Currently the workaround is to use "latestQuery" that was used for fetching offer list.
     *   Make sure this action is placed after fetchOfferListAtRoute action.
     */
    if (ctx.prevResult.region?.length > 0) {
        await dispatch(getMarketplaceRegionListApi.endpoints.getArchitectRegionList.initiate());
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {rpApi} from "../../app/api/rp_api";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getMarketplaceRegionListApi} from "../../region/api/get_marketplace_region_list";
import {IAppDispatch} from "../../store/store";

/*
 * TODO: There seems to be a consensus, that we want to move to "one action per view" approach.
 *  So please add new "offer list" actions to this file and migrate the logic from the old ones.
 */

export const offerListingAction = (ctx: IFetchContext<IRPRequestMeta, {region?: string}>) => async (dispatch: IAppDispatch) => {
    /**
     * TODO: Currently, we "parse" url in a separate action, and pass parsed data as "prevResult" in ctx.
     *   This should be rewritten to a more explicite approach, we can define what "filters" we expect in the url.
     *   Currently the workaround is to use "latestQuery" that was used for fetching offer list.
     *   Make sure this action is placed after fetchOfferListAtRoute action.
     */
    if (ctx.prevResult.region?.length > 0) {
        await dispatch(getMarketplaceRegionListApi.endpoints.getArchitectRegionList.initiate());
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
