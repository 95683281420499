import {DomUtils, parseDocument} from "htmlparser2";

export const addImageLazyLoad = (text: string): string => {
    const parsedArticleTextDOM = parseDocument(text, {decodeEntities: true});

    DomUtils.findAll((childNode: {tagName: string; attribs: {loading: string}}) => {
        if (childNode.tagName === "img" && !Boolean(childNode.attribs.loading)) {
            childNode.attribs.loading = "lazy";
        }
    }, parsedArticleTextDOM.childNodes);

    return DomUtils.getOuterHTML(parsedArticleTextDOM);
};
import {DomUtils, parseDocument} from "htmlparser2";

export const addImageLazyLoad = (text: string): string => {
    const parsedArticleTextDOM = parseDocument(text, {decodeEntities: true});

    DomUtils.findAll((childNode: {tagName: string; attribs: {loading: string}}) => {
        if (childNode.tagName === "img" && !Boolean(childNode.attribs.loading)) {
            childNode.attribs.loading = "lazy";
        }
    }, parsedArticleTextDOM.childNodes);

    return DomUtils.getOuterHTML(parsedArticleTextDOM);
};
