// Replacement for https://lodash.com/docs/4.17.15#isNumber
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any, strict = false): value is number => {
    if (strict) {
        return typeof value === "number" && isFinite(value);
    }

    return typeof value === "number";
};
// Replacement for https://lodash.com/docs/4.17.15#isNumber
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any, strict = false): value is number => {
    if (strict) {
        return typeof value === "number" && isFinite(value);
    }

    return typeof value === "number";
};
