import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IOfferBoxWithPropertiesModalOffer} from "../../atoms/OfferBoxWithOfferDetailsAction";
import {fetchPostApplicationNearbyOffersTypes} from "../actions/fetch_offer_detail_offers_list";

export interface IOffersNearbyStore {
    application: IOfferBoxWithPropertiesModalOffer[];
    applicationRequestState: RequestState;
}

export const offersNearbyReducer = combineReducers({
    application: reduceResponseField<IOfferBoxWithPropertiesModalOffer[]>(fetchPostApplicationNearbyOffersTypes, "offers", []),
    applicationRequestState: reduceRequestState(fetchPostApplicationNearbyOffersTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IOfferBoxWithPropertiesModalOffer} from "../../atoms/OfferBoxWithOfferDetailsAction";
import {fetchPostApplicationNearbyOffersTypes} from "../actions/fetch_offer_detail_offers_list";

export interface IOffersNearbyStore {
    application: IOfferBoxWithPropertiesModalOffer[];
    applicationRequestState: RequestState;
}

export const offersNearbyReducer = combineReducers({
    application: reduceResponseField<IOfferBoxWithPropertiesModalOffer[]>(fetchPostApplicationNearbyOffersTypes, "offers", []),
    applicationRequestState: reduceRequestState(fetchPostApplicationNearbyOffersTypes)
});
