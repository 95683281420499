export const useGeolocation = () => {
    const locateUser = (onSuccess: (position: GeolocationCoordinates) => void, onError: () => void) => {
        const geolocation = navigator?.geolocation;
        if (geolocation) {
            geolocation.getCurrentPosition(
                (position) => {
                    onSuccess(position.coords);
                },
                () => {
                    onError();
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    };

    return {
        locateUser
    };
};
export const useGeolocation = () => {
    const locateUser = (onSuccess: (position: GeolocationCoordinates) => void, onError: () => void) => {
        const geolocation = navigator?.geolocation;
        if (geolocation) {
            geolocation.getCurrentPosition(
                (position) => {
                    onSuccess(position.coords);
                },
                () => {
                    onError();
                },
                {
                    enableHighAccuracy: true
                }
            );
        }
    };

    return {
        locateUser
    };
};
