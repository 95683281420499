/**
 * ATTENTION, EVERYONE WHO EDITS THIS FILE
 * Note that, when adding `UserSource` we need to edit database model to allow new values
 * Please, inform backend about each change in enum
 */

export enum UserSource {
    REGISTRATION = 1, // Rejestracja
    APPLICATION = 2, // Zgłoszenie
    NEWSLETTER = 3, // Newsletter
    FAVOURITE_OFFER = 4, // Ulubiona inwestycja
    FAVOURITE_PROPERTY = 5, // Ulubiona nieruchomość
    FAVOURITE_ARTICLE = 6, // Ulubiony artykuł
    MAP = 7, // Rejestracja przy POI mapy
    COMMENT_ARTICLE = 8, // Rejestracja podczas dodania komentarza
    NAVIGATION = 9, // Rejestracja/Logowanie przez button w nawigacji
    REPORT_GENERATOR = 10, // Rejestracja przez “Wygeneruj raport cenowy dla lokalizacji” z listingu inwestycji
    MY_OFFER_LIST = 11, // Rejestracja przez “Moje Zapytania” na stronie Moje Oferty
    ACCOUNT_SHARE = 12, //Zalogowanie się na udostępnionym koncie, poprzez wejście w link.
    PASSWORD_RECOVERED = 13, //Po kliknięciu w email w link odzyskiwania konta
    REGISTRATION_PROMOTION_MODAL = 14, //Po kliknięciu w link w hederze listingu kierującego na listę promocji
    COMPARISON_TOOL = 15, //Rejestracje w porównywarce pre-sell
    APPLICATION_VISIBLE_PRICE = 17 // Rejestracja na formularzu zgłoszeniowym z ceną
}

export function getUserSourceDisplay(source: UserSource): string {
    switch (source) {
        case UserSource.REGISTRATION:
            return "Rejestracja";
        case UserSource.APPLICATION:
            return "Zgłoszenie";
        case UserSource.NEWSLETTER:
            return "Newsletter";
        case UserSource.FAVOURITE_OFFER:
            return "Ulubiona inwestycja";
        case UserSource.FAVOURITE_PROPERTY:
            return "Ulubiona nieruchomość";
        case UserSource.FAVOURITE_ARTICLE:
            return "Ulubiony artykuł";
        case UserSource.MAP:
            return "Rejestracja przy POI mapy";
        case UserSource.COMMENT_ARTICLE:
            return "Rejestracja podczas dodania komentarza";
        case UserSource.NAVIGATION:
            return "Rejestracja/Logowanie przez button w nawigacji";
        case UserSource.REPORT_GENERATOR:
            return "Raport cenowy dla lokalizacji";
        case UserSource.MY_OFFER_LIST:
            return "Rejestracja/Logowanie przez button w Moje Zapytania";
        case UserSource.ACCOUNT_SHARE:
            return "Zalogowanie się na udostępnionym koncie, poprzez wejście w link";
        case UserSource.PASSWORD_RECOVERED:
            return "Po kliknięciu w email w link odzyskiwania konta";
        case UserSource.REGISTRATION_PROMOTION_MODAL:
            return "Po kliknięciu w link kierujący na listing promocji";
        case UserSource.COMPARISON_TOOL:
            return "Rejestracja przy porównywarce pre-sell";
        case UserSource.APPLICATION_VISIBLE_PRICE:
            return "Rejestracja na formularzu zgłoszeniowym z ceną";
        default:
            return "Rejestracja";
    }
}

export function getUserSourceData(sourceId: UserSource) {
    return {
        source: getUserSourceDisplay(sourceId),
        source_id: sourceId
    };
}
/**
 * ATTENTION, EVERYONE WHO EDITS THIS FILE
 * Note that, when adding `UserSource` we need to edit database model to allow new values
 * Please, inform backend about each change in enum
 */

export enum UserSource {
    REGISTRATION = 1, // Rejestracja
    APPLICATION = 2, // Zgłoszenie
    NEWSLETTER = 3, // Newsletter
    FAVOURITE_OFFER = 4, // Ulubiona inwestycja
    FAVOURITE_PROPERTY = 5, // Ulubiona nieruchomość
    FAVOURITE_ARTICLE = 6, // Ulubiony artykuł
    MAP = 7, // Rejestracja przy POI mapy
    COMMENT_ARTICLE = 8, // Rejestracja podczas dodania komentarza
    NAVIGATION = 9, // Rejestracja/Logowanie przez button w nawigacji
    REPORT_GENERATOR = 10, // Rejestracja przez “Wygeneruj raport cenowy dla lokalizacji” z listingu inwestycji
    MY_OFFER_LIST = 11, // Rejestracja przez “Moje Zapytania” na stronie Moje Oferty
    ACCOUNT_SHARE = 12, //Zalogowanie się na udostępnionym koncie, poprzez wejście w link.
    PASSWORD_RECOVERED = 13, //Po kliknięciu w email w link odzyskiwania konta
    REGISTRATION_PROMOTION_MODAL = 14, //Po kliknięciu w link w hederze listingu kierującego na listę promocji
    COMPARISON_TOOL = 15, //Rejestracje w porównywarce pre-sell
    APPLICATION_VISIBLE_PRICE = 17 // Rejestracja na formularzu zgłoszeniowym z ceną
}

export function getUserSourceDisplay(source: UserSource): string {
    switch (source) {
        case UserSource.REGISTRATION:
            return "Rejestracja";
        case UserSource.APPLICATION:
            return "Zgłoszenie";
        case UserSource.NEWSLETTER:
            return "Newsletter";
        case UserSource.FAVOURITE_OFFER:
            return "Ulubiona inwestycja";
        case UserSource.FAVOURITE_PROPERTY:
            return "Ulubiona nieruchomość";
        case UserSource.FAVOURITE_ARTICLE:
            return "Ulubiony artykuł";
        case UserSource.MAP:
            return "Rejestracja przy POI mapy";
        case UserSource.COMMENT_ARTICLE:
            return "Rejestracja podczas dodania komentarza";
        case UserSource.NAVIGATION:
            return "Rejestracja/Logowanie przez button w nawigacji";
        case UserSource.REPORT_GENERATOR:
            return "Raport cenowy dla lokalizacji";
        case UserSource.MY_OFFER_LIST:
            return "Rejestracja/Logowanie przez button w Moje Zapytania";
        case UserSource.ACCOUNT_SHARE:
            return "Zalogowanie się na udostępnionym koncie, poprzez wejście w link";
        case UserSource.PASSWORD_RECOVERED:
            return "Po kliknięciu w email w link odzyskiwania konta";
        case UserSource.REGISTRATION_PROMOTION_MODAL:
            return "Po kliknięciu w link kierujący na listing promocji";
        case UserSource.COMPARISON_TOOL:
            return "Rejestracja przy porównywarce pre-sell";
        case UserSource.APPLICATION_VISIBLE_PRICE:
            return "Rejestracja na formularzu zgłoszeniowym z ceną";
        default:
            return "Rejestracja";
    }
}

export function getUserSourceData(sourceId: UserSource) {
    return {
        source: getUserSourceDisplay(sourceId),
        source_id: sourceId
    };
}
