import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export interface IInvestmentOfferStatsByRegion {
    is_commercial: number;
    is_investment_apartment: number;
    is_condohotel: number;
    promotions_count_total: number;
}

export interface IInvestmentOfferStats {
    totals: {
        holiday_location_lake: number;
        holiday_location_mountains: number;
        holiday_location_sea: number;
        is_condohotel: number;
        is_holiday_location: number;
        is_dedicated_for_rent: number;
        is_investment_apartment: number;
    };
    by_region: Record<string, IInvestmentOfferStatsByRegion>;
    abroad: {
        spain: number;
        spain_max_price: number;
    };
}

const FETCH_INVESTMENT_OFFER_STATS = "investment_offer/STATS";
export const fetchInvestmentOfferStatsTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_STATS);

const url = apiV2Link.stats.offers(null);

export const fetchInvestmentOfferStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchInvestmentOfferStatsTypes.start});

    return getRequest(ctx.meta, url)
        .then((result: IInvestmentOfferStats) => {
            dispatch({type: fetchInvestmentOfferStatsTypes.success, result});
            return result;
        })
        .catch(
            catch404(async (error) => {
                dispatch({type: fetchInvestmentOfferStatsTypes.error, error});
                return true;
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export interface IInvestmentOfferStatsByRegion {
    is_commercial: number;
    is_investment_apartment: number;
    is_condohotel: number;
    promotions_count_total: number;
}

export interface IInvestmentOfferStats {
    totals: {
        holiday_location_lake: number;
        holiday_location_mountains: number;
        holiday_location_sea: number;
        is_condohotel: number;
        is_holiday_location: number;
        is_dedicated_for_rent: number;
        is_investment_apartment: number;
    };
    by_region: Record<string, IInvestmentOfferStatsByRegion>;
    abroad: {
        spain: number;
        spain_max_price: number;
    };
}

const FETCH_INVESTMENT_OFFER_STATS = "investment_offer/STATS";
export const fetchInvestmentOfferStatsTypes = createRequestActionTypes(FETCH_INVESTMENT_OFFER_STATS);

const url = apiV2Link.stats.offers(null);

export const fetchInvestmentOfferStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchInvestmentOfferStatsTypes.start});

    return getRequest(ctx.meta, url)
        .then((result: IInvestmentOfferStats) => {
            dispatch({type: fetchInvestmentOfferStatsTypes.success, result});
            return result;
        })
        .catch(
            catch404(async (error) => {
                dispatch({type: fetchInvestmentOfferStatsTypes.error, error});
                return true;
            })
        );
};
