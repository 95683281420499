import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {IRpStoreFetchBaseQueryError} from "../types/IRpStoreFetchBaseQueryError";

/**
 * Possible error data is extended with additinal field `ignoreNotify`.
 * Related type - IRpStoreFetchBaseQueryError.
 * This function is to be used in `transformErrorResponse` of RTK Query API, to simplify error handling.
 */
export function getIgnoredNotifyResponse(
    initialResponse: FetchBaseQueryError,
    ignoredStatuses: (number | "FETCH_ERROR" | "PARSING_ERROR" | "TIMEOUT_ERROR" | "CUSTOM_ERROR")[]
) {
    const response = initialResponse as IRpStoreFetchBaseQueryError;

    if (ignoredStatuses.includes(response.status)) {
        response.ignoreNotify = true;
    }

    return response;
}
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {IRpStoreFetchBaseQueryError} from "../types/IRpStoreFetchBaseQueryError";

/**
 * Possible error data is extended with additinal field `ignoreNotify`.
 * Related type - IRpStoreFetchBaseQueryError.
 * This function is to be used in `transformErrorResponse` of RTK Query API, to simplify error handling.
 */
export function getIgnoredNotifyResponse(
    initialResponse: FetchBaseQueryError,
    ignoredStatuses: (number | "FETCH_ERROR" | "PARSING_ERROR" | "TIMEOUT_ERROR" | "CUSTOM_ERROR")[]
) {
    const response = initialResponse as IRpStoreFetchBaseQueryError;

    if (ignoredStatuses.includes(response.status)) {
        response.ignoreNotify = true;
    }

    return response;
}
