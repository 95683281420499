export enum DropdownToOpen {
    AREA,
    PRICE,
    ROOMS,
    FLOORS,
    CONSTRUCTION_END_DATE,
    PROPERTY,
    DISTANCE
}
export enum DropdownToOpen {
    AREA,
    PRICE,
    ROOMS,
    FLOORS,
    CONSTRUCTION_END_DATE,
    PROPERTY,
    DISTANCE
}
