import {combineReducers} from "redux";

import {IAppError, reduceLatestQuery, reduceRequestErrors, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {ILatestQuery} from "../../../utils/latest_query";
import {IOfferListOffer} from "../../types/IOfferListOffer";
import {fetchOfferListTypes} from "../actions/fetch_offer_list";
import {fetchPrivilegedOfferListTypes} from "../actions/fetch_privileged_offer_list";
import {fetchPropertiesCountTypes} from "../actions/fetch_properties_count";
import {fetchSoldOfferListTypes} from "../actions/fetch_sold_offer_list";
import {fetchSoonFinishedOfferListTypes} from "../actions/fetch_soon_finished_offer_list";

export interface IOfferListListStore {
    offers: IOfferListOffer[];
    privileged_offers: IOfferListOffer[];
    sold_offers: IOfferListOffer[];
    soon_finished_offers: IOfferListOffer[];
    sold_offers_page: number;
    sold_offers_count: number;
    soon_finished_offers_count: number;
    count: number;
    page: number;
    page_size: number;
    requestState: RequestState;
    latestQuery: ILatestQuery & Record<string, string | string[] | null>;
    error: IAppError;
    propertiesCount: number | null;
    propertiesCountRequestState: RequestState;
}

export const offerListListReducer = combineReducers({
    offers: reduceResponseField<IOfferListOffer[]>(fetchOfferListTypes, "offers", []),
    privileged_offers: reduceResponseField<IOfferListOffer[]>(fetchPrivilegedOfferListTypes, "offers", []),
    sold_offers: reduceResponseField<IOfferListOffer[]>(fetchSoldOfferListTypes, "offers", []),
    soon_finished_offers: reduceResponseField(fetchSoonFinishedOfferListTypes, "offers", []),
    sold_offers_page: reduceResponseField<number>(fetchSoldOfferListTypes, "page", 0),
    sold_offers_count: reduceResponseField<number>(fetchSoldOfferListTypes, "count", 0),
    soon_finished_offers_count: reduceResponseField<number>(fetchSoonFinishedOfferListTypes, "count", 0),
    count: reduceResponseField<number>(fetchOfferListTypes, "count", 0),
    page: reduceResponseField<number>(fetchOfferListTypes, "page", 0),
    page_size: reduceResponseField<number>(fetchOfferListTypes, "page_size", 0),
    requestState: reduceRequestState(fetchOfferListTypes),
    latestQuery: reduceLatestQuery(fetchOfferListTypes),
    error: reduceRequestErrors(fetchOfferListTypes),
    propertiesCount: reduceResponse(fetchPropertiesCountTypes),
    propertiesCountRequestState: reduceRequestState(fetchPropertiesCountTypes)
});
import {combineReducers} from "redux";

import {IAppError, reduceLatestQuery, reduceRequestErrors, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {ILatestQuery} from "../../../utils/latest_query";
import {IOfferListOffer} from "../../types/IOfferListOffer";
import {fetchOfferListTypes} from "../actions/fetch_offer_list";
import {fetchPrivilegedOfferListTypes} from "../actions/fetch_privileged_offer_list";
import {fetchPropertiesCountTypes} from "../actions/fetch_properties_count";
import {fetchSoldOfferListTypes} from "../actions/fetch_sold_offer_list";
import {fetchSoonFinishedOfferListTypes} from "../actions/fetch_soon_finished_offer_list";

export interface IOfferListListStore {
    offers: IOfferListOffer[];
    privileged_offers: IOfferListOffer[];
    sold_offers: IOfferListOffer[];
    soon_finished_offers: IOfferListOffer[];
    sold_offers_page: number;
    sold_offers_count: number;
    soon_finished_offers_count: number;
    count: number;
    page: number;
    page_size: number;
    requestState: RequestState;
    latestQuery: ILatestQuery & Record<string, string | string[] | null>;
    error: IAppError;
    propertiesCount: number | null;
    propertiesCountRequestState: RequestState;
}

export const offerListListReducer = combineReducers({
    offers: reduceResponseField<IOfferListOffer[]>(fetchOfferListTypes, "offers", []),
    privileged_offers: reduceResponseField<IOfferListOffer[]>(fetchPrivilegedOfferListTypes, "offers", []),
    sold_offers: reduceResponseField<IOfferListOffer[]>(fetchSoldOfferListTypes, "offers", []),
    soon_finished_offers: reduceResponseField(fetchSoonFinishedOfferListTypes, "offers", []),
    sold_offers_page: reduceResponseField<number>(fetchSoldOfferListTypes, "page", 0),
    sold_offers_count: reduceResponseField<number>(fetchSoldOfferListTypes, "count", 0),
    soon_finished_offers_count: reduceResponseField<number>(fetchSoonFinishedOfferListTypes, "count", 0),
    count: reduceResponseField<number>(fetchOfferListTypes, "count", 0),
    page: reduceResponseField<number>(fetchOfferListTypes, "page", 0),
    page_size: reduceResponseField<number>(fetchOfferListTypes, "page_size", 0),
    requestState: reduceRequestState(fetchOfferListTypes),
    latestQuery: reduceLatestQuery(fetchOfferListTypes),
    error: reduceRequestErrors(fetchOfferListTypes),
    propertiesCount: reduceResponse(fetchPropertiesCountTypes),
    propertiesCountRequestState: reduceRequestState(fetchPropertiesCountTypes)
});
