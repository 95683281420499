import {offerModalInitialState} from "./offer_modal_initial_state";

export const offerModalsSliceInitialState = {
    mainOfferModal: offerModalInitialState,
    extraOfferModal: offerModalInitialState
};
import {offerModalInitialState} from "./offer_modal_initial_state";

export const offerModalsSliceInitialState = {
    mainOfferModal: offerModalInitialState,
    extraOfferModal: offerModalInitialState
};
