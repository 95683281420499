import {IFetchContext} from "@pg-mono/data-fetcher";
import {isPlainObject} from "@pg-mono/nodash";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {IAppDispatch} from "../../../store/store";
import {isRtkqError} from "../../../store/utils/is_rtkq_error";
import {getMapRegion} from "../api/get_map_region";

export const fetchMapRegionAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
    const isMultiRegion = Array.isArray(ctx.route.query.region);
    const offerListQuery = isPlainObject(ctx.prevResult) ? ctx.prevResult : ctx.route.query;

    let results: IRegionListMap[] = [];
    try {
        const region = isMultiRegion ? offerListQuery.region?.map((r: string) => parseInt(r, 10)) : offerListQuery.region;
        const response = await dispatch(getMapRegion.endpoints.getMapRegions.initiate({region})).unwrap();
        results = response.results;
    } catch (error) {
        if (isRtkqError(error) && error.status === 400) {
            dispatch(enable301ResponseState(rpAppLink.offer.map()));
            return false;
        }

        if (isRtkqError(error) && error.status === 404) {
            dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            return false;
        }
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    return results;
};
import {IFetchContext} from "@pg-mono/data-fetcher";
import {isPlainObject} from "@pg-mono/nodash";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {IAppDispatch} from "../../../store/store";
import {isRtkqError} from "../../../store/utils/is_rtkq_error";
import {getMapRegion} from "../api/get_map_region";

export const fetchMapRegionAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
    const isMultiRegion = Array.isArray(ctx.route.query.region);
    const offerListQuery = isPlainObject(ctx.prevResult) ? ctx.prevResult : ctx.route.query;

    let results: IRegionListMap[] = [];
    try {
        const region = isMultiRegion ? offerListQuery.region?.map((r: string) => parseInt(r, 10)) : offerListQuery.region;
        const response = await dispatch(getMapRegion.endpoints.getMapRegions.initiate({region})).unwrap();
        results = response.results;
    } catch (error) {
        if (isRtkqError(error) && error.status === 400) {
            dispatch(enable301ResponseState(rpAppLink.offer.map()));
            return false;
        }

        if (isRtkqError(error) && error.status === 404) {
            dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            return false;
        }
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
    return results;
};
