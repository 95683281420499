import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {IArticleListArticle} from "../../../article/actions/fetch_article_list";
import {IRegionReportPrice} from "../../../region/types/IRegionReportPrice";
import {fetchPriceReportRegionTypes} from "../actions/fetch_price_report_region";
import {fetchPricesArticleListTypes} from "../actions/fetch_prices_article_list";
import {fetchReportPriceAveragePricesTypes, fetchReportPriceYearAgoAveragePricesTypes} from "../actions/fetch_report_price_average_prices";
import {fetchReportPriceAllCitiesPrices} from "../api/fetch_all_cities_prices";
import {fetchReportPriceAllCitiesPricesSOld} from "../api/fetch_all_cities_prices_old";
import {IPriceReportAveragePrices} from "../types/IPriceReportAveragePrices";

export interface IPriceReportStore {
    region: IRegionReportPrice | null;
    prices: IPriceReportAveragePrices[] | null;
    yearAgoPrices: IPriceReportAveragePrices;
    allCitiesData: IPriceReportAveragePrices[];
    allCitiesDataOld: IPriceReportAveragePrices[];
    articles: IArticleListArticle[];
}

export const priceReportReducer = combineReducers({
    region: reduceResponse<IRegionReportPrice>(fetchPriceReportRegionTypes),
    prices: reduceResponse<IPriceReportAveragePrices>(fetchReportPriceAveragePricesTypes),
    yearAgoPrices: reduceResponse<IPriceReportAveragePrices>(fetchReportPriceYearAgoAveragePricesTypes),
    allCitiesData: reduceResponse<IPriceReportAveragePrices[]>(fetchReportPriceAllCitiesPrices),
    allCitiesDataOld: reduceResponse<IPriceReportAveragePrices[]>(fetchReportPriceAllCitiesPricesSOld),
    articles: reduceResponse<IArticleListArticle[]>(fetchPricesArticleListTypes, [])
});
import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {IArticleListArticle} from "../../../article/actions/fetch_article_list";
import {IRegionReportPrice} from "../../../region/types/IRegionReportPrice";
import {fetchPriceReportRegionTypes} from "../actions/fetch_price_report_region";
import {fetchPricesArticleListTypes} from "../actions/fetch_prices_article_list";
import {fetchReportPriceAveragePricesTypes, fetchReportPriceYearAgoAveragePricesTypes} from "../actions/fetch_report_price_average_prices";
import {fetchReportPriceAllCitiesPrices} from "../api/fetch_all_cities_prices";
import {fetchReportPriceAllCitiesPricesSOld} from "../api/fetch_all_cities_prices_old";
import {IPriceReportAveragePrices} from "../types/IPriceReportAveragePrices";

export interface IPriceReportStore {
    region: IRegionReportPrice | null;
    prices: IPriceReportAveragePrices[] | null;
    yearAgoPrices: IPriceReportAveragePrices;
    allCitiesData: IPriceReportAveragePrices[];
    allCitiesDataOld: IPriceReportAveragePrices[];
    articles: IArticleListArticle[];
}

export const priceReportReducer = combineReducers({
    region: reduceResponse<IRegionReportPrice>(fetchPriceReportRegionTypes),
    prices: reduceResponse<IPriceReportAveragePrices>(fetchReportPriceAveragePricesTypes),
    yearAgoPrices: reduceResponse<IPriceReportAveragePrices>(fetchReportPriceYearAgoAveragePricesTypes),
    allCitiesData: reduceResponse<IPriceReportAveragePrices[]>(fetchReportPriceAllCitiesPrices),
    allCitiesDataOld: reduceResponse<IPriceReportAveragePrices[]>(fetchReportPriceAllCitiesPricesSOld),
    articles: reduceResponse<IArticleListArticle[]>(fetchPricesArticleListTypes, [])
});
