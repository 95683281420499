import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IRankingRegion} from "../types/IRankingRegion";

export const updateRankingInfoMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = `Ranking Inwestycji deweloperskich - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji deweloperskich przygotowanych przez portal ${BRAND_NAME}. Sprawdź, które inwestycje cieszą się największym uznaniem Polaków.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
};

export const updateRankingListMetaDataYear = (ctx: IFetchContext<IRPRequestMeta>, region: IRankingRegion) => (dispatch: MetaDataDispatch) => {
    const title = `Ranking Inwestycji deweloperskich ${region.name_declension_where} - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji ${region.name_declension_where}. Zobacz najpopularniejsze inwestycje według użytkowników portalu ${BRAND_NAME}!`;

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";
import {IRankingRegion} from "../types/IRankingRegion";

export const updateRankingInfoMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const title = `Ranking Inwestycji deweloperskich - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji deweloperskich przygotowanych przez portal ${BRAND_NAME}. Sprawdź, które inwestycje cieszą się największym uznaniem Polaków.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
};

export const updateRankingListMetaDataYear = (ctx: IFetchContext<IRPRequestMeta>, region: IRankingRegion) => (dispatch: MetaDataDispatch) => {
    const title = `Ranking Inwestycji deweloperskich ${region.name_declension_where} - ${BRAND_NAME}`;
    const description = `Ranking najlepszych inwestycji ${region.name_declension_where}. Zobacz najpopularniejsze inwestycje według użytkowników portalu ${BRAND_NAME}!`;

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
};
