import {IModalProps} from "../../../types/IModalProps";

import * as style from "./full_screen_style.module.css";

export const fullScreenStyle = (fullScreen: IModalProps["fullScreen"]) => {
    switch (fullScreen) {
        case "xs":
            return style.xs;

        case true:
            return style.fullscreen;

        default:
            return "";
    }
};
import {IModalProps} from "../../../types/IModalProps";

import * as style from "./full_screen_style.module.css";

export const fullScreenStyle = (fullScreen: IModalProps["fullScreen"]) => {
    switch (fullScreen) {
        case "xs":
            return style.xs;

        case true:
            return style.fullscreen;

        default:
            return "";
    }
};
