export const getFavouriteObject = ({offerId = null, propertyId = null}: Record<string, number | null>) => {
    return {
        offer: offerId ? {rp_id: offerId} : null,
        property: propertyId ? {rp_id: propertyId} : null
    };
};
export const getFavouriteObject = ({offerId = null, propertyId = null}: Record<string, number | null>) => {
    return {
        offer: offerId ? {rp_id: offerId} : null,
        property: propertyId ? {rp_id: propertyId} : null
    };
};
