import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferFilters, IOfferListingSeoRule, offerFilterKeys} from "./constants";

export const getOnlyFilterRecords = (filters: IOfferFilters) =>
    offerFilterKeys.reduce((result, currKey) => {
        return {
            ...result,
            ...{[currKey]: filters[currKey]}
        } as IOfferFilters;
    }, {} as IOfferFilters);

export const shouldHaveActiveFilters = (filters: IOfferFilters, filterNames: (keyof IOfferFilters)[]) =>
    filterNames.every((filterName) => !!filters[filterName]);

export const shouldHaveActiveSomeFilters = (filters: IOfferFilters, filterNames: (keyof IOfferFilters)[]) =>
    filterNames.some((filterName) => !!filters[filterName]);

export const doesExistAnyFilterBesidesAllowed = (filters: IOfferFilters, allowedFilterNames: (keyof IOfferFilters)[]) => {
    const restFilterKeys = Object.keys(getOnlyFilterRecords(filters)).filter((name) => !(allowedFilterNames as string[]).includes(name));

    return restFilterKeys.some((key) => !!filters[key as keyof IOfferFilters]);
};

export const doesFilterContainsNonListedValues = (filterValue: string | undefined, allowedValues: string[]) =>
    !filterValue || !allowedValues.includes(filterValue);

const matchOfferListingSeoRule = (rules: IOfferListingSeoRule[]) => rules.find((rule) => rule.condition());

export const getOfferListingSeoRobotsByRules = (rules: IOfferListingSeoRule[]): OfferMetaRobots | undefined => matchOfferListingSeoRule(rules)?.robots;

export const getOfferListingSeoCanonicalByRules = (rules: IOfferListingSeoRule[]) => {
    const rule = matchOfferListingSeoRule(rules);

    return rule?.canonical && rule.canonical();
};
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferFilters, IOfferListingSeoRule, offerFilterKeys} from "./constants";

export const getOnlyFilterRecords = (filters: IOfferFilters) =>
    offerFilterKeys.reduce((result, currKey) => {
        return {
            ...result,
            ...{[currKey]: filters[currKey]}
        } as IOfferFilters;
    }, {} as IOfferFilters);

export const shouldHaveActiveFilters = (filters: IOfferFilters, filterNames: (keyof IOfferFilters)[]) =>
    filterNames.every((filterName) => !!filters[filterName]);

export const shouldHaveActiveSomeFilters = (filters: IOfferFilters, filterNames: (keyof IOfferFilters)[]) =>
    filterNames.some((filterName) => !!filters[filterName]);

export const doesExistAnyFilterBesidesAllowed = (filters: IOfferFilters, allowedFilterNames: (keyof IOfferFilters)[]) => {
    const restFilterKeys = Object.keys(getOnlyFilterRecords(filters)).filter((name) => !(allowedFilterNames as string[]).includes(name));

    return restFilterKeys.some((key) => !!filters[key as keyof IOfferFilters]);
};

export const doesFilterContainsNonListedValues = (filterValue: string | undefined, allowedValues: string[]) =>
    !filterValue || !allowedValues.includes(filterValue);

const matchOfferListingSeoRule = (rules: IOfferListingSeoRule[]) => rules.find((rule) => rule.condition());

export const getOfferListingSeoRobotsByRules = (rules: IOfferListingSeoRule[]): OfferMetaRobots | undefined => matchOfferListingSeoRule(rules)?.robots;

export const getOfferListingSeoCanonicalByRules = (rules: IOfferListingSeoRule[]) => {
    const rule = matchOfferListingSeoRule(rules);

    return rule?.canonical && rule.canonical();
};
