import {SearchSortString} from "../offer/list/constants/SearchSort";

export interface ILatestQuery {
    sort?: SearchSortString;
    is_luxury?: string;
    price_lower_than_average?: string;
    region?: string[];
    near_by_offer?: string;
    vendor?: string;
    lat?: string;
    lng?: string;
    type?: string;
    price_0?: string;
    price_1?: string;
    rooms_0?: string;
    rooms_1?: string;
    area_0?: string;
    area_1?: string;
    price_m2_0?: string;
    price_m2_1?: string;
    floor_0?: string;
    floor_1?: string;
    construction_end_date?: string;
    property_additional_areas?: string[];
    additional_areas?: string[];
    facilities?: string;
    offer_size?: string;
    is_holiday?: string;
    floor_choices?: string | string[];
    distance?: string;
    page?: number;
    house_type?: string | string[];
    house_additional_areas?: string | string[];
    house_storeys?: string | string[];
    country?: string;
    region_name?: string;
    instalment?: string;
}

// checks if value parsed from query param was a proper number before returning it. Returns undefined for values that are NaN
export const parseQueryParamToInt = (queryParam: string | undefined) => {
    const parsedValue = queryParam ? parseInt(queryParam, 10) : undefined;
    return Number.isFinite(parsedValue) ? parsedValue : undefined;
};
import {SearchSortString} from "../offer/list/constants/SearchSort";

export interface ILatestQuery {
    sort?: SearchSortString;
    is_luxury?: string;
    price_lower_than_average?: string;
    region?: string[];
    near_by_offer?: string;
    vendor?: string;
    lat?: string;
    lng?: string;
    type?: string;
    price_0?: string;
    price_1?: string;
    rooms_0?: string;
    rooms_1?: string;
    area_0?: string;
    area_1?: string;
    price_m2_0?: string;
    price_m2_1?: string;
    floor_0?: string;
    floor_1?: string;
    construction_end_date?: string;
    property_additional_areas?: string[];
    additional_areas?: string[];
    facilities?: string;
    offer_size?: string;
    is_holiday?: string;
    floor_choices?: string | string[];
    distance?: string;
    page?: number;
    house_type?: string | string[];
    house_additional_areas?: string | string[];
    house_storeys?: string | string[];
    country?: string;
    region_name?: string;
    instalment?: string;
}

// checks if value parsed from query param was a proper number before returning it. Returns undefined for values that are NaN
export const parseQueryParamToInt = (queryParam: string | undefined) => {
    const parsedValue = queryParam ? parseInt(queryParam, 10) : undefined;
    return Number.isFinite(parsedValue) ? parsedValue : undefined;
};
