import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferDetail} from "../../../meta_data/page_description/offer_detail";
import {titleOfferDetail} from "../../../meta_data/page_title/offer_detail";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";

export const updateOfferDetailMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {offer} = getState().offerDetail;

        if (offer) {
            const isDistrictOrNeighborhood = [RegionType.DISTRICT, RegionType.NEIGHBORHOOD].includes(offer.region.type);

            const title =
                offer.configuration.show_meta_fields && offer.configuration.meta_title
                    ? offer.configuration.meta_title
                    : titleOfferDetail({
                          offerType: offer.type || 0,
                          offerName: offer.name || "",
                          regionName: generateMetaRegionName(offer.region, false),
                          isSpain: offer.region.country === Country.SPAIN,
                          parentRegionName: offer.region.parent.name,
                          isDistrictOrNeighborhood
                      });
            const description =
                offer.configuration.show_meta_fields && offer.configuration.meta_description
                    ? offer.configuration.meta_description
                    : descriptionOfferDetail({
                          offerName: offer.name,
                          vendorName: offer.vendor.name,
                          offerType: offer.type,
                          isSpain: offer.region.country === Country.SPAIN,
                          regionName: generateMetaRegionName(offer.region, false),
                          parentRegionName: offer.region.parent.name,
                          isDistrictOrNeighborhood
                      });
            const dimensionRegion = offer.region.full_name;
            const gtmPage = "Widok inwestycji";
            const ogImage = offer.main_image.m_img_1500;
            const robots = offer.configuration.meta_robots;
            const metaData = updateMetaData({
                title,
                description,
                pathname: route.pathname,
                dimensionRegion,
                gtmPage,
                ogImage,
                robots
            });
            dispatch(metaData);
        }
        return true;
    };
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferDetail} from "../../../meta_data/page_description/offer_detail";
import {titleOfferDetail} from "../../../meta_data/page_title/offer_detail";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {Country} from "../../../region/types/Country";
import {RegionType} from "../../../region/types/RegionType";

export const updateOfferDetailMetaData =
    ({route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
        const {offer} = getState().offerDetail;

        if (offer) {
            const isDistrictOrNeighborhood = [RegionType.DISTRICT, RegionType.NEIGHBORHOOD].includes(offer.region.type);

            const title =
                offer.configuration.show_meta_fields && offer.configuration.meta_title
                    ? offer.configuration.meta_title
                    : titleOfferDetail({
                          offerType: offer.type || 0,
                          offerName: offer.name || "",
                          regionName: generateMetaRegionName(offer.region, false),
                          isSpain: offer.region.country === Country.SPAIN,
                          parentRegionName: offer.region.parent.name,
                          isDistrictOrNeighborhood
                      });
            const description =
                offer.configuration.show_meta_fields && offer.configuration.meta_description
                    ? offer.configuration.meta_description
                    : descriptionOfferDetail({
                          offerName: offer.name,
                          vendorName: offer.vendor.name,
                          offerType: offer.type,
                          isSpain: offer.region.country === Country.SPAIN,
                          regionName: generateMetaRegionName(offer.region, false),
                          parentRegionName: offer.region.parent.name,
                          isDistrictOrNeighborhood
                      });
            const dimensionRegion = offer.region.full_name;
            const gtmPage = "Widok inwestycji";
            const ogImage = offer.main_image.m_img_1500;
            const robots = offer.configuration.meta_robots;
            const metaData = updateMetaData({
                title,
                description,
                pathname: route.pathname,
                dimensionRegion,
                gtmPage,
                ogImage,
                robots
            });
            dispatch(metaData);
        }
        return true;
    };
