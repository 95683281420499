import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IOfferGroupsSimplified} from "../../offer/detail/types/IOfferGroups";
import {IPaginatedResponse} from "../../request/IResponse";
import {fetchSiteMapSelectedRegionBySlug} from "./fetch_site_map_region";

export interface ISiteMapOfferListOffer {
    id: number;
    name: string;
    slug: string;
    vendor: {
        id: number;
        slug: string;
        name: string;
    };
    groups: IOfferGroupsSimplified | null;
}

export interface ISitemapOfferListResponse extends IPaginatedResponse {
    results: ISiteMapOfferListOffer[];
}

const FETCH_SITE_MAP_OFFER_LIST = "SITE_MAP/OFFER/LIST";
export const fetchSiteMapOfferListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_OFFER_LIST);

const offerListApiLink = apiV2ListLink.offer.list(Scenario.SITEMAP_OFFER_LIST);

export const SITE_MAP_OFFER_LIST_PAGE_SIZE = 200;

export const fetchSiteMapOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {voivodeshipSlug: string}, {results: {slug: string}[]}>) => async (dispatch: Dispatch) => {
        dispatch({type: fetchSiteMapOfferListActionTypes.start});

        const page = ctx.route.query.page || 1;

        if (!ctx.prevResult.results.find((region) => region.slug === ctx.match.params.voivodeshipSlug)) {
            dispatch(enable301ResponseState(rpAppPath.siteMap.offer.base));
            return Promise.resolve(false);
        }

        const region = await dispatch(fetchSiteMapSelectedRegionBySlug(ctx.match.params.voivodeshipSlug, ctx));
        if (!region || !region.id) {
            dispatch(enable301ResponseState(rpAppPath.siteMap.offer.base));
            return Promise.resolve(false);
        }

        const fullQuery = {
            region: region.id,
            page_size: SITE_MAP_OFFER_LIST_PAGE_SIZE,
            page
        };

        const url = appendQueryString(offerListApiLink, fullQuery);

        return getRequest(ctx.meta, url)
            .then((json: ISitemapOfferListResponse) => {
                dispatch({type: fetchSiteMapOfferListActionTypes.success, result: json});
                return json;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IOfferGroupsSimplified} from "../../offer/detail/types/IOfferGroups";
import {IPaginatedResponse} from "../../request/IResponse";
import {fetchSiteMapSelectedRegionBySlug} from "./fetch_site_map_region";

export interface ISiteMapOfferListOffer {
    id: number;
    name: string;
    slug: string;
    vendor: {
        id: number;
        slug: string;
        name: string;
    };
    groups: IOfferGroupsSimplified | null;
}

export interface ISitemapOfferListResponse extends IPaginatedResponse {
    results: ISiteMapOfferListOffer[];
}

const FETCH_SITE_MAP_OFFER_LIST = "SITE_MAP/OFFER/LIST";
export const fetchSiteMapOfferListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_OFFER_LIST);

const offerListApiLink = apiV2ListLink.offer.list(Scenario.SITEMAP_OFFER_LIST);

export const SITE_MAP_OFFER_LIST_PAGE_SIZE = 200;

export const fetchSiteMapOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {voivodeshipSlug: string}, {results: {slug: string}[]}>) => async (dispatch: Dispatch) => {
        dispatch({type: fetchSiteMapOfferListActionTypes.start});

        const page = ctx.route.query.page || 1;

        if (!ctx.prevResult.results.find((region) => region.slug === ctx.match.params.voivodeshipSlug)) {
            dispatch(enable301ResponseState(rpAppPath.siteMap.offer.base));
            return Promise.resolve(false);
        }

        const region = await dispatch(fetchSiteMapSelectedRegionBySlug(ctx.match.params.voivodeshipSlug, ctx));
        if (!region || !region.id) {
            dispatch(enable301ResponseState(rpAppPath.siteMap.offer.base));
            return Promise.resolve(false);
        }

        const fullQuery = {
            region: region.id,
            page_size: SITE_MAP_OFFER_LIST_PAGE_SIZE,
            page
        };

        const url = appendQueryString(offerListApiLink, fullQuery);

        return getRequest(ctx.meta, url)
            .then((json: ISitemapOfferListResponse) => {
                dispatch({type: fetchSiteMapOfferListActionTypes.success, result: json});
                return json;
            })
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                })
            );
    };
