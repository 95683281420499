import {IVendorRegion} from "../../vendor/actions/fetch_vendor_list_region";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";
import {getPageTitle} from "../utils/get_page_title";

export interface ITitleVendorList {
    page: number;
    region: IVendorRegion | null;
}

export const titleVendorList = (props: ITitleVendorList) => {
    const regionName = props.region === null ? "w Polsce" : generateMetaRegionName(props.region);

    return getPageTitle(`Deweloper ${regionName}`, props.page);
};
import {IVendorRegion} from "../../vendor/actions/fetch_vendor_list_region";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";
import {getPageTitle} from "../utils/get_page_title";

export interface ITitleVendorList {
    page: number;
    region: IVendorRegion | null;
}

export const titleVendorList = (props: ITitleVendorList) => {
    const regionName = props.region === null ? "w Polsce" : generateMetaRegionName(props.region);

    return getPageTitle(`Deweloper ${regionName}`, props.page);
};
