import {apiV2Path} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRankingQuarter} from "../types/IRankingQuarter";

const rankingQuartersApiLink = apiV2Path.ratings.quarters;

// Sorted list (from the newest to the oldest) of quarters that have ranking data
export const getRankingQuarters = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRankingQuarters: build.query<IPaginatedResponse<IRankingQuarter>, void>({
            query: () => rankingQuartersApiLink
        })
    })
});

export const {useGetRankingQuartersQuery} = getRankingQuarters;
import {apiV2Path} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IRankingQuarter} from "../types/IRankingQuarter";

const rankingQuartersApiLink = apiV2Path.ratings.quarters;

// Sorted list (from the newest to the oldest) of quarters that have ranking data
export const getRankingQuarters = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRankingQuarters: build.query<IPaginatedResponse<IRankingQuarter>, void>({
            query: () => rankingQuartersApiLink
        })
    })
});

export const {useGetRankingQuartersQuery} = getRankingQuarters;
