import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateOurOfferMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = `Oferta dla dewelopera - ${BRAND_NAME}`;
    const description = `Zwiększ sprzedaż mieszkań dzięki skutecznej promocji ${concatWithBrandName("na portalu")}.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateOurOfferMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch) => {
    const title = `Oferta dla dewelopera - ${BRAND_NAME}`;
    const description = `Zwiększ sprzedaż mieszkań dzięki skutecznej promocji ${concatWithBrandName("na portalu")}.`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return true;
};
