import React, {useEffect, useState} from "react";

import {UserDeviceContext} from "../context/UserDeviceContext";
import {IUserDeviceContext} from "../interfaces/IUserDeviceContext";
import {isIOS} from "../utils/is_ios";
import {isMobile} from "../utils/is_mobile";

interface IProps {
    breakpoint?: number;
    children: React.ReactNode;
    userAgent?: string;
}
/*
 * Bear in mind that isMobile value may not be calculated correctly for the first time in a specific case.
 * Above will happen when using the resolution simulator via dev tools or browser extension.
 * In such a situation user-agent will still point to the device you are using and HTML content will flash.
 * Simulate user-agent (via dev-tools) change to remove the above problem.
 */
export const UserDeviceProvider = (props: IProps) => {
    const {breakpoint = 1024} = props;

    const [value, setValue] = useState<IUserDeviceContext>({isMobile: isMobile(props.userAgent), isIOS: isIOS(props.userAgent)});

    useEffect(() => {
        const listener = (event: MediaQueryListEvent) => {
            setValue({isMobile: !event.matches, isIOS: value.isIOS});
        };

        let mediaQuery: null | MediaQueryList = null;

        if (window) {
            mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);

            mediaQuery?.addEventListener?.("change", listener);
        }

        return () => {
            mediaQuery?.removeEventListener?.("change", listener);
        };
    }, []);

    useEffect(() => {
        if (window) {
            setValue({isMobile: window.innerWidth < breakpoint, isIOS: value.isIOS});
        }
    }, []);

    return <UserDeviceContext.Provider value={value}>{props.children}</UserDeviceContext.Provider>;
};
import React, {useEffect, useState} from "react";

import {UserDeviceContext} from "../context/UserDeviceContext";
import {IUserDeviceContext} from "../interfaces/IUserDeviceContext";
import {isIOS} from "../utils/is_ios";
import {isMobile} from "../utils/is_mobile";

interface IProps {
    breakpoint?: number;
    children: React.ReactNode;
    userAgent?: string;
}
/*
 * Bear in mind that isMobile value may not be calculated correctly for the first time in a specific case.
 * Above will happen when using the resolution simulator via dev tools or browser extension.
 * In such a situation user-agent will still point to the device you are using and HTML content will flash.
 * Simulate user-agent (via dev-tools) change to remove the above problem.
 */
export const UserDeviceProvider = (props: IProps) => {
    const {breakpoint = 1024} = props;

    const [value, setValue] = useState<IUserDeviceContext>({isMobile: isMobile(props.userAgent), isIOS: isIOS(props.userAgent)});

    useEffect(() => {
        const listener = (event: MediaQueryListEvent) => {
            setValue({isMobile: !event.matches, isIOS: value.isIOS});
        };

        let mediaQuery: null | MediaQueryList = null;

        if (window) {
            mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);

            mediaQuery?.addEventListener?.("change", listener);
        }

        return () => {
            mediaQuery?.removeEventListener?.("change", listener);
        };
    }, []);

    useEffect(() => {
        if (window) {
            setValue({isMobile: window.innerWidth < breakpoint, isIOS: value.isIOS});
        }
    }, []);

    return <UserDeviceContext.Provider value={value}>{props.children}</UserDeviceContext.Provider>;
};
