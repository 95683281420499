import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {updateMetaTitleDescription} from "./update_meta_data";

export const FETCH_CUSTOM_METADATA = "metaData/FETCH_CUSTOM_METADATA";
export const customMetaActionTypes = createRequestActionTypes(FETCH_CUSTOM_METADATA);

interface ISeoMetaDataResponse {
    heading_h1: string;
    title: string;
    description: string;
}

export type SeoMetaDataType = {
    title: string;
    description: string;
    heading_h1?: string;
};

export const fetchCustomMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: customMetaActionTypes.start});

    const query = {
        url: `${rpCommonUrl}${ctx.route.pathname}`
    };

    const url = appendQueryString(apiV2Link.metadata(null), query);

    return getRequest(ctx.meta, url)
        .then((res: ISeoMetaDataResponse) => {
            const seoMeta = {
                title: res.title,
                description: res.description,
                heading_h1: res.heading_h1
            };

            dispatch(updateMetaTitleDescription(seoMeta.title, seoMeta.description));
            dispatch({type: customMetaActionTypes.success, result: seoMeta});

            return seoMeta;
        })
        .catch(
            catch404(() => {
                dispatch({type: customMetaActionTypes.reset});
                return true;
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {rpCommonUrl} from "../../app/read_rp_environment_variables";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {updateMetaTitleDescription} from "./update_meta_data";

export const FETCH_CUSTOM_METADATA = "metaData/FETCH_CUSTOM_METADATA";
export const customMetaActionTypes = createRequestActionTypes(FETCH_CUSTOM_METADATA);

interface ISeoMetaDataResponse {
    heading_h1: string;
    title: string;
    description: string;
}

export type SeoMetaDataType = {
    title: string;
    description: string;
    heading_h1?: string;
};

export const fetchCustomMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: customMetaActionTypes.start});

    const query = {
        url: `${rpCommonUrl}${ctx.route.pathname}`
    };

    const url = appendQueryString(apiV2Link.metadata(null), query);

    return getRequest(ctx.meta, url)
        .then((res: ISeoMetaDataResponse) => {
            const seoMeta = {
                title: res.title,
                description: res.description,
                heading_h1: res.heading_h1
            };

            dispatch(updateMetaTitleDescription(seoMeta.title, seoMeta.description));
            dispatch({type: customMetaActionTypes.success, result: seoMeta});

            return seoMeta;
        })
        .catch(
            catch404(() => {
                dispatch({type: customMetaActionTypes.reset});
                return true;
            })
        );
};
