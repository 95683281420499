import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchSelectedRegionBySlug} from "../../list/actions/offer_list_selected_data_actions";
import {getCountryId} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {fetchSearchParsedOnError} from "../searches/fetch_search";
import {fromParsedObjectToQuery, ISlugParsed, ISlugQuery, offerUrlParser} from "./offer_url_parser";

export const parseFriendlyUrl =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch): Promise<Partial<ISlugQuery> | false> => {
        const slugParsed: Partial<ISlugParsed> | null = offerUrlParser(ctx.match.params.friendlySlug, ctx.match.params.offerListSubFilter);
        const country = ctx.match?.params?.country ? getCountryId(ctx.match?.params?.country) : undefined;
        // Pass country to slugParsed if the country comes from an abroad URL
        if (country && slugParsed) {
            slugParsed.country = country;
        }
        if (slugParsed === null || isEmpty(slugParsed)) {
            // not valid slug - return search
            // not parsed properly
            return await getSearchQueryOnError(ctx, dispatch);
        }

        const region = slugParsed.region || ctx.match?.params?.abroadRegionSlug;
        // fetch region id when defined in slug
        const regionData = region ? await getRegionIdAndCountry(ctx, dispatch, [region], country) : null;
        // cannot find proper region - return search
        if (slugParsed.region && !regionData?.id) {
            return await getSearchQueryOnError(ctx, dispatch);
        }

        if (regionData?.id) {
            // region found, we need id
            slugParsed.region = [regionData?.id.toString()];
            slugParsed.region_name = Array.isArray(region) ? region[0] : region;
        }
        //pass country to be able to do redirect on old abroad listings urls
        if (regionData?.country && !country) {
            slugParsed.country = regionData.country;
        }

        // prepare full query (with strings)
        const slugQuery = slugParsed ? fromParsedObjectToQuery(slugParsed) : {};
        return {...ctx.route.query, ...slugQuery};
    };

async function getSearchQueryOnError(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch) {
    const searchQuery = await dispatch(fetchSearchParsedOnError(ctx));
    return searchQuery === null ? false : {...ctx.route.query, ...searchQuery};
}

async function getRegionIdAndCountry(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch, region?: string | string[], country?: number) {
    if (!region) {
        return null;
    }

    const parsedRegion = region[0];
    const fetchedRegion = await dispatch(fetchSelectedRegionBySlug(ctx.meta, parsedRegion, country));

    if (fetchedRegion === null || fetchedRegion.id === null) {
        return null;
    }

    return {id: fetchedRegion.id, country: fetchedRegion.country};
}
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty} from "@pg-mono/nodash";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchSelectedRegionBySlug} from "../../list/actions/offer_list_selected_data_actions";
import {getCountryId} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {fetchSearchParsedOnError} from "../searches/fetch_search";
import {fromParsedObjectToQuery, ISlugParsed, ISlugQuery, offerUrlParser} from "./offer_url_parser";

export const parseFriendlyUrl =
    (ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch): Promise<Partial<ISlugQuery> | false> => {
        const slugParsed: Partial<ISlugParsed> | null = offerUrlParser(ctx.match.params.friendlySlug, ctx.match.params.offerListSubFilter);
        const country = ctx.match?.params?.country ? getCountryId(ctx.match?.params?.country) : undefined;
        // Pass country to slugParsed if the country comes from an abroad URL
        if (country && slugParsed) {
            slugParsed.country = country;
        }
        if (slugParsed === null || isEmpty(slugParsed)) {
            // not valid slug - return search
            // not parsed properly
            return await getSearchQueryOnError(ctx, dispatch);
        }

        const region = slugParsed.region || ctx.match?.params?.abroadRegionSlug;
        // fetch region id when defined in slug
        const regionData = region ? await getRegionIdAndCountry(ctx, dispatch, [region], country) : null;
        // cannot find proper region - return search
        if (slugParsed.region && !regionData?.id) {
            return await getSearchQueryOnError(ctx, dispatch);
        }

        if (regionData?.id) {
            // region found, we need id
            slugParsed.region = [regionData?.id.toString()];
            slugParsed.region_name = Array.isArray(region) ? region[0] : region;
        }
        //pass country to be able to do redirect on old abroad listings urls
        if (regionData?.country && !country) {
            slugParsed.country = regionData.country;
        }

        // prepare full query (with strings)
        const slugQuery = slugParsed ? fromParsedObjectToQuery(slugParsed) : {};
        return {...ctx.route.query, ...slugQuery};
    };

async function getSearchQueryOnError(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch) {
    const searchQuery = await dispatch(fetchSearchParsedOnError(ctx));
    return searchQuery === null ? false : {...ctx.route.query, ...searchQuery};
}

async function getRegionIdAndCountry(ctx: IFetchContext<IRPRequestMeta>, dispatch: Dispatch, region?: string | string[], country?: number) {
    if (!region) {
        return null;
    }

    const parsedRegion = region[0];
    const fetchedRegion = await dispatch(fetchSelectedRegionBySlug(ctx.meta, parsedRegion, country));

    if (fetchedRegion === null || fetchedRegion.id === null) {
        return null;
    }

    return {id: fetchedRegion.id, country: fetchedRegion.country};
}
