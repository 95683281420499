import {gtmId} from "../../app/read_rp_environment_variables";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";

declare global {
    interface Window {
        dataLayer: object[];
        pgGtmLib: {
            gtmHitLib: (gtmData: IGtmData) => void;
        };
    }
}

const GTM_ID = gtmId;
let isGtmInitialized = false;

/**
 * Init
 */
export const initGoogleTagManager = (): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    // fetch/init GTM
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (function (w: Window, d, s: "script", dataLayer: "dataLayer", gtmId: string) {
        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        const firstScriptElem = d.getElementsByTagName(s)[0] as HTMLScriptElement;
        const newScriptElem = d.createElement(s);
        newScriptElem.async = true;
        newScriptElem.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScriptElem.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })(win, document, "script", "dataLayer", GTM_ID);

    // this exception is provided only for desktop-homepage (for now) - requires favourites unification
    win.pgGtmLib = win.pgGtmLib || {};
    win.pgGtmLib.gtmHitLib = hitGoogleTagManager; // make it available for desktop integrations, e.g. homepage

    isGtmInitialized = true;
};

/**
 * Config
 */
interface IConfigGTM {
    viewType: ViewType | null;
    regionName: string | null;
    vendorName: string | null;
    userId: string | null;
    userGaId: string | null;
    blogCategory: string[] | null;
    businessStatus: string | null;
    investorPotential: string | null;
    hasPageProjection2d?: boolean | null;
}
export const configGoogleTagManager = (config: IConfigGTM): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }

    interface IGtmGroup {
        pageType: string;
        contentRegion: string;
        contentDeveloperName: string;
        userID_CRM: string;
        userID_GA: string;
        userLoggedIn: "tak" | "nie";
        blogCategory: string[] | string;
        businessStatus: string;
        investorPotential: string;
        projection2d: string;
    }

    // on page load run script to group future content sent do GTM
    (function (w: Window, dataLayer: "dataLayer") {
        w[dataLayer] = w[dataLayer] || [];
        const gtmData: IGtmGroup = {
            pageType: config.viewType != null ? getViewTypeName(config.viewType) : "na",
            contentRegion: config.regionName ?? "na",
            contentDeveloperName: config.vendorName ?? "na",
            userID_CRM: config.userId ?? "na",
            userID_GA: config.userGaId ?? "na",
            userLoggedIn: config.userId != null ? "tak" : "nie",
            blogCategory: config.blogCategory ?? "na",
            businessStatus: config.businessStatus ?? "na",
            investorPotential: config.investorPotential ?? "na",
            projection2d: config.hasPageProjection2d ? "tak" : "nie"
        };
        w[dataLayer].push(gtmData);
    })(win, "dataLayer");
};

/**
 * Hit
 */
const MAX_ELEMENTS_PER_HIT = 10;

export interface IGtmData {
    event: string;
    button?: string;
    ecommerce?: {
        [key: string]: object[] | unknown;
        checkout?: unknown;
        currencyCode?: unknown;
        impressions?: object[];
        purchase?: unknown;
        items?: object[];
    };
    action?: string;
    label?: string | number;
}

export const hitGoogleTagManager = <GtmData extends IGtmData>(gtmData: GtmData) => {
    const win = window as Window;

    win.dataLayer = win.dataLayer || [];
    const chunkDataAndPush = (key: string) => {
        const elements = gtmData.ecommerce?.[key];
        if (Array.isArray(elements) && elements?.length > MAX_ELEMENTS_PER_HIT) {
            const chunks = Math.ceil(elements.length / MAX_ELEMENTS_PER_HIT);
            for (let i = 0; i < chunks; ++i) {
                const chunkData = structuredClone(gtmData);
                chunkData.ecommerce![key] = elements.slice(i * MAX_ELEMENTS_PER_HIT, (i + 1) * MAX_ELEMENTS_PER_HIT);
                win.dataLayer.push(chunkData);
            }
        } else {
            win.dataLayer.push(gtmData);
        }
    };

    // need to chunk elements bcs of GTM limit
    if ((gtmData.ecommerce?.impressions?.length ?? 0) > MAX_ELEMENTS_PER_HIT) {
        chunkDataAndPush("impressions");
    } else if ((gtmData.ecommerce?.items?.length ?? 0) > MAX_ELEMENTS_PER_HIT) {
        chunkDataAndPush("items");
    } else {
        win.dataLayer.push(gtmData);
    }
};
import {gtmId} from "../../app/read_rp_environment_variables";
import {getViewTypeName, ViewType} from "../../view_type/ViewType";

declare global {
    interface Window {
        dataLayer: object[];
        pgGtmLib: {
            gtmHitLib: (gtmData: IGtmData) => void;
        };
    }
}

const GTM_ID = gtmId;
let isGtmInitialized = false;

/**
 * Init
 */
export const initGoogleTagManager = (): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }
    // initialize only once
    if (isGtmInitialized) {
        return;
    }
    // fetch/init GTM
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (function (w: Window, d, s: "script", dataLayer: "dataLayer", gtmId: string) {
        w[dataLayer] = w[dataLayer] || [];
        w[dataLayer].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js"
        });
        const firstScriptElem = d.getElementsByTagName(s)[0] as HTMLScriptElement;
        const newScriptElem = d.createElement(s);
        newScriptElem.async = true;
        newScriptElem.src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        firstScriptElem.parentNode?.insertBefore(newScriptElem, firstScriptElem);
    })(win, document, "script", "dataLayer", GTM_ID);

    // this exception is provided only for desktop-homepage (for now) - requires favourites unification
    win.pgGtmLib = win.pgGtmLib || {};
    win.pgGtmLib.gtmHitLib = hitGoogleTagManager; // make it available for desktop integrations, e.g. homepage

    isGtmInitialized = true;
};

/**
 * Config
 */
interface IConfigGTM {
    viewType: ViewType | null;
    regionName: string | null;
    vendorName: string | null;
    userId: string | null;
    userGaId: string | null;
    blogCategory: string[] | null;
    businessStatus: string | null;
    investorPotential: string | null;
    hasPageProjection2d?: boolean | null;
}
export const configGoogleTagManager = (config: IConfigGTM): void => {
    const win = window as Window;

    // initialize only in browser
    if (process.env.EXEC_ENV !== "browser") {
        return;
    }

    interface IGtmGroup {
        pageType: string;
        contentRegion: string;
        contentDeveloperName: string;
        userID_CRM: string;
        userID_GA: string;
        userLoggedIn: "tak" | "nie";
        blogCategory: string[] | string;
        businessStatus: string;
        investorPotential: string;
        projection2d: string;
    }

    // on page load run script to group future content sent do GTM
    (function (w: Window, dataLayer: "dataLayer") {
        w[dataLayer] = w[dataLayer] || [];
        const gtmData: IGtmGroup = {
            pageType: config.viewType != null ? getViewTypeName(config.viewType) : "na",
            contentRegion: config.regionName ?? "na",
            contentDeveloperName: config.vendorName ?? "na",
            userID_CRM: config.userId ?? "na",
            userID_GA: config.userGaId ?? "na",
            userLoggedIn: config.userId != null ? "tak" : "nie",
            blogCategory: config.blogCategory ?? "na",
            businessStatus: config.businessStatus ?? "na",
            investorPotential: config.investorPotential ?? "na",
            projection2d: config.hasPageProjection2d ? "tak" : "nie"
        };
        w[dataLayer].push(gtmData);
    })(win, "dataLayer");
};

/**
 * Hit
 */
const MAX_ELEMENTS_PER_HIT = 10;

export interface IGtmData {
    event: string;
    button?: string;
    ecommerce?: {
        [key: string]: object[] | unknown;
        checkout?: unknown;
        currencyCode?: unknown;
        impressions?: object[];
        purchase?: unknown;
        items?: object[];
    };
    action?: string;
    label?: string | number;
}

export const hitGoogleTagManager = <GtmData extends IGtmData>(gtmData: GtmData) => {
    const win = window as Window;

    win.dataLayer = win.dataLayer || [];
    const chunkDataAndPush = (key: string) => {
        const elements = gtmData.ecommerce?.[key];
        if (Array.isArray(elements) && elements?.length > MAX_ELEMENTS_PER_HIT) {
            const chunks = Math.ceil(elements.length / MAX_ELEMENTS_PER_HIT);
            for (let i = 0; i < chunks; ++i) {
                const chunkData = structuredClone(gtmData);
                chunkData.ecommerce![key] = elements.slice(i * MAX_ELEMENTS_PER_HIT, (i + 1) * MAX_ELEMENTS_PER_HIT);
                win.dataLayer.push(chunkData);
            }
        } else {
            win.dataLayer.push(gtmData);
        }
    };

    // need to chunk elements bcs of GTM limit
    if ((gtmData.ecommerce?.impressions?.length ?? 0) > MAX_ELEMENTS_PER_HIT) {
        chunkDataAndPush("impressions");
    } else if ((gtmData.ecommerce?.items?.length ?? 0) > MAX_ELEMENTS_PER_HIT) {
        chunkDataAndPush("items");
    } else {
        win.dataLayer.push(gtmData);
    }
};
