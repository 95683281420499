import {RegionType} from "../../region/types/RegionType";

interface IRegionDeclensionWhere {
    type: RegionType;
    name: string;
    short_name: string;
    full_name: string;
    name_declension_where: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
}

export const generateMetaRegionNameDeclensionWhere = (region: IRegionDeclensionWhere) => {
    switch (region.type) {
        case RegionType.DISTRICT:
        case RegionType.NEIGHBORHOOD:
            return `w lokalizacji ${region.short_name}`; // "w lokalizacji Warszawa, Wola",  "w lokalizacji Warszawa, Wola, Mirów"
        case RegionType.AGGREGATION:
            return region.name_declension_where; // "w Warszawie i okolicach"
        case RegionType.COUNTY:
            return `${region.name_declension_where}${region.parent && region.is_duplicate ? `, województwo ${region.parent.name}` : ""}`; // "powiecie piaseczyńskim", "powiecie bielskim, województwo mazowieckie"
        case RegionType.TOWN:
            return `${region.name_declension_where}${region.parent ? `, powiat ${region.parent.name}` : ""}`; // "Józefosław, powiat piaseczyński"
        case RegionType.CITY:
            return `${region.name_declension_where}${region.parent && region.is_duplicate ? `, powiat ${region.parent.name}` : ""}`; // "w Warszawie", "w Józefowie, powiat otwocki"
        case RegionType.VOIVODESHIP:
        default:
            return region.name_declension_where; // "w woj. mazowieckim"
    }
};
import {RegionType} from "../../region/types/RegionType";

interface IRegionDeclensionWhere {
    type: RegionType;
    name: string;
    short_name: string;
    full_name: string;
    name_declension_where: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
}

export const generateMetaRegionNameDeclensionWhere = (region: IRegionDeclensionWhere) => {
    switch (region.type) {
        case RegionType.DISTRICT:
        case RegionType.NEIGHBORHOOD:
            return `w lokalizacji ${region.short_name}`; // "w lokalizacji Warszawa, Wola",  "w lokalizacji Warszawa, Wola, Mirów"
        case RegionType.AGGREGATION:
            return region.name_declension_where; // "w Warszawie i okolicach"
        case RegionType.COUNTY:
            return `${region.name_declension_where}${region.parent && region.is_duplicate ? `, województwo ${region.parent.name}` : ""}`; // "powiecie piaseczyńskim", "powiecie bielskim, województwo mazowieckie"
        case RegionType.TOWN:
            return `${region.name_declension_where}${region.parent ? `, powiat ${region.parent.name}` : ""}`; // "Józefosław, powiat piaseczyński"
        case RegionType.CITY:
            return `${region.name_declension_where}${region.parent && region.is_duplicate ? `, powiat ${region.parent.name}` : ""}`; // "w Warszawie", "w Józefowie, powiat otwocki"
        case RegionType.VOIVODESHIP:
        default:
            return region.name_declension_where; // "w woj. mazowieckim"
    }
};
