import React, {CSSProperties, PropsWithChildren, ReactNode, useState} from "react";
import classNames from "classnames";

import {ChevronDownIcon} from "@pg-design/icons-module";

import {
    panelExpandableBody,
    panelExpandableBodyMD,
    panelExpandableBodySM,
    panelExpandableHeader,
    panelExpandableIconWrapper,
    panelExpandableIconWrapperMD,
    panelExpandableWrapper,
    panelExpandableWrapperMD
} from "./PanelExpandable.module.css";

export interface IProps {
    title: ReactNode;
    panelArrowIcon?: ReactNode;
    headerCss?: CSSProperties;
    alwaysOpen?: boolean;
    disableOnDesktop?: boolean;
    className?: string;
    testId?: string;
}

export const PanelExpandable: React.FC<PropsWithChildren<IProps>> = (props) => {
    const disableOnDesktop = typeof props.disableOnDesktop === "boolean" ? props.disableOnDesktop : true;

    const [isOpen, setIsOpen] = useState(props.alwaysOpen || false);

    const onClick = () => {
        if (!props.alwaysOpen) {
            setIsOpen(!isOpen);
        }
    };

    const defaultPanelArrowIcon = <ChevronDownIcon size="1.8" />;
    const iconWrapperTransform: CSSProperties = {transform: isOpen ? "rotate(180deg)" : ""};
    const bodyDynamicStyle: CSSProperties = {overflow: !isOpen ? "hidden" : "visible", "--maxHeight": isOpen ? "900px" : "0"};

    const panelExpandableWrapperCN = classNames(props.className, panelExpandableWrapper, disableOnDesktop && panelExpandableWrapperMD);
    const panelExpandableIconWrapperCN = classNames(panelExpandableIconWrapper, disableOnDesktop && panelExpandableIconWrapperMD);
    const panelExpandableBodyCN = classNames(panelExpandableBody, disableOnDesktop && panelExpandableBodyMD, disableOnDesktop && panelExpandableBodySM);

    return (
        <div className={panelExpandableWrapperCN} data-testid={props.testId}>
            <div className={panelExpandableHeader} onClick={onClick} style={props.headerCss}>
                {props.title}
                {!props.alwaysOpen && (
                    <div className={panelExpandableIconWrapperCN} style={iconWrapperTransform}>
                        {props.panelArrowIcon || defaultPanelArrowIcon}
                    </div>
                )}
            </div>
            <div className={panelExpandableBodyCN} style={bodyDynamicStyle}>
                {props.children}
            </div>
        </div>
    );
};
import React, {CSSProperties, PropsWithChildren, ReactNode, useState} from "react";
import classNames from "classnames";

import {ChevronDownIcon} from "@pg-design/icons-module";

import {
    panelExpandableBody,
    panelExpandableBodyMD,
    panelExpandableBodySM,
    panelExpandableHeader,
    panelExpandableIconWrapper,
    panelExpandableIconWrapperMD,
    panelExpandableWrapper,
    panelExpandableWrapperMD
} from "./PanelExpandable.module.css";

export interface IProps {
    title: ReactNode;
    panelArrowIcon?: ReactNode;
    headerCss?: CSSProperties;
    alwaysOpen?: boolean;
    disableOnDesktop?: boolean;
    className?: string;
    testId?: string;
}

export const PanelExpandable: React.FC<PropsWithChildren<IProps>> = (props) => {
    const disableOnDesktop = typeof props.disableOnDesktop === "boolean" ? props.disableOnDesktop : true;

    const [isOpen, setIsOpen] = useState(props.alwaysOpen || false);

    const onClick = () => {
        if (!props.alwaysOpen) {
            setIsOpen(!isOpen);
        }
    };

    const defaultPanelArrowIcon = <ChevronDownIcon size="1.8" />;
    const iconWrapperTransform: CSSProperties = {transform: isOpen ? "rotate(180deg)" : ""};
    const bodyDynamicStyle: CSSProperties = {overflow: !isOpen ? "hidden" : "visible", "--maxHeight": isOpen ? "900px" : "0"};

    const panelExpandableWrapperCN = classNames(props.className, panelExpandableWrapper, disableOnDesktop && panelExpandableWrapperMD);
    const panelExpandableIconWrapperCN = classNames(panelExpandableIconWrapper, disableOnDesktop && panelExpandableIconWrapperMD);
    const panelExpandableBodyCN = classNames(panelExpandableBody, disableOnDesktop && panelExpandableBodyMD, disableOnDesktop && panelExpandableBodySM);

    return (
        <div className={panelExpandableWrapperCN} data-testid={props.testId}>
            <div className={panelExpandableHeader} onClick={onClick} style={props.headerCss}>
                {props.title}
                {!props.alwaysOpen && (
                    <div className={panelExpandableIconWrapperCN} style={iconWrapperTransform}>
                        {props.panelArrowIcon || defaultPanelArrowIcon}
                    </div>
                )}
            </div>
            <div className={panelExpandableBodyCN} style={bodyDynamicStyle}>
                {props.children}
            </div>
        </div>
    );
};
