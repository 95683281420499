import {OfferType} from "../../offer/types/OfferType";
import {getPageTitle} from "../utils/get_page_title";

export interface ITitleOfferDetail {
    offerName: string;
    offerType: OfferType;
    regionName: string;
    isSpain: boolean;
    parentRegionName: string;
    isDistrictOrNeighborhood?: boolean;
}

export const titleOfferDetail = (props: ITitleOfferDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = "mieszkania";
            break;
        case OfferType.HOUSE:
            type = "domy";
            break;
        case OfferType.COMMERCIAL:
            type = "lokale użytkowe";
            break;
        default:
            break;
    }

    if (props.isSpain) {
        return getPageTitle(`${props.offerName} - ${type} Hiszpania, ${props.parentRegionName}`);
    }

    if (props.isDistrictOrNeighborhood) {
        if (props.offerType === OfferType.FLAT) {
            return getPageTitle(`${props.offerName.toUpperCase()} ceny, zdjęcia`);
        }
        return getPageTitle(`${props.offerName} ${props.regionName.split(",")[0].trim()}: ${type} na sprzedaż`);
    }

    if (props.offerType === OfferType.FLAT) {
        return getPageTitle(`${props.offerName.toUpperCase()} ceny, zdjęcia`);
    }

    return getPageTitle(`${props.offerName} ${props.regionName} - ${type} na sprzedaż`);
};
import {OfferType} from "../../offer/types/OfferType";
import {getPageTitle} from "../utils/get_page_title";

export interface ITitleOfferDetail {
    offerName: string;
    offerType: OfferType;
    regionName: string;
    isSpain: boolean;
    parentRegionName: string;
    isDistrictOrNeighborhood?: boolean;
}

export const titleOfferDetail = (props: ITitleOfferDetail) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = "mieszkania";
            break;
        case OfferType.HOUSE:
            type = "domy";
            break;
        case OfferType.COMMERCIAL:
            type = "lokale użytkowe";
            break;
        default:
            break;
    }

    if (props.isSpain) {
        return getPageTitle(`${props.offerName} - ${type} Hiszpania, ${props.parentRegionName}`);
    }

    if (props.isDistrictOrNeighborhood) {
        if (props.offerType === OfferType.FLAT) {
            return getPageTitle(`${props.offerName.toUpperCase()} ceny, zdjęcia`);
        }
        return getPageTitle(`${props.offerName} ${props.regionName.split(",")[0].trim()}: ${type} na sprzedaż`);
    }

    if (props.offerType === OfferType.FLAT) {
        return getPageTitle(`${props.offerName.toUpperCase()} ceny, zdjęcia`);
    }

    return getPageTitle(`${props.offerName} ${props.regionName} - ${type} na sprzedaż`);
};
