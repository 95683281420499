import {useEffect, useRef, useState} from "react";

interface IParams {
    isActive: boolean;
    initialHeight?: number;
}

export const useHeightObserver = ({isActive, initialHeight = 0}: IParams) => {
    const [clientHeight, setClientHeight] = useState<number | undefined>(initialHeight);
    const elementRef = useRef<HTMLElement>();
    const observerRef = useRef<ResizeObserver>();

    if (typeof ResizeObserver !== "undefined" && !observerRef.current) {
        observerRef.current = new ResizeObserver((entries) => {
            setClientHeight(entries[0].target.clientHeight);
        });
    }

    useEffect(() => {
        observerRef.current?.disconnect();

        if (isActive && elementRef.current) {
            observerRef.current?.observe(elementRef.current);
        }

        return () => {
            observerRef.current?.disconnect();
        };
    }, [isActive]);

    return {
        ref: elementRef,
        height: clientHeight
    };
};
import {useEffect, useRef, useState} from "react";

interface IParams {
    isActive: boolean;
    initialHeight?: number;
}

export const useHeightObserver = ({isActive, initialHeight = 0}: IParams) => {
    const [clientHeight, setClientHeight] = useState<number | undefined>(initialHeight);
    const elementRef = useRef<HTMLElement>();
    const observerRef = useRef<ResizeObserver>();

    if (typeof ResizeObserver !== "undefined" && !observerRef.current) {
        observerRef.current = new ResizeObserver((entries) => {
            setClientHeight(entries[0].target.clientHeight);
        });
    }

    useEffect(() => {
        observerRef.current?.disconnect();

        if (isActive && elementRef.current) {
            observerRef.current?.observe(elementRef.current);
        }

        return () => {
            observerRef.current?.disconnect();
        };
    }, [isActive]);

    return {
        ref: elementRef,
        height: clientHeight
    };
};
