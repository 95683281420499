import {houseFiltersToFriendQuery} from "../../../../constants/house_filter";
import {OfferListSubType} from "../../../types/OfferListSubType";
import {IFiltersParam, isSingleHouseFilterSelected} from "../validator/is_single_house_filter_selected";

export const getOfferListSubTypeByHouseFilter = (houseFilter: IFiltersParam) => {
    if (!isSingleHouseFilterSelected(houseFilter)) {
        throw new Error("Cannot transform more than one house filter into offerListSubType");
    }

    const houseTypeFilter = houseFilter.house_type
        ? houseFiltersToFriendQuery.house_type[Array.isArray(houseFilter.house_type) ? houseFilter.house_type[0] : houseFilter.house_type]
        : undefined;

    const houseAdditionalAreasFilter = houseFilter.house_additional_areas
        ? houseFiltersToFriendQuery.house_additional_areas[houseFilter.house_additional_areas[0]]
        : undefined;

    const houseFloorOptionsFilter = houseFilter.house_storeys
        ? houseFiltersToFriendQuery.house_storeys[Array.isArray(houseFilter.house_storeys) ? houseFilter.house_storeys[0] : houseFilter.house_storeys]
        : undefined;

    // one of house filters has type OfferListSubType
    return (houseTypeFilter || houseAdditionalAreasFilter || houseFloorOptionsFilter) as OfferListSubType;
};
import {houseFiltersToFriendQuery} from "../../../../constants/house_filter";
import {OfferListSubType} from "../../../types/OfferListSubType";
import {IFiltersParam, isSingleHouseFilterSelected} from "../validator/is_single_house_filter_selected";

export const getOfferListSubTypeByHouseFilter = (houseFilter: IFiltersParam) => {
    if (!isSingleHouseFilterSelected(houseFilter)) {
        throw new Error("Cannot transform more than one house filter into offerListSubType");
    }

    const houseTypeFilter = houseFilter.house_type
        ? houseFiltersToFriendQuery.house_type[Array.isArray(houseFilter.house_type) ? houseFilter.house_type[0] : houseFilter.house_type]
        : undefined;

    const houseAdditionalAreasFilter = houseFilter.house_additional_areas
        ? houseFiltersToFriendQuery.house_additional_areas[houseFilter.house_additional_areas[0]]
        : undefined;

    const houseFloorOptionsFilter = houseFilter.house_storeys
        ? houseFiltersToFriendQuery.house_storeys[Array.isArray(houseFilter.house_storeys) ? houseFilter.house_storeys[0] : houseFilter.house_storeys]
        : undefined;

    // one of house filters has type OfferListSubType
    return (houseTypeFilter || houseAdditionalAreasFilter || houseFloorOptionsFilter) as OfferListSubType;
};
