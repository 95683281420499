import {BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const bdBaseQuery = (api: BaseQueryApi) =>
    fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api)
    });

const bdServiceBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    return bdBaseQuery(api)(args, api, extraOptions);
};

export const bdApi = createApi({
    reducerPath: "bdApi",
    baseQuery: bdServiceBaseQuery,
    endpoints: () => ({})
});
import {BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const bdBaseQuery = (api: BaseQueryApi) =>
    fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api)
    });

const bdServiceBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    return bdBaseQuery(api)(args, api, extraOptions);
};

export const bdApi = createApi({
    reducerPath: "bdApi",
    baseQuery: bdServiceBaseQuery,
    endpoints: () => ({})
});
