import * as lscache from "lscache";

import {getFavouriteObject, IFavourite, initLocalStorageFavouritesService} from "@pg-mono/favourites";
import {isEmpty} from "@pg-mono/nodash";

import {FAVOURITE_LOCAL_STORAGE_KEY_V2, FAVOURITE_LOCAL_STORAGE_KEY_V3} from "../../app/constants/storage_keys";

// v1 (no suffix): only offerId keys and propertyId array as value
// v2: offers and articles keys
// v3: one list of favourites of all types - generic favourite model (no articles at this moment)

export interface IFavouritesV2 {
    offers: {
        [offerId: string]: number[];
    };
    articles: number[];
}

export const localStorageFavouritesService = initLocalStorageFavouritesService(FAVOURITE_LOCAL_STORAGE_KEY_V3);

// Method transforms old favourites values (saved in local storage) to new API favourites structure
// `number: []` = offer like
// `number: [number, number]` = separate like for offer and each property
export const transformLocalStorageFavouritesToNewApi = (favourites: IFavouritesV2): IFavourite[] => {
    if (isEmpty(favourites.offers)) {
        return [];
    }

    return Object.entries(favourites.offers)
        .map(([offerId, propertyIds]) => {
            if (isEmpty(propertyIds)) {
                return getFavouriteObject({offerId: parseInt(offerId)});
            }
            return [getFavouriteObject({offerId: parseInt(offerId)}), ...propertyIds.map((propertyId) => getFavouriteObject({propertyId: propertyId}))];
        })
        .flat();
};

export const getLocalStorageFavourites = (): IFavourite[] => {
    let favourites = localStorageFavouritesService.getLocalStorageFavourites();

    // TODO: Remove this code (lines 20-35) after e.g. 1 year from the date of release
    const favouritesV2: IFavouritesV2 = lscache.get(FAVOURITE_LOCAL_STORAGE_KEY_V2);

    if (isEmpty(favouritesV2)) {
        return favourites;
    }

    /*
     * If local storage contains value for `FAVOURITE_LOCAL_STORAGE_KEY_V2` and value for `FAVOURITE_LOCAL_STORAGE_KEY_V3`
     * is empty then transform `FAVOURITE_LOCAL_STORAGE_KEY_V2` value to `FAVOURITE_LOCAL_STORAGE_KEY_V3` value.
     */
    if (isEmpty(favourites)) {
        favourites = transformLocalStorageFavouritesToNewApi(favouritesV2);
        // Set transformed `FAVOURITE_LOCAL_STORAGE_KEY_V2` value as `FAVOURITE_LOCAL_STORAGE_KEY_V3` value
        lscache.set(FAVOURITE_LOCAL_STORAGE_KEY_V3, favourites);
    }

    // Finally, remove `FAVOURITE_LOCAL_STORAGE_KEY_V2` value - we don't need this value anymore.
    lscache.remove(FAVOURITE_LOCAL_STORAGE_KEY_V2);

    return favourites;
};

export const clearLocalStorageFavourites = () => {
    lscache.remove(FAVOURITE_LOCAL_STORAGE_KEY_V3);
};
import * as lscache from "lscache";

import {getFavouriteObject, IFavourite, initLocalStorageFavouritesService} from "@pg-mono/favourites";
import {isEmpty} from "@pg-mono/nodash";

import {FAVOURITE_LOCAL_STORAGE_KEY_V2, FAVOURITE_LOCAL_STORAGE_KEY_V3} from "../../app/constants/storage_keys";

// v1 (no suffix): only offerId keys and propertyId array as value
// v2: offers and articles keys
// v3: one list of favourites of all types - generic favourite model (no articles at this moment)

export interface IFavouritesV2 {
    offers: {
        [offerId: string]: number[];
    };
    articles: number[];
}

export const localStorageFavouritesService = initLocalStorageFavouritesService(FAVOURITE_LOCAL_STORAGE_KEY_V3);

// Method transforms old favourites values (saved in local storage) to new API favourites structure
// `number: []` = offer like
// `number: [number, number]` = separate like for offer and each property
export const transformLocalStorageFavouritesToNewApi = (favourites: IFavouritesV2): IFavourite[] => {
    if (isEmpty(favourites.offers)) {
        return [];
    }

    return Object.entries(favourites.offers)
        .map(([offerId, propertyIds]) => {
            if (isEmpty(propertyIds)) {
                return getFavouriteObject({offerId: parseInt(offerId)});
            }
            return [getFavouriteObject({offerId: parseInt(offerId)}), ...propertyIds.map((propertyId) => getFavouriteObject({propertyId: propertyId}))];
        })
        .flat();
};

export const getLocalStorageFavourites = (): IFavourite[] => {
    let favourites = localStorageFavouritesService.getLocalStorageFavourites();

    // TODO: Remove this code (lines 20-35) after e.g. 1 year from the date of release
    const favouritesV2: IFavouritesV2 = lscache.get(FAVOURITE_LOCAL_STORAGE_KEY_V2);

    if (isEmpty(favouritesV2)) {
        return favourites;
    }

    /*
     * If local storage contains value for `FAVOURITE_LOCAL_STORAGE_KEY_V2` and value for `FAVOURITE_LOCAL_STORAGE_KEY_V3`
     * is empty then transform `FAVOURITE_LOCAL_STORAGE_KEY_V2` value to `FAVOURITE_LOCAL_STORAGE_KEY_V3` value.
     */
    if (isEmpty(favourites)) {
        favourites = transformLocalStorageFavouritesToNewApi(favouritesV2);
        // Set transformed `FAVOURITE_LOCAL_STORAGE_KEY_V2` value as `FAVOURITE_LOCAL_STORAGE_KEY_V3` value
        lscache.set(FAVOURITE_LOCAL_STORAGE_KEY_V3, favourites);
    }

    // Finally, remove `FAVOURITE_LOCAL_STORAGE_KEY_V2` value - we don't need this value anymore.
    lscache.remove(FAVOURITE_LOCAL_STORAGE_KEY_V2);

    return favourites;
};

export const clearLocalStorageFavourites = () => {
    lscache.remove(FAVOURITE_LOCAL_STORAGE_KEY_V3);
};
