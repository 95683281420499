import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

enum OfferTypeSlug {
    HOUSE = "domy",
    FLAT = "mieszkania",
    COMMERCIAL = "lokale-uzytkowe"
}

export const updateSiteMapOfferTypeMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const {pathname} = ctx.route;
    const [, offerTypeSlug] = pathname.split("/").filter(Boolean);
    const parsedOfferTypeSlug = (() => {
        switch (offerTypeSlug) {
            case OfferTypeSlug.COMMERCIAL:
                return "Lokale użytkowe";
            case OfferTypeSlug.FLAT:
                return "Mieszkania";
            case OfferTypeSlug.HOUSE:
                return "Domy";
            default:
                return null;
        }
    })();
    const title = `Mapa strony - Miejscowości - ${concatWithBrandName(parsedOfferTypeSlug ? parsedOfferTypeSlug + " - " : "")}`;
    const description = `Mapa strony - dodatkowa nawigacja dla kategorii Miejscowości${parsedOfferTypeSlug ? " - " + parsedOfferTypeSlug : ""}`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return Promise.resolve();
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

enum OfferTypeSlug {
    HOUSE = "domy",
    FLAT = "mieszkania",
    COMMERCIAL = "lokale-uzytkowe"
}

export const updateSiteMapOfferTypeMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch) => {
    const {pathname} = ctx.route;
    const [, offerTypeSlug] = pathname.split("/").filter(Boolean);
    const parsedOfferTypeSlug = (() => {
        switch (offerTypeSlug) {
            case OfferTypeSlug.COMMERCIAL:
                return "Lokale użytkowe";
            case OfferTypeSlug.FLAT:
                return "Mieszkania";
            case OfferTypeSlug.HOUSE:
                return "Domy";
            default:
                return null;
        }
    })();
    const title = `Mapa strony - Miejscowości - ${concatWithBrandName(parsedOfferTypeSlug ? parsedOfferTypeSlug + " - " : "")}`;
    const description = `Mapa strony - dodatkowa nawigacja dla kategorii Miejscowości${parsedOfferTypeSlug ? " - " + parsedOfferTypeSlug : ""}`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname}));
    return Promise.resolve();
};
