import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, isEqual} from "@pg-mono/nodash";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_LIST_ARTICLES_PREFIX = "offer_list/FETCH_OFFER_LIST_ARTICLES";
export const fetchOfferListArticlesTypes = createRequestActionTypes(FETCH_OFFER_LIST_ARTICLES_PREFIX);

interface IArticleGallery {
    image: {
        g_img_65x65: string;
        g_img_206x206: string;
        g_img_349x239: string;
        g_img_440x440: string;
    } | null;
}
export interface IOfferArticle {
    author: {
        name: string;
    };
    date_published: string | null;
    excerpt: string;
    gallery: IArticleGallery | (IArticleGallery | undefined)[]; // gallery can be either array or plain object. Gallery as object is being used in offer-article-list scenario. It was a backend decision to make this exception
    id: number;
    slug: string;
    title: string;
}

interface IOfferListArticlesResponse {
    results: IOfferArticle[];
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.OFFER_ARTICLE_LIST);

export const fetchOfferListArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {selectedRegions} = getState().offerList;
    if (isEmpty(selectedRegions)) {
        return false;
    }
    const query = {
        region: selectedRegions[0].id,
        page_size: 4
    };

    if (isEqual(query, getState().offerList.articlesLatestQuery)) {
        // do not fetch the same data again
        return getState().offerList.articles;
    }

    dispatch({type: fetchOfferListArticlesTypes.start, latestQuery: query});
    const url = appendQueryString(articleListApiLink, query);
    return getRequest(ctx.meta, url).then((json: IOfferListArticlesResponse) => {
        dispatch({type: fetchOfferListArticlesTypes.success, result: json.results});
        return json;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, isEqual} from "@pg-mono/nodash";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_LIST_ARTICLES_PREFIX = "offer_list/FETCH_OFFER_LIST_ARTICLES";
export const fetchOfferListArticlesTypes = createRequestActionTypes(FETCH_OFFER_LIST_ARTICLES_PREFIX);

interface IArticleGallery {
    image: {
        g_img_65x65: string;
        g_img_206x206: string;
        g_img_349x239: string;
        g_img_440x440: string;
    } | null;
}
export interface IOfferArticle {
    author: {
        name: string;
    };
    date_published: string | null;
    excerpt: string;
    gallery: IArticleGallery | (IArticleGallery | undefined)[]; // gallery can be either array or plain object. Gallery as object is being used in offer-article-list scenario. It was a backend decision to make this exception
    id: number;
    slug: string;
    title: string;
}

interface IOfferListArticlesResponse {
    results: IOfferArticle[];
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.OFFER_ARTICLE_LIST);

export const fetchOfferListArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const {selectedRegions} = getState().offerList;
    if (isEmpty(selectedRegions)) {
        return false;
    }
    const query = {
        region: selectedRegions[0].id,
        page_size: 4
    };

    if (isEqual(query, getState().offerList.articlesLatestQuery)) {
        // do not fetch the same data again
        return getState().offerList.articles;
    }

    dispatch({type: fetchOfferListArticlesTypes.start, latestQuery: query});
    const url = appendQueryString(articleListApiLink, query);
    return getRequest(ctx.meta, url).then((json: IOfferListArticlesResponse) => {
        dispatch({type: fetchOfferListArticlesTypes.success, result: json.results});
        return json;
    });
};
