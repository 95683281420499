import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {fetchArticleCategoriesActionTypes, ISitemapArticleCategory} from "../actions/fetch_site_map_article_category";
import {fetchSiteMapOfferListActionTypes, ISitemapOfferListResponse} from "../actions/fetch_site_map_offer";
import {fetchSiteMapVendorListActionTypes, ISitemapVendorListVendor} from "../actions/fetch_site_map_vendor_list";
import {ISiteMapArticleStore, siteMapArticleReducer} from "./site_map_articles_reducer";
import {ISitemapRegionsStore, siteMapRegionsReducer} from "./site_map_regions_reducer";

export interface ISiteMapStore {
    regions: ISitemapRegionsStore;
    offers: ISitemapOfferListResponse;
    articleCategories: ISitemapArticleCategory[];
    articles: ISiteMapArticleStore;
    vendors: ISitemapVendorListVendor[];
}

export const siteMapReducer = combineReducers({
    regions: siteMapRegionsReducer,
    offers: reduceResponse(fetchSiteMapOfferListActionTypes),
    articleCategories: reduceResponse(fetchArticleCategoriesActionTypes),
    articles: siteMapArticleReducer,
    vendors: reduceResponse(fetchSiteMapVendorListActionTypes)
});
import {combineReducers} from "redux";

import {reduceResponse} from "@pg-mono/request-state";

import {fetchArticleCategoriesActionTypes, ISitemapArticleCategory} from "../actions/fetch_site_map_article_category";
import {fetchSiteMapOfferListActionTypes, ISitemapOfferListResponse} from "../actions/fetch_site_map_offer";
import {fetchSiteMapVendorListActionTypes, ISitemapVendorListVendor} from "../actions/fetch_site_map_vendor_list";
import {ISiteMapArticleStore, siteMapArticleReducer} from "./site_map_articles_reducer";
import {ISitemapRegionsStore, siteMapRegionsReducer} from "./site_map_regions_reducer";

export interface ISiteMapStore {
    regions: ISitemapRegionsStore;
    offers: ISitemapOfferListResponse;
    articleCategories: ISitemapArticleCategory[];
    articles: ISiteMapArticleStore;
    vendors: ISitemapVendorListVendor[];
}

export const siteMapReducer = combineReducers({
    regions: siteMapRegionsReducer,
    offers: reduceResponse(fetchSiteMapOfferListActionTypes),
    articleCategories: reduceResponse(fetchArticleCategoriesActionTypes),
    articles: siteMapArticleReducer,
    vendors: reduceResponse(fetchSiteMapVendorListActionTypes)
});
