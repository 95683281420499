import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FacebookIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 .91a4.344 4.344 0 1 0 0 8.687A4.344 4.344 0 0 0 5 .91Zm1.15 4.325h-.753v2.682H4.282V5.235h-.53v-.947h.53v-.614c0-.439.209-1.124 1.126-1.124l.825.003v.92h-.6c-.097 0-.236.048-.236.257v.558h.85l-.098.947Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const FacebookIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5 .91a4.344 4.344 0 1 0 0 8.687A4.344 4.344 0 0 0 5 .91Zm1.15 4.325h-.753v2.682H4.282V5.235h-.53v-.947h.53v-.614c0-.439.209-1.124 1.126-1.124l.825.003v.92h-.6c-.097 0-.236.048-.236.257v.558h.85l-.098.947Z" />
        </SvgIcon>
    );
};
