import React from "react";
import classNames from "classnames";

import * as cns from "./Loader.module.css";

const loaderData = require("../img/loader.svg");

export type ILoaderProps = {
    className?: string;
    scaleToContainer?: boolean;
    fullWidth?: boolean;
    size?: ILoaderSize;
    padding?: IPaddingSize;
};

type ILoaderSize = "xsm" | "sm" | "md" | "lg" | "xl";

const loaderIconSizeCn: Record<ILoaderSize, string> = {
    xsm: cns.loaderIconWrapXSm,
    sm: cns.loaderIconWrapSm,
    md: cns.loaderIconWrapMd,
    lg: cns.loaderIconWrapLg,
    xl: cns.loaderIconWrapXl
};

type IPaddingSize = "sm" | "md" | "lg" | "xl" | "xxl";
const loaderWrapPaddingCn: Record<IPaddingSize, string> = {
    sm: cns.loaderPaddingSm,
    md: cns.loaderPaddingMd,
    lg: cns.loaderPaddingLg,
    xl: cns.loaderPaddingXLg,
    xxl: cns.loaderPaddingXXL
};

//  Component
export function Loader(props: ILoaderProps) {
    const {size = "sm", padding = "sm", scaleToContainer, fullWidth, className} = props;

    const loaderWrapCn = classNames(
        className,
        cns.loader,
        loaderWrapPaddingCn[padding],
        scaleToContainer && cns.loaderScaleToContainer,
        fullWidth && cns.loaderFullWidth
    );

    const iconSizeCn = classNames(loaderIconSizeCn[size]);
    const iconWrapCn = classNames(cns.loaderIconWrap, scaleToContainer ? cns.loaderScaleToContainer : iconSizeCn);

    return (
        <div className={loaderWrapCn}>
            <div className={iconWrapCn}>
                <LoaderIcon />
            </div>
        </div>
    );
}

const LoaderIcon = () => <object type="image/svg+xml" data={loaderData} />;
import React from "react";
import classNames from "classnames";

import * as cns from "./Loader.module.css";

const loaderData = require("../img/loader.svg");

export type ILoaderProps = {
    className?: string;
    scaleToContainer?: boolean;
    fullWidth?: boolean;
    size?: ILoaderSize;
    padding?: IPaddingSize;
};

type ILoaderSize = "xsm" | "sm" | "md" | "lg" | "xl";

const loaderIconSizeCn: Record<ILoaderSize, string> = {
    xsm: cns.loaderIconWrapXSm,
    sm: cns.loaderIconWrapSm,
    md: cns.loaderIconWrapMd,
    lg: cns.loaderIconWrapLg,
    xl: cns.loaderIconWrapXl
};

type IPaddingSize = "sm" | "md" | "lg" | "xl" | "xxl";
const loaderWrapPaddingCn: Record<IPaddingSize, string> = {
    sm: cns.loaderPaddingSm,
    md: cns.loaderPaddingMd,
    lg: cns.loaderPaddingLg,
    xl: cns.loaderPaddingXLg,
    xxl: cns.loaderPaddingXXL
};

//  Component
export function Loader(props: ILoaderProps) {
    const {size = "sm", padding = "sm", scaleToContainer, fullWidth, className} = props;

    const loaderWrapCn = classNames(
        className,
        cns.loader,
        loaderWrapPaddingCn[padding],
        scaleToContainer && cns.loaderScaleToContainer,
        fullWidth && cns.loaderFullWidth
    );

    const iconSizeCn = classNames(loaderIconSizeCn[size]);
    const iconWrapCn = classNames(cns.loaderIconWrap, scaleToContainer ? cns.loaderScaleToContainer : iconSizeCn);

    return (
        <div className={loaderWrapCn}>
            <div className={iconWrapCn}>
                <LoaderIcon />
            </div>
        </div>
    );
}

const LoaderIcon = () => <object type="image/svg+xml" data={loaderData} />;
