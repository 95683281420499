import React from "react";
import {useHistory} from "react-router";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {w100} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IStaticContext} from "../../../server/types/StaticContext";
import {ErrorSection} from "../components/ErrorSection";

const img404Mobile = require("../images/404_mobile.png");
const img404Desktop = require("../images/404_desktop.png");

interface IProps {
    staticContext?: IStaticContext;
}

export const NotFoundView = (props: IProps) => {
    const theme = useTheme();
    const history = useHistory();

    // server side 404 status response
    if (props.staticContext) {
        props.staticContext.noMatchRoute = true;
    }

    return (
        <ErrorSection
            header="Uuups!"
            subheader="Przepraszamy, nie możemy wyświetlić tej strony. Możliwe, że zmienił się jej adres lub została usunięta."
            infoGraphic={{
                alt: "404",
                sources: [
                    {minWidthPX: 0, width: 336, height: 306, src: img404Mobile},
                    {minWidthPX: theme.breakpoints.numeric.md, width: 383, height: 346, src: img404Desktop}
                ]
            }}
        >
            <Button css={button} variant="filled_primary" href={rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"})}>
                Poszukaj ofert
            </Button>

            <Button css={button} variant="outlined_secondary" onClick={() => history.replace(rpAppLink.base())}>
                Strona główna
            </Button>
        </ErrorSection>
    );
};

export const button = (theme: Theme) => css`
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 20rem;
        max-width: 20rem;
    }
`;
import React from "react";
import {useHistory} from "react-router";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {w100} from "@pg-design/helpers-css";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IStaticContext} from "../../../server/types/StaticContext";
import {ErrorSection} from "../components/ErrorSection";

const img404Mobile = require("../images/404_mobile.png");
const img404Desktop = require("../images/404_desktop.png");

interface IProps {
    staticContext?: IStaticContext;
}

export const NotFoundView = (props: IProps) => {
    const theme = useTheme();
    const history = useHistory();

    // server side 404 status response
    if (props.staticContext) {
        props.staticContext.noMatchRoute = true;
    }

    return (
        <ErrorSection
            header="Uuups!"
            subheader="Przepraszamy, nie możemy wyświetlić tej strony. Możliwe, że zmienił się jej adres lub została usunięta."
            infoGraphic={{
                alt: "404",
                sources: [
                    {minWidthPX: 0, width: 336, height: 306, src: img404Mobile},
                    {minWidthPX: theme.breakpoints.numeric.md, width: 383, height: 346, src: img404Desktop}
                ]
            }}
        >
            <Button css={button} variant="filled_primary" href={rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"})}>
                Poszukaj ofert
            </Button>

            <Button css={button} variant="outlined_secondary" onClick={() => history.replace(rpAppLink.base())}>
                Strona główna
            </Button>
        </ErrorSection>
    );
};

export const button = (theme: Theme) => css`
    ${w100};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 20rem;
        max-width: 20rem;
    }
`;
