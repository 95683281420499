import {RequestActionTypes} from "./create_request_action_types";

export interface IAction {
    type: string;
}

export interface IResponseAction<T> extends IAction {
    result: T;
}

export interface ILatestQueryAction extends IAction {
    latestQuery: {[key: string]: string | string[]};
}

export function reduceResponse<T>(requestTypes: RequestActionTypes, init: T | null = null) {
    return (state: T | null = init, action: IResponseAction<T>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.result;
            case requestTypes.reset: // reset is usually called on unmout, so we need to clear fetched data
                return init;
            default:
                return state;
        }
    };
}

// Be advised to use it only when you actually mean to preserve data on reset e.g. middleware data
export function reduceResponseNoReset<T>(requestTypes: RequestActionTypes, init: T | null = null) {
    return (state: T | null = init, action: IResponseAction<T>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.result;
            default:
                return state;
        }
    };
}

export function reduceResponseField<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T | null = null) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T}>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return action.result[fieldName] == null ? defaultState : action.result[fieldName];
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}

// Be advised to use it only when you actually mean to preserve data on reset e.g. auxiliary data for offer-list
export function reduceResponseFieldNoReset<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T | null = null) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T}>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return action.result[fieldName] == null ? defaultState : action.result[fieldName];
            default:
                return state;
        }
    };
}

export function reduceAppendedResponseField<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T[] = []) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T[]}>): T[] => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return [...state, ...action.result[fieldName]];
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}

export function reduceSpreadResponse<T>(requestTypes: RequestActionTypes, defaultState: Record<string, T> = {}) {
    return (state = defaultState, action: IResponseAction<Record<string, T>>): Record<string, T> => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return {...state, ...action.result};
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}
import {RequestActionTypes} from "./create_request_action_types";

export interface IAction {
    type: string;
}

export interface IResponseAction<T> extends IAction {
    result: T;
}

export interface ILatestQueryAction extends IAction {
    latestQuery: {[key: string]: string | string[]};
}

export function reduceResponse<T>(requestTypes: RequestActionTypes, init: T | null = null) {
    return (state: T | null = init, action: IResponseAction<T>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.result;
            case requestTypes.reset: // reset is usually called on unmout, so we need to clear fetched data
                return init;
            default:
                return state;
        }
    };
}

// Be advised to use it only when you actually mean to preserve data on reset e.g. middleware data
export function reduceResponseNoReset<T>(requestTypes: RequestActionTypes, init: T | null = null) {
    return (state: T | null = init, action: IResponseAction<T>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                return action.result;
            default:
                return state;
        }
    };
}

export function reduceResponseField<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T | null = null) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T}>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return action.result[fieldName] == null ? defaultState : action.result[fieldName];
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}

// Be advised to use it only when you actually mean to preserve data on reset e.g. auxiliary data for offer-list
export function reduceResponseFieldNoReset<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T | null = null) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T}>): T | null => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return action.result[fieldName] == null ? defaultState : action.result[fieldName];
            default:
                return state;
        }
    };
}

export function reduceAppendedResponseField<T>(requestTypes: RequestActionTypes, fieldName: string, defaultState: T[] = []) {
    return (state = defaultState, action: IResponseAction<{[fieldName: string]: T[]}>): T[] => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return [...state, ...action.result[fieldName]];
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}

export function reduceSpreadResponse<T>(requestTypes: RequestActionTypes, defaultState: Record<string, T> = {}) {
    return (state = defaultState, action: IResponseAction<Record<string, T>>): Record<string, T> => {
        switch (action.type) {
            case requestTypes.success:
                // response may not contain any results
                return {...state, ...action.result};
            case requestTypes.reset:
                return defaultState;
            default:
                return state;
        }
    };
}
