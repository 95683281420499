import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isNumber} from "@pg-mono/nodash";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState, enable302ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {setGlobals} from "../../app/actions/globals";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {createOfferLink} from "../../offer/helpers/create_offer_link";
import {IPropertyDetail} from "../types/IPropertyDetail";

const PROPERTY_DETAIL_PREFIX = "property_detail/fetch";
export const fetchPropertyDetailTypes = createRequestActionTypes(PROPERTY_DETAIL_PREFIX);

export const fetchPropertyDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchPropertyDetailTypes.start});

    const {offerId, offerSlug, propertyId, vendorSlug} = ctx.match.params;

    const url = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});

    return getRequest(ctx.meta, url)
        .then((property: IPropertyDetail) => {
            const parsedOfferId = parseInt(offerId);
            const offerIdMatch = parsedOfferId && isNumber(parsedOfferId) && isFinite(parsedOfferId) ? parsedOfferId === property.offer.id : false;

            if (vendorSlug !== property.offer.vendor.slug || offerSlug !== property.offer.slug || !offerIdMatch) {
                const link = rpAppLink.property.detail.base({
                    vendorSlug: property.offer.vendor.slug,
                    offerSlug: property.offer.slug,
                    offerId: property.offer.id,
                    propertyId: property.id
                });
                dispatch(enable301ResponseState(link));
                return false;
            }
            if (!property.is_active) {
                const use302Redirect = property.offer.configuration.redirect_type === 302;

                const link = createOfferLink(property.offer);
                use302Redirect ? dispatch(enable302ResponseState(link)) : dispatch(enable301ResponseState(link));
                return;
            }

            dispatch(setGlobals({hasPageProjection2d: !!property.external_plan_url}));
            dispatch({type: fetchPropertyDetailTypes.success, result: property});
            return property;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isNumber} from "@pg-mono/nodash";
import {catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState, enable302ResponseState} from "@pg-mono/response-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppLink} from "@pg-mono/rp-routes";

import {setGlobals} from "../../app/actions/globals";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {createOfferLink} from "../../offer/helpers/create_offer_link";
import {IPropertyDetail} from "../types/IPropertyDetail";

const PROPERTY_DETAIL_PREFIX = "property_detail/fetch";
export const fetchPropertyDetailTypes = createRequestActionTypes(PROPERTY_DETAIL_PREFIX);

export const fetchPropertyDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchPropertyDetailTypes.start});

    const {offerId, offerSlug, propertyId, vendorSlug} = ctx.match.params;

    const url = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});

    return getRequest(ctx.meta, url)
        .then((property: IPropertyDetail) => {
            const parsedOfferId = parseInt(offerId);
            const offerIdMatch = parsedOfferId && isNumber(parsedOfferId) && isFinite(parsedOfferId) ? parsedOfferId === property.offer.id : false;

            if (vendorSlug !== property.offer.vendor.slug || offerSlug !== property.offer.slug || !offerIdMatch) {
                const link = rpAppLink.property.detail.base({
                    vendorSlug: property.offer.vendor.slug,
                    offerSlug: property.offer.slug,
                    offerId: property.offer.id,
                    propertyId: property.id
                });
                dispatch(enable301ResponseState(link));
                return false;
            }
            if (!property.is_active) {
                const use302Redirect = property.offer.configuration.redirect_type === 302;

                const link = createOfferLink(property.offer);
                use302Redirect ? dispatch(enable302ResponseState(link)) : dispatch(enable301ResponseState(link));
                return;
            }

            dispatch(setGlobals({hasPageProjection2d: !!property.external_plan_url}));
            dispatch({type: fetchPropertyDetailTypes.success, result: property});
            return property;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
