import {css} from "@emotion/react";

import {modalStyles} from "@pg-design/modal-module";
import {fontFace} from "@pg-design/styles";
import {getThemeVariable, themeVariableListRp} from "@pg-design/styles-strings";

import {BROWSER_SUPPORT_ID} from "../components/BrowserSupport";
import {tippyPopoverStyles} from "./tippy_popover_styles";

const getFontPath = (variant: string): string => `/static-rp/fonts/${variant}/`;

const primaryFontVariant = "poppins";
const primaryFontPath = getFontPath(primaryFontVariant);

const secondaryFontVariant = "playfair_display";
const secondaryFontPath = getFontPath(secondaryFontVariant);

export const globalStylesRp = css`
    @layer global {
        :root {
            ${themeVariableListRp}
        }

        html {
            font-size: ${getThemeVariable("globals.text.root")} !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        body {
            font-family: ${getThemeVariable("globals.text.family")};
            font-size: ${getThemeVariable("globals.text.size.desktop")};
            font-weight: ${getThemeVariable("globals.text.weight")};
            font-weight: ${getThemeVariable("globals.text.weight")};
            line-height: ${getThemeVariable("globals.text.lineHeight")};
            color: ${getThemeVariable("globals.text.color")};
            background-color: ${getThemeVariable("globals.body.background")};
        }
        html {
            font-family: ${getThemeVariable("globals.text.family")};
        }

        a {
            color: ${getThemeVariable("globals.link.color")};
            text-decoration: none;
        }

        a:hover,
        a:focus {
            color: ${getThemeVariable("globals.link.hover.color")};
            text-decoration: ${getThemeVariable("globals.link.hover.decoration")};
        }

        input,
        button,
        select,
        textarea {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        [role="button"] {
            cursor: pointer;
        }

        // reset
        button {
            border: none;
            padding: 0;
            background-color: transparent;
            cursor: pointer;
        }
        // normalize
        * {
            box-sizing: border-box;
        }

        *:before,
        *:after {
            box-sizing: border-box;
        }

        html {
            line-height: 1.15;
            -webkit-text-size-adjust: 100%;
        }

        body {
            margin: 0;
        }

        main {
            display: block;
        }

        h1 {
            font-size: 2em;
            margin: 0.67em 0;
        }

        hr {
            box-sizing: content-box;
            height: 0;
            overflow: visible;
        }

        pre {
            font-family: monospace, monospace;
            font-size: 1em;
        }

        a {
            background-color: transparent;
        }

        address {
            font-style: normal;
        }

        abbr[title] {
            border-bottom: none;
            text-decoration: underline dotted;
        }

        b,
        strong {
            font-weight: bolder;
        }

        code,
        kbd,
        samp {
            font-family: monospace, monospace;
            font-size: 1em;
        }

        small {
            font-size: 80%;
        }

        sub,
        sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
        }

        sub {
            bottom: -0.25em;
        }

        sup {
            top: -0.5em;
        }

        img {
            border-style: none;
        }

        button,
        input,
        optgroup,
        select,
        textarea {
            font-family: inherit;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
        }

        button,
        input {
            overflow: visible;
        }

        button,
        select {
            text-transform: none;
        }

        button,
        [type="button"],
        [type="reset"],
        [type="submit"] {
            -webkit-appearance: button;
        }

        button::-moz-focus-inner,
        [type="button"]::-moz-focus-inner,
        [type="reset"]::-moz-focus-inner,
        [type="submit"]::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        button:-moz-focusring,
        [type="button"]:-moz-focusring,
        [type="reset"]:-moz-focusring,
        [type="submit"]:-moz-focusring {
            outline: 1px dotted ButtonText;
        }

        fieldset {
            padding: 0.35em 0.75em 0.625em;
        }

        legend {
            box-sizing: border-box;
            color: inherit;
            display: table;
            max-width: 100%;
            padding: 0;
            white-space: normal;
        }

        progress {
            vertical-align: baseline;
        }

        textarea {
            overflow: auto;
        }

        [type="checkbox"],
        [type="radio"] {
            box-sizing: border-box;
            padding: 0;
        }

        [type="number"]::-webkit-inner-spin-button,
        [type="number"]::-webkit-outer-spin-button {
            height: auto;
        }

        [type="search"] {
            -webkit-appearance: textfield;
            outline-offset: -2px;
        }

        [type="search"]::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        ::-webkit-file-upload-button {
            -webkit-appearance: button;
            font: inherit;
        }

        details {
            display: block;
        }

        summary {
            display: list-item;
        }

        template {
            display: none;
        }

        [hidden] {
            display: none;
        }

        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_light`, 300)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_regular`, 400)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_medium`, 500)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_semibold`, 600)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_bold`, 700)};

        ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_regular`, 400)};
        ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_bold`, 700)};

        ${tippyPopoverStyles}
        ${modalStyles};

        a:hover,
        a:focus {
            outline: 0;
        }

        .mobile-search-offset {
            padding-bottom: 6.2rem;
        }

        .mobile-contact-bar-offset {
            padding-bottom: 6rem;
        }

        /**
based on weather "align-items" is supported by browser or not we show/hide browser upgrade message to a user.
Because "align-items" has the closest correlation to expected browsers compatibility it is being used as indicator.
 */
        @supports not (align-items: center) {
            body {
                overflow: hidden;
            }
            #${BROWSER_SUPPORT_ID} {
                display: block !important;
            }
        }

        /*
Code below should work in case of IE10+, @supports doesn't work there, in order to show BrowserSupportView it needs to be set with @media.   
*/
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            body {
                overflow: hidden;
            }
            #${BROWSER_SUPPORT_ID} {
                display: block !important;
            }
        }
    }
`;
import {css} from "@emotion/react";

import {modalStyles} from "@pg-design/modal-module";
import {fontFace} from "@pg-design/styles";
import {getThemeVariable, themeVariableListRp} from "@pg-design/styles-strings";

import {BROWSER_SUPPORT_ID} from "../components/BrowserSupport";
import {tippyPopoverStyles} from "./tippy_popover_styles";

const getFontPath = (variant: string): string => `/static-rp/fonts/${variant}/`;

const primaryFontVariant = "poppins";
const primaryFontPath = getFontPath(primaryFontVariant);

const secondaryFontVariant = "playfair_display";
const secondaryFontPath = getFontPath(secondaryFontVariant);

export const globalStylesRp = css`
    @layer global {
        :root {
            ${themeVariableListRp}
        }

        html {
            font-size: ${getThemeVariable("globals.text.root")} !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        body {
            font-family: ${getThemeVariable("globals.text.family")};
            font-size: ${getThemeVariable("globals.text.size.desktop")};
            font-weight: ${getThemeVariable("globals.text.weight")};
            font-weight: ${getThemeVariable("globals.text.weight")};
            line-height: ${getThemeVariable("globals.text.lineHeight")};
            color: ${getThemeVariable("globals.text.color")};
            background-color: ${getThemeVariable("globals.body.background")};
        }
        html {
            font-family: ${getThemeVariable("globals.text.family")};
        }

        a {
            color: ${getThemeVariable("globals.link.color")};
            text-decoration: none;
        }

        a:hover,
        a:focus {
            color: ${getThemeVariable("globals.link.hover.color")};
            text-decoration: ${getThemeVariable("globals.link.hover.decoration")};
        }

        input,
        button,
        select,
        textarea {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        [role="button"] {
            cursor: pointer;
        }

        // reset
        button {
            border: none;
            padding: 0;
            background-color: transparent;
            cursor: pointer;
        }
        // normalize
        * {
            box-sizing: border-box;
        }

        *:before,
        *:after {
            box-sizing: border-box;
        }

        html {
            line-height: 1.15;
            -webkit-text-size-adjust: 100%;
        }

        body {
            margin: 0;
        }

        main {
            display: block;
        }

        h1 {
            font-size: 2em;
            margin: 0.67em 0;
        }

        hr {
            box-sizing: content-box;
            height: 0;
            overflow: visible;
        }

        pre {
            font-family: monospace, monospace;
            font-size: 1em;
        }

        a {
            background-color: transparent;
        }

        address {
            font-style: normal;
        }

        abbr[title] {
            border-bottom: none;
            text-decoration: underline dotted;
        }

        b,
        strong {
            font-weight: bolder;
        }

        code,
        kbd,
        samp {
            font-family: monospace, monospace;
            font-size: 1em;
        }

        small {
            font-size: 80%;
        }

        sub,
        sup {
            font-size: 75%;
            line-height: 0;
            position: relative;
            vertical-align: baseline;
        }

        sub {
            bottom: -0.25em;
        }

        sup {
            top: -0.5em;
        }

        img {
            border-style: none;
        }

        button,
        input,
        optgroup,
        select,
        textarea {
            font-family: inherit;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
        }

        button,
        input {
            overflow: visible;
        }

        button,
        select {
            text-transform: none;
        }

        button,
        [type="button"],
        [type="reset"],
        [type="submit"] {
            -webkit-appearance: button;
        }

        button::-moz-focus-inner,
        [type="button"]::-moz-focus-inner,
        [type="reset"]::-moz-focus-inner,
        [type="submit"]::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }

        button:-moz-focusring,
        [type="button"]:-moz-focusring,
        [type="reset"]:-moz-focusring,
        [type="submit"]:-moz-focusring {
            outline: 1px dotted ButtonText;
        }

        fieldset {
            padding: 0.35em 0.75em 0.625em;
        }

        legend {
            box-sizing: border-box;
            color: inherit;
            display: table;
            max-width: 100%;
            padding: 0;
            white-space: normal;
        }

        progress {
            vertical-align: baseline;
        }

        textarea {
            overflow: auto;
        }

        [type="checkbox"],
        [type="radio"] {
            box-sizing: border-box;
            padding: 0;
        }

        [type="number"]::-webkit-inner-spin-button,
        [type="number"]::-webkit-outer-spin-button {
            height: auto;
        }

        [type="search"] {
            -webkit-appearance: textfield;
            outline-offset: -2px;
        }

        [type="search"]::-webkit-search-decoration {
            -webkit-appearance: none;
        }

        ::-webkit-file-upload-button {
            -webkit-appearance: button;
            font: inherit;
        }

        details {
            display: block;
        }

        summary {
            display: list-item;
        }

        template {
            display: none;
        }

        [hidden] {
            display: none;
        }

        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_light`, 300)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_regular`, 400)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_medium`, 500)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_semibold`, 600)};
        ${fontFace(primaryFontPath, primaryFontVariant, `${primaryFontVariant}_bold`, 700)};

        ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_regular`, 400)};
        ${fontFace(secondaryFontPath, secondaryFontVariant, `${secondaryFontVariant}_bold`, 700)};

        ${tippyPopoverStyles}
        ${modalStyles};

        a:hover,
        a:focus {
            outline: 0;
        }

        .mobile-search-offset {
            padding-bottom: 6.2rem;
        }

        .mobile-contact-bar-offset {
            padding-bottom: 6rem;
        }

        /**
based on weather "align-items" is supported by browser or not we show/hide browser upgrade message to a user.
Because "align-items" has the closest correlation to expected browsers compatibility it is being used as indicator.
 */
        @supports not (align-items: center) {
            body {
                overflow: hidden;
            }
            #${BROWSER_SUPPORT_ID} {
                display: block !important;
            }
        }

        /*
Code below should work in case of IE10+, @supports doesn't work there, in order to show BrowserSupportView it needs to be set with @media.   
*/
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            body {
                overflow: hidden;
            }
            #${BROWSER_SUPPORT_ID} {
                display: block !important;
            }
        }
    }
`;
