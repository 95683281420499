import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchVariablesTypes} from "../actions/fetch_variables";
import {IValueLabel} from "../types/IValueLabel";

export interface IVariables {
    // PLEASE keep alphabetical order
    application_meeting_time_preferences: IValueLabel[];
    application_source_sections: IValueLabel[];
    country_codes: IValueLabel[];
    for_investor_search_type: IValueLabel[];
    offer_configuration_display_type: IValueLabel[];
    offer_construction_end_date: IValueLabel[];
    offer_display_type: IValueLabel[];
    offer_distance: IValueLabel[];
    offer_facilities: IValueLabel[];
    offer_funds_protection: IValueLabel[];
    offer_holiday_location: IValueLabel[];
    offer_listing_bullets: IValueLabel[];
    offer_listing_bullets_short: IValueLabel[];
    offer_natural_sites: IValueLabel[];
    offer_parking_place_type: IValueLabel[];
    offer_price_type: IValueLabel[];
    offer_size: IValueLabel[];
    offer_standard_description_type: IValueLabel[];
    promotion_type: IValueLabel[];
    property_additional_areas: IValueLabel[];
    property_flat_type: IValueLabel[];
    property_house_type: IValueLabel[];
    property_kitchen_type: IValueLabel[];
    property_ownership_form: IValueLabel[];
    property_quarters: IValueLabel[];
    property_room_area_type: IValueLabel[];
    recommendation_stat_label: IValueLabel[];
    region_type: IValueLabel[];
    user_type: IValueLabel[];
    user_sex: IValueLabel[];
    vendor_office_type: IValueLabel[];
    vendor_type: IValueLabel[];
}

export interface IVariablesStore {
    data: IVariables | null;
    requestState: RequestState;
}

export const variablesReducer = combineReducers({
    data: reduceResponse(fetchVariablesTypes),
    requestState: reduceRequestState(fetchVariablesTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchVariablesTypes} from "../actions/fetch_variables";
import {IValueLabel} from "../types/IValueLabel";

export interface IVariables {
    // PLEASE keep alphabetical order
    application_meeting_time_preferences: IValueLabel[];
    application_source_sections: IValueLabel[];
    country_codes: IValueLabel[];
    for_investor_search_type: IValueLabel[];
    offer_configuration_display_type: IValueLabel[];
    offer_construction_end_date: IValueLabel[];
    offer_display_type: IValueLabel[];
    offer_distance: IValueLabel[];
    offer_facilities: IValueLabel[];
    offer_funds_protection: IValueLabel[];
    offer_holiday_location: IValueLabel[];
    offer_listing_bullets: IValueLabel[];
    offer_listing_bullets_short: IValueLabel[];
    offer_natural_sites: IValueLabel[];
    offer_parking_place_type: IValueLabel[];
    offer_price_type: IValueLabel[];
    offer_size: IValueLabel[];
    offer_standard_description_type: IValueLabel[];
    promotion_type: IValueLabel[];
    property_additional_areas: IValueLabel[];
    property_flat_type: IValueLabel[];
    property_house_type: IValueLabel[];
    property_kitchen_type: IValueLabel[];
    property_ownership_form: IValueLabel[];
    property_quarters: IValueLabel[];
    property_room_area_type: IValueLabel[];
    recommendation_stat_label: IValueLabel[];
    region_type: IValueLabel[];
    user_type: IValueLabel[];
    user_sex: IValueLabel[];
    vendor_office_type: IValueLabel[];
    vendor_type: IValueLabel[];
}

export interface IVariablesStore {
    data: IVariables | null;
    requestState: RequestState;
}

export const variablesReducer = combineReducers({
    data: reduceResponse(fetchVariablesTypes),
    requestState: reduceRequestState(fetchVariablesTypes)
});
