import cn from "classnames";

import {Loader} from "@pg-design/loader-module";

import {ButtonVariant} from "../types/ButtonVariant";
import {IButtonProps} from "../types/IButtonProps";
import {IButtonSizeBrandIcon} from "../types/IButtonSizeBrandIcon";
import {IButtonSizePrimary} from "../types/IButtonSizePrimary";
import {IButtonVariantUnionType} from "../types/IButtonVariantUnionType";
import {ButtonText} from "./ButtonText";

import {
    button,
    buttonBannerPrimary,
    buttonBig,
    buttonBrandIconBig,
    buttonBrandIconLeftIcon,
    buttonBrandIconMedium,
    buttonBrandIconRightIcon,
    buttonContentWrapper,
    buttonContentWrapperIcon,
    buttonCustomTag,
    buttonDefault,
    buttonFilledPrimary,
    buttonFilledSecondary,
    buttonFullWidth,
    buttonHighlightPrimary,
    buttonLeftIcon,
    buttonMapTooltip,
    buttonMapTooltipLeftIcon,
    buttonMapTooltipRightIcon,
    buttonMedium,
    buttonNoneSecondary,
    buttonOutlinedSecondary,
    buttonOutlinedSecondaryLight,
    buttonRightIcon,
    buttonSmall,
    buttonStretchedPrimary,
    buttonStretchedPrimaryIcon,
    buttonStretchedPrimaryLeftIcon,
    buttonStretchedPrimaryRightIcon,
    buttonXSmall
} from "./Button.module.css";

export function Button(props: IButtonProps) {
    const {
        as = "button",
        variant = "filled_primary",
        size,
        fullWidth = false,
        iconLeft: IconLeft,
        iconRight: IconRight,
        children,
        isLoading,
        className,
        dataTestId,
        type,
        ...linkAndTagProps
    } = props;
    const {href, target, rel, ...tagProps} = linkAndTagProps;

    const Component = href ? "a" : as;

    const buttonCn = cn(className, getButtonCn(variant, Component !== "button", size, fullWidth));

    const hasIcon = Boolean(IconLeft) || Boolean(IconRight);
    const hasText = Boolean(children);

    const iconLeftProps = IconLeft ? getButtonIconProps(variant, "left", hasText, size) : undefined;
    const iconRightProps = IconRight ? getButtonIconProps(variant, "right", hasText, size) : undefined;

    return (
        <Component {...tagProps} className={buttonCn} data-testid={dataTestId} href={href} target={href && target} rel={href && rel} type={type || "submit"}>
            <span className={cn(buttonContentWrapper, hasIcon && buttonContentWrapperIcon)}>
                {!isLoading && (
                    <>
                        {iconLeftProps && IconLeft ? <IconLeft {...iconLeftProps} /> : null}
                        <ButtonText variant={variant} size={size}>
                            {children}
                        </ButtonText>
                        {iconRightProps && IconRight ? <IconRight {...iconRightProps} /> : null}
                    </>
                )}
            </span>

            {isLoading && <Loader scaleToContainer />}
        </Component>
    );
}

// Utils
type IButtonSize = IButtonSizePrimary | IButtonSizeBrandIcon;

function getButtonCn(variant: IButtonVariantUnionType, isWithCustomTag: boolean, size?: IButtonSize, fullWidth?: boolean) {
    const customTagCn = isWithCustomTag ? buttonCustomTag : null;
    const sizeCn = getButtonSizeCn(size);
    const fullWidthCn = fullWidth ? buttonFullWidth : null;

    switch (variant) {
        case ButtonVariant.FILLED_PRIMARY:
            return cn(button, buttonFilledPrimary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.FILLED_SECONDARY:
            return cn(button, buttonFilledSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.OUTLINED_SECONDARY:
            return cn(button, buttonOutlinedSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.OUTLINED_SECONDARY_LIGHT:
            return cn(button, buttonOutlinedSecondaryLight, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.NONE_SECONDARY:
            return cn(button, buttonNoneSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.BRAND_ICON:
            const brandIconSizeCn = getBrandIconSizeCn(size);
            return cn(button, brandIconSizeCn, customTagCn);
        case ButtonVariant.STRETCHED_PRIMARY:
            return cn(button, buttonStretchedPrimary, customTagCn);
        case ButtonVariant.HIGHLIGHT_PRIMARY:
            return cn(button, buttonHighlightPrimary, customTagCn);
        case ButtonVariant.MAP_TOOLTIP:
            return cn(button, buttonMapTooltip, customTagCn);
        case ButtonVariant.BANNER_PRIMARY:
            return cn(button, buttonBannerPrimary, customTagCn, sizeCn);
        default:
            return cn(button, buttonDefault, customTagCn, sizeCn);
    }
}

function getButtonSizeCn(size?: IButtonSize): string | null {
    switch (size) {
        case "x-small":
            return buttonXSmall;
        case "small":
            return buttonSmall;
        case "medium":
            return buttonMedium;
        case "big":
            return buttonBig;
        default:
            return null;
    }
}

function getBrandIconSizeCn(size?: IButtonSize): string | null {
    switch (size) {
        case "medium":
            return buttonBrandIconMedium;
        case "big":
            return buttonBrandIconBig;
        default:
            return null;
    }
}

type IButtonIconProps = {
    size: string;
    fill: string;
    className?: string;
    wrapperColor?: string;
    wrapperSize?: string;
    wrapperType?: "circle" | "square";
};

function getButtonIconProps(variant: IButtonVariantUnionType, orientation: "left" | "right", hasText: boolean, buttonSize?: IButtonSize): IButtonIconProps {
    switch (variant) {
        case ButtonVariant.STRETCHED_PRIMARY:
            return {
                size: "2.4",
                className: cn(buttonStretchedPrimaryIcon, orientation === "left" ? buttonStretchedPrimaryLeftIcon : buttonStretchedPrimaryRightIcon),
                fill: "inherit"
            };
        case ButtonVariant.MAP_TOOLTIP:
            return {
                size: "1.2",
                className: hasText ? cn(orientation === "left" ? buttonMapTooltipLeftIcon : buttonMapTooltipRightIcon) : undefined,
                fill: "inherit"
            };
        case ButtonVariant.BRAND_ICON:
            return {
                size: buttonSize === "big" ? "6" : "4",
                className: hasText ? cn(orientation === "left" ? buttonBrandIconLeftIcon : buttonBrandIconRightIcon) : undefined,
                fill: "inherit",
                wrapperColor: "#ebff00",
                wrapperSize: buttonSize === "big" ? "6.4" : "4.8",
                wrapperType: "circle"
            };
        default:
            return {
                className: hasText ? cn(orientation === "left" ? buttonLeftIcon : buttonRightIcon) : undefined,
                fill: "inherit",
                size: "2.4",
                wrapperSize: "2.4",
                wrapperColor: "transparent"
            };
    }
}
import cn from "classnames";

import {Loader} from "@pg-design/loader-module";

import {ButtonVariant} from "../types/ButtonVariant";
import {IButtonProps} from "../types/IButtonProps";
import {IButtonSizeBrandIcon} from "../types/IButtonSizeBrandIcon";
import {IButtonSizePrimary} from "../types/IButtonSizePrimary";
import {IButtonVariantUnionType} from "../types/IButtonVariantUnionType";
import {ButtonText} from "./ButtonText";

import {
    button,
    buttonBannerPrimary,
    buttonBig,
    buttonBrandIconBig,
    buttonBrandIconLeftIcon,
    buttonBrandIconMedium,
    buttonBrandIconRightIcon,
    buttonContentWrapper,
    buttonContentWrapperIcon,
    buttonCustomTag,
    buttonDefault,
    buttonFilledPrimary,
    buttonFilledSecondary,
    buttonFullWidth,
    buttonHighlightPrimary,
    buttonLeftIcon,
    buttonMapTooltip,
    buttonMapTooltipLeftIcon,
    buttonMapTooltipRightIcon,
    buttonMedium,
    buttonNoneSecondary,
    buttonOutlinedSecondary,
    buttonOutlinedSecondaryLight,
    buttonRightIcon,
    buttonSmall,
    buttonStretchedPrimary,
    buttonStretchedPrimaryIcon,
    buttonStretchedPrimaryLeftIcon,
    buttonStretchedPrimaryRightIcon,
    buttonXSmall
} from "./Button.module.css";

export function Button(props: IButtonProps) {
    const {
        as = "button",
        variant = "filled_primary",
        size,
        fullWidth = false,
        iconLeft: IconLeft,
        iconRight: IconRight,
        children,
        isLoading,
        className,
        dataTestId,
        type,
        ...linkAndTagProps
    } = props;
    const {href, target, rel, ...tagProps} = linkAndTagProps;

    const Component = href ? "a" : as;

    const buttonCn = cn(className, getButtonCn(variant, Component !== "button", size, fullWidth));

    const hasIcon = Boolean(IconLeft) || Boolean(IconRight);
    const hasText = Boolean(children);

    const iconLeftProps = IconLeft ? getButtonIconProps(variant, "left", hasText, size) : undefined;
    const iconRightProps = IconRight ? getButtonIconProps(variant, "right", hasText, size) : undefined;

    return (
        <Component {...tagProps} className={buttonCn} data-testid={dataTestId} href={href} target={href && target} rel={href && rel} type={type || "submit"}>
            <span className={cn(buttonContentWrapper, hasIcon && buttonContentWrapperIcon)}>
                {!isLoading && (
                    <>
                        {iconLeftProps && IconLeft ? <IconLeft {...iconLeftProps} /> : null}
                        <ButtonText variant={variant} size={size}>
                            {children}
                        </ButtonText>
                        {iconRightProps && IconRight ? <IconRight {...iconRightProps} /> : null}
                    </>
                )}
            </span>

            {isLoading && <Loader scaleToContainer />}
        </Component>
    );
}

// Utils
type IButtonSize = IButtonSizePrimary | IButtonSizeBrandIcon;

function getButtonCn(variant: IButtonVariantUnionType, isWithCustomTag: boolean, size?: IButtonSize, fullWidth?: boolean) {
    const customTagCn = isWithCustomTag ? buttonCustomTag : null;
    const sizeCn = getButtonSizeCn(size);
    const fullWidthCn = fullWidth ? buttonFullWidth : null;

    switch (variant) {
        case ButtonVariant.FILLED_PRIMARY:
            return cn(button, buttonFilledPrimary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.FILLED_SECONDARY:
            return cn(button, buttonFilledSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.OUTLINED_SECONDARY:
            return cn(button, buttonOutlinedSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.OUTLINED_SECONDARY_LIGHT:
            return cn(button, buttonOutlinedSecondaryLight, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.NONE_SECONDARY:
            return cn(button, buttonNoneSecondary, customTagCn, sizeCn, fullWidthCn);
        case ButtonVariant.BRAND_ICON:
            const brandIconSizeCn = getBrandIconSizeCn(size);
            return cn(button, brandIconSizeCn, customTagCn);
        case ButtonVariant.STRETCHED_PRIMARY:
            return cn(button, buttonStretchedPrimary, customTagCn);
        case ButtonVariant.HIGHLIGHT_PRIMARY:
            return cn(button, buttonHighlightPrimary, customTagCn);
        case ButtonVariant.MAP_TOOLTIP:
            return cn(button, buttonMapTooltip, customTagCn);
        case ButtonVariant.BANNER_PRIMARY:
            return cn(button, buttonBannerPrimary, customTagCn, sizeCn);
        default:
            return cn(button, buttonDefault, customTagCn, sizeCn);
    }
}

function getButtonSizeCn(size?: IButtonSize): string | null {
    switch (size) {
        case "x-small":
            return buttonXSmall;
        case "small":
            return buttonSmall;
        case "medium":
            return buttonMedium;
        case "big":
            return buttonBig;
        default:
            return null;
    }
}

function getBrandIconSizeCn(size?: IButtonSize): string | null {
    switch (size) {
        case "medium":
            return buttonBrandIconMedium;
        case "big":
            return buttonBrandIconBig;
        default:
            return null;
    }
}

type IButtonIconProps = {
    size: string;
    fill: string;
    className?: string;
    wrapperColor?: string;
    wrapperSize?: string;
    wrapperType?: "circle" | "square";
};

function getButtonIconProps(variant: IButtonVariantUnionType, orientation: "left" | "right", hasText: boolean, buttonSize?: IButtonSize): IButtonIconProps {
    switch (variant) {
        case ButtonVariant.STRETCHED_PRIMARY:
            return {
                size: "2.4",
                className: cn(buttonStretchedPrimaryIcon, orientation === "left" ? buttonStretchedPrimaryLeftIcon : buttonStretchedPrimaryRightIcon),
                fill: "inherit"
            };
        case ButtonVariant.MAP_TOOLTIP:
            return {
                size: "1.2",
                className: hasText ? cn(orientation === "left" ? buttonMapTooltipLeftIcon : buttonMapTooltipRightIcon) : undefined,
                fill: "inherit"
            };
        case ButtonVariant.BRAND_ICON:
            return {
                size: buttonSize === "big" ? "6" : "4",
                className: hasText ? cn(orientation === "left" ? buttonBrandIconLeftIcon : buttonBrandIconRightIcon) : undefined,
                fill: "inherit",
                wrapperColor: "#ebff00",
                wrapperSize: buttonSize === "big" ? "6.4" : "4.8",
                wrapperType: "circle"
            };
        default:
            return {
                className: hasText ? cn(orientation === "left" ? buttonLeftIcon : buttonRightIcon) : undefined,
                fill: "inherit",
                size: "2.4",
                wrapperSize: "2.4",
                wrapperColor: "transparent"
            };
    }
}
