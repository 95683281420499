import {notificationsSlice} from "../../store/notifications/notifications_slice";

export const {addNotification, removeNotification, dropAllTtlAndRemoveInvalid} = notificationsSlice.actions;

export type INotificationValues = ReturnType<typeof addNotification>["payload"];
import {notificationsSlice} from "../../store/notifications/notifications_slice";

export const {addNotification, removeNotification, dropAllTtlAndRemoveInvalid} = notificationsSlice.actions;

export type INotificationValues = ReturnType<typeof addNotification>["payload"];
