import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isPlainObject} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_STATS_PREFIX = "stats/FETCH_REGION_STATS";
export const fetchRegionStatsTypes = createRequestActionTypes(FETCH_STATS_PREFIX);

interface IRegionStatsResponse {
    area_min: number;
    area_avg: number;
    area_max: number;
    price_min: number;
    price_avg: number;
    price_max: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchRegionStats = (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, any>) => (dispatch: Dispatch) => {
    //TODO: Proper type of query
    dispatch({type: fetchRegionStatsTypes.start});

    const url = appendQueryString(apiV2Link.property.stats(null), {...query});
    return getRequest(ctx.meta, url)
        .then((res: IRegionStatsResponse) => {
            dispatch({type: fetchRegionStatsTypes.success, result: res});
            return res;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchRegionStatsTypes.error, error: error.appError});
            })
        );
};

export const fetchRegionStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const query = isPlainObject(ctx.prevResult) ? ctx.prevResult : ctx.route.query;
    dispatch(fetchRegionStats(ctx, query));
    return ctx.prevResult;
};

export const resetFetchStatsDesktop = () => ({type: fetchRegionStatsTypes.reset});

export const fetchRegionStatsOnPropertyDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    return ctx.prevResult.offer.revealed_prices && dispatch(fetchRegionStats(ctx, {property: {id: ctx.prevResult.id}}));
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isPlainObject} from "@pg-mono/nodash";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

const FETCH_STATS_PREFIX = "stats/FETCH_REGION_STATS";
export const fetchRegionStatsTypes = createRequestActionTypes(FETCH_STATS_PREFIX);

interface IRegionStatsResponse {
    area_min: number;
    area_avg: number;
    area_max: number;
    price_min: number;
    price_avg: number;
    price_max: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchRegionStats = (ctx: IFetchContext<IRPRequestMeta>, query: Record<string, any>) => (dispatch: Dispatch) => {
    //TODO: Proper type of query
    dispatch({type: fetchRegionStatsTypes.start});

    const url = appendQueryString(apiV2Link.property.stats(null), {...query});
    return getRequest(ctx.meta, url)
        .then((res: IRegionStatsResponse) => {
            dispatch({type: fetchRegionStatsTypes.success, result: res});
            return res;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchRegionStatsTypes.error, error: error.appError});
            })
        );
};

export const fetchRegionStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const query = isPlainObject(ctx.prevResult) ? ctx.prevResult : ctx.route.query;
    dispatch(fetchRegionStats(ctx, query));
    return ctx.prevResult;
};

export const resetFetchStatsDesktop = () => ({type: fetchRegionStatsTypes.reset});

export const fetchRegionStatsOnPropertyDetailAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    return ctx.prevResult.offer.revealed_prices && dispatch(fetchRegionStats(ctx, {property: {id: ctx.prevResult.id}}));
};
