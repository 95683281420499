import {OfferType} from "../../offer/types/OfferType";

interface IProps {
    offerType: OfferType;
    offerName: string;
    vendorName: string;
    isSpain: boolean;
    regionName: string;
    parentRegionName: string;
    isDistrictOrNeighborhood?: boolean;
}

export const descriptionOfferDetail = (props: IProps) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = "mieszkania";
            break;
        case OfferType.HOUSE:
            type = "domu";
            break;
        case OfferType.COMMERCIAL:
            type = "lokalu użytkowego";
            break;
        default:
            break;
    }

    if (props.isSpain) {
        return `Szukasz ${type} w Hiszpanii? 🌞 Sprawdź ofertę ${props.offerName} w ${props.parentRegionName}. Zainwestuj za granicą i zacznij generować zyski już dziś! ✅`;
    }

    if (props.isDistrictOrNeighborhood) {
        return `Szukasz ${type} na sprzedaż ${props.regionName.split(",")[0].trim()}? Sprawdź ${props.offerName} od ${props.vendorName}. U nas znajdziesz wszystkie informacje. Zarezerwuj już dziś✅!`;
    }

    return `Szukasz ${type} w ${props.offerName} od ${props.vendorName}? U nas znajdziesz wszystkie informacje. Zarezerwuj już dziś✅!`;
};
import {OfferType} from "../../offer/types/OfferType";

interface IProps {
    offerType: OfferType;
    offerName: string;
    vendorName: string;
    isSpain: boolean;
    regionName: string;
    parentRegionName: string;
    isDistrictOrNeighborhood?: boolean;
}

export const descriptionOfferDetail = (props: IProps) => {
    let type = "";
    switch (props.offerType) {
        case OfferType.FLAT:
            type = "mieszkania";
            break;
        case OfferType.HOUSE:
            type = "domu";
            break;
        case OfferType.COMMERCIAL:
            type = "lokalu użytkowego";
            break;
        default:
            break;
    }

    if (props.isSpain) {
        return `Szukasz ${type} w Hiszpanii? 🌞 Sprawdź ofertę ${props.offerName} w ${props.parentRegionName}. Zainwestuj za granicą i zacznij generować zyski już dziś! ✅`;
    }

    if (props.isDistrictOrNeighborhood) {
        return `Szukasz ${type} na sprzedaż ${props.regionName.split(",")[0].trim()}? Sprawdź ${props.offerName} od ${props.vendorName}. U nas znajdziesz wszystkie informacje. Zarezerwuj już dziś✅!`;
    }

    return `Szukasz ${type} w ${props.offerName} od ${props.vendorName}? U nas znajdziesz wszystkie informacje. Zarezerwuj już dziś✅!`;
};
