import React from "react";
import {css} from "@emotion/react";

import {PanelExpandable} from "@pg-design/expandable-module";
import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

export interface IProps {
    children: React.ReactNode;
    title: string;
    alwaysOpen?: boolean;
    className?: string;
}

export const FooterPanelExpandable: React.FC<IProps> = (props) => {
    return (
        <PanelExpandable
            css={panelStyle}
            alwaysOpen={props.alwaysOpen}
            className={props.className}
            headerCss={headerStyle}
            title={
                <Text variant="headline_6" as="h4">
                    {props.title}
                </Text>
            }
            panelArrowIcon={<ChevronDownIcon size="2.2" fill="#fff" />}
        >
            {props.children}
        </PanelExpandable>
    );
};

const headerStyle = {
    paddingBottom: "2.4re"
};

const panelStyle = css`
    color: #fff;
`;
import React from "react";
import {css} from "@emotion/react";

import {PanelExpandable} from "@pg-design/expandable-module";
import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

export interface IProps {
    children: React.ReactNode;
    title: string;
    alwaysOpen?: boolean;
    className?: string;
}

export const FooterPanelExpandable: React.FC<IProps> = (props) => {
    return (
        <PanelExpandable
            css={panelStyle}
            alwaysOpen={props.alwaysOpen}
            className={props.className}
            headerCss={headerStyle}
            title={
                <Text variant="headline_6" as="h4">
                    {props.title}
                </Text>
            }
            panelArrowIcon={<ChevronDownIcon size="2.2" fill="#fff" />}
        >
            {props.children}
        </PanelExpandable>
    );
};

const headerStyle = {
    paddingBottom: "2.4re"
};

const panelStyle = css`
    color: #fff;
`;
