import {combineReducers} from "redux";

import {IAppError, reduceRequestErrors, reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {unsubscribeUserTypes} from "../actions/fetch_user_unsubscription_data";

export interface IUnsubscribeUserStore {
    email: string | null;
    requestState: RequestState;
    error: IAppError;
}

export const unsubscribeUserReducer = combineReducers({
    email: reduceResponseField<string>(unsubscribeUserTypes, "email", null),
    requestState: reduceRequestState(unsubscribeUserTypes),
    error: reduceRequestErrors(unsubscribeUserTypes)
});
import {combineReducers} from "redux";

import {IAppError, reduceRequestErrors, reduceRequestState, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {unsubscribeUserTypes} from "../actions/fetch_user_unsubscription_data";

export interface IUnsubscribeUserStore {
    email: string | null;
    requestState: RequestState;
    error: IAppError;
}

export const unsubscribeUserReducer = combineReducers({
    email: reduceResponseField<string>(unsubscribeUserTypes, "email", null),
    requestState: reduceRequestState(unsubscribeUserTypes),
    error: reduceRequestErrors(unsubscribeUserTypes)
});
