import {IRequestMeta, IRequestServicesMeta} from "@pg-mono/request";

import {serverApiUrls} from "./constants/server_api_urls";
import {isBrowser, isServer, rpClientApiUrl, rpInternalApiCookieDomain, rpServerInternalApiUrl, rpServerV1ApiUrl} from "./read_rp_environment_variables";

export interface IRPRequestMeta extends Partial<IRequestMeta> {}
interface IRPServicesMeta extends Partial<IRequestServicesMeta> {}

export const createRPRequestMeta = (services: IRPServicesMeta): IRPRequestMeta => {
    const meta: IRPRequestMeta = {...services};
    if (isServer) {
        meta.serverApiUrl = {
            main: rpServerV1ApiUrl,
            internal: rpServerInternalApiUrl
        };
        meta.serverApiUrls = serverApiUrls;
        meta.internalApiCookieDomain = rpInternalApiCookieDomain;
    } else if (isBrowser) {
        meta.clientApiUrl = {
            main: rpClientApiUrl
        };
    }
    return meta;
};
import {IRequestMeta, IRequestServicesMeta} from "@pg-mono/request";

import {serverApiUrls} from "./constants/server_api_urls";
import {isBrowser, isServer, rpClientApiUrl, rpInternalApiCookieDomain, rpServerInternalApiUrl, rpServerV1ApiUrl} from "./read_rp_environment_variables";

export interface IRPRequestMeta extends Partial<IRequestMeta> {}
interface IRPServicesMeta extends Partial<IRequestServicesMeta> {}

export const createRPRequestMeta = (services: IRPServicesMeta): IRPRequestMeta => {
    const meta: IRPRequestMeta = {...services};
    if (isServer) {
        meta.serverApiUrl = {
            main: rpServerV1ApiUrl,
            internal: rpServerInternalApiUrl
        };
        meta.serverApiUrls = serverApiUrls;
        meta.internalApiCookieDomain = rpInternalApiCookieDomain;
    } else if (isBrowser) {
        meta.clientApiUrl = {
            main: rpClientApiUrl
        };
    }
    return meta;
};
