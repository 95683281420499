import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferList} from "../../../meta_data/page_description/offer_list";
import {titleOfferList} from "../../../meta_data/page_title/offer_list";
import {getOfferTypeUrl} from "../../helpers/OfferType";

export const updateOfferMultiListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {selectedRegions} = getState().offerList;
    const regions = selectedRegions.length > 0 ? selectedRegions : undefined;
    const {propertiesCount, latestQuery} = getState().offerList.list;
    const type = parseInt(latestQuery["type"] as string, 10);

    const filtersData = pick(latestQuery, ["construction_end_date", "is_luxury", "price_lower_than_average"]);

    const title = titleOfferList(
        {
            regionName: regions && `${regions.map((r) => `- ${r.full_name_reverted}`).join(" ")}`,
            type,
            regions
        },
        undefined,
        {
            construction_end_date: filtersData.construction_end_date,
            is_luxury: Boolean(filtersData.is_luxury),
            price_lower_than_average: Boolean(filtersData.price_lower_than_average)
        }
    );

    const fullQuery = {
        regions: regions,
        propertiesCount,
        is_luxury: Boolean(latestQuery.is_luxury),
        price_lower_than_average: Boolean(latestQuery.price_lower_than_average),
        sort: latestQuery.sort,
        offerType: isFinite(type) ? type : undefined,
        type: isFinite(type) ? type : undefined,
        construction_end_date: latestQuery.construction_end_date
    };

    const description = descriptionOfferList(fullQuery);

    const robots = "noindex, follow";
    const customCanonicalPath = `/s/${getOfferTypeUrl(type)}-${regions && regions[0].slug}/`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname, robots, customCanonicalPath}));
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferList} from "../../../meta_data/page_description/offer_list";
import {titleOfferList} from "../../../meta_data/page_title/offer_list";
import {getOfferTypeUrl} from "../../helpers/OfferType";

export const updateOfferMultiListMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {selectedRegions} = getState().offerList;
    const regions = selectedRegions.length > 0 ? selectedRegions : undefined;
    const {propertiesCount, latestQuery} = getState().offerList.list;
    const type = parseInt(latestQuery["type"] as string, 10);

    const filtersData = pick(latestQuery, ["construction_end_date", "is_luxury", "price_lower_than_average"]);

    const title = titleOfferList(
        {
            regionName: regions && `${regions.map((r) => `- ${r.full_name_reverted}`).join(" ")}`,
            type,
            regions
        },
        undefined,
        {
            construction_end_date: filtersData.construction_end_date,
            is_luxury: Boolean(filtersData.is_luxury),
            price_lower_than_average: Boolean(filtersData.price_lower_than_average)
        }
    );

    const fullQuery = {
        regions: regions,
        propertiesCount,
        is_luxury: Boolean(latestQuery.is_luxury),
        price_lower_than_average: Boolean(latestQuery.price_lower_than_average),
        sort: latestQuery.sort,
        offerType: isFinite(type) ? type : undefined,
        type: isFinite(type) ? type : undefined,
        construction_end_date: latestQuery.construction_end_date
    };

    const description = descriptionOfferList(fullQuery);

    const robots = "noindex, follow";
    const customCanonicalPath = `/s/${getOfferTypeUrl(type)}-${regions && regions[0].slug}/`;
    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname, robots, customCanonicalPath}));
    return true;
};
