import {combineReducers} from "redux";

import {IPoiStore, poiReducer} from "./poi_reducer";
import {IPoiTravelDirectionsStore, poiTravelDirectionsReducer} from "./poi_travel_directions_reducer";
import {userMapPreferencesReducer, UserMapPreferencesStore} from "./user_map_preferences_reducer";
import {userPoiReducer, UserPoiStore} from "./user_poi_reducer";

export interface IMapsStore {
    poi: IPoiStore;
    travelDirections: IPoiTravelDirectionsStore;
    userPoi: UserPoiStore;
    userPreferences: UserMapPreferencesStore;
}

export const mapsReducer = combineReducers({
    poi: poiReducer,
    travelDirections: poiTravelDirectionsReducer,
    userPoi: userPoiReducer,
    userPreferences: userMapPreferencesReducer
});
import {combineReducers} from "redux";

import {IPoiStore, poiReducer} from "./poi_reducer";
import {IPoiTravelDirectionsStore, poiTravelDirectionsReducer} from "./poi_travel_directions_reducer";
import {userMapPreferencesReducer, UserMapPreferencesStore} from "./user_map_preferences_reducer";
import {userPoiReducer, UserPoiStore} from "./user_poi_reducer";

export interface IMapsStore {
    poi: IPoiStore;
    travelDirections: IPoiTravelDirectionsStore;
    userPoi: UserPoiStore;
    userPreferences: UserMapPreferencesStore;
}

export const mapsReducer = combineReducers({
    poi: poiReducer,
    travelDirections: poiTravelDirectionsReducer,
    userPoi: userPoiReducer,
    userPreferences: userMapPreferencesReducer
});
