import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const XIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M 5.468962 4.623115 L 7.301885 2.538311 L 6.867577 2.538311 L 5.275346 4.348154 L 4.004539 2.538311 L 2.5385 2.538311 L 4.460615 5.275346 L 2.5385 7.461384 L 2.972785 7.461384 L 4.653193 5.549692 L 5.995538 7.461384 L 7.461577 7.461384 L 5.468962 4.623115 Z M 4.873961 5.299385 L 4.678885 5.026654 L 3.1294 2.858673 L 3.796554 2.858673 L 5.047423 4.608962 L 5.241654 4.881654 L 6.867384 7.156654 L 6.200231 7.156654 L 4.873961 5.299385 Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const XIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M 5.468962 4.623115 L 7.301885 2.538311 L 6.867577 2.538311 L 5.275346 4.348154 L 4.004539 2.538311 L 2.5385 2.538311 L 4.460615 5.275346 L 2.5385 7.461384 L 2.972785 7.461384 L 4.653193 5.549692 L 5.995538 7.461384 L 7.461577 7.461384 L 5.468962 4.623115 Z M 4.873961 5.299385 L 4.678885 5.026654 L 3.1294 2.858673 L 3.796554 2.858673 L 5.047423 4.608962 L 5.241654 4.881654 L 6.867384 7.156654 L 6.200231 7.156654 L 4.873961 5.299385 Z" />
        </SvgIcon>
    );
};
