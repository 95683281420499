import {isNumber} from "@pg-mono/nodash";

import {Country} from "../types/Country";

export const getCountriesList = () => {
    return Object.values(Country).filter((value) => isNumber(value)) as number[];
};
import {isNumber} from "@pg-mono/nodash";

import {Country} from "../types/Country";

export const getCountriesList = () => {
    return Object.values(Country).filter((value) => isNumber(value)) as number[];
};
