export enum ViewType {
    ABOUT_US = "about_us",
    ARTICLE = "article",
    ARTICLE_LIST = "article_list",
    FOR_VENDOR = "for_vendor",
    HOMEPAGE = "homepage",
    MY_OFFERS = "my_offers",
    OFFER = "offer",
    OFFER_LIST = "offer_list",
    OFFER_LIST_ABROAD = "offer_list_abroad",
    OFFER_LIST_MAP = "offer_list_map",
    OFFER_LIST_MAP_ABROAD = "offer_list_map_abroad",
    OFFER_RANKING = "offer_ranking",
    PROMOTION_LIST = "promotion_list",
    PROPERTY = "property",
    VENDOR = "vendor",
    VENDOR_LIST = "vendor_list",
    FOR_INVESTMENT = "for_investment",
    FOR_INVESTMENT_LIST = "for_investment_list",
    FOR_INVESTMENT_LIST_MAP = "for_investment_list_map",
    CREDIT_CALCULATOR = "credit_view",
    CREDIT_OFFERS = "credit_offers_view",
    CREDIT_RATING_CALCULATOR = "credit_rating_view",
    // additional (non GTM) views
    REGISTRATION = "registration",
    MY_FAVOURITES_LIST = "my_favourites_list",
    COMPARISON_TOOL = "comparison_tool",
    BUYLANDO_LANDING = "buylando_landing",
    PROPERTY_CONFIGURATOR = "property_configurator",
    MODULAR_HOUSES_OFFER_DETAIL = "modular_houses_offer_detail"
}

// here we keep only GTM specified values
// https://docs.google.com/spreadsheets/d/1Yye_OKLPd_4u0jC0Dyuppf40cuqQD3JC8JqBAlIsDQ8/edit#gid=1610419594
export const getViewTypeName = (viewType: ViewType): string => {
    switch (viewType) {
        case ViewType.HOMEPAGE:
            return "Strona główna";
        case ViewType.OFFER_LIST_MAP:
            return "Mapa";
        case ViewType.OFFER_LIST:
            return "Listing - inwestycje";
        case ViewType.PROMOTION_LIST:
            return "Listing - promocje";
        case ViewType.VENDOR_LIST:
            return "Listing - deweloperzy";
        case ViewType.VENDOR:
            return "Widok dewelopera";
        case ViewType.OFFER:
            return "Widok inwestycji";
        case ViewType.PROPERTY:
            return "Widok nieruchomości";
        case ViewType.ARTICLE_LIST:
            return "Listing - artykuły";
        case ViewType.ARTICLE:
            return "Widok artykułu";
        case ViewType.OFFER_RANKING:
            return "Ranking inwestycji";
        case ViewType.FOR_VENDOR:
            return "Dla dewelopera";
        case ViewType.ABOUT_US:
            return "O nas";
        case ViewType.MY_OFFERS:
            return "Moje oferty";
        case ViewType.MY_FAVOURITES_LIST:
            return "Moja lista";
        case ViewType.COMPARISON_TOOL:
            return "Porównywarka";
        case ViewType.BUYLANDO_LANDING:
            return "Buylando - Landing";
        case ViewType.CREDIT_CALCULATOR:
            return "Kredyty";
        case ViewType.CREDIT_OFFERS:
            return "Listing - kredyty";
        case ViewType.CREDIT_RATING_CALCULATOR:
            return "Kredyty - kalkulator zdolności";
        default:
            return "Inne";
    }
};
export enum ViewType {
    ABOUT_US = "about_us",
    ARTICLE = "article",
    ARTICLE_LIST = "article_list",
    FOR_VENDOR = "for_vendor",
    HOMEPAGE = "homepage",
    MY_OFFERS = "my_offers",
    OFFER = "offer",
    OFFER_LIST = "offer_list",
    OFFER_LIST_ABROAD = "offer_list_abroad",
    OFFER_LIST_MAP = "offer_list_map",
    OFFER_LIST_MAP_ABROAD = "offer_list_map_abroad",
    OFFER_RANKING = "offer_ranking",
    PROMOTION_LIST = "promotion_list",
    PROPERTY = "property",
    VENDOR = "vendor",
    VENDOR_LIST = "vendor_list",
    FOR_INVESTMENT = "for_investment",
    FOR_INVESTMENT_LIST = "for_investment_list",
    FOR_INVESTMENT_LIST_MAP = "for_investment_list_map",
    CREDIT_CALCULATOR = "credit_view",
    CREDIT_OFFERS = "credit_offers_view",
    CREDIT_RATING_CALCULATOR = "credit_rating_view",
    // additional (non GTM) views
    REGISTRATION = "registration",
    MY_FAVOURITES_LIST = "my_favourites_list",
    COMPARISON_TOOL = "comparison_tool",
    BUYLANDO_LANDING = "buylando_landing",
    PROPERTY_CONFIGURATOR = "property_configurator",
    MODULAR_HOUSES_OFFER_DETAIL = "modular_houses_offer_detail"
}

// here we keep only GTM specified values
// https://docs.google.com/spreadsheets/d/1Yye_OKLPd_4u0jC0Dyuppf40cuqQD3JC8JqBAlIsDQ8/edit#gid=1610419594
export const getViewTypeName = (viewType: ViewType): string => {
    switch (viewType) {
        case ViewType.HOMEPAGE:
            return "Strona główna";
        case ViewType.OFFER_LIST_MAP:
            return "Mapa";
        case ViewType.OFFER_LIST:
            return "Listing - inwestycje";
        case ViewType.PROMOTION_LIST:
            return "Listing - promocje";
        case ViewType.VENDOR_LIST:
            return "Listing - deweloperzy";
        case ViewType.VENDOR:
            return "Widok dewelopera";
        case ViewType.OFFER:
            return "Widok inwestycji";
        case ViewType.PROPERTY:
            return "Widok nieruchomości";
        case ViewType.ARTICLE_LIST:
            return "Listing - artykuły";
        case ViewType.ARTICLE:
            return "Widok artykułu";
        case ViewType.OFFER_RANKING:
            return "Ranking inwestycji";
        case ViewType.FOR_VENDOR:
            return "Dla dewelopera";
        case ViewType.ABOUT_US:
            return "O nas";
        case ViewType.MY_OFFERS:
            return "Moje oferty";
        case ViewType.MY_FAVOURITES_LIST:
            return "Moja lista";
        case ViewType.COMPARISON_TOOL:
            return "Porównywarka";
        case ViewType.BUYLANDO_LANDING:
            return "Buylando - Landing";
        case ViewType.CREDIT_CALCULATOR:
            return "Kredyty";
        case ViewType.CREDIT_OFFERS:
            return "Listing - kredyty";
        case ViewType.CREDIT_RATING_CALCULATOR:
            return "Kredyty - kalkulator zdolności";
        default:
            return "Inne";
    }
};
