import {formatISO, lastDayOfMonth, setDate, sub} from "date-fns";
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {fetchAveragePricesBySlug} from "../api/fetch_average_prices";
import {getTypeForFetch, PricesViewType} from "../helpers/prices_view_type";

const FETCH_REPORT_PRICE_AVERAGE_PRICES = "reports/FETCH_REPORT_PRICE_AVERAGE_PRICES";
const FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES = "reports/FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES";
export const fetchReportPriceAveragePricesTypes = createRequestActionTypes(FETCH_REPORT_PRICE_AVERAGE_PRICES);
export const fetchReportPriceYearAgoAveragePricesTypes = createRequestActionTypes(FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES);

export const fetchReportPriceAveragePrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceAveragePricesTypes.start});

    const date = new Date();
    const query = {
        //first day of month, 6 months ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 6}), {representation: "date"}),
        //last day of month before date, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1})), {representation: "date"}),
        scenario: "rp-avg",
        slug_city: ctx.match.params.regionSlug,
        offer_type: getTypeForFetch(PricesViewType.FLATS)
    };

    const prices = await fetchAveragePricesBySlug(query);

    if (prices) {
        dispatch({type: fetchReportPriceAveragePricesTypes.success, result: prices});

        return prices;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};

export const fetchReportPriceYearAgoAveragePrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceYearAgoAveragePricesTypes.start});

    const date = new Date();
    const query = {
        //first day of month before date year ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 1, years: 1}), {representation: "date"}),
        //last day of month before date year ago, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1, years: 1})), {representation: "date"}),
        scenario: "rp-avg",
        slug_city: ctx.match.params.regionSlug,
        offer_type: getTypeForFetch(PricesViewType.FLATS)
    };

    const yearAgoPrices = await fetchAveragePricesBySlug(query);

    if (yearAgoPrices) {
        dispatch({type: fetchReportPriceYearAgoAveragePricesTypes.success, result: yearAgoPrices[0]});

        return yearAgoPrices[0];
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
import {formatISO, lastDayOfMonth, setDate, sub} from "date-fns";
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {fetchAveragePricesBySlug} from "../api/fetch_average_prices";
import {getTypeForFetch, PricesViewType} from "../helpers/prices_view_type";

const FETCH_REPORT_PRICE_AVERAGE_PRICES = "reports/FETCH_REPORT_PRICE_AVERAGE_PRICES";
const FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES = "reports/FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES";
export const fetchReportPriceAveragePricesTypes = createRequestActionTypes(FETCH_REPORT_PRICE_AVERAGE_PRICES);
export const fetchReportPriceYearAgoAveragePricesTypes = createRequestActionTypes(FETCH_REPORT_PRICE_YEAR_AGO_AVERAGE_PRICES);

export const fetchReportPriceAveragePrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceAveragePricesTypes.start});

    const date = new Date();
    const query = {
        //first day of month, 6 months ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 6}), {representation: "date"}),
        //last day of month before date, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1})), {representation: "date"}),
        scenario: "rp-avg",
        slug_city: ctx.match.params.regionSlug,
        offer_type: getTypeForFetch(PricesViewType.FLATS)
    };

    const prices = await fetchAveragePricesBySlug(query);

    if (prices) {
        dispatch({type: fetchReportPriceAveragePricesTypes.success, result: prices});

        return prices;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};

export const fetchReportPriceYearAgoAveragePrices = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchReportPriceYearAgoAveragePricesTypes.start});

    const date = new Date();
    const query = {
        //first day of month before date year ago, format yyyy-mm-dd
        date_start: formatISO(sub(setDate(date, 1), {months: 1, years: 1}), {representation: "date"}),
        //last day of month before date year ago, format yyyy-mm-dd
        date_end: formatISO(lastDayOfMonth(sub(date, {months: 1, years: 1})), {representation: "date"}),
        scenario: "rp-avg",
        slug_city: ctx.match.params.regionSlug,
        offer_type: getTypeForFetch(PricesViewType.FLATS)
    };

    const yearAgoPrices = await fetchAveragePricesBySlug(query);

    if (yearAgoPrices) {
        dispatch({type: fetchReportPriceYearAgoAveragePricesTypes.success, result: yearAgoPrices[0]});

        return yearAgoPrices[0];
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
