import React, {useMemo} from "react";
import {matchPath, useLocation} from "react-router";
import {css, Theme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {ChevronDownIcon} from "@pg-design/icons-module";
import {useIsPrint} from "@pg-mono/hooks";
import {rpAppPath} from "@pg-mono/rp-routes";
import {Footer as DesignFooter} from "@pg-rp/footer";

import {useFooterNavData} from "../hooks/use_footer_nav_data";
import {useTopArticlesLinks} from "../hooks/use_top_articles_links";

declare global {
    interface Window {
        Cookiebot?: {renew: () => void};
    }
}

export const Footer = () => {
    const isPrint = useIsPrint();
    const location = useLocation();
    const topArticles = useTopArticlesLinks();
    const footerNavDataBase = useFooterNavData();
    const footerNavData = useMemo(() => ({...footerNavDataBase, topArticles}), [topArticles]);

    const footerExcludedPaths = [
        {path: rpAppPath.offer.map, exact: true, strict: true},
        {path: rpAppPath.friendlyOfferListMap.base, exact: true, strict: true},
        {path: rpAppPath.multiOfferList.map, exact: true, strict: true},
        {path: rpAppPath.investmentOffer.investmentCategory.map, exact: true, strict: true},
        {path: rpAppPath.investmentOffer.investmentCategory.subcategory.map, exact: true, strict: true}
    ];

    const isFooterExcluded = footerExcludedPaths.some((excludedPath) => matchPath(location.pathname, excludedPath));

    if (isFooterExcluded || isPrint) {
        return null;
    }

    return (
        <div css={footerBg}>
            <WideContent>
                <DesignFooter footerMenuData={footerNavData} panelArrowDown={<ChevronDownIcon />} />
            </WideContent>
        </div>
    );
};

const footerBg = (theme: Theme) => css`
    background-color: ${theme.colors.secondary};
`;
import React, {useMemo} from "react";
import {matchPath, useLocation} from "react-router";
import {css, Theme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {ChevronDownIcon} from "@pg-design/icons-module";
import {useIsPrint} from "@pg-mono/hooks";
import {rpAppPath} from "@pg-mono/rp-routes";
import {Footer as DesignFooter} from "@pg-rp/footer";

import {useFooterNavData} from "../hooks/use_footer_nav_data";
import {useTopArticlesLinks} from "../hooks/use_top_articles_links";

declare global {
    interface Window {
        Cookiebot?: {renew: () => void};
    }
}

export const Footer = () => {
    const isPrint = useIsPrint();
    const location = useLocation();
    const topArticles = useTopArticlesLinks();
    const footerNavDataBase = useFooterNavData();
    const footerNavData = useMemo(() => ({...footerNavDataBase, topArticles}), [topArticles]);

    const footerExcludedPaths = [
        {path: rpAppPath.offer.map, exact: true, strict: true},
        {path: rpAppPath.friendlyOfferListMap.base, exact: true, strict: true},
        {path: rpAppPath.multiOfferList.map, exact: true, strict: true},
        {path: rpAppPath.investmentOffer.investmentCategory.map, exact: true, strict: true},
        {path: rpAppPath.investmentOffer.investmentCategory.subcategory.map, exact: true, strict: true}
    ];

    const isFooterExcluded = footerExcludedPaths.some((excludedPath) => matchPath(location.pathname, excludedPath));

    if (isFooterExcluded || isPrint) {
        return null;
    }

    return (
        <div css={footerBg}>
            <WideContent>
                <DesignFooter footerMenuData={footerNavData} panelArrowDown={<ChevronDownIcon />} />
            </WideContent>
        </div>
    );
};

const footerBg = (theme: Theme) => css`
    background-color: ${theme.colors.secondary};
`;
