import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchArticleListActionTypes, ISitemapArticle} from "../actions/fetch_site_map_article_list";

export interface ISiteMapArticleStore {
    list: ISitemapArticle[];
    count: number;
    page: number;
    page_size: number;
}

export const siteMapArticleReducer = combineReducers({
    list: reduceResponseField<ISitemapArticle[]>(fetchArticleListActionTypes, "results", []),
    count: reduceResponseField<number>(fetchArticleListActionTypes, "count", 0),
    page: reduceResponseField<number>(fetchArticleListActionTypes, "page", 0),
    page_size: reduceResponseField<number>(fetchArticleListActionTypes, "page_size", 0)
});
import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchArticleListActionTypes, ISitemapArticle} from "../actions/fetch_site_map_article_list";

export interface ISiteMapArticleStore {
    list: ISitemapArticle[];
    count: number;
    page: number;
    page_size: number;
}

export const siteMapArticleReducer = combineReducers({
    list: reduceResponseField<ISitemapArticle[]>(fetchArticleListActionTypes, "results", []),
    count: reduceResponseField<number>(fetchArticleListActionTypes, "count", 0),
    page: reduceResponseField<number>(fetchArticleListActionTypes, "page", 0),
    page_size: reduceResponseField<number>(fetchArticleListActionTypes, "page_size", 0)
});
