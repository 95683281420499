export const V1_API_PREFIX = "/api/";
export const BIG_DATA_API_PREFIX = "/api-charts/rp/";
export const GLUE_API_PREFIX = "/api/s/v2/";
export const V2_API_PREFIX = "/api/v2/";
export const USER_API_PREFIX = "/user-api/";
export const MP_API_PREFIX = "/api/marketplace/";
export const V1_API_PREFIX = "/api/";
export const BIG_DATA_API_PREFIX = "/api-charts/rp/";
export const GLUE_API_PREFIX = "/api/s/v2/";
export const V2_API_PREFIX = "/api/v2/";
export const USER_API_PREFIX = "/user-api/";
export const MP_API_PREFIX = "/api/marketplace/";
