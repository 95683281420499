import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SearchIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 8.623 6.643 6.266a3.2 3.2 0 1 0-.377.377L8.623 9 9 8.623ZM1.533 4.2a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const SearchIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M9 8.623 6.643 6.266a3.2 3.2 0 1 0-.377.377L8.623 9 9 8.623ZM1.533 4.2a2.667 2.667 0 1 1 5.334 0 2.667 2.667 0 0 1-5.334 0Z" />
        </SvgIcon>
    );
};
