import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {getCookie, matchApiUrlByApiPrefix} from "@pg-mono/request";

import {serverApiUrls} from "../constants/server_api_urls";
import {isServer} from "../read_rp_environment_variables";
import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const rpServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;
    const serverApiUrl = matchApiUrlByApiPrefix(serverApiUrls, endpointUrl);

    const baseUrl = isServer ? serverApiUrl : "";
    const absoluteUrl = `${baseUrl}${endpointUrl}`;

    const adjustedArgs = typeof args === "string" ? `${absoluteUrl}` : {...args, url: `${absoluteUrl}`};

    const rpBaseQuery = fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            const customCsrfToken = headers.get("X-CSRFToken");
            const csrf = !isServer ? getCookie("csrftoken") : null;

            if (api.type === "mutation") {
                headers.set("Content-Type", "application/json");
            }

            if (api.type === "mutation" && !customCsrfToken && csrf) {
                headers.set("X-CSRFToken", csrf);
            }

            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api),
        timeout: 30000
    });

    return rpBaseQuery(adjustedArgs, api, extraOptions);
};

export const rpApi = createApi({
    reducerPath: "rpApi",
    baseQuery: rpServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["ModularHouseOffer"]
});
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {getCookie, matchApiUrlByApiPrefix} from "@pg-mono/request";

import {serverApiUrls} from "../constants/server_api_urls";
import {isServer} from "../read_rp_environment_variables";
import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const rpServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;
    const serverApiUrl = matchApiUrlByApiPrefix(serverApiUrls, endpointUrl);

    const baseUrl = isServer ? serverApiUrl : "";
    const absoluteUrl = `${baseUrl}${endpointUrl}`;

    const adjustedArgs = typeof args === "string" ? `${absoluteUrl}` : {...args, url: `${absoluteUrl}`};

    const rpBaseQuery = fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            const customCsrfToken = headers.get("X-CSRFToken");
            const csrf = !isServer ? getCookie("csrftoken") : null;

            if (api.type === "mutation") {
                headers.set("Content-Type", "application/json");
            }

            if (api.type === "mutation" && !customCsrfToken && csrf) {
                headers.set("X-CSRFToken", csrf);
            }

            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api),
        timeout: 30000
    });

    return rpBaseQuery(adjustedArgs, api, extraOptions);
};

export const rpApi = createApi({
    reducerPath: "rpApi",
    baseQuery: rpServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["ModularHouseOffer"]
});
