export enum OfferMetaRobots {
    INDEX_FOLLOW = "index, follow",
    NOINDEX_FOLLOW = "noindex, follow",
    NOINDEX = "noindex"
}

export const offerMetaRobotsDictionary = {
    1: OfferMetaRobots.INDEX_FOLLOW,
    2: OfferMetaRobots.NOINDEX_FOLLOW,
    3: OfferMetaRobots.NOINDEX
} as const;

export const offerMetaRobotsDictionaryReversed = {
    [OfferMetaRobots.INDEX_FOLLOW]: 1,
    [OfferMetaRobots.NOINDEX_FOLLOW]: 2,
    [OfferMetaRobots.NOINDEX]: 3
} as const;

export type IOfferMetaRobotsIds = keyof typeof offerMetaRobotsDictionary;
export type IOfferMetaRobotsValues = (typeof offerMetaRobotsDictionary)[IOfferMetaRobotsIds];
export enum OfferMetaRobots {
    INDEX_FOLLOW = "index, follow",
    NOINDEX_FOLLOW = "noindex, follow",
    NOINDEX = "noindex"
}

export const offerMetaRobotsDictionary = {
    1: OfferMetaRobots.INDEX_FOLLOW,
    2: OfferMetaRobots.NOINDEX_FOLLOW,
    3: OfferMetaRobots.NOINDEX
} as const;

export const offerMetaRobotsDictionaryReversed = {
    [OfferMetaRobots.INDEX_FOLLOW]: 1,
    [OfferMetaRobots.NOINDEX_FOLLOW]: 2,
    [OfferMetaRobots.NOINDEX]: 3
} as const;

export type IOfferMetaRobotsIds = keyof typeof offerMetaRobotsDictionary;
export type IOfferMetaRobotsValues = (typeof offerMetaRobotsDictionary)[IOfferMetaRobotsIds];
