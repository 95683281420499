export const safelyParsePage = (page: string | string[]) => {
    const parsedPage = getParsedPage(page);
    return isNaN(parsedPage) ? 1 : parsedPage;
};

const getParsedPage = (page: string | string[]) => {
    if (Array.isArray(page)) {
        return parseInt(page[0], 10);
    } else {
        return parseInt(page, 10);
    }
};
export const safelyParsePage = (page: string | string[]) => {
    const parsedPage = getParsedPage(page);
    return isNaN(parsedPage) ? 1 : parsedPage;
};

const getParsedPage = (page: string | string[]) => {
    if (Array.isArray(page)) {
        return parseInt(page[0], 10);
    } else {
        return parseInt(page, 10);
    }
};
