import {upperFirst} from "@pg-mono/nodash";
import {numberFormat, pluralize} from "@pg-mono/string-utils";

import {getSearchFilterPrefix} from "../../offer/helpers/searches/generate_name";
import {offerSearchPhraseBuilder} from "../../offer/helpers/searches/search_phrase";
import {getHouseFilterMetaText} from "../../offer/list/helpers/sub_type/meta/get_house_filter_meta_text";
import {getFloorChoiceByOfferListSubType} from "../../offer/list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_house_filter";
import {OfferListSubType} from "../../offer/list/types/OfferListSubType";
import {OfferType} from "../../offer/types/OfferType";
import {getFloorChoiceText} from "../../real_estate/utils/get_floor_choice_text";
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {generateMetaPageNumber} from "../utils/generate_meta_page_number";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";

export interface IRegion {
    name: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
    short_name: string;
    short_name_reverted: string;
    type: RegionType;
    full_name_reverted: string;
}

interface IDescriptionOfferList {
    offerType?: OfferType;
    regions?: IRegion[];
    rooms_0?: string;
    rooms_1?: string;
    is_luxury?: boolean;
    price_lower_than_average?: boolean;
    construction_end_date?: string;
    sort?: string | undefined;
    propertiesCount?: number | null;
    page?: number;
    offerListSubType?: OfferListSubType;
    offerListSubFilter?: string;
    country?: string;
}
const getType = (offerType?: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "inwestycji mieszkaniowych";
        case OfferType.HOUSE:
            return "domów";
        case OfferType.COMMERCIAL:
            return "lokali użytkowych";
        default:
            return "nieruchomości";
    }
};

export const descriptionOfferListMap = (props: IDescriptionOfferList) => {
    const type = getType(props.offerType);
    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    const properties = props.propertiesCount ? props.propertiesCount : "";
    const propertiesPluralize = pluralize(["oferta", "oferty", "ofert"]);

    return `Mapa ${type} ${region} - ${
        properties && `${properties} ${propertiesPluralize(properties)}`
    } od deweloperów ✅. Szybko i bezpłatnie. Sprawdź, gdzie się buduje!`;
};

export const descriptionOfferList = (props: IDescriptionOfferList) => {
    const {is_luxury, price_lower_than_average, construction_end_date, offerListSubFilter} = props;
    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    const propertiesPluralize = pluralize(["oferta", "oferty", "ofert"]);
    const searchFilterPrefix = upperFirst(
        getSearchFilterPrefix({construction_end_date, is_luxury, price_lower_than_average: Boolean(price_lower_than_average)})
    );

    const isSingleRoomFlat = props.offerType === OfferType.FLAT && props.rooms_1 === "1";
    const searchPhrase = offerSearchPhraseBuilder(
        {...props, regionName: region},
        {
            rooms: {
                showSingleFilterAsNumber: (props.offerType === OfferType.FLAT && props.rooms_1 !== "1") || props.offerType === OfferType.HOUSE
            },
            isMetaDescription: true,
            isSingleRoomFlat
        }
    );
    const properties = props.propertiesCount ? `${numberFormat(props.propertiesCount)} ${propertiesPluralize(props.propertiesCount)}` : "0 ofert";

    const forSalePreSuffix = props.regions?.length === 1 && props.regions[0].type === RegionType.NEIGHBORHOOD ? "na sprzedaż " : "";
    const suffix = `${forSalePreSuffix}od deweloperów ✅. Szybko, bezpłatnie i z pomocą doradcy do spraw nieruchomości. Sprawdź${is_luxury ? " nieruchomości premium!" : "!"}`;
    const offerType = props.offerType === OfferType.FLAT ? "mieszkania" : props.offerType === OfferType.HOUSE ? "domy" : "nieruchomości";

    if (props.country && Number(props.country) === Country.SPAIN) {
        const offerTypeString = region ? ` ${offerType} ` : upperFirst(offerType);

        return `${region}${offerTypeString} Hiszpania - ${properties} na sprzedaż ✅. Zainwestuj w nieruchomość w Hiszpanii na rynekpierwotny.pl. Zarabiaj i wypoczywaj!`;
    }

    if (offerListSubFilter && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        const floorChoiceText = getFloorChoiceText(floorChoice);
        return `Mieszkania ${floorChoiceText} ${region} - ${properties} ${suffix}`;
    }

    if (offerListSubFilter && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        return `${searchFilterPrefix} domy ${getHouseFilterMetaText(offerListSubFilter)} ${region} - ${properties} ${suffix}`;
    }

    if (is_luxury || price_lower_than_average || construction_end_date === "0") {
        const regionIndex = searchPhrase.indexOf(region);

        return `${searchFilterPrefix} ${regionIndex > 0 ? searchPhrase.slice(0, regionIndex) : searchPhrase}${
            region && props.offerType && props.offerType !== OfferType.COMMERCIAL ? "na sprzedaż " : ""
        }${region ? `${region} -` : "-"} ${properties} ${suffix}${generateMetaPageNumber(props.page)}`;
    }

    return `${searchFilterPrefix} ${searchPhrase} - ${properties} ${suffix}${generateMetaPageNumber(props.page)}`;
};
import {upperFirst} from "@pg-mono/nodash";
import {numberFormat, pluralize} from "@pg-mono/string-utils";

import {getSearchFilterPrefix} from "../../offer/helpers/searches/generate_name";
import {offerSearchPhraseBuilder} from "../../offer/helpers/searches/search_phrase";
import {getHouseFilterMetaText} from "../../offer/list/helpers/sub_type/meta/get_house_filter_meta_text";
import {getFloorChoiceByOfferListSubType} from "../../offer/list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_house_filter";
import {OfferListSubType} from "../../offer/list/types/OfferListSubType";
import {OfferType} from "../../offer/types/OfferType";
import {getFloorChoiceText} from "../../real_estate/utils/get_floor_choice_text";
import {Country} from "../../region/types/Country";
import {RegionType} from "../../region/types/RegionType";
import {generateMetaPageNumber} from "../utils/generate_meta_page_number";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";

export interface IRegion {
    name: string;
    is_duplicate: boolean;
    parent: {
        name: string;
    } | null;
    short_name: string;
    short_name_reverted: string;
    type: RegionType;
    full_name_reverted: string;
}

interface IDescriptionOfferList {
    offerType?: OfferType;
    regions?: IRegion[];
    rooms_0?: string;
    rooms_1?: string;
    is_luxury?: boolean;
    price_lower_than_average?: boolean;
    construction_end_date?: string;
    sort?: string | undefined;
    propertiesCount?: number | null;
    page?: number;
    offerListSubType?: OfferListSubType;
    offerListSubFilter?: string;
    country?: string;
}
const getType = (offerType?: OfferType) => {
    switch (offerType) {
        case OfferType.FLAT:
            return "inwestycji mieszkaniowych";
        case OfferType.HOUSE:
            return "domów";
        case OfferType.COMMERCIAL:
            return "lokali użytkowych";
        default:
            return "nieruchomości";
    }
};

export const descriptionOfferListMap = (props: IDescriptionOfferList) => {
    const type = getType(props.offerType);
    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    const properties = props.propertiesCount ? props.propertiesCount : "";
    const propertiesPluralize = pluralize(["oferta", "oferty", "ofert"]);

    return `Mapa ${type} ${region} - ${
        properties && `${properties} ${propertiesPluralize(properties)}`
    } od deweloperów ✅. Szybko i bezpłatnie. Sprawdź, gdzie się buduje!`;
};

export const descriptionOfferList = (props: IDescriptionOfferList) => {
    const {is_luxury, price_lower_than_average, construction_end_date, offerListSubFilter} = props;
    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    const propertiesPluralize = pluralize(["oferta", "oferty", "ofert"]);
    const searchFilterPrefix = upperFirst(
        getSearchFilterPrefix({construction_end_date, is_luxury, price_lower_than_average: Boolean(price_lower_than_average)})
    );

    const isSingleRoomFlat = props.offerType === OfferType.FLAT && props.rooms_1 === "1";
    const searchPhrase = offerSearchPhraseBuilder(
        {...props, regionName: region},
        {
            rooms: {
                showSingleFilterAsNumber: (props.offerType === OfferType.FLAT && props.rooms_1 !== "1") || props.offerType === OfferType.HOUSE
            },
            isMetaDescription: true,
            isSingleRoomFlat
        }
    );
    const properties = props.propertiesCount ? `${numberFormat(props.propertiesCount)} ${propertiesPluralize(props.propertiesCount)}` : "0 ofert";

    const forSalePreSuffix = props.regions?.length === 1 && props.regions[0].type === RegionType.NEIGHBORHOOD ? "na sprzedaż " : "";
    const suffix = `${forSalePreSuffix}od deweloperów ✅. Szybko, bezpłatnie i z pomocą doradcy do spraw nieruchomości. Sprawdź${is_luxury ? " nieruchomości premium!" : "!"}`;
    const offerType = props.offerType === OfferType.FLAT ? "mieszkania" : props.offerType === OfferType.HOUSE ? "domy" : "nieruchomości";

    if (props.country && Number(props.country) === Country.SPAIN) {
        const offerTypeString = region ? ` ${offerType} ` : upperFirst(offerType);

        return `${region}${offerTypeString} Hiszpania - ${properties} na sprzedaż ✅. Zainwestuj w nieruchomość w Hiszpanii na rynekpierwotny.pl. Zarabiaj i wypoczywaj!`;
    }

    if (offerListSubFilter && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        const floorChoiceText = getFloorChoiceText(floorChoice);
        return `Mieszkania ${floorChoiceText} ${region} - ${properties} ${suffix}`;
    }

    if (offerListSubFilter && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        return `${searchFilterPrefix} domy ${getHouseFilterMetaText(offerListSubFilter)} ${region} - ${properties} ${suffix}`;
    }

    if (is_luxury || price_lower_than_average || construction_end_date === "0") {
        const regionIndex = searchPhrase.indexOf(region);

        return `${searchFilterPrefix} ${regionIndex > 0 ? searchPhrase.slice(0, regionIndex) : searchPhrase}${
            region && props.offerType && props.offerType !== OfferType.COMMERCIAL ? "na sprzedaż " : ""
        }${region ? `${region} -` : "-"} ${properties} ${suffix}${generateMetaPageNumber(props.page)}`;
    }

    return `${searchFilterPrefix} ${searchPhrase} - ${properties} ${suffix}${generateMetaPageNumber(props.page)}`;
};
