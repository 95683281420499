import React from "react";
import {Provider} from "react-redux";
import {Global, ThemeProvider} from "@emotion/react";
import {GoogleOAuthProvider} from "@react-oauth/google";

import {DSThemeProvider, IThemeRP, themeRP} from "@pg-design/themes";
import {WeglotContextProvider} from "@pg-mono/i18n";
import {UserDeviceProvider} from "@pg-mono/user-device";

import {GOOGLE_OAUTH_CLIENT_ID} from "../auth/utils/social_auth_config";
import {RpStore} from "../store/store";
import {globalStylesRp} from "./styles/emotion_global_styles_rp";
import {isDevelopment, weglotApiKey} from "./read_rp_environment_variables";

interface IProps {
    children: React.ReactNode;
    store: RpStore;
    userAgent?: string;
}

declare module "@emotion/react" {
    export interface Theme extends IThemeRP {}
}

export const RpCommonProviders: React.FC<IProps> = (props) => {
    return (
        <Provider store={props.store}>
            <DSThemeProvider>
                <ThemeProvider theme={themeRP}>
                    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                        <Global styles={globalStylesRp} />
                        <UserDeviceProvider userAgent={props.userAgent}>
                            <WeglotContextProvider
                                apiKey={weglotApiKey}
                                excludedTranslationBlocks={[{value: "#offers-panel-region"}, {value: ".bt"}]}
                                isDevelopment={isDevelopment}
                            >
                                {props.children}
                            </WeglotContextProvider>
                        </UserDeviceProvider>
                    </GoogleOAuthProvider>
                </ThemeProvider>
            </DSThemeProvider>
        </Provider>
    );
};
import React from "react";
import {Provider} from "react-redux";
import {Global, ThemeProvider} from "@emotion/react";
import {GoogleOAuthProvider} from "@react-oauth/google";

import {DSThemeProvider, IThemeRP, themeRP} from "@pg-design/themes";
import {WeglotContextProvider} from "@pg-mono/i18n";
import {UserDeviceProvider} from "@pg-mono/user-device";

import {GOOGLE_OAUTH_CLIENT_ID} from "../auth/utils/social_auth_config";
import {RpStore} from "../store/store";
import {globalStylesRp} from "./styles/emotion_global_styles_rp";
import {isDevelopment, weglotApiKey} from "./read_rp_environment_variables";

interface IProps {
    children: React.ReactNode;
    store: RpStore;
    userAgent?: string;
}

declare module "@emotion/react" {
    export interface Theme extends IThemeRP {}
}

export const RpCommonProviders: React.FC<IProps> = (props) => {
    return (
        <Provider store={props.store}>
            <DSThemeProvider>
                <ThemeProvider theme={themeRP}>
                    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                        <Global styles={globalStylesRp} />
                        <UserDeviceProvider userAgent={props.userAgent}>
                            <WeglotContextProvider
                                apiKey={weglotApiKey}
                                excludedTranslationBlocks={[{value: "#offers-panel-region"}, {value: ".bt"}]}
                                isDevelopment={isDevelopment}
                            >
                                {props.children}
                            </WeglotContextProvider>
                        </UserDeviceProvider>
                    </GoogleOAuthProvider>
                </ThemeProvider>
            </DSThemeProvider>
        </Provider>
    );
};
