import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {fetchRegionReportPriceBySlug} from "../../../region/api/fetch_region_report_price_by_slug";

const FETCH_PRICE_REPORT_REGION = "reports/FETCH_PRICE_REPORT_REGION";
export const fetchPriceReportRegionTypes = createRequestActionTypes(FETCH_PRICE_REPORT_REGION);

export const fetchPriceReportRegion = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPriceReportRegionTypes.start});

    const [region] = await fetchRegionReportPriceBySlug([ctx.match.params.regionSlug]);

    if (region) {
        dispatch({type: fetchPriceReportRegionTypes.success, result: region});

        return region;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {rpEnable404ResponseState} from "../../../errors/actions/rp_enable_404_response_state";
import {fetchRegionReportPriceBySlug} from "../../../region/api/fetch_region_report_price_by_slug";

const FETCH_PRICE_REPORT_REGION = "reports/FETCH_PRICE_REPORT_REGION";
export const fetchPriceReportRegionTypes = createRequestActionTypes(FETCH_PRICE_REPORT_REGION);

export const fetchPriceReportRegion = (ctx: IFetchContext<IRPRequestMeta, {regionSlug: string}>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchPriceReportRegionTypes.start});

    const [region] = await fetchRegionReportPriceBySlug([ctx.match.params.regionSlug]);

    if (region) {
        dispatch({type: fetchPriceReportRegionTypes.success, result: region});

        return region;
    }

    dispatch(rpEnable404ResponseState(ctx.route.pathname));

    return false;
};
