import Bugsnag from "@bugsnag/js";

export const notifyBugsnagClient = (err: {message: string; filename?: string; error?: Error}, errorInfo: string, errorData: Object = {}) => {
    if (err.error) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData: {errorInfo, errorData, deployment: "rp-web"}});
        });
    } else {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", {errorInfo, errorData, deployment: "rp-web"});
            }
        );
    }
};
import Bugsnag from "@bugsnag/js";

export const notifyBugsnagClient = (err: {message: string; filename?: string; error?: Error}, errorInfo: string, errorData: Object = {}) => {
    if (err.error) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData: {errorInfo, errorData, deployment: "rp-web"}});
        });
    } else {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", {errorInfo, errorData, deployment: "rp-web"});
            }
        );
    }
};
