export const generateMetaPageNumber = (page?: number): string => {
    if (page && page > 1) {
        return ` - strona ${page}`;
    }
    return "";
};
export const generateMetaPageNumber = (page?: number): string => {
    if (page && page > 1) {
        return ` - strona ${page}`;
    }
    return "";
};
