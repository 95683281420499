import {Dispatch} from "redux";

import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppPath} from "@pg-mono/rp-routes";

export const redirectToSiteMap = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.base));
    return false;
};

export const redirectToSiteMapVendors = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.vendor));
    return false;
};

export const redirectToSiteMapFlats = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.flat));
    return false;
};

export const redirectToSiteMapHouses = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.house));
    return false;
};

export const redirectToSiteMapCommercials = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.commercial));
    return false;
};
import {Dispatch} from "redux";

import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppPath} from "@pg-mono/rp-routes";

export const redirectToSiteMap = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.base));
    return false;
};

export const redirectToSiteMapVendors = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.vendor));
    return false;
};

export const redirectToSiteMapFlats = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.flat));
    return false;
};

export const redirectToSiteMapHouses = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.house));
    return false;
};

export const redirectToSiteMapCommercials = () => async (dispatch: Dispatch) => {
    dispatch(enable301ResponseState(rpAppPath.siteMap.commercial));
    return false;
};
