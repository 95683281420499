import {createContext} from "react";

import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";

export const ModalContext = createContext<IModalProps>(defaultModalParams);

export const ModalContextProvider = ModalContext.Provider;
import {createContext} from "react";

import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";

export const ModalContext = createContext<IModalProps>(defaultModalParams);

export const ModalContextProvider = ModalContext.Provider;
