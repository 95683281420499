export enum EventNames {
    UserLogin = "user_login",
    UserRegistration = "user_registration",
    UserUpdate = "user_update",
    UserLead = "user_lead",
    UserLeadModularHouses = "user_lead_modular_houses",
    UserArticleAttachmentDownload = "user_article_attachment_download"
}
export enum EventNames {
    UserLogin = "user_login",
    UserRegistration = "user_registration",
    UserUpdate = "user_update",
    UserLead = "user_lead",
    UserLeadModularHouses = "user_lead_modular_houses",
    UserArticleAttachmentDownload = "user_article_attachment_download"
}
