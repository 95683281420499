import {SiteSlug} from "@pg-mono/km-routes";

import {kmUrl} from "../app/read_rp_environment_variables";

export const getFullKMLink = (getPath: (params: {site: SiteSlug}) => string) => `${kmUrl}${getPath({site: SiteSlug.RP})}`;
import {SiteSlug} from "@pg-mono/km-routes";

import {kmUrl} from "../app/read_rp_environment_variables";

export const getFullKMLink = (getPath: (params: {site: SiteSlug}) => string) => `${kmUrl}${getPath({site: SiteSlug.RP})}`;
