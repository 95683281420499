import type {LocaleObject} from "yup";

/**
 * @deprecated use util from package `@pg-mono/react-hook-forms-utils`
 */
export const yupLocales: LocaleObject = {
    date: {},
    mixed: {
        required: "To pole nie może być puste",
        notType: "Niepoprawna wartość"
    },
    string: {
        email: "Podaj poprawny adres e-mail"
    }
};
import type {LocaleObject} from "yup";

/**
 * @deprecated use util from package `@pg-mono/react-hook-forms-utils`
 */
export const yupLocales: LocaleObject = {
    date: {},
    mixed: {
        required: "To pole nie może być puste",
        notType: "Niepoprawna wartość"
    },
    string: {
        email: "Podaj poprawny adres e-mail"
    }
};
