import {IAction, RequestState} from "@pg-mono/request-state";

import {IFavourite} from "../interfaces/IFavourite";
import {IFavourites} from "../interfaces/IFavourites";
import {FavouritesActionTypes} from "../utils/create_favourites_action_types";
import {favouriteInCollection} from "../utils/favourite_in_collection";
import {findLatestUpdatedFavouriteDate} from "../utils/find_latest_updated_favourite_date";

export interface IFavouriteStore {
    fetchRequest: RequestState;
    favourites: IFavourites;
}

export interface IFavouriteAction extends IAction {
    list: IFavourite[];
    offers: number[];
    properties: number[];
    latestUpdatedFavouriteDate?: string;
}

export const initialFavouriteState = {
    favourites: {list: [], offers: [], properties: [], latestUpdatedFavouriteDate: null, initialized: false},
    fetchRequest: RequestState.None
};

export const createFavouriteReducer =
    (favouriteTypes: FavouritesActionTypes) =>
    (state: IFavouriteStore = initialFavouriteState, action: IFavouriteAction): IFavouriteStore => {
        switch (action.type) {
            case favouriteTypes.set: {
                return {
                    fetchRequest: RequestState.Success,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(action.list),
                        list: action.list,
                        offers: action.offers,
                        properties: action.properties,
                        initialized: true
                    }
                };
            }
            case favouriteTypes.add: {
                const {list, offers, properties} = state.favourites;

                const updatedList = action.list ? list.concat(action.list) : list;

                return {
                    ...state,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(updatedList),
                        list: updatedList,
                        offers: action.offers ? offers.concat(action.offers) : offers,
                        properties: action.properties ? properties.concat(action.properties) : properties,
                        initialized: true
                    }
                };
            }
            case favouriteTypes.remove: {
                const {list, offers, properties} = state.favourites;

                const updatedList = list.filter((favourite) => !favouriteInCollection(favourite, action.list));

                return {
                    ...state,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(updatedList),
                        list: updatedList,
                        offers: offers.filter((offer) => !action.offers.includes(offer)),
                        properties: properties.filter((property) => !action.properties.includes(property)),
                        initialized: true
                    }
                };
            }
            case favouriteTypes.sync: {
                const {latestUpdatedFavouriteDate: stateValue} = state.favourites;

                const actionValue = findLatestUpdatedFavouriteDate(action.list);

                if ((!stateValue && actionValue) || (stateValue && actionValue && new Date(actionValue).getTime() > new Date(stateValue).getTime())) {
                    return {
                        ...state,
                        favourites: {
                            ...state.favourites,
                            latestUpdatedFavouriteDate: actionValue
                        }
                    };
                }

                return state;
            }
            default:
                return state;
        }
    };
import {IAction, RequestState} from "@pg-mono/request-state";

import {IFavourite} from "../interfaces/IFavourite";
import {IFavourites} from "../interfaces/IFavourites";
import {FavouritesActionTypes} from "../utils/create_favourites_action_types";
import {favouriteInCollection} from "../utils/favourite_in_collection";
import {findLatestUpdatedFavouriteDate} from "../utils/find_latest_updated_favourite_date";

export interface IFavouriteStore {
    fetchRequest: RequestState;
    favourites: IFavourites;
}

export interface IFavouriteAction extends IAction {
    list: IFavourite[];
    offers: number[];
    properties: number[];
    latestUpdatedFavouriteDate?: string;
}

export const initialFavouriteState = {
    favourites: {list: [], offers: [], properties: [], latestUpdatedFavouriteDate: null, initialized: false},
    fetchRequest: RequestState.None
};

export const createFavouriteReducer =
    (favouriteTypes: FavouritesActionTypes) =>
    (state: IFavouriteStore = initialFavouriteState, action: IFavouriteAction): IFavouriteStore => {
        switch (action.type) {
            case favouriteTypes.set: {
                return {
                    fetchRequest: RequestState.Success,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(action.list),
                        list: action.list,
                        offers: action.offers,
                        properties: action.properties,
                        initialized: true
                    }
                };
            }
            case favouriteTypes.add: {
                const {list, offers, properties} = state.favourites;

                const updatedList = action.list ? list.concat(action.list) : list;

                return {
                    ...state,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(updatedList),
                        list: updatedList,
                        offers: action.offers ? offers.concat(action.offers) : offers,
                        properties: action.properties ? properties.concat(action.properties) : properties,
                        initialized: true
                    }
                };
            }
            case favouriteTypes.remove: {
                const {list, offers, properties} = state.favourites;

                const updatedList = list.filter((favourite) => !favouriteInCollection(favourite, action.list));

                return {
                    ...state,
                    favourites: {
                        latestUpdatedFavouriteDate: findLatestUpdatedFavouriteDate(updatedList),
                        list: updatedList,
                        offers: offers.filter((offer) => !action.offers.includes(offer)),
                        properties: properties.filter((property) => !action.properties.includes(property)),
                        initialized: true
                    }
                };
            }
            case favouriteTypes.sync: {
                const {latestUpdatedFavouriteDate: stateValue} = state.favourites;

                const actionValue = findLatestUpdatedFavouriteDate(action.list);

                if ((!stateValue && actionValue) || (stateValue && actionValue && new Date(actionValue).getTime() > new Date(stateValue).getTime())) {
                    return {
                        ...state,
                        favourites: {
                            ...state.favourites,
                            latestUpdatedFavouriteDate: actionValue
                        }
                    };
                }

                return state;
            }
            default:
                return state;
        }
    };
