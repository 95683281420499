import {appendQueryString} from "../append_query_string";
import {RequestMethod} from "../request_methods";

export const getApmTransactionName = (url: string, method: RequestMethod = RequestMethod.GET): string => {
    const parsedUrl = new URL(url);

    const query = parsedUrl.searchParams.has("s") ? {s: parsedUrl.searchParams.get("s")} : {};

    // In order to group similar requests we need to normalize them by replacing dynamic pathname params with ":id"
    const normalizedUrl = parsedUrl.pathname
        .split("/")
        .map((fragment) => {
            return isFinite(parseInt(fragment)) ? ":id" : fragment;
        })
        .join("/");

    return `${method} ${appendQueryString(normalizedUrl, query)}`;
};
import {appendQueryString} from "../append_query_string";
import {RequestMethod} from "../request_methods";

export const getApmTransactionName = (url: string, method: RequestMethod = RequestMethod.GET): string => {
    const parsedUrl = new URL(url);

    const query = parsedUrl.searchParams.has("s") ? {s: parsedUrl.searchParams.get("s")} : {};

    // In order to group similar requests we need to normalize them by replacing dynamic pathname params with ":id"
    const normalizedUrl = parsedUrl.pathname
        .split("/")
        .map((fragment) => {
            return isFinite(parseInt(fragment)) ? ":id" : fragment;
        })
        .join("/");

    return `${method} ${appendQueryString(normalizedUrl, query)}`;
};
