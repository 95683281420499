import type {ElementType, PropsWithChildren} from "react";
import React from "react";

import {Container} from "./Container";

interface IProps {
    as?: ElementType;
    className?: string;
    id?: string;
}

export const WideContent = (props: PropsWithChildren<IProps>) => {
    return (
        <Container as={props.as} className={props.className} id={props.id}>
            {props.children}
        </Container>
    );
};
import type {ElementType, PropsWithChildren} from "react";
import React from "react";

import {Container} from "./Container";

interface IProps {
    as?: ElementType;
    className?: string;
    id?: string;
}

export const WideContent = (props: PropsWithChildren<IProps>) => {
    return (
        <Container as={props.as} className={props.className} id={props.id}>
            {props.children}
        </Container>
    );
};
