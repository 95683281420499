import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchRegionPath} from "../../region/fetch_region_path";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const redirectToSEOFriendlyListing = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const regionSEOMetadata = getState().vendor.list.regionSEOMetadata;

    if (regionSEOMetadata) {
        // Redirect to URL selected by SEO
        if (regionSEOMetadata.redirect_to) {
            dispatch(enable301ResponseState(regionSEOMetadata.redirect_to));

            return false;
        }

        // If listing is marked as unpublished and has no URL to redirect, use the nearest parent region
        if (!regionSEOMetadata.is_published && !regionSEOMetadata.redirect_to) {
            const ancestorRegion = await fetchRegionPath(regionSEOMetadata.region.id);
            const firstAncestorRegion = ancestorRegion.reverse()[0];

            if (firstAncestorRegion) {
                const url = rpAppLink.vendor.listFriendly({friendlySlug: firstAncestorRegion.slug});

                dispatch(enable301ResponseState(url));

                return false;
            }
        }
    }

    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetchRegionPath} from "../../region/fetch_region_path";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const redirectToSEOFriendlyListing = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    const regionSEOMetadata = getState().vendor.list.regionSEOMetadata;

    if (regionSEOMetadata) {
        // Redirect to URL selected by SEO
        if (regionSEOMetadata.redirect_to) {
            dispatch(enable301ResponseState(regionSEOMetadata.redirect_to));

            return false;
        }

        // If listing is marked as unpublished and has no URL to redirect, use the nearest parent region
        if (!regionSEOMetadata.is_published && !regionSEOMetadata.redirect_to) {
            const ancestorRegion = await fetchRegionPath(regionSEOMetadata.region.id);
            const firstAncestorRegion = ancestorRegion.reverse()[0];

            if (firstAncestorRegion) {
                const url = rpAppLink.vendor.listFriendly({friendlySlug: firstAncestorRegion.slug});

                dispatch(enable301ResponseState(url));

                return false;
            }
        }
    }

    return true;
};
