import React from "react";
import {useTheme} from "@emotion/react";

import {Text} from "@pg-design/text-module";

interface IProps {
    lines: string[];
    className?: string;
}

export const FooterContactBox = (props: IProps) => {
    const theme = useTheme();
    return (
        <div className={props.className}>
            <Text variant="headline_6" as="h4" color="#fff" mb="2.4rem">
                Kontakt
            </Text>

            <Text variant="info_txt_1" as="div" color={theme?.colors?.gray[600] || "#a7a7ab"}>
                {props.lines.map((line) => (
                    <div key={line}>{line}</div>
                ))}
            </Text>
        </div>
    );
};
import React from "react";
import {useTheme} from "@emotion/react";

import {Text} from "@pg-design/text-module";

interface IProps {
    lines: string[];
    className?: string;
}

export const FooterContactBox = (props: IProps) => {
    const theme = useTheme();
    return (
        <div className={props.className}>
            <Text variant="headline_6" as="h4" color="#fff" mb="2.4rem">
                Kontakt
            </Text>

            <Text variant="info_txt_1" as="div" color={theme?.colors?.gray[600] || "#a7a7ab"}>
                {props.lines.map((line) => (
                    <div key={line}>{line}</div>
                ))}
            </Text>
        </div>
    );
};
