import {FloorChoice} from "../../../../../real_estate/types/FloorChoice";
import {OfferListSubType} from "../../../types/OfferListSubType";

export function getOfferListSubTypeByFloorChoice(floorChoice: FloorChoice) {
    switch (floorChoice) {
        case FloorChoice.GROUND:
            return OfferListSubType.FLOOR_GROUND;
        case FloorChoice.GROUND_WITH_GARDEN:
            return OfferListSubType.FLOOR_GROUND_WITH_GARDEN;
        case FloorChoice.LAST_LEVEL:
            return OfferListSubType.LAST_FLOOR;
        default:
            throw new Error(`Cannot transform FloorChoice (${floorChoice}) into offer's sub-type`);
    }
}
import {FloorChoice} from "../../../../../real_estate/types/FloorChoice";
import {OfferListSubType} from "../../../types/OfferListSubType";

export function getOfferListSubTypeByFloorChoice(floorChoice: FloorChoice) {
    switch (floorChoice) {
        case FloorChoice.GROUND:
            return OfferListSubType.FLOOR_GROUND;
        case FloorChoice.GROUND_WITH_GARDEN:
            return OfferListSubType.FLOOR_GROUND_WITH_GARDEN;
        case FloorChoice.LAST_LEVEL:
            return OfferListSubType.LAST_FLOOR;
        default:
            throw new Error(`Cannot transform FloorChoice (${floorChoice}) into offer's sub-type`);
    }
}
