import Bugsnag from "@bugsnag/js";

const hasErrorParameter = (error: unknown): error is {error: Error} => {
    return Object.prototype.hasOwnProperty.call(error, "error") && !!(error as {error: Error})?.error;
};

const hasMessageParameter = (error: unknown): error is {message: string; filename?: string} => {
    return Object.prototype.hasOwnProperty.call(error, "message");
};

const isGroupedError = (error: unknown): error is {name: string; message: string; groupingHash: string} => {
    return Object.prototype.hasOwnProperty.call(error, "groupingHash");
};

export const notifyBugsnag = (err: unknown, errorInfo: string, errorData: unknown = {}, rtkqAction?: object) => {
    const metaData = {errorInfo, errorData, deployment: "rp-web"};

    // notify regarding err
    if (isGroupedError(err)) {
        Bugsnag.notify(err, (event) => {
            event.groupingHash = err.groupingHash;
            event.addMetadata("metadata", {metaData});

            if (rtkqAction) {
                event.request = {
                    action: rtkqAction
                };
            }
        });
    } else if (hasErrorParameter(err)) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData});

            if (rtkqAction) {
                event.request = {
                    action: rtkqAction
                };
            }
        });
    } else if (hasMessageParameter(err)) {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", metaData);

                if (rtkqAction) {
                    event.request = {
                        action: rtkqAction
                    };
                }
            }
        );
    } else {
        Bugsnag.notify(
            {
                name: "unknown error",
                message: JSON.stringify(err)
            },
            (event) => {
                event.addMetadata("metadata", {metaData});

                if (rtkqAction) {
                    event.request = {
                        action: rtkqAction
                    };
                }
            }
        );
    }
};
import Bugsnag from "@bugsnag/js";

const hasErrorParameter = (error: unknown): error is {error: Error} => {
    return Object.prototype.hasOwnProperty.call(error, "error") && !!(error as {error: Error})?.error;
};

const hasMessageParameter = (error: unknown): error is {message: string; filename?: string} => {
    return Object.prototype.hasOwnProperty.call(error, "message");
};

const isGroupedError = (error: unknown): error is {name: string; message: string; groupingHash: string} => {
    return Object.prototype.hasOwnProperty.call(error, "groupingHash");
};

export const notifyBugsnag = (err: unknown, errorInfo: string, errorData: unknown = {}, rtkqAction?: object) => {
    const metaData = {errorInfo, errorData, deployment: "rp-web"};

    // notify regarding err
    if (isGroupedError(err)) {
        Bugsnag.notify(err, (event) => {
            event.groupingHash = err.groupingHash;
            event.addMetadata("metadata", {metaData});

            if (rtkqAction) {
                event.request = {
                    action: rtkqAction
                };
            }
        });
    } else if (hasErrorParameter(err)) {
        Bugsnag.notify(err.error, (event) => {
            event.addMetadata("metadata", {metaData});

            if (rtkqAction) {
                event.request = {
                    action: rtkqAction
                };
            }
        });
    } else if (hasMessageParameter(err)) {
        Bugsnag.notify(
            {
                name: err.message || "Error is not defined",
                message: err.filename + ":" + err.message
            },
            (event) => {
                event.addMetadata("metadata", metaData);

                if (rtkqAction) {
                    event.request = {
                        action: rtkqAction
                    };
                }
            }
        );
    } else {
        Bugsnag.notify(
            {
                name: "unknown error",
                message: JSON.stringify(err)
            },
            (event) => {
                event.addMetadata("metadata", {metaData});

                if (rtkqAction) {
                    event.request = {
                        action: rtkqAction
                    };
                }
            }
        );
    }
};
