export interface FavouritesActionTypes {
    add: string;
    remove: string;
    set: string;
    sync: string;
}

export const createFavouritesActionTypes = (name: string): FavouritesActionTypes => ({
    add: `${name}_ADD`,
    remove: `${name}_REMOVE`,
    set: `${name}_SET`,
    sync: `${name}_SYNC`
});
export interface FavouritesActionTypes {
    add: string;
    remove: string;
    set: string;
    sync: string;
}

export const createFavouritesActionTypes = (name: string): FavouritesActionTypes => ({
    add: `${name}_ADD`,
    remove: `${name}_REMOVE`,
    set: `${name}_SET`,
    sync: `${name}_SYNC`
});
