import {Country} from "../../../../region/types/Country";
import {OfferListSubType} from "../../types/OfferListSubType";
import {getFloorChoiceByOfferListSubType} from "./transform/get_floor_choice_by_offer_list_sub_type";
import {getHouseFilterByOfferListSubTypeForQuery} from "./transform/get_house_filter_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "./validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "./validator/is_offer_list_sub_type_valid_house_filter";

export const getFetchOfferListQuery = (
    query: Record<string, string | string[] | null>,
    offerListSubType?: OfferListSubType,
    offerListSubFilter?: string,
    country?: string
) => {
    if (offerListSubFilter && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        query.floor_choices = [floorChoice.toString()];
    } else if (offerListSubFilter && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        query = {
            ...query,
            ...(country ? {country: getCountryId(country).toString()} : {}),
            ...getHouseFilterByOfferListSubTypeForQuery(offerListSubFilter)
        };
    }

    return query;
};

const isCountryEnumValue = (value: number): value is Country => {
    return Object.values(Country).includes(value);
};

export const getCountryId = (country?: string): Country => {
    if (country && !isNaN(parseInt(country))) {
        const countryId = parseInt(country, 10);
        if (isCountryEnumValue(countryId)) {
            return countryId;
        }
    }

    switch (country) {
        case "hiszpania":
            return Country.SPAIN;
        default:
            return Country.POLAND;
    }
};

export const getCountryName = (country?: number | null) => {
    switch (country) {
        case Country.SPAIN:
            return "hiszpania";
        default:
            return "polska";
    }
};
import {Country} from "../../../../region/types/Country";
import {OfferListSubType} from "../../types/OfferListSubType";
import {getFloorChoiceByOfferListSubType} from "./transform/get_floor_choice_by_offer_list_sub_type";
import {getHouseFilterByOfferListSubTypeForQuery} from "./transform/get_house_filter_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "./validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "./validator/is_offer_list_sub_type_valid_house_filter";

export const getFetchOfferListQuery = (
    query: Record<string, string | string[] | null>,
    offerListSubType?: OfferListSubType,
    offerListSubFilter?: string,
    country?: string
) => {
    if (offerListSubFilter && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        query.floor_choices = [floorChoice.toString()];
    } else if (offerListSubFilter && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        query = {
            ...query,
            ...(country ? {country: getCountryId(country).toString()} : {}),
            ...getHouseFilterByOfferListSubTypeForQuery(offerListSubFilter)
        };
    }

    return query;
};

const isCountryEnumValue = (value: number): value is Country => {
    return Object.values(Country).includes(value);
};

export const getCountryId = (country?: string): Country => {
    if (country && !isNaN(parseInt(country))) {
        const countryId = parseInt(country, 10);
        if (isCountryEnumValue(countryId)) {
            return countryId;
        }
    }

    switch (country) {
        case "hiszpania":
            return Country.SPAIN;
        default:
            return Country.POLAND;
    }
};

export const getCountryName = (country?: number | null) => {
    switch (country) {
        case Country.SPAIN:
            return "hiszpania";
        default:
            return "polska";
    }
};
