import * as pathToRegexp from "path-to-regexp";

export function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return (apiPathParams: TParams): string => compiler(apiPathParams as unknown as Record<string, string>);
}
import * as pathToRegexp from "path-to-regexp";

export function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return (apiPathParams: TParams): string => compiler(apiPathParams as unknown as Record<string, string>);
}
