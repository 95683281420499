import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

export const updatePricesFlatMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const region = getState().report.price.region;
    const title = concatWithBrandName(`Ceny mieszkań ${region ? region?.short_name : "w Polsce"} -`);
    const description = `Jak zmieniają się ceny mieszkań ${region ? region?.name_declension_where : "w Polsce"}? Ile wynosi średnia cena metra kwadratowego? Sprawdź ceny mieszkań ${region ? region?.short_name : "Polska"} wykres na${concatWithBrandName(
        ""
    )}!`;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";

export const updatePricesFlatMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const region = getState().report.price.region;
    const title = concatWithBrandName(`Ceny mieszkań ${region ? region?.short_name : "w Polsce"} -`);
    const description = `Jak zmieniają się ceny mieszkań ${region ? region?.name_declension_where : "w Polsce"}? Ile wynosi średnia cena metra kwadratowego? Sprawdź ceny mieszkań ${region ? region?.short_name : "Polska"} wykres na${concatWithBrandName(
        ""
    )}!`;

    const metaData = updateMetaData({
        title,
        description,
        pathname: ctx.route.pathname
    });

    dispatch(metaData);
    return true;
};
