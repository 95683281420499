import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IArticleListResponse} from "./fetch_article_list";

const ARTICLE_LIST_PREFIX = "selected_article_list/fetch";
export const fetchArticleListByIdTypes = createRequestActionTypes(ARTICLE_LIST_PREFIX);

const creditArticleIds = [10440, 10569, 9313, 12362, 792];
const ratingArticlesIds = [663, 10320, 11067, 12518];

export const fetchArticleListByIdsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const articlesIds = ctx.match.path === rpAppPath.credits.base ? creditArticleIds : ratingArticlesIds;
    const query: Record<string, string[]> = {article: articlesIds.map(String)};

    dispatch({type: fetchArticleListByIdTypes.start, latestQuery: query});
    const articleListApiLink = apiV2ListLink.article.list(Scenario.ARTICLE_LIST);

    const url = appendQueryString(articleListApiLink, query);
    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles
            };
            dispatch({type: fetchArticleListByIdTypes.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchArticleListByIdTypes.error, error: error.appError});
                return true;
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";
import {rpAppPath} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IArticleListResponse} from "./fetch_article_list";

const ARTICLE_LIST_PREFIX = "selected_article_list/fetch";
export const fetchArticleListByIdTypes = createRequestActionTypes(ARTICLE_LIST_PREFIX);

const creditArticleIds = [10440, 10569, 9313, 12362, 792];
const ratingArticlesIds = [663, 10320, 11067, 12518];

export const fetchArticleListByIdsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const articlesIds = ctx.match.path === rpAppPath.credits.base ? creditArticleIds : ratingArticlesIds;
    const query: Record<string, string[]> = {article: articlesIds.map(String)};

    dispatch({type: fetchArticleListByIdTypes.start, latestQuery: query});
    const articleListApiLink = apiV2ListLink.article.list(Scenario.ARTICLE_LIST);

    const url = appendQueryString(articleListApiLink, query);
    return getRequest(ctx.meta, url)
        .then(async (json: IArticleListResponse) => {
            const articles = json.results.map((article) => {
                return {
                    ...article,
                    lead: article.lead && article.lead.replace(/\r\n/g, "\n")
                };
            });
            const result = {
                articles
            };
            dispatch({type: fetchArticleListByIdTypes.success, result});
            return json;
        })
        .catch(
            catch400((error) => {
                dispatch({type: fetchArticleListByIdTypes.error, error: error.appError});
                return true;
            })
        );
};
