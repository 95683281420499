const getDate = (): string => Date();
const generateMsg = (msg: string, ...args: unknown[]) => (args && args.length ? `${getDate()} - ${msg}: ` : `${getDate()} - ${msg}`);

export const consoleLog = (msg: string, ...args: unknown[]) => console.log(generateMsg(msg, ...args), ...args);
export const consoleWarn = (msg: string, ...args: unknown[]) => console.warn(generateMsg(msg, ...args), ...args);
export const consoleError = (msg: string, ...args: unknown[]) => console.error(generateMsg(msg, ...args), ...args);
const getDate = (): string => Date();
const generateMsg = (msg: string, ...args: unknown[]) => (args && args.length ? `${getDate()} - ${msg}: ` : `${getDate()} - ${msg}`);

export const consoleLog = (msg: string, ...args: unknown[]) => console.log(generateMsg(msg, ...args), ...args);
export const consoleWarn = (msg: string, ...args: unknown[]) => console.warn(generateMsg(msg, ...args), ...args);
export const consoleError = (msg: string, ...args: unknown[]) => console.error(generateMsg(msg, ...args), ...args);
