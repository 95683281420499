import {RefObject, useEffect} from "react";

export const useClickOutside = (ref: RefObject<HTMLElement>, onClickOutside: () => void, deps: unknown[] = []) => {
    useEffect(() => {
        const clickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
                onClickOutside();
            }
        };

        document.addEventListener("mousedown", clickOutside);

        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, [ref, ...deps]);
};
import {RefObject, useEffect} from "react";

export const useClickOutside = (ref: RefObject<HTMLElement>, onClickOutside: () => void, deps: unknown[] = []) => {
    useEffect(() => {
        const clickOutside = (event: Event) => {
            if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
                onClickOutside();
            }
        };

        document.addEventListener("mousedown", clickOutside);

        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
    }, [ref, ...deps]);
};
