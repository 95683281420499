export enum PropertyHouseAdditionalAreas {
    BALCONY = 1,
    TERRACE = 2,
    GARDEN = 3,
    GARAGE = 4,
    ATTIC = 5
}
export enum PropertyHouseAdditionalAreas {
    BALCONY = 1,
    TERRACE = 2,
    GARDEN = 3,
    GARAGE = 4,
    ATTIC = 5
}
