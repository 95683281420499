import {AnyAction} from "redux";

import {INVESTMENT_OFFER_PARSED_CATEGORIES} from "../list/actions/parse_investment_offer_url";

export const investmentOfferCategoriesReducer = (state: boolean | null = null, action: AnyAction) => {
    switch (action.type) {
        case INVESTMENT_OFFER_PARSED_CATEGORIES:
            return action.params;
        default:
            return state;
    }
};
import {AnyAction} from "redux";

import {INVESTMENT_OFFER_PARSED_CATEGORIES} from "../list/actions/parse_investment_offer_url";

export const investmentOfferCategoriesReducer = (state: boolean | null = null, action: AnyAction) => {
    switch (action.type) {
        case INVESTMENT_OFFER_PARSED_CATEGORIES:
            return action.params;
        default:
            return state;
    }
};
