/*
 * Method is used to trim aggregated region part (ie poludniowo-wschodnie) from region slug.
 * According to SEO such aggregated region parts has no search potential.
 */
export const trimAggregationFromRegion = (region: string) => {
    const captureGroup = /(polnocne|poludniowe|poludniowe|poludniowo|polnocno|wschodnie|zachodnie)(-wschodnie|-zachodnie)?-okolice/g;

    return region.replace(captureGroup, "okolice");
};
/*
 * Method is used to trim aggregated region part (ie poludniowo-wschodnie) from region slug.
 * According to SEO such aggregated region parts has no search potential.
 */
export const trimAggregationFromRegion = (region: string) => {
    const captureGroup = /(polnocne|poludniowe|poludniowe|poludniowo|polnocno|wschodnie|zachodnie)(-wschodnie|-zachodnie)?-okolice/g;

    return region.replace(captureGroup, "okolice");
};
