import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getArticleCategoryBySlug} from "./ArticleCategory";

const FETCH_CATEGORY_DESCRIPTION = "article_list/FETCH_CATEGORY_DESCRIPTION";
export const fetchCategoryDescriptionTypes = createRequestActionTypes(FETCH_CATEGORY_DESCRIPTION);

export interface ICategoryDescription {
    seo_description: string;
    phrases: string[];
    id: number;
    name: string;
    slug: string;
}

export const fetchCategoryDescriptionAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (!ctx.match.params.categorySlug) {
        return true;
    }
    const categoryId = getArticleCategoryBySlug(ctx.match.params.categorySlug);
    if (categoryId === null) {
        return true;
    }
    const categoryDescriptionApiLink = apiV2Link.category.detail(Scenario.CATEGORY_DETAIL, {categoryId});
    return getRequest(ctx.meta, categoryDescriptionApiLink).then(async (category: ICategoryDescription) => {
        dispatch({type: fetchCategoryDescriptionTypes.success, result: category});
        return true;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {getArticleCategoryBySlug} from "./ArticleCategory";

const FETCH_CATEGORY_DESCRIPTION = "article_list/FETCH_CATEGORY_DESCRIPTION";
export const fetchCategoryDescriptionTypes = createRequestActionTypes(FETCH_CATEGORY_DESCRIPTION);

export interface ICategoryDescription {
    seo_description: string;
    phrases: string[];
    id: number;
    name: string;
    slug: string;
}

export const fetchCategoryDescriptionAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    if (!ctx.match.params.categorySlug) {
        return true;
    }
    const categoryId = getArticleCategoryBySlug(ctx.match.params.categorySlug);
    if (categoryId === null) {
        return true;
    }
    const categoryDescriptionApiLink = apiV2Link.category.detail(Scenario.CATEGORY_DETAIL, {categoryId});
    return getRequest(ctx.meta, categoryDescriptionApiLink).then(async (category: ICategoryDescription) => {
        dispatch({type: fetchCategoryDescriptionTypes.success, result: category});
        return true;
    });
};
