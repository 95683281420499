import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function isRtkqError(error: unknown): error is FetchBaseQueryError {
    if (typeof error == "object" && error && "status" in error) {
        return true;
    }

    return false;
}
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

export function isRtkqError(error: unknown): error is FetchBaseQueryError {
    if (typeof error == "object" && error && "status" in error) {
        return true;
    }

    return false;
}
