import {friendlyQueryToDescription} from "../../../../constants/house_filter";

export const getHouseFilterMetaText = (offerListSubFilter?: string) => {
    const metaText = offerListSubFilter ? friendlyQueryToDescription[offerListSubFilter] : null;

    if (!metaText) {
        throw new Error(`Cannot return meta text for house filter ${offerListSubFilter}`);
    }

    return metaText;
};
import {friendlyQueryToDescription} from "../../../../constants/house_filter";

export const getHouseFilterMetaText = (offerListSubFilter?: string) => {
    const metaText = offerListSubFilter ? friendlyQueryToDescription[offerListSubFilter] : null;

    if (!metaText) {
        throw new Error(`Cannot return meta text for house filter ${offerListSubFilter}`);
    }

    return metaText;
};
