import {removeRedundantWhiteSpaces} from "@pg-mono/string-utils";

import {BRAND_NAME} from "../../app/constants/company";

export interface ITitlePromotionList {
    type?: string;
    region?: string;
    page?: number;
}

export const titlePromotionList = (props: ITitlePromotionList) => {
    const page = typeof props.page === "number" && props.page > 1 ? ` - Strona ${props.page}` : "";

    if (props?.type || props?.region) {
        return removeRedundantWhiteSpaces(`Aktualne ${props.type || "oferty specjalne"} ${props.region || ""}${page} ✅ - ${BRAND_NAME}`);
    }
    return `Aktualne promocje, przeceny, dni otwarte${page} ✅ - ${BRAND_NAME}`;
};
import {removeRedundantWhiteSpaces} from "@pg-mono/string-utils";

import {BRAND_NAME} from "../../app/constants/company";

export interface ITitlePromotionList {
    type?: string;
    region?: string;
    page?: number;
}

export const titlePromotionList = (props: ITitlePromotionList) => {
    const page = typeof props.page === "number" && props.page > 1 ? ` - Strona ${props.page}` : "";

    if (props?.type || props?.region) {
        return removeRedundantWhiteSpaces(`Aktualne ${props.type || "oferty specjalne"} ${props.region || ""}${page} ✅ - ${BRAND_NAME}`);
    }
    return `Aktualne promocje, przeceny, dni otwarte${page} ✅ - ${BRAND_NAME}`;
};
