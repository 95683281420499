import {ReactNode} from "react";
import cn from "classnames";

import {Highlight, Text, TextVariant} from "@pg-design/text-module";

import {IButtonSizePrimary} from "../types/IButtonSizePrimary";
import {IButtonVariantUnionType} from "../types/IButtonVariantUnionType";

import {buttonBannerPrimaryText, buttonHighlightedText, buttonMapTooltipText} from "./ButtonText.module.css";

interface IProps {
    children: ReactNode;
    variant: IButtonVariantUnionType;
    size?: IButtonSizePrimary;
}

export function ButtonText(props: IProps) {
    const {children, variant, size} = props;

    const textVariant = getTextVariant(variant, size);

    const className = cn(
        variant === "banner_primary" && buttonBannerPrimaryText,
        variant === "map_tooltip" && buttonMapTooltipText,
        variant === "highlight_primary" && buttonHighlightedText
    );

    if (variant === "highlight_primary") {
        return (
            <Highlight>
                <Text variant={textVariant} className={className}>
                    {children}
                </Text>
            </Highlight>
        );
    }

    return (
        <Text variant={textVariant} className={className}>
            {children}
        </Text>
    );
}

function getTextVariant(variant: IButtonVariantUnionType, size?: IButtonSizePrimary) {
    const sizeToVariantMap = {
        big: TextVariant.BUTTON_BIG,
        medium: TextVariant.BUTTON_MEDIUM,
        small: TextVariant.BUTTON_SMALL,
        "x-small": TextVariant.BUTTON_MICRO
    };

    if (["highlight_primary", "filled_primary", "none_secondary"].includes(variant) && size) {
        return sizeToVariantMap[size];
    }

    if (variant === "highlight_primary" && !size) {
        return TextVariant.BUTTON_MEDIUM;
    }

    if (variant === "banner_primary") {
        return TextVariant.BODY_COPY_0;
    }

    return TextVariant.BUTTON_MEDIUM;
}
import {ReactNode} from "react";
import cn from "classnames";

import {Highlight, Text, TextVariant} from "@pg-design/text-module";

import {IButtonSizePrimary} from "../types/IButtonSizePrimary";
import {IButtonVariantUnionType} from "../types/IButtonVariantUnionType";

import {buttonBannerPrimaryText, buttonHighlightedText, buttonMapTooltipText} from "./ButtonText.module.css";

interface IProps {
    children: ReactNode;
    variant: IButtonVariantUnionType;
    size?: IButtonSizePrimary;
}

export function ButtonText(props: IProps) {
    const {children, variant, size} = props;

    const textVariant = getTextVariant(variant, size);

    const className = cn(
        variant === "banner_primary" && buttonBannerPrimaryText,
        variant === "map_tooltip" && buttonMapTooltipText,
        variant === "highlight_primary" && buttonHighlightedText
    );

    if (variant === "highlight_primary") {
        return (
            <Highlight>
                <Text variant={textVariant} className={className}>
                    {children}
                </Text>
            </Highlight>
        );
    }

    return (
        <Text variant={textVariant} className={className}>
            {children}
        </Text>
    );
}

function getTextVariant(variant: IButtonVariantUnionType, size?: IButtonSizePrimary) {
    const sizeToVariantMap = {
        big: TextVariant.BUTTON_BIG,
        medium: TextVariant.BUTTON_MEDIUM,
        small: TextVariant.BUTTON_SMALL,
        "x-small": TextVariant.BUTTON_MICRO
    };

    if (["highlight_primary", "filled_primary", "none_secondary"].includes(variant) && size) {
        return sizeToVariantMap[size];
    }

    if (variant === "highlight_primary" && !size) {
        return TextVariant.BUTTON_MEDIUM;
    }

    if (variant === "banner_primary") {
        return TextVariant.BODY_COPY_0;
    }

    return TextVariant.BUTTON_MEDIUM;
}
