interface IOptions {
    length?: number;
    omission?: string;
    separator?: string | RegExp;
}

export const truncate = (text: string, options: IOptions = {}) => {
    const {length = 10, omission = "...", separator} = options;

    if (text.length <= length) {
        return text;
    }

    let truncated = text.slice(0, length - omission.length);
    if (separator) {
        const separatorRegex = typeof separator === "string" ? new RegExp(separator) : separator;
        const regexResult = separatorRegex.exec(truncated);

        if (regexResult) {
            truncated = truncated.slice(0, regexResult.index);
        }
    }

    return truncated + omission;
};
interface IOptions {
    length?: number;
    omission?: string;
    separator?: string | RegExp;
}

export const truncate = (text: string, options: IOptions = {}) => {
    const {length = 10, omission = "...", separator} = options;

    if (text.length <= length) {
        return text;
    }

    let truncated = text.slice(0, length - omission.length);
    if (separator) {
        const separatorRegex = typeof separator === "string" ? new RegExp(separator) : separator;
        const regexResult = separatorRegex.exec(truncated);

        if (regexResult) {
            truncated = truncated.slice(0, regexResult.index);
        }
    }

    return truncated + omission;
};
