import {SearchSortString} from "../list/constants/SearchSort";

export const getOfferListSortName = (searchSort: SearchSortString) => {
    switch (searchSort) {
        case SearchSortString.PRICE:
            return "price";
        case SearchSortString.PRICE_M2:
            return "price_m2";
        case SearchSortString.CONSTRUCTION_DATE:
            return "construction_date_range";
        case SearchSortString.LATEST:
            return "create_date";
        default:
            return "";
    }
};
import {SearchSortString} from "../list/constants/SearchSort";

export const getOfferListSortName = (searchSort: SearchSortString) => {
    switch (searchSort) {
        case SearchSortString.PRICE:
            return "price";
        case SearchSortString.PRICE_M2:
            return "price_m2";
        case SearchSortString.CONSTRUCTION_DATE:
            return "construction_date_range";
        case SearchSortString.LATEST:
            return "create_date";
        default:
            return "";
    }
};
