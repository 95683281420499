export const fontFace = (src: string, family: string, variant: string, weight: 300 | 400 | 500 | 600 | 700, style = "normal") => `
    @font-face {
        font-family: '${family}';
        src: url('${src}${variant}.woff2') format('woff2'),
             url('${src}${variant}.woff') format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: swap;
    }
`;
export const fontFace = (src: string, family: string, variant: string, weight: 300 | 400 | 500 | 600 | 700, style = "normal") => `
    @font-face {
        font-family: '${family}';
        src: url('${src}${variant}.woff2') format('woff2'),
             url('${src}${variant}.woff') format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: swap;
    }
`;
