import {Dispatch} from "redux";

import {IRegionListRegion} from "./fetch_search_regions_action";

export interface IMultiRegionListStore {
    showMultiRegionList: boolean;
    regions: IRegionListRegion[];
}

export interface ISetMultiRegionListStateAction {
    type: string;
    multiRegionListState: IMultiRegionListStore;
}

export const SET_MULTI_REGION_LIST_STATE = "desktop/search-engine/SET_MULTI_REGION_LIST_STATE";
export const setMultiRegionListState = (multiRegionListState: IMultiRegionListStore) => (dispatch: Dispatch<ISetMultiRegionListStateAction>) => {
    dispatch({type: SET_MULTI_REGION_LIST_STATE, multiRegionListState});
};

export const RESET_MULTI_REGION_LIST_STATE = "desktop/search-engine/RESET_MULTI_REGION_LIST_STATE";
export const resetMultiRegionListState = () => (dispatch: Dispatch) => dispatch({type: RESET_MULTI_REGION_LIST_STATE});
import {Dispatch} from "redux";

import {IRegionListRegion} from "./fetch_search_regions_action";

export interface IMultiRegionListStore {
    showMultiRegionList: boolean;
    regions: IRegionListRegion[];
}

export interface ISetMultiRegionListStateAction {
    type: string;
    multiRegionListState: IMultiRegionListStore;
}

export const SET_MULTI_REGION_LIST_STATE = "desktop/search-engine/SET_MULTI_REGION_LIST_STATE";
export const setMultiRegionListState = (multiRegionListState: IMultiRegionListStore) => (dispatch: Dispatch<ISetMultiRegionListStateAction>) => {
    dispatch({type: SET_MULTI_REGION_LIST_STATE, multiRegionListState});
};

export const RESET_MULTI_REGION_LIST_STATE = "desktop/search-engine/RESET_MULTI_REGION_LIST_STATE";
export const resetMultiRegionListState = () => (dispatch: Dispatch) => dispatch({type: RESET_MULTI_REGION_LIST_STATE});
