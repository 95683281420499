import {consoleWarn} from "@pg-mono/logger";

import {UpdateUserAttributesPayload} from "./events/update_user_attributes";

declare global {
    interface Window {
        civchat: {
            apiKey: string;
            state: string;
        };
        rpuserengage: (event: string, data: Record<string, unknown>) => void;
        userComEvents: {
            userengage: {event: string; data: Record<string, unknown>}[];
            pageHit: UpdateUserAttributesPayload[];
        };
        userengage: (event: string, data: Record<string, unknown>) => void;
        rpUE: {pageHit: (data: UpdateUserAttributesPayload) => void};
        UE: {pageHit: (data: Record<string, unknown>) => void};
    }
}

let isUSCInitialized = false;

interface IParams {
    userComId?: string;
    userComAppSubdomain?: string;
    isBrowser?: boolean;
}
export const initUserCom = ({userComId, userComAppSubdomain, isBrowser = false}: IParams): void => {
    if (!userComId || !userComAppSubdomain) {
        consoleWarn(`user.com was not initialized due to lack of configuration data. Provided id ${userComId} and subdomain ${userComAppSubdomain}`);

        return;
    }

    if (process.env.EXEC_ENV !== "browser" && !isBrowser) {
        return;
    }

    // initialize only once
    if (isUSCInitialized) {
        return;
    }

    createUserComQueues();

    (function () {
        window.civchat = {apiKey: userComId, state: "hidden"};
        const firstScriptTag = document.getElementsByTagName("script")[0];

        const usc = document.createElement("script");
        usc.type = "text/javascript";
        usc.async = true;
        usc.dataset.cfasync = "false";
        usc.src = ("https:" == document.location.protocol ? "https://" : "http://") + `${userComAppSubdomain}` + ".user.com/widget.js";

        usc.onload = uscOnLoad;

        firstScriptTag.parentNode?.insertBefore(usc, firstScriptTag);

        isUSCInitialized = true;
    })();
};

/**
 * stores all prematurely fired user.com events until user.com script is loaded
 */
const createUserComQueues = () => {
    window.rpuserengage = (event, data) => {
        if (!!window.userengage) {
            window.userengage(event, data);
        } else {
            window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

            window.userComEvents.userengage.push({event, data});
        }
    };

    window.rpUE = {
        pageHit: (data) => {
            if (!!window.UE) {
                window.UE.pageHit(data);
            } else {
                window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

                window.userComEvents.pageHit.push(data);
            }
        }
    };
};

/**
 * triggers all stored events after user.com script is loaded
 */
const uscOnLoad = () => {
    /*
     * Because we had a problem with sending - from GTM -events to the user.com API (the events were sent before the user.com script was loaded) -
     * we decided to share global variable userComEvents. The userComEvents array is used to store events from GTM and when the user.com script will be
     * loaded we will send all collected events to the user.com API.
     */
    window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

    if (window.userComEvents.userengage?.length && !!window.userengage) {
        window.userComEvents.userengage.forEach(({event, data}) => window.userengage(event, data));

        window.userComEvents.userengage = [];
    }

    if (window.userComEvents.pageHit?.length && !!window.UE) {
        window.userComEvents.pageHit.forEach((data) => window.UE.pageHit(data));

        window.userComEvents.pageHit = [];
    }
};
import {consoleWarn} from "@pg-mono/logger";

import {UpdateUserAttributesPayload} from "./events/update_user_attributes";

declare global {
    interface Window {
        civchat: {
            apiKey: string;
            state: string;
        };
        rpuserengage: (event: string, data: Record<string, unknown>) => void;
        userComEvents: {
            userengage: {event: string; data: Record<string, unknown>}[];
            pageHit: UpdateUserAttributesPayload[];
        };
        userengage: (event: string, data: Record<string, unknown>) => void;
        rpUE: {pageHit: (data: UpdateUserAttributesPayload) => void};
        UE: {pageHit: (data: Record<string, unknown>) => void};
    }
}

let isUSCInitialized = false;

interface IParams {
    userComId?: string;
    userComAppSubdomain?: string;
    isBrowser?: boolean;
}
export const initUserCom = ({userComId, userComAppSubdomain, isBrowser = false}: IParams): void => {
    if (!userComId || !userComAppSubdomain) {
        consoleWarn(`user.com was not initialized due to lack of configuration data. Provided id ${userComId} and subdomain ${userComAppSubdomain}`);

        return;
    }

    if (process.env.EXEC_ENV !== "browser" && !isBrowser) {
        return;
    }

    // initialize only once
    if (isUSCInitialized) {
        return;
    }

    createUserComQueues();

    (function () {
        window.civchat = {apiKey: userComId, state: "hidden"};
        const firstScriptTag = document.getElementsByTagName("script")[0];

        const usc = document.createElement("script");
        usc.type = "text/javascript";
        usc.async = true;
        usc.dataset.cfasync = "false";
        usc.src = ("https:" == document.location.protocol ? "https://" : "http://") + `${userComAppSubdomain}` + ".user.com/widget.js";

        usc.onload = uscOnLoad;

        firstScriptTag.parentNode?.insertBefore(usc, firstScriptTag);

        isUSCInitialized = true;
    })();
};

/**
 * stores all prematurely fired user.com events until user.com script is loaded
 */
const createUserComQueues = () => {
    window.rpuserengage = (event, data) => {
        if (!!window.userengage) {
            window.userengage(event, data);
        } else {
            window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

            window.userComEvents.userengage.push({event, data});
        }
    };

    window.rpUE = {
        pageHit: (data) => {
            if (!!window.UE) {
                window.UE.pageHit(data);
            } else {
                window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

                window.userComEvents.pageHit.push(data);
            }
        }
    };
};

/**
 * triggers all stored events after user.com script is loaded
 */
const uscOnLoad = () => {
    /*
     * Because we had a problem with sending - from GTM -events to the user.com API (the events were sent before the user.com script was loaded) -
     * we decided to share global variable userComEvents. The userComEvents array is used to store events from GTM and when the user.com script will be
     * loaded we will send all collected events to the user.com API.
     */
    window.userComEvents = window.userComEvents || {userengage: [], pageHit: []};

    if (window.userComEvents.userengage?.length && !!window.userengage) {
        window.userComEvents.userengage.forEach(({event, data}) => window.userengage(event, data));

        window.userComEvents.userengage = [];
    }

    if (window.userComEvents.pageHit?.length && !!window.UE) {
        window.userComEvents.pageHit.forEach((data) => window.UE.pageHit(data));

        window.userComEvents.pageHit = [];
    }
};
