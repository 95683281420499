import {omit} from "@pg-mono/nodash";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {getOfferListSortName} from "../../helpers/get_offer_list_sort_name";
import {OfferType} from "../../types/OfferType";
import {DEFAULT_DISTANCE, OFFER_LIST_CONSTRAINTS, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {SearchSortString} from "../constants/SearchSort";
import {OfferListSubType} from "../types/OfferListSubType";
import {getBrowserDefaultDistance} from "./get_browser_default_distance";
import {getValidOfferListQuery, ValidOfferListQuery} from "./validate_offer_list_query";

export const getFetchOfferListQueryData = (
    query: Record<string, string | string[] | null | boolean>,
    offerListSubType?: OfferListSubType,
    isPresale?: boolean
) => {
    const validatedQuery = getValidOfferListQuery(query);

    const intPage = parseInt(validatedQuery.page as string, 10);
    const paramPage = isNaN(intPage) ? 1 : intPage;
    // "s" query param clashes with scenario parameter when searching Google Places
    const safeQuery = omit(validatedQuery, ["s"]);

    const fullQuery: ValidOfferListQuery = {
        distance: isBrowser ? getBrowserDefaultDistance(offerListSubType) : DEFAULT_DISTANCE,
        ...safeQuery,
        ...(safeQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {}),
        ...OFFER_LIST_CONSTRAINTS,
        sort: getOfferListSortName(safeQuery.sort as SearchSortString),
        page_size: OFFER_LIST_PAGE_SIZE,
        page: paramPage,
        ...(safeQuery.price_lower_than_average ? {price_lower_than_average: 1} : {}), // enable "tanie" filter, when upper price query is lower than average price per m2 for selected region
        ...(isPresale ? {include_pre_sale: "true"} : {})
    };

    return {fullQuery, paramPage, safeQuery};
};
import {omit} from "@pg-mono/nodash";

import {isBrowser} from "../../../app/read_rp_environment_variables";
import {getOfferListSortName} from "../../helpers/get_offer_list_sort_name";
import {OfferType} from "../../types/OfferType";
import {DEFAULT_DISTANCE, OFFER_LIST_CONSTRAINTS, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {SearchSortString} from "../constants/SearchSort";
import {OfferListSubType} from "../types/OfferListSubType";
import {getBrowserDefaultDistance} from "./get_browser_default_distance";
import {getValidOfferListQuery, ValidOfferListQuery} from "./validate_offer_list_query";

export const getFetchOfferListQueryData = (
    query: Record<string, string | string[] | null | boolean>,
    offerListSubType?: OfferListSubType,
    isPresale?: boolean
) => {
    const validatedQuery = getValidOfferListQuery(query);

    const intPage = parseInt(validatedQuery.page as string, 10);
    const paramPage = isNaN(intPage) ? 1 : intPage;
    // "s" query param clashes with scenario parameter when searching Google Places
    const safeQuery = omit(validatedQuery, ["s"]);

    const fullQuery: ValidOfferListQuery = {
        distance: isBrowser ? getBrowserDefaultDistance(offerListSubType) : DEFAULT_DISTANCE,
        ...safeQuery,
        ...(safeQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {}),
        ...OFFER_LIST_CONSTRAINTS,
        sort: getOfferListSortName(safeQuery.sort as SearchSortString),
        page_size: OFFER_LIST_PAGE_SIZE,
        page: paramPage,
        ...(safeQuery.price_lower_than_average ? {price_lower_than_average: 1} : {}), // enable "tanie" filter, when upper price query is lower than average price per m2 for selected region
        ...(isPresale ? {include_pre_sale: "true"} : {})
    };

    return {fullQuery, paramPage, safeQuery};
};
