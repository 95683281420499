export const matchApiUrlByApiPrefix = (apiUrls: Record<string, string> = {}, path: string): string | null => {
    // We need to find the longest API prefix match with path
    const sortedApiByLength = Object.keys(apiUrls).sort((a, b) => b.length - a.length);

    const matchedApi = sortedApiByLength.find((api) => path.startsWith(api));

    return matchedApi ? apiUrls[matchedApi] : null;
};
export const matchApiUrlByApiPrefix = (apiUrls: Record<string, string> = {}, path: string): string | null => {
    // We need to find the longest API prefix match with path
    const sortedApiByLength = Object.keys(apiUrls).sort((a, b) => b.length - a.length);

    const matchedApi = sortedApiByLength.find((api) => path.startsWith(api));

    return matchedApi ? apiUrls[matchedApi] : null;
};
