import React, {FC} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {BadgeVariants, BadgeVariantsUnionType} from "../utils/badge_variant";

import {badgeBaseStyle, labelStyle} from "./Badge.module.css";

interface IProps {
    children: React.ReactNode;
    as?: React.ElementType;
    variant?: BadgeVariantsUnionType;
    icon?: (props: {fill: string; className: string; size?: string}) => React.ReactElement;
    className?: string;
    iconSize?: string;
}

export const Badge: FC<IProps> = (props) => {
    const {as, variant = BadgeVariants.LABEL_DEFAULT, icon: Icon, className, iconSize = "1"} = props;

    const Component = as || "span";

    const variantStyle = getBadgeVariantStyle(variant);
    const badgeBaseCN = classNames(badgeBaseStyle, className);

    return (
        <Component className={badgeBaseCN} style={variantStyle}>
            {Icon && <Icon fill="inherit" className={labelStyle} size={iconSize} />}
            <Text variant="info_txt_2" noWrap as={as}>
                {props.children}
            </Text>
        </Component>
    );
};
const getBadgeVariantStyle = (variant: BadgeVariantsUnionType) => {
    switch (variant) {
        case BadgeVariants.LABEL_DEFAULT: {
            return {
                backgroundColor: "var(--colors-background-100)"
            };
        }
        case BadgeVariants.LABEL_BG2: {
            return {
                backgroundColor: "var(--colors-background-200)"
            };
        }

        case BadgeVariants.LABEL_SUCCESS: {
            return {
                backgroundColor: "var(--colors-success)"
            };
        }

        case BadgeVariants.LABEL_INFO: {
            return {
                backgroundColor: "var(--colors-info)"
            };
        }

        case BadgeVariants.LABEL_WARNING: {
            return {
                backgroundColor: "var(--colors-warning)"
            };
        }

        case BadgeVariants.LABEL_HIGHLIGHT: {
            return {
                backgroundColor: "var(--colors-highlight)"
            };
        }

        case BadgeVariants.LABEL_PRIMARY: {
            return {
                backgroundColor: "var(--colors-primary)",
                color: "var(--button-primaryLabelColor)"
            };
        }

        case BadgeVariants.LABEL_SECONDARY: {
            return {
                backgroundColor: "var(--colors-secondary)",
                color: "white",
                fill: "white"
            };
        }

        case BadgeVariants.LABEL_DANGER: {
            return {
                backgroundColor: "var(--colors-danger)",
                color: "white",
                fill: "white"
            };
        }
    }
};
import React, {FC} from "react";
import classNames from "classnames";

import {Text} from "@pg-design/text-module";

import {BadgeVariants, BadgeVariantsUnionType} from "../utils/badge_variant";

import {badgeBaseStyle, labelStyle} from "./Badge.module.css";

interface IProps {
    children: React.ReactNode;
    as?: React.ElementType;
    variant?: BadgeVariantsUnionType;
    icon?: (props: {fill: string; className: string; size?: string}) => React.ReactElement;
    className?: string;
    iconSize?: string;
}

export const Badge: FC<IProps> = (props) => {
    const {as, variant = BadgeVariants.LABEL_DEFAULT, icon: Icon, className, iconSize = "1"} = props;

    const Component = as || "span";

    const variantStyle = getBadgeVariantStyle(variant);
    const badgeBaseCN = classNames(badgeBaseStyle, className);

    return (
        <Component className={badgeBaseCN} style={variantStyle}>
            {Icon && <Icon fill="inherit" className={labelStyle} size={iconSize} />}
            <Text variant="info_txt_2" noWrap as={as}>
                {props.children}
            </Text>
        </Component>
    );
};
const getBadgeVariantStyle = (variant: BadgeVariantsUnionType) => {
    switch (variant) {
        case BadgeVariants.LABEL_DEFAULT: {
            return {
                backgroundColor: "var(--colors-background-100)"
            };
        }
        case BadgeVariants.LABEL_BG2: {
            return {
                backgroundColor: "var(--colors-background-200)"
            };
        }

        case BadgeVariants.LABEL_SUCCESS: {
            return {
                backgroundColor: "var(--colors-success)"
            };
        }

        case BadgeVariants.LABEL_INFO: {
            return {
                backgroundColor: "var(--colors-info)"
            };
        }

        case BadgeVariants.LABEL_WARNING: {
            return {
                backgroundColor: "var(--colors-warning)"
            };
        }

        case BadgeVariants.LABEL_HIGHLIGHT: {
            return {
                backgroundColor: "var(--colors-highlight)"
            };
        }

        case BadgeVariants.LABEL_PRIMARY: {
            return {
                backgroundColor: "var(--colors-primary)",
                color: "var(--button-primaryLabelColor)"
            };
        }

        case BadgeVariants.LABEL_SECONDARY: {
            return {
                backgroundColor: "var(--colors-secondary)",
                color: "white",
                fill: "white"
            };
        }

        case BadgeVariants.LABEL_DANGER: {
            return {
                backgroundColor: "var(--colors-danger)",
                color: "white",
                fill: "white"
            };
        }
    }
};
