import {VendorType} from "../../vendor/helpers/VendorType";
import {getPageTitle} from "../utils/get_page_title";

export const titleVendorDetail = (vendorType: VendorType, vendorName: string) =>
    getPageTitle(`${vendorType === VendorType.AGENCY ? "Agencja nieruchomości" : "Deweloper"} ${vendorName}`);
import {VendorType} from "../../vendor/helpers/VendorType";
import {getPageTitle} from "../utils/get_page_title";

export const titleVendorDetail = (vendorType: VendorType, vendorName: string) =>
    getPageTitle(`${vendorType === VendorType.AGENCY ? "Agencja nieruchomości" : "Deweloper"} ${vendorName}`);
