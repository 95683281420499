import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchRecommendedRegionsVendorsTypes, IRecommendedRegionVendors} from "../actions/fetch_recommended_regions_vendors";
import {fetchVendorNearbyRegionsVendorsTypes, IVendorsNearbyRegion} from "../actions/fetch_vendor_nearby_regions_vendors";

export interface IRecommendedRegionVendorsStore {
    recommendedRegions: IRecommendedRegionVendors[];
    nearbyRegions: IVendorsNearbyRegion[];
}

export const vendorRecommendedVendorsReducer = combineReducers({
    recommendedRegions: reduceResponseField(fetchRecommendedRegionsVendorsTypes, "results", []),
    nearbyRegions: reduceResponseField(fetchVendorNearbyRegionsVendorsTypes, "results", [])
});
import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchRecommendedRegionsVendorsTypes, IRecommendedRegionVendors} from "../actions/fetch_recommended_regions_vendors";
import {fetchVendorNearbyRegionsVendorsTypes, IVendorsNearbyRegion} from "../actions/fetch_vendor_nearby_regions_vendors";

export interface IRecommendedRegionVendorsStore {
    recommendedRegions: IRecommendedRegionVendors[];
    nearbyRegions: IVendorsNearbyRegion[];
}

export const vendorRecommendedVendorsReducer = combineReducers({
    recommendedRegions: reduceResponseField(fetchRecommendedRegionsVendorsTypes, "results", []),
    nearbyRegions: reduceResponseField(fetchVendorNearbyRegionsVendorsTypes, "results", [])
});
