export enum ResponseState {
    DEFAULT,
    REDIRECT_301,
    REDIRECT_302,
    STATE_404,
    STATE_ERROR
}
export enum ResponseState {
    DEFAULT,
    REDIRECT_301,
    REDIRECT_302,
    STATE_404,
    STATE_ERROR
}
