import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchUserPreferencesTypes} from "../actions/fetch_user_preferences";
import {IUserPreferences} from "../interfaces/IUserPreferences";

export interface IUserPreferencesStore {
    data?: IUserPreferences;
    requestState: RequestState;
}

export const userPreferencesReducer = combineReducers({
    data: reduceResponse<IUserPreferences>(fetchUserPreferencesTypes),
    requestState: reduceRequestState(fetchUserPreferencesTypes)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {fetchUserPreferencesTypes} from "../actions/fetch_user_preferences";
import {IUserPreferences} from "../interfaces/IUserPreferences";

export interface IUserPreferencesStore {
    data?: IUserPreferences;
    requestState: RequestState;
}

export const userPreferencesReducer = combineReducers({
    data: reduceResponse<IUserPreferences>(fetchUserPreferencesTypes),
    requestState: reduceRequestState(fetchUserPreferencesTypes)
});
