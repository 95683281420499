import type {ReactNode} from "react";
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {backgroundColor, calculateRemSize, flexJustifyCenter, m, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {IPictureProps, Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    header: string;
    subheader: string;
    infoGraphic: IPictureProps;
    children?: ReactNode;
}

export const ErrorSection = (props: IProps) => {
    const theme = useTheme();

    return (
        <div css={[flexJustifyCenter, w100, p(3, 2, 5), backgroundColor(theme.colors.gray[100]), onDesktop(p(4, 2, 9))]}>
            <WideContent>
                <div css={[flexJustifyCenter]}>
                    <Picture {...props.infoGraphic} />
                </div>

                <Text align="center" as="div" variant="headline_0" css={[m(3, 0, 2)]}>
                    {props.header}
                </Text>

                <Text align="center" as="p" variant="body_copy_1">
                    {props.subheader}
                </Text>

                {props.children && <div css={childrenWrapper}>{props.children}</div>}
            </WideContent>
        </div>
    );
};

const childrenWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${w100};
    row-gap: ${calculateRemSize(2)};
    ${mt(3)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
        flex-direction: row;
        column-gap: ${calculateRemSize(3)};
    }
`;
import type {ReactNode} from "react";
import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {backgroundColor, calculateRemSize, flexJustifyCenter, m, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {IPictureProps, Picture} from "@pg-design/picture-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    header: string;
    subheader: string;
    infoGraphic: IPictureProps;
    children?: ReactNode;
}

export const ErrorSection = (props: IProps) => {
    const theme = useTheme();

    return (
        <div css={[flexJustifyCenter, w100, p(3, 2, 5), backgroundColor(theme.colors.gray[100]), onDesktop(p(4, 2, 9))]}>
            <WideContent>
                <div css={[flexJustifyCenter]}>
                    <Picture {...props.infoGraphic} />
                </div>

                <Text align="center" as="div" variant="headline_0" css={[m(3, 0, 2)]}>
                    {props.header}
                </Text>

                <Text align="center" as="p" variant="body_copy_1">
                    {props.subheader}
                </Text>

                {props.children && <div css={childrenWrapper}>{props.children}</div>}
            </WideContent>
        </div>
    );
};

const childrenWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${w100};
    row-gap: ${calculateRemSize(2)};
    ${mt(3)};

    @media (min-width: ${theme.breakpoints.md}) {
        ${mt(6)};
        flex-direction: row;
        column-gap: ${calculateRemSize(3)};
    }
`;
