import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferFilters, IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";

export const createCanonicalAndRobotsInstalmentFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => doesInstalmentFiltersExist(params.filters),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

const doesInstalmentFiltersExist = (filters: IOfferFilters) => !!filters.instalment;
import {OfferMetaRobots} from "../../constants/offer_meta_robots";
import {IOfferFilters, IOfferListingSeoRuleParams} from "./constants";
import {getCustomOfferCanonical} from "./get_custom_offer_canonical";

export const createCanonicalAndRobotsInstalmentFilterRule = (params: IOfferListingSeoRuleParams) => ({
    condition: () => doesInstalmentFiltersExist(params.filters),
    robots: OfferMetaRobots.NOINDEX,
    canonical: () => getCustomOfferCanonical(params)
});

const doesInstalmentFiltersExist = (filters: IOfferFilters) => !!filters.instalment;
