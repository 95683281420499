import {uniq} from "@pg-mono/nodash";

import {ranking2023} from "../assets/ranking_2023";

export function getRankingCitiesIds() {
    return uniq(ranking2023.map(({regionId}) => regionId));
}
import {uniq} from "@pg-mono/nodash";

import {ranking2023} from "../assets/ranking_2023";

export function getRankingCitiesIds() {
    return uniq(ranking2023.map(({regionId}) => regionId));
}
