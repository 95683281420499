import type {BaseQueryApi} from "@reduxjs/toolkit/query";
import {Span} from "elastic-apm-node";

import {getApmTransactionName} from "@pg-mono/request";

import {IRPServicesMeta} from "../../store/types/IRPServicesMeta";
import {isServer} from "../read_rp_environment_variables";

export const fetchWithServices =
    (api: BaseQueryApi): typeof fetch =>
    async (input, init) => {
        let span: Span | null = null;
        const ssrUtils = (api.extra as {ssrUtils: IRPServicesMeta | null}).ssrUtils;
        const apm = ssrUtils?.apm;
        const requestUrl = typeof input === "string" ? input : (input as Request).url;

        if (isServer && apm) {
            const transactionName = getApmTransactionName(requestUrl);
            span = apm.transaction.startSpan(transactionName, "http", "node-fetch", {childOf: apm.transaction.traceparent});

            span?.addLabels({url: requestUrl}, true);
        }

        try {
            const inputHeaders = typeof input === "string" ? null : (input as Request).headers;

            if (!init) {
                init = {};
            }

            init.headers = inputHeaders || init.headers || new Headers();

            if (isServer && span && apm) {
                (init.headers as Headers).set("traceparent", span.traceparent);
                (init.headers as Headers).set("tracestate", apm.tracestate || "");
            }

            ssrUtils?.serverProfile?.start(requestUrl);

            const result = await fetch(input, init);
            span?.setOutcome("success");
            return result;
        } catch (error) {
            if (span) {
                span?.setOutcome("failure");
            }
            throw error;
        } finally {
            if (span) {
                span.end();
                ssrUtils?.serverProfile?.stop(requestUrl);
            }
        }
    };
import type {BaseQueryApi} from "@reduxjs/toolkit/query";
import {Span} from "elastic-apm-node";

import {getApmTransactionName} from "@pg-mono/request";

import {IRPServicesMeta} from "../../store/types/IRPServicesMeta";
import {isServer} from "../read_rp_environment_variables";

export const fetchWithServices =
    (api: BaseQueryApi): typeof fetch =>
    async (input, init) => {
        let span: Span | null = null;
        const ssrUtils = (api.extra as {ssrUtils: IRPServicesMeta | null}).ssrUtils;
        const apm = ssrUtils?.apm;
        const requestUrl = typeof input === "string" ? input : (input as Request).url;

        if (isServer && apm) {
            const transactionName = getApmTransactionName(requestUrl);
            span = apm.transaction.startSpan(transactionName, "http", "node-fetch", {childOf: apm.transaction.traceparent});

            span?.addLabels({url: requestUrl}, true);
        }

        try {
            const inputHeaders = typeof input === "string" ? null : (input as Request).headers;

            if (!init) {
                init = {};
            }

            init.headers = inputHeaders || init.headers || new Headers();

            if (isServer && span && apm) {
                (init.headers as Headers).set("traceparent", span.traceparent);
                (init.headers as Headers).set("tracestate", apm.tracestate || "");
            }

            ssrUtils?.serverProfile?.start(requestUrl);

            const result = await fetch(input, init);
            span?.setOutcome("success");
            return result;
        } catch (error) {
            if (span) {
                span?.setOutcome("failure");
            }
            throw error;
        } finally {
            if (span) {
                span.end();
                ssrUtils?.serverProfile?.stop(requestUrl);
            }
        }
    };
