import {OfferDisplayType} from "../helpers/OfferDisplayType";

export const detailOfferListConstraints = {
    limited_presentation: false,
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: true
};
import {OfferDisplayType} from "../helpers/OfferDisplayType";

export const detailOfferListConstraints = {
    limited_presentation: false,
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: true
};
