import {initAlgolytics} from "@pg-mono/algolytics";
import {setDefaultOptions as setOSMDefaultOptions} from "@pg-mono/open-street-map";

import {abTestingInstance} from "../../common/ab_testing/ab_testing_instance";
import {algolyticsId, osmTileUrl, showAlgolyticsLogs} from "../../common/app/read_rp_environment_variables";
import {initGoogleMap} from "../../common/maps/utils/init_google_map";
import {RpStore} from "../../common/store/store";
import {saveUserActivityTimestamp, userReturningVisitHit} from "../../common/tracking/algolytics_hits/user_returning_visit_hit";
import {FacebookPixel, RPFacebookPixelId} from "../../common/tracking/facebook_pixel/track_facebook_pixel";
import {fetchUserProfile} from "../../common/user/actions/fetch_user_profile";
import {fetchCookies, fetchMpCookies} from "../../common/user/api/fetch_cookies";
import {syncUserWithUserCom} from "../../common/user/api/sync_user_with_user_com";
import {initDebugBearRUM} from "../rp_debugbear_rum_init";
import {rpGtmInit} from "../rp_gtm_init";
import {rpUserComInit} from "../rp_user_com_init";
import {readUtmParams} from "./read_utm_params";

export const initClientApp = async (store: RpStore) => {
    initGoogleMap();

    await Promise.all([fetchCookies(readUtmParams()), fetchMpCookies(readUtmParams())]);

    if (osmTileUrl) {
        setOSMDefaultOptions({
            tileUrl: osmTileUrl,
            tileSize: 256,
            tileCrossOrigin: false,
            defaultMinZoom: 7,
            defaultMaxZoom: 16
        });
    }

    // Further init logic
    rpUserComInit();
    initAlgolytics(algolyticsId, showAlgolyticsLogs);
    saveUserActivityTimestamp(userReturningVisitHit);

    FacebookPixel.init(RPFacebookPixelId);

    const isAuthenticated = store.getState().isAuthenticated;

    if (isAuthenticated) {
        const userProfile = await fetchUserProfile()(store.dispatch, store.getState);

        if (userProfile) {
            syncUserWithUserCom(userProfile);
        }
    }

    rpGtmInit(store.getState(), store.dispatch); // requires user data and dispatch action to setGtmInit
    initDebugBearRUM();

    await store.dispatch(abTestingInstance.setClientSideABTestsInStore());
};
import {initAlgolytics} from "@pg-mono/algolytics";
import {setDefaultOptions as setOSMDefaultOptions} from "@pg-mono/open-street-map";

import {abTestingInstance} from "../../common/ab_testing/ab_testing_instance";
import {algolyticsId, osmTileUrl, showAlgolyticsLogs} from "../../common/app/read_rp_environment_variables";
import {initGoogleMap} from "../../common/maps/utils/init_google_map";
import {RpStore} from "../../common/store/store";
import {saveUserActivityTimestamp, userReturningVisitHit} from "../../common/tracking/algolytics_hits/user_returning_visit_hit";
import {FacebookPixel, RPFacebookPixelId} from "../../common/tracking/facebook_pixel/track_facebook_pixel";
import {fetchUserProfile} from "../../common/user/actions/fetch_user_profile";
import {fetchCookies, fetchMpCookies} from "../../common/user/api/fetch_cookies";
import {syncUserWithUserCom} from "../../common/user/api/sync_user_with_user_com";
import {initDebugBearRUM} from "../rp_debugbear_rum_init";
import {rpGtmInit} from "../rp_gtm_init";
import {rpUserComInit} from "../rp_user_com_init";
import {readUtmParams} from "./read_utm_params";

export const initClientApp = async (store: RpStore) => {
    initGoogleMap();

    await Promise.all([fetchCookies(readUtmParams()), fetchMpCookies(readUtmParams())]);

    if (osmTileUrl) {
        setOSMDefaultOptions({
            tileUrl: osmTileUrl,
            tileSize: 256,
            tileCrossOrigin: false,
            defaultMinZoom: 7,
            defaultMaxZoom: 16
        });
    }

    // Further init logic
    rpUserComInit();
    initAlgolytics(algolyticsId, showAlgolyticsLogs);
    saveUserActivityTimestamp(userReturningVisitHit);

    FacebookPixel.init(RPFacebookPixelId);

    const isAuthenticated = store.getState().isAuthenticated;

    if (isAuthenticated) {
        const userProfile = await fetchUserProfile()(store.dispatch, store.getState);

        if (userProfile) {
            syncUserWithUserCom(userProfile);
        }
    }

    rpGtmInit(store.getState(), store.dispatch); // requires user data and dispatch action to setGtmInit
    initDebugBearRUM();

    await store.dispatch(abTestingInstance.setClientSideABTestsInStore());
};
