import {TextVariant} from "@pg-design/text";

export const sizeToVariantMap = {
    big: TextVariant.BUTTON_BIG,
    medium: TextVariant.BUTTON_MEDIUM,
    small: TextVariant.BUTTON_SMALL,
    "x-small": TextVariant.BUTTON_MICRO
};
import {TextVariant} from "@pg-design/text";

export const sizeToVariantMap = {
    big: TextVariant.BUTTON_BIG,
    medium: TextVariant.BUTTON_MEDIUM,
    small: TextVariant.BUTTON_SMALL,
    "x-small": TextVariant.BUTTON_MICRO
};
