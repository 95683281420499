export const strEmpty = <DefValue>(value: unknown, defaultValue?: DefValue) => {
    if (value && typeof value === "string") {
        return value as string;
    }

    return defaultValue || "";
};
export const strEmpty = <DefValue>(value: unknown, defaultValue?: DefValue) => {
    if (value && typeof value === "string") {
        return value as string;
    }

    return defaultValue || "";
};
