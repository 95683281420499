import React, {CSSProperties, forwardRef, useId} from "react";
import ReactSelect, {GroupBase, Props as IReactSelectProps, SelectInstance} from "react-select";

import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text-module";

import {useSelectTheme} from "../hooks/use_select_theme";

import {iconStyle} from "./Select.module.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ISelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> extends IReactSelectProps {
    isBorderless?: boolean;
    labelContent?: string;
    dataTestId?: string;
    style?: CSSProperties;
}

const Wrapper = (props: {children: (React.ReactNode | null)[]; hasLabel?: boolean; className?: string; style?: CSSProperties}) => {
    if (props.hasLabel) {
        return (
            <div className={props.className} style={props.style}>
                {props.children}
            </div>
        );
    }
    return <>{props.children}</>;
};

function SelectBase<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ISelectProps<Option, IsMulti, Group>,
    ref: React.LegacyRef<SelectInstance<unknown, boolean, GroupBase<unknown>>>
) {
    const {getTheme, styles} = useSelectTheme();
    const {labelContent, isBorderless, className, placeholder, dataTestId, style, ...restProps} = props;

    const instanceId = useId();

    return (
        <Wrapper hasLabel={!!labelContent} className={className} style={style} data-testid={dataTestId}>
            {labelContent ? (
                <Text mb="0.4rem" variant="body_copy_2" as="div">
                    {labelContent}
                </Text>
            ) : null}
            <ReactSelect
                instanceId={instanceId}
                components={{
                    DropdownIndicator: (state) => {
                        return <ChevronDownIcon className={iconStyle} size="2" fill={`var(--colors-gray-${state.isDisabled ? "300" : "700"})`} />;
                    }
                }}
                isSearchable={false}
                styles={{
                    ...styles,
                    ...(isBorderless && {
                        control: (provided) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            backgroundColor: "transparent"
                        })
                    })
                }}
                theme={getTheme}
                {...restProps}
                className={!labelContent ? className : undefined}
                placeholder={placeholder || "Wybierz"}
                ref={ref}
            />
        </Wrapper>
    );
}

export const Select = forwardRef(SelectBase);
import React, {CSSProperties, forwardRef, useId} from "react";
import ReactSelect, {GroupBase, Props as IReactSelectProps, SelectInstance} from "react-select";

import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text-module";

import {useSelectTheme} from "../hooks/use_select_theme";

import {iconStyle} from "./Select.module.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ISelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> extends IReactSelectProps {
    isBorderless?: boolean;
    labelContent?: string;
    dataTestId?: string;
    style?: CSSProperties;
}

const Wrapper = (props: {children: (React.ReactNode | null)[]; hasLabel?: boolean; className?: string; style?: CSSProperties}) => {
    if (props.hasLabel) {
        return (
            <div className={props.className} style={props.style}>
                {props.children}
            </div>
        );
    }
    return <>{props.children}</>;
};

function SelectBase<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: ISelectProps<Option, IsMulti, Group>,
    ref: React.LegacyRef<SelectInstance<unknown, boolean, GroupBase<unknown>>>
) {
    const {getTheme, styles} = useSelectTheme();
    const {labelContent, isBorderless, className, placeholder, dataTestId, style, ...restProps} = props;

    const instanceId = useId();

    return (
        <Wrapper hasLabel={!!labelContent} className={className} style={style} data-testid={dataTestId}>
            {labelContent ? (
                <Text mb="0.4rem" variant="body_copy_2" as="div">
                    {labelContent}
                </Text>
            ) : null}
            <ReactSelect
                instanceId={instanceId}
                components={{
                    DropdownIndicator: (state) => {
                        return <ChevronDownIcon className={iconStyle} size="2" fill={`var(--colors-gray-${state.isDisabled ? "300" : "700"})`} />;
                    }
                }}
                isSearchable={false}
                styles={{
                    ...styles,
                    ...(isBorderless && {
                        control: (provided) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            backgroundColor: "transparent"
                        })
                    })
                }}
                theme={getTheme}
                {...restProps}
                className={!labelContent ? className : undefined}
                placeholder={placeholder || "Wybierz"}
                ref={ref}
            />
        </Wrapper>
    );
}

export const Select = forwardRef(SelectBase);
