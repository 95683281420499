import {Attribute} from "../types/Attribute";
import {CustomAttribute} from "../types/CustomAttribute";
import {EventNames} from "../types/EventNames";

type UserComUserData = Partial<Record<Attribute, string>> & Partial<Record<CustomAttribute, string>>;

export type UpdateUserAttributesPayload = UserComUserData & {
    event: UserComUserData & {
        event_name: EventNames | string;
    };
};

export const updateUserAttributes = (data: UserComUserData & {event_name: EventNames | string}) => {
    const payload: UpdateUserAttributesPayload = {
        ...data,
        event: data
    };

    window.rpUE.pageHit(payload);
};
import {Attribute} from "../types/Attribute";
import {CustomAttribute} from "../types/CustomAttribute";
import {EventNames} from "../types/EventNames";

type UserComUserData = Partial<Record<Attribute, string>> & Partial<Record<CustomAttribute, string>>;

export type UpdateUserAttributesPayload = UserComUserData & {
    event: UserComUserData & {
        event_name: EventNames | string;
    };
};

export const updateUserAttributes = (data: UserComUserData & {event_name: EventNames | string}) => {
    const payload: UpdateUserAttributesPayload = {
        ...data,
        event: data
    };

    window.rpUE.pageHit(payload);
};
