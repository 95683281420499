// Replacement for https://lodash.com/docs/4.17.15#head
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const head = <T = any>(array: T[]) => {
    return array[0];
};
// Replacement for https://lodash.com/docs/4.17.15#head
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const head = <T = any>(array: T[]) => {
    return array[0];
};
