import {Dispatch} from "redux";

import {appendQueryString, parseSearch} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {addFailureNotification} from "../../notifications/utils/add_failure_notification";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {userSignInHit} from "../../tracking/algolytics_hits/user_sign_in_hit";
import {UserSource} from "../../tracking/tracking_utils/user_source";

export const afterRedirectOauthLogin = (isAuthenticated: boolean) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (window) {
        const query = parseSearch(location.search);

        if (query.oauth_login) {
            dispatch(
                isAuthenticated
                    ? addSuccessNotification("Zalogowano do konta")
                    : addFailureNotification("Wystąpił błąd podczas logowania do konta. Spróbuj ponownie później.")
            );

            if (isAuthenticated) {
                const userProfileData = getState().user.profile.data;
                const currentViewType = getState().viewType.current;

                if (userProfileData) {
                    userSignInHit(UserSource.NAVIGATION, userProfileData, currentViewType);
                }
            }

            // Remove `oauth_login` - we don't need it anymore
            delete query.oauth_login;

            window.history.replaceState({}, document.title, appendQueryString(location.pathname, query));
        }
    }
};
import {Dispatch} from "redux";

import {appendQueryString, parseSearch} from "@pg-mono/request";

import {IRPStore} from "../../app/rp_reducer";
import {addFailureNotification} from "../../notifications/utils/add_failure_notification";
import {addSuccessNotification} from "../../notifications/utils/add_success_notification";
import {userSignInHit} from "../../tracking/algolytics_hits/user_sign_in_hit";
import {UserSource} from "../../tracking/tracking_utils/user_source";

export const afterRedirectOauthLogin = (isAuthenticated: boolean) => async (dispatch: Dispatch, getState: () => IRPStore) => {
    if (window) {
        const query = parseSearch(location.search);

        if (query.oauth_login) {
            dispatch(
                isAuthenticated
                    ? addSuccessNotification("Zalogowano do konta")
                    : addFailureNotification("Wystąpił błąd podczas logowania do konta. Spróbuj ponownie później.")
            );

            if (isAuthenticated) {
                const userProfileData = getState().user.profile.data;
                const currentViewType = getState().viewType.current;

                if (userProfileData) {
                    userSignInHit(UserSource.NAVIGATION, userProfileData, currentViewType);
                }
            }

            // Remove `oauth_login` - we don't need it anymore
            delete query.oauth_login;

            window.history.replaceState({}, document.title, appendQueryString(location.pathname, query));
        }
    }
};
