import {memo} from "react";
import {useSelector} from "react-redux";

import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {useIsMobile, useIsPrint} from "@pg-mono/hooks";
import {WeglotLangSwitcher} from "@pg-mono/i18n";
import {rpAppLink} from "@pg-mono/rp-routes";
import {Navigation as DesignNavigation} from "@pg-rp/navigation";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPStore} from "../../app/rp_reducer";
import {NAV_MAIN_ELEMENTS} from "../constants/navigation_elements";
import {useNavigationSearch} from "../hooks/use_navigation_search";
import {useNavigationUserElements} from "../hooks/use_navigation_user_elements";
import {hitLangSwitch} from "../tracking/track_lang_switcher";

const rpLogo = require("../../app/images/rp_logo.svg");

export const NAV_MOBILE_BREAKPOINT = 1310;
const FIXED_WIDTH_MAX_BREAKPOINT = 1505;

interface IProps {
    onPin?: () => void;
    onUnpin?: () => void;
    onUnfix?: () => void;
    blendTopOffset?: string;
}

export const Navigation = memo((props: IProps) => {
    const isMobile = useIsMobile(true, NAV_MOBILE_BREAKPOINT);
    const isPrinting = useIsPrint();
    const backToUrl = useSelector((store: IRPStore) => store.backToUrl);

    const navMiddleElement = useNavigationSearch();
    const {navRightElements, navMobileMiddleElements} = useNavigationUserElements();

    return (
        <DesignNavigation
            navLeftElements={NAV_MAIN_ELEMENTS}
            navMiddleElement={navMiddleElement}
            navMobileMiddleElements={navMobileMiddleElements}
            navRightElements={navRightElements}
            logo={logoData}
            arrows={navArrows}
            backToUrl={backToUrl}
            isMobile={isMobile}
            mobileBreakpoint={NAV_MOBILE_BREAKPOINT}
            isPrinting={isPrinting}
            langSwitcher={
                <WeglotLangSwitcher
                    isMobile={isMobile}
                    onLangChange={(langCode) => hitLangSwitch(langCode)}
                    setWidthBreakpoints={{min: `${NAV_MOBILE_BREAKPOINT + 1}px`, max: `${FIXED_WIDTH_MAX_BREAKPOINT}px`}}
                />
            }
            blendTopOffset={props.blendTopOffset}
            onPin={props.onPin}
            onUnpin={props.onUnpin}
            onUnfix={props.onUnfix}
        />
    );
});

const navArrows = {
    left: <ChevronLeftIcon size="1.2" />,
    right: <ChevronRightIcon size="1.2" />
};

const logoData = {
    imgUrl: rpLogo,
    url: rpAppLink.base(),
    alt: `Logo portalu ${BRAND_NAME}`,
    title: `Logo portalu ${BRAND_NAME}`
};
import {memo} from "react";
import {useSelector} from "react-redux";

import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons-module";
import {useIsMobile, useIsPrint} from "@pg-mono/hooks";
import {WeglotLangSwitcher} from "@pg-mono/i18n";
import {rpAppLink} from "@pg-mono/rp-routes";
import {Navigation as DesignNavigation} from "@pg-rp/navigation";

import {BRAND_NAME} from "../../app/constants/company";
import {IRPStore} from "../../app/rp_reducer";
import {NAV_MAIN_ELEMENTS} from "../constants/navigation_elements";
import {useNavigationSearch} from "../hooks/use_navigation_search";
import {useNavigationUserElements} from "../hooks/use_navigation_user_elements";
import {hitLangSwitch} from "../tracking/track_lang_switcher";

const rpLogo = require("../../app/images/rp_logo.svg");

export const NAV_MOBILE_BREAKPOINT = 1310;
const FIXED_WIDTH_MAX_BREAKPOINT = 1505;

interface IProps {
    onPin?: () => void;
    onUnpin?: () => void;
    onUnfix?: () => void;
    blendTopOffset?: string;
}

export const Navigation = memo((props: IProps) => {
    const isMobile = useIsMobile(true, NAV_MOBILE_BREAKPOINT);
    const isPrinting = useIsPrint();
    const backToUrl = useSelector((store: IRPStore) => store.backToUrl);

    const navMiddleElement = useNavigationSearch();
    const {navRightElements, navMobileMiddleElements} = useNavigationUserElements();

    return (
        <DesignNavigation
            navLeftElements={NAV_MAIN_ELEMENTS}
            navMiddleElement={navMiddleElement}
            navMobileMiddleElements={navMobileMiddleElements}
            navRightElements={navRightElements}
            logo={logoData}
            arrows={navArrows}
            backToUrl={backToUrl}
            isMobile={isMobile}
            mobileBreakpoint={NAV_MOBILE_BREAKPOINT}
            isPrinting={isPrinting}
            langSwitcher={
                <WeglotLangSwitcher
                    isMobile={isMobile}
                    onLangChange={(langCode) => hitLangSwitch(langCode)}
                    setWidthBreakpoints={{min: `${NAV_MOBILE_BREAKPOINT + 1}px`, max: `${FIXED_WIDTH_MAX_BREAKPOINT}px`}}
                />
            }
            blendTopOffset={props.blendTopOffset}
            onPin={props.onPin}
            onUnpin={props.onUnpin}
            onUnfix={props.onUnfix}
        />
    );
});

const navArrows = {
    left: <ChevronLeftIcon size="1.2" />,
    right: <ChevronRightIcon size="1.2" />
};

const logoData = {
    imgUrl: rpLogo,
    url: rpAppLink.base(),
    alt: `Logo portalu ${BRAND_NAME}`,
    title: `Logo portalu ${BRAND_NAME}`
};
