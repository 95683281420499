import {IFavourite} from "@pg-mono/favourites";
import {postRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

interface IUpdateFavourite {
    is_deleted: boolean;
    rp_offer_id: number | null;
    rp_property_id: number | null;
}

export const updateFavourites = (favourites: IUpdateFavourite[]): Promise<IFavourite[]> => {
    const userApiMeta = getUserApiRequestMeta({});

    return postRequest(userApiMeta, apiUserLink.favorites.favorite, favourites);
};

export const getServerFavourite = (favourite: IFavourite, isDeleted = false): IUpdateFavourite => {
    return {
        is_deleted: isDeleted,
        rp_offer_id: favourite?.offer?.rp_id || null,
        rp_property_id: favourite?.property?.rp_id || null
    };
};
import {IFavourite} from "@pg-mono/favourites";
import {postRequest} from "@pg-mono/request";
import {apiUserLink} from "@pg-mono/rp-api-routes";

import {getUserApiRequestMeta} from "../../user/utils/get_user_api_request_meta";

interface IUpdateFavourite {
    is_deleted: boolean;
    rp_offer_id: number | null;
    rp_property_id: number | null;
}

export const updateFavourites = (favourites: IUpdateFavourite[]): Promise<IFavourite[]> => {
    const userApiMeta = getUserApiRequestMeta({});

    return postRequest(userApiMeta, apiUserLink.favorites.favorite, favourites);
};

export const getServerFavourite = (favourite: IFavourite, isDeleted = false): IUpdateFavourite => {
    return {
        is_deleted: isDeleted,
        rp_offer_id: favourite?.offer?.rp_id || null,
        rp_property_id: favourite?.property?.rp_id || null
    };
};
