import {DEFAULT_BASE, rem} from "./utils/rem";

type Size = 0 | 0.5 | 1 | 2 | 3 | 4;

// multiplies border size by base (0.8rem). Accepts up to 4 arguments for border-radius sides
export const borderRadius = (...sizeArgs: [] | [Size] | [Size, Size, Size, Size]) => {
    const hasAnyBorderRadiusSizes = sizeArgs.length > 0;

    if (hasAnyBorderRadiusSizes) {
        return `
            border-radius: ${sizeArgs.map((size) => rem(size * DEFAULT_BASE)).join(" ")};
        `;
    }

    return `
        border-radius: ${rem(DEFAULT_BASE)};
    `;
};
import {DEFAULT_BASE, rem} from "./utils/rem";

type Size = 0 | 0.5 | 1 | 2 | 3 | 4;

// multiplies border size by base (0.8rem). Accepts up to 4 arguments for border-radius sides
export const borderRadius = (...sizeArgs: [] | [Size] | [Size, Size, Size, Size]) => {
    const hasAnyBorderRadiusSizes = sizeArgs.length > 0;

    if (hasAnyBorderRadiusSizes) {
        return `
            border-radius: ${sizeArgs.map((size) => rem(size * DEFAULT_BASE)).join(" ")};
        `;
    }

    return `
        border-radius: ${rem(DEFAULT_BASE)};
    `;
};
