import type {Dispatch, UnknownAction} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {IFormValuesAction} from "@pg-mono/form";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {InvestmentOfferCategory, InvestmentOfferSubCategory, InvestmentOfferTypeSlug} from "../../constants/investment_offer_constants";
import {getParsedInvestmentOfferListUrl} from "../../utils/get_parsed_investment_offer_list_url";
import {validateInvestmentOfferSubCategory} from "../utils/validate_investment_offer_subcategory";
import {investmentOfferListFilterFormActions} from "./investment_offer_list_filter_form_actions";

export const INVESTMENT_OFFER_PARSED_CATEGORIES = "investment_offer_list/CATEGORIES";

export type IParsedInvestmentOfferCategories = {category: InvestmentOfferCategory | null; subcategory: InvestmentOfferSubCategory | null};

export const parseInvestmentOfferUrl =
    (redirectLink: (params: {category: string}) => string) =>
    (ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch<IFormValuesAction<unknown> | UnknownAction>): Promise<IParsedInvestmentOfferCategories | boolean> => {
        const params = getParsedInvestmentOfferListUrl(ctx.route.pathname);

        const validCategories = Object.keys(InvestmentOfferTypeSlug).map((key) => InvestmentOfferTypeSlug[key as keyof typeof InvestmentOfferTypeSlug]);
        const isCategoryValid = params.category && validCategories.includes(params.category as InvestmentOfferTypeSlug);
        const isSubcategoryValid = validateInvestmentOfferSubCategory(params);

        if (!isCategoryValid || (params.subcategory && !isSubcategoryValid)) {
            await redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta)(dispatch);
            return false;
        }
        if (params.category && params.subcategory && !isSubcategoryValid) {
            await dispatch(enable301ResponseState(redirectLink({category: params.category})));
            return false;
        }

        dispatch({type: INVESTMENT_OFFER_PARSED_CATEGORIES, params});

        dispatch(investmentOfferListFilterFormActions.replace(params));

        return params;
    };
import type {Dispatch, UnknownAction} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {IFormValuesAction} from "@pg-mono/form";
import {enable301ResponseState} from "@pg-mono/response-state";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {InvestmentOfferCategory, InvestmentOfferSubCategory, InvestmentOfferTypeSlug} from "../../constants/investment_offer_constants";
import {getParsedInvestmentOfferListUrl} from "../../utils/get_parsed_investment_offer_list_url";
import {validateInvestmentOfferSubCategory} from "../utils/validate_investment_offer_subcategory";
import {investmentOfferListFilterFormActions} from "./investment_offer_list_filter_form_actions";

export const INVESTMENT_OFFER_PARSED_CATEGORIES = "investment_offer_list/CATEGORIES";

export type IParsedInvestmentOfferCategories = {category: InvestmentOfferCategory | null; subcategory: InvestmentOfferSubCategory | null};

export const parseInvestmentOfferUrl =
    (redirectLink: (params: {category: string}) => string) =>
    (ctx: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch<IFormValuesAction<unknown> | UnknownAction>): Promise<IParsedInvestmentOfferCategories | boolean> => {
        const params = getParsedInvestmentOfferListUrl(ctx.route.pathname);

        const validCategories = Object.keys(InvestmentOfferTypeSlug).map((key) => InvestmentOfferTypeSlug[key as keyof typeof InvestmentOfferTypeSlug]);
        const isCategoryValid = params.category && validCategories.includes(params.category as InvestmentOfferTypeSlug);
        const isSubcategoryValid = validateInvestmentOfferSubCategory(params);

        if (!isCategoryValid || (params.subcategory && !isSubcategoryValid)) {
            await redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta)(dispatch);
            return false;
        }
        if (params.category && params.subcategory && !isSubcategoryValid) {
            await dispatch(enable301ResponseState(redirectLink({category: params.category})));
            return false;
        }

        dispatch({type: INVESTMENT_OFFER_PARSED_CATEGORIES, params});

        dispatch(investmentOfferListFilterFormActions.replace(params));

        return params;
    };
