import {
    investmentOfferCities,
    InvestmentOfferCitySlug,
    investmentOfferCountries,
    InvestmentOfferCountrySlug,
    investmentOfferHolidayTypes,
    InvestmentOfferSubTypeSlug,
    InvestmentOfferTypeSlug
} from "../../constants/investment_offer_constants";
import {IParsedInvestmentOfferCategories} from "../actions/parse_investment_offer_url";

const validSubCategoriesCities = investmentOfferCities.map((city) => city.slug);
const validSubCategoriesHoliday = investmentOfferHolidayTypes.map((type) => type.slug);
const validSubCategoriesCountries = investmentOfferCountries.map((type) => type.slug);

export const validateInvestmentOfferSubCategory = (params: IParsedInvestmentOfferCategories) => {
    if (params.subcategory && params.category) {
        switch (params.category) {
            case InvestmentOfferTypeSlug.CONDOHOTEL:
            case InvestmentOfferTypeSlug.FOR_RENT:
                return [...validSubCategoriesCities, ...validSubCategoriesHoliday].includes(
                    params.subcategory as InvestmentOfferSubTypeSlug | InvestmentOfferCitySlug
                );
            case InvestmentOfferTypeSlug.HOLIDAY:
                return validSubCategoriesHoliday.includes(params.subcategory as InvestmentOfferSubTypeSlug);
            case InvestmentOfferTypeSlug.INVESTMENT:
                return validSubCategoriesCities.includes(params.subcategory as InvestmentOfferCitySlug);
            case InvestmentOfferTypeSlug.ABROAD:
                return validSubCategoriesCountries.includes(params.subcategory as InvestmentOfferCountrySlug);

            default:
                return false;
        }
    }
    return false;
};
import {
    investmentOfferCities,
    InvestmentOfferCitySlug,
    investmentOfferCountries,
    InvestmentOfferCountrySlug,
    investmentOfferHolidayTypes,
    InvestmentOfferSubTypeSlug,
    InvestmentOfferTypeSlug
} from "../../constants/investment_offer_constants";
import {IParsedInvestmentOfferCategories} from "../actions/parse_investment_offer_url";

const validSubCategoriesCities = investmentOfferCities.map((city) => city.slug);
const validSubCategoriesHoliday = investmentOfferHolidayTypes.map((type) => type.slug);
const validSubCategoriesCountries = investmentOfferCountries.map((type) => type.slug);

export const validateInvestmentOfferSubCategory = (params: IParsedInvestmentOfferCategories) => {
    if (params.subcategory && params.category) {
        switch (params.category) {
            case InvestmentOfferTypeSlug.CONDOHOTEL:
            case InvestmentOfferTypeSlug.FOR_RENT:
                return [...validSubCategoriesCities, ...validSubCategoriesHoliday].includes(
                    params.subcategory as InvestmentOfferSubTypeSlug | InvestmentOfferCitySlug
                );
            case InvestmentOfferTypeSlug.HOLIDAY:
                return validSubCategoriesHoliday.includes(params.subcategory as InvestmentOfferSubTypeSlug);
            case InvestmentOfferTypeSlug.INVESTMENT:
                return validSubCategoriesCities.includes(params.subcategory as InvestmentOfferCitySlug);
            case InvestmentOfferTypeSlug.ABROAD:
                return validSubCategoriesCountries.includes(params.subcategory as InvestmentOfferCountrySlug);

            default:
                return false;
        }
    }
    return false;
};
