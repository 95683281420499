import {DEFAULT_CREDIT_DURATION_YEARS, DEFAULT_INTEREST} from "../constants/calculations_defaults";

export const calculatePriceFromInstalment = (instalment: number) => {
    if (instalment <= 0 || isNaN(instalment)) {
        return "";
    }
    /**
     * A=R*m*(1-(m/m+b)^n)/b,
     *  R is the installment amount,
     *  A is the amount of credit granted,
     *  b is the interest rate on the loan per year - we assume 7%,
     *  m is the number of installments to pay in a year,
     *  n is the number of installments
     * This equation is a transformed equation from the `calculate_instalment.ts` file
     */
    const instalmentsNumber = DEFAULT_CREDIT_DURATION_YEARS * 12;
    const instalmentsPerYear = 12;
    const creditFactor = 1 - Math.pow(instalmentsPerYear / (instalmentsPerYear + DEFAULT_INTEREST), instalmentsNumber);

    const credit = (instalment * instalmentsPerYear * creditFactor) / DEFAULT_INTEREST;
    return Math.ceil(credit / 0.8).toString();
};
import {DEFAULT_CREDIT_DURATION_YEARS, DEFAULT_INTEREST} from "../constants/calculations_defaults";

export const calculatePriceFromInstalment = (instalment: number) => {
    if (instalment <= 0 || isNaN(instalment)) {
        return "";
    }
    /**
     * A=R*m*(1-(m/m+b)^n)/b,
     *  R is the installment amount,
     *  A is the amount of credit granted,
     *  b is the interest rate on the loan per year - we assume 7%,
     *  m is the number of installments to pay in a year,
     *  n is the number of installments
     * This equation is a transformed equation from the `calculate_instalment.ts` file
     */
    const instalmentsNumber = DEFAULT_CREDIT_DURATION_YEARS * 12;
    const instalmentsPerYear = 12;
    const creditFactor = 1 - Math.pow(instalmentsPerYear / (instalmentsPerYear + DEFAULT_INTEREST), instalmentsNumber);

    const credit = (instalment * instalmentsPerYear * creditFactor) / DEFAULT_INTEREST;
    return Math.ceil(credit / 0.8).toString();
};
