import {getOfferListSubTypeByFloorChoice} from "../../../list/helpers/sub_type/transform/get_offer_list_sub_type_by_floor_choice";
import {getOfferListSubTypeByHouseFilter} from "../../../list/helpers/sub_type/transform/get_offer_list_sub_type_by_house_filter";
import {isSingleHouseFilterSelected} from "../../../list/helpers/sub_type/validator/is_single_house_filter_selected";
import {IFriendlySubQuery} from "../../../list/types/friendly_sub_query";
import {OfferListSubType} from "../../../list/types/OfferListSubType";

export function getOfferListSubFilterValue(data: Partial<IFriendlySubQuery>): OfferListSubType | null {
    if ("floor_choices" in data && data.floor_choices?.length === 1) {
        return getOfferListSubTypeByFloorChoice(data.floor_choices[0]);
    }

    if (isSingleHouseFilterSelected(data)) {
        return getOfferListSubTypeByHouseFilter(data);
    }

    return null;
}
import {getOfferListSubTypeByFloorChoice} from "../../../list/helpers/sub_type/transform/get_offer_list_sub_type_by_floor_choice";
import {getOfferListSubTypeByHouseFilter} from "../../../list/helpers/sub_type/transform/get_offer_list_sub_type_by_house_filter";
import {isSingleHouseFilterSelected} from "../../../list/helpers/sub_type/validator/is_single_house_filter_selected";
import {IFriendlySubQuery} from "../../../list/types/friendly_sub_query";
import {OfferListSubType} from "../../../list/types/OfferListSubType";

export function getOfferListSubFilterValue(data: Partial<IFriendlySubQuery>): OfferListSubType | null {
    if ("floor_choices" in data && data.floor_choices?.length === 1) {
        return getOfferListSubTypeByFloorChoice(data.floor_choices[0]);
    }

    if (isSingleHouseFilterSelected(data)) {
        return getOfferListSubTypeByHouseFilter(data);
    }

    return null;
}
