export const getCookie = (name: string) => getCookieFromText(name, document.cookie);

export const getCookieFromText = (name: string, text: string): string | null => {
    const value = "; " + text;
    const parts = value.split("; " + name + "=");
    return parts.length >= 2 ? (parts.pop() as string).split(";").shift() ?? null : null;
};
export const getCookie = (name: string) => getCookieFromText(name, document.cookie);

export const getCookieFromText = (name: string, text: string): string | null => {
    const value = "; " + text;
    const parts = value.split("; " + name + "=");
    return parts.length >= 2 ? (parts.pop() as string).split(";").shift() ?? null : null;
};
