import {useMemo} from "react";

import {createOfferLink, ICreateOfferLinkOfferParam} from "../../helpers/create_offer_link";

export const useOfferLink = (offer: ICreateOfferLinkOfferParam) => {
    return useMemo(() => createOfferLink(offer), [offer.id, offer.slug, offer.vendor.slug, offer.groups]);
};
import {useMemo} from "react";

import {createOfferLink, ICreateOfferLinkOfferParam} from "../../helpers/create_offer_link";

export const useOfferLink = (offer: ICreateOfferLinkOfferParam) => {
    return useMemo(() => createOfferLink(offer), [offer.id, offer.slug, offer.vendor.slug, offer.groups]);
};
