import {upperFirst} from "@pg-mono/nodash";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {getSearchFilterPrefix, IFilterOptions} from "../../offer/helpers/searches/generate_name";
import {ISearchPhrase, offerMapSearchPhraseBuilder, offerSearchPhraseBuilder} from "../../offer/helpers/searches/search_phrase";
import {getCountryId, getCountryName} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {getHouseFilterMetaText} from "../../offer/list/helpers/sub_type/meta/get_house_filter_meta_text";
import {getFloorChoiceByOfferListSubType} from "../../offer/list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_house_filter";
import {OfferType} from "../../offer/types/OfferType";
import {getFloorChoiceText} from "../../real_estate/utils/get_floor_choice_text";
import {Country} from "../../region/types/Country";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";
import {getPageTitle} from "../utils/get_page_title";

interface ITitleOfferList extends ISearchPhrase {}

export const titleOfferList = (props: ITitleOfferList, page: number | undefined, filterOptions: IFilterOptions, offerListSubFilter: string | null = null) => {
    const isSingleRoomFlat = props.type === OfferType.FLAT && props.rooms_1 === "1";
    const searchFilterPrefix = isSingleRoomFlat ? "" : `${getSearchFilterPrefix(filterOptions)} `;
    const country = getCountryId(props?.country);

    const searchPhrase = offerSearchPhraseBuilder(props, {
        rooms: {
            showSingleFilterAsNumber: (props.type === OfferType.FLAT && props.rooms_1 !== "1") || props.type === OfferType.HOUSE
        },
        isSingleRoomFlat,
        isTitle: true
    });
    const offerType = props.type === OfferType.FLAT ? "mieszkania" : props.type === OfferType.HOUSE ? "domy" : "nieruchomości";

    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    if (country && country !== Country.POLAND) {
        const showDealType = (props.type !== OfferType.FLAT && props.type !== OfferType.HOUSE) || region;
        const dealTypeString = showDealType ? " na sprzedaż" : "";
        const offerTypeString = region ? ` ${offerType} ` : upperFirst(offerType);

        return getPageTitle(`${region}${offerTypeString}${dealTypeString} ${region ? "" : upperFirst(getCountryName(country))}`, page);
    }
    if (offerListSubFilter && props.type === OfferType.FLAT && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        const floorText = getFloorChoiceText(floorChoice);
        return concatWithBrandName(`Mieszkania ${floorText} ${region} -`);
    }

    if (offerListSubFilter && props.type === OfferType.HOUSE && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        const houseFilterText = getHouseFilterMetaText(offerListSubFilter);
        return concatWithBrandName(`${searchFilterPrefix} domy ${houseFilterText} ${region} -`);
    }

    return getPageTitle(`${upperFirst(searchFilterPrefix)}${searchPhrase}`, page);
};

export const titleOfferListMap = (props: ITitleOfferList) => {
    return getPageTitle(`Mapa inwestycji ${props.regionName || ""} - ${offerMapSearchPhraseBuilder(props)}`);
};
import {upperFirst} from "@pg-mono/nodash";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {getSearchFilterPrefix, IFilterOptions} from "../../offer/helpers/searches/generate_name";
import {ISearchPhrase, offerMapSearchPhraseBuilder, offerSearchPhraseBuilder} from "../../offer/helpers/searches/search_phrase";
import {getCountryId, getCountryName} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {getHouseFilterMetaText} from "../../offer/list/helpers/sub_type/meta/get_house_filter_meta_text";
import {getFloorChoiceByOfferListSubType} from "../../offer/list/helpers/sub_type/transform/get_floor_choice_by_offer_list_sub_type";
import {isOfferListSubTypeValidFloorChoice} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_floor_choice";
import {isOfferListSubTypeValidHouseFilter} from "../../offer/list/helpers/sub_type/validator/is_offer_list_sub_type_valid_house_filter";
import {OfferType} from "../../offer/types/OfferType";
import {getFloorChoiceText} from "../../real_estate/utils/get_floor_choice_text";
import {Country} from "../../region/types/Country";
import {generateMetaRegionName} from "../utils/generate_meta_region_name";
import {getPageTitle} from "../utils/get_page_title";

interface ITitleOfferList extends ISearchPhrase {}

export const titleOfferList = (props: ITitleOfferList, page: number | undefined, filterOptions: IFilterOptions, offerListSubFilter: string | null = null) => {
    const isSingleRoomFlat = props.type === OfferType.FLAT && props.rooms_1 === "1";
    const searchFilterPrefix = isSingleRoomFlat ? "" : `${getSearchFilterPrefix(filterOptions)} `;
    const country = getCountryId(props?.country);

    const searchPhrase = offerSearchPhraseBuilder(props, {
        rooms: {
            showSingleFilterAsNumber: (props.type === OfferType.FLAT && props.rooms_1 !== "1") || props.type === OfferType.HOUSE
        },
        isSingleRoomFlat,
        isTitle: true
    });
    const offerType = props.type === OfferType.FLAT ? "mieszkania" : props.type === OfferType.HOUSE ? "domy" : "nieruchomości";

    const region = !!props.regions
        ? props.regions.length === 1
            ? generateMetaRegionName(props.regions[0], true)
            : props.regions.map((r) => `- ${r.full_name_reverted}`).join(" ")
        : "";
    if (country && country !== Country.POLAND) {
        const showDealType = (props.type !== OfferType.FLAT && props.type !== OfferType.HOUSE) || region;
        const dealTypeString = showDealType ? " na sprzedaż" : "";
        const offerTypeString = region ? ` ${offerType} ` : upperFirst(offerType);

        return getPageTitle(`${region}${offerTypeString}${dealTypeString} ${region ? "" : upperFirst(getCountryName(country))}`, page);
    }
    if (offerListSubFilter && props.type === OfferType.FLAT && isOfferListSubTypeValidFloorChoice(offerListSubFilter)) {
        const floorChoice = getFloorChoiceByOfferListSubType(offerListSubFilter);
        const floorText = getFloorChoiceText(floorChoice);
        return concatWithBrandName(`Mieszkania ${floorText} ${region} -`);
    }

    if (offerListSubFilter && props.type === OfferType.HOUSE && isOfferListSubTypeValidHouseFilter(offerListSubFilter)) {
        const houseFilterText = getHouseFilterMetaText(offerListSubFilter);
        return concatWithBrandName(`${searchFilterPrefix} domy ${houseFilterText} ${region} -`);
    }

    return getPageTitle(`${upperFirst(searchFilterPrefix)}${searchPhrase}`, page);
};

export const titleOfferListMap = (props: ITitleOfferList) => {
    return getPageTitle(`Mapa inwestycji ${props.regionName || ""} - ${offerMapSearchPhraseBuilder(props)}`);
};
