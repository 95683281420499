import {ranking2023} from "../assets/ranking_2023";

export function getRankingItems(regionId: number) {
    return ranking2023.filter((cityRecord) => cityRecord.regionId === regionId);
}
import {ranking2023} from "../assets/ranking_2023";

export function getRankingItems(regionId: number) {
    return ranking2023.filter((cityRecord) => cityRecord.regionId === regionId);
}
