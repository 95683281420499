import {breakpoints} from "./base_breakpoints";

// TODO: After all DS rewrite into CSS modules audit below values in terms of actual use
export const grid = {
    sm: {
        gutter: 16,
        gutterPx: "16px",
        columns: 4,
        offset: 12,
        offsetPx: "12px",
        value: 0,
        width: breakpoints.numeric.sm,
        widthPx: breakpoints.sm
    },
    md: {
        gutter: 24,
        gutterPx: "24px",
        columns: 12,
        offset: 20,
        offsetPx: "20px",
        value: breakpoints.numeric.md,
        width: 1192,
        widthPx: "1192px"
    },
    lg: {
        gutter: 24,
        gutterPx: "24px",
        columns: 16,
        offset: 20,
        offsetPx: "20px",
        value: breakpoints.numeric.lg,
        width: 1576,
        widthPx: "1576px"
    }
};
import {breakpoints} from "./base_breakpoints";

// TODO: After all DS rewrite into CSS modules audit below values in terms of actual use
export const grid = {
    sm: {
        gutter: 16,
        gutterPx: "16px",
        columns: 4,
        offset: 12,
        offsetPx: "12px",
        value: 0,
        width: breakpoints.numeric.sm,
        widthPx: breakpoints.sm
    },
    md: {
        gutter: 24,
        gutterPx: "24px",
        columns: 12,
        offset: 20,
        offsetPx: "20px",
        value: breakpoints.numeric.md,
        width: 1192,
        widthPx: "1192px"
    },
    lg: {
        gutter: 24,
        gutterPx: "24px",
        columns: 16,
        offset: 20,
        offsetPx: "20px",
        value: breakpoints.numeric.lg,
        width: 1576,
        widthPx: "1576px"
    }
};
