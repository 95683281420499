import {useSelector} from "react-redux";

import {IRPStore} from "../../../app/rp_reducer";
import {InvestmentOfferTypeSlug} from "../../../investment_offer/constants/investment_offer_constants";
import {ViewType} from "../../../view_type/ViewType";

export const useOfferListingAbroadView = (): boolean => {
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const investmentCategory = useSelector((state: IRPStore) => state.investmentOffer?.categories?.category);
    if (investmentCategory) {
        return investmentCategory === InvestmentOfferTypeSlug.ABROAD;
    }
    return !!viewType.current && [ViewType.OFFER_LIST_ABROAD, ViewType.OFFER_LIST_MAP_ABROAD].includes(viewType.current);
};
import {useSelector} from "react-redux";

import {IRPStore} from "../../../app/rp_reducer";
import {InvestmentOfferTypeSlug} from "../../../investment_offer/constants/investment_offer_constants";
import {ViewType} from "../../../view_type/ViewType";

export const useOfferListingAbroadView = (): boolean => {
    const viewType = useSelector((state: IRPStore) => state.viewType);
    const investmentCategory = useSelector((state: IRPStore) => state.investmentOffer?.categories?.category);
    if (investmentCategory) {
        return investmentCategory === InvestmentOfferTypeSlug.ABROAD;
    }
    return !!viewType.current && [ViewType.OFFER_LIST_ABROAD, ViewType.OFFER_LIST_MAP_ABROAD].includes(viewType.current);
};
