import {Dispatch} from "redux";

import {catch5xx, catch400, catch403, getExternalRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {RequestState} from "@pg-mono/request-state";
import {userPreferencesApiLink} from "@pg-mono/services-api-routes";

import {msStoreJsServerApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IUserPreferences} from "../interfaces/IUserPreferences";

const FETCH_USER_PREFERENCES = "user/FETCH_PREFERENCES";
export const fetchUserPreferencesTypes = createRequestActionTypes(FETCH_USER_PREFERENCES);

const createFetchUserPreferences = (forceFetch = false) => {
    return (meta: IRPRequestMeta) =>
        async (dispatch: Dispatch, getState: () => IRPStore): Promise<IUserPreferences | null> => {
            const userPreferences = getState().user.preferences;

            if (!forceFetch && userPreferences?.requestState !== RequestState.None) {
                return userPreferences?.data || {};
            }

            // continue with preferences fetch
            dispatch({type: fetchUserPreferencesTypes.start});

            const url = userPreferencesApiLink.userPreferences.base(msStoreJsServerApiUrl);
            return getExternalRequest(meta, url, 5000)
                .then((userPreferences: {filters: Record<string, number | number[]>} | null) => {
                    const result = userPreferences || null;

                    dispatch({
                        type: fetchUserPreferencesTypes.success,
                        result: userPreferences
                    });

                    return result;
                })
                .catch(
                    catch400((error) => {
                        dispatch({type: fetchUserPreferencesTypes.error, error: error.appError});

                        return null;
                    })
                )
                .catch(
                    catch403(() => {
                        dispatch({type: fetchUserPreferencesTypes.reset});

                        return null;
                    })
                )
                .catch(
                    catch5xx(() => {
                        dispatch({type: fetchUserPreferencesTypes.reset});

                        return null;
                    })
                );
        };
};

export const fetchUserPreferences = createFetchUserPreferences(false);
export const forceFetchUserPreferences = createFetchUserPreferences(true);
import {Dispatch} from "redux";

import {catch5xx, catch400, catch403, getExternalRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {RequestState} from "@pg-mono/request-state";
import {userPreferencesApiLink} from "@pg-mono/services-api-routes";

import {msStoreJsServerApiUrl} from "../../app/read_rp_environment_variables";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IUserPreferences} from "../interfaces/IUserPreferences";

const FETCH_USER_PREFERENCES = "user/FETCH_PREFERENCES";
export const fetchUserPreferencesTypes = createRequestActionTypes(FETCH_USER_PREFERENCES);

const createFetchUserPreferences = (forceFetch = false) => {
    return (meta: IRPRequestMeta) =>
        async (dispatch: Dispatch, getState: () => IRPStore): Promise<IUserPreferences | null> => {
            const userPreferences = getState().user.preferences;

            if (!forceFetch && userPreferences?.requestState !== RequestState.None) {
                return userPreferences?.data || {};
            }

            // continue with preferences fetch
            dispatch({type: fetchUserPreferencesTypes.start});

            const url = userPreferencesApiLink.userPreferences.base(msStoreJsServerApiUrl);
            return getExternalRequest(meta, url, 5000)
                .then((userPreferences: {filters: Record<string, number | number[]>} | null) => {
                    const result = userPreferences || null;

                    dispatch({
                        type: fetchUserPreferencesTypes.success,
                        result: userPreferences
                    });

                    return result;
                })
                .catch(
                    catch400((error) => {
                        dispatch({type: fetchUserPreferencesTypes.error, error: error.appError});

                        return null;
                    })
                )
                .catch(
                    catch403(() => {
                        dispatch({type: fetchUserPreferencesTypes.reset});

                        return null;
                    })
                )
                .catch(
                    catch5xx(() => {
                        dispatch({type: fetchUserPreferencesTypes.reset});

                        return null;
                    })
                );
        };
};

export const fetchUserPreferences = createFetchUserPreferences(false);
export const forceFetchUserPreferences = createFetchUserPreferences(true);
