import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";
import {omit} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";
import {removeRedundantWhiteSpaces} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferListMap} from "../../../meta_data/page_description/offer_list";
import {titleOfferListMap} from "../../../meta_data/page_title/offer_list";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {trimAggregationFromRegion} from "../../../meta_data/utils/trim_aggregation_from_region";
import {Country} from "../../../region/types/Country";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {SPECIAL_LISTING} from "../../list/constants/offer_list";
import {SearchSort, SearchSortString} from "../../list/constants/SearchSort";
import {getCountryId} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {OfferType} from "../../types/OfferType";

interface IGetCustomCanonicalPathForRegionsParams {
    regions: IRPStore["offerListMap"]["selectedRegion"];
    type: number | undefined;
    sort: SearchSortString | undefined;
    is_luxury: IFriendlyQuery["is_luxury"];
    price_lower_than_average: IFriendlyQuery["price_lower_than_average"];
    construction_end_date: IFriendlyQuery["construction_end_date"] | undefined;
}

const getCustomCanonicalPathForRegions = ({
    is_luxury,
    price_lower_than_average,
    construction_end_date,
    regions,
    sort,
    type
}: IGetCustomCanonicalPathForRegionsParams) =>
    rpAppLink.offer.mapFriendly({
        friendlySlug: offerUrlBuilder({
            sort: sort !== undefined ? parseInt(sort, 10) : SearchSort.DEFAULT,
            region: regions[0].slug,
            type,
            is_luxury,
            price_lower_than_average,
            construction_end_date
        }).friendlySlug
    });

export const updateOfferListMapMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const pathname = ctx.route.pathname;
    const state = getState();
    const selectedRegion = state.offerListMap.selectedRegion;
    const {latestQuery, propertiesCount} = getState().offerListMap.list;
    const regions = selectedRegion.length > 0 ? selectedRegion : undefined;
    const data = {
        ...pick(latestQuery, [
            "type",
            "area_0",
            "area_1",
            "rooms_0",
            "rooms_1",
            "price_0",
            "price_1",
            "sort",
            "is_luxury",
            "is_holiday",
            "price_lower_than_average",
            "construction_end_date",
            "country"
        ])
    };

    const convertedQuery = {
        is_luxury: Boolean(data.is_luxury),
        is_holiday: Boolean(data.is_holiday),
        price_lower_than_average: Boolean(data.price_lower_than_average),
        holiday_location: data.is_holiday,
        type: parseQueryParamToInt(data.type),
        construction_end_date: data.construction_end_date,
        country: latestQuery.country
    };

    // right now we do not set unique meta data
    const title = removeRedundantWhiteSpaces(
        titleOfferListMap({
            regionName: regions && generateMetaRegionName(regions[0]),
            ...data,
            price_lower_than_average: convertedQuery.price_lower_than_average,
            type: convertedQuery.type,
            is_luxury: convertedQuery.is_luxury,
            is_holiday: convertedQuery.is_holiday
        })
    );

    const description = removeRedundantWhiteSpaces(
        descriptionOfferListMap({
            regions,
            is_luxury: convertedQuery.is_luxury,
            price_lower_than_average: convertedQuery.price_lower_than_average,
            sort: data.sort,
            propertiesCount: propertiesCount,
            offerType: convertedQuery.type
        })
    );

    const alternate = `${rpCommonUrl}${pathname}`;
    const customCanonicalPath = regions
        ? trimAggregationFromRegion(
              getCustomCanonicalPathForRegions({
                  regions,
                  type: convertedQuery.type,
                  sort: data.sort,
                  is_luxury: convertedQuery.is_luxury,
                  price_lower_than_average: convertedQuery.price_lower_than_average,
                  construction_end_date: convertedQuery.construction_end_date
              })
          )
        : rpAppLink.offer.map();

    const hasExtraFilters = Boolean(Object.keys(omit(data, ["type"])).length);
    const isCommercialType = !!convertedQuery.type && convertedQuery.type === OfferType.COMMERCIAL;
    const isSpecialListing = SPECIAL_LISTING.some((el) => pathname.includes(el));
    const isAbroadRegions = regions && getCountryId(latestQuery.country) !== Country.POLAND;
    const robots =
        propertiesCount === 0 || isAbroadRegions ? "noindex, follow" : hasExtraFilters || isCommercialType || isSpecialListing ? "noindex" : "index, follow";

    const updateMetaDataOptions = regions
        ? {
              alternate: alternate,
              customCanonicalPath,
              robots: robots
          }
        : {alternate: alternate, robots: robots};

    dispatch(updateMetaData({title, description, pathname, ...updateMetaDataOptions}));
    return true;
};
import {IFetchContext} from "@pg-mono/data-fetcher";
import {pick} from "@pg-mono/nodash";
import {omit} from "@pg-mono/nodash";
import {rpAppLink} from "@pg-mono/rp-routes";
import {removeRedundantWhiteSpaces} from "@pg-mono/string-utils";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {descriptionOfferListMap} from "../../../meta_data/page_description/offer_list";
import {titleOfferListMap} from "../../../meta_data/page_title/offer_list";
import {generateMetaRegionName} from "../../../meta_data/utils/generate_meta_region_name";
import {trimAggregationFromRegion} from "../../../meta_data/utils/trim_aggregation_from_region";
import {Country} from "../../../region/types/Country";
import {parseQueryParamToInt} from "../../../utils/latest_query";
import {offerUrlBuilder} from "../../helpers/friendly_offer_list/offer_url_builder";
import {SPECIAL_LISTING} from "../../list/constants/offer_list";
import {SearchSort, SearchSortString} from "../../list/constants/SearchSort";
import {getCountryId} from "../../list/helpers/sub_type/get_fetch_offer_list_query";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {OfferType} from "../../types/OfferType";

interface IGetCustomCanonicalPathForRegionsParams {
    regions: IRPStore["offerListMap"]["selectedRegion"];
    type: number | undefined;
    sort: SearchSortString | undefined;
    is_luxury: IFriendlyQuery["is_luxury"];
    price_lower_than_average: IFriendlyQuery["price_lower_than_average"];
    construction_end_date: IFriendlyQuery["construction_end_date"] | undefined;
}

const getCustomCanonicalPathForRegions = ({
    is_luxury,
    price_lower_than_average,
    construction_end_date,
    regions,
    sort,
    type
}: IGetCustomCanonicalPathForRegionsParams) =>
    rpAppLink.offer.mapFriendly({
        friendlySlug: offerUrlBuilder({
            sort: sort !== undefined ? parseInt(sort, 10) : SearchSort.DEFAULT,
            region: regions[0].slug,
            type,
            is_luxury,
            price_lower_than_average,
            construction_end_date
        }).friendlySlug
    });

export const updateOfferListMapMetaData = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const pathname = ctx.route.pathname;
    const state = getState();
    const selectedRegion = state.offerListMap.selectedRegion;
    const {latestQuery, propertiesCount} = getState().offerListMap.list;
    const regions = selectedRegion.length > 0 ? selectedRegion : undefined;
    const data = {
        ...pick(latestQuery, [
            "type",
            "area_0",
            "area_1",
            "rooms_0",
            "rooms_1",
            "price_0",
            "price_1",
            "sort",
            "is_luxury",
            "is_holiday",
            "price_lower_than_average",
            "construction_end_date",
            "country"
        ])
    };

    const convertedQuery = {
        is_luxury: Boolean(data.is_luxury),
        is_holiday: Boolean(data.is_holiday),
        price_lower_than_average: Boolean(data.price_lower_than_average),
        holiday_location: data.is_holiday,
        type: parseQueryParamToInt(data.type),
        construction_end_date: data.construction_end_date,
        country: latestQuery.country
    };

    // right now we do not set unique meta data
    const title = removeRedundantWhiteSpaces(
        titleOfferListMap({
            regionName: regions && generateMetaRegionName(regions[0]),
            ...data,
            price_lower_than_average: convertedQuery.price_lower_than_average,
            type: convertedQuery.type,
            is_luxury: convertedQuery.is_luxury,
            is_holiday: convertedQuery.is_holiday
        })
    );

    const description = removeRedundantWhiteSpaces(
        descriptionOfferListMap({
            regions,
            is_luxury: convertedQuery.is_luxury,
            price_lower_than_average: convertedQuery.price_lower_than_average,
            sort: data.sort,
            propertiesCount: propertiesCount,
            offerType: convertedQuery.type
        })
    );

    const alternate = `${rpCommonUrl}${pathname}`;
    const customCanonicalPath = regions
        ? trimAggregationFromRegion(
              getCustomCanonicalPathForRegions({
                  regions,
                  type: convertedQuery.type,
                  sort: data.sort,
                  is_luxury: convertedQuery.is_luxury,
                  price_lower_than_average: convertedQuery.price_lower_than_average,
                  construction_end_date: convertedQuery.construction_end_date
              })
          )
        : rpAppLink.offer.map();

    const hasExtraFilters = Boolean(Object.keys(omit(data, ["type"])).length);
    const isCommercialType = !!convertedQuery.type && convertedQuery.type === OfferType.COMMERCIAL;
    const isSpecialListing = SPECIAL_LISTING.some((el) => pathname.includes(el));
    const isAbroadRegions = regions && getCountryId(latestQuery.country) !== Country.POLAND;
    const robots =
        propertiesCount === 0 || isAbroadRegions ? "noindex, follow" : hasExtraFilters || isCommercialType || isSpecialListing ? "noindex" : "index, follow";

    const updateMetaDataOptions = regions
        ? {
              alternate: alternate,
              customCanonicalPath,
              robots: robots
          }
        : {alternate: alternate, robots: robots};

    dispatch(updateMetaData({title, description, pathname, ...updateMetaDataOptions}));
    return true;
};
