export enum FormFieldType {
    // deprecated component-based FormFieldTypes
    Checkbox = "checkbox",
    MultiCheckbox = "multi-checkbox",
    Input = "input",
    InputRange = "input-range",
    MultiSelect = "multiselect",
    Radio = "radio",
    Select = "select",
    SelectString = "select-string",
    SelectRange = "select-range",
    Textarea = "textarea",
    SelectRangeNumber = "select-range-number",
    PhoneField = "phone-field",
    Rooms = "rooms",
    // special-case-based FormFieldTypes
    SearchOfferType = "search-offer-type"
}

export type FormFieldClassMap = Record<string, FormFieldType>;

export type IFormValues = Record<string, unknown>;
export enum FormFieldType {
    // deprecated component-based FormFieldTypes
    Checkbox = "checkbox",
    MultiCheckbox = "multi-checkbox",
    Input = "input",
    InputRange = "input-range",
    MultiSelect = "multiselect",
    Radio = "radio",
    Select = "select",
    SelectString = "select-string",
    SelectRange = "select-range",
    Textarea = "textarea",
    SelectRangeNumber = "select-range-number",
    PhoneField = "phone-field",
    Rooms = "rooms",
    // special-case-based FormFieldTypes
    SearchOfferType = "search-offer-type"
}

export type FormFieldClassMap = Record<string, FormFieldType>;

export type IFormValues = Record<string, unknown>;
