import {IFetchContext} from "@pg-mono/data-fetcher";
import {ISmsCampaignParams} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getPageTitle} from "../../meta_data/utils/get_page_title";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getCampaignDetails} from "../api/get_campaign_details";
import {campaignConstraints} from "../constants/campaign_constraints";
import {ICampaignQuery} from "../types/ICampaignQuery";

export const smsCampaignAction = (ctx: IFetchContext<IRPRequestMeta, ISmsCampaignParams>) => async (dispatch: IAppDispatch) => {
    const {campaignUuid, subscriberUuid} = ctx.match.params;

    // Data
    const getCampaignQuery: ICampaignQuery = campaignConstraints;

    if (subscriberUuid) {
        getCampaignQuery.subscriber_uuid = subscriberUuid;
    }

    try {
        const campaign = await dispatch(
            getCampaignDetails.endpoints.getCampaignDetails.initiate({
                campaignUuid,
                ...getCampaignQuery
            })
        ).unwrap();

        // Meta
        const offerName = (campaign && campaign.offer.name) || "";
        dispatch(
            updateMetaData({
                title: getPageTitle(`Zapraszamy na dni otwarte - ${offerName}`),
                description:
                    "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty " +
                    "z dopłatą MDM, aktualne promocje i przeceny, kalendarz dni otwartych.",
                pathname: ctx.route.pathname
            })
        );
    } catch (error) {
        if (isRtkqError(error) && error.status === 404) {
            await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            return false;
        }
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
import {IFetchContext} from "@pg-mono/data-fetcher";
import {ISmsCampaignParams} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";
import {getPageTitle} from "../../meta_data/utils/get_page_title";
import {IAppDispatch} from "../../store/store";
import {isRtkqError} from "../../store/utils/is_rtkq_error";
import {getCampaignDetails} from "../api/get_campaign_details";
import {campaignConstraints} from "../constants/campaign_constraints";
import {ICampaignQuery} from "../types/ICampaignQuery";

export const smsCampaignAction = (ctx: IFetchContext<IRPRequestMeta, ISmsCampaignParams>) => async (dispatch: IAppDispatch) => {
    const {campaignUuid, subscriberUuid} = ctx.match.params;

    // Data
    const getCampaignQuery: ICampaignQuery = campaignConstraints;

    if (subscriberUuid) {
        getCampaignQuery.subscriber_uuid = subscriberUuid;
    }

    try {
        const campaign = await dispatch(
            getCampaignDetails.endpoints.getCampaignDetails.initiate({
                campaignUuid,
                ...getCampaignQuery
            })
        ).unwrap();

        // Meta
        const offerName = (campaign && campaign.offer.name) || "";
        dispatch(
            updateMetaData({
                title: getPageTitle(`Zapraszamy na dni otwarte - ${offerName}`),
                description:
                    "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty " +
                    "z dopłatą MDM, aktualne promocje i przeceny, kalendarz dni otwartych.",
                pathname: ctx.route.pathname
            })
        );
    } catch (error) {
        if (isRtkqError(error) && error.status === 404) {
            await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            return false;
        }
    }

    await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));
};
