import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {SITE_MAP_OFFER_LIST_PAGE_SIZE} from "../fetch_site_map_offer";
import {ISitemapRegionListRegion} from "../fetch_site_map_region";

export const updateSiteMapOfferVoivodeshipMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {voivodeshipSlug} = ctx.match.params;
    const {count} = getState().siteMap.offers;

    const voivodeship = getState().siteMap.regions.voivodeship.find((v) => v.slug === voivodeshipSlug) as ISitemapRegionListRegion;
    const title = `Mapa strony - Oferty - ${concatWithBrandName(voivodeship ? voivodeship.name + " - " : "")}`;
    const description = `Mapa strony - dodatkowa nawigacja dla kategorii Oferty${voivodeship ? " - " + voivodeship.name : ""}`;

    let page = parseInt(ctx.route.query.page as string, 10);
    page = isNaN(page) ? 1 : page;
    const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
    const prev = (() => {
        if (page === 1) {
            return undefined;
        }
        if (page === 2) {
            return hostAndPathname;
        }
        return appendQueryString(hostAndPathname, {...ctx.route.query, page: page - 1});
    })();
    const next = page * SITE_MAP_OFFER_LIST_PAGE_SIZE < count ? appendQueryString(hostAndPathname, {...ctx.route.query, page: page + 1}) : undefined;

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname, prev, next, pageNumber: page}));
    return Promise.resolve();
};
import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";

import {rpCommonUrl} from "../../../app/read_rp_environment_variables";
import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {concatWithBrandName} from "../../../app/utils/concat_with_brand_name";
import {MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {SITE_MAP_OFFER_LIST_PAGE_SIZE} from "../fetch_site_map_offer";
import {ISitemapRegionListRegion} from "../fetch_site_map_region";

export const updateSiteMapOfferVoivodeshipMetaData = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const {voivodeshipSlug} = ctx.match.params;
    const {count} = getState().siteMap.offers;

    const voivodeship = getState().siteMap.regions.voivodeship.find((v) => v.slug === voivodeshipSlug) as ISitemapRegionListRegion;
    const title = `Mapa strony - Oferty - ${concatWithBrandName(voivodeship ? voivodeship.name + " - " : "")}`;
    const description = `Mapa strony - dodatkowa nawigacja dla kategorii Oferty${voivodeship ? " - " + voivodeship.name : ""}`;

    let page = parseInt(ctx.route.query.page as string, 10);
    page = isNaN(page) ? 1 : page;
    const hostAndPathname = `${rpCommonUrl}${ctx.route.pathname}`;
    const prev = (() => {
        if (page === 1) {
            return undefined;
        }
        if (page === 2) {
            return hostAndPathname;
        }
        return appendQueryString(hostAndPathname, {...ctx.route.query, page: page - 1});
    })();
    const next = page * SITE_MAP_OFFER_LIST_PAGE_SIZE < count ? appendQueryString(hostAndPathname, {...ctx.route.query, page: page + 1}) : undefined;

    dispatch(updateMetaData({title, description, pathname: ctx.route.pathname, prev, next, pageNumber: page}));
    return Promise.resolve();
};
