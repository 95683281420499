import {Dispatch} from "redux";

import {getCookie} from "@pg-mono/request";

import {getOfferDisplayTypeNameGTM} from "../common/offer/helpers/OfferDisplayType";
import {IOfferDetail} from "../common/offer/types/IOfferDetail";
import {IPropertyDetailOffer} from "../common/property/types/IPropertyDetailOffer";
import {IRootState} from "../common/store/store";
import {setGTMInit} from "../common/tracking/google_tag_manager/actions/initialize_google_tag_manager_actions";
import {configGoogleTagManager, initGoogleTagManager} from "../common/tracking/google_tag_manager/init_google_tag_manager";

export const rpGtmInit = (state: IRootState, dispatch: Dispatch): void => {
    const viewType = state.viewType.current;
    const regionName = state.viewType.currentData.contentRegion ?? null;
    const vendorName = state.viewType.currentData.contentDeveloperName ?? null;
    const blogCategory = state.viewType.currentData.blogCategory ?? null;
    const userId = state.user.profile.data?.uuid ?? null;
    const hasPageProjection2d = state.globals.hasPageProjection2d;

    const gaCookie = getCookie("_ga");
    const userGaId = gaCookie ? gaCookie.split(".").slice(2).join(".") : null;
    //businessStatus
    const configurationDisplayType = state.offerDetail.offer?.configuration.display_type || state.property.property?.offer?.configuration.display_type;
    const businessStatus = configurationDisplayType ? getOfferDisplayTypeNameGTM(configurationDisplayType) : null;
    //investorPotential
    const offerDetails = state.offerDetail.offer || state.property.property?.offer;
    const investorPotential = offerDetails ? setInvestorPotential(offerDetails) : null;

    configGoogleTagManager({viewType, regionName, vendorName, userId, userGaId, blogCategory, businessStatus, investorPotential, hasPageProjection2d});
    initGoogleTagManager();
    dispatch(setGTMInit()); // set gtmInitialized in store
};

/**
 * Helpers
 */

function setInvestorPotential(offer: IOfferDetail | IPropertyDetailOffer): string {
    if (offer.is_condohotel) {
        return "condohotel";
    } else if (offer.is_holiday_location) {
        return "apartamenty wakacyjne";
    } else if ((offer.stats?.properties_with_roi_count ?? 0) > 0) {
        return "pod wynajem";
    } else {
        return "na";
    }
}
import {Dispatch} from "redux";

import {getCookie} from "@pg-mono/request";

import {getOfferDisplayTypeNameGTM} from "../common/offer/helpers/OfferDisplayType";
import {IOfferDetail} from "../common/offer/types/IOfferDetail";
import {IPropertyDetailOffer} from "../common/property/types/IPropertyDetailOffer";
import {IRootState} from "../common/store/store";
import {setGTMInit} from "../common/tracking/google_tag_manager/actions/initialize_google_tag_manager_actions";
import {configGoogleTagManager, initGoogleTagManager} from "../common/tracking/google_tag_manager/init_google_tag_manager";

export const rpGtmInit = (state: IRootState, dispatch: Dispatch): void => {
    const viewType = state.viewType.current;
    const regionName = state.viewType.currentData.contentRegion ?? null;
    const vendorName = state.viewType.currentData.contentDeveloperName ?? null;
    const blogCategory = state.viewType.currentData.blogCategory ?? null;
    const userId = state.user.profile.data?.uuid ?? null;
    const hasPageProjection2d = state.globals.hasPageProjection2d;

    const gaCookie = getCookie("_ga");
    const userGaId = gaCookie ? gaCookie.split(".").slice(2).join(".") : null;
    //businessStatus
    const configurationDisplayType = state.offerDetail.offer?.configuration.display_type || state.property.property?.offer?.configuration.display_type;
    const businessStatus = configurationDisplayType ? getOfferDisplayTypeNameGTM(configurationDisplayType) : null;
    //investorPotential
    const offerDetails = state.offerDetail.offer || state.property.property?.offer;
    const investorPotential = offerDetails ? setInvestorPotential(offerDetails) : null;

    configGoogleTagManager({viewType, regionName, vendorName, userId, userGaId, blogCategory, businessStatus, investorPotential, hasPageProjection2d});
    initGoogleTagManager();
    dispatch(setGTMInit()); // set gtmInitialized in store
};

/**
 * Helpers
 */

function setInvestorPotential(offer: IOfferDetail | IPropertyDetailOffer): string {
    if (offer.is_condohotel) {
        return "condohotel";
    } else if (offer.is_holiday_location) {
        return "apartamenty wakacyjne";
    } else if ((offer.stats?.properties_with_roi_count ?? 0) > 0) {
        return "pod wynajem";
    } else {
        return "na";
    }
}
