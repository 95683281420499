import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {OfferType} from "../../types/OfferType";
import {DEFAULT_DISTANCE, MAX_SOLD_OFFERS_PAGE, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {IOfferListResponse} from "../types/IOfferListResponse";

const SOLD_OFFER_LIST = "offer_list/SOLD_OFFER_LIST";
export const fetchSoldOfferListTypes = createRequestActionTypes(SOLD_OFFER_LIST);

export const fetchSoldOfferList =
    (
        ctx: IFetchContext<IRPRequestMeta>,
        originalPage: number,
        archivedQuery: Record<string, string | string[] | number | null>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offersCount: number
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchSoldOfferListTypes.start});
        // prepare full-query

        const offerListSoldLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

        const fullQuery: Record<string, string | number | number[] | boolean> = {
            distance: DEFAULT_DISTANCE,
            ...archivedQuery,
            ...(archivedQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {}),
            limited_presentation: true,
            page_size: OFFER_LIST_PAGE_SIZE,
            sort: "update_date"
        };
        // define url and start fetch
        const url = appendQueryString(offerListSoldLink, fullQuery);
        return getRequest(ctx.meta, url)
            .then(async (json: IOfferListResponse) => {
                const result = {
                    offers: json.results,
                    page: fullQuery.page,
                    count: Math.min(json.count, MAX_SOLD_OFFERS_PAGE * OFFER_LIST_PAGE_SIZE) // we display only limited number of pages
                };
                dispatch({type: fetchSoldOfferListTypes.success, result});
                return json;
            })
            .catch(
                catch400((error) => {
                    // we do not expect to receive 400 at this point because previous list succeeded
                    dispatch({type: fetchSoldOfferListTypes.error, error: error.appError});
                })
            )
            .catch(
                catch404(async () => {
                    // standard 404
                    if (originalPage > 1) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const {page, ...restQuery} = ctx.route.query;
                        const firstPageUrl = appendQueryString(ctx.route.pathname, restQuery);
                        dispatch(enable301ResponseState(firstPageUrl));
                    } else {
                        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                    }
                })
            );
    };

export const resetSoldOfferList = () => ({type: fetchSoldOfferListTypes.reset});
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch400, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../../errors/actions/page_404_actions";
import {OfferType} from "../../types/OfferType";
import {DEFAULT_DISTANCE, MAX_SOLD_OFFERS_PAGE, OFFER_LIST_PAGE_SIZE} from "../constants/offer_list";
import {IOfferListResponse} from "../types/IOfferListResponse";

const SOLD_OFFER_LIST = "offer_list/SOLD_OFFER_LIST";
export const fetchSoldOfferListTypes = createRequestActionTypes(SOLD_OFFER_LIST);

export const fetchSoldOfferList =
    (
        ctx: IFetchContext<IRPRequestMeta>,
        originalPage: number,
        archivedQuery: Record<string, string | string[] | number | null>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offersCount: number
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({type: fetchSoldOfferListTypes.start});
        // prepare full-query

        const offerListSoldLink = apiV2ListLink.offer.list(Scenario.OFFER_LIST);

        const fullQuery: Record<string, string | number | number[] | boolean> = {
            distance: DEFAULT_DISTANCE,
            ...archivedQuery,
            ...(archivedQuery.type == null ? {type: [OfferType.FLAT, OfferType.HOUSE]} : {}),
            limited_presentation: true,
            page_size: OFFER_LIST_PAGE_SIZE,
            sort: "update_date"
        };
        // define url and start fetch
        const url = appendQueryString(offerListSoldLink, fullQuery);
        return getRequest(ctx.meta, url)
            .then(async (json: IOfferListResponse) => {
                const result = {
                    offers: json.results,
                    page: fullQuery.page,
                    count: Math.min(json.count, MAX_SOLD_OFFERS_PAGE * OFFER_LIST_PAGE_SIZE) // we display only limited number of pages
                };
                dispatch({type: fetchSoldOfferListTypes.success, result});
                return json;
            })
            .catch(
                catch400((error) => {
                    // we do not expect to receive 400 at this point because previous list succeeded
                    dispatch({type: fetchSoldOfferListTypes.error, error: error.appError});
                })
            )
            .catch(
                catch404(async () => {
                    // standard 404
                    if (originalPage > 1) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const {page, ...restQuery} = ctx.route.query;
                        const firstPageUrl = appendQueryString(ctx.route.pathname, restQuery);
                        dispatch(enable301ResponseState(firstPageUrl));
                    } else {
                        await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
                    }
                })
            );
    };

export const resetSoldOfferList = () => ({type: fetchSoldOfferListTypes.reset});
