/* eslint-disable @typescript-eslint/no-explicit-any */
//  TODO: Work on better types and implementation to avoid any

export function isEqual(x: any, y: any): boolean {
    const ok = Object.keys;

    return x && y && typeof x === "object" && typeof y === "object" ? ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key])) : x === y;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
//  TODO: Work on better types and implementation to avoid any

export function isEqual(x: any, y: any): boolean {
    const ok = Object.keys;

    return x && y && typeof x === "object" && typeof y === "object" ? ok(x).length === ok(y).length && ok(x).every((key) => isEqual(x[key], y[key])) : x === y;
}
