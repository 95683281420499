import type {BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {osmApiUrl} from "../read_rp_environment_variables";
import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const osmBaseQuery = (api: BaseQueryApi) =>
    fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api)
    });

const osmServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;

    const baseUrl = osmApiUrl;
    const adjustedArgs = typeof args === "string" ? `${baseUrl}${endpointUrl}` : {...args, url: `${baseUrl}${endpointUrl}`};

    return osmBaseQuery(api)(adjustedArgs, api, extraOptions);
};

export const osmApi = createApi({
    reducerPath: "osmApi",
    baseQuery: osmServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: []
});
import type {BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import qs from "query-string";

import {osmApiUrl} from "../read_rp_environment_variables";
import {appendCacheMissHeader} from "../utils/append_cache_miss_header";
import {fetchWithServices} from "./fetch_with_services";

const osmBaseQuery = (api: BaseQueryApi) =>
    fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithServices(api)
    });

const osmServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;

    const baseUrl = osmApiUrl;
    const adjustedArgs = typeof args === "string" ? `${baseUrl}${endpointUrl}` : {...args, url: `${baseUrl}${endpointUrl}`};

    return osmBaseQuery(api)(adjustedArgs, api, extraOptions);
};

export const osmApi = createApi({
    reducerPath: "osmApi",
    baseQuery: osmServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: []
});
