import * as React from "react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import {Plugin} from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {bugsnagPerformanceKey, bugsnagReleaseStage} from "../../app/read_rp_environment_variables";
import {enabledBugsnagReleaseStages} from "./bugsnag_stages";

const BUGSNAG_CLIENT_KEY = "8697cfa4e4ae0e1d21c0d00bc3cddf51";

export const initBrowserBugsnag = () => {
    Bugsnag.start({
        apiKey: BUGSNAG_CLIENT_KEY,
        releaseStage: bugsnagReleaseStage,
        enabledReleaseStages: enabledBugsnagReleaseStages,
        plugins: [new BugsnagPluginReact() as Plugin]
    });

    if (bugsnagPerformanceKey) {
        BugsnagPerformance.start({
            apiKey: BUGSNAG_CLIENT_KEY,
            releaseStage: bugsnagReleaseStage,
            enabledReleaseStages: enabledBugsnagReleaseStages
        });
    }
};

export const getBugsnagErrorBoundary = () => {
    // BANG: if bugsnag for some reason does not work properly we need to throw an Error
    return Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line @typescript-eslint/no-non-null-assertion
};
import * as React from "react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import {Plugin} from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {bugsnagPerformanceKey, bugsnagReleaseStage} from "../../app/read_rp_environment_variables";
import {enabledBugsnagReleaseStages} from "./bugsnag_stages";

const BUGSNAG_CLIENT_KEY = "8697cfa4e4ae0e1d21c0d00bc3cddf51";

export const initBrowserBugsnag = () => {
    Bugsnag.start({
        apiKey: BUGSNAG_CLIENT_KEY,
        releaseStage: bugsnagReleaseStage,
        enabledReleaseStages: enabledBugsnagReleaseStages,
        plugins: [new BugsnagPluginReact() as Plugin]
    });

    if (bugsnagPerformanceKey) {
        BugsnagPerformance.start({
            apiKey: BUGSNAG_CLIENT_KEY,
            releaseStage: bugsnagReleaseStage,
            enabledReleaseStages: enabledBugsnagReleaseStages
        });
    }
};

export const getBugsnagErrorBoundary = () => {
    // BANG: if bugsnag for some reason does not work properly we need to throw an Error
    return Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line @typescript-eslint/no-non-null-assertion
};
