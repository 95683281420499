import {hitAlgolytics} from "@pg-mono/algolytics";

export enum CoordinatesSourceType {
    LOCATION = 1,
    IP = 2
}

export interface IHitGeolocationEvent {
    latitude: number;
    longitude: number;
    type: CoordinatesSourceType;
    accuracy: number;
}

export const HitGeolocationEvent = (payload: IHitGeolocationEvent) => {
    hitAlgolytics("geolocation", payload);
};
import {hitAlgolytics} from "@pg-mono/algolytics";

export enum CoordinatesSourceType {
    LOCATION = 1,
    IP = 2
}

export interface IHitGeolocationEvent {
    latitude: number;
    longitude: number;
    type: CoordinatesSourceType;
    accuracy: number;
}

export const HitGeolocationEvent = (payload: IHitGeolocationEvent) => {
    hitAlgolytics("geolocation", payload);
};
