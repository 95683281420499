import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";
import {IArticleListArticle} from "./fetch_article_list";
import {IArticleDetailArticle} from "./IArticleDetail";

const RELATED_ARTICLES_PREFIX = "article_detail/fetch_related_articles";
export const fetchRelatedArticlesTypes = createRequestActionTypes(RELATED_ARTICLES_PREFIX);
export const RELATED_ARTICLES_PAGE_SIZE = 5;

interface IRelatedArticleResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}
const articleListApiLink = apiV2ListLink.article.list;

export const fetchRelatedArticlesAtRoute =
    (ctx: IFetchContext<IRPRequestMeta, {}, IArticleDetailArticle>) =>
    async (dispatch: Dispatch): Promise<IArticleListArticle[] | undefined> => {
        const articleCategory = ctx.prevResult.categories[0];
        if (articleCategory == null) {
            return;
        }

        const query: Record<string, IQueryValue> = {
            ...ctx.route.query,
            page: 1,
            page_size: RELATED_ARTICLES_PAGE_SIZE,
            category: articleCategory.id
        };

        dispatch({type: fetchRelatedArticlesTypes.start, latestQuery: query as Record<string, string[]>});

        const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);
        return getRequest(ctx.meta, url).then(async (json: IRelatedArticleResponse) => {
            dispatch({type: fetchRelatedArticlesTypes.success, result: json.results});
            return json.results;
        });
    };
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest, IQueryValue} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";
import {IArticleListArticle} from "./fetch_article_list";
import {IArticleDetailArticle} from "./IArticleDetail";

const RELATED_ARTICLES_PREFIX = "article_detail/fetch_related_articles";
export const fetchRelatedArticlesTypes = createRequestActionTypes(RELATED_ARTICLES_PREFIX);
export const RELATED_ARTICLES_PAGE_SIZE = 5;

interface IRelatedArticleResponse extends IPaginatedResponse {
    results: IArticleListArticle[];
}
const articleListApiLink = apiV2ListLink.article.list;

export const fetchRelatedArticlesAtRoute =
    (ctx: IFetchContext<IRPRequestMeta, {}, IArticleDetailArticle>) =>
    async (dispatch: Dispatch): Promise<IArticleListArticle[] | undefined> => {
        const articleCategory = ctx.prevResult.categories[0];
        if (articleCategory == null) {
            return;
        }

        const query: Record<string, IQueryValue> = {
            ...ctx.route.query,
            page: 1,
            page_size: RELATED_ARTICLES_PAGE_SIZE,
            category: articleCategory.id
        };

        dispatch({type: fetchRelatedArticlesTypes.start, latestQuery: query as Record<string, string[]>});

        const url = appendQueryString(articleListApiLink(Scenario.ARTICLE_LIST), query);
        return getRequest(ctx.meta, url).then(async (json: IRelatedArticleResponse) => {
            dispatch({type: fetchRelatedArticlesTypes.success, result: json.results});
            return json.results;
        });
    };
