import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitLangSwitch(langCode: string) {
    hitGoogleTagManager({
        event: "language_swap",
        language: langCode
    });
}
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitLangSwitch(langCode: string) {
    hitGoogleTagManager({
        event: "language_swap",
        language: langCode
    });
}
