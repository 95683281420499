import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchTestimonialsTypes, ITestimonial} from "../actions/fetch_testimonials";

export interface ITestimonialsStore {
    list: ITestimonial[];
}

export const testimonialsReducer = combineReducers({
    list: reduceResponseField<ITestimonial[]>(fetchTestimonialsTypes, "results", [])
});
import {combineReducers} from "redux";

import {reduceResponseField} from "@pg-mono/request-state";

import {fetchTestimonialsTypes, ITestimonial} from "../actions/fetch_testimonials";

export interface ITestimonialsStore {
    list: ITestimonial[];
}

export const testimonialsReducer = combineReducers({
    list: reduceResponseField<ITestimonial[]>(fetchTestimonialsTypes, "results", [])
});
