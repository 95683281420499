import {PropertyHouseAdditionalAreas} from "../detail/constants/PropertyHouseAdditionalAreas";
import {PropertyHouseStoreys} from "../detail/constants/PropertyHouseStoreys";
import {PropertyHouseType} from "../detail/constants/PropertyHouseType";
import {OfferListSubType} from "../list/types/OfferListSubType";

export const houseFiltersToFriendQuery: {
    house_type: {[key in string]: OfferListSubType};
    house_storeys: {[key in string]: OfferListSubType};
    house_additional_areas: {[key in string]: OfferListSubType};
} = {
    house_type: {
        [PropertyHouseType.SERIAL]: OfferListSubType.HOUSE_TYPE_SERIAL,
        [PropertyHouseType.DETACHED]: OfferListSubType.HOUSE_TYPE_DETACHED,
        [PropertyHouseType.SEMI_DETACHED]: OfferListSubType.HOUSE_TYPE_SEMI_DETACHED,
        [PropertyHouseType.WOODEN]: OfferListSubType.HOUSE_TYPE_WOODEN
    },
    house_storeys: {
        [PropertyHouseStoreys.MULTI_STOREY]: OfferListSubType.HOUSE_STOREYS_MULTI_STOREY,
        [PropertyHouseStoreys.SINGLE_STOREY]: OfferListSubType.HOUSE_STOREYS_SINGLE_STOREY
    },
    house_additional_areas: {
        [PropertyHouseAdditionalAreas.ATTIC]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_ATTIC,
        [PropertyHouseAdditionalAreas.GARAGE]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_GARAGE,
        [PropertyHouseAdditionalAreas.GARDEN]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_GARDEN,
        [PropertyHouseAdditionalAreas.TERRACE]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_TERRACE,
        [PropertyHouseAdditionalAreas.BALCONY]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_BALCONY
    }
};

export const houseFiltersFriendlyQueryValues = Object.values(houseFiltersToFriendQuery).reduce((result, houseFilter) => {
    return [...result, ...Object.values(houseFilter)];
}, [] as string[]);

export const friendlyQueryToHouseFilters: Record<
    string,
    {house_type: PropertyHouseType} | {house_storeys: PropertyHouseStoreys} | {house_additional_areas: PropertyHouseAdditionalAreas}
> = {
    // house_type
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SERIAL]]: {house_type: PropertyHouseType.SERIAL},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.DETACHED]]: {house_type: PropertyHouseType.DETACHED},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SEMI_DETACHED]]: {house_type: PropertyHouseType.SEMI_DETACHED},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.WOODEN]]: {house_type: PropertyHouseType.WOODEN},
    // house_storeys
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.MULTI_STOREY]]: {house_storeys: PropertyHouseStoreys.MULTI_STOREY},
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.SINGLE_STOREY]]: {house_storeys: PropertyHouseStoreys.SINGLE_STOREY},
    // house_additional_areas
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.ATTIC]]: {house_additional_areas: PropertyHouseAdditionalAreas.ATTIC},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARAGE]]: {house_additional_areas: PropertyHouseAdditionalAreas.GARAGE},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARDEN]]: {house_additional_areas: PropertyHouseAdditionalAreas.GARDEN},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.TERRACE]]: {house_additional_areas: PropertyHouseAdditionalAreas.TERRACE},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.BALCONY]]: {house_additional_areas: PropertyHouseAdditionalAreas.BALCONY}
};

export const friendlyQueryToDescription: Record<string, string> = {
    // house_type
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SERIAL]]: "szeregowe",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.DETACHED]]: "wolnostojące",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SEMI_DETACHED]]: "bliźniaki",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.WOODEN]]: "z bali",
    // house_storeys
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.MULTI_STOREY]]: "piętrowe",
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.SINGLE_STOREY]]: "parterowe",
    // house_additional_areas
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.ATTIC]]: "z poddaszem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARAGE]]: "z garażem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARDEN]]: "z ogrodem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.TERRACE]]: "z tarasem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.BALCONY]]: "z balkonem"
};
import {PropertyHouseAdditionalAreas} from "../detail/constants/PropertyHouseAdditionalAreas";
import {PropertyHouseStoreys} from "../detail/constants/PropertyHouseStoreys";
import {PropertyHouseType} from "../detail/constants/PropertyHouseType";
import {OfferListSubType} from "../list/types/OfferListSubType";

export const houseFiltersToFriendQuery: {
    house_type: {[key in string]: OfferListSubType};
    house_storeys: {[key in string]: OfferListSubType};
    house_additional_areas: {[key in string]: OfferListSubType};
} = {
    house_type: {
        [PropertyHouseType.SERIAL]: OfferListSubType.HOUSE_TYPE_SERIAL,
        [PropertyHouseType.DETACHED]: OfferListSubType.HOUSE_TYPE_DETACHED,
        [PropertyHouseType.SEMI_DETACHED]: OfferListSubType.HOUSE_TYPE_SEMI_DETACHED,
        [PropertyHouseType.WOODEN]: OfferListSubType.HOUSE_TYPE_WOODEN
    },
    house_storeys: {
        [PropertyHouseStoreys.MULTI_STOREY]: OfferListSubType.HOUSE_STOREYS_MULTI_STOREY,
        [PropertyHouseStoreys.SINGLE_STOREY]: OfferListSubType.HOUSE_STOREYS_SINGLE_STOREY
    },
    house_additional_areas: {
        [PropertyHouseAdditionalAreas.ATTIC]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_ATTIC,
        [PropertyHouseAdditionalAreas.GARAGE]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_GARAGE,
        [PropertyHouseAdditionalAreas.GARDEN]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_GARDEN,
        [PropertyHouseAdditionalAreas.TERRACE]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_TERRACE,
        [PropertyHouseAdditionalAreas.BALCONY]: OfferListSubType.HOUSE_ADDITIONAL_AREAS_BALCONY
    }
};

export const houseFiltersFriendlyQueryValues = Object.values(houseFiltersToFriendQuery).reduce((result, houseFilter) => {
    return [...result, ...Object.values(houseFilter)];
}, [] as string[]);

export const friendlyQueryToHouseFilters: Record<
    string,
    {house_type: PropertyHouseType} | {house_storeys: PropertyHouseStoreys} | {house_additional_areas: PropertyHouseAdditionalAreas}
> = {
    // house_type
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SERIAL]]: {house_type: PropertyHouseType.SERIAL},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.DETACHED]]: {house_type: PropertyHouseType.DETACHED},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SEMI_DETACHED]]: {house_type: PropertyHouseType.SEMI_DETACHED},
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.WOODEN]]: {house_type: PropertyHouseType.WOODEN},
    // house_storeys
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.MULTI_STOREY]]: {house_storeys: PropertyHouseStoreys.MULTI_STOREY},
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.SINGLE_STOREY]]: {house_storeys: PropertyHouseStoreys.SINGLE_STOREY},
    // house_additional_areas
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.ATTIC]]: {house_additional_areas: PropertyHouseAdditionalAreas.ATTIC},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARAGE]]: {house_additional_areas: PropertyHouseAdditionalAreas.GARAGE},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARDEN]]: {house_additional_areas: PropertyHouseAdditionalAreas.GARDEN},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.TERRACE]]: {house_additional_areas: PropertyHouseAdditionalAreas.TERRACE},
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.BALCONY]]: {house_additional_areas: PropertyHouseAdditionalAreas.BALCONY}
};

export const friendlyQueryToDescription: Record<string, string> = {
    // house_type
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SERIAL]]: "szeregowe",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.DETACHED]]: "wolnostojące",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.SEMI_DETACHED]]: "bliźniaki",
    [houseFiltersToFriendQuery.house_type[PropertyHouseType.WOODEN]]: "z bali",
    // house_storeys
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.MULTI_STOREY]]: "piętrowe",
    [houseFiltersToFriendQuery.house_storeys[PropertyHouseStoreys.SINGLE_STOREY]]: "parterowe",
    // house_additional_areas
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.ATTIC]]: "z poddaszem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARAGE]]: "z garażem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.GARDEN]]: "z ogrodem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.TERRACE]]: "z tarasem",
    [houseFiltersToFriendQuery.house_additional_areas[PropertyHouseAdditionalAreas.BALCONY]]: "z balkonem"
};
