export enum SearchSort {
    DEFAULT = 1,
    LATEST = 2,
    PRICE = 3,
    PRICE_M2 = 4,
    CONSTRUCTION_DATE = 5
}

export enum SearchSortString {
    DEFAULT = "1",
    LATEST = "2",
    PRICE = "3",
    PRICE_M2 = "4",
    CONSTRUCTION_DATE = "5"
}
export enum SearchSort {
    DEFAULT = 1,
    LATEST = 2,
    PRICE = 3,
    PRICE_M2 = 4,
    CONSTRUCTION_DATE = 5
}

export enum SearchSortString {
    DEFAULT = "1",
    LATEST = "2",
    PRICE = "3",
    PRICE_M2 = "4",
    CONSTRUCTION_DATE = "5"
}
