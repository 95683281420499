export const COMMON_TEST_IDS = {
    PAGINATION: {
        PAGE: "common-pagination-page"
    }
};

export const NAVIGATION_TEST_IDS = {
    MAIN_LOGO: "top-left-logo",
    MAIN_ELEMENT: "navigation-container",
    FIRST_LVL_ELEMENT: "navigation-first-level-element",
    SECOND_LVL_ELEMENT: "navigation-second-level-element",
    THIRD_LVL_ELEMENT: "navigation-third-level-element",
    SEARCH_BAR: {
        INPUT: "search-autocomplete",
        COUNTRY_TAB: "country-tab",
        RESULT_ITEM: "results-list-item",
        DROPDOWN: "search-autocomplete-dropdown"
    }
};

export const FOOTER_TEST_IDS = {
    FOOTER_ELEMENT: "footer-nav-container",
    FOOTER_SOCIAL_ICON: "social-media-icon",
    PANEL_EXPAND_WRAPPER: "panel-expandable-wrapper"
};

export const COOKIES_MODAL_IDS = {
    MODAL_BOX: "CybotCookiebotDialog",
    ACCEPT_BUTTON: "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll"
};
export const COMMON_TEST_IDS = {
    PAGINATION: {
        PAGE: "common-pagination-page"
    }
};

export const NAVIGATION_TEST_IDS = {
    MAIN_LOGO: "top-left-logo",
    MAIN_ELEMENT: "navigation-container",
    FIRST_LVL_ELEMENT: "navigation-first-level-element",
    SECOND_LVL_ELEMENT: "navigation-second-level-element",
    THIRD_LVL_ELEMENT: "navigation-third-level-element",
    SEARCH_BAR: {
        INPUT: "search-autocomplete",
        COUNTRY_TAB: "country-tab",
        RESULT_ITEM: "results-list-item",
        DROPDOWN: "search-autocomplete-dropdown"
    }
};

export const FOOTER_TEST_IDS = {
    FOOTER_ELEMENT: "footer-nav-container",
    FOOTER_SOCIAL_ICON: "social-media-icon",
    PANEL_EXPAND_WRAPPER: "panel-expandable-wrapper"
};

export const COOKIES_MODAL_IDS = {
    MODAL_BOX: "CybotCookiebotDialog",
    ACCEPT_BUTTON: "CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll"
};
