import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_DETAIL_ARTICLES_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_ARTICLES";
export const fetchOfferDetailArticlesTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_ARTICLES_PREFIX);

const FETCH_PROPERTY_DETAIL_ARTICLES_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_ARTICLES";
export const fetchPropertyDetailArticlesTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_ARTICLES_PREFIX);

export interface IOfferDetailArticle {
    author: number;
    date_published: string | null;
    gallery: {
        image: {
            g_img_440x440: string;
            g_img_728x400: string;
            g_img_1160x638: string;
        } | null;
    };
    id: number;
    lead: string | null;
    slug: string;
    title: string;
}

export interface IOfferDetailArticleAuthor {
    id: number;
    name: string;
}

interface IOfferListArticlesResponse {
    results: IOfferDetailArticle[];
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.OFFER_ARTICLE_LIST);

export const fetchOfferDetailArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    createFetchOfferArticles(fetchOfferDetailArticlesTypes)(ctx.meta, {
        related_vendor: ctx.prevResult.offer.vendor.id
    });

export const fetchPropertyDetailArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    createFetchOfferArticles(fetchPropertyDetailArticlesTypes)(ctx.meta, {
        related_vendor: ctx.prevResult.offer.vendor.id
    });

export const createFetchOfferArticles = (actionType: RequestActionTypes) => (meta: IRPRequestMeta, query: Record<string, number>) => {
    return async (dispatch: Dispatch) => {
        dispatch({type: actionType.start});
        const url = appendQueryString(articleListApiLink, {
            related_vendor: query.related_vendor,
            page_size: 4
        });

        return getRequest(meta, url).then((json: IOfferListArticlesResponse) => {
            dispatch({type: actionType.success, result: json.results});
            return json;
        });
    };
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";

const FETCH_OFFER_DETAIL_ARTICLES_PREFIX = "offer_detail/FETCH_OFFER_DETAIL_ARTICLES";
export const fetchOfferDetailArticlesTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_ARTICLES_PREFIX);

const FETCH_PROPERTY_DETAIL_ARTICLES_PREFIX = "property_detail/FETCH_PROPERTY_DETAIL_ARTICLES";
export const fetchPropertyDetailArticlesTypes = createRequestActionTypes(FETCH_PROPERTY_DETAIL_ARTICLES_PREFIX);

export interface IOfferDetailArticle {
    author: number;
    date_published: string | null;
    gallery: {
        image: {
            g_img_440x440: string;
            g_img_728x400: string;
            g_img_1160x638: string;
        } | null;
    };
    id: number;
    lead: string | null;
    slug: string;
    title: string;
}

export interface IOfferDetailArticleAuthor {
    id: number;
    name: string;
}

interface IOfferListArticlesResponse {
    results: IOfferDetailArticle[];
}

const articleListApiLink = apiV2ListLink.article.list(Scenario.OFFER_ARTICLE_LIST);

export const fetchOfferDetailArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    createFetchOfferArticles(fetchOfferDetailArticlesTypes)(ctx.meta, {
        related_vendor: ctx.prevResult.offer.vendor.id
    });

export const fetchPropertyDetailArticlesAtRoute = (ctx: IFetchContext<IRPRequestMeta>) =>
    createFetchOfferArticles(fetchPropertyDetailArticlesTypes)(ctx.meta, {
        related_vendor: ctx.prevResult.offer.vendor.id
    });

export const createFetchOfferArticles = (actionType: RequestActionTypes) => (meta: IRPRequestMeta, query: Record<string, number>) => {
    return async (dispatch: Dispatch) => {
        dispatch({type: actionType.start});
        const url = appendQueryString(articleListApiLink, {
            related_vendor: query.related_vendor,
            page_size: 4
        });

        return getRequest(meta, url).then((json: IOfferListArticlesResponse) => {
            dispatch({type: actionType.success, result: json.results});
            return json;
        });
    };
};
