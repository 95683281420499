import lscache from "lscache";

import {isEmpty} from "@pg-mono/nodash";

import {IFavourite} from "../interfaces/IFavourite";
import {favouriteInCollection} from "../utils/favourite_in_collection";

const addToStorageFavourites = (localStorageKey: string) => (favourites: IFavourite[]) => {
    const storageFavourites: IFavourite[] = lscache.get(localStorageKey) || [];

    // If storage is empty, set new favourites value as valid
    if (isEmpty(storageFavourites)) {
        lscache.set(localStorageKey, favourites);

        return;
    }

    // Check if favourite value (from favourites collection) has been already added
    const favouritesToAdd = favourites.filter((favourite) => {
        return !favouriteInCollection(favourite, storageFavourites);
    });

    if (!isEmpty(favouritesToAdd)) {
        lscache.set(localStorageKey, [...storageFavourites, ...favouritesToAdd]);
    }
};

const removeFromStorageFavourites = (localStorageKey: string) => (favourites: IFavourite[]) => {
    if (isEmpty(favourites)) {
        return;
    }

    const storageFavourites: IFavourite[] = lscache.get(localStorageKey) || [];

    lscache.set(
        localStorageKey,
        storageFavourites.filter((favourite) => !favouriteInCollection(favourite, favourites))
    );
};

const getLocalStorageFavourites = (localStorageKey: string) => (): IFavourite[] => {
    return (lscache.get(localStorageKey) || []) as IFavourite[];
};

const clearLocalStorageFavourites = (localStorageKey: string) => () => {
    lscache.remove(localStorageKey);
};

export const initLocalStorageFavouritesService = (localStorageKey: string) => {
    return {
        addToStorageFavourites: addToStorageFavourites(localStorageKey),
        clearLocalStorageFavourites: clearLocalStorageFavourites(localStorageKey),
        getLocalStorageFavourites: getLocalStorageFavourites(localStorageKey),
        removeFromStorageFavourites: removeFromStorageFavourites(localStorageKey)
    };
};
import lscache from "lscache";

import {isEmpty} from "@pg-mono/nodash";

import {IFavourite} from "../interfaces/IFavourite";
import {favouriteInCollection} from "../utils/favourite_in_collection";

const addToStorageFavourites = (localStorageKey: string) => (favourites: IFavourite[]) => {
    const storageFavourites: IFavourite[] = lscache.get(localStorageKey) || [];

    // If storage is empty, set new favourites value as valid
    if (isEmpty(storageFavourites)) {
        lscache.set(localStorageKey, favourites);

        return;
    }

    // Check if favourite value (from favourites collection) has been already added
    const favouritesToAdd = favourites.filter((favourite) => {
        return !favouriteInCollection(favourite, storageFavourites);
    });

    if (!isEmpty(favouritesToAdd)) {
        lscache.set(localStorageKey, [...storageFavourites, ...favouritesToAdd]);
    }
};

const removeFromStorageFavourites = (localStorageKey: string) => (favourites: IFavourite[]) => {
    if (isEmpty(favourites)) {
        return;
    }

    const storageFavourites: IFavourite[] = lscache.get(localStorageKey) || [];

    lscache.set(
        localStorageKey,
        storageFavourites.filter((favourite) => !favouriteInCollection(favourite, favourites))
    );
};

const getLocalStorageFavourites = (localStorageKey: string) => (): IFavourite[] => {
    return (lscache.get(localStorageKey) || []) as IFavourite[];
};

const clearLocalStorageFavourites = (localStorageKey: string) => () => {
    lscache.remove(localStorageKey);
};

export const initLocalStorageFavouritesService = (localStorageKey: string) => {
    return {
        addToStorageFavourites: addToStorageFavourites(localStorageKey),
        clearLocalStorageFavourites: clearLocalStorageFavourites(localStorageKey),
        getLocalStorageFavourites: getLocalStorageFavourites(localStorageKey),
        removeFromStorageFavourites: removeFromStorageFavourites(localStorageKey)
    };
};
