import {IAction} from "@pg-mono/request-state";

import {SET_GTM_INIT} from "../actions/initialize_google_tag_manager_actions";

export type IGoogleTagManagerStore = boolean;

export const googleTagManagerReducer = (state = false, action: IAction) => {
    switch (action.type) {
        case SET_GTM_INIT:
            return true;
        default:
            return state;
    }
};
import {IAction} from "@pg-mono/request-state";

import {SET_GTM_INIT} from "../actions/initialize_google_tag_manager_actions";

export type IGoogleTagManagerStore = boolean;

export const googleTagManagerReducer = (state = false, action: IAction) => {
    switch (action.type) {
        case SET_GTM_INIT:
            return true;
        default:
            return state;
    }
};
