import {omit, pick} from "@pg-mono/nodash";

import {IFriendlyQuery} from "../../list/types/friendly_query";
import {friendlyUrlQueryKeys, offerUrlBuilder} from "./offer_url_builder";

// `query` is not really `Partial<IFriendlyQuery>` but can also extend `Record<string, string | number>`, still specified keys should have specified value-types
export const getFriendlyOfferListLinkParams = (query: Partial<IFriendlyQuery>) => {
    const friendlyQueryValues = pick(query, friendlyUrlQueryKeys) as Partial<IFriendlyQuery>;
    const restQueryValues = omit(query, friendlyUrlQueryKeys) as Record<string, string | number>;
    const friendlySlug = offerUrlBuilder(friendlyQueryValues).friendlySlug;

    return {
        friendlySlug,
        restQueryValues
    };
};
import {omit, pick} from "@pg-mono/nodash";

import {IFriendlyQuery} from "../../list/types/friendly_query";
import {friendlyUrlQueryKeys, offerUrlBuilder} from "./offer_url_builder";

// `query` is not really `Partial<IFriendlyQuery>` but can also extend `Record<string, string | number>`, still specified keys should have specified value-types
export const getFriendlyOfferListLinkParams = (query: Partial<IFriendlyQuery>) => {
    const friendlyQueryValues = pick(query, friendlyUrlQueryKeys) as Partial<IFriendlyQuery>;
    const restQueryValues = omit(query, friendlyUrlQueryKeys) as Record<string, string | number>;
    const friendlySlug = offerUrlBuilder(friendlyQueryValues).friendlySlug;

    return {
        friendlySlug,
        restQueryValues
    };
};
