import {useEffect, useState} from "react";

import {useIsMounted} from "./use_is_mounted";

/** This wont trigger on firefox printing. Still useful for things like avoiding mount errors on chrome and safari*/
export const useIsPrint = () => {
    if (typeof window === "undefined" || typeof window.matchMedia === "undefined") {
        return false;
    }

    const mediaQuery = window.matchMedia("print");

    const [isPrinting, setIsPrinting] = useState(false);
    const isMounted = useIsMounted();

    const handler = () => setIsPrinting(mediaQuery.matches);

    useEffect(() => {
        if ((mediaQuery.addEventListener as AddEventListenerOptions) || undefined) {
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        } else {
            //<Safari 14, IE, <Edge 16 support only deprecated MediaQueryList' API. https://github.com/microsoft/TypeScript/issues/32210
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            setIsPrinting(mediaQuery.matches);
        }
    }, [isMounted]);

    return isPrinting;
};
import {useEffect, useState} from "react";

import {useIsMounted} from "./use_is_mounted";

/** This wont trigger on firefox printing. Still useful for things like avoiding mount errors on chrome and safari*/
export const useIsPrint = () => {
    if (typeof window === "undefined" || typeof window.matchMedia === "undefined") {
        return false;
    }

    const mediaQuery = window.matchMedia("print");

    const [isPrinting, setIsPrinting] = useState(false);
    const isMounted = useIsMounted();

    const handler = () => setIsPrinting(mediaQuery.matches);

    useEffect(() => {
        if ((mediaQuery.addEventListener as AddEventListenerOptions) || undefined) {
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        } else {
            //<Safari 14, IE, <Edge 16 support only deprecated MediaQueryList' API. https://github.com/microsoft/TypeScript/issues/32210
            mediaQuery.addListener(handler);
            return () => mediaQuery.removeListener(handler);
        }
    }, []);

    useEffect(() => {
        if (isMounted) {
            setIsPrinting(mediaQuery.matches);
        }
    }, [isMounted]);

    return isPrinting;
};
