import {IModalProps} from "../../../types/IModalProps";

import * as styles from "./modal_valign_style.module.css";

export const modalValignStyle = (align: IModalProps["vAlign"]) => align && styles[align];
import {IModalProps} from "../../../types/IModalProps";

import * as styles from "./modal_valign_style.module.css";

export const modalValignStyle = (align: IModalProps["vAlign"]) => align && styles[align];
