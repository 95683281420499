import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {IRankingAnnualParams, rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetch404Redirect} from "../../errors/actions/page_404_actions";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {getRankingCities} from "../api/get_ranking_cities";
import {getRankingQuarters} from "../api/get_ranking_quarters";
import {rankingLastYear} from "../constants/ranking";
import {getRankingCitiesIds} from "../utils/get_ranking_cities_ids";
import {getRankingRegionAndValidate} from "../utils/get_ranking_region_and_validate";
import {updateRankingListMetaDataYear} from "./update_ranking_meta_data";

export const rankingArchivedYearViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingAnnualParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {citySlug, year} = ctx.match.params;

        if (year === rankingLastYear.toString()) {
            dispatch(
                enable301ResponseState(
                    rpAppLink.ranking.lastYearList({
                        citySlug
                    })
                )
            );
            return false;
        }

        const rankingCitiesResponse = await dispatch(getRankingCities.endpoints.getRankingCities.initiate()).unwrap();
        const rankingRegion = await getRankingRegionAndValidate(citySlug, rankingCitiesResponse.results, dispatch);

        if (!rankingRegion) {
            // TODO rewrite to rtk?
            const redirectUrl = (await fetch404Redirect(ctx.route.pathname, ctx.meta)) ?? appendQueryString(rpAppLink.ranking.base(), ctx.route.query);
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        await dispatch(getRankingQuarters.endpoints.getRankingQuarters.initiate());

        const cities2023 = getRankingCitiesIds();
        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities2023}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities2023}));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));

        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));
        dispatch(updateRankingListMetaDataYear(ctx, rankingRegion));
    };
import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {IRankingAnnualParams, rpAppLink} from "@pg-mono/rp-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRPStore} from "../../app/rp_reducer";
import {IRPRequestMeta} from "../../app/rp_request_meta";
import {fetch404Redirect} from "../../errors/actions/page_404_actions";
import {getRegionDetailListApi} from "../../region/api/get_region_detail_list";
import {getRegionListApi} from "../../region/api/get_region_list";
import {IAppDispatch} from "../../store/store";
import {setViewTypeAction} from "../../view_type/actions/set_view_type_action";
import {ViewType} from "../../view_type/ViewType";
import {getRankingCities} from "../api/get_ranking_cities";
import {getRankingQuarters} from "../api/get_ranking_quarters";
import {rankingLastYear} from "../constants/ranking";
import {getRankingCitiesIds} from "../utils/get_ranking_cities_ids";
import {getRankingRegionAndValidate} from "../utils/get_ranking_region_and_validate";
import {updateRankingListMetaDataYear} from "./update_ranking_meta_data";

export const rankingArchivedYearViewAction =
    (ctx: IFetchContext<IRPRequestMeta, IRankingAnnualParams>) => async (dispatch: IAppDispatch, _getState: () => IRPStore) => {
        const {citySlug, year} = ctx.match.params;

        if (year === rankingLastYear.toString()) {
            dispatch(
                enable301ResponseState(
                    rpAppLink.ranking.lastYearList({
                        citySlug
                    })
                )
            );
            return false;
        }

        const rankingCitiesResponse = await dispatch(getRankingCities.endpoints.getRankingCities.initiate()).unwrap();
        const rankingRegion = await getRankingRegionAndValidate(citySlug, rankingCitiesResponse.results, dispatch);

        if (!rankingRegion) {
            // TODO rewrite to rtk?
            const redirectUrl = (await fetch404Redirect(ctx.route.pathname, ctx.meta)) ?? appendQueryString(rpAppLink.ranking.base(), ctx.route.query);
            dispatch(enable301ResponseState(redirectUrl));
            return false;
        }

        await dispatch(getRankingQuarters.endpoints.getRankingQuarters.initiate());

        const cities2023 = getRankingCitiesIds();
        await dispatch(getRegionListApi.endpoints.getRegionList.initiate({region: cities2023}));
        await dispatch(getRegionDetailListApi.endpoints.getRegionDetailList.initiate({regionIds: cities2023}));

        await Promise.all(dispatch(rpApi.util.getRunningQueriesThunk()));

        dispatch(setViewTypeAction(ViewType.OFFER_RANKING));
        dispatch(updateRankingListMetaDataYear(ctx, rankingRegion));
    };
