import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

interface IAppliedListResponse extends IPaginatedResponse {
    results: {pk: number; property: null | number; offer: number; vendor: number}[];
}

export const fetchMyApplicationsList = (meta: IRPRequestMeta): Promise<IAppliedListResponse> => {
    const query = {
        "i[]": ["application.offer", "application.property"],
        page_size: 1000
    };

    const url = appendQueryString(apiLink.application.sent({})(null), query);

    return getRequest(meta, url);
};
import {appendQueryString, getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IResponse";

interface IAppliedListResponse extends IPaginatedResponse {
    results: {pk: number; property: null | number; offer: number; vendor: number}[];
}

export const fetchMyApplicationsList = (meta: IRPRequestMeta): Promise<IAppliedListResponse> => {
    const query = {
        "i[]": ["application.offer", "application.property"],
        page_size: 1000
    };

    const url = appendQueryString(apiLink.application.sent({})(null), query);

    return getRequest(meta, url);
};
