import {Key, pathToRegexp} from "path-to-regexp";

import {omit, pickBy} from "@pg-mono/nodash";

import {FloorChoice} from "../../../real_estate/types/FloorChoice";
import {Country} from "../../../region/types/Country";
import {houseFiltersFriendlyQueryValues} from "../../constants/house_filter";
import {PropertyHouseAdditionalAreas} from "../../detail/constants/PropertyHouseAdditionalAreas";
import {PropertyHouseStoreys} from "../../detail/constants/PropertyHouseStoreys";
import {PropertyHouseType} from "../../detail/constants/PropertyHouseType";
import {floorFilterFriendlyQueryValues} from "../../list/constants/floor_filter_friendly_query_values";
import {OfferType} from "../../types/OfferType";
import {getOfferListSubFilterParsed} from "./get_offer_list_sub_filter_parsed";
import {offerUrlConstants} from "./offer_url_constants";

const sortRegex = ":sort(nowe|tanie|gotowe|luksusowe|^$)?";
const beforeTypeDashRegex = ":beforeTypeDash(-)?";
const typeRegex = ":type(mieszkania-i-domy|lokale-uzytkowe|domy|mieszkania)";
const beforeRegionDashRegex = ":beforeRegionDash(-)?";
const regionRegex = ":region(.*?)";
const beforePriceDashRegex = ":beforePriceDash(-)?";
const priceRegex = ":price(cena)?:price_0_prefix(-od-)?:price_0(\\d+)?:price_1_prefix(-do-)?:price_1(\\d+)?:zl(-zl)?";
const beforeAreaDashRegex = ":beforeAreaDash(-)?";
const areaRegex = ":area(powierzchnia)?:area_0_prefix(-od-)?:area_0(\\d+)?:area_1_prefix(-do-)?:area_1(\\d+)?:m2(-m2)?";
const beforeRoomsDashRegex = ":beforeRoomsDash(-)?";
const roomsRegex = ":rooms(liczba-pokoi)?:rooms_0_prefix(-od-)?:rooms_0([1-5])?:rooms_1_prefix(-do-)?:rooms_1([1-5])?";
const beforeEqualRoomsDashRegex = ":beforeEqualRoomsDash(-)?";
const roomsEqualRegex = ":roomsEqual(jednopokojowe|dwupokojowe|trzypokojowe|czteropokojowe|pieciopokojowe-i-wiecej|pieciopokojowe)?";
const offerListSubFilter = `/:offerListSubFilter(${floorFilterFriendlyQueryValues.join("|")}|${houseFiltersFriendlyQueryValues.join("|")})?`;

export const offerListRegex = [
    sortRegex,
    beforeTypeDashRegex,
    typeRegex,
    beforeRegionDashRegex,
    regionRegex,
    beforePriceDashRegex,
    priceRegex,
    beforeAreaDashRegex,
    areaRegex,
    beforeRoomsDashRegex,
    roomsRegex,
    beforeEqualRoomsDashRegex,
    roomsEqualRegex,
    offerListSubFilter
].join("");

// full data received by parsing offer list friendly slug or search entry, parser returns only `Partial<ISlugParsed>` fields
// created by `offerUrlParser` when parsing friendly url and by `fromSearchResponseToParsed` when parsing `ISearch` received from API
export interface ISlugParsed {
    sort: number | null;
    type: number;
    region: string[]; // first step assigns region's slug; second step assigns region's id (as string)
    price_0: number;
    price_1: number;
    area_0: number;
    area_1: number;
    rooms_0: number;
    rooms_1: number;
    is_luxury: true | undefined;
    price_lower_than_average: boolean;
    construction_end_date: string;
    floor_choices: FloorChoice;
    house_type?: PropertyHouseType;
    house_additional_areas?: PropertyHouseAdditionalAreas;
    house_storeys?: PropertyHouseStoreys;
    country?: Country;
    region_name?: string;
}
// created by `fromParsedObjectToQuery` when translating parsed object (derived from slug or search) into query params (string based)
export interface ISlugQuery {
    sort: string | null;
    type: string;
    region: string[];
    price_0: string;
    price_1: string;
    area_0: string;
    area_1: string;
    rooms_0: string;
    rooms_1: string;
    is_luxury: "true" | "false";
    floor_choices: string[] | string;
    houseFilter: string;
}

const isPrimitive = (val: unknown) => val !== Object(val);
export const fromParsedObjectToQuery = (parsed: Partial<ISlugParsed>): Partial<ISlugQuery> => {
    const query: Record<string, unknown> = {};
    for (const key in parsed) {
        const val = parsed[key as keyof ISlugParsed];
        if (val != null && isPrimitive(val)) {
            query[key] = val.toString();
        } else {
            query[key] = val;
        }
    }
    return query as Partial<ISlugQuery>;
};

export const offerUrlParser = (friendlySlug: string, offerListSubFilterSlug?: string): Partial<ISlugParsed> | null => {
    // urls ending with dash are always incorrect, ie. 'nowe-mieszkania-'
    if (friendlySlug.match(/-$/gi)) {
        return null;
    }

    const keys: Key[] = [];
    const regexp = pathToRegexp(offerListRegex, keys);
    const regExpExecArray: RegExpExecArray | null = regexp.exec(friendlySlug);
    if (regExpExecArray == null) {
        return null;
    }

    const formatNumber = (value: string) => value && parseInt(value, 10);
    const subFilterValues = getOfferListSubFilterParsed(offerListSubFilterSlug);

    const values: Record<string, string | number | boolean | null | string[]> = {
        type: offerUrlConstants.types[regExpExecArray[3]],
        sort: offerUrlConstants.sort[regExpExecArray[1]],
        region: [regExpExecArray[5]],
        price: regExpExecArray[7],
        price_0: formatNumber(regExpExecArray[9]),
        price_1: formatNumber(regExpExecArray[11]),
        area: regExpExecArray[14],
        area_0: formatNumber(regExpExecArray[16]),
        area_1: formatNumber(regExpExecArray[18]),
        rooms: offerUrlConstants.rooms[regExpExecArray[27]],
        rooms_0: formatNumber(regExpExecArray[23]),
        rooms_1: formatNumber(regExpExecArray[25]),
        ...(regExpExecArray[1] === "luksusowe" ? {is_luxury: true} : {}),
        ...(regExpExecArray[1] === "tanie" ? {price_lower_than_average: true} : {}),
        ...(regExpExecArray[1] === "gotowe" ? {construction_end_date: "0"} : {}),
        ...subFilterValues
    };

    const isParsedQueryValid = validateValues(values);
    if (!isParsedQueryValid) {
        return null;
    }
    if (values.rooms) {
        if (values.rooms === 5) {
            values.rooms_0 = values.rooms;
        } else if (values.rooms === 1) {
            values.rooms_1 = values.rooms;
        } else {
            values.rooms_0 = values.rooms_1 = +values.rooms;
        }
    }

    return pickBy(omit(values, ["price", "area", "rooms"]), (elem) => elem != null && !Number.isNaN(elem) && elem != "");
};

const validateValues = (values: Record<string, string | number | boolean | null | string[]>) => {
    const reservedKeywords = [
        "mieszkania",
        "domy",
        "z-doplata-mdm",
        "-cena",
        "-powierzchnia",
        "-liczba-pokoi",
        "jednopokojowe",
        "dwupokojowe",
        "trzypokojowe",
        "czteropokojowe",
        "pieciopokojowe",
        "-od-",
        "-do-"
    ];
    const isRegionInvalid = reservedKeywords.some((keyword) => {
        return values.region && (values.region as ISlugParsed["region"])[0]?.includes(keyword);
    });
    // region contains invalid characters eg: '-cena' in 'warszawa-cena-350000'
    if (isRegionInvalid) {
        return false;
    }
    // url contains 'cena', but has no prices
    if ((values.price && !values.price_0 && !values.price_1) || (!values.price && (values.price_0 || values.price_1))) {
        return false;
    }
    // url contains 'powierzchnia', but has no areas
    if ((values.area && !values.area_0 && !values.area_1) || (!values.area && (values.area_0 || values.area_1))) {
        return false;
    }
    // sort is `undefined` when not matched with `offerUrlConstants.sort`, thus we expect different prefix like "luksusowe-*"
    if (values.sort === undefined && !values.is_luxury) {
        return false;
    }
    // redirect luxury commercials
    if (values.is_luxury && values.type === OfferType.COMMERCIAL) {
        return false;
    }

    if (values.houseFilter && !houseFiltersFriendlyQueryValues.includes(String(values.houseFilter))) {
        return false;
    }

    return true;
};
import {Key, pathToRegexp} from "path-to-regexp";

import {omit, pickBy} from "@pg-mono/nodash";

import {FloorChoice} from "../../../real_estate/types/FloorChoice";
import {Country} from "../../../region/types/Country";
import {houseFiltersFriendlyQueryValues} from "../../constants/house_filter";
import {PropertyHouseAdditionalAreas} from "../../detail/constants/PropertyHouseAdditionalAreas";
import {PropertyHouseStoreys} from "../../detail/constants/PropertyHouseStoreys";
import {PropertyHouseType} from "../../detail/constants/PropertyHouseType";
import {floorFilterFriendlyQueryValues} from "../../list/constants/floor_filter_friendly_query_values";
import {OfferType} from "../../types/OfferType";
import {getOfferListSubFilterParsed} from "./get_offer_list_sub_filter_parsed";
import {offerUrlConstants} from "./offer_url_constants";

const sortRegex = ":sort(nowe|tanie|gotowe|luksusowe|^$)?";
const beforeTypeDashRegex = ":beforeTypeDash(-)?";
const typeRegex = ":type(mieszkania-i-domy|lokale-uzytkowe|domy|mieszkania)";
const beforeRegionDashRegex = ":beforeRegionDash(-)?";
const regionRegex = ":region(.*?)";
const beforePriceDashRegex = ":beforePriceDash(-)?";
const priceRegex = ":price(cena)?:price_0_prefix(-od-)?:price_0(\\d+)?:price_1_prefix(-do-)?:price_1(\\d+)?:zl(-zl)?";
const beforeAreaDashRegex = ":beforeAreaDash(-)?";
const areaRegex = ":area(powierzchnia)?:area_0_prefix(-od-)?:area_0(\\d+)?:area_1_prefix(-do-)?:area_1(\\d+)?:m2(-m2)?";
const beforeRoomsDashRegex = ":beforeRoomsDash(-)?";
const roomsRegex = ":rooms(liczba-pokoi)?:rooms_0_prefix(-od-)?:rooms_0([1-5])?:rooms_1_prefix(-do-)?:rooms_1([1-5])?";
const beforeEqualRoomsDashRegex = ":beforeEqualRoomsDash(-)?";
const roomsEqualRegex = ":roomsEqual(jednopokojowe|dwupokojowe|trzypokojowe|czteropokojowe|pieciopokojowe-i-wiecej|pieciopokojowe)?";
const offerListSubFilter = `/:offerListSubFilter(${floorFilterFriendlyQueryValues.join("|")}|${houseFiltersFriendlyQueryValues.join("|")})?`;

export const offerListRegex = [
    sortRegex,
    beforeTypeDashRegex,
    typeRegex,
    beforeRegionDashRegex,
    regionRegex,
    beforePriceDashRegex,
    priceRegex,
    beforeAreaDashRegex,
    areaRegex,
    beforeRoomsDashRegex,
    roomsRegex,
    beforeEqualRoomsDashRegex,
    roomsEqualRegex,
    offerListSubFilter
].join("");

// full data received by parsing offer list friendly slug or search entry, parser returns only `Partial<ISlugParsed>` fields
// created by `offerUrlParser` when parsing friendly url and by `fromSearchResponseToParsed` when parsing `ISearch` received from API
export interface ISlugParsed {
    sort: number | null;
    type: number;
    region: string[]; // first step assigns region's slug; second step assigns region's id (as string)
    price_0: number;
    price_1: number;
    area_0: number;
    area_1: number;
    rooms_0: number;
    rooms_1: number;
    is_luxury: true | undefined;
    price_lower_than_average: boolean;
    construction_end_date: string;
    floor_choices: FloorChoice;
    house_type?: PropertyHouseType;
    house_additional_areas?: PropertyHouseAdditionalAreas;
    house_storeys?: PropertyHouseStoreys;
    country?: Country;
    region_name?: string;
}
// created by `fromParsedObjectToQuery` when translating parsed object (derived from slug or search) into query params (string based)
export interface ISlugQuery {
    sort: string | null;
    type: string;
    region: string[];
    price_0: string;
    price_1: string;
    area_0: string;
    area_1: string;
    rooms_0: string;
    rooms_1: string;
    is_luxury: "true" | "false";
    floor_choices: string[] | string;
    houseFilter: string;
}

const isPrimitive = (val: unknown) => val !== Object(val);
export const fromParsedObjectToQuery = (parsed: Partial<ISlugParsed>): Partial<ISlugQuery> => {
    const query: Record<string, unknown> = {};
    for (const key in parsed) {
        const val = parsed[key as keyof ISlugParsed];
        if (val != null && isPrimitive(val)) {
            query[key] = val.toString();
        } else {
            query[key] = val;
        }
    }
    return query as Partial<ISlugQuery>;
};

export const offerUrlParser = (friendlySlug: string, offerListSubFilterSlug?: string): Partial<ISlugParsed> | null => {
    // urls ending with dash are always incorrect, ie. 'nowe-mieszkania-'
    if (friendlySlug.match(/-$/gi)) {
        return null;
    }

    const keys: Key[] = [];
    const regexp = pathToRegexp(offerListRegex, keys);
    const regExpExecArray: RegExpExecArray | null = regexp.exec(friendlySlug);
    if (regExpExecArray == null) {
        return null;
    }

    const formatNumber = (value: string) => value && parseInt(value, 10);
    const subFilterValues = getOfferListSubFilterParsed(offerListSubFilterSlug);

    const values: Record<string, string | number | boolean | null | string[]> = {
        type: offerUrlConstants.types[regExpExecArray[3]],
        sort: offerUrlConstants.sort[regExpExecArray[1]],
        region: [regExpExecArray[5]],
        price: regExpExecArray[7],
        price_0: formatNumber(regExpExecArray[9]),
        price_1: formatNumber(regExpExecArray[11]),
        area: regExpExecArray[14],
        area_0: formatNumber(regExpExecArray[16]),
        area_1: formatNumber(regExpExecArray[18]),
        rooms: offerUrlConstants.rooms[regExpExecArray[27]],
        rooms_0: formatNumber(regExpExecArray[23]),
        rooms_1: formatNumber(regExpExecArray[25]),
        ...(regExpExecArray[1] === "luksusowe" ? {is_luxury: true} : {}),
        ...(regExpExecArray[1] === "tanie" ? {price_lower_than_average: true} : {}),
        ...(regExpExecArray[1] === "gotowe" ? {construction_end_date: "0"} : {}),
        ...subFilterValues
    };

    const isParsedQueryValid = validateValues(values);
    if (!isParsedQueryValid) {
        return null;
    }
    if (values.rooms) {
        if (values.rooms === 5) {
            values.rooms_0 = values.rooms;
        } else if (values.rooms === 1) {
            values.rooms_1 = values.rooms;
        } else {
            values.rooms_0 = values.rooms_1 = +values.rooms;
        }
    }

    return pickBy(omit(values, ["price", "area", "rooms"]), (elem) => elem != null && !Number.isNaN(elem) && elem != "");
};

const validateValues = (values: Record<string, string | number | boolean | null | string[]>) => {
    const reservedKeywords = [
        "mieszkania",
        "domy",
        "z-doplata-mdm",
        "-cena",
        "-powierzchnia",
        "-liczba-pokoi",
        "jednopokojowe",
        "dwupokojowe",
        "trzypokojowe",
        "czteropokojowe",
        "pieciopokojowe",
        "-od-",
        "-do-"
    ];
    const isRegionInvalid = reservedKeywords.some((keyword) => {
        return values.region && (values.region as ISlugParsed["region"])[0]?.includes(keyword);
    });
    // region contains invalid characters eg: '-cena' in 'warszawa-cena-350000'
    if (isRegionInvalid) {
        return false;
    }
    // url contains 'cena', but has no prices
    if ((values.price && !values.price_0 && !values.price_1) || (!values.price && (values.price_0 || values.price_1))) {
        return false;
    }
    // url contains 'powierzchnia', but has no areas
    if ((values.area && !values.area_0 && !values.area_1) || (!values.area && (values.area_0 || values.area_1))) {
        return false;
    }
    // sort is `undefined` when not matched with `offerUrlConstants.sort`, thus we expect different prefix like "luksusowe-*"
    if (values.sort === undefined && !values.is_luxury) {
        return false;
    }
    // redirect luxury commercials
    if (values.is_luxury && values.type === OfferType.COMMERCIAL) {
        return false;
    }

    if (values.houseFilter && !houseFiltersFriendlyQueryValues.includes(String(values.houseFilter))) {
        return false;
    }

    return true;
};
