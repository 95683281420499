import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export const redirectOpendayPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const campaignUuid = ctx.match.params.campaignUuid;

    const {subscriber_uuid: subscriberUuid, ...restQuery} = ctx.route.query;

    const redirectUrl = subscriberUuid
        ? rpAppLink.openDay.withSubscriberId({campaignUuid, subscriberUuid: subscriberUuid as string})
        : rpAppLink.openDay.base({campaignUuid});

    dispatch(enable301ResponseState(appendQueryString(redirectUrl, restQuery)));

    return true;
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";

export const redirectOpendayPermalink = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    const campaignUuid = ctx.match.params.campaignUuid;

    const {subscriber_uuid: subscriberUuid, ...restQuery} = ctx.route.query;

    const redirectUrl = subscriberUuid
        ? rpAppLink.openDay.withSubscriberId({campaignUuid, subscriberUuid: subscriberUuid as string})
        : rpAppLink.openDay.base({campaignUuid});

    dispatch(enable301ResponseState(appendQueryString(redirectUrl, restQuery)));

    return true;
};
