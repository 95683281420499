interface IABTest {
    id: string;
    variant: number;
}

export const ADD_AB_TEST_DATA = "ab_testing/ADD_AB_TEST_DATA";
export const SET_AB_TESTING_INITIALIZED = "ab_testing/SET_AB_TESTING_INITIALIZED";

export interface IAddTestAction {
    type: typeof ADD_AB_TEST_DATA;
    abTest: IABTest;
}

export const addABTestData = (abTest: IABTest): IAddTestAction => ({
    type: ADD_AB_TEST_DATA,
    abTest
});

export interface ISetABTestingInitializedAction {
    type: typeof SET_AB_TESTING_INITIALIZED;
}

export const setABTestingInitialized = (): ISetABTestingInitializedAction => ({
    type: SET_AB_TESTING_INITIALIZED
});

export type IABTestAction = IAddTestAction | ISetABTestingInitializedAction;
interface IABTest {
    id: string;
    variant: number;
}

export const ADD_AB_TEST_DATA = "ab_testing/ADD_AB_TEST_DATA";
export const SET_AB_TESTING_INITIALIZED = "ab_testing/SET_AB_TESTING_INITIALIZED";

export interface IAddTestAction {
    type: typeof ADD_AB_TEST_DATA;
    abTest: IABTest;
}

export const addABTestData = (abTest: IABTest): IAddTestAction => ({
    type: ADD_AB_TEST_DATA,
    abTest
});

export interface ISetABTestingInitializedAction {
    type: typeof SET_AB_TESTING_INITIALIZED;
}

export const setABTestingInitialized = (): ISetABTestingInitializedAction => ({
    type: SET_AB_TESTING_INITIALIZED
});

export type IABTestAction = IAddTestAction | ISetABTestingInitializedAction;
