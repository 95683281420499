import {floorFilterFriendlyQueryValues} from "../../../constants/floor_filter_friendly_query_values";

export function isOfferListSubTypeValidFloorChoice(offerListSubFilter?: string) {
    if (!offerListSubFilter) {
        return false;
    }

    return floorFilterFriendlyQueryValues.includes(offerListSubFilter);
}
import {floorFilterFriendlyQueryValues} from "../../../constants/floor_filter_friendly_query_values";

export function isOfferListSubTypeValidFloorChoice(offerListSubFilter?: string) {
    if (!offerListSubFilter) {
        return false;
    }

    return floorFilterFriendlyQueryValues.includes(offerListSubFilter);
}
