import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IGetOfferDetailPoiResponse} from "../../../maps/api/get_offer_detail_poi";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IOfferArticle} from "../../list/actions/fetch_offer_list_articles";
import {IRelatedOfferList} from "../../list/types/IOfferListResponse";
import {IOfferDetail} from "../../types/IOfferDetail";
import {fetchOfferDetailTypes} from "../actions/fetch_offer_detail";
import {fetchOfferDetailArticlesTypes} from "../actions/fetch_offer_detail_articles";
import {fetchOfferDetailRelatedOffersTypes} from "../actions/fetch_offer_detail_offers_list";
import {fetchOfferDetailPoiStatsTypes, IPoiStatsResponse} from "../actions/fetch_offer_detail_poi";
import {fetchOfferDetailPromotionsTypes, IOfferDetailPromotion} from "../actions/fetch_offer_detail_promotions";
import {fetchOfferDetailRecommendedRegionsTypes, IRecommendedRegion} from "../actions/fetch_offer_detail_recommended_regions";
import {fetchOfferDetailTransportPoi} from "../actions/fetch_offer_transport_poi_at_route";
import {fetchRecommendedOfferTypes} from "../actions/fetch_recommended_offers";
import {IOfferPropertiesStore, offerDetailPropertiesReducer} from "./offer_detail_property_reducer";

export interface IOfferDetailStore {
    offer: IOfferDetail | null;
    offerRequest: RequestState;
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    // promotions
    promotions: IOfferDetailPromotion[];
    promotionsRequest: RequestState;
    // properties
    properties: IOfferPropertiesStore;
    propertiesCount: number | null;
    propertiesRequest: RequestState;
    // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: IRelatedOfferList[];
    relatedOffersRequestState: RequestState;
    // nearby regions for sidebar
    recommendedRegions: IRecommendedRegion[];
    recommendedRegionsRequestState: RequestState;
    recommendedOffers: IRecommendedOffer[];
    poisStats: IPoiStatsResponse | null;
    transportPoi: IGetOfferDetailPoiResponse | null;
}

export const offerDetailReducer = combineReducers({
    offer: reduceResponse<IOfferDetail>(fetchOfferDetailTypes),
    offerRequest: reduceRequestState(fetchOfferDetailTypes),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchOfferDetailArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferDetailArticlesTypes),
    // promotions
    promotions: reduceResponse<IOfferDetailPromotion[]>(fetchOfferDetailPromotionsTypes, []),
    promotionsRequest: reduceRequestState(fetchOfferDetailPromotionsTypes),
    properties: offerDetailPropertiesReducer,
    // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: reduceResponseField<IRelatedOfferList[]>(fetchOfferDetailRelatedOffersTypes, "offers", []),
    relatedOffersRequestState: reduceRequestState(fetchOfferDetailRelatedOffersTypes),
    // nearby regions for sidebar
    recommendedRegions: reduceResponse<IRecommendedRegion[]>(fetchOfferDetailRecommendedRegionsTypes, []),
    recommendedRegionsRequestState: reduceRequestState(fetchOfferDetailRecommendedRegionsTypes),
    recommendedOffers: reduceResponse<IRecommendedOffer[]>(fetchRecommendedOfferTypes, []),
    poisStats: reduceResponse<IPoiStatsResponse>(fetchOfferDetailPoiStatsTypes, null),
    transportPoi: reduceResponse<IGetOfferDetailPoiResponse>(fetchOfferDetailTransportPoi, null)
});
import {combineReducers} from "redux";

import {reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {IGetOfferDetailPoiResponse} from "../../../maps/api/get_offer_detail_poi";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IOfferArticle} from "../../list/actions/fetch_offer_list_articles";
import {IRelatedOfferList} from "../../list/types/IOfferListResponse";
import {IOfferDetail} from "../../types/IOfferDetail";
import {fetchOfferDetailTypes} from "../actions/fetch_offer_detail";
import {fetchOfferDetailArticlesTypes} from "../actions/fetch_offer_detail_articles";
import {fetchOfferDetailRelatedOffersTypes} from "../actions/fetch_offer_detail_offers_list";
import {fetchOfferDetailPoiStatsTypes, IPoiStatsResponse} from "../actions/fetch_offer_detail_poi";
import {fetchOfferDetailPromotionsTypes, IOfferDetailPromotion} from "../actions/fetch_offer_detail_promotions";
import {fetchOfferDetailRecommendedRegionsTypes, IRecommendedRegion} from "../actions/fetch_offer_detail_recommended_regions";
import {fetchOfferDetailTransportPoi} from "../actions/fetch_offer_transport_poi_at_route";
import {fetchRecommendedOfferTypes} from "../actions/fetch_recommended_offers";
import {IOfferPropertiesStore, offerDetailPropertiesReducer} from "./offer_detail_property_reducer";

export interface IOfferDetailStore {
    offer: IOfferDetail | null;
    offerRequest: RequestState;
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    // promotions
    promotions: IOfferDetailPromotion[];
    promotionsRequest: RequestState;
    // properties
    properties: IOfferPropertiesStore;
    propertiesCount: number | null;
    propertiesRequest: RequestState;
    // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: IRelatedOfferList[];
    relatedOffersRequestState: RequestState;
    // nearby regions for sidebar
    recommendedRegions: IRecommendedRegion[];
    recommendedRegionsRequestState: RequestState;
    recommendedOffers: IRecommendedOffer[];
    poisStats: IPoiStatsResponse | null;
    transportPoi: IGetOfferDetailPoiResponse | null;
}

export const offerDetailReducer = combineReducers({
    offer: reduceResponse<IOfferDetail>(fetchOfferDetailTypes),
    offerRequest: reduceRequestState(fetchOfferDetailTypes),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchOfferDetailArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferDetailArticlesTypes),
    // promotions
    promotions: reduceResponse<IOfferDetailPromotion[]>(fetchOfferDetailPromotionsTypes, []),
    promotionsRequest: reduceRequestState(fetchOfferDetailPromotionsTypes),
    properties: offerDetailPropertiesReducer,
    // Related offers (limited presentation purpose) with near_by_offer and distance filter
    relatedOffers: reduceResponseField<IRelatedOfferList[]>(fetchOfferDetailRelatedOffersTypes, "offers", []),
    relatedOffersRequestState: reduceRequestState(fetchOfferDetailRelatedOffersTypes),
    // nearby regions for sidebar
    recommendedRegions: reduceResponse<IRecommendedRegion[]>(fetchOfferDetailRecommendedRegionsTypes, []),
    recommendedRegionsRequestState: reduceRequestState(fetchOfferDetailRecommendedRegionsTypes),
    recommendedOffers: reduceResponse<IRecommendedOffer[]>(fetchRecommendedOfferTypes, []),
    poisStats: reduceResponse<IPoiStatsResponse>(fetchOfferDetailPoiStatsTypes, null),
    transportPoi: reduceResponse<IGetOfferDetailPoiResponse>(fetchOfferDetailTransportPoi, null)
});
