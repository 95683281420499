import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Country} from "../../../region/types/Country";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {getFriendlyOfferListLinkParams} from "./get_friendly_offer_list_link_params";
import {offerUrlBuilder} from "./offer_url_builder";

export const buildFriendlyOfferListLink = (query: Partial<IFriendlyQuery>): string => {
    const {friendlySlug, restQueryValues} = getFriendlyOfferListLinkParams(query);
    if (query.country && query.country !== Country.POLAND) {
        const {friendlySlug, countrySlug} = offerUrlBuilder({type: query.type, country: query.country});
        return rpAppLink.offer.listFriendlyAbroad({friendlySlug, country: countrySlug, abroadRegionSlug: query.region});
    }

    return appendQueryString(rpAppLink.offer.listFriendly({friendlySlug}), restQueryValues);
};
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {Country} from "../../../region/types/Country";
import {IFriendlyQuery} from "../../list/types/friendly_query";
import {getFriendlyOfferListLinkParams} from "./get_friendly_offer_list_link_params";
import {offerUrlBuilder} from "./offer_url_builder";

export const buildFriendlyOfferListLink = (query: Partial<IFriendlyQuery>): string => {
    const {friendlySlug, restQueryValues} = getFriendlyOfferListLinkParams(query);
    if (query.country && query.country !== Country.POLAND) {
        const {friendlySlug, countrySlug} = offerUrlBuilder({type: query.type, country: query.country});
        return rpAppLink.offer.listFriendlyAbroad({friendlySlug, country: countrySlug, abroadRegionSlug: query.region});
    }

    return appendQueryString(rpAppLink.offer.listFriendly({friendlySlug}), restQueryValues);
};
