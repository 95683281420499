import {pluralize} from "@pg-mono/string-utils";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {IVendorRegion} from "../../vendor/actions/fetch_vendor_list_region";
import {generateMetaPageNumber} from "../utils/generate_meta_page_number";
import {generateMetaRegionNameDeclensionWhere} from "../utils/generate_meta_region_name_declension_where";

export interface IDescriptionVendorList {
    count: number;
    region: IVendorRegion | null;
    page: number;
}

export const descriptionVendorList = (props: IDescriptionVendorList) => {
    const vendorsCountPluralize = pluralize(["firma", "firmy", "firm"]);
    const regionName = props.region === null ? "w Polsce" : generateMetaRegionNameDeclensionWhere(props.region);

    return `Zobacz wszystkie firmy deweloperskie realizujące projekty mieszkaniowe ${regionName}${generateMetaPageNumber(props.page)} - Przeglądaj ofertę ${
        props.count
    } ${vendorsCountPluralize(props.count)} ${concatWithBrandName("na")}!`;
};
import {pluralize} from "@pg-mono/string-utils";

import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";
import {IVendorRegion} from "../../vendor/actions/fetch_vendor_list_region";
import {generateMetaPageNumber} from "../utils/generate_meta_page_number";
import {generateMetaRegionNameDeclensionWhere} from "../utils/generate_meta_region_name_declension_where";

export interface IDescriptionVendorList {
    count: number;
    region: IVendorRegion | null;
    page: number;
}

export const descriptionVendorList = (props: IDescriptionVendorList) => {
    const vendorsCountPluralize = pluralize(["firma", "firmy", "firm"]);
    const regionName = props.region === null ? "w Polsce" : generateMetaRegionNameDeclensionWhere(props.region);

    return `Zobacz wszystkie firmy deweloperskie realizujące projekty mieszkaniowe ${regionName}${generateMetaPageNumber(props.page)} - Przeglądaj ofertę ${
        props.count
    } ${vendorsCountPluralize(props.count)} ${concatWithBrandName("na")}!`;
};
