import {useEffect, useState} from "react";

import {ASKED_ABOUT_LOCATION} from "../../app/constants/storage_keys";
import {sessionCache} from "../../utils/session_cache";

export const useGeolocationStorage = () => {
    const [locationAsked, setLocationAsked] = useState<boolean>(false);

    const checkGeolocationStorage = () => {
        return sessionCache.get<boolean>(ASKED_ABOUT_LOCATION) || false;
    };

    useEffect(() => {
        const locationInfo = checkGeolocationStorage();

        if (locationInfo) {
            setLocationAsked(locationInfo);
        }
    }, []);

    const saveGeolocationStorage = () => {
        sessionCache.set(ASKED_ABOUT_LOCATION, true);
        setLocationAsked(true);
    };

    return {locationAsked, checkGeolocationStorage, saveGeolocationStorage};
};
import {useEffect, useState} from "react";

import {ASKED_ABOUT_LOCATION} from "../../app/constants/storage_keys";
import {sessionCache} from "../../utils/session_cache";

export const useGeolocationStorage = () => {
    const [locationAsked, setLocationAsked] = useState<boolean>(false);

    const checkGeolocationStorage = () => {
        return sessionCache.get<boolean>(ASKED_ABOUT_LOCATION) || false;
    };

    useEffect(() => {
        const locationInfo = checkGeolocationStorage();

        if (locationInfo) {
            setLocationAsked(locationInfo);
        }
    }, []);

    const saveGeolocationStorage = () => {
        sessionCache.set(ASKED_ABOUT_LOCATION, true);
        setLocationAsked(true);
    };

    return {locationAsked, checkGeolocationStorage, saveGeolocationStorage};
};
