import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fetchSoldVendorListTypes, fetchVendorListTypes, VENDOR_LIST_PAGE_SIZE} from "../actions/fetch_vendor_list";
import {fetchVendorRegionTypes, IVendorRegion} from "../actions/fetch_vendor_list_region";
import {fetchVendorRegionSEOMetadataTypes, IVendorRegionSEOMetadata} from "../actions/fetch_vendor_region_seo_metadata";
import {IVendorBoxVendor} from "../components/VendorBox";

export interface IVendorListStore {
    vendors: IVendorBoxVendor[];
    soldVendors: IVendorBoxVendor[];
    soldVendorsCount: number;
    allOffersCount: number;
    region: IVendorRegion[] | null;
    count: number;
    pageSize: number;
    page: number;
    requestState: RequestState;
    latestQuery: Record<string, string | string[]>;
    regionSEOMetadata: IVendorRegionSEOMetadata | null;
}

export const vendorListReducer = combineReducers({
    vendors: reduceResponseField<IVendorBoxVendor[]>(fetchVendorListTypes, "vendors", []),
    soldVendors: reduceResponseField<IVendorBoxVendor[]>(fetchSoldVendorListTypes, "vendors", []),
    soldVendorsCount: reduceResponseField<number>(fetchSoldVendorListTypes, "count", 0),
    allOffersCount: reduceResponseField<number>(fetchVendorListTypes, "allOffersCount", 0),
    region: reduceResponse<IVendorRegion>(fetchVendorRegionTypes),
    count: reduceResponseField<number>(fetchVendorListTypes, "count", 0),
    pageSize: reduceResponseField<number>(fetchVendorListTypes, "pageSize", VENDOR_LIST_PAGE_SIZE),
    page: reduceResponseField<number>(fetchVendorListTypes, "page", 1),
    requestState: reduceRequestState(fetchVendorListTypes),
    latestQuery: reduceLatestQuery(fetchVendorListTypes),
    regionSEOMetadata: reduceResponse(fetchVendorRegionSEOMetadataTypes, null)
});
import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, reduceResponseField, RequestState} from "@pg-mono/request-state";

import {fetchSoldVendorListTypes, fetchVendorListTypes, VENDOR_LIST_PAGE_SIZE} from "../actions/fetch_vendor_list";
import {fetchVendorRegionTypes, IVendorRegion} from "../actions/fetch_vendor_list_region";
import {fetchVendorRegionSEOMetadataTypes, IVendorRegionSEOMetadata} from "../actions/fetch_vendor_region_seo_metadata";
import {IVendorBoxVendor} from "../components/VendorBox";

export interface IVendorListStore {
    vendors: IVendorBoxVendor[];
    soldVendors: IVendorBoxVendor[];
    soldVendorsCount: number;
    allOffersCount: number;
    region: IVendorRegion[] | null;
    count: number;
    pageSize: number;
    page: number;
    requestState: RequestState;
    latestQuery: Record<string, string | string[]>;
    regionSEOMetadata: IVendorRegionSEOMetadata | null;
}

export const vendorListReducer = combineReducers({
    vendors: reduceResponseField<IVendorBoxVendor[]>(fetchVendorListTypes, "vendors", []),
    soldVendors: reduceResponseField<IVendorBoxVendor[]>(fetchSoldVendorListTypes, "vendors", []),
    soldVendorsCount: reduceResponseField<number>(fetchSoldVendorListTypes, "count", 0),
    allOffersCount: reduceResponseField<number>(fetchVendorListTypes, "allOffersCount", 0),
    region: reduceResponse<IVendorRegion>(fetchVendorRegionTypes),
    count: reduceResponseField<number>(fetchVendorListTypes, "count", 0),
    pageSize: reduceResponseField<number>(fetchVendorListTypes, "pageSize", VENDOR_LIST_PAGE_SIZE),
    page: reduceResponseField<number>(fetchVendorListTypes, "page", 1),
    requestState: reduceRequestState(fetchVendorListTypes),
    latestQuery: reduceLatestQuery(fetchVendorListTypes),
    regionSEOMetadata: reduceResponse(fetchVendorRegionSEOMetadataTypes, null)
});
