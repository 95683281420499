import {useEffect} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../app/rp_reducer";
import {viewSessionTimeHit} from "../tracking/algolytics_hits/view_session_time_hit";

export const useSessionTracking = () => {
    const currentViewType = useSelector((store: IRPStore) => store.viewType.current);

    useEffect(() => {
        const visibilityStartTime = getCurrentTime();

        const handleVisibilityChange = () => {
            const visibilityChangeTime = getCurrentTime();

            if (document.hidden) {
                viewSessionTimeHit(visibilityStartTime, visibilityChangeTime, window.location.pathname, currentViewType);
            }
        };

        const handleUnloadAndBlur = () => {
            const beforeUnloadTime = getCurrentTime();

            viewSessionTimeHit(visibilityStartTime, beforeUnloadTime, window.location.pathname, currentViewType);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("beforeunload", handleUnloadAndBlur);
        window.addEventListener("blur", handleUnloadAndBlur);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("beforeunload", handleUnloadAndBlur);
            window.removeEventListener("blur", handleUnloadAndBlur);
        };
    }, []);

    return;
};

function getCurrentTime() {
    return new Date().getTime();
}
import {useEffect} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../app/rp_reducer";
import {viewSessionTimeHit} from "../tracking/algolytics_hits/view_session_time_hit";

export const useSessionTracking = () => {
    const currentViewType = useSelector((store: IRPStore) => store.viewType.current);

    useEffect(() => {
        const visibilityStartTime = getCurrentTime();

        const handleVisibilityChange = () => {
            const visibilityChangeTime = getCurrentTime();

            if (document.hidden) {
                viewSessionTimeHit(visibilityStartTime, visibilityChangeTime, window.location.pathname, currentViewType);
            }
        };

        const handleUnloadAndBlur = () => {
            const beforeUnloadTime = getCurrentTime();

            viewSessionTimeHit(visibilityStartTime, beforeUnloadTime, window.location.pathname, currentViewType);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("beforeunload", handleUnloadAndBlur);
        window.addEventListener("blur", handleUnloadAndBlur);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            window.removeEventListener("beforeunload", handleUnloadAndBlur);
            window.removeEventListener("blur", handleUnloadAndBlur);
        };
    }, []);

    return;
};

function getCurrentTime() {
    return new Date().getTime();
}
