export interface ITrackedProfile {
    uuid: string;
    name: string | null;
    phone: string | null;
    email: string | null;
}

export const getTrackedProfileData = (profile: ITrackedProfile) => ({
    // For now always send 1 - mean user was registered via email
    profile_type: 1,
    /*
     * Before kontomieszkaniowe.pl production deployment we used numerical id - for security reasons we have stopped doing this.
     * We are currently sending uuid, but `rp_id` left for data consistency in the analytics database.
     */
    rp_id: null,
    rp_id_uuid: profile.uuid,
    email: profile.email,
    name: profile.name,
    phone: profile.phone
});
export interface ITrackedProfile {
    uuid: string;
    name: string | null;
    phone: string | null;
    email: string | null;
}

export const getTrackedProfileData = (profile: ITrackedProfile) => ({
    // For now always send 1 - mean user was registered via email
    profile_type: 1,
    /*
     * Before kontomieszkaniowe.pl production deployment we used numerical id - for security reasons we have stopped doing this.
     * We are currently sending uuid, but `rp_id` left for data consistency in the analytics database.
     */
    rp_id: null,
    rp_id_uuid: profile.uuid,
    email: profile.email,
    name: profile.name,
    phone: profile.phone
});
