import {combineReducers} from "redux";

import {articleDetailReducer, IArticleDetailStore} from "./article_detail_reducer";
import {articleListReducer, IArticleListStore} from "./article_list_reducer";
import {authorDetailReducer, IAuthorsDetailStore} from "./author_detail_reducer";
import {authorsListReducer, IAuthorsListStore} from "./authors_list_reducer";

export interface IArticleStore {
    detail: IArticleDetailStore;
    list: IArticleListStore;
    authors: IAuthorsListStore;
    authorDetail: IAuthorsDetailStore;
}

export const articleReducer = combineReducers({
    detail: articleDetailReducer,
    list: articleListReducer,
    authors: authorsListReducer,
    authorDetail: authorDetailReducer
});
import {combineReducers} from "redux";

import {articleDetailReducer, IArticleDetailStore} from "./article_detail_reducer";
import {articleListReducer, IArticleListStore} from "./article_list_reducer";
import {authorDetailReducer, IAuthorsDetailStore} from "./author_detail_reducer";
import {authorsListReducer, IAuthorsListStore} from "./authors_list_reducer";

export interface IArticleStore {
    detail: IArticleDetailStore;
    list: IArticleListStore;
    authors: IAuthorsListStore;
    authorDetail: IAuthorsDetailStore;
}

export const articleReducer = combineReducers({
    detail: articleDetailReducer,
    list: articleListReducer,
    authors: authorsListReducer,
    authorDetail: authorDetailReducer
});
