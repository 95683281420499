/* eslint-disable @typescript-eslint/no-explicit-any */
import type {Dispatch} from "redux";

import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiPath} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {rpEnable404ResponseState} from "./rp_enable_404_response_state";

// Used by server and `redirectOrEnable404ResponseState`
export const fetch404Redirect = (appUrl: string, meta: IRPRequestMeta): Promise<string | null> => {
    const url = appendQueryString(apiPath.redirect.redirect, {url: appUrl});

    return getRequest(meta, url)
        .then((json: {new_path: string}) => {
            return json.new_path;
        })
        .catch(
            catch404(() => {
                return null;
            })
        );
};

export const redirectOrEnable404ResponseState = (pathname: string, meta: IRPRequestMeta) => async (dispatch: Dispatch) => {
    const newPath = await fetch404Redirect(pathname, meta);

    if (newPath) {
        dispatch(enable301ResponseState(newPath));
    } else {
        dispatch(rpEnable404ResponseState(pathname));
    }

    return Promise.resolve(newPath);
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import type {Dispatch} from "redux";

import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {enable301ResponseState} from "@pg-mono/response-state";
import {apiPath} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {rpEnable404ResponseState} from "./rp_enable_404_response_state";

// Used by server and `redirectOrEnable404ResponseState`
export const fetch404Redirect = (appUrl: string, meta: IRPRequestMeta): Promise<string | null> => {
    const url = appendQueryString(apiPath.redirect.redirect, {url: appUrl});

    return getRequest(meta, url)
        .then((json: {new_path: string}) => {
            return json.new_path;
        })
        .catch(
            catch404(() => {
                return null;
            })
        );
};

export const redirectOrEnable404ResponseState = (pathname: string, meta: IRPRequestMeta) => async (dispatch: Dispatch) => {
    const newPath = await fetch404Redirect(pathname, meta);

    if (newPath) {
        dispatch(enable301ResponseState(newPath));
    } else {
        dispatch(rpEnable404ResponseState(pathname));
    }

    return Promise.resolve(newPath);
};
