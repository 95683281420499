import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

export const description404Error =
    "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty dla inwestorów, " +
    `aktualne promocje oraz wiadomości z rynku nieruchomości &#10004;. ${concatWithBrandName("Portal")} zaprasza &#128077;`;
import {concatWithBrandName} from "../../app/utils/concat_with_brand_name";

export const description404Error =
    "Wszystkie oferty nowych mieszkań i domów od deweloperów w jednym miejscu, oferty dla inwestorów, " +
    `aktualne promocje oraz wiadomości z rynku nieruchomości &#10004;. ${concatWithBrandName("Portal")} zaprasza &#128077;`;
