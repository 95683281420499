import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IPaginatedResponse} from "../../request/IResponse";

export interface ISitemapVendorListVendor {
    id: number;
    name: string;
    slug: string;
    is_indexing_allowed: boolean;
}

interface ISiteMapVendorListResponse extends IPaginatedResponse {
    results: ISitemapVendorListVendor[];
}

const PAGE_SIZE = 50;

const FETCH_SITE_MAP_VENDOR_LIST = "SITE_MAP/VENDOR_LIST";
export const fetchSiteMapVendorListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_VENDOR_LIST);

const siteMapVendorListApiLink = apiV2ListLink.vendor.list(Scenario.SITEMAP_VENDOR_LIST);

export const fetchSiteMapVendorList = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchSiteMapVendorListActionTypes.start});

    const fullQuery = {
        page_size: PAGE_SIZE,
        sort: "accuracy",
        is_indexing_allowed: "True"
    };

    const url = appendQueryString(siteMapVendorListApiLink, fullQuery);

    return getRequest(ctx.meta, url)
        .then((json: ISiteMapVendorListResponse) => {
            dispatch({type: fetchSiteMapVendorListActionTypes.success, result: json.results});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {redirectOrEnable404ResponseState} from "../../errors/actions/page_404_actions";
import {IPaginatedResponse} from "../../request/IResponse";

export interface ISitemapVendorListVendor {
    id: number;
    name: string;
    slug: string;
    is_indexing_allowed: boolean;
}

interface ISiteMapVendorListResponse extends IPaginatedResponse {
    results: ISitemapVendorListVendor[];
}

const PAGE_SIZE = 50;

const FETCH_SITE_MAP_VENDOR_LIST = "SITE_MAP/VENDOR_LIST";
export const fetchSiteMapVendorListActionTypes = createRequestActionTypes(FETCH_SITE_MAP_VENDOR_LIST);

const siteMapVendorListApiLink = apiV2ListLink.vendor.list(Scenario.SITEMAP_VENDOR_LIST);

export const fetchSiteMapVendorList = (ctx: IFetchContext<IRPRequestMeta>) => (dispatch: Dispatch) => {
    dispatch({type: fetchSiteMapVendorListActionTypes.start});

    const fullQuery = {
        page_size: PAGE_SIZE,
        sort: "accuracy",
        is_indexing_allowed: "True"
    };

    const url = appendQueryString(siteMapVendorListApiLink, fullQuery);

    return getRequest(ctx.meta, url)
        .then((json: ISiteMapVendorListResponse) => {
            dispatch({type: fetchSiteMapVendorListActionTypes.success, result: json.results});
            return json;
        })
        .catch(
            catch404(async () => {
                await dispatch(redirectOrEnable404ResponseState(ctx.route.pathname, ctx.meta));
            })
        );
};
