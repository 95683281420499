import {hitAlgolytics} from "@pg-mono/algolytics";

import {ViewType} from "../../view_type/ViewType";
import {getUserSourceData, UserSource} from "../tracking_utils/user_source";
import {getTrackedProfileData, ITrackedProfile} from "./tracked_utils/tracked_profile";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const userSignInHit = (source: UserSource, profile: ITrackedProfile, viewType?: ViewType | null) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getTrackedProfileData(profile),
        ...getUserSourceData(source),
        ...(viewType ? {view_type: viewType} : {})
    };
    hitAlgolytics("user_login", payload);
};

export const signInResetPasswordSuccessHit = (source: UserSource, login: string, viewType?: ViewType | null) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getUserSourceData(source),
        login,
        ...(viewType ? {view_type: viewType} : {})
    };
    hitAlgolytics("sign_in_reset_password", payload);
};
import {hitAlgolytics} from "@pg-mono/algolytics";

import {ViewType} from "../../view_type/ViewType";
import {getUserSourceData, UserSource} from "../tracking_utils/user_source";
import {getTrackedProfileData, ITrackedProfile} from "./tracked_utils/tracked_profile";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";

export const userSignInHit = (source: UserSource, profile: ITrackedProfile, viewType?: ViewType | null) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getTrackedProfileData(profile),
        ...getUserSourceData(source),
        ...(viewType ? {view_type: viewType} : {})
    };
    hitAlgolytics("user_login", payload);
};

export const signInResetPasswordSuccessHit = (source: UserSource, login: string, viewType?: ViewType | null) => {
    const payload = {
        ...getTrackedSiteData(),
        ...getUserSourceData(source),
        login,
        ...(viewType ? {view_type: viewType} : {})
    };
    hitAlgolytics("sign_in_reset_password", payload);
};
