import React from "react";
import {css} from "@emotion/react";

import {flex, mb, onDesktop, p} from "@pg-design/helpers-css";
import {Radio} from "@pg-design/radio-module";

import {SEARCH_DISTANCE_VALUES} from "../../offer/list/constants/offer_list";
import {SearchFilterLabel} from "./filters/SearchFilterLabel";

interface IProps {
    error?: string[];
    name: string;
    label: string;
    id: string;
    onChange: (fieldName: string, value: number) => void;
    value: number | [];
    className?: string;
}

export const OfferDistanceSwitch = (props: IProps) => {
    const {name, onChange} = props;
    const value = +props.value;

    const onDistanceChange = (newValue: number) => {
        onChange(name, newValue);
    };

    return (
        <>
            <SearchFilterLabel htmlFor={props.id} label={props.label} />
            <div css={[offerTypeHolder]} className={props.className}>
                {SEARCH_DISTANCE_VALUES.map((distance) => (
                    <Radio
                        key={distance}
                        id={`distance-${distance}`}
                        labelContent={<div css={[radioLabel]}>{distance} km</div>}
                        isActive={distance === value}
                        name="distance"
                        value={value}
                        onChange={() => onDistanceChange(distance)}
                    />
                ))}
            </div>
        </>
    );
};

const offerTypeHolder = css`
    ${flex("center", "space-between")};
    ${mb(2)};
    flex-shrink: 0;
    flex-grow: 0;

    button:last-of-type {
        margin-right: 0;
    }

    ${onDesktop(css`
        flex-direction: column;
        align-items: start;
    `)};
`;

const radioLabel = css`
    ${p(0.5)};
    width: 25rem;
`;
import React from "react";
import {css} from "@emotion/react";

import {flex, mb, onDesktop, p} from "@pg-design/helpers-css";
import {Radio} from "@pg-design/radio-module";

import {SEARCH_DISTANCE_VALUES} from "../../offer/list/constants/offer_list";
import {SearchFilterLabel} from "./filters/SearchFilterLabel";

interface IProps {
    error?: string[];
    name: string;
    label: string;
    id: string;
    onChange: (fieldName: string, value: number) => void;
    value: number | [];
    className?: string;
}

export const OfferDistanceSwitch = (props: IProps) => {
    const {name, onChange} = props;
    const value = +props.value;

    const onDistanceChange = (newValue: number) => {
        onChange(name, newValue);
    };

    return (
        <>
            <SearchFilterLabel htmlFor={props.id} label={props.label} />
            <div css={[offerTypeHolder]} className={props.className}>
                {SEARCH_DISTANCE_VALUES.map((distance) => (
                    <Radio
                        key={distance}
                        id={`distance-${distance}`}
                        labelContent={<div css={[radioLabel]}>{distance} km</div>}
                        isActive={distance === value}
                        name="distance"
                        value={value}
                        onChange={() => onDistanceChange(distance)}
                    />
                ))}
            </div>
        </>
    );
};

const offerTypeHolder = css`
    ${flex("center", "space-between")};
    ${mb(2)};
    flex-shrink: 0;
    flex-grow: 0;

    button:last-of-type {
        margin-right: 0;
    }

    ${onDesktop(css`
        flex-direction: column;
        align-items: start;
    `)};
`;

const radioLabel = css`
    ${p(0.5)};
    width: 25rem;
`;
