import React from "react";
import {ThemeProvider} from "@emotion/react";

import {themeRP} from "./theme_rp/theme_rp";

interface IProps {
    children: React.ReactNode;
}

export const DSThemeProvider: React.FC<IProps> = (props) => {
    // TODO: This ignore shouldn't be necessary after RP rebranding
    // Currently in `apps/rp/src/common/typings/custom/emotion.d.ts` `Theme` interface is extended by two interfaces
    // for new and old theming - this causes typing errors.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <ThemeProvider theme={themeRP}>{props.children}</ThemeProvider>;
};
import React from "react";
import {ThemeProvider} from "@emotion/react";

import {themeRP} from "./theme_rp/theme_rp";

interface IProps {
    children: React.ReactNode;
}

export const DSThemeProvider: React.FC<IProps> = (props) => {
    // TODO: This ignore shouldn't be necessary after RP rebranding
    // Currently in `apps/rp/src/common/typings/custom/emotion.d.ts` `Theme` interface is extended by two interfaces
    // for new and old theming - this causes typing errors.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <ThemeProvider theme={themeRP}>{props.children}</ThemeProvider>;
};
