import {Country} from "../../region/types/Country";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {HolidayLocationType} from "../../types/HolidayLocationType";
import {investmentOfferCities, investmentOfferSpainCities, InvestmentOfferSubTypeSlug, InvestmentOfferTypeSlug} from "../constants/investment_offer_constants";

export const getInvestmentOfferListQuery = (params: {category: string | null; subcategory?: string | null}) => {
    if (!params) {
        return null;
    }
    if (!params.category) {
        return {};
    }
    return {
        ...getInvestmentOfferTypeQuery(params.category),
        ...getLocationTypesQuery(params.subcategory)
    };
};

const getInvestmentOfferTypeQuery = (category: string) => {
    switch (category) {
        case InvestmentOfferTypeSlug.HOLIDAY:
            return {is_holiday: true};
        case InvestmentOfferTypeSlug.FOR_RENT:
            return {is_dedicated_for_rent: true};
        case InvestmentOfferTypeSlug.CONDOHOTEL:
            return {is_condohotel: true};
        case InvestmentOfferTypeSlug.INVESTMENT:
            return {is_investment_apartment: true};
        case InvestmentOfferTypeSlug.ABROAD:
            return {country: investmentOfferSpainCities.map(({country}) => country.toString())};
        default:
            return {};
    }
};

const getLocationTypesQuery = (subcategory?: string | null) => {
    if (subcategory) {
        switch (subcategory) {
            case InvestmentOfferSubTypeSlug.SEA:
                return {is_holiday: true, holiday_location: HolidayLocationType.SEA};
            case InvestmentOfferSubTypeSlug.MOUNTAIN:
                return {is_holiday: true, holiday_location: HolidayLocationType.MOUNTAINS};
            case InvestmentOfferSubTypeSlug.LAKE:
                return {is_holiday: true, holiday_location: HolidayLocationType.LAKE};

            default:
                if (isSpainInvestmentCategory(subcategory)) {
                    return {country: Country.SPAIN.toString()};
                }
                const region = investmentOfferCities.find((city) => subcategory === city.slug);

                return {region: region?.id};
        }
    }
    return {};
};
import {Country} from "../../region/types/Country";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {HolidayLocationType} from "../../types/HolidayLocationType";
import {investmentOfferCities, investmentOfferSpainCities, InvestmentOfferSubTypeSlug, InvestmentOfferTypeSlug} from "../constants/investment_offer_constants";

export const getInvestmentOfferListQuery = (params: {category: string | null; subcategory?: string | null}) => {
    if (!params) {
        return null;
    }
    if (!params.category) {
        return {};
    }
    return {
        ...getInvestmentOfferTypeQuery(params.category),
        ...getLocationTypesQuery(params.subcategory)
    };
};

const getInvestmentOfferTypeQuery = (category: string) => {
    switch (category) {
        case InvestmentOfferTypeSlug.HOLIDAY:
            return {is_holiday: true};
        case InvestmentOfferTypeSlug.FOR_RENT:
            return {is_dedicated_for_rent: true};
        case InvestmentOfferTypeSlug.CONDOHOTEL:
            return {is_condohotel: true};
        case InvestmentOfferTypeSlug.INVESTMENT:
            return {is_investment_apartment: true};
        case InvestmentOfferTypeSlug.ABROAD:
            return {country: investmentOfferSpainCities.map(({country}) => country.toString())};
        default:
            return {};
    }
};

const getLocationTypesQuery = (subcategory?: string | null) => {
    if (subcategory) {
        switch (subcategory) {
            case InvestmentOfferSubTypeSlug.SEA:
                return {is_holiday: true, holiday_location: HolidayLocationType.SEA};
            case InvestmentOfferSubTypeSlug.MOUNTAIN:
                return {is_holiday: true, holiday_location: HolidayLocationType.MOUNTAINS};
            case InvestmentOfferSubTypeSlug.LAKE:
                return {is_holiday: true, holiday_location: HolidayLocationType.LAKE};

            default:
                if (isSpainInvestmentCategory(subcategory)) {
                    return {country: Country.SPAIN.toString()};
                }
                const region = investmentOfferCities.find((city) => subcategory === city.slug);

                return {region: region?.id};
        }
    }
    return {};
};
