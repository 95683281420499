import React, {ElementType, ReactNode, useContext} from "react";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";

import {IModalProps} from "../../types/IModalProps";
import {ModalContext} from "../ModalContext";
import {ModalCloseButton} from "./ModalCloseButton";

import * as styles from "./ModalHeader.module.css";

interface IModalHeaderProps {
    as?: ElementType;
    variant?: "clean" | "bar" | "bar_mini";
    children?: ReactNode;
    showCloseButton?: boolean;
    className?: string;
}

const getTextVariant = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "clean":
            return "headline_3";
        case "bar":
            return "headline_6";
        default:
            return "body_copy_2";
    }
};

const getVariantColors = (headerVariant: IModalHeaderProps["variant"], modalTheme: IModalProps["modalTheme"]) => {
    switch (headerVariant) {
        case "bar":
        case "bar_mini":
            if (modalTheme === "light") {
                return {
                    backgroundColor: getThemeVariable("colors.gray.100"),
                    color: getThemeVariable("colors.secondary"),
                    closeButtonBackgroundColor: getThemeVariable("colors.gray.200"),
                    closeButtonColor: getThemeVariable("colors.secondary")
                };
            } else {
                return {
                    backgroundColor: getThemeVariable("colors.secondary"),
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
        default:
            if (modalTheme === "light") {
                return {
                    backgroundColor: "#fff",
                    color: getThemeVariable("colors.secondary"),
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: getThemeVariable("colors.secondary")
                };
            } else {
                return {
                    backgroundColor: getThemeVariable("colors.secondary"),
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
    }
};

const getIconSize = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "bar_mini":
            return "1.4";
        default:
            return "2.4";
    }
};

export const ModalHeader = (props: IModalHeaderProps) => {
    const {variant = "clean", showCloseButton = true, children} = props;
    const {modalTheme} = useContext(ModalContext);

    const variantColors = getVariantColors(variant, modalTheme);
    const iconSize = getIconSize(variant);

    const cn = classNames(props.className, styles.modalHeaderWrapperStyle);

    const style = {
        backgroundColor: variantColors.backgroundColor
    };

    return (
        <div style={style} className={cn}>
            <Text as={props.as ?? "h3"} variant={getTextVariant(variant)} color={variantColors.color} className={styles.headerStyle}>
                {children}
            </Text>
            {showCloseButton && (
                <ModalCloseButton size={iconSize} wrapperColor={variantColors.closeButtonBackgroundColor} fillColor={variantColors.closeButtonColor} />
            )}
        </div>
    );
};
import React, {ElementType, ReactNode, useContext} from "react";
import classNames from "classnames";

import {getThemeVariable} from "@pg-design/styles-strings";
import {Text} from "@pg-design/text-module";

import {IModalProps} from "../../types/IModalProps";
import {ModalContext} from "../ModalContext";
import {ModalCloseButton} from "./ModalCloseButton";

import * as styles from "./ModalHeader.module.css";

interface IModalHeaderProps {
    as?: ElementType;
    variant?: "clean" | "bar" | "bar_mini";
    children?: ReactNode;
    showCloseButton?: boolean;
    className?: string;
}

const getTextVariant = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "clean":
            return "headline_3";
        case "bar":
            return "headline_6";
        default:
            return "body_copy_2";
    }
};

const getVariantColors = (headerVariant: IModalHeaderProps["variant"], modalTheme: IModalProps["modalTheme"]) => {
    switch (headerVariant) {
        case "bar":
        case "bar_mini":
            if (modalTheme === "light") {
                return {
                    backgroundColor: getThemeVariable("colors.gray.100"),
                    color: getThemeVariable("colors.secondary"),
                    closeButtonBackgroundColor: getThemeVariable("colors.gray.200"),
                    closeButtonColor: getThemeVariable("colors.secondary")
                };
            } else {
                return {
                    backgroundColor: getThemeVariable("colors.secondary"),
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
        default:
            if (modalTheme === "light") {
                return {
                    backgroundColor: "#fff",
                    color: getThemeVariable("colors.secondary"),
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: getThemeVariable("colors.secondary")
                };
            } else {
                return {
                    backgroundColor: getThemeVariable("colors.secondary"),
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
    }
};

const getIconSize = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "bar_mini":
            return "1.4";
        default:
            return "2.4";
    }
};

export const ModalHeader = (props: IModalHeaderProps) => {
    const {variant = "clean", showCloseButton = true, children} = props;
    const {modalTheme} = useContext(ModalContext);

    const variantColors = getVariantColors(variant, modalTheme);
    const iconSize = getIconSize(variant);

    const cn = classNames(props.className, styles.modalHeaderWrapperStyle);

    const style = {
        backgroundColor: variantColors.backgroundColor
    };

    return (
        <div style={style} className={cn}>
            <Text as={props.as ?? "h3"} variant={getTextVariant(variant)} color={variantColors.color} className={styles.headerStyle}>
                {children}
            </Text>
            {showCloseButton && (
                <ModalCloseButton size={iconSize} wrapperColor={variantColors.closeButtonBackgroundColor} fillColor={variantColors.closeButtonColor} />
            )}
        </div>
    );
};
