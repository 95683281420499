import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchRegionPath} from "../../../region/fetch_region_path";

const FETCH_OFFER_LIST_REGION_PATH = "offer_list/FETCH_OFFER_LIST_REGION_PATH";
export const fetchOfferListRegionPathTypes = createRequestActionTypes(FETCH_OFFER_LIST_REGION_PATH);

const hasAppError = (err: unknown): err is {appError: string} => {
    return Object.prototype.hasOwnProperty.call(err, "appError");
};

const fetchOfferListRegionPath =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const regions = getState().offerList.selectedRegions;

        // Fetch data only when 1 region is selected and region is of desired type
        const isSingleRegion = regions.length === 1;

        if (!isSingleRegion) {
            dispatch({type: actionType.success, result: []});

            return true;
        }

        const region = ctx.prevResult.region;
        const query = region?.[0] || null;

        if (isEqual(query, getState().offerList.regionPathLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.regionPath;
        }

        dispatch({type: actionType.start, latestQuery: query});

        try {
            const regionPath = await fetchRegionPath(query);

            dispatch({type: actionType.success, result: regionPath});
            return regionPath;
        } catch (error) {
            dispatch({type: actionType.error, error: hasAppError(error) ? error.appError : null});

            // Return `true` this is not crucial data, and it shouldn't break view
            return true;
        }
    };

export const fetchOfferListRegionPathAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListRegionPath(fetchOfferListRegionPathTypes)(ctx);
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEqual} from "@pg-mono/nodash";
import {createRequestActionTypes, RequestActionTypes} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchRegionPath} from "../../../region/fetch_region_path";

const FETCH_OFFER_LIST_REGION_PATH = "offer_list/FETCH_OFFER_LIST_REGION_PATH";
export const fetchOfferListRegionPathTypes = createRequestActionTypes(FETCH_OFFER_LIST_REGION_PATH);

const hasAppError = (err: unknown): err is {appError: string} => {
    return Object.prototype.hasOwnProperty.call(err, "appError");
};

const fetchOfferListRegionPath =
    (actionType: RequestActionTypes) => (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch, getState: () => IRPStore) => {
        const regions = getState().offerList.selectedRegions;

        // Fetch data only when 1 region is selected and region is of desired type
        const isSingleRegion = regions.length === 1;

        if (!isSingleRegion) {
            dispatch({type: actionType.success, result: []});

            return true;
        }

        const region = ctx.prevResult.region;
        const query = region?.[0] || null;

        if (isEqual(query, getState().offerList.regionPathLatestQuery)) {
            // do not fetch the same data again
            return getState().offerList.regionPath;
        }

        dispatch({type: actionType.start, latestQuery: query});

        try {
            const regionPath = await fetchRegionPath(query);

            dispatch({type: actionType.success, result: regionPath});
            return regionPath;
        } catch (error) {
            dispatch({type: actionType.error, error: hasAppError(error) ? error.appError : null});

            // Return `true` this is not crucial data, and it shouldn't break view
            return true;
        }
    };

export const fetchOfferListRegionPathAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => {
    return fetchOfferListRegionPath(fetchOfferListRegionPathTypes)(ctx);
};
