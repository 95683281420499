import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IRegionPathRegion} from "../../../region/fetch_region_path";
import {fetchOfferListSearchActionTypes, ISearch} from "../../helpers/searches/fetch_search";
import {fetchOfferListArticlesTypes, IOfferArticle} from "../actions/fetch_offer_list_articles";
import {fetchOfferListNearbyRegionsTypes, fetchOfferListVoivodeshipTopCitiesTypes, IOfferListNearbyRegion} from "../actions/fetch_offer_list_nearby_regions";
import {fetchOfferListRegionPathTypes} from "../actions/fetch_offer_list_parent_regions";
import {fetchOfferListRecommendedRegionsTypes, IRecommendedRegion, IVoivodeshipRegion} from "../actions/fetch_offer_list_recommended_regions";
import {fetchSelectedRegionEstimatedPriceTypes, ISelectedRegionEstimatedPrice} from "../actions/fetch_selected_region_estimated_price";
import {fetchSelectedRegionDataTypes, ISelectedRegion} from "../actions/offer_list_selected_data_actions";
import {IOfferListListStore, offerListListReducer} from "./desktop_offer_list_list_reducer";
import {offerListSelectedRegionsReducer} from "./offer_list_selected_regions_reducer";

export interface IOfferListStore {
    list: IOfferListListStore;
    selectedRegions: ISelectedRegion[];
    selectedRegionsLatestQuery: string | string[] | Record<string, string>[];
    selectedRegionEstimatedPrices: ISelectedRegionEstimatedPrice[];
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    articlesLatestQuery: Record<string, string>;
    // nearby regions for linkoza
    nearbyRegions: IOfferListNearbyRegion[];
    nearbyRegionsRequestState: RequestState;
    nearbyRegionsLatestQuery: Record<string, string>;
    // parent regions for linkoza
    regionPath: IRegionPathRegion[];
    regionPathRequestState: RequestState;
    regionPathLatestQuery: Record<string, string>;
    search: ISearch;
    // nearby regions for linkoza
    recommendedRegions: IRecommendedRegion[];
    recommendedRegionsRequestState: RequestState;
    recommendedRegionsLatestQuery: Record<string, string>;
    voivodeshipTopCities: IVoivodeshipRegion[];
    voivodeshipTopCitiesLatestQuery: Record<string, string>;
}

export const offerListReducer = combineReducers({
    list: offerListListReducer,
    selectedRegions: offerListSelectedRegionsReducer(),
    selectedRegionsLatestQuery: reduceLatestQuery(fetchSelectedRegionDataTypes),
    selectedRegionEstimatedPrices: reduceResponse<ISelectedRegionEstimatedPrice[]>(fetchSelectedRegionEstimatedPriceTypes, []),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchOfferListArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferListArticlesTypes),
    articlesLatestQuery: reduceLatestQuery(fetchOfferListArticlesTypes),
    // nearby regions
    nearbyRegions: reduceResponse<IOfferListNearbyRegion[]>(fetchOfferListNearbyRegionsTypes, []),
    nearbyRegionsRequest: reduceRequestState(fetchOfferListNearbyRegionsTypes),
    nearbyRegionsLatestQuery: reduceLatestQuery(fetchOfferListNearbyRegionsTypes),
    // region path
    regionPath: reduceResponse<IOfferListNearbyRegion[]>(fetchOfferListRegionPathTypes, []),
    regionPathRequestState: reduceRequestState(fetchOfferListRegionPathTypes),
    regionPathLatestQuery: reduceLatestQuery(fetchOfferListRegionPathTypes),
    search: reduceResponse<ISearch>(fetchOfferListSearchActionTypes),
    // nearby regions for sidebar
    recommendedRegions: reduceResponse<IRecommendedRegion[]>(fetchOfferListRecommendedRegionsTypes, []),
    recommendedRegionsRequestState: reduceRequestState(fetchOfferListRecommendedRegionsTypes),
    recommendedRegionsLatestQuery: reduceLatestQuery(fetchOfferListRecommendedRegionsTypes),
    voivodeshipTopCities: reduceResponse<IVoivodeshipRegion[]>(fetchOfferListVoivodeshipTopCitiesTypes, []),
    voivodeshipTopCitiesLatestQuery: reduceLatestQuery(fetchOfferListVoivodeshipTopCitiesTypes)
});
import {combineReducers} from "redux";

import {reduceLatestQuery, reduceRequestState, reduceResponse, RequestState} from "@pg-mono/request-state";

import {IRegionPathRegion} from "../../../region/fetch_region_path";
import {fetchOfferListSearchActionTypes, ISearch} from "../../helpers/searches/fetch_search";
import {fetchOfferListArticlesTypes, IOfferArticle} from "../actions/fetch_offer_list_articles";
import {fetchOfferListNearbyRegionsTypes, fetchOfferListVoivodeshipTopCitiesTypes, IOfferListNearbyRegion} from "../actions/fetch_offer_list_nearby_regions";
import {fetchOfferListRegionPathTypes} from "../actions/fetch_offer_list_parent_regions";
import {fetchOfferListRecommendedRegionsTypes, IRecommendedRegion, IVoivodeshipRegion} from "../actions/fetch_offer_list_recommended_regions";
import {fetchSelectedRegionEstimatedPriceTypes, ISelectedRegionEstimatedPrice} from "../actions/fetch_selected_region_estimated_price";
import {fetchSelectedRegionDataTypes, ISelectedRegion} from "../actions/offer_list_selected_data_actions";
import {IOfferListListStore, offerListListReducer} from "./desktop_offer_list_list_reducer";
import {offerListSelectedRegionsReducer} from "./offer_list_selected_regions_reducer";

export interface IOfferListStore {
    list: IOfferListListStore;
    selectedRegions: ISelectedRegion[];
    selectedRegionsLatestQuery: string | string[] | Record<string, string>[];
    selectedRegionEstimatedPrices: ISelectedRegionEstimatedPrice[];
    // related articles
    articles: IOfferArticle[];
    articlesRequest: RequestState;
    articlesLatestQuery: Record<string, string>;
    // nearby regions for linkoza
    nearbyRegions: IOfferListNearbyRegion[];
    nearbyRegionsRequestState: RequestState;
    nearbyRegionsLatestQuery: Record<string, string>;
    // parent regions for linkoza
    regionPath: IRegionPathRegion[];
    regionPathRequestState: RequestState;
    regionPathLatestQuery: Record<string, string>;
    search: ISearch;
    // nearby regions for linkoza
    recommendedRegions: IRecommendedRegion[];
    recommendedRegionsRequestState: RequestState;
    recommendedRegionsLatestQuery: Record<string, string>;
    voivodeshipTopCities: IVoivodeshipRegion[];
    voivodeshipTopCitiesLatestQuery: Record<string, string>;
}

export const offerListReducer = combineReducers({
    list: offerListListReducer,
    selectedRegions: offerListSelectedRegionsReducer(),
    selectedRegionsLatestQuery: reduceLatestQuery(fetchSelectedRegionDataTypes),
    selectedRegionEstimatedPrices: reduceResponse<ISelectedRegionEstimatedPrice[]>(fetchSelectedRegionEstimatedPriceTypes, []),
    //related articles
    articles: reduceResponse<IOfferArticle[]>(fetchOfferListArticlesTypes, []),
    articlesRequest: reduceRequestState(fetchOfferListArticlesTypes),
    articlesLatestQuery: reduceLatestQuery(fetchOfferListArticlesTypes),
    // nearby regions
    nearbyRegions: reduceResponse<IOfferListNearbyRegion[]>(fetchOfferListNearbyRegionsTypes, []),
    nearbyRegionsRequest: reduceRequestState(fetchOfferListNearbyRegionsTypes),
    nearbyRegionsLatestQuery: reduceLatestQuery(fetchOfferListNearbyRegionsTypes),
    // region path
    regionPath: reduceResponse<IOfferListNearbyRegion[]>(fetchOfferListRegionPathTypes, []),
    regionPathRequestState: reduceRequestState(fetchOfferListRegionPathTypes),
    regionPathLatestQuery: reduceLatestQuery(fetchOfferListRegionPathTypes),
    search: reduceResponse<ISearch>(fetchOfferListSearchActionTypes),
    // nearby regions for sidebar
    recommendedRegions: reduceResponse<IRecommendedRegion[]>(fetchOfferListRecommendedRegionsTypes, []),
    recommendedRegionsRequestState: reduceRequestState(fetchOfferListRecommendedRegionsTypes),
    recommendedRegionsLatestQuery: reduceLatestQuery(fetchOfferListRecommendedRegionsTypes),
    voivodeshipTopCities: reduceResponse<IVoivodeshipRegion[]>(fetchOfferListVoivodeshipTopCitiesTypes, []),
    voivodeshipTopCitiesLatestQuery: reduceLatestQuery(fetchOfferListVoivodeshipTopCitiesTypes)
});
