import React from "react";

import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize} from "@pg-mono/string-utils";

import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {getCountryName} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {Country} from "../../region/types/Country";
import {SearchTab} from "../actions/fetch_search_all_action";
import {fetchSingleRegionSimple} from "../actions/fetch_search_regions_action";
import {SearchInfoWindowCitiesList, SearchInfoWindowCitiesListSpain} from "../utils/search_info_window_cities_list";
import {resultsList} from "./atoms/atoms";
import {ResultsListItem} from "./atoms/ResultsListItem";
import {ISearchInputValue} from "./ISearchInputValue";

interface IProps {
    activeItemId: number | null;
    onLinkClick: (option: ISearchInputValue) => void;
    country?: Country;
}

export const SuggestedRegions = (props: IProps) => {
    const onSuggestedRegionLinkClick = async (item: {id: number}) => {
        const suggestedRegion = await fetchSingleRegionSimple(item.id);
        props.onLinkClick({
            label: suggestedRegion.full_name_reverted,
            tabType: SearchTab.Regions,
            regions: [suggestedRegion]
        });
    };
    const allSpainResultsHandler = () => {
        const {friendlySlug} = offerUrlBuilder({region: ""});
        window.location.href = rpAppLink.offer.listFriendlyAbroad({
            friendlySlug: friendlySlug,
            country: getCountryName(Country.SPAIN)
        });
    };

    const SuggestedRegionList = props.country === Country.SPAIN ? SearchInfoWindowCitiesListSpain : SearchInfoWindowCitiesList;

    return (
        <ul css={resultsList}>
            {props.country === Country.SPAIN && <ResultsListItem title="Wszystkie lokalizacje w Hiszpanii" onClick={allSpainResultsHandler} />}
            {SuggestedRegionList.map((item, idx) => {
                const propertiesCount = item.offerCount;
                const propertiesFormat = pluralize(["oferta", "oferty", "ofert"])(propertiesCount);

                return (
                    <ResultsListItem
                        key={idx}
                        idx={idx}
                        activeItemId={props.activeItemId}
                        onClick={() => onSuggestedRegionLinkClick(item)}
                        title={item.label}
                        subTitle={item.regionFull}
                        countLabel={`${propertiesCount} ${propertiesFormat}`}
                    />
                );
            })}
        </ul>
    );
};
import React from "react";

import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize} from "@pg-mono/string-utils";

import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {getCountryName} from "../../offer/list/helpers/sub_type/get_fetch_offer_list_query";
import {Country} from "../../region/types/Country";
import {SearchTab} from "../actions/fetch_search_all_action";
import {fetchSingleRegionSimple} from "../actions/fetch_search_regions_action";
import {SearchInfoWindowCitiesList, SearchInfoWindowCitiesListSpain} from "../utils/search_info_window_cities_list";
import {resultsList} from "./atoms/atoms";
import {ResultsListItem} from "./atoms/ResultsListItem";
import {ISearchInputValue} from "./ISearchInputValue";

interface IProps {
    activeItemId: number | null;
    onLinkClick: (option: ISearchInputValue) => void;
    country?: Country;
}

export const SuggestedRegions = (props: IProps) => {
    const onSuggestedRegionLinkClick = async (item: {id: number}) => {
        const suggestedRegion = await fetchSingleRegionSimple(item.id);
        props.onLinkClick({
            label: suggestedRegion.full_name_reverted,
            tabType: SearchTab.Regions,
            regions: [suggestedRegion]
        });
    };
    const allSpainResultsHandler = () => {
        const {friendlySlug} = offerUrlBuilder({region: ""});
        window.location.href = rpAppLink.offer.listFriendlyAbroad({
            friendlySlug: friendlySlug,
            country: getCountryName(Country.SPAIN)
        });
    };

    const SuggestedRegionList = props.country === Country.SPAIN ? SearchInfoWindowCitiesListSpain : SearchInfoWindowCitiesList;

    return (
        <ul css={resultsList}>
            {props.country === Country.SPAIN && <ResultsListItem title="Wszystkie lokalizacje w Hiszpanii" onClick={allSpainResultsHandler} />}
            {SuggestedRegionList.map((item, idx) => {
                const propertiesCount = item.offerCount;
                const propertiesFormat = pluralize(["oferta", "oferty", "ofert"])(propertiesCount);

                return (
                    <ResultsListItem
                        key={idx}
                        idx={idx}
                        activeItemId={props.activeItemId}
                        onClick={() => onSuggestedRegionLinkClick(item)}
                        title={item.label}
                        subTitle={item.regionFull}
                        countLabel={`${propertiesCount} ${propertiesFormat}`}
                    />
                );
            })}
        </ul>
    );
};
