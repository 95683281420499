import React from "react";

import {isEmpty} from "@pg-mono/nodash";

import {ResultsInfo} from "../../../../search/components/atoms/ResultsInfo";
import {RegionTabs} from "../../../../search/components/RegionTabs";
import {IDropdownListOwnProps, ResultWrapper} from "../../../../search/components/search_autocomplete/SearchAutocompleteDropdown";
import {RegionsTab, RegionsTabLabelTypes} from "../../../../search/components/search_autocomplete/tab_renderers/RegionsTab";
import {SuggestedRegions} from "../../../../search/components/SuggestedRegions";

export const PromotionListSearchFilterAutocompleteDropdown = (props: IDropdownListOwnProps) => {
    const renderTabResults = () => {
        return (
            <RegionsTab
                regionsLabelType={RegionsTabLabelTypes.PROMOTIONS}
                regions={props.regions}
                requestStateObj={props.requestStateObj}
                activeItem={props.activeItem}
                onLinkClick={props.onLinkClick}
            />
        );
    };

    const renderDropdownContent = () => {
        if (props.search.label && isEmpty(props.search.label) && isEmpty(props.regions)) {
            return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
        } else if (!props.search.label) {
            return <SuggestedRegions activeItemId={props.activeItem.id} onLinkClick={props.onLinkClick} />;
        } else {
            return <ResultWrapper>{renderTabResults()}</ResultWrapper>;
        }
    };

    return (
        <>
            <RegionTabs search={props.search} selectedTab={props.selectedTab} removeRegionTag={props.removeRegionTag} regionsLimit={PROMOTION_REGIONS_LIMIT} />
            {renderDropdownContent()}
        </>
    );
};

const PROMOTION_REGIONS_LIMIT = 3;
import React from "react";

import {isEmpty} from "@pg-mono/nodash";

import {ResultsInfo} from "../../../../search/components/atoms/ResultsInfo";
import {RegionTabs} from "../../../../search/components/RegionTabs";
import {IDropdownListOwnProps, ResultWrapper} from "../../../../search/components/search_autocomplete/SearchAutocompleteDropdown";
import {RegionsTab, RegionsTabLabelTypes} from "../../../../search/components/search_autocomplete/tab_renderers/RegionsTab";
import {SuggestedRegions} from "../../../../search/components/SuggestedRegions";

export const PromotionListSearchFilterAutocompleteDropdown = (props: IDropdownListOwnProps) => {
    const renderTabResults = () => {
        return (
            <RegionsTab
                regionsLabelType={RegionsTabLabelTypes.PROMOTIONS}
                regions={props.regions}
                requestStateObj={props.requestStateObj}
                activeItem={props.activeItem}
                onLinkClick={props.onLinkClick}
            />
        );
    };

    const renderDropdownContent = () => {
        if (props.search.label && isEmpty(props.search.label) && isEmpty(props.regions)) {
            return <ResultsInfo title="Niestety nic nie znaleźliśmy" text="Zmień parametry wyszukiwania" />;
        } else if (!props.search.label) {
            return <SuggestedRegions activeItemId={props.activeItem.id} onLinkClick={props.onLinkClick} />;
        } else {
            return <ResultWrapper>{renderTabResults()}</ResultWrapper>;
        }
    };

    return (
        <>
            <RegionTabs search={props.search} selectedTab={props.selectedTab} removeRegionTag={props.removeRegionTag} regionsLimit={PROMOTION_REGIONS_LIMIT} />
            {renderDropdownContent()}
        </>
    );
};

const PROMOTION_REGIONS_LIMIT = 3;
