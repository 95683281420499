export interface IUserComUserengageData {
    [key: string]: string | number | boolean;
}

declare global {
    interface Window {
        userengage: (event: string, data: Record<string, unknown>) => void;
        rpuserengage: (event: string, data: Record<string, unknown>) => void;
    }
}

export const userComUserengage = (event: string, data: IUserComUserengageData) => {
    ((window as Window).userengage || (window as Window).rpuserengage)?.(event, data);
};
export interface IUserComUserengageData {
    [key: string]: string | number | boolean;
}

declare global {
    interface Window {
        userengage: (event: string, data: Record<string, unknown>) => void;
        rpuserengage: (event: string, data: Record<string, unknown>) => void;
    }
}

export const userComUserengage = (event: string, data: IUserComUserengageData) => {
    ((window as Window).userengage || (window as Window).rpuserengage)?.(event, data);
};
