import {RequestActionTypes} from "./create_request_action_types";
import {ILatestQueryAction} from "./reduce_response";

export const reduceLatestQuery =
    (requestTypes: RequestActionTypes) =>
    (state: Record<string, string | string[]> = {}, action: ILatestQueryAction): Record<string, string | string[]> => {
        switch (action.type) {
            case requestTypes.start:
                return action.latestQuery;
            default:
                return state;
        }
    };
import {RequestActionTypes} from "./create_request_action_types";
import {ILatestQueryAction} from "./reduce_response";

export const reduceLatestQuery =
    (requestTypes: RequestActionTypes) =>
    (state: Record<string, string | string[]> = {}, action: ILatestQueryAction): Record<string, string | string[]> => {
        switch (action.type) {
            case requestTypes.start:
                return action.latestQuery;
            default:
                return state;
        }
    };
