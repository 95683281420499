import {match, matchPath} from "react-router";

interface IPathDefinition {
    [key: string]:
        | {
              [key: string]: string | IPathDefinition;
          }
        | string;
}

/*
 * returns a matching path from deeply nested objects i.e. constructed using prefixPath, like `apiPath`, `appPath`
 */
export const getMatchedPathDefinition = (pathsDefinition: IPathDefinition, pathName: string): match | null => {
    let match: match | null = null;
    const realPathName = pathName.replace("api-proxy/", "");

    const keys = Object.keys(pathsDefinition);

    keys.forEach((key) => {
        const path = pathsDefinition[key];
        if (typeof path === "string") {
            match = matchPath(realPathName, {path, exact: true});
        } else {
            match = getMatchedPathDefinition(path, realPathName) || match;
        }
    });

    return match;
};
import {match, matchPath} from "react-router";

interface IPathDefinition {
    [key: string]:
        | {
              [key: string]: string | IPathDefinition;
          }
        | string;
}

/*
 * returns a matching path from deeply nested objects i.e. constructed using prefixPath, like `apiPath`, `appPath`
 */
export const getMatchedPathDefinition = (pathsDefinition: IPathDefinition, pathName: string): match | null => {
    let match: match | null = null;
    const realPathName = pathName.replace("api-proxy/", "");

    const keys = Object.keys(pathsDefinition);

    keys.forEach((key) => {
        const path = pathsDefinition[key];
        if (typeof path === "string") {
            match = matchPath(realPathName, {path, exact: true});
        } else {
            match = getMatchedPathDefinition(path, realPathName) || match;
        }
    });

    return match;
};
