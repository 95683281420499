import React, {FC, useEffect} from "react";
import ReactModal from "react-modal";

import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";
import {ModalCloseButton} from "./atoms/ModalCloseButton";
import {ModalContent} from "./atoms/ModalContent";
import {ModalHeader} from "./atoms/ModalHeader";
import {ContentElement} from "./core/ContentElement";
import {OverlayElement} from "./core/OverlayElement";
import {ModalLayoutEmptyResponsive} from "./layouts/ModalLayoutEmptyResponsive";
import {ModalLayoutPrompt} from "./layouts/ModalLayoutPrompt";
import {ModalContextProvider} from "./ModalContext";

interface ModalAtoms {
    Header: typeof ModalHeader;
    CloseButton: typeof ModalCloseButton;
    Content: typeof ModalContent;
}

interface ModalLayouts {
    Layout: {
        EmptyResponsive: typeof ModalLayoutEmptyResponsive;
        Prompt: typeof ModalLayoutPrompt;
    };
}

type ModalType = FC<IModalProps> & ModalAtoms & ModalLayouts;

export const Modal: ModalType = (props) => {
    const closeTimeoutMS = props.closeTimeout ?? 200;

    useEffect(() => {
        const rootId = props.modalRootId ?? defaultModalParams["defaultRootSelector"];

        if (rootId) {
            ReactModal.setAppElement(rootId);
        }
    }, [props.modalRootId]);

    // useEffect(() => {
    //     props.isOpen && toggleBodyScroll(props.isOpen);
    //
    //     return () => toggleBodyScroll(false);
    // }, [props.isOpen]);

    return (
        <ModalContextProvider value={{...defaultModalParams, ...props}}>
            <ReactModal
                closeTimeoutMS={closeTimeoutMS}
                isOpen={Boolean(props.isOpen)}
                ariaHideApp={false}
                contentLabel={props.contentLabel}
                shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
                shouldCloseOnEsc={props.shouldCloseOnEsc}
                contentElement={ContentElement(props)}
                overlayElement={OverlayElement(props)}
                onRequestClose={props?.onModalClose}
                onAfterOpen={props?.onAfterOpen}
                onAfterClose={props?.onAfterClose}
                className={props.className}
            >
                {props.children}
            </ReactModal>
        </ModalContextProvider>
    );
};

Modal.Header = ModalHeader;
Modal.CloseButton = ModalCloseButton;
Modal.Content = ModalContent;

Modal.Layout = {
    EmptyResponsive: ModalLayoutEmptyResponsive,
    Prompt: ModalLayoutPrompt
};
import React, {FC, useEffect} from "react";
import ReactModal from "react-modal";

import {defaultModalParams} from "../constants/defaults";
import {IModalProps} from "../types/IModalProps";
import {ModalCloseButton} from "./atoms/ModalCloseButton";
import {ModalContent} from "./atoms/ModalContent";
import {ModalHeader} from "./atoms/ModalHeader";
import {ContentElement} from "./core/ContentElement";
import {OverlayElement} from "./core/OverlayElement";
import {ModalLayoutEmptyResponsive} from "./layouts/ModalLayoutEmptyResponsive";
import {ModalLayoutPrompt} from "./layouts/ModalLayoutPrompt";
import {ModalContextProvider} from "./ModalContext";

interface ModalAtoms {
    Header: typeof ModalHeader;
    CloseButton: typeof ModalCloseButton;
    Content: typeof ModalContent;
}

interface ModalLayouts {
    Layout: {
        EmptyResponsive: typeof ModalLayoutEmptyResponsive;
        Prompt: typeof ModalLayoutPrompt;
    };
}

type ModalType = FC<IModalProps> & ModalAtoms & ModalLayouts;

export const Modal: ModalType = (props) => {
    const closeTimeoutMS = props.closeTimeout ?? 200;

    useEffect(() => {
        const rootId = props.modalRootId ?? defaultModalParams["defaultRootSelector"];

        if (rootId) {
            ReactModal.setAppElement(rootId);
        }
    }, [props.modalRootId]);

    // useEffect(() => {
    //     props.isOpen && toggleBodyScroll(props.isOpen);
    //
    //     return () => toggleBodyScroll(false);
    // }, [props.isOpen]);

    return (
        <ModalContextProvider value={{...defaultModalParams, ...props}}>
            <ReactModal
                closeTimeoutMS={closeTimeoutMS}
                isOpen={Boolean(props.isOpen)}
                ariaHideApp={false}
                contentLabel={props.contentLabel}
                shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
                shouldCloseOnEsc={props.shouldCloseOnEsc}
                contentElement={ContentElement(props)}
                overlayElement={OverlayElement(props)}
                onRequestClose={props?.onModalClose}
                onAfterOpen={props?.onAfterOpen}
                onAfterClose={props?.onAfterClose}
                className={props.className}
            >
                {props.children}
            </ReactModal>
        </ModalContextProvider>
    );
};

Modal.Header = ModalHeader;
Modal.CloseButton = ModalCloseButton;
Modal.Content = ModalContent;

Modal.Layout = {
    EmptyResponsive: ModalLayoutEmptyResponsive,
    Prompt: ModalLayoutPrompt
};
